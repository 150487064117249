import i18next from 'i18next';

import { TimeActivity } from '../interfaces';
import { formatYearMonthDay, formatMonthYear } from '../../../utils/date';
import { stockingPhaseTypes } from '../../../config/commons';
import { getTextColor } from '../../../helpers/chartjs.helpers';

export const getAppActivityData = (props: { days: string[], analysisUsed: { [key: string]: number[] }; showActivityByPhaseType: boolean }) => {
  const { days, analysisUsed, showActivityByPhaseType } = props;

  if (!showActivityByPhaseType) {
    return {
      labels: days.map(day => formatYearMonthDay(day)),
      datasets: [
        {
          backgroundColor: '#1B62E6',
          borderWidth: 0,
          data: analysisUsed['ALL'],
          label: 'ALL',
        },
      ]
    };
  }

  return {
    labels: days.map(day => formatYearMonthDay(day)),
    datasets: [
      {
        backgroundColor: '#1B62E6',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.LARVAE],
        label: i18next.t('adminDashboard.phaseTypes.LARVAE'),
      },
      {
        backgroundColor: '#12AA86',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.JUVENILE],
        label: i18next.t('adminDashboard.phaseTypes.JUVENILE'),
      },
      {
        backgroundColor: '#E82963',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.ADULT],
        label: i18next.t('adminDashboard.phaseTypes.ADULT'),
      }
    ]
  };
};

export const getAppActivityMonthlyData = (props: { months: string[], analysisUsed: { [key: string]: number[] }; showActivityByPhaseType: boolean }) => {
  const { months, analysisUsed, showActivityByPhaseType } = props;

  if (!showActivityByPhaseType) {
    return {
      labels: months.map(month => formatMonthYear(month)),
      datasets: [
        {
          backgroundColor: '#1B62E6',
          borderWidth: 0,
          data: analysisUsed['ALL'],
          label: 'ALL',
        },
      ]
    };
  }

  return {
    labels: months.map(month => formatMonthYear(month)),
    datasets: [
      {
        backgroundColor: '#1B62E6',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.LARVAE],
        label: i18next.t('adminDashboard.phaseTypes.LARVAE'),
      },
      {
        backgroundColor: '#12AA86',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.JUVENILE],
        label: i18next.t('adminDashboard.phaseTypes.JUVENILE'),
      },
      {
        backgroundColor: '#E82963',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.ADULT],
        label: i18next.t('adminDashboard.phaseTypes.ADULT'),
      }
    ]
  };
};

export const getAppFullActivityData = (props: { selectDate?: string, days: string[], analysisUsed: { [key: string]: number[] }, analysisUsedByDay: TimeActivity[] }) => {
  const { selectDate, days, analysisUsed, analysisUsedByDay } = props;

  if (selectDate && analysisUsedByDay.length) {
    return {
      labels: analysisUsedByDay.map(data => data.label),
      datasets: [
        {
          backgroundColor: '#1B62E6',
          borderWidth: 0,
          data: analysisUsedByDay.map(data => data.count)
        }
      ]
    };
  }

  return {
    labels: days.map(day => formatYearMonthDay(day)),
    datasets: [
      {
        backgroundColor: '#1B62E6',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.LARVAE],
        label: i18next.t('adminDashboard.phaseTypes.LARVAE'),
      },
      {
        backgroundColor: '#12AA86',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.JUVENILE],
        label: i18next.t('adminDashboard.phaseTypes.JUVENILE'),
      },
      {
        backgroundColor: '#E82963',
        borderWidth: 0,
        data: analysisUsed[stockingPhaseTypes.ADULT],
        label: i18next.t('adminDashboard.phaseTypes.ADULT'),
      }
    ]
  };
};

export const getOptionsBar = (props: { showLegends: boolean; theme?: 'dark' | 'light' }) => {
  const { showLegends, theme } = props;

  return {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    layout: {
      padding: {
        left: 20,
        right: 20,
        bottom: 10,
      }
    },
    plugins: {
      legend: {
        display: showLegends,
        labels: {
          color: getTextColor(theme),
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          color: getTextColor(theme),
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: getTextColor(theme),
        }
      },
    },
  };
};