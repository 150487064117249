import cx from 'classnames';
import 'react-phone-number-input/style.css';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { Space, Button, Row, Tooltip, Dropdown, Menu, InputRef } from 'antd';
import React, { useState, useEffect, useRef, MutableRefObject, Key } from 'react';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';

import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { getUserSession } from '../../utils/userSession';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { isSuperAdmin, onboardingTypes, isSalesRole, isSalesManagerRole, clientOptions, THEME, hasAdminRole } from '../../config/commons';
import { setStepIndex, setOnboardingType, setShouldRun, setRun, goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';

import './Clients.scss';
import { Client } from './interfaces';
import { NewClient } from './NewClient';
import { EditClient } from './EditClient';
import styles from './Clients.module.scss';
import { ClientSettings } from './ClientSettings';
import { ClientSubHeader } from './ClientSubHeader';
import { CLIENTS, getValueActiveUsers, clientDefault } from './clientsHelper';
import { fetchClients, setSelectedClient, fetchClient, fetchSearchClients, fetchPlans, fetchCompanies, fetchCompaniesByIds, setActiveAssignedCompanies, setInactiveAssignedCompanies, fetchSellers, setSellers, setPromoters, fetchPromotersBySeller, setShowEditClientModal, setShowCreateClientModal, setShowSettingClientModal } from './clientsSlice';

function Clients () {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    clients,
    isClientsLoading,
    filters,
  } = useSelector((state: RootState) => state.clients);

  const { clientStatus } = filters;

  const refSearchName = useRef(null);
  const refSearchEmail = useRef(null);

  const onboardingUserData = useSelector((state: RootState) => state.onboarding.user);
  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);
  const existsUserData = useSelector((state: RootState) => state.onboarding.existsUserData);

  const [copiedCode, setCopiedCode] = useState('');

  const userSession = getUserSession();
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.clientsSection) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.CLIENTS_SECTION));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'clients.title' }));
    const active = getValueActiveUsers(CLIENTS.ACTIVE);
    dispatch(fetchClients({ active }));
  }, [dispatch]);

  const clientsData = clients ? clients.map((client: Client, index) => {
    return {
      key: index,
      _id: client._id,
      active: client.active,
      name: client.name,
      email: client.owner?.email ? client.owner?.email : <LrvTag color='#f50'>{t('clients.notAssigned')}</LrvTag>,
      code: client.code,
      assignedCompanies: client.assignedCompanies,
      isDistributor: client.isDistributor,
    };
  }) : [];

  const getClientsColumns = () => {
    const columns: ColumnsType<Client> = [
      {
        key: 1,
        title: t('clients.company'),
        dataIndex: 'name',
        width: isSuperAdmin() ? '30%' : '20%',
        ...getColumnSearchProps('name', refSearchName),
      },
      {
        key: 2,
        title: t('clients.email'),
        dataIndex: 'email',
        width: isSuperAdmin() ? '35%' : '35%',
        responsive: ['sm'] as Breakpoint[],
        ...getColumnSearchProps('email', refSearchEmail),
      },
    ];

    if (isSuperAdmin()) {
      columns.push({
        key: 3,
        title: t('clients.code'),
        dataIndex: 'code',
        width: '20%',
        render: (text: string) => {
          if (!text) {
            return null;
          }

          return <Tooltip title={t('clients.clickToCopy')} placement='left'>
            <span
              className={cx(styles.clickToCopy, copiedCode === text ? styles.clickToToCopyActive : '')}
              onClick={(e) => {
                e.stopPropagation();
                setCopiedCode(text);
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(text);
                }

                setTimeout(() => {
                  setCopiedCode('');
                }, 500);
              }}
            >
              {text}
            </span>
          </Tooltip >;
        }
      });
    }

    columns.push({
      key: 4,
      dataIndex: 'active',
      width: '10%',
      render: (_, record: Client) => {
        return (
          <Space align='center' className={styles.statusCell} size={5}>
            {renderStatusTag(record)}
          </Space>
        );
      },
    });

    columns.push({
      key: 5,
      width: isSuperAdmin() ?
        (isDesktop ? '30%' : '10%') :
        (isDesktop ? '20%' : '15%'),
      render: (_, record: Client) => {
        return (
          <div className={styles.optionsCell}>
            {renderDropdownClientOptions(record)}
          </div>
        );
      }
    });

    return columns;
  };

  const renderStatusTag = (record: Client) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag id={id} type='info' className={styles.statusTag}>{t('users.userInactive')}</LrvTag>
    );
  };

  const renderDropdownClientOptions = (client: Client) => {
    return (
      <Dropdown
        overlay={() => menuItemsClientOptions(client)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          id={`button_${client._id}`}
          onClick={(e) => e.stopPropagation()}
        />
      </Dropdown>
    );
  };

  const menuItemsClientOptions = (client: Client) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case clientOptions.EDIT:
              onEditClientOption(client);
              break;

            case clientOptions.SETTINGS:
              onSettingClientOption(client);
              break;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={clientOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('clients.editClient')}</span>
        </Menu.Item>

        <Menu.Item id='menu_option_settings' key={clientOptions.SETTINGS} className={styles.menuItemOptions}>
          <Icon name='settings-4' theme={theme} className={styles.icon} />
          <span>{t('clients.settings')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const onEditClientOption = (record: Client) => {
    dispatch(setShowEditClientModal(true));
    dispatch(fetchClient(record._id, record.isDistributor));
    dispatch(setActiveAssignedCompanies([]));
    dispatch(setInactiveAssignedCompanies([]));

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(2500));
    }
  };

  const onSettingClientOption = (record: Client) => {
    dispatch(setShowSettingClientModal(true));
    dispatch(fetchClient(record._id, record.isDistributor));
    dispatch(setActiveAssignedCompanies([]));
    dispatch(setInactiveAssignedCompanies([]));

    if (record.isDistributor) {
      dispatch(fetchCompanies());

      if (record.assignedCompanies && record.assignedCompanies.length > 0) {
        dispatch(fetchCompaniesByIds(record.assignedCompanies));
      }
    }
  };

  const searchClients = (props: { dataIndex: string; confirm: (param?: FilterConfirmProps | undefined) => void; value: Key[] }) => {
    const { dataIndex, confirm, value } = props;
    confirm();

    const status = getValueActiveUsers(clientStatus);
    switch (dataIndex) {
      case 'name':
        dispatch(fetchSearchClients({ name: value[0], clientStatus: status }));
        break;

      case 'email':
        dispatch(fetchSearchClients({ email: value[0], clientStatus: status }));
        break;
    }
  };

  const getColumnSearchProps = (dataIndex: string, ref: MutableRefObject<InputRef | null>) => {
    const label = `clients.paramsSearch.${dataIndex}`;
    const placeholder = `${t('clients.search')} ${t(label)}`;

    return {
      filterDropdown: (props: FilterDropdownProps) => {
        const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;
        return (
          <div className={styles.menuSearch}>
            <LrvInput
              theme='light'
              id={`txt_${dataIndex}_search`}
              ref={ref}
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => {
                if (selectedKeys[0]) {
                  clearFilters && clearFilters();
                  searchClients({ dataIndex, confirm, value: selectedKeys });
                }
              }}
              className={styles.inputSearch}
            />
            <Space>
              <Button
                id={`btn_${dataIndex}_search`}
                type='primary'
                onClick={() => {
                  clearFilters && clearFilters();
                  searchClients({ dataIndex, confirm, value: selectedKeys });
                }}
                icon={<SearchOutlined />}
                size='small'
                className={styles.buttonSearch}
                disabled={!selectedKeys[0]}
              >
                {t('clients.search')}
              </Button>
              <Button
                id={`btn_${dataIndex}_reset`}
                onClick={() => {
                  clearFilters && clearFilters();
                  const active = getValueActiveUsers(CLIENTS.ACTIVE);
                  dispatch(fetchClients({ active }));
                }}
                className={styles.buttonReset}
              >
                {t('clients.reset')}
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => <SearchOutlined className={isLightTheme ? styles.searchOutlinedIconLight : styles.searchOutlinedIconDark} style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value: boolean | Key, record: Client) => {
        const item: GenericParam = record;
        const field: string = item[dataIndex].toString().toLowerCase();
        return field.includes(`${value}`.toLowerCase());
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => ref.current?.select(), 100);
        }
      },
    };
  };

  const resetSelectedClient = () => {
    dispatch(setSelectedClient(clientDefault));
  };

  const onButtonAddClient = () => {
    dispatch(fetchPlans());
    dispatch(setSellers([]));
    dispatch(setPromoters([]));
    dispatch(fetchSellers());

    if (isSalesRole() || isSalesManagerRole() || hasAdminRole()) {
      dispatch(fetchPromotersBySeller(userSession._id));
    }

    resetSelectedClient();
    dispatch(setShowCreateClientModal(true));
  };

  return (
    <div className={styles.clients}>
      <ClientSubHeader onClickAddClient={onButtonAddClient} theme={theme} />

      <Row className={styles.rowFlex}>
        <LrvTable
          id='table_clients'
          className={styles.tableClients}
          columns={getClientsColumns()}
          rowClassName={styles.clientsRow}
          dataSource={clientsData}
          loading={isClientsLoading}
          scroll={{ y: '' }}
          theme={theme}
          size='small'
          pagination={{
            showSizeChanger: false,
            size: 'default',
          }}
        />
      </Row >

      <NewClient />
      <EditClient />
      <ClientSettings />
    </div>
  );
}

export default Clients;
