import React from 'react';
import moment from 'moment';
import { Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { DropdownProps } from '../../common/interfaces/commons';
import IconButton from '../../common/components/buttons/IconButton';
import CleanButton from '../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { timezone, getHoursOffset, formatYearMonthDay } from '../../utils/date';
import { companiesTypes, DATE_FORMATS, isSuperAdmin } from '../../config/commons';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvFilterPanel } from '../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import { FilterAdminDashboard } from './interfaces';
import styles from './AdminDashboardFilters.module.scss';
import * as adminDashboardSlice from './adminDashboardSlice';
import { downloadAnalysisActivity } from './adminDashboardSlice';
import { disabledDateFrom, disabledDateTo, disabledRangeDate, disabledMonthlyDateFrom } from './helpers';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function AdminDashboardFilters (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters, isDownloadingAnalysisActivity } = useSelector((state: Store) => state.adminDashboard);
  const { daily, monthly, companyType, activityType, dateSelected, showActivityByPhaseType } = filters;
  const { fromDate: dailyFromDate, toDate: dailyToDate } = daily;
  const { fromDate: monthlyFromDate, toDate: monthlyToDate } = monthly;

  const onChangeFilters = (props: FilterAdminDashboard) => {
    const { activityType, daily, monthly, companyType, dateSelected } = props;

    switch (activityType) {
      case 'DAILY':
        dispatch(adminDashboardSlice.fetchAppActivity({ fromDate: daily.fromDate, toDate: daily.toDate, companyType }));
        break;

      case 'MONTHLY':
        dispatch(adminDashboardSlice.fetchAppMonthlyActivity({ fromDate: monthly.fromDate, toDate: monthly.toDate, companyType }));
        break;

      case 'PER_HOUR':
        dispatch(adminDashboardSlice.fetchAppActivityByHour({ date: dateSelected, companyType }));
        break;
    }
  };

  const renderDropdownActivityType = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        theme={theme}
        className={className}
        id='dropdown_activity_type'
        title={t('adminDashboard.filters.activity')}
        value={activityType}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        onChange={(value) => {
          dispatch(adminDashboardSlice.setActivityType(value));
          const params: FilterAdminDashboard = {
            ...filters,
            activityType: value,
          };

          onChangeFilters(params);
        }}
        optionFilterProp='children'
        filterOption={(input, option) => {
          const children = `${option?.children || ''}`;
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        dropdownMatchSelectWidth={false}
      >
        <Option key={1} value='DAILY'>{t('adminDashboard.filters.byDays')}</Option>
        {isSuperAdmin() && <Option key={2} value='MONTHLY'>{t('adminDashboard.filters.byMonth')}</Option>}
        <Option key={3} value='PER_HOUR'>{t('adminDashboard.filters.byHours')}</Option>
      </LrvSelect>
    );
  };

  const renderCheckoutByPhase = (props: DropdownProps) => {
    const { className, theme } = props;

    if (!activityType) {
      return null;
    }

    return (
      <LrvCheckbox
        className={className}
        theme={theme}
        onChange={event => dispatch(adminDashboardSlice.setShowActivityByPhaseType(event.target.checked))}
        checked={showActivityByPhaseType}
      >
        {t('adminDashboard.filters.byPhase')}
      </LrvCheckbox>
    );

  };

  const renderDropdownCompanyType = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        theme={theme}
        className={className}
        id='dropdown_company_type'
        title={t('adminDashboard.filters.typeCompanies')}
        value={companyType || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        onChange={(value) => {
          dispatch(adminDashboardSlice.setCompanyType(value));
          const params: FilterAdminDashboard = {
            ...filters,
            companyType: value,
          };

          onChangeFilters(params);
        }}
        optionFilterProp='children'
        filterOption={(input, option) => {
          const children = `${option?.children || ''}`;
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        dropdownMatchSelectWidth={false}
      >
        <Option key={companiesTypes.ALL} value={companiesTypes.ALL}>{t('adminDashboard.companyType.all')}</Option>
        <Option key={companiesTypes.CLIENT} value={companiesTypes.CLIENT}>{t('adminDashboard.companyType.clients')}</Option>
        <Option key={companiesTypes.TRIAL_PHASE} value={companiesTypes.TRIAL_PHASE}>{t('adminDashboard.companyType.trialPhase')}</Option>
      </LrvSelect>
    );
  };

  const renderDatePickers = (props: DropdownProps) => {
    const { className, theme } = props;

    switch (activityType) {
      case 'DAILY':
      default:

        return (
          <>
            <LrvDatePicker
              theme={theme}
              className={className}
              id='from_date_picker'
              title={t('adminDashboard.filters.fromDate')}
              value={moment(dailyFromDate)}
              defaultValue={moment(dailyFromDate)}
              allowClear={false}
              disabledDate={(value) => disabledDateFrom(value, new Date(dailyToDate))}
              placeholder={t('adminDashboard.dateFrom')}
              onChange={(date, dateString) => {
                dispatch(adminDashboardSlice.setDailyFromDate(dateString));
                const params: FilterAdminDashboard = {
                  ...filters,
                  daily: {
                    fromDate: dateString,
                    toDate: dailyToDate,
                  },
                };

                onChangeFilters(params);
              }}
            />

            <LrvDatePicker
              theme={theme}
              className={className}
              id='to_date_picker'
              title={t('adminDashboard.filters.toDate')}
              value={moment(dailyToDate)}
              defaultValue={moment(dailyToDate)}
              allowClear={false}
              disabledDate={(value) => disabledDateTo(value, new Date(dailyFromDate))}
              placeholder={t('adminDashboard.dateTo')}
              onChange={(date, dateString) => {
                dispatch(adminDashboardSlice.setDailyToDate(dateString));
                const params: FilterAdminDashboard = {
                  ...filters,
                  daily: {
                    fromDate: dailyFromDate,
                    toDate: dateString,
                  },
                };

                onChangeFilters(params);
              }}
            />
          </>
        );

      case 'MONTHLY':

        return (
          <>
            <LrvDatePicker
              theme={theme}
              className={className}
              id='from_date_picker'
              title={t('adminDashboard.filters.fromDate')}
              value={moment(monthlyFromDate)}
              defaultValue={moment(monthlyFromDate)}
              allowClear={false}
              disabledDate={(value) => disabledMonthlyDateFrom(value, new Date(monthlyToDate))}
              placeholder={t('adminDashboard.dateFrom')}
              picker='month'
              onChange={(date, dateString) => {
                dispatch(adminDashboardSlice.setMonthlyFromDate(dateString));
                const params: FilterAdminDashboard = {
                  ...filters,
                  monthly: {
                    fromDate: dateString,
                    toDate: monthlyToDate,
                  },
                };

                onChangeFilters(params);
              }}
            />

            <LrvDatePicker
              theme={theme}
              className={className}
              id='to_date_picker'
              title={t('adminDashboard.filters.toDate')}
              value={moment(monthlyToDate)}
              defaultValue={moment(monthlyToDate)}
              allowClear={false}
              disabledDate={(value) => disabledDateTo(value, new Date(monthlyFromDate))}
              placeholder={t('adminDashboard.dateTo')}
              picker='month'
              onChange={(date, dateString) => {
                const value = moment(dateString).endOf('month').format(DATE_FORMATS.YYYY_MM_DD);
                dispatch(adminDashboardSlice.setMonthlyToDate(value));
                const params: FilterAdminDashboard = {
                  ...filters,
                  monthly: {
                    fromDate: monthlyFromDate,
                    toDate: value,
                  },
                };

                onChangeFilters(params);
              }}
            />
          </>
        );

      case 'PER_HOUR':
        return (
          <LrvDatePicker
            theme={theme}
            className={className}
            id='select_day_of_date_picker'
            title={t('adminDashboard.selectDay')}
            allowClear={false}
            disabledDate={disabledRangeDate}
            value={dateSelected === '' ? undefined : moment(dateSelected)}
            placeholder={t('adminDashboard.dateTo')}
            onChange={(date, dateString) => {
              dispatch(adminDashboardSlice.setDateSelected(dateString));
              const params: FilterAdminDashboard = {
                ...filters,
                dateSelected: dateString,
              };

              onChangeFilters(params);
            }}
          />
        );
        break;
    }
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('balances.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(adminDashboardSlice.fetchAppActivityDefault());
              dispatch(adminDashboardSlice.resetAdminDashboardFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderDropdownCompanyType({ theme: 'light' })}
            {renderDropdownActivityType({ theme: 'light' })}
            {renderDatePickers({ theme: 'light' })}
            {renderCheckoutByPhase({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(adminDashboardSlice.fetchAppActivityDefault());
          dispatch(adminDashboardSlice.resetAdminDashboardFilters());
        }}
      />
    );
  };

  const renderDownloadIcon = () => {
    return (
      <div className={styles.container}>
        <IconButton
          iconName='download'
          loading={isDownloadingAnalysisActivity}
          placement='right'
          tooltipText={t('production.download')}
          disabled={activityType === 'MONTHLY'}
          onClick={() => {
            switch (activityType) {
              case 'DAILY': {
                const params = { companyType, fromDate: formatYearMonthDay(dailyFromDate), toDate: formatYearMonthDay(dailyToDate), hoursOffset: getHoursOffset(), activityType, timezone };
                dispatch(downloadAnalysisActivity(params));
                break;
              }

              case 'MONTHLY': {
                const params = { companyType, fromDate: formatYearMonthDay(monthlyFromDate), toDate: formatYearMonthDay(monthlyToDate), hoursOffset: getHoursOffset(), activityType, timezone };
                dispatch(downloadAnalysisActivity(params));
                break;
              }

              case 'PER_HOUR': {
                const params = { companyType, fromDate: formatYearMonthDay(dateSelected), hoursOffset: getHoursOffset(), activityType, timezone };
                dispatch(downloadAnalysisActivity(params));
                break;
              }
            }
          }}
        />
      </div>
    );
  };

  return (
    <Row className={styles.container}>
      <Space className={styles.filters} align='end'>
        {renderDropdownCompanyType({ className: styles.select, theme })}
        {renderDropdownActivityType({ className: styles.select, theme })}
        {renderDatePickers({ className: styles.select, theme })}
        {renderCheckoutByPhase({ className: styles.checkbox, theme })}
        {renderDownloadIcon()}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}
    </Row>
  );
}
