import cx from 'classnames';
import React from 'react';

import { LrvTooltip } from '../LrvTooltip/LrvTooltip';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './TinyCounter.module.scss';

interface Props {
  text: string;
  theme?: 'light' | 'dark';
  title?: string;
  className?: string;
}

export default function TinyCounter (props: Props) {
  const { text, title, className, theme = getCurrentTheme() } = props;

  return (
    <LrvTooltip title={title} themeStyle={theme}>
      <div className={cx(styles.tinyCounter, className)}>
        {text}
      </div>
    </LrvTooltip>
  );
}
