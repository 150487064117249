import React from 'react';
import cx from 'classnames';
import { ButtonProps } from 'antd';

import Icon from '../Icon/Icon';
import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './ExtraActionsButton.module.scss';
import ActionButton from './ActionButton';

interface ExtraActionsButtonProps extends ButtonProps {
  theme?: 'dark' | 'light';
}

export const ExtraActionsButton = (props: ExtraActionsButtonProps) => {
  const {
    theme = getCurrentTheme(),
    className,
    type = 'text',
    icon,
  } = props;

  const isLightTheme = theme === THEME.LIGHT;

  const actionButtonProps: ButtonProps = {
    ...props,
    type,
    className: cx(styles.extraActions, isLightTheme ? styles.extraActionsLight : styles.extraActionsDark, className),
    icon: icon ?? <Icon name='more-2' className={styles.icon} theme={theme} />,
  };

  return (
    <ActionButton
      {...actionButtonProps}
    />
  );
};
