import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { getMonthName } from '../../utils/date';
import { removeSpaces } from '../../utils/strings';
import { Store } from '../../state/store.interfaces';
import { isSuperAdmin, THEME } from '../../config/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import ProgressBar from '../../common/components/ProgressBar/ProgressBar';
import { LrvPagination } from '../../common/components/LrvPagination/LrvPagination';
import { ItemRenderPagination } from '../../common/components/LrvPagination/ItemRenderPagination';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { getUserSession } from '../../utils/userSession';

import Legends from './Legends';
import Spinner from './Spinner';
import styles from './PostpaidBalanceConsumption.module.scss';
import { CompanyBalances, CompanyTooltip } from './interfaces';
import { COLOR_CURRENT_MONTH, getBackgroundColor } from './helpers';
import { fetchPostpaidBalanceUsage, fetchXlsxPaymentsReport, setPostpaidCurrentPage } from './BalanceConsumptionSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function PostpaidBalanceConsumption (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { filters, postpaid } = useSelector((state: Store) => state.balanceConsumption);

  const {
    data: dataBalance,
    months,
    total,
    limit,
    currentPage,
    isLoading,
    isDownloadingBeforeLastMonth,
    isDownloadingLastMonth,
  } = postpaid;

  const {
    sellerId, promoterId,
    showActiveCompanies,
    showInternationalCompanies,
    showTrialPhaseCompanies,
  } = filters;

  const [scroll, setScroll] = useState(0);
  const refGrid = useRef<HTMLDivElement>(null);

  const userSession = getUserSession();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    const params = { page: 0, sellerId, promoterId, active: showActiveCompanies, isInternational: showInternationalCompanies, showTrialPhase: showTrialPhaseCompanies };
    dispatch(fetchPostpaidBalanceUsage(params));
  }, [dispatch, sellerId, promoterId, showActiveCompanies, showTrialPhaseCompanies, showInternationalCompanies]);

  function fillEmptyMonths (companyBalances: CompanyBalances[]) {
    const companyBalancesCopy: CompanyBalances[] = [];

    const monthsWithBalance = companyBalances.map((balance: CompanyBalances) => {
      return balance.month;
    });
    const currentMonths = [months.currentMonth.month, months.lastMonth.month, months.monthBeforeLast.month];

    for (let index = 0; index < currentMonths.length; index++) {
      const month = currentMonths[index];

      if (monthsWithBalance.includes(month.toString())) {
        const index = companyBalances.findIndex(item => item.month === month.toString());
        companyBalancesCopy.push(companyBalances[index]);
      } else {
        const balance: CompanyBalances = {
          _id: `${index}-${month}`,
          month: month.toString(),
          quota: 0,
          quotaUsage: 0,
          bonusQuota: 0,
        };

        companyBalancesCopy.push(balance);
      }
    }

    companyBalancesCopy.reverse();
    return companyBalancesCopy;
  }

  function renderBalanceUsageByCompany (companyBalances: CompanyBalances[]) {
    const listMonth = [];

    for (let index = 0; index < companyBalances.length; index++) {
      const balance = companyBalances[index];

      const quota = balance.quota;
      const quotaUsage = balance.quotaUsage;
      const percentage = quota === 0 ? 0 : quotaUsage * 100 / quota;

      const borderStyle = percentage >= 100 ? '' : styles.border;
      const backgroundColor = balance.month === months.currentMonth.month.toString() ? COLOR_CURRENT_MONTH : getBackgroundColor(percentage);

      const month = (
        <div className={styles.month} key={balance._id + 'progress-bar' + index}>
          <ProgressBar
            classNameContainer={cx(styles.progressBar, isLightTheme ? '' : styles.progressBarDark, borderStyle)}
            backgroundColorLabel={backgroundColor}
            percent={percentage}
          />
        </div>
      );

      listMonth.push(month);
    }

    return listMonth;
  }

  function renderTooltips (companyBalances: CompanyBalances[], companyName: string) {

    const itemCompany: CompanyTooltip = {
      tooltips: [],
      marginTop: -90,
    };

    const currentMonth = new Date().getMonth();

    for (let index = 0; index < companyBalances.length; index++) {
      const balance = companyBalances[index];
      const month = balance.month;
      const companyWithoutSpaces = removeSpaces(companyName).toLowerCase();
      const currentMonthId = month === (currentMonth + 1).toString() ? `current_month_${companyWithoutSpaces}` : '';

      const bonusQuota = balance.bonusQuota ?? 0;
      const quota = balance.quota + bonusQuota;
      const quotaUsage = balance.quotaUsage;
      const percentage = quota === 0 ? 0 : Math.round(quotaUsage * 100 / quota);

      let bonusQuotaLabel;
      if (bonusQuota > 0) {
        itemCompany.marginTop = -100;

        bonusQuotaLabel = (
          <div>
            <div>{t('balances.bonusQuota')}:</div>
            <span>{bonusQuota}</span>
          </div>
        );
      }

      const tooltip = (
        <div key={balance._id + 'tooltip' + index}>
          <div className={cx(styles.gridTooltip, isLightTheme ? styles.gridTooltipLight : styles.gridTooltipDark)}>
            <div>
              <div>{t('balances.consumed')}:</div>
              <div>
                <span id={currentMonthId}>{quotaUsage}</span>
                <span>({percentage}%)</span>
              </div>
            </div>

            <div>
              <div>{t('balances.assigned')}:</div>
              <span>{quota}</span>
            </div>
            {bonusQuotaLabel}
          </div>
        </div>
      );

      itemCompany.tooltips.push(tooltip);
    }

    return itemCompany;
  }

  function renderBalanceUsage () {
    const listPercentage = [];

    for (const key in dataBalance) {
      if (Object.prototype.hasOwnProperty.call(dataBalance, key)) {
        const companyName = key;
        let companyBalances: CompanyBalances[] = dataBalance[key];

        companyBalances = fillEmptyMonths(companyBalances);
        const listMonth = renderBalanceUsageByCompany(companyBalances);
        const companyTooltips = renderTooltips(companyBalances, companyName);

        const companyId = `company_${removeSpaces(companyName).toLowerCase()}`;
        const percentagesId = `percentages_${removeSpaces(companyName).toLowerCase()}`;

        const item = (
          <div className={styles.row} key={companyName}>
            <LrvTooltip title={companyName} placement='topLeft'>
              <div id={companyId} className={styles.company}>
                <LrvText text={companyName} theme={theme} />
              </div>
            </LrvTooltip>

            <div id={percentagesId} className={styles.percentages}>
              <div
                className={styles.months}
                onMouseEnter={() => {
                  const scrollTop = refGrid.current?.scrollTop ?? 0;
                  setScroll(scrollTop);
                }}
              >
                {listMonth}
              </div>

              <div className={styles.tooltip} style={{ marginTop: companyTooltips.marginTop - scroll }}>
                {companyTooltips.tooltips}
              </div>
            </div>
          </div>
        );

        listPercentage.push(item);
      }
    }

    return listPercentage;
  }

  const renderMonthBeforeLast = () => {
    let monthBeforeLast = '';
    if (months.monthBeforeLast.month !== 0 && months.monthBeforeLast.year !== 0) {
      monthBeforeLast = `${getMonthName(months.monthBeforeLast.month.toString())} ${months.monthBeforeLast.year}`;
    }

    return (
      <Space>
        <div className={styles.month}>
          <LrvText text={monthBeforeLast} theme={theme} />
        </div>

        {
          (monthBeforeLast && (isSuperAdmin() || userSession.haveAccessPaymentReport)) &&
          <IconButton
            id='maturations_button'
            loading={isDownloadingBeforeLastMonth}
            className={styles.button}
            iconName='download'
            onClick={() => {
              const data = { year: months.monthBeforeLast.year, month: months.monthBeforeLast.month };
              dispatch(fetchXlsxPaymentsReport({ ...data, monthToDownload: 'beforeLast', payment: 'postpaid' }));
            }}
            tooltipText={t('balances.download', { date: monthBeforeLast })}
          />
        }
      </Space>
    );
  };

  const renderLastMonth = () => {
    let lastMonth = '';
    if (months.lastMonth.month !== 0 && months.lastMonth.year !== 0) {
      lastMonth = `${getMonthName(months.lastMonth.month.toString())} ${months.lastMonth.year}`;
    }

    return (
      <Space>
        <div className={styles.month}>
          <LrvText text={lastMonth} theme={theme} />
        </div>

        {
          (lastMonth && (isSuperAdmin() || userSession.haveAccessPaymentReport)) &&
          <IconButton
            id='maturations_button'
            loading={isDownloadingLastMonth}
            className={styles.button}
            iconName='download'
            onClick={() => {
              const data = { year: months.lastMonth.year, month: months.lastMonth.month };
              dispatch(fetchXlsxPaymentsReport({ ...data, monthToDownload: 'last', payment: 'postpaid' }));
            }}
            tooltipText={t('balances.download', { date: lastMonth })}
          />
        }
      </Space>
    );
  };

  const renderCurrentMonth = () => {
    let currentMonth = '';
    if (months.currentMonth.month !== 0 && months.currentMonth.year !== 0) {
      currentMonth = `${getMonthName(months.currentMonth.month.toString())} ${months.currentMonth.year}`;
    }

    return (
      <div>
        <div className={styles.month}>
          <LrvText text={currentMonth} theme={theme} />
        </div>
      </div>
    );
  };

  function renderBalanceReport () {
    const listPercentage = renderBalanceUsage();

    return (
      <div>
        <div className={styles.container}>
          <div className={styles.months}>
            {renderMonthBeforeLast()}
            {renderLastMonth()}
            {renderCurrentMonth()}
          </div>
        </div>

        <div className={styles.grid} ref={refGrid}>
          {listPercentage}
        </div>
      </div>
    );
  }

  const resetScroll = () => {
    if (refGrid.current?.scrollTop && refGrid.current?.scrollTop > 0) {
      refGrid.current.scrollTop = 0;
    }
  };

  function onChangePage (page: number) {
    dispatch(setPostpaidCurrentPage(page));
    resetScroll();

    const params = { page: page, sellerId, promoterId, active: showActiveCompanies, isInternational: showInternationalCompanies, showTrialPhase: showTrialPhaseCompanies };
    dispatch(fetchPostpaidBalanceUsage(params));
  }

  function renderPagination () {
    return <div className={styles.pagination}>
      <LrvPagination
        defaultPageSize={limit}
        pageSize={limit}
        onChange={onChangePage}
        total={total}
        current={currentPage}
        showSizeChanger={false}
        itemRender={ItemRenderPagination}
        theme={theme}
      />
    </div>;
  }

  const renderSpinner = () => {
    if (isLoading) {
      return (
        <Spinner />
      );
    }

    return null;
  };

  return (
    <div className={styles.postpaid}>
      {renderSpinner()}

      <Legends theme={theme} />
      {renderBalanceReport()}
      {renderPagination()}
    </div>
  );
}
