import i18next from 'i18next';

export const getLanguage = () => {
  let language = i18next.language;

  if (language) {
    language = language.split('-')[0];

    return language;
  }

  language = navigator.language;
  language = language.split('-')[0];

  return language;
};