import moment from 'moment';
import { useEffect } from 'react';
import { capitalize } from 'lodash';
import { Row, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { stockingPhaseTypes } from '../../../config/commons';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { matchNamesWithSpecialCharacters } from '../../../utils/strings';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';
import { openErrorNotification } from '../../../common/notification/Notification';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import IconButton from '../../../common/components/buttons/IconButton';

import styles from './LaboratoryChartFilters.module.scss';
import * as laboratoryChartSlice from './laboratoryChartSlice';
import { disabledMaximunDateTo, disabledMinimunDateTo } from './helpers';

interface Props {
  refFilters: React.RefObject<HTMLDivElement>;
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const LaboratoryChartFilters = (props: Props) => {
  const { refFilters, theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    dataSource,
    filters,
    isDownloadingFile,
    firstStageZoom,
    lastStageZoom,
    units,
  } = useSelector((state: Store) => state.laboratoryChart);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    maximumDate,
    minimumDate,
    parameter,
    scale,
    unitId,
  } = filters;

  const disabledFilters = phaseType === stockingPhaseTypes.LARVAE;

  useEffect(() => {
    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: getUnitPhaseTypeFromStocking(phaseType),
    };
    dispatch(laboratoryChartSlice.setUnitId(''));
    dispatch(laboratoryChartSlice.fetchUnits(params));
  }, [phaseType, selectedCompany]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    const unitsOptions = units.map((item) => {
      return { _id: item._id, value: item._id, label: item.name };
    });
    unitsOptions.unshift({ _id: '', value: '', label: t('geneticsInsights.allUnits') });

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unitId}
        onChange={(value) => {
          dispatch(laboratoryChartSlice.setUnitId(value));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('geneticsInsights.filters.unit')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        <Option key='' value=''>{t('geneticsInsights.allUnits')}</Option>

        {units.map((unit) =>
          <Option key={unit._id} value={unit._id}>{unit.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const renderParameterDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    const juvenileOptions = [
      { id: `parameter_${typeParam.AVG_WEIGHT.toLowerCase()}`, value: typeParam.AVG_WEIGHT, label: capitalize(t('shadedplot.type.avg_weight').toLowerCase()) },
      { id: `parameter_${typeParam.AVG_LENGTH.toLowerCase()}`, value: typeParam.AVG_LENGTH, label: capitalize(t('shadedplot.type.avg_length').toLowerCase()) },
      { id: `parameter_${typeParam.UNIFORMITY.toLowerCase()}`, value: typeParam.UNIFORMITY, label: capitalize(t('shadedplot.type.uniformity').toLowerCase()) },
      { id: `parameter_${typeParam.CV_LENGTH.toLowerCase()}`, value: typeParam.CV_LENGTH, label: capitalize(t('shadedplot.type.cv_length').toLowerCase()) },
    ];

    return (
      <LrvSelect
        id='dropdown_parameter'
        theme={theme}
        className={className}
        value={parameter}
        onChange={(value) => {
          dispatch(laboratoryChartSlice.setParameter(value));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('geneticsInsights.filters.parameter')}
        options={juvenileOptions}
        disabled={disabledFilters}
      />
    );
  };

  const renderScaleDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_scale'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={scale}
        onChange={(value) => {
          dispatch(laboratoryChartSlice.setScale(value));
        }}
        title={t('geneticsInsights.filters.scale')}
        options={[
          { id: `scale_${typeScale.LINEAR.toLowerCase()}`, value: typeScale.LINEAR, label: capitalize(t('shadedplot.scale.linear').toLowerCase()) },
          { id: `scale_${typeScale.LOGARITHMIC.toLowerCase()}`, value: typeScale.LOGARITHMIC, label: capitalize(t('shadedplot.scale.logarithmic').toLowerCase()) },
        ]}
        disabled={disabledFilters}
      />
    );
  };

  const renderMinimumDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='minimunDate_datePicker'
        theme={theme}
        className={className}
        value={moment(minimumDate)}
        defaultValue={moment(minimumDate)}
        allowClear={false}
        disabled={disabledFilters}
        disabledDate={(value) => disabledMinimunDateTo(value, maximumDate)}
        placeholder={t('stockings.growthDelta.dateTo')}
        title={t('geneticsInsights.filters.minimunDate')}
        onChange={(date, dateString) => {
          dispatch(laboratoryChartSlice.setMinimumDate(dateString));
        }}
      />
    );
  };

  const renderMaximumDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='maximunDate_datePicker'
        theme={theme}
        className={className}
        value={moment(maximumDate)}
        defaultValue={moment(maximumDate)}
        allowClear={false}
        disabled={disabledFilters}
        disabledDate={disabledMaximunDateTo}
        placeholder={t('stockings.growthDelta.dateTo')}
        title={t('geneticsInsights.filters.maximunDate')}
        onChange={(date, dateString) => {
          const minimumDate = moment(dateString).subtract(2, 'months').format();
          dispatch(laboratoryChartSlice.setMinimumDate(minimumDate));
          dispatch(laboratoryChartSlice.setMaximumDate(dateString));
        }}
      />
    );
  };

  const renderButtonGeneratePdf = () => {
    return (
      <IconButton
        className={styles.generatePdf}
        loading={isDownloadingFile}
        onClick={generatePdf}
        iconName='download'
        disabled={disabledFilters}
        placement='left'
        tooltipText={t('shadedplot.generate')}
      />
    );
  };

  const generatePdf = () => {
    const laboratories = matchNamesWithSpecialCharacters(dataSource);

    if (laboratories === '') {
      openErrorNotification(t('laboratory.noLegendError'));
      return;
    }
  
    const unitName = units.find((item) => item._id === unitId)?.name || '';

    const params = {
      companyId: selectedCompany._id,
      parameter,
      scale,
      phaseType,
      laboratories,
      minimumDate,
      maximumDate,
      companyName: selectedCompany.name,
      firstStage: firstStageZoom,
      lastStage: lastStageZoom,
      unitId,
      unitName,
    };

    dispatch(laboratoryChartSlice.fetchUrlLaboratoryComparisonPdf(params));
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<LrvText className={styles.title} theme='light' text={t('laboratory.title')} />}
          cleanButtonProps={{
            onClick: () => {
              dispatch(laboratoryChartSlice.resetLaboratoryFilters({ company: selectedCompany, phaseType }));
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderUnitsDropdown({ theme: 'light' })}
            {renderParameterDropdown({ theme: 'light' })}
            {renderScaleDropdown({ theme: 'light' })}
            {renderMinimumDatePicker({ theme: 'light' })}
            {renderMaximumDatePicker({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(laboratoryChartSlice.resetLaboratoryFilters({ company: selectedCompany, phaseType }))}
        disabled={disabledFilters}
      />
    );
  };

  return (
    <Row className={styles.container} ref={refFilters}>
      <Space className={styles.filters} align='end'>
        {renderUnitsDropdown({ className: styles.select, theme })}
        {renderParameterDropdown({ className: styles.select, theme })}
        {renderScaleDropdown({ className: styles.select, theme })}
        {renderMinimumDatePicker({ className: styles.select, theme })}
        {renderMaximumDatePicker({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}

      <Row className={styles.options}>
        {renderButtonGeneratePdf()}
      </Row>
    </Row>
  );
};
