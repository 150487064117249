import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';

import styles from './ParameterEmpty.module.scss';
import { existsMinimumDataParameters } from './helpers';

export const ParameterEmpty = () => {
  const [t] = useTranslation();
  const theme = getCurrentTheme();

  const {
    unitId,
    moduleId,
    isLoading,
    stockingParameterGraphs,
    selectedParameterName,
  } = useSelector((state: Store) => state.parameterState);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  const renderEmptyComponente = (label: string) => {
    return (
      <div className={styles.center} >
        <LrvEmpty description={label} theme={theme} />
      </div>
    );
  };

  if (!unitId) {
    return renderEmptyComponente(t('parameterStateGraph.selectUnit'));
  }

  if (!moduleId) {
    return renderEmptyComponente(t('parameterStateGraph.selectModule'));
  }

  if (!stockingParameterGraphs.length) {
    return renderEmptyComponente(t('parameterStateGraph.empty'));
  }

  const existsMinimumData = existsMinimumDataParameters({ stockingParameterGraphs, selectedParameterName });
  if (!existsMinimumData) {
    return renderEmptyComponente(t('parameterStateGraph.empty'));
  }

  return null;
};
