import cx from 'classnames';
import { Avatar } from 'antd';

import { AdultIcon } from '../Icon/AdultIcon';
import { LarvaeIcon } from '../Icon/LarvaeIcon';
import { JuvenileIcon } from '../Icon/JuvenileIcon';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import Icon from '../Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './LrvAvatarPhase.module.scss';

export interface LrvAvatarPhaseProps {
  onClick?: () => void;
  phaseType?: string;
  avatarSize?: number;
  iconSize?: number;
  showFilterIcon?: boolean;
}

export const LrvAvatarPhase = (props: LrvAvatarPhaseProps) => {
  const {
    avatarSize = 32,
    iconSize = 24,
    onClick,
    phaseType,
    showFilterIcon = false,
  } = props;
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const renderIcon = () => {
    if (showFilterIcon) {
      return (
        <div className={styles.center}>
          <Icon name='settings-4' className={styles.icon} />
        </div>
      );
    }

    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
      default:
        return (
          <div className={styles.center}>
            <LarvaeIcon width={iconSize} height={iconSize} />
          </div>
        );

      case stockingPhaseTypes.JUVENILE:
        return (
          <div className={styles.center}>
            <JuvenileIcon size={iconSize} />
          </div>
        );

      case stockingPhaseTypes.ADULT:
        return (
          <div className={styles.center}>
            <AdultIcon width={iconSize} height={iconSize} />
          </div>
        );
    }
  };

  const getClassNameAvatar = () => {
    if (showFilterIcon) {
      return cx(styles.avatar, isLightTheme ? styles.btnLight : styles.btnDark);
    }

    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
      default:
        return cx(styles.avatar, styles.larvaeAvatar);

      case stockingPhaseTypes.JUVENILE:
        return cx(styles.avatar, styles.juvenileAvatar);

      case stockingPhaseTypes.ADULT:
        return cx(styles.avatar, styles.growOutAvatar);
    }
  };

  return (
    <Avatar
      className={getClassNameAvatar()}
      size={avatarSize}
      icon={renderIcon()}
      onClick={onClick}
    />
  );
};
