import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon/Icon';
import { LrvText } from '../LrvText/LrvText';
import { THEME } from '../../../config/commons';
import { LrvResult } from '../LrvResult/LrvResult';
import { ReactComponent as Logo } from '../../../assets/larvia-dark.svg';
import { ReactComponent as LogoLight } from '../../../assets/larvia-light.svg';

import styles from './ResultAnalysis.module.scss';

interface Props {
  id: string;
  message: string;
  theme?: 'dark' | 'light';
}

export default function ResultAnalysis (props: Props) {
  const { id, message, theme = 'dark' } = props;
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <div id={id} className={styles.showResult}>
      <LrvResult
        icon={theme === THEME.DARK ? <Logo /> : <LogoLight />}
        title={<div className={styles.message}>
          <Icon className={styles.icon} name='close-circle' />
          <LrvText text={message} theme={theme} />
        </div>}
      />
      <div className={styles.centerButton}>
        <Button type='primary' onClick={() => history.push('/login')}>
          {t('share.errors.home').toUpperCase()}
        </Button>
      </div>
    </div>
  );
}
