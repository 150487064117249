import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import L, { Icon, LeafletMouseEvent } from 'leaflet';
import { Marker, Popup, useMapEvents } from 'react-leaflet';

import marker from '../../../assets/marker.png';
import { containerTypes } from '../../../config/commons';

import { Container, Unit } from './interfaces';
import styles from './LocationMarker.module.scss';

const markerIcon = new Icon({
  iconUrl: marker,
  iconSize: [24, 24],
  iconAnchor: [12, 24],
  popupAnchor: [1, -24],
});

interface Props {
  units: Unit[];
  unitId: string;
  container: Container;
  unlocatedContainer?: Container;
  handleNewMarker: (props: { container: Container; }) => void;
  handleMoveMarker: (props: { container: Container; }) => void;
}

export const LocationMarker = (props: Props) => {
  const { container, units, unitId, handleNewMarker, handleMoveMarker, unlocatedContainer } = props;

  const [t] = useTranslation();
  const markerRef = useRef<L.Marker>(null);

  const handleMouseOver = () => {
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
    }
  };

  const handleMouseOut = () => {
    const marker = markerRef.current;
    if (marker) {
      marker.closePopup();
    }
  };

  const containerType = container.type || containerTypes.TANK;
  const unitPhaseType = units.find((unit) => unit._id === unitId)?.phaseType;

  useMapEvents({
    click (e: LeafletMouseEvent) {
      if (!unlocatedContainer?._id) {
        return;
      }

      unlocatedContainer.latitude = e.latlng.lat;
      unlocatedContainer.longitude = e.latlng.lng;
      unlocatedContainer.isLocationUpdated = true;

      handleNewMarker({ container: unlocatedContainer });
    },
  });

  return (
    <Marker
      position={[container?.latitude || 0, container?.longitude || 0]}
      icon={markerIcon}
      ref={markerRef}
      draggable={true}
      eventHandlers={{
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
        dragend: (event) => {
          const { lat, lng } = event.target.getLatLng();
          container.latitude = lat;
          container.longitude = lng;
          container.isLocationUpdated = true;
          handleMoveMarker({ container });
        },
      }}
    >
      <Popup className={styles.popup}>
        <div className={styles.content}>
          {t(`campus.containerTypes.${containerType}`)}: <strong> {container.name} </strong> <br />
          {t('campus.volume')}: <strong> {container.volume} ({t(`campus.prefixUnitOfMeasurement.${unitPhaseType}.${containerType}`)}) </strong>
        </div>
      </Popup>
    </Marker>
  );
};
