import cx from 'classnames';
import { useRef } from 'react';
import { List, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { LrvList } from '../../../common/components/LrvList/LrvList';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { Container } from './interfaces';
import styles from './Containers.module.scss';
import * as containerMapSlice from './containerMapSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const Containers = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters } = useSelector((state: Store) => state.containerMap);

  const { containers, selectedContainer } = filters;
  const listContainerRef = useRef<HTMLDivElement>(null);
  const isLightTheme = theme === THEME.LIGHT;

  const renderTitleDropdownContainer = () => {
    return (
      <div className={isLightTheme ? styles.dropdownContainerLight : styles.dropdownContainerDark}>
        <div className={styles.tanksTitle}>
          <div className={styles.title}>
            {t('containerMap.unlocatedContainers')}
          </div>
        </div>
      </div>
    );
  };

  const renderItemDropdownContainer = (container: Container) => {
    return (
      <List.Item
        className={cx(styles.itemList, container._id === selectedContainer.unlocated?._id ? styles.tankSelected : styles.tank)}
        title=''
      >
        <span>{container.name}</span>

        <ActionButton
          id='btn_add_location'
          theme={theme}
          type='ghost'
          className={styles.btnAddLocation}
          onClick={() => {
            dispatch(containerMapSlice.setSelectedUnLocatedContainer(container));
            openSuccessNotification(t('containerMap.addLocation'));
          }}
        >
          {t('containerMap.locate')}
        </ActionButton>
      </List.Item >
    );
  };

  const renderDropdownContainer = () => {
    return (
      <LrvList
        id='list'
        ref={listContainerRef}
        title={renderTitleDropdownContainer()}
        size='small'
        theme={theme}
        className={cx(styles.tanks, isLightTheme ? styles.tanksLight : styles.tanksDark)}
        bordered
        dataSource={containers.unlocated}
        rowKey={(container: Container) => container._id}
        renderItem={renderItemDropdownContainer}
      />
    );
  };

  return (
    <Space direction='vertical' className={styles.container}>
      {renderDropdownContainer()}
    </Space>
  );
};
