import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { LrvPopover } from './LrvPopover';

export default function PasswordPolicy () {
  const [t] = useTranslation();

  const content = (
    <div style={{ marginLeft: -10 }}>
      <ul>
        <li>{t('password.reset.policy1')}</li>
        <li>{t('password.reset.policy2')}</li>
        <li>{t('password.reset.policy3')}</li>
      </ul>
    </div>
  );

  return (
    <LrvPopover content={content} title={t('password.reset.passwordPolicy')}>
      <QuestionCircleOutlined />
    </LrvPopover>
  );
}
