import i18next from 'i18next';

import { ReferenceCurves } from '../Units/interfaces';
import { referenceCurveMaxValues, roundTwoDecimals, stockingPhaseTypes } from '../../config/commons';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';

interface ReferenceCurve {
  key: number;
  stage: number | undefined;
  mean: number | undefined;
  range: number | undefined;
}

const getDefaultReferenceValues = ({ key, stage, mean, range }: ReferenceCurve) => ({
  key: key || 0,
  stage: stage || 0,
  mean: mean || 0,
  range: range || 0
});

const divideAverage = (props: { mean?: number, divider: number }) => {
  const { mean, divider } = props;
  const value = mean ? mean / divider : 0;
  return roundTwoDecimals(value);
};

const multiplyMean = (props: { mean?: number, multiplier: number }) => {
  const { mean, multiplier } = props;
  const value = mean ? mean * multiplier : 0;
  return roundTwoDecimals(value);
};

export const getDividedReferences = (props: { referenceCurves: ReferenceCurves }) => {
  const { referenceCurves } = props;

  if (referenceCurves.phaseType === stockingPhaseTypes.LARVAE) {
    return referenceCurves;
  }

  if (referenceCurves.type === typeParam.AVG_WEIGHT) {
    referenceCurves.values = referenceCurves.values.map(({ key, stage, mean, range }) => getDefaultReferenceValues({ key, stage, mean: divideAverage({ mean, divider: 1000 }), range }));
    return referenceCurves;
  }

  if (referenceCurves.type === typeParam.AVG_LENGTH) {
    referenceCurves.values = referenceCurves.values.map(({ key, stage, mean, range }) => getDefaultReferenceValues({ key, stage, mean: divideAverage({ mean, divider: 10 }), range }));
    return referenceCurves;
  }

  return referenceCurves;
};

export const getMultipliedReferences = (props: { referenceCurves: ReferenceCurve[]; phaseType: string; parameter: string }) => {
  const { referenceCurves, phaseType, parameter } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return referenceCurves.map(getDefaultReferenceValues);
  }

  if (parameter === typeParam.AVG_WEIGHT) {
    return referenceCurves.map(({ key, stage, mean, range }) => getDefaultReferenceValues({ key, stage, mean: multiplyMean({ mean, multiplier: 1000 }), range }));
  }

  if (parameter === typeParam.AVG_LENGTH) {
    return referenceCurves.map(({ key, stage, mean, range }) => getDefaultReferenceValues({ key, stage, mean: multiplyMean({ mean, multiplier: 10 }), range }));
  }

  return referenceCurves.map(getDefaultReferenceValues);
};

export const getXaxisLabel = (phaseType: string) => {
  return phaseType === stockingPhaseTypes.LARVAE ? i18next.t('referenceCurve.stage') : i18next.t('referenceCurve.day');
};

export const getDayTitle = (phaseType: string) => {
  return phaseType === stockingPhaseTypes.LARVAE ? i18next.t('references.dayDescription.1.stage') : i18next.t('references.dayDescription.1.day');
};

export const isValidMean = (props: { mean?: number; newPhaseType: string; newParameter: string }) => {
  const { mean, newPhaseType, newParameter } = props;

  if (!mean || !newParameter || !newPhaseType) {
    return true;
  }

  switch (newPhaseType) {
    case stockingPhaseTypes.LARVAE: {
      switch (newParameter) {
        case typeParam.PLG:
          return referenceCurveMaxValues.LARVAE.PLG >= mean;

        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.LARVAE.AVERAGE_WEIGHT >= mean;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.LARVAE.AVERAGE_LENGHT >= mean;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.LARVAE.UNIFORMITY >= mean;
      }

      break;
    }

    case stockingPhaseTypes.JUVENILE: {
      switch (newParameter) {
        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.JUVENILE.AVERAGE_WEIGHT >= mean;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.JUVENILE.AVERAGE_LENGHT >= mean;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.JUVENILE.UNIFORMITY >= mean;
      }

      break;
    }

    case stockingPhaseTypes.ADULT: {
      switch (newParameter) {
        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.GROW_OUT.AVERAGE_WEIGHT >= mean;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.GROW_OUT.AVERAGE_LENGHT >= mean;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.GROW_OUT.UNIFORMITY >= mean;
      }

      break;
    }
  }

  return false;
};

export const maxMeanValue = (props: { mean?: number; newPhaseType: string; newParameter: string }) => {
  const { mean, newPhaseType, newParameter } = props;

  if (!mean || !newParameter || !newPhaseType) {
    return 0;
  }

  switch (newPhaseType) {
    case stockingPhaseTypes.LARVAE: {
      switch (newParameter) {
        case typeParam.PLG:
          return referenceCurveMaxValues.LARVAE.PLG;

        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.LARVAE.AVERAGE_WEIGHT;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.LARVAE.AVERAGE_LENGHT;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.LARVAE.UNIFORMITY;
      }

      break;
    }

    case stockingPhaseTypes.JUVENILE: {
      switch (newParameter) {
        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.JUVENILE.AVERAGE_WEIGHT;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.JUVENILE.AVERAGE_LENGHT;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.JUVENILE.UNIFORMITY;
      }

      break;
    }

    case stockingPhaseTypes.ADULT: {
      switch (newParameter) {
        case typeParam.AVG_WEIGHT:
          return referenceCurveMaxValues.GROW_OUT.AVERAGE_WEIGHT;

        case typeParam.AVG_LENGTH:
          return referenceCurveMaxValues.GROW_OUT.AVERAGE_LENGHT;

        case typeParam.UNIFORMITY:
          return referenceCurveMaxValues.GROW_OUT.UNIFORMITY;
      }

      break;
    }
  }

  return 0;
};

export const areInOrderAndUnique = (arr: number[]): boolean => {
  for (let i = 0; i < arr.length - 1; i++) {
    if (arr[i] > arr[i + 1]) {
      return false;
    }
  }

  const uniqueSet = new Set<number>();

  for (const num of arr) {
    if (uniqueSet.has(num)) {
      return false;
    }
    uniqueSet.add(num);
  }

  return true;
};

export const isValidDay = (props: { referenceCurves: ReferenceCurve[]; day?: number; index: number; }) => {
  const { day, index, referenceCurves } = props;

  if (!day) {
    return true;
  }

  const days = referenceCurves.filter((_, i) => i < index && _.stage !== undefined).map((referenceCurve) => referenceCurve.stage || 0);
  if (days.includes(day)) {
    return false;
  }

  days.push(day);
  return areInOrderAndUnique(days);
};
