import i18next from 'i18next';
import { CONFLICT } from 'http-status-codes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GenericParam } from '../../../common/interfaces/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { ReferenceCurves, ReferenceCurvesDto } from '../../Units/interfaces';
import { getReferenceCurveDataPayload } from '../../../helpers/reference-curve';
import { REFERENCE_CURVES_BY_FILTERS_URL, REFERENCE_CURVES_URL } from '../../../config/config.api';
import { openErrorNotification, openSuccessNotification } from '../../../common/notification/Notification';

import { CompanyReferenceCurvesState } from './interfaces';

const initialState: CompanyReferenceCurvesState = {
  phaseType: '',
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  showModal: false,
  referenceCurves: [],
  referenceCurveSelected: {
    _id: '',
    campusId: '',
    companyId: '',
    name: '',
    phaseType: '',
    values: [],
    createdAt: '',
    updatedAt: '',
    type: '',
    active: false,
  },
};

export const companyReferenceCurvesSlice = createSlice({
  initialState,
  name: 'companyReferenceCurves',
  reducers: {
    setIsLoading: (state: CompanyReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCreating: (state: CompanyReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setIsUpdating: (state: CompanyReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setReferenceCurves: (state: CompanyReferenceCurvesState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves = action.payload;
    },
    setSelectedReferenceCurve: (state: CompanyReferenceCurvesState, action: PayloadAction<ReferenceCurves>) => {
      state.referenceCurveSelected = action.payload;
    },
    setPhaseType: (state: CompanyReferenceCurvesState, action: PayloadAction<string>) => {
      state.phaseType = action.payload;
    },
    setShowModal: (state: CompanyReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const { setIsLoading, setIsCreating, setIsUpdating, setReferenceCurves, setSelectedReferenceCurve, setPhaseType, setShowModal } = companyReferenceCurvesSlice.actions;

export default companyReferenceCurvesSlice.reducer;

export const fetchCompanyReferenceCurves = (props: { companyId: string; phaseType: string }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  dispatch(setIsLoading(true));

  const params: GenericParam = {
    companyId,
    phaseType: phaseType || undefined,
  };

  try {
    const response = await axios.get(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
  dispatch(setIsLoading(false));
};

export const createCompanyReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; phaseType: string }) => async (dispatch: Function) => {
  const { referenceCurve, phaseType } = props;

  dispatch(setIsCreating(true));
  const data = getReferenceCurveDataPayload(referenceCurve);

  try {
    await axios.post(REFERENCE_CURVES_URL, data);
    dispatch(setShowModal(false));
    openSuccessNotification(i18next.t('referenceCurve.created'));
    dispatch(fetchCompanyReferenceCurves({ companyId: data.companyId, phaseType }));
  } catch (error) {
    console.log(error?.response);

    const code = error?.response?.data?.code;
    if (code === CONFLICT) {
      openErrorNotification(i18next.t('references.error.companyMaxDocs'));
      dispatch(setIsCreating(false));
      return;
    }
    openErrorNotification(i18next.t('references.error.unknownError'));
  }
  dispatch(setIsCreating(false));
};

export const updateCompanyReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; phaseType: string }) => async (dispatch: Function) => {
  const { referenceCurve, phaseType } = props;

  dispatch(setIsUpdating(true));
  const url = `${REFERENCE_CURVES_URL}/${referenceCurve._id}`;
  const data = getReferenceCurveDataPayload(referenceCurve);

  try {
    await axios.patch(url, data);
    openSuccessNotification(i18next.t('referenceCurve.updated'));
    dispatch(fetchCompanyReferenceCurves({ companyId: referenceCurve.companyId, phaseType }));
    dispatch(setSelectedReferenceCurve({} as ReferenceCurves));
    dispatch(setShowModal(false));
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsUpdating(false));
};

export const enableReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; phaseType: string }) => async (dispatch: Function) => {
  const { referenceCurve, phaseType } = props;

  const url = `${REFERENCE_CURVES_URL}/${referenceCurve._id}`;
  const data = {
    active: referenceCurve.active,
  };

  try {
    await axios.patch(url, data);
    openSuccessNotification(i18next.t('referenceCurve.updated'));
    dispatch(fetchCompanyReferenceCurves({ companyId: referenceCurve.companyId, phaseType }));
    dispatch(setSelectedReferenceCurve({} as ReferenceCurves));
  } catch (e) {
    console.log(e?.response);
  }
};
