import { Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


import { THEME } from '../../config/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvPassword } from '../../common/components/LrvPassword/LrvPassword';
import { validateString, validatePasswordPolicy, validateConfirmPassword } from '../../utils/validations';
import ActionButton from '../../common/components/buttons/ActionButton';

import { updatePassword } from './settingsSlice';
import styles from './TabFormEdit.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabFormChangePassword (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();
  const [formPassword] = Form.useForm();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabledButtonPassword, setDisabledButtonPassword] = useState(true);

  const isLightTheme = theme === THEME.LIGHT;

  return (
    <LrvForm
      id='formPassword'
      name='formPassword'
      form={formPassword}
      layout='vertical'
      theme={theme}
      onFinish={() => {
        if (currentPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
          const password = { currentPassword: currentPassword, newPassword: newPassword };
          updatePassword(password);
        }
      }}
      onFieldsChange={() =>
        setDisabledButtonPassword(
          !formPassword.isFieldsTouched(true) ||
          formPassword.getFieldsError().filter(({ errors }) => errors.length).length > 0
        )
      }
    >
      <Form.Item>
        <div>
          <LrvText theme={theme} text={t('share.publicLink')} />
          <br /><br />
          <ul className={isLightTheme ? styles.listLight : ''}>
            <li>
              <LrvText theme={theme} text={t('password.reset.policy1')} />
            </li>
            <li>
              <LrvText theme={theme} text={t('password.reset.policy2')} />
            </li>
            <li>
              <LrvText theme={theme} text={t('password.reset.policy3')} />
            </li>
          </ul>
        </div>
      </Form.Item>

      <Form.Item
        name='currentPassword'
        label={t('users.currentPassword')}
        required
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvPassword
          value={currentPassword}
          theme={theme}
          onChange={(e) => {
            const value = e.target.value;
            setCurrentPassword(value);

            formPassword.setFieldsValue({
              currentPassword: value,
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name='newPassword'
        label={t('users.newPassword')}
        required
        rules={[() => ({ validator (rule, value) { return validatePasswordPolicy(value); } })]}
      >
        <LrvPassword
          value={newPassword}
          theme={theme}
          onChange={(e) => {
            const value = e.target.value;
            setNewPassword(value);

            formPassword.setFieldsValue({
              newPassword: value,
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name='confirmPassword'
        label={t('users.confirmPassword')}
        required
        dependencies={['newPassword']}
        rules={[({ getFieldValue }) => ({ validator (rule, value) { return validateConfirmPassword(getFieldValue, value); } })]}
      >
        <LrvPassword
          name='confirmPassword'
          value={confirmPassword}
          theme={theme}
          onChange={(e) => {
            const value = e.target.value;
            setConfirmPassword(value);

            formPassword.setFieldsValue({
              confirmPassword: value,
            });
          }}
        />
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight}>
          <ActionButton id='submit_password_button' type='primary' htmlType='submit' disabled={disabledButtonPassword}>
            {t('users.save')}
          </ActionButton>
        </div>
      </Form.Item>
    </LrvForm>
  );
}
