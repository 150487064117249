import React from 'react';
import cx from 'classnames';
import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


import Icon from '../../../common/components/Icon/Icon';
import { Store } from '../../../state/store.interfaces';

import styles from './PopoverItem.module.scss';

interface PopoverItemProps {
  id?: string;
  key: React.Key;
  type?: 'navigation' | 'action' | 'none';
  path?: string;
  isSelected?: boolean;
  icon: string;
  title: string;
  onClickItem?: () => void;
  extra?: React.ReactNode;
}

const PopoverItem = (props: PopoverItemProps) => {
  const { id, key, type = 'none', path, icon, title, isSelected = false, onClickItem, extra } = props;
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  if (type === 'navigation') {
    return (
      <div
        id={id ?? icon}
        key={key}
        className={cx(styles.popoverItem, isSelected ? styles.popoverItemSelected : '')}
      >
        {/* // pointerEvents: 'none' prevent that the onBoarding breaks */}
        <Link style={{ pointerEvents: isRunningOnboarding ? 'none' : undefined }} to={path ?? ''} onClick={() => {
          if (isRunningOnboarding || !onClickItem) { // This prevents that the onBoarding breaks
            return;
          }
          onClickItem();
        }}
        >
          <Row justify='space-between' align='middle'>
            <Col className={styles.col}>
              <Icon className={styles.popoverMenuIcon} name={icon} />
            </Col>
            <Col className={styles.col} span={20}>
              <div className={styles.popoverMenuTitle}>{title}</div>
            </Col>
          </Row>
        </Link>
      </div>
    );
  }

  if (type === 'none') {
    return (
      <div
        id={id ?? icon}
        key={key}
        className={cx(styles.popoverItem, isSelected ? styles.popoverItemSelected : '')}
      >
        <Row justify='start' align='middle'>
          <Col className={styles.col} span={4}>
            <Icon className={styles.popoverMenuIcon} name={icon} />
          </Col>
          <Col className={styles.col}>
            <div className={styles.popoverMenuTitle}>{title}</div>
          </Col>
          <Col span={10}>
            {extra}
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Button
      id={id ?? icon}
      key={key}
      className={styles.popoverItemButton}
      type='text' onClick={() => {
        if (isRunningOnboarding || !onClickItem) { // This prevents that the onBoarding breaks
          return;
        }
        onClickItem();
      }}
    >
      <Row justify='space-between' align='middle'>
        <Col className={styles.col}>
          <Icon className={styles.popoverMenuIcon} name={icon} />
        </Col>
        <Col className={styles.col} span={20}>
          <div className={styles.popoverMenuTitle}>{title}</div>
        </Col>
      </Row>
    </Button>
  );
};

export default PopoverItem;
