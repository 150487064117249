import { Banner } from '../../../pages/Settings/interfaces';

export const areBannersEqual = (oldAlertMessage: Banner, newAlertMessage: Banner) => {
  return (
    arraysAreEqual(oldAlertMessage.companyIds, newAlertMessage.companyIds) &&
    oldAlertMessage.en === newAlertMessage.en &&
    oldAlertMessage.es === newAlertMessage.es &&
    oldAlertMessage.pt === newAlertMessage.pt &&
    (oldAlertMessage.alertClosed || oldAlertMessage.showAlert === newAlertMessage.showAlert) &&
    oldAlertMessage.persistentMode === newAlertMessage.persistentMode &&
    (oldAlertMessage.endDate || '') === (newAlertMessage.endDate || '') &&
    oldAlertMessage.backgroundColor === newAlertMessage.backgroundColor &&
    oldAlertMessage.icon === newAlertMessage.icon
  );
};

export const arraysAreEqual = (arr1: string[], arr2: string[]) => {
  return arr1 && arr2 && arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
};
