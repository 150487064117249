import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../../../state/store.interfaces';
import { LrvText } from '../../../../../common/components/LrvText/LrvText';

import styles from './Subtitle.module.scss';

interface Props {
  addAnimal?: () => void;
  addDisease?: () => void;
  changeGroupAnimal?: () => void;
  undoChangeGroupAnimal?: () => void;
  undoRemovedAnimal?: () => void;
  theme?: 'dark' | 'light';
}

export default function Subtitle (props: Props) {
  const {
    addAnimal,
    addDisease,
    changeGroupAnimal,
    undoChangeGroupAnimal,
    undoRemovedAnimal,
    theme
  } = props;

  const [t] = useTranslation();

  const {
    animalSelected,
    newAnimal,
    sampleAnimalList,
    disease,
  } = useSelector((state: Store) => state.sample);

  const onPressDoneButton = () => {
    if (newAnimal) {
      addAnimal && addAnimal();
      return;
    }

    if (!animalSelected) {
      return;
    }

    if (disease) {
      addDisease && addDisease();
      return;
    }

    if (animalSelected.removed) {
      undoRemovedAnimal && undoRemovedAnimal();
      return;
    }

    if (animalSelected.previousGroup) {
      undoChangeGroupAnimal && undoChangeGroupAnimal();
      return;
    }

    changeGroupAnimal && changeGroupAnimal();
  };

  return (
    <div className={styles.subtitle}>
      <LrvText className={styles.actions} theme={theme} text={`${t('detail.manageAnimals.actions')}: ${sampleAnimalList.length}`} />

      <div
        className={styles.doneButton}
        onClick={() => onPressDoneButton()}
      >
        {t('detail.manageAnimals.done')}
      </div>
    </div>
  );
}
