import moment from 'moment';

import { INITIAL_DATE } from '../../../config/commons';
import { getCurrentElementHeight } from '../../../utils/dimensions';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
  slider: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, slider } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const labelTopHeight = 24;
  const extraHeight = 64;

  const value = getCurrentElementHeight(filters) + getCurrentElementHeight(slider) + axisXHeight + headerHeight + extraHeight + labelTopHeight;
  return value;
};

const getMaturationPanelWidth = (element: React.RefObject<HTMLDivElement>) => {
  return element.current?.offsetWidth || 0;
};

interface WidthProps {
  maturationPanel: React.RefObject<HTMLDivElement>;
}

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { maturationPanel } = props;
  const extraWidth = 40;

  if (window.innerWidth <= 950) {
    return getMaturationPanelWidth(maturationPanel) + extraWidth;
  }

  if (window.innerWidth <= 1420) {
    return getMaturationPanelWidth(maturationPanel) + extraWidth + 80;
  }

  return getMaturationPanelWidth(maturationPanel) + 264 + extraWidth;
};

export const getMaximumDate = () => {
  return moment().format();
};

export const getMinimumDate = () => {
  return moment().subtract(2, 'months').format();
};

export const disabledMinimunDateTo = (current: moment.Moment, maximumDate: string) => {
  const start = moment(maximumDate).subtract(6, 'months');
  const end = moment(maximumDate).subtract(1, 'months');
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledMaximunDateTo = (current: moment.Moment) => {
  const start = moment(INITIAL_DATE);
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const initialGeneticsFiltersState = {
  maximumDate: getMaximumDate(),
  minimumDate: getMinimumDate(),
  parameter: typeParam.AVG_WEIGHT,
  scale: typeScale.LINEAR,
  unit: '',
};
