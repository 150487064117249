import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../utils/sort';
import { tankStatuses } from '../../config/commons';
import { downloadFile } from '../../utils/download';
import { TANKS_QR_PDF_URL, TANKS_URL } from '../../config/config.api';
import { ApiClient, axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';

import { CreateTank, Tank, TankQR, TanksState } from './interfaces';

const apiClient: ApiClient = new ApiClient(false);

const initialState: TanksState = {
  tanksQR: [],
  tanks: [],
  selectedTank: {
    _id: '',
    name: '',
    code: '',
    type: '',
    volume: 0,
    status: tankStatuses.AVAILABLE,
    moduleId: '',
    campusId: '',
  },
  isTankLoading: false,
  isTanksLoading: false,
  isTanksQrLoading: false,
  isDownloadingTanksQr: false,
  addingLocations: false,
};

export const tanksSlice = createSlice({
  name: 'tanks',
  initialState,
  reducers: {
    setTanks: (state: TanksState, action: PayloadAction<Tank[]>) => {
      state.tanks = action.payload;
    },
    setTanksQr: (state: TanksState, action: PayloadAction<TankQR[]>) => {
      state.tanksQR = action.payload;
    },
    setSelectedTank: (state: TanksState, action: PayloadAction<Tank>) => {
      state.selectedTank._id = action.payload._id;
      state.selectedTank.name = action.payload.name;
      state.selectedTank.code = action.payload.code;
      state.selectedTank.volume = action.payload.volume;
      state.selectedTank.status = action.payload.status;
      state.selectedTank.moduleId = action.payload.moduleId;
      state.selectedTank.campusId = action.payload.campusId;
      state.selectedTank.type = action.payload.type;
      state.selectedTank.latitude = action.payload.latitude;
      state.selectedTank.longitude = action.payload.longitude;
    },
    setIsTanksLoading: (state: TanksState, action: PayloadAction<boolean>) => {
      state.isTanksLoading = action.payload;
    },
    setIsTankLoading: (state: TanksState, action: PayloadAction<boolean>) => {
      state.isTankLoading = action.payload;
    },
    setIsTanksQrLoading: (state: TanksState, action: PayloadAction<boolean>) => {
      state.isTanksQrLoading = action.payload;
    },
    setIsDownloadingTanksQr: (state: TanksState, action: PayloadAction<boolean>) => {
      state.isDownloadingTanksQr = action.payload;
    },
    setAddingLocations: (state: TanksState, action: PayloadAction<boolean>) => {
      state.addingLocations = action.payload;
    },
  }
});

export const {
  setTanks,
  setSelectedTank,
  setIsTanksLoading,
  setIsTankLoading,
  setTanksQr,
  setIsTanksQrLoading,
  setIsDownloadingTanksQr,
  setAddingLocations,
} = tanksSlice.actions;

export const fetchTanks = (moduleId: string) => async (dispatch: Function) => {
  dispatch(setIsTanksLoading(true));
  const requestParams = {
    $limit: -1,
    moduleId,
    active: true,
    '$sort[name]': 1
  };

  try {
    const response = await axios.get<Tank[]>(TANKS_URL, { params: requestParams });
    response.data.sort(sortByName);
    dispatch(setTanks(response.data));
    dispatch(setIsTanksLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchTank = (tankId: string) => async (dispatch: Function) => {
  dispatch(setIsTankLoading(true));

  try {
    const response = await axios.get<Tank>(`${TANKS_URL}/${tankId}`);
    dispatch(setSelectedTank(response.data));
    dispatch(setIsTankLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createTank = (tankData: { name: string; code: string; volume?: number; moduleId: string; campusId: string; type: string }) => async (dispatch: Function) => {
  try {
    if (tankData.volume === 0) {
      delete tankData.volume;
    }
    await axios.post(TANKS_URL, tankData);

    openSuccessNotification(i18next.t(`campus.tankCreated.${tankData.type}`));
    dispatch(fetchTanks(tankData.moduleId));
    dispatch(goToOnboardingNextStep());
  } catch (e) {
    console.log(e?.response);
  }
};

export const createTanks = (tanksData: CreateTank[]) => async (dispatch: Function) => {
  try {
    await axios.post(TANKS_URL, tanksData);
    openSuccessNotification(i18next.t(`campus.tankCreated.${tanksData[0].type}`));
    dispatch(fetchTanks(tanksData[0].moduleId));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateTank = (tankData: { _id: string; name: string; code: string; volume?: number; active: boolean; moduleId: string; latitude?: number; longitude?: number; type: string; }) => async (dispatch: Function) => {
  if (tankData.volume === 0) {
    delete tankData.volume;
  }

  try {
    await axios.patch(`${TANKS_URL}/${tankData._id}`, tankData);
    openSuccessNotification(i18next.t(`campus.tankUpdated.${tankData.type}`));
    dispatch(fetchTanks(tankData.moduleId));
  } catch (e) {
    console.log(e?.response);
  }
};

export const deleteTank = (tankId: string, type: string) => async (dispatch: Function) => {
  try {
    const response = await axios.patch<Tank>(`${TANKS_URL}/${tankId}`, { active: false });
    openSuccessNotification(i18next.t(`campus.tankDeleted.${type}`));
    dispatch(fetchTanks(response.data.moduleId));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchTanksQR = (accessToken: string) => async (dispatch: Function) => {
  const requestParams = {
    shouldGenerateQR: true,
    $limit: -1,
    active: true,
    '$select': ['_id', 'name'],
    '$populate': ['campusId', 'moduleId'],
  };
  dispatch(setIsTanksQrLoading(true));

  try {
    if (accessToken) {
      const response = await apiClient.axios.get(TANKS_URL, {
        headers: { 'Authorization': accessToken },
        params: requestParams,
      });
      dispatch(setTanksQr(response.data));
    }
    else {
      const response = await axios.get(TANKS_URL, { params: requestParams });
      dispatch(setTanksQr(response.data));
    }
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsTanksQrLoading(false));
};

export const fetchUrlTanksQR = () => async (dispatch: Function) => {
  dispatch(setIsDownloadingTanksQr(true));
  try {
    const response = await axios.get(TANKS_QR_PDF_URL);
    openSuccessNotification(i18next.t('campus.downloadingQRTanks'));

    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?accessToken=${accessToken}`;
    await downloadFile(url, 'TANKS_QR', 'pdf');
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsDownloadingTanksQr(false));
};

export const updateContainerLocations = (props: { companyId: string; }) => async (dispatch: Function) => {
  const { companyId } = props;

  dispatch(setAddingLocations(true));
  const url = `${TANKS_URL}/inject-location`;

  const body = {
    companyId,
  };

  try {
    const response = await axios.post<{ updatedContainersCount: number }>(url, body);
    openSuccessNotification(i18next.t('campus.amountContainers', { amount: response.data.updatedContainersCount }));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setAddingLocations(false));
};

export default tanksSlice.reducer;
