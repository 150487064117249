import React from 'react';
import Qrcode from 'qrcode.react';

import tankImg from '../../../assets/stack.png';

import styles from './TankQRCode.module.scss';

export default function TankQRCode (props: { id: string; tankName: string }) {
  const { id: tankId, tankName } = props;
  const iconSize = 35;

  function renderName () {
    return <div className={styles.name}>{tankName}</div>;
  }

  return (
    <div id={tankId} className={styles.containerQRCode}>
      <Qrcode
        size={200}
        className={styles.qrcode}
        value={tankId}
        fgColor='#000000'
        level='H'
        renderAs='canvas'
        includeMargin={false}
        imageSettings={{
          src: tankImg,
          width: iconSize,
          height: iconSize,
          excavate: true,
        }}
      />
      {renderName()}
    </div>
  );
}
