import i18next from 'i18next';

import { maturationGlobalStatus } from '../config/commons';

export const getMaturationStatus = (status?: string) => {
  switch (status) {
    case maturationGlobalStatus.PENDING_REVIEW:
      return i18next.t('maturations.global.approvalPending');

    case maturationGlobalStatus.APPROVED:
      return i18next.t('maturations.global.approved');

    case maturationGlobalStatus.REJECTED:
      return i18next.t('maturations.global.rejected');

    default:
      return '';
  }
};