import cx from 'classnames';
import TabPane from 'antd/lib/tabs/TabPane';
import { useTranslation } from 'react-i18next';

import { getCurrentTheme } from '../../helpers/theme';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';

import Units from './Units';
import styles from './UnitScreen.module.scss';
import { UnitSubHeader } from './UnitSubHeader';
import { ContainerMap } from './ContainerMap/ContainerMap';

export const UnitScreen = () => {
  const [t] = useTranslation();

  const theme = getCurrentTheme();

  return (
    <div className={cx('unitScreen', styles.container)}>
      <UnitSubHeader />

      <LrvTabs defaultActiveKey='1' theme={theme} className={styles.tabs}>
        <TabPane tab={<div id='tab_unit'>{t('campus.title')}</div>} key='1' className={styles.tab}>
          <Units />
        </TabPane>

        <TabPane tab={<div id='tab_map_container'>{t('containerMap.title')}</div>} key='2' className={styles.tab}>
          <ContainerMap />
        </TabPane>
      </LrvTabs>
    </div>
  );
};
