import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Stocking } from '../interfaces';
import { removeSpaces } from '../../../utils/strings';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { getParameter } from '../../../helpers/stocking.helpers';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import * as stockingAnalysisSlice from '../Analysis/stockingAnalysisSlice';
import { colorsPoints, typeParam } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './LegendNormal.module.scss';

interface Props {
  color?: string;
  stockingDefault?: Stocking;
  maxStage: number;
  theme?: 'dark' | 'light';
}

export const LegendNormal = (props: Props) => {
  const { color, stockingDefault, theme = getCurrentTheme() } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    listStockingsName,
    enabledStockings,
    stockingSelected,
    parameter,
    showDerivative,
  } = useSelector((state: Store) => state.stockingAnalysis);

  const phaseTypeSelected = stockingSelected?.phaseType || stockingPhaseTypes.LARVAE;
  const isLightTheme = theme === THEME.LIGHT;

  const renderYParameter = (props: { opacity?: number }) => {
    const { opacity = 1 } = props;

    const value = getParameter({ parameter, stockingPhaseType: stockingDefault?.phaseType || stockingPhaseTypes.LARVAE });

    return (
      <div className={styles.labelAxisY} style={{ opacity }}>
        <LrvText theme={theme} className={isLightTheme ? styles.lightText : styles.darkText} text={value} />
      </div>
    );
  };

  const renderRightAxisYParameter = () => {
    return (
      <div className={styles.labelAxisY}>
        <LrvText theme={theme} className={isLightTheme ? styles.lightText : styles.darkText} text={t('production.window.derivative')} />
      </div>
    );
  };

  const renderLegends = () => {
    const parameterLabel = getParameter({ parameter, stockingPhaseType: `${stockingDefault?.phaseType}`, addUnit: false });

    switch (parameter) {
      case typeParam.FACTOR_K:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.fullMoon)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('detail.moonPhase.fullMoon')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.lastQuarterMoon)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('detail.moonPhase.lastQuarterMoon')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, isLightTheme ? styles.newMoonLight : styles.newMoonDark)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('detail.moonPhase.newMoon')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.firstQuarterMoon)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('detail.moonPhase.firstQuarterMoon')} />
              </span>
            </div>
          </div>
        );

      case typeParam.PIGMENTATION:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.pigmentation)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={parameterLabel} />
              </span>
            </div>
          </div>
        );

      case typeParam.GROWTH_DELTA:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.growthDelta)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={parameterLabel} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.movingAverage)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('production.movingAverage')} />
              </span>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.circle, styles.below)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.legends.below')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, isLightTheme ? styles.lightNormal : styles.darkNormal)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.legends.normal')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.over)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.legends.over')} />
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.consolidated)} />
              <span style={{ color }}>
                <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.legends.consolidated')} />
              </span>
            </div>

            {
              parameter === typeParam.AVG_WEIGHT && phaseTypeSelected === stockingPhaseTypes.ADULT &&
              <div className={styles.item}>
                <div className={cx(styles.colorBox, styles.movingAverage)} />
                <span style={{ color }}>
                  <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('production.movingAverage')} />
                </span>
              </div>
            }
          </div>
        );
    }
  };

  const renderNormalLegends = () => {
    return (
      <div className={styles.containerNormalLegend}>
        <div className={styles.normalLegend}>
          {renderYParameter({})}
          {renderLegends()}
          {showDerivative ? renderRightAxisYParameter() : renderYParameter({ opacity: 0 })}
        </div>
        <div className={styles.rect} />
      </div>
    );
  };

  const renderStockingLegends = () => {
    const legendsList = [];
    for (let index = 0; index < listStockingsName.length; index++) {
      const name = listStockingsName[index];
      const nameWithoutSpaces = removeSpaces(name.toLowerCase());
      const className = stockingSelected?.name === name ? cx(styles.item, styles.itemSelected) : styles.item;

      const element = (
        <div
          id={`legend_${nameWithoutSpaces}`}
          key={index}
          className={cx(className, isLightTheme ? styles.itemLight : styles.itemDark)}
          onClick={() => {
            const enableStockingList = [...enabledStockings];
            enableStockingList[index] = !enableStockingList[index];
            dispatch(stockingAnalysisSlice.setEnabledStockings(enableStockingList));
          }}
        >
          <div className={cx(styles.colorBox, stockingDefault?.name === name ? styles.colorBoxSelected : '')} style={{ background: `${colorsPoints[index]}` }} />
          <span className={enabledStockings[index] ? '' : styles.disabledStocking}>{name}</span>
        </div>
      );

      legendsList.push(element);
    }

    return (
      <div className={styles.containerStockingLegend}>
        <div className={styles.sideLeft}>
          {renderYParameter({})}
        </div>

        <div className={styles.stockingLegend}>{legendsList}</div>
        {renderYParameter({ opacity: 0 })}
      </div>
    );
  };

  return listStockingsName.length === 0 ? renderNormalLegends() : renderStockingLegends();
};