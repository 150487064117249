import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, LeafletMouseEvent } from 'leaflet';
import { TileLayer, Marker, useMapEvents, MapContainer } from 'react-leaflet';

import { LrvText } from '../LrvText/LrvText';
import marker from '../../../assets/marker.png';
import DotSpinner from '../DotSpinner/DotSpinner';
import { useLocation } from '../../../hooks/useLocation';
import { Location } from '../../../pages/Tanks/interfaces';
import { GOOGLE_MAP_API } from '../../../config/google-maps';

import './LrvMap.scss';
import styles from './LrvMap.module.scss';

const markerIcon = new Icon({
  iconUrl: marker,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [1, -34],
});

interface Props {
  onLocationSelect: (location: Location) => void;
  containerLocation?: Location;
}

export const LrvMap = (props: Props) => {
  const { onLocationSelect, containerLocation } = props;

  const [t] = useTranslation();

  const [position, setPosition] = useState<Location | undefined>();
  const { location, isLocationFetchError } = useLocation({ containerLocation });

  useEffect(() => {
    if (containerLocation?.latitude && containerLocation.longitude) {
      setPosition(containerLocation);
      return;
    }

    setPosition(location);
  }, [location]);

  const LocationMarker = () => {
    useMapEvents({
      click (e: LeafletMouseEvent) {
        const location = { latitude: e.latlng.lat, longitude: e.latlng.lng };

        setPosition(location);
        onLocationSelect(location);
      },
    });

    return position === null ? null : (
      <Marker
        position={[position?.latitude || 0, position?.longitude || 0]}
        icon={markerIcon}
        draggable={true}
        eventHandlers={{
          dragend: (event) => {
            const { lat, lng } = event.target.getLatLng();

            setPosition({ latitude: lat, longitude: lng });
            onLocationSelect({ latitude: lat, longitude: lng });
          },
        }}
      >
      </Marker>
    );
  };

  if (isLocationFetchError) {
    return (
      <div>
        <LrvText text={t('campus.enablePermissionLocation')} theme='light' />
      </div>
    );
  }

  if (!position?.latitude || !position?.longitude) {
    return (
      <div className={styles.spinner}>
        <DotSpinner theme='light' />
      </div>
    );
  }

  return (
    <MapContainer
      id='map'
      className='map'
      center={[position?.latitude || 0, position?.longitude || 0]}
      zoom={16}
    >
      <TileLayer
        url={`https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&key=${GOOGLE_MAP_API}`}
        attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
      />
      <LocationMarker />
    </MapContainer>
  );
};
