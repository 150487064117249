import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import SampleD3 from '../SampleD3';
import { Percentile } from '../../interfaces';
import * as sampleSlice from '../sampleSlice';
import { Store } from '../../../../../state/store.interfaces';
import Icon from '../../../../../common/components/Icon/Icon';
import { LrvText } from '../../../../../common/components/LrvText/LrvText';

import styles from './Percentiles.module.scss';
import { isRemovedOrHasPreviousGroup } from './helper';

interface Props {
  listPercentile: Percentile[];
  labelsUnit: string;
  sampleChartFullScreen?: SampleD3 | null;
  theme?: 'dark' | 'light';
}

export default function Percentiles (props: Props) {
  const {
    labelsUnit,
    listPercentile,
    sampleChartFullScreen,
    theme = 'dark',
  } = props;

  const dispatch = useDispatch();

  const {
    animalSelected,
    newPercentile,
    oldPercentile,
  } = useSelector((state: Store) => state.sample);


  const getClassNameCheckIcon = (index: number) => {
    switch (index) {
      case 0:
        return styles.icon1;

      case 1:
        return styles.icon2;

      case 2:
        return styles.icon3;

      case 3:
        return styles.icon4;

      case 4:
        return styles.icon5;
    }
  };

  return (
    <div className={styles.percentiles}>
      {
        listPercentile.map((percentile, index) => {
          const sizeIcon = 15 + (3 * (index + 1));
          const sizeCheckIcon = 15 + (4 * (index + 1));
          const maxRadius = 20 + (3 * listPercentile.length);

          let showCheckIcon = false;

          if (!newPercentile && oldPercentile && oldPercentile.group === percentile.group) {
            showCheckIcon = true;
          }

          if (newPercentile && newPercentile.group === percentile.group) {
            showCheckIcon = true;
          }

          return (
            <div
              key={index}
              className={styles.percentile}
              onClick={() => {
                if (!isRemovedOrHasPreviousGroup(animalSelected)) {
                  dispatch(sampleSlice.setNewPercentile(percentile));
                  sampleChartFullScreen && sampleChartFullScreen.updateBackgroundColor(percentile.color);
                }
              }}
            >
              <div
                className={styles.containerCircle}
                style={{
                  width: maxRadius,
                  height: maxRadius,
                }}
              >
                <div
                  className={styles.circle}
                  style={{
                    backgroundColor: percentile.color,
                    width: showCheckIcon ? sizeCheckIcon : sizeIcon,
                    height: showCheckIcon ? sizeCheckIcon : sizeIcon,
                  }}
                >
                  {
                    showCheckIcon &&
                    <Icon name='check' type='line' theme={theme} className={cx(styles.icon, getClassNameCheckIcon(index))} />
                  }
                </div>
              </div>

              <LrvText className={styles.rank} theme={theme} text={`${percentile.rank} ${labelsUnit}`} />
            </div>
          );
        })
      }
    </div>
  );
}
