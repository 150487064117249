import React from 'react';

import { colorsPoints } from '../../common/components/charts/ShadedPlot/helpers';

import styles from './LegendStocking.module.scss';

export default function LegendStocking (props: { legendList?: string[] }) {
  const { legendList = [] } = props;

  if (legendList.length === 0) {
    return null;
  }

  const legendsList = [];
  for (let index = 0; index < legendList.length; index++) {
    const element = (
      <div key={index} className={styles.item}>
        <div className={styles.colorBox} style={{ background: `${colorsPoints[index]}` }} />
        <span className={styles.stockingName}> {legendList[index]} </span>
      </div>
    );

    legendsList.push(element);
  }
  return <div className={styles.legend}>{legendsList}</div>;
}
