import cx from 'classnames';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';

import Data from '../Data';
import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import { DataSource, Point } from '../../home/interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { stockingPhaseTypes } from '../../../config/commons';
import { charactersToSeparate } from '../../../utils/strings';
import Content from '../../../common/components/Content/Content';
import { getLabelAxisX } from '../../../helpers/stocking.helpers';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import * as geneticsAnalysisSlice from '../../Genetics/Analysis/geneticsAnalysisSlice';
import { typesChart, metricsStatuses, colorsPoints, getDefaultDataByStage } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './GeneticsAnalysisPdf.module.scss';

type TParams = {
  companyId: string; scale: string;
  language: string; accessToken: string; maturationsName: string;
  parameter: string; phaseType: string;
  minimumDate: string; maximumDate: string;
  firstStage: string; lastStage: string;
  referenceId: string;
  unitId?: string; unitName?: string;
};

const MaturationComparisionPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId, language, scale,
    accessToken, maturationsName, parameter,
    minimumDate, maximumDate, phaseType,
    firstStage, lastStage,
    referenceId,
    unitId, unitName
  } = match.params;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const labelStageHeight = 23;
  const legendHeight = 100 + labelStageHeight;
  const selectedMaturationNames = maturationsName ? maturationsName.split(charactersToSeparate) : [];

  const {
    metrics,
    maturations,
    dataSource,
    filters,
  } = useSelector((state: Store) => state.geneticsAnalysis);

  const { company: selectedCompany } = useSelector((state: Store) => state.header);

  const { maturationMetrics, maturationCodeMetrics } = metrics;
  const { referenceCurve } = filters;

  const refChartMain = useRef<HTMLDivElement>(null);
  const maturationNames = dataSource.map((data) => data.name);

  const width = 1680 - 96;
  const height = maturations.length === 0 ? 950 - 22 : 920 - 22;
  const backgroundColor = 'white';

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    if (phaseType === stockingPhaseTypes.LARVAE) {
      dispatch(geneticsAnalysisSlice.fetchReferenceCurve({ referenceId, accessToken }));
      return;
    }
    dispatch(geneticsAnalysisSlice.fetchReferenceCurveGroupedByDays({ referenceId, accessToken }));
  }, [dispatch, referenceId, accessToken]);

  useEffect(() => {
    const maturationDataSource: DataSource[] = [];

    let index = 0;
    for (const [maturationName, maturationStageMetrics] of Object.entries(maturationMetrics)) {
      const maturationByStageList: Point[] = [];
      const color = colorsPoints[index];
      const enabled = true;
      const show = true;

      if (!selectedMaturationNames.includes(maturationName)) {
        continue;
      }

      for (const [stage, maturationStage] of Object.entries(maturationStageMetrics)) {
        const x: number = parseInt(stage);
        const y: number = maturationStage.mean;
        const analysesNumber = maturationStage.number;
        const maturationByStage: Point = getDefaultDataByStage({ x, y, analysesNumber });
        maturationByStageList.push(maturationByStage);
      }

      const maturationData: DataSource = { id: maturationName, name: maturationName, color, enabled, show, points: [], avgPoint: maturationByStageList };
      maturationDataSource.push(maturationData);
      index++;
    }

    for (const [maturationName, maturationCodeMetric] of Object.entries(maturationCodeMetrics)) {
      for (const [maturationCode, maturationStage] of Object.entries(maturationCodeMetric)) {
        const key = `${maturationName}_${maturationCode}`;

        // eslint-disable-next-line
        if (!selectedMaturationNames.includes(key)) {
          continue;
        }

        const maturationByStageList: Point[] = [];
        const dataSourceSelected = dataSource.find((data) => data.id === key);
        const color = dataSourceSelected?.color || colorsPoints[index];

        const enabled = dataSourceSelected?.enabled || true;
        const show = dataSourceSelected?.show || true;

        // eslint-disable-next-line
        for (const [stage, value] of Object.entries(maturationStage)) {
          const x: number = parseInt(stage);
          const y: number = value.mean;
          const analysesNumber = value.number;
          const maturationByStage: Point = getDefaultDataByStage({ x, y, analysesNumber });
          maturationByStageList.push(maturationByStage);
        }
        const maturationData: DataSource = { id: key, name: `${maturationName}: ${maturationCode}`, color, enabled, show, points: [], avgPoint: maturationByStageList };
        maturationDataSource.push(maturationData);
        index++;
      }
    }

    dispatch(geneticsAnalysisSlice.setDataSource(maturationDataSource));
  }, [maturationMetrics, maturationCodeMetrics]);

  useEffect(() => {
    return () => {
      dispatch(geneticsAnalysisSlice.resetGeneticsAnalysis());
    };
  }, [dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(geneticsAnalysisSlice.fetchCompanyMaturations(companyId, accessToken));
    }
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    if (maturations.length > 0 && companyId) {
      const params = { unitId: unitId ?? '', companyId: companyId, type: parameter, phaseType, minimumDate, maximumDate, accessToken };
      dispatch(geneticsAnalysisSlice.fetchMaturationMetrics(params));
    }
  }, [dispatch, companyId, unitId, parameter, maturations, phaseType, minimumDate, maximumDate, accessToken]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    if (!dataSource.length || !referenceCurve?._id) {
      return;
    }

    const showChart = () => {
      const dataSourceFiltered = dataSource.filter((data) => data.enabled && data.show && data.color);
      const colorsPoints = dataSourceFiltered.map((dataSource) => `${dataSource.color}`);
      const colorsStroke = colorsPoints;

      const props = {
        colorFillRect: backgroundColor,
        colorLine: 'white',
        companyData: selectedCompany,
        container: refChartMain.current,
        dataMetric: referenceCurve?.values || [],
        dataSource: cloneDeep(dataSource),
        firstStage: parseFloat(firstStage),
        height,
        lastStage: parseFloat(lastStage),
        parameter,
        phaseType,
        scale: scale,
        showLabels: false,
        typeChart: typesChart.MATURATIONS,
        typeMetric: metricsStatuses.REFERENCE_CURVE,
        width,
        colorsPoints,
        colorsStroke,
        showMovingAverage: false,
        isMaturationCurves: true,
      };
      new D3ShadedPlot(props);
    };

    showChart();
  }, [metrics, maturations, scale, parameter, firstStage, lastStage, width, height, legendHeight, referenceCurve, selectedCompany, phaseType, dataSource]);

  if (!maturations || maturations.length === 0 || !dataSource.length) {
    return (
      <div className={styles.spinner} >
        <DotSpinner />
      </div>
    );
  }

  return (
    <div id='maturationChartPdf' className={cx(styles.containerMain, 'maturationComparisionPdf')}>
      <Header
        title={selectedCompany.name}
        subtitle={`${t('maturations.comparison')} -`}
        rightSubtitle={`${moment(minimumDate).format('YYYY-MMM-DD')} - ${moment(maximumDate).format('YYYY-MMM-DD')}`}
      />

      <div className={styles.rowData}>
        <Data
          stockingPhaseType={phaseType}
          parameter={parameter}
          legendList={maturationNames}
          scale={scale}
          language={language}
          unitName={unitName}
        />
      </div>

      <div className={styles.rowData}>
        <Content
          headerClassName={styles.headerPadding}
          titleClassName={styles.titleContent}
          noPadding
          style={{ backgroundColor: backgroundColor }}
        >
          <div className='maturationChart'>
            <div ref={refChartMain} className={styles.chart}> </div>
            <div className={styles.labelAxisX}>{getLabelAxisX(phaseType)}</div>
          </div>
        </Content>
      </div>

      <Footer />
    </div>
  );
};

export default MaturationComparisionPdf;