import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getLanguage } from '../../utils/language';
import { downloadFile } from '../../utils/download';
import { getUserSession } from '../../utils/userSession';
import * as stockingsSlice from '../Sowings/sowingsSlice';
import { UserSession } from '../../common/interfaces/auth';
import { axiosClient as axios } from '../../utils/axios_instance';
import { AnalysisCardMobile } from '../Company/AnalysisCard/interfaces';
import { openErrorNotification, openSuccessNotification } from '../../common/notification/Notification';
import { analysisError, analysisStatuses, paginationAnalysis, unitStatuses, inactiveCampus, isConsolidatedAnalysis, analysisBelongsToConsolidated, typeErrorStocking, platform } from '../../config/commons';
import { ANALYSES_URL, RELAUNCH_ANALYSIS_URL, CAMPUS_URL, MODULES_URL, TANKS_URL, STOCKINGS_URL, SAMPLE_WEIGHT_LIMITS_URL, EXCLUDE_CHART_URL, INCLUDE_CHART_URL, ARCHIVE_ANALYSIS_URL, UNARCHIVE_ANALYSIS_URL, REMOVE_CONSOLIDATED_URL, STOCKING_CHARTS_PDF_URL, ANALYSIS_DETAIL_CUSTOMIZABLE_URL } from '../../config/config.api';

import { AnalysisPayload, AnalysisState, Campus, EditAnalysis, SampleWeight } from './interfaces';

const initialState: AnalysisState = {
  total: 0,
  skip: 0,
  limit: 0,
  currentPage: 1,
  analysis: [],
  isLoading: false,
  isDownloadingFile: false,
  hasPermission: true,
  selectCampus: {
    _id: '',
    name: '',
    province: '',
  },
  editAnalysis: {
    data: {
      code: '',
      analysisId: '',
      maturationId: '',
      sampleWeight: '',
      stage: '',
    },
    showModal: false,
  },
  moveAnalysis: {
    isLoading: false,
    showModal: false,
    analysisId: '',
    selectedCampus: {
      _id: '',
      name: '',
    },
    selectedModule: {
      _id: '',
      name: '',
    },
    selectedContainer: {
      _id: '',
      name: '',
    },
    selectedStocking: {
      _id: '',
      name: '',
    },
    campuses: [],
    modules: [],
    containers: [],
    stockings: [],
  },
  sampleLimits: {
    larvae: {
      max: 0,
      min: 0
    },
    juvenile: {
      max: 0,
      min: 0
    },
    growOut: {
      max: 0,
      min: 0
    }
  },
  analysisCards: [],
};

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    setAnalysis: (state: AnalysisState, action: PayloadAction<AnalysisPayload>) => {
      state.total = action.payload.total;
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.analysis = action.payload.data;
    },
    setIsLoading: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentPage: (state: AnalysisState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setIsDownloadingFile: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setHasPermission: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.hasPermission = action.payload;
    },
    setSelectCampus: (state: AnalysisState, action: PayloadAction<Campus>) => {
      state.selectCampus = action.payload;
    },
    setIsLoadingMove: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.moveAnalysis.isLoading = action.payload;
    },

    setShowModalMove: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.moveAnalysis.showModal = action.payload;
    },

    setCampuses: (state: AnalysisState, action: PayloadAction<[]>) => {
      state.moveAnalysis.campuses = action.payload;
    },

    setModules: (state: AnalysisState, action: PayloadAction<[]>) => {
      state.moveAnalysis.modules = action.payload;
    },

    setContainers: (state: AnalysisState, action: PayloadAction<[]>) => {
      state.moveAnalysis.containers = action.payload;
    },

    setStockings: (state: AnalysisState, action: PayloadAction<[]>) => {
      state.moveAnalysis.stockings = action.payload;
    },

    setAnalysisId: (state: AnalysisState, action: PayloadAction<string>) => {
      state.moveAnalysis.analysisId = action.payload;
    },

    setSelectedCampus: (state: AnalysisState, action: PayloadAction<{ _id: string; name: string }>) => {
      state.moveAnalysis.selectedCampus._id = action.payload._id;
      state.moveAnalysis.selectedCampus.name = action.payload.name;
    },

    setSelectedModule: (state: AnalysisState, action: PayloadAction<{ _id: string; name: string }>) => {
      state.moveAnalysis.selectedModule._id = action.payload._id;
      state.moveAnalysis.selectedModule.name = action.payload.name;
    },

    setSelectedContainer: (state: AnalysisState, action: PayloadAction<{ _id: string; name: string }>) => {
      state.moveAnalysis.selectedContainer._id = action.payload._id;
      state.moveAnalysis.selectedContainer.name = action.payload.name;
    },

    setSelectedStocking: (state: AnalysisState, action: PayloadAction<{ _id: string; name: string }>) => {
      state.moveAnalysis.selectedStocking._id = action.payload._id;
      state.moveAnalysis.selectedStocking.name = action.payload.name;
    },
    setSampleLimits: (state: AnalysisState, action: PayloadAction<SampleWeight>) => {
      state.sampleLimits = action.payload;
    },

    setEditDataAnalysis: (state: AnalysisState, action: PayloadAction<EditAnalysis>) => {
      state.editAnalysis.data = action.payload;
    },
    setShowModalEdit: (state: AnalysisState, action: PayloadAction<boolean>) => {
      state.editAnalysis.showModal = action.payload;
    },
    setAnalysisCards: (state: AnalysisState, action: PayloadAction<AnalysisCardMobile[]>) => {
      state.analysisCards = action.payload;
    },
  },
});

export const {
  setAnalysis,
  setIsLoading,
  setCurrentPage,
  setIsDownloadingFile, setHasPermission, setSelectCampus,
  setIsLoadingMove, setShowModalMove, setCampuses, setModules, setContainers, setStockings, setSampleLimits,
  setAnalysisId, setSelectedCampus, setSelectedModule, setSelectedContainer, setSelectedStocking,
  setEditDataAnalysis,
  setShowModalEdit,
  setAnalysisCards,
} = analysisSlice.actions;

export const fetchAnalysis = (props: { stockingId: string; page: number; companyId?: string }) => async (dispatch: Function) => {
  const { stockingId, page, companyId } = props;

  let skip = 0;
  dispatch(setIsLoading(true));
  dispatch(setHasPermission(true));

  if (page !== 0) {
    skip = paginationAnalysis * (page - 1);
  }

  try {
    const userSession: UserSession = JSON.parse(
      localStorage.getItem('user') || '{}'
    );

    const params = {
      '$limit': paginationAnalysis,
      '$skip': skip,
      'sowingId': stockingId,
      'companyId': companyId || userSession.companyId,
      'isArchived': false,
      'inputData.stage[$ne]': '',
      '$sort[createdAt]': -1,
      '$select': [
        'code', 'inputData',
        'resultData.uniformity', 'resultData.larvaeNumber', 'resultData.larvaePerGram',
        'resultData.averageWeight', 'resultData.averageLength',
        'resultData.uniformity', 'resultData.variationCoefficient', 'resultData.variationCoefficientLength',
        'createdAt', 'status',
        'sowingId', 'type', 'hidden', 'isArchived', 'analysisIds',
        'coordinatesRemoved', 'unusualAverageWeight', 'phaseType',
      ],
      '$or[0][status]': analysisStatuses.COMPLETED,
      '$or[1][status]': analysisStatuses.ERROR,
    };

    const response = await axios.get<AnalysisPayload>(ANALYSES_URL, { params: params });
    dispatch(setAnalysis(response.data));
  } catch (e) {
    if (e?.response?.status === 403) {
      dispatch(setHasPermission(false));
    }

    console.log('ERROR' + e);
    return;
  }

  dispatch(setIsLoading(false));
};

export const updateAnalysis = (
  props: {
    companyId: string,
    analysisId: string,
    stockingId: string,
    analysisData: {
      code: string; inputData: { stage: number; sampleWeight: number; maturationId: string; species: string }; fix: boolean;
    },
    currentPage: number,
  }
) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, analysisData, currentPage } = props;

  try {
    await axios.patch(`${ANALYSES_URL}/${analysisId}`, analysisData);
    openSuccessNotification(i18next.t('analysis.updated'));
  } catch (e) {
    console.log(e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }

    return;
  }

  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const excludeAnalysisFromChart = (props: { companyId: string; analysisId: string; stockingId: string; currentPage: number }) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, currentPage } = props;

  try {
    await axios.post(`${EXCLUDE_CHART_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.hiddenFromGraph'));
  } catch (e) {
    console.log(e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }
    return;
  }

  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const includeAnalysisFromChart = (props: { companyId: string; analysisId: string; stockingId: string; currentPage: number }) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, currentPage } = props;

  try {
    await axios.post(`${INCLUDE_CHART_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.shownInGraph'));
  } catch (e) {
    console.log(e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }
    return;
  }

  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const relaunchAnalysis = (code: string, body: { analysisId: string }) => async () => {
  try {
    await axios.post(RELAUNCH_ANALYSIS_URL, body);
    openSuccessNotification(i18next.t('analysis.relaunched') + code);
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampuses = (companyId?: string, phaseType?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();
  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    phaseType: phaseType || undefined,
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
  };

  try {
    const response = await axios.get(MODULES_URL, { params: params });
    dispatch(setModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchContainers = (campusId?: string, moduleId?: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    moduleId: moduleId || undefined,
  };

  try {
    const response = await axios.get(TANKS_URL, { params: params });
    dispatch(setContainers(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockings = (campusId?: string, moduleId?: string, containerId?: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    moduleId: moduleId || undefined,
    tankId: containerId || undefined,
  };

  try {
    const response = await axios.get(STOCKINGS_URL, { params: params });
    dispatch(setStockings(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStocking = (stockingId: string) => async (dispatch: Function) => {
  const params = {
    $select: ['campusId', 'moduleId', 'tankId', 'name'],
  };

  try {
    const response = await axios.get(`${STOCKINGS_URL}/${stockingId}`, { params: params });
    dispatch(setSelectedStocking(response.data));
    dispatch(fetchCampusMoveAnalysis(response.data.campusId));
    dispatch(fetchModule(response.data.moduleId));
    dispatch(fetchContainer(response.data.tankId));

    dispatch(fetchModules(response.data.campusId));
    dispatch(fetchContainers(response.data.campusId, response.data.moduleId));
    dispatch(fetchStockings(response.data.campusId, response.data.moduleId, response.data.tankId));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampusMoveAnalysis = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $select: ['name'],
  };

  try {
    const response = await axios.get(`${CAMPUS_URL}/${campusId}`, { params: params });
    dispatch(setSelectedCampus(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModule = (moduleId: string) => async (dispatch: Function) => {
  const params = {
    $select: ['name'],
  };

  try {
    const response = await axios.get(`${MODULES_URL}/${moduleId}`, { params: params });
    dispatch(setSelectedModule(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchContainer = (tankId: string) => async (dispatch: Function) => {
  const params = {
    $select: ['name'],
  };

  try {
    const response = await axios.get(`${TANKS_URL}/${tankId}`, { params: params });
    dispatch(setSelectedContainer(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const moveAnalysis = (
  props: {
    companyId: string,
    analysisId: string,
    body: { campusId: string; moduleId: string; tankId: string; sowingId: string },
    paramsFetchAnalysis: { stockingId: string; page: number }
  }
) => async (dispatch: Function) => {
  const { companyId, analysisId, body, paramsFetchAnalysis } = props;
  dispatch(setIsLoadingMove(true));

  try {
    await axios.patch(`${ANALYSES_URL}/${analysisId}/move`, body);
    openSuccessNotification(i18next.t('analysis.move.success'));
  } catch (e) {
    dispatch(setIsLoadingMove(false));

    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      const code = e.response.data.data.analysisCode;
      showErrorAnalysis(error, code);
      return;
    }

    openErrorNotification(i18next.t('analysis.move.error'));
    return;
  }

  dispatch(setIsLoadingMove(false));
  dispatch(setShowModalMove(false));
  dispatch(stockingsSlice.fetchStocking({ id: paramsFetchAnalysis.stockingId }));
  dispatch(fetchAnalysis({ stockingId: paramsFetchAnalysis.stockingId, page: paramsFetchAnalysis.page, companyId }));
};

const showErrorAnalysis = (error: string, code?: string) => {
  switch (error) {
    case analysisError.CODE:
      openErrorNotification(i18next.t('analysis.codeError'));
      break;

    case analysisError.LENGTH:
      openErrorNotification(i18next.t('analysis.codeLengthError'));
      break;

    case analysisError.IS_ARCHIVED:
      openErrorNotification(i18next.t('analysis.archivedAnalysisError'));
      break;

    case inactiveCampus:
      openErrorNotification(i18next.t('analysis.inactiveCampus'));
      break;

    case isConsolidatedAnalysis:
      openErrorNotification(i18next.t('analysis.isConsolidatedAnalysis'));
      break;

    case analysisBelongsToConsolidated:
      openErrorNotification(i18next.t('analysis.analysisBelongsToConsolidated', { code }));
      break;

    case typeErrorStocking.ORIGIN_STOCKING_IS_PUBLISHED:
      openErrorNotification(i18next.t('stockings.errors.originStockingIsPublished'));
      break;

    case typeErrorStocking.DESTINY_STOCKING_IS_PUBLISHED:
      openErrorNotification(i18next.t('stockings.errors.destinyStockingIsPublished'));
      break;
  }
};

export const fetchSampleWeightLimits = () => async (dispatch: Function) => {
  try {
    const response = await axios.get(SAMPLE_WEIGHT_LIMITS_URL);
    dispatch(setSampleLimits(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
};

export const archiveAnalysis = (props: { companyId: string, analysisId: string; stockingId: string; currentPage: number }) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, currentPage } = props;

  try {
    await axios.post(`${ARCHIVE_ANALYSIS_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.archived'));
  } catch (e) {
    console.log('ERROR', e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }
    return;
  }

  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const unarchiveAnalysis = (props: { companyId: string, analysisId: string; stockingId: string; currentPage: number }) => async (dispatch: Function) => {
  const { companyId, analysisId, stockingId, currentPage } = props;

  try {
    await axios.post(`${UNARCHIVE_ANALYSIS_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.unarchived'));
  } catch (e) {
    console.log(e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }
    return;
  }

  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const desconsolidateAnalysis = (props: { analysisId: string, stockingId: string; currentPage: number, companyId: string }) => async (dispatch: Function) => {
  const { analysisId, stockingId, currentPage, companyId } = props;
  try {
    await axios.delete(`${REMOVE_CONSOLIDATED_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.deconsolidated'));
  } catch (e) {
    console.log(e?.response);
    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorAnalysis(error);
    }
    return;
  }
  dispatch(fetchAnalysis({ stockingId, page: currentPage, companyId }));
};

export const getUrlStockingChartsPdf = (stockingId: string) => async (dispatch: Function) => {
  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(STOCKING_CHARTS_PDF_URL);

    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?stockingId=${stockingId}&accessToken=${accessToken}&language=${language}`;
    await downloadFile(url, stockingId, 'pdf');
  } catch (e) {
    console.log(e.response);
  }

  dispatch(setIsDownloadingFile(false));
};

export const fetchCustomAnalysisCards = (props: { companyId: string; }) => async (dispatch: Function) => {
  const { companyId } = props;

  const params = {
    $limit: -1,
    companyId,
    platform: platform.MOBILE,
    $select: ['phaseType', 'fields'],
  };

  try {
    const response = await axios.get<AnalysisCardMobile[]>(ANALYSIS_DETAIL_CUSTOMIZABLE_URL, { params });
    dispatch(setAnalysisCards(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default analysisSlice.reducer;
