import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BASE_MATURATIONS_URL } from '../../../config/config.api';
import { paginationGeneticsGlobal } from '../../../config/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { BaseMaturation, BaseMaturationPayload, BaseMaturationsState, MergeMaturation, UpdateBaseMaturation } from './interfaces';

const initialState: BaseMaturationsState = {
  activeBaseMaturations: [],
  allBaseMaturations: [],
  isLoadingAllMaturations: false,
  baseMaturation: {
    _id: '',
    active: false,
    companyId: '',
    companyName: '',
    name: '',
    codes: [],
    newName: '',
    merged: false,
    similarMaturations: [],
  },
  isLoading: false,
  limit: paginationGeneticsGlobal,
  currentPage: 0,
  skip: 0,
  total: 0,
  showMergeMaturationModal: false,
};

export const baseMaturationSlice = createSlice({
  name: 'baseMaturations',
  initialState,
  reducers: {
    setActiveBaseMaturations: (state: BaseMaturationsState, action: PayloadAction<BaseMaturation[]>) => {
      state.activeBaseMaturations = action.payload;
    },
    setAllBaseMaturations: (state: BaseMaturationsState, action: PayloadAction<BaseMaturationPayload>) => {
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.total = action.payload.total;
      state.allBaseMaturations = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },
    setIsLoadingAllMaturations: (state: BaseMaturationsState, action: PayloadAction<boolean>) => {
      state.isLoadingAllMaturations = action.payload;
    },
    setBaseMaturation: (state: BaseMaturationsState, action: PayloadAction<BaseMaturation>) => {
      state.baseMaturation = action.payload;
    },
    setIsLoading: (state: BaseMaturationsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentPage: (state: BaseMaturationsState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setShowMergeMaturationModal: (state: BaseMaturationsState, action: PayloadAction<boolean>) => {
      state.showMergeMaturationModal = action.payload;
    },
  },
});

export const {
  setActiveBaseMaturations,
  setAllBaseMaturations,
  setIsLoadingAllMaturations,
  setBaseMaturation,
  setIsLoading,
  setCurrentPage,
  setShowMergeMaturationModal,
} = baseMaturationSlice.actions;

export const fetchAllBaseMaturations = (props: { page: number }) => async (dispatch: Function) => {
  const { page } = props;

  let skip = 0;

  if (page !== 0) {
    skip = paginationGeneticsGlobal * (page - 1);
  }

  const params = {
    $limit: paginationGeneticsGlobal,
    $skip: skip,
    '$sort[_id]': -1,
  };

  dispatch(setIsLoadingAllMaturations(true));

  try {
    const response = await axios.get<BaseMaturationPayload>(BASE_MATURATIONS_URL, { params });
    dispatch(setAllBaseMaturations(response.data));
    dispatch(setIsLoadingAllMaturations(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchBaseMaturation = (id: string) => async (dispatch: Function) => {
  const url = `${BASE_MATURATIONS_URL}/${id}`;
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<BaseMaturation>(url);
    dispatch(setBaseMaturation(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateBaseMaturation = (props: UpdateBaseMaturation) => async (dispatch: Function) => {
  const { id, active, name, companyId, companyName, codes, newName } = props;
  const url = `${BASE_MATURATIONS_URL}/${id}`;

  const body = {
    active,
    name,
    companyId,
    companyName,
    codes,
    newName,
  };

  try {
    const response = await axios.patch<BaseMaturation>(url, body);
    dispatch(setBaseMaturation(response.data));
    openSuccessNotification(i18next.t('maturations.updated'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchActiveBaseMaturations = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    '$sort[name]': 1,
    active: true,
  };

  try {
    const response = await axios.get<BaseMaturation[]>(BASE_MATURATIONS_URL, { params });
    dispatch(setActiveBaseMaturations(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const mergeMaturation = async (props: MergeMaturation) => {
  const { mainMaturationId, secondaryMaturationId } = props;
  const url = `${BASE_MATURATIONS_URL}/merge/maturations`;

  const body = {
    mainMaturationId,
    secondaryMaturationId,
  };

  try {
    await axios.post<BaseMaturation>(url, body);
    openSuccessNotification(i18next.t('maturations.merge.updated'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default baseMaturationSlice.reducer;
