import cx from 'classnames';
import { Button, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem, swap, move } from 'react-grid-dnd';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';

import { CompanyStockingParameter } from './interfaces';
import { getKeysStockingParameterCustom } from './helpers';
import styles from './StockingParameterCustomizableModal.module.scss';
import { saveStockingParameterCustomizable, setShowCustomizableModal, updateStockingParameterCustomizable } from './stockingParameterCustomizableSlice';

const maxItemsToShow = 4;
interface Props {
  theme?: 'dark' | 'light';
}

export const StockingParameterCustomizableModal = (props: Props) => {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isLightTheme = theme === THEME.LIGHT;

  const { company } = useSelector((state: Store) => state.header);
  const { fields } = useSelector((state: Store) => state.stockingParameterCustomizable);
  const { companyStockingParameters } = useSelector((state: Store) => state.companyStockingParameter);
  const { _id: stockingParameterCustomizableId, showCustomizableModal } = useSelector((state: Store) => state.stockingParameterCustomizable);

  const [allData, setAllData] = useState<{ keysSelected: CompanyStockingParameter[], otherKeys: CompanyStockingParameter[] }>({ keysSelected: [], otherKeys: [] });

  useEffect(() => {
    const { keysSelected, otherKeys } = getKeysStockingParameterCustom({ companyStockingParameters, fields });
    setAllData({ keysSelected, otherKeys });
  }, [companyStockingParameters, fields]);

  const onChange = (sourceId: 'keysSelected' | 'otherKeys', sourceIndex: number, targetIndex: number, targetId?: 'keysSelected' | 'otherKeys') => {
    if (targetId === 'keysSelected' && allData.keysSelected.length >= maxItemsToShow) {
      return;
    }

    if (targetId) {
      const result = move(
        allData[sourceId],
        allData[targetId],
        sourceIndex,
        targetIndex
      );

      return setAllData({
        ...allData,
        [sourceId]: result[0],
        [targetId]: result[1]
      });
    }

    const result = swap(allData[sourceId], sourceIndex, targetIndex);
    return setAllData({
      ...allData,
      [sourceId]: result
    });
  };

  const isValidDetailCustomizable = () => {
    return allData.keysSelected.length === maxItemsToShow;
  };

  const onSaveDetailCustomizable = () => {
    const fields = allData.keysSelected.map((item) => item.key);

    if (stockingParameterCustomizableId) {
      dispatch(updateStockingParameterCustomizable({ _id: stockingParameterCustomizableId, fields }));
      dispatch(setShowCustomizableModal(false));
      return;
    }


    dispatch(saveStockingParameterCustomizable({ companyId: company._id, fields }));
    dispatch(setShowCustomizableModal(false));
  };

  const getBorderRadiusClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.borderRadiusLeft;

      case (maxItemsToShow - 1):
        return styles.borderRadiusRight;

      default:
        return '';
    }
  };

  const getHoverGridItemClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.hoverItem0;

      case (maxItemsToShow - 1):
        return styles.hoverItem3;
    }
  };

  const getWidthClass = (index: number) => {
    switch (index) {
      case (maxItemsToShow - 1):
        return styles.width;

      default:
        return '';
    }
  };

  return (
    <LrvModal
      open={showCustomizableModal}
      title={t('stockingParameterCustom.title')}
      theme={theme}
      cancelText={undefined}
      className={styles.detailCustomizableModal}
      onCancel={() => dispatch(setShowCustomizableModal(false))}
      footer={[
        <Button
          type='primary'
          className={styles.button}
          disabled={!isValidDetailCustomizable()}
          onClick={onSaveDetailCustomizable}
        >
          {t('stockingParameterCustom.save')}
        </Button>
      ]}
    >
      <div className={styles.container}>
        <LrvText
          className={styles.settings}
          theme={theme}
          text={'* ' + t('stockingParameterCustom.settings')}
        />

        <GridContextProvider
          onChange={(sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string) => {
            onChange(sourceId as 'keysSelected' | 'otherKeys', sourceIndex, targetIndex, targetId as 'keysSelected' | 'otherKeys');
          }}
        >
          <Space className={styles.row} direction='vertical'>
            <div>
              <div className={styles.label}>
                <LrvText
                  className={styles.description}
                  theme={theme}
                  text={t('stockingParameterCustom.description')}
                />
              </div>

              <GridDropZone
                className={cx(styles.dropzone, styles.otherKeys, isLightTheme ? styles.otherKeysLight : styles.otherKeysDark)}
                id='otherKeys'
                boxesPerRow={3}
                rowHeight={40}
                style={{
                  height: ((companyStockingParameters.length + 1) * 20)
                }}
              >
                {allData.otherKeys.map(item => (
                  <GridItem key={item._id}>
                    <div className={cx(styles.gridItem, styles.gridItemOtherKeys)}>
                      <div className={cx(styles.gridItemContent, styles.keys)}>
                        <LrvText
                          text={item.key}
                          theme={theme}
                        />
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </div>

            <div>
              <GridDropZone
                className={cx(styles.dropzone, styles.keysSelected)}
                id='keysSelected'
                boxesPerRow={4}
                rowHeight={78}
              >
                {allData.keysSelected.map((item, index) => (
                  <GridItem
                    key={item._id}
                    className={cx(styles.grid, getBorderRadiusClass(index), getWidthClass(index))}
                  >
                    <div className={cx(styles.gridItem, getHoverGridItemClass(index))}>
                      <div className={styles.gridItemContent}>
                        <LrvText
                          text={item.key}
                          theme={theme}
                        />
                      </div>
                    </div>
                  </GridItem>
                ))}
              </GridDropZone>
            </div>
          </Space>
        </GridContextProvider>
      </div>
    </LrvModal>
  );
};
