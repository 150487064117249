import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MODULES_URL } from '../../config/config.api';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';

import { Module, ModulesState } from './interfaces';

const initialState: ModulesState = {
  modules: [],
  selectedModule: {
    _id: '',
    name: '',
    code: '',
    campusId: '',
    phaseType: '',
  },
  isModuleLoading: false,
  isModulesLoading: false
};

export const modulesSlice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    setModules: (state: ModulesState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setSelectedModule: (state: ModulesState, action: PayloadAction<Module>) => {
      state.selectedModule._id = action.payload._id;
      state.selectedModule.name = action.payload.name;
      state.selectedModule.code = action.payload.code;
      state.selectedModule.campusId = action.payload.campusId;
      state.selectedModule.phaseType = action.payload.phaseType;
    },
    setIsModulesLoading: (state: ModulesState, action: PayloadAction<boolean>) => {
      state.isModulesLoading = action.payload;
    },
    setIsModuleLoading: (state: ModulesState, action: PayloadAction<boolean>) => {
      state.isModuleLoading = action.payload;
    }
  }
});

export const { setModules, setSelectedModule, setIsModulesLoading, setIsModuleLoading } = modulesSlice.actions;

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  let modules = [];
  dispatch(setIsModulesLoading(true));
  try {

    const requestParams = {
      $limit: -1,
      campusId,
      active: true,
      '$sort[name]': 1
    };

    const response = await axios.get(MODULES_URL, {
      params: requestParams
    });
    modules = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setModules(modules));
  dispatch(setIsModulesLoading(false));
};

export const fetchModule = (moduleId: string) => async (dispatch: Function) => {
  let module: Module;
  dispatch(setIsModuleLoading(true));

  try {
    const response = await axios.get(`${MODULES_URL}/${moduleId}`);
    module = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  dispatch(setSelectedModule(module));
  dispatch(setIsModuleLoading(false));
};

export const createModule = (moduleData: { name: string; code: string; campusId: string; phaseType: string }) => async (dispatch: Function) => {
  try {
    await axios.post(MODULES_URL, moduleData);
    openSuccessNotification(i18next.t('campus.moduleCreated'));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(fetchModules(moduleData.campusId));
  dispatch(goToOnboardingNextStep());
};

export const updateModule = (moduleData: { _id: string; name: string; code: string; active: boolean; campusId: string }) => async (dispatch: Function) => {
  try {
    await axios.patch(`${MODULES_URL}/${moduleData._id}`, moduleData);
    openSuccessNotification(i18next.t('campus.moduleUpdated'));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(fetchModules(moduleData.campusId));
};

export const deleteModule = (moduleId: string) => async (dispatch: Function) => {
  let module: Module;
  try {
    const response = await axios.patch(`${MODULES_URL}/${moduleId}`, { active: false });
    module = response.data;
    openSuccessNotification(i18next.t('campus.moduleDeleted'));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(fetchModules(module.campusId));
};

export default modulesSlice.reducer;
