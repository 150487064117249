import cx from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar, Badge, Menu, Space, Spin } from 'antd';

import { Stocking } from '../Sowings/interfaces';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import * as campusSlice from '../Units/campusSlice';
import * as stockingSlice from '../Sowings/sowingsSlice';
import { stockingPhaseTypes, THEME } from '../../config/commons';
import * as finishStockingSlice from '../Sowings/finishStockingSlice';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { fetchStockingsToBeFinished } from '../Sowings/stockingToBeFinishedSlice';

import './StockingNotification.scss';
import styles from './StockingNotification.module.scss';


const { Item, SubMenu } = Menu;

interface Props {
  theme?: 'dark' | 'light';
}

export default function StockingNotification (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isLightTheme = theme === THEME.LIGHT;

  const skip = useSelector((state: Store) => state.stockingToBeFinished.skip);
  const limit = useSelector((state: Store) => state.stockingToBeFinished.limit);
  const total = useSelector((state: Store) => state.stockingToBeFinished.total);
  const stockings = useSelector((state: Store) => state.stockingToBeFinished.stockings);
  const showStockingNotification = useSelector((state: Store) => state.stockingToBeFinished.showStockingNotification);

  const { company, phaseType } = useSelector((state: Store) => state.header);

  const [currentPage, setCurrentPage] = useState(((skip / limit) + 1) || 1);

  if (!showStockingNotification) {
    return null;
  }

  const renderIcon = () => {
    if (total === 0) {
      return (
        <span className={styles.noBadgeContainer}>
          <Icon name='notification-2' className={styles.bellIcon} theme={theme} />
        </span>
      );
    }

    return (
      <Badge dot={true} size='default' className={styles.badge} offset={[-4, 2]} >
        <Icon name='notification-2' className={styles.bellIcon} theme={theme} />
      </Badge>
    );
  };

  const renderItem = (stocking: Stocking) => {
    const unitSource = `${stocking.campusId?.name} > ${stocking.moduleId?.name} > ${stocking.tankId?.name}`;

    return (
      <div className={styles.containerItem}>
        <Space direction='horizontal' className={styles.space}>
          <Avatar shape='circle' size='default' className={styles.avatar}>
            <Icon name='plant' type='line' className={styles.planIcon} theme='light' />
          </Avatar>

          <div className={styles.column}>
            <span className={styles.row}>
              {t('notifications.finishStocking.notFinished')}
            </span>

            <span className={styles.row}>
              <span className={styles.labelStocking}>
                {stocking.name}
              </span>
            </span>

            <LrvTooltip
              title={unitSource}
              themeStyle='light'
            >
              <span className={styles.row}>
                <span className={styles.label}>
                  {stocking.campusId?.name}
                </span>

                <Icon name='arrow-right-s' className={styles.arrowIcon} theme='light' />

                <span className={styles.label}>
                  {stocking.moduleId?.name}
                </span>

                <Icon name='arrow-right-s' className={styles.arrowIcon} theme='light' />

                <span className={styles.label}>
                  {stocking.tankId?.name}
                </span>
              </span>
            </LrvTooltip>
          </div>
        </Space>
      </div>
    );
  };

  const renderItems = () => {
    return stockings.map((stocking, index) => {
      return (
        <div className={styles.item} key={stocking._id}>
          <Item
            key={stocking._id}
            onClick={() => {
              dispatch(finishStockingSlice.getAveragePLG(stocking._id));

              if (stocking.phaseType === stockingPhaseTypes.JUVENILE) {
                dispatch(campusSlice.fetchVolumeRanges());
                dispatch(stockingSlice.fetchStockingDateRanges());
              }

              dispatch(stockingSlice.fetchStocking({ id: stocking._id }));
              dispatch(finishStockingSlice.fetchStockingFinishedData());

              dispatch(finishStockingSlice.setShowModalFinish(true));
            }}
          >
            {renderItem(stocking)}
          </Item>
          {stockings.length !== (index + 1) && <LrvDivider className={styles.divider} />}
        </div>
      );
    });
  };

  const nextItems = () => {
    if (!phaseType) {
      return;
    }

    const page = currentPage + 1;
    setCurrentPage(page);

    const props = { page, phaseType, companyId: company._id, stockings: stockings };
    dispatch(fetchStockingsToBeFinished(props));
  };

  const renderLoader = () => {
    return (
      <div className='spin'>
        <Spin />
      </div>
    );
  };

  const renderBody = () => {
    const isEmpty = stockings.length === 0;

    return (
      <InfiniteScroll
        dataLength={stockings.length}
        height={isEmpty ? 150 : 246}
        next={nextItems}
        hasMore={total > (skip + limit)}
        loader={renderLoader()}
        className={isEmpty ? styles.emptyContainer : ''}
      >
        {isEmpty ? <div>{t('notifications.empty')}</div> : renderItems()}
      </InfiniteScroll>
    );
  };

  const renderStockingNotification = () => {
    return (
      <Menu
        className={cx(styles.menu, isLightTheme ? styles.menuLight : styles.menuDark, 'menu')}
        id='menu-notifications'
        inlineIndent={0}
        triggerSubMenuAction='click'
      >
        <SubMenu
          className={styles.submenu}
          key='submenu'
          title={renderIcon()}
        >
          <div
            key='container'
            className={styles.containerNotifications}
          >
            <div className={styles.header}>
              {t('notifications.title')}
            </div>

            <LrvDivider className={styles.divider} />

            {renderBody()}
          </div>
        </SubMenu>
      </Menu>
    );
  };

  return renderStockingNotification();
}
