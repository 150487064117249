import React from 'react';
import cx from 'classnames';
import { Modal } from 'antd';

import DotSpinner from '../DotSpinner/DotSpinner';
import { LrvModalProps } from '../../interfaces/lrvModal';

import styles from './LrvModal.module.scss';

export const LrvModal = (props: LrvModalProps) => {
  const { children, className, theme = 'dark', isLoading = false, maskClosable = false } = props;
  const isDarkTheme = theme === 'dark';

  const lrvModalProps = {
    ...props,
    maskClosable,
    className: cx(styles.lrvModal, isDarkTheme ? styles.darkModal : styles.lightModal, className),
  };

  const renderSppinner = () => {
    if (!isLoading) {
      return null;
    }

    return (
      <div className={styles.spinner}>
        <DotSpinner theme='dark' />
      </div>
    );
  };

  return (
    <Modal {...lrvModalProps}>
      {renderSppinner()}
      {children}
    </Modal>
  );
};
