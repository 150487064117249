import React, { forwardRef } from 'react';
import cx from 'classnames';
import { Input, InputRef } from 'antd';

import { LrvInputProps } from '../../interfaces/lrvInput';

import styles from './LrvInput.module.scss';

export const LrvInput = forwardRef<InputRef, LrvInputProps>((props, ref) => {
  const { className, containerClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvInputProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkInput : styles.lightInput, className),
  };

  return (
    <div className={cx(styles.container, containerClassName)}>
      <Input {...lrvInputProps} ref={ref}>
      </Input>
    </div>
  );
});
