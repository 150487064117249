import React from 'react';
import cx from 'classnames';

import Icon from '../Icon/Icon';
import { removeSpaces } from '../../../utils/strings';

import styles from './TagWrapper.module.scss';

interface Props {
  text: string;
  className?: string;
  onClose: React.MouseEventHandler<HTMLDivElement>;
}

export default function TagWrapper (props: Props) {
  const { text, className, onClose } = props;
  const nameWithoutSpaces = removeSpaces(text.toLowerCase());

  return <div className={cx(styles.tagWrapper, className)}>
    <div className={styles.text}>{text}</div>
    <div className={styles.close} onClick={onClose}>
      <Icon id={`delete_item_${nameWithoutSpaces}`} name='close' />
    </div>
  </div>;
}
