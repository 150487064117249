export interface ProfileState {
  _id: string;
  firstName: string;
  lastName: string;
  language: string;
  defaultPhase: string;
  email: string;
  active: boolean;
  theme?: 'dark' | 'light' | undefined;
  isLoading?: boolean;
  imageAPI: APIToken;
  dataAPI: APIToken;
  allowDataApi: boolean;
  allowImageApi: boolean;
  settingTab?: SettingsTab;
}

export interface APIToken {
  _id: string;
  companyId: string;
  url: string;
  apiKey: string;
  token: string;
}

export interface Banner {
  companyIds: string[];
  en: string;
  es: string;
  pt: string;
  showAlert: boolean;
  persistentMode: boolean;
  endDate?: string;
  backgroundColor: string;
  icon: string;
  alertClosed?: boolean;
}

export interface ScheduleRuleState {
  _id: string;
  companyId: string;
  hours: string[];
  isLoading: boolean;
}

export interface ScheduleRule {
  _id: string;
  companyId: string;
  hours: string[];
}

export type SettingsTab = 'USER' |
  'PASSWORD' | 'API_SERVICE' | 'COUPON' |
  'BANNER' | 'SCHEDULE' | 'NOVELTIES';

export const settingsTabs: Record<SettingsTab, SettingsTab> = {
  USER: 'USER',
  PASSWORD: 'PASSWORD',
  API_SERVICE: 'API_SERVICE',
  COUPON: 'COUPON',
  BANNER: 'BANNER',
  SCHEDULE: 'SCHEDULE',
  NOVELTIES: 'NOVELTIES',
};
