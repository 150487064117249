import { AxiosResponse } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserSession } from '../../common/interfaces/auth';
import { ApiClient, axiosClient as axios } from '../../utils/axios_instance';
import { USERS_URL, IMPERSONATE_URL, REFRESH_TOKEN_URL } from '../../config/config.api';

import { UserImpersonate, UsersImpersonateState } from './interfaces';

const initialState: UsersImpersonateState = {
  users: [],
  isUsersLoading: false,
  showModal: false,
};

export const userSlice = createSlice({
  name: 'impersonate',
  initialState,
  reducers: {
    setUsers: (state: UsersImpersonateState, action: PayloadAction<UserImpersonate[]>) => {
      state.users = action.payload;
    },
    setIsUsersLoading: (state: UsersImpersonateState, action: PayloadAction<boolean>) => {
      state.isUsersLoading = action.payload;
    },
    setShowModalImpersonate: (state: UsersImpersonateState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const { setUsers, setIsUsersLoading, setShowModalImpersonate } = userSlice.actions;

export const fetchUsers = () => async (dispatch: Function) => {
  dispatch(setIsUsersLoading(true));

  const params = {
    $limit: -1,
    active: true,
    $select: ['firstName', 'lastName', 'companyId', 'email', 'active', 'isAssignedCompany', 'allowXlsxAnalysisReport', 'haveAccessPaymentReport', 'language'],
    $populate: ['companyId'],
  };

  try {
    const response = await axios.get<UserImpersonate[]>(USERS_URL, { params });
    dispatch(setUsers(response.data));
    dispatch(setIsUsersLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchImpersonate = (params: { userId: string; firstName: string; lastName: string; companyId: string; email: string; active: boolean; isAssignedCompany: boolean; allowXlsxAnalysisReport: boolean; haveAccessPaymentReport: boolean; language: string }) => async () => {
  const { userId, firstName, lastName, companyId, email, active, isAssignedCompany, allowXlsxAnalysisReport, haveAccessPaymentReport, language } = params;

  try {
    const response = await axios.post(`${IMPERSONATE_URL}/${userId}`);

    localStorage.setItem('refreshTokenSecondary', localStorage.getItem('refreshToken') ?? '');
    localStorage.setItem('accessTokenSecondary', localStorage.getItem('accessToken') ?? '');
    localStorage.setItem('userSecondary', localStorage.getItem('user') ?? '');
    localStorage.setItem('isAssignedCompanySecondary', localStorage.getItem('isAssignedCompany') ?? '');
    localStorage.setItem('isDistributorSecondary', localStorage.getItem('isDistributor') ?? '');

    localStorage.setItem('impersonate', 'true');
    localStorage.setItem('user', JSON.stringify({ _id: userId, firstName, lastName, companyId, email, active, isAssignedCompany, allowXlsxAnalysisReport, haveAccessPaymentReport, language }));
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('isAssignedCompany', response.data.isAssignedCompany);
    localStorage.setItem('isDistributor', response.data.isDistributor);

    window.location.href = '/';
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const backAccount = () => async () => {
  try {
    const userSession: UserSession = JSON.parse(
      localStorage.getItem('userSecondary') || '{}'
    );

    const apiClient: ApiClient = new ApiClient(true, 'accessTokenSecondary');
    const response = await apiClient.axios.post(`${IMPERSONATE_URL}/${userSession._id}`);

    editLocalStorage(response);
    window.location.href = '/';
  } catch (e) {
    if (!!e.response?.status && e.response.status === 401) {
      await refreshToken();
    }
  }
};

const refreshToken = async () => {
  try {
    const apiClient: ApiClient = new ApiClient();
    const refreshToken: string = localStorage.getItem('refreshTokenSecondary') as string;
    const response = await apiClient.axios.post(REFRESH_TOKEN_URL, { refreshToken });

    editLocalStorage(response);
    window.location.href = '/';
  } catch (e) {
    console.log(e.response);
    editLocalStorage();
    window.location.href = '/';
  }
};

const editLocalStorage = (response?: AxiosResponse) => {
  if (response) {
    localStorage.setItem('refreshToken', response.data.refreshToken);
    localStorage.setItem('accessToken', response.data.accessToken);
  } else {
    localStorage.setItem('refreshToken', localStorage.getItem('refreshTokenSecondary') ?? '');
    localStorage.setItem('accessToken', localStorage.getItem('accessTokenSecondary') ?? '');
  }

  localStorage.setItem('isAssignedCompany', localStorage.getItem('isAssignedCompanySecondary') ?? '');
  localStorage.setItem('isDistributor', localStorage.getItem('isDistributorSecondary') ?? '');
  localStorage.setItem('user', localStorage.getItem('userSecondary') ?? '');

  localStorage.removeItem('impersonate');
  localStorage.removeItem('refreshTokenSecondary');
  localStorage.removeItem('accessTokenSecondary');
  localStorage.removeItem('userSecondary');
  localStorage.removeItem('isAssignedCompanySecondary');
  localStorage.removeItem('isDistributorSecondary');
  localStorage.removeItem('daysToInitialStageSecondary');
};


export default userSlice.reducer;
