import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { calculateDateRange } from '../../Sowings/Parameters/helpers';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';
import * as parameterChartSlice from '../../Sowings/Parameters/parameterChartSlice';
import * as stockingAnalysisSlice from '../../Sowings/Analysis/stockingAnalysisSlice';

import styles from './ParameterGraph.module.scss';
import { StockingParameterData } from './interfaces';
import ParameterStateD3 from './ParameterStateD3';
import { generateStockingParameterValues, getAllDataParameters, getAxisXDate, getAxisY, minimumItems } from './helpers';

interface Props {
  data: StockingParameterData;
  containerId: string;
  stockingId: string;
  stockingName: string;
}

export const StockingParameterGraph = (props: Props) => {
  const { data, containerId, stockingId, stockingName } = props;

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const refChartMain = useRef<HTMLDivElement>(null);

  const {
    selectedDateOption,
    selectedParameterName,
    unitId,
    moduleId,
    stockingParameterGraphs,
    selectedReferenceCurve,
  } = useSelector((state: Store) => state.parameterState);

  const {
    phaseType,
    company,
  } = useSelector((state: Store) => state.header);

  const height = 200;
  const width = 220;

  useEffect(() => {
    if (!selectedParameterName || data.values.length < minimumItems) {
      return;
    }

    const dates = getAxisXDate({ stockingParameters: data.values });
    const { maxDate, minDate } = calculateDateRange({ defaultMaxDate: dates.maxDate.toString(), defaultMinDate: dates.minDate.toString(), option: selectedDateOption });
    const referenceCurves = generateStockingParameterValues({ referenceCurve: selectedReferenceCurve, stockingParameters: data.values, phaseType });

    const allData = getAllDataParameters({ selectedParameterName, stockingParameterGraphs });
    const { maxY, minY } = getAxisY({ referenceCurves, stockingParameters: allData });

    new ParameterStateD3({
      container: refChartMain.current,
      data,
      height,
      maxDate,
      minDate,
      parameter: selectedParameterName,
      theme,
      width,
      maxY,
      minY,
      referenceCurves,
    });
  }, [dispatch, width, height, theme, data, selectedDateOption, selectedParameterName, refChartMain.current, selectedReferenceCurve?._id, phaseType]);

  if (data.values.length < minimumItems) {
    return null;
  }

  const navigateToComparisionGraph = () => {
    if (!unitId || !moduleId) {
      return;
    }

    const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
    dispatch(stockingAnalysisSlice.setBrowsingFromStocking(true));

    dispatch(stockingAnalysisSlice.fetchUnits({ companyId: company._id, phaseType: unitPhaseType, modulePhaseType: phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setUnitSelected(unitId));
    dispatch(stockingAnalysisSlice.fetchModules({ unitId, phaseType: phaseType, selectFirstItem: false }));
    dispatch(stockingAnalysisSlice.setModuleSelected(moduleId));
    dispatch(stockingAnalysisSlice.fetchContainers({ unitId, moduleId }));
    dispatch(stockingAnalysisSlice.setContainerSelected(containerId));
    dispatch(stockingAnalysisSlice.fetchStocking({ stockingId }));

    history.push('/production/insights');
  };

  const navigateToParameterGraph = () => {
    if (!unitId || !moduleId) {
      return;
    }

    dispatch(parameterChartSlice.setUnitId(unitId));

    dispatch(parameterChartSlice.fetchModules({ unitId }));
    dispatch(parameterChartSlice.setModuleId(moduleId));

    dispatch(parameterChartSlice.fetchContainers({ unitId, moduleId }));
    dispatch(parameterChartSlice.setContainerId(containerId));

    dispatch(parameterChartSlice.fetchStockings({ unitId, moduleId, containerId, companyId: company._id }));
    dispatch(parameterChartSlice.setStockingId(stockingId));

    dispatch(parameterChartSlice.fetchStockingParameterChart({ companyId: company._id, stockingId }));

    history.push('/production/parameters');
  };

  const navigateTo = () => {
    switch (selectedParameterName) {
      case 'averageWeight':
        navigateToComparisionGraph();
        break;

      case 'biomass':
      case 'totalFeed':
        navigateToParameterGraph();
        break;
    }
  };

  return (
    <LrvTooltip
      title={t(`parameterStateGraph.tooltip.${selectedParameterName}`)}
    >
      <div className={styles.square} onClick={navigateTo}>
        <LrvText className={styles.stockingName} text={stockingName} theme={theme} />
        <div ref={refChartMain} />
      </div>
    </LrvTooltip>
  );
};
