import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ItemReferenceCurves } from '../../Units/interfaces';
import { ReferenceCurves } from '../../Sowings/Analysis/interfaces';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { analysisStatuses, stockingPhaseTypes } from '../../../config/commons';
import { typeParam } from '../../../common/components/charts/ShadedPlot/helpers';
import { ANALYSES_URL, GROWTH_DELTA_URL, REFERENCE_CURVES_URL } from '../../../config/config.api';

import { GrowthDelta, GrowthDeltaData, StockingChartState } from './interfaces';

const initialState: StockingChartState = {
  plg: [],
  uniformity: [],
  averageWeight: [],
  growthDelta: [],
  analysis: [],
  total: 0,
  skip: 0,
  limit: 0,
  isFetchAnalysis: false,
  isFetchMetrics: false,
};

export const chartsSlice = createSlice({
  name: 'stockingCharts',
  initialState,
  reducers: {
    setGlobalPlgReferenceCurves: (state: StockingChartState, action: PayloadAction<ItemReferenceCurves[]>) => {
      state.plg = action.payload;
    },
    setGlobalUniformityReferenceCurves: (state: StockingChartState, action: PayloadAction<ItemReferenceCurves[]>) => {
      state.uniformity = action.payload;
    },
    setGlobalAverageWeightReferenceCurves: (state: StockingChartState, action: PayloadAction<ItemReferenceCurves[]>) => {
      state.averageWeight = action.payload;
    },

    setAnalysis: (state: StockingChartState, action: PayloadAction<[]>) => {
      state.analysis = action.payload;
    },
    setIsFetchAnalysis: (state: StockingChartState, action: PayloadAction<boolean>) => {
      state.isFetchAnalysis = action.payload;
    },
    setIsFetchMetrics: (state: StockingChartState, action: PayloadAction<boolean>) => {
      state.isFetchMetrics = action.payload;
    },
    setGrowthDelta: (state: StockingChartState, action: PayloadAction<GrowthDelta[]>) => {
      state.growthDelta = action.payload;
    },
  },
});

export const {
  setGlobalPlgReferenceCurves,
  setGlobalUniformityReferenceCurves,
  setGlobalAverageWeightReferenceCurves,
  setAnalysis, setIsFetchAnalysis,
  setIsFetchMetrics, setGrowthDelta
} = chartsSlice.actions;

export const fetchAnalysis = (props: { stockingId: string; showLoading: boolean; token?: string }) => async (dispatch: Function) => {
  const { stockingId, showLoading, token } = props;

  if (showLoading) {
    dispatch(setIsFetchAnalysis(true));
  }

  const params = {
    $limit: '-1',
    sowingId: stockingId,
    hidden: false,
    $select: ['code', 'resultData.averageWeight', 'inputData.stage', 'resultData.uniformity', 'resultData.larvaePerGram', 'resultData.averageLength', 'sowingId', 'resultData.variationCoefficientLength', 'resultData.histogramPigmentation'],
    '$sort[stage]': '1',
    '$or[0][status]': analysisStatuses.COMPLETED,
  };
  let response;

  try {
    if (token) {
      response = await axios.get(ANALYSES_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get(ANALYSES_URL, { params: params });
    }

    dispatch(setAnalysis(response.data));
    if (showLoading) {
      dispatch(setIsFetchAnalysis(false));
    }
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchGlobalPlgReferenceCurves = (props: { phaseType: string; token?: string; }) => async (dispatch: Function) => {
  const { phaseType, token } = props;

  dispatch(setGlobalPlgReferenceCurves([]));

  if (phaseType === stockingPhaseTypes.ADULT) {
    return;
  }

  const params = {
    $limit: -1,
    'companyId[$exists]': false,
    phaseType,
    type: typeParam.PLG,
  };

  let response;

  try {
    if (token) {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, { params: params });
    }

    dispatch(setGlobalPlgReferenceCurves(response.data[0].values));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGlobalUniformityReferenceCurves = (props: { phaseType: string; token?: string; }) => async (dispatch: Function) => {
  const { phaseType, token } = props;

  dispatch(setGlobalUniformityReferenceCurves([]));

  if (phaseType === stockingPhaseTypes.ADULT) {
    return;
  }

  const params = {
    $limit: -1,
    'companyId[$exists]': false,
    phaseType,
    type: typeParam.UNIFORMITY,
  };

  let response;

  try {
    if (token) {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, { params: params });
    }

    dispatch(setGlobalUniformityReferenceCurves(response.data[0].values));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGlobalAverageWeightReferenceCurves = (props: { phaseType: string; token?: string; }) => async (dispatch: Function) => {
  const { phaseType, token } = props;

  dispatch(setGlobalAverageWeightReferenceCurves([]));

  const params = {
    $limit: -1,
    'companyId[$exists]': false,
    phaseType,
    type: typeParam.AVG_WEIGHT,
  };

  let response;

  try {
    if (token) {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, {
        headers: { 'Authorization': token },
        params: params,
      });
    } else {
      response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, { params: params });
    }

    dispatch(setGlobalAverageWeightReferenceCurves(response.data[0].values));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGrowthDelta = (props: { stockingId: string; token?: string }) => async (dispatch: Function) => {
  const { stockingId, token } = props;
  dispatch(setIsFetchMetrics(true));

  try {
    const params = new URLSearchParams({ sowingId: stockingId });
    let response;

    if (token) {
      response = await axios.get<GrowthDeltaData[]>(GROWTH_DELTA_URL, {
        headers: { 'Authorization': token },
        params,
      });
    } else {
      response = await axios.get<GrowthDeltaData[]>(GROWTH_DELTA_URL, { params });
    }

    const deltaData: GrowthDelta[] = [{ stockingId: stockingId, data: response.data }];
    dispatch(setGrowthDelta(deltaData));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsFetchMetrics(false));
};

export default chartsSlice.reducer;
