import moment from 'moment';
import cx from 'classnames';
import { Row, Col } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import SamplesByUser from '../SamplesByUser/SamplesByUser';
import Content from '../../common/components/Content/Content';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { isAdminUser, onboardingTypes } from '../../config/commons';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { getUnitPhaseTypeFromAnalysis } from '../../helpers/units.helpers';
import { setShowSamplesByUserModal } from '../SamplesByUser/samplesByUserSlice';
import { setOnboardingType, setShouldRun, setRun, setStepIndex, setExistsUserData } from '../../common/components/Onboarding/OnboardingSlice';

import { UserActivity } from './interfaces';
import styles from './ActivityDashboard.module.scss';
import UsersActivity from './widgets/UsersActivity';
import CampusActivity from './widgets/CampusActivity';
import StagesActivity from './widgets/StagesActivity';
import AnalysisResults from './widgets/AnalysisResults';
import ActivityDashboardFilters from './ActivityDashboardFilters';
import * as activityDashboardSlice from './activityDashboardSlice';
import { getAmountAnalysis, getAnalysisUsageLabel } from './helpers';
import UserActivityDashboard from './UserActivityDashboard/UserActivityDashboard';
import * as userActivityDashboardSlice from './UserActivityDashboard/userActivityDashboardSlice';


function ActivityDashboard () {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const fromDate = useSelector((state: Store) => state.activityDashboard.filters.fromDate);
  const toDate = useSelector((state: Store) => state.activityDashboard.filters.toDate);
  const usersActivity = useSelector((state: Store) => state.activityDashboard.usersActivity as UserActivity[]);

  const { showModal: showSamplesByUserModal, isLoading } = useSelector((state: Store) => state.samplesByUser);
  const { showModal: userActivityDashboardModal, filters } = useSelector((state: Store) => state.userActivityDashboard);
  const onboardingUserData = useSelector((state: Store) => state.onboarding.user);
  const existsUserData = useSelector((state: Store) => state.onboarding.existsUserData);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const { frequency } = filters;
  const lastDays = moment(toDate).diff(moment(fromDate), 'days') + 1;

  const theme = getCurrentTheme();

  useEffect(() => {
    return () => {
      dispatch(setExistsUserData(false));
    };
  }, [dispatch]);

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.clientDashboard) {
      setTimeout(() => {
        dispatch(setStepIndex(0));
        dispatch(setOnboardingType(onboardingTypes.CLIENT_DASHBOARD));
        dispatch(setShouldRun(true));
        dispatch(setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    const subtitle = t('clientDashboard.subtitle', { days: lastDays }) + ' - ' + t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis(usersActivity) });
    dispatch(changeHeader({ title: 'clientDashboard.title', subtitle: subtitle }));
  }, [dispatch, lastDays, t, usersActivity]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(activityDashboardSlice.setSelectedCampus({ _id: '', name: '' }));
    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(activityDashboardSlice.fetchCampuses(unitPhaseType, selectedCompany._id));
  }, [dispatch, selectedCompany._id, phaseType]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(activityDashboardSlice.resetActivityDashboardFilters());
  }, [dispatch, selectedCompany._id]);

  const renderLastDaysLabels = () => {
    const subtitle = t('clientDashboard.subtitle', { days: lastDays }) + ' - ' + t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis(usersActivity) });

    return (
      <Row className={styles.lastDays}>
        <LrvText text={subtitle} theme={theme} />
      </Row>
    );
  };

  const renderClientDashboardFilters = () => {
    return (
      <Row className={styles.filters}>
        <Col className={styles.col}>
          <ActivityDashboardFilters />
        </Col>
      </Row>
    );
  };

  return <div className={styles.clientsDashboard}>
    {renderLastDaysLabels()}
    {renderClientDashboardFilters()}

    <div className={styles.charts}>
      <Row className={cx(styles.row, styles.firstRow)}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.usersActivity}>
          <Content
            contentId='userActivity'
            className={styles.widget}
            title={t('clientDashboard.usersActivity')}
            noPadding
            theme={theme}
          >
            <UsersActivity theme={theme} />
          </Content>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} className={styles.campusActivity}>
          <Content
            contentId='campusActivity'
            className={styles.widget}
            title={t('clientDashboard.campusActivity')}
            noPadding
            theme={theme}
          >
            <CampusActivity theme={theme} />
          </Content>
        </Col>
      </Row>

      {
        isAdminUser() ?
          <Row className={styles.row} >
            <Col xs={24} sm={24} md={24} lg={8} xl={8} className={styles.analysisResults}>
              <Content
                contentId='analysisActivity'
                className={styles.widget}
                title={t('clientDashboard.analysisResults')}
                noPadding
                theme={theme}
              >
                <AnalysisResults theme={theme} />
              </Content>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} className={styles.analysisUsage}>
              <Content
                contentId='stageActivity'
                className={styles.widget}
                title={getAnalysisUsageLabel(phaseType)}
                noPadding
                theme={theme}
              >
                <StagesActivity theme={theme} />
              </Content>
            </Col>
          </Row> :
          <Row className={styles.analysisPerStage} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className={styles.analysisUsage}>
              <Content
                contentId='stageActivity'
                className={styles.widget}
                title={getAnalysisUsageLabel(phaseType)}
                noPadding
                theme={theme}
              >
                <StagesActivity theme={theme} />
              </Content>
            </Col>
          </Row>
      }
    </div>

    <LrvModal
      theme='light'
      title={t('clientDashboard.userActivityDashboard.title')}
      open={userActivityDashboardModal}
      destroyOnClose={true}
      onCancel={() => dispatch(userActivityDashboardSlice.setShowUserActivityDashboardModal(false))}
      className={frequency === 'WEEKLY' ? cx('userActivityDashboardModal', 'weeklyUserActivityDashboardModal') : 'userActivityDashboardModal'}
      closeIcon={<Icon id='close_user_activity_dashboard_modal' name='close' />}
    >
      <UserActivityDashboard />
    </LrvModal>

    <LrvModal
      theme='light'
      isLoading={isLoading}
      title={t('clientDashboard.samplesByUser.title')}
      open={showSamplesByUserModal}
      destroyOnClose={true}
      onCancel={() => dispatch(setShowSamplesByUserModal(false))}
      className='samplesByUser'
      closeIcon={<Icon id='close_samples_by_user_modal' name='close' />}
    >
      <SamplesByUser theme='light' />
    </LrvModal>
  </div>;
}

export default ActivityDashboard;
