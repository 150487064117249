import React, { useEffect, useState } from 'react';

import { checkIsLoggedIn } from '../../../utils/checkRoutes';

import styles from './MouseTooltip.module.scss';


interface Props {
  children: React.ReactNode;
  offsetX: number;
  offsetY: number;
}

export default function MouseTooltip (props: Props) {
  const { offsetX, offsetY, children } = props;
  const [xPosition, setXPosition] = useState(0);
  const [yPosition, setYPosition] = useState(0);
  const [mouseMoved, setMouseMoved] = useState(false);

  const impersonate = localStorage.getItem('impersonate');
  const isLoggedIn = checkIsLoggedIn();
  const isImpersonating = impersonate === 'true' && isLoggedIn;

  useEffect(() => {
    const getTooltipPosition = (params: { clientX: number; clientY: number }) => {
      setXPosition(params.clientX);
      setYPosition(params.clientY);
      setMouseMoved(true);
    };

    const addListener = () => {
      window.addEventListener('mousemove', getTooltipPosition);
    };

    const removeListener = () => {
      window.removeEventListener('mousemove', getTooltipPosition);
    };

    addListener();
    return () => {
      removeListener();
    };

  }, []);

  return (
    <div
      id='impersonate_icon'
      className={styles.mouseTooltip}
      style={{
        display: isImpersonating && mouseMoved ? 'block' : 'none',
        position: 'fixed',
        top: yPosition + offsetY,
        left: xPosition + offsetX
      }}
    >
      {children}
    </div>
  );
}
