import cx from 'classnames';

import SampleD3 from '../SampleD3';
import { Percentile } from '../../interfaces';
import { THEME } from '../../../../../config/commons';

import Title from './Title';
import Subtitle from './Subtitle';
import Percentiles from './Percentiles';
import DiseaseDropdown from './DiseaseDropdown';
import styles from './NewAnimalPanel.module.scss';

interface Props {
  listPercentile: Percentile[];
  labelsUnit: string;
  sampleChartFullScreen?: SampleD3 | null;
  theme?: 'dark' | 'light';
  addAnimal: () => void;
}

export default function NewAnimalPanel (props: Props) {
  const {
    addAnimal,
    labelsUnit,
    listPercentile,
    sampleChartFullScreen,
    theme = 'dark',
  } = props;

  const isLightTheme = theme === THEME.LIGHT;

  return (
    <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark)}>
      <Title theme={theme} />
      <Subtitle
        addAnimal={addAnimal}
        theme={theme}
      />
      <Percentiles
        labelsUnit={labelsUnit}
        listPercentile={listPercentile}
        theme={theme}
        sampleChartFullScreen={sampleChartFullScreen}
      />
      <DiseaseDropdown theme={theme} />
    </div>
  );
}
