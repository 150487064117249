import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import * as sampleSlice from '../sampleSlice';
import { Store } from '../../../../../state/store.interfaces';
import Icon from '../../../../../common/components/Icon/Icon';
import { LrvSelect } from '../../../../../common/components/LrvSelect/LrvSelect';

import styles from './DiseaseDropdown.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export default function DiseaseDropdown (props: Props) {
  const {
    theme = 'dark',
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    disease,
    diseases,
  } = useSelector((state: Store) => state.sample);

  return (
    <div
      className={styles.diseases}
    >
      <LrvSelect
        id='dropdown_diseases'
        theme={theme}
        showSearch
        value={disease || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('detail.manageAnimals.disease')}
        placeholder={t('detail.manageAnimals.disease')}
        onChange={(value) => {
          dispatch(sampleSlice.setDisease(value));
        }}
        dropdownMatchSelectWidth={false}
        optionLabelProp='label'
      >
        {diseases.map((disease) => <Option key={disease} value={disease}>{disease}</Option>)}
      </LrvSelect>
    </div>
  );
}
