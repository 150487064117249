import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ANALYSES_URL } from '../../config/config.api';
import { axiosClient as axios } from '../../utils/axios_instance';

import { SamplesByUserState, SamplesByUser } from './interfaces';

const initialState: SamplesByUserState = {
  samplesByUsers: {},
  isLoading: false,
  showModal: false,
  filters: {
    company: {
      _id: '',
      name: '',
      allowBeta: false,
    },
    fromDate: '',
    toDate: '',
    analysisPhaseType: ''
  },
};

export const samplesByUserSlice = createSlice({
  name: 'samplesByUser',
  initialState,
  reducers: {
    setSamplesByUsers: (state: SamplesByUserState, action: PayloadAction<SamplesByUser>) => {
      state.samplesByUsers = action.payload;
    },
    setLoading: (state: SamplesByUserState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowSamplesByUserModal: (state: SamplesByUserState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setFiltersSampleByUser: (state: SamplesByUserState, action: PayloadAction<{ company: { _id: string; name: string; allowBeta?: boolean }; fromDate: string; toDate: string; analysisPhaseType: string }>) => {
      state.filters = action.payload;
    },
  }
});

export const { setSamplesByUsers, setLoading, setShowSamplesByUserModal, setFiltersSampleByUser } = samplesByUserSlice.actions;

export const fetchSamplesByUsers = (props: { companyId: string; fromDate: string; toDate: string; analysisPhaseType?: string }) => async (dispatch: Function) => {
  const { analysisPhaseType, companyId, fromDate, toDate } = props;
  dispatch(setLoading(true));

  const url = `${ANALYSES_URL}/${companyId}/samples-by-users`;

  const params = {
    analysisPhaseType,
    fromDate,
    toDate,
  };

  try {
    const response = await axios.get<SamplesByUser>(url, {
      params: params,
    });

    dispatch(setSamplesByUsers(response.data));
    dispatch(setLoading(false));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export default samplesByUserSlice.reducer;
