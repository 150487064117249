import React from 'react';
import cx from 'classnames';
import { Input } from 'antd';

import { LrvTextAreaProps } from '../../interfaces/lrvTextArea';

import styles from './LrvTextArea.module.scss';

const { TextArea } = Input;

export const LrvTextArea = (props: LrvTextAreaProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkTextArea : styles.lightTextArea, className),
  };

  return (
    <TextArea {...lrvProps}>
    </TextArea>
  );
};
