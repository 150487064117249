import cx from 'classnames';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import Icon from '../Icon/Icon';
import { LrvAvatarPhase } from '../LrvAvatarPhase/LrvAvatarPhase';
import { LrvFilterPanelProps } from '../../interfaces/lrvFilterPanel';
import { getCurrentTheme } from '../../../helpers/theme';
import { THEME } from '../../../config/commons';

import styles from './LrvFilterPanel.module.scss';

export const LrvFilterPanel = (props: LrvFilterPanelProps) => {
  const {
    className, containerClassName, bodyClassName, headerClassName, // classNames
    title, children, // React Nodes
    cleanButtonProps, onClick, onClose,
    showFilterIcon = false,
  } = props;
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const [showPanel, setShowPanel] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => { // Close panel when click outside
    const handleClickOutside = (event: Event) => {
      const target = event.target as HTMLElement;
      const targetClass = target.classList.value;
      const isClickOnDropdown = targetClass.includes('ant-select') || targetClass.includes('ant-picker') || targetClass === '' || targetClass.includes('ri-settings') || targetClass.includes('LrvAvatarPhase');

      if (divRef.current && !divRef.current.contains(event.target as Node) && showPanel && !isClickOnDropdown) {
        setShowPanel(!showPanel);
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPanel]);

  const renderCleanButton = () => {
    return (
      <Button
        {...cleanButtonProps}
        type='text'
        icon={<Icon name='eraser' />}
        className={cx(styles.cleanButton, cleanButtonProps?.className)}
      />
    );
  };

  return (
    <>
      <div className={styles.floatingButton}>
        <div className={cx(styles.containerAvatar, isLightTheme ? styles.lightContainerAvatar : styles.darkContainerAvatar)}>
          <LrvAvatarPhase
            showFilterIcon={showFilterIcon}
            onClick={() => {
              setShowPanel(!showPanel);

              if (!showPanel && onClick) {
                onClick();
                return;
              }

              if (showPanel && onClose) {
                onClose();
              }
            }} />
        </div>
      </div>

      <div className={styles.containerPanel} ref={divRef}>
        <div className={showPanel ? styles.showPanel : styles.hidePanel} >
          <div className={cx(styles.filterPanel, className)}>
            <div className={cx(styles.container, containerClassName)}>
              <div className={cx(styles.header, headerClassName)}>
                <div>{title}</div>
                {renderCleanButton()}
              </div>

              <div className={styles.line}></div>

              <div className={cx(styles.body, bodyClassName)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
