

import React from 'react';
import cx from 'classnames';

import { THEME } from '../../../config/commons';

import styles from './HorizontalGradient.module.scss';

export default function HorizontalGradient (props: {
  children: React.ReactNode;
  showGradient?: boolean;
  className?: string;
  width?: number;
  id?: string;
  theme?: 'light' | 'dark'
}) {
  const { id, children, showGradient = true, className, width, theme = 'dark' } = props;
  const isLightTheme = theme === THEME.LIGHT;

  const style = width ? { width: width } : {};
  const scrollGradientStyle = cx(styles.scrollGradient, isLightTheme ? styles.scrollGradientLight : styles.scrollGradientDark);

  return (
    <div id={id} className={cx(showGradient ? scrollGradientStyle : '', className)} style={style}>
      {children}
    </div>
  );
}
