import { useEffect, useState } from 'react';

import { Location } from '../pages/Tanks/interfaces';

interface Props {
  containerLocation?: Location;
}

export const useLocation = (props: Props) => {
  const { containerLocation } = props;

  const [location, setLocation] = useState<Location | undefined>({ latitude: 0, longitude: 0 });
  const [isLocationFetchError, setIsLocationFetchError] = useState<boolean>(false);

  useEffect(() => {
    if (containerLocation?.latitude && containerLocation?.longitude) {
      setLocation(containerLocation);
      return;
    }

    if (!navigator.geolocation) {
      setLocation({ latitude: 0, longitude: 0 });
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setLocation({ latitude, longitude });
      },
      (error) => {
        console.error(error);
        setIsLocationFetchError(true);
        setLocation({ latitude: 0, longitude: 0 });
      },
    );

    return (() => {
      setIsLocationFetchError(false);
    });
  }, []);

  return {
    location,
    isLocationFetchError,
  };
};