import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { stockingPhaseTypes, unitStatuses } from '../../../config/commons';
import { CAMPUS_URL, MODULES_URL, PREV_STATS_URL } from '../../../config/config.api';

import { PrevStatsState, Module, ChartData, Unit, AvailableTanksByModule, PrevTankSelected } from './interfaces';

const initialState: PrevStatsState = {
  dataSource: [],
  isLoadingPrevStats: false,
  modulesList: [],
  unitsList: [],
  moduleSelected: '',
  unitSelected: '',
  availableTanksByModules: [],
  prevTanksSelected: [],
};

export const prevStatsSlice = createSlice({
  name: 'prevStats',
  initialState,
  reducers: {
    setIsLoadingPrevStats: (state: PrevStatsState, action: PayloadAction<boolean>) => {
      state.isLoadingPrevStats = action.payload;
    },
    setUnitsList: (state: PrevStatsState, action: PayloadAction<Unit[]>) => {
      state.unitsList = action.payload;
    },
    setModulesList: (state: PrevStatsState, action: PayloadAction<Module[]>) => {
      state.modulesList = action.payload;
    },
    setUnitSelected: (state: PrevStatsState, action: PayloadAction<string>) => {
      state.unitSelected = action.payload;
    },
    setModuleSelected: (state: PrevStatsState, action: PayloadAction<string>) => {
      state.moduleSelected = action.payload;
    },
    setPrevTanksAvailable: (state: PrevStatsState, action: PayloadAction<AvailableTanksByModule[]>) => {
      state.availableTanksByModules = action.payload;
    },
    setTanksSelected: (state: PrevStatsState, action: PayloadAction<PrevTankSelected[]>) => {
      state.prevTanksSelected = action.payload;
    },
    setChartData: (state: PrevStatsState, action: PayloadAction<ChartData[]>) => {
      state.dataSource = action.payload;
    },
  },
});

export const {
  setIsLoadingPrevStats, setChartData, setModulesList, setUnitsList,
  setUnitSelected, setModuleSelected, setPrevTanksAvailable, setTanksSelected,
} = prevStatsSlice.actions;

export const fetchUnitsList = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: [unitStatuses.ACTIVE],
    '$sort[name]': 1
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: unitParams });
    dispatch(setUnitsList(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModulesList = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    phaseType: stockingPhaseTypes.ADULT,
    campusId: campusId || undefined,
  };

  try {
    const response = await axios.get(MODULES_URL, { params: params });
    dispatch(setModulesList(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
};

export const fetchChartDataSource = (params: { companyId: string; moduleId: string }) => async (dispatch: Function) => {
  dispatch(setIsLoadingPrevStats(true));
  try {
    const response = await axios.get(PREV_STATS_URL, { params });
    const dataSource = response?.data?.dataSource;

    if (!dataSource || dataSource?.length === 0) {
      dispatch(setChartData([]));
      dispatch(setPrevTanksAvailable([]));
      dispatch(setIsLoadingPrevStats(false));
      return;
    }

    const availableTanksByModules = response.data.availableTanksByModules;
    dispatch(setChartData(dataSource));
    dispatch(setPrevTanksAvailable(availableTanksByModules));

  } catch (error) {
    dispatch(setChartData([]));
    dispatch(setPrevTanksAvailable([]));
    console.log('ERROR', error);
  }
  dispatch(setIsLoadingPrevStats(false));
};

export default prevStatsSlice.reducer;