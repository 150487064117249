import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';

import styles from './TabFormEdit.module.scss';
import { updateCredential } from './scheduleRuleSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;
const maxHours = 4;

export default function TabScheduleRule (props: Props) {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [formScheduleRule] = Form.useForm();

  const [hours, setHours] = useState<string[]>([]);

  const scheduleRule = useSelector((state: Store) => state.scheduleRule);
  const disabledSaveButton = hours.length === 0 || hours.length > 4;

  useEffect(() => {
    if (!scheduleRule?.hours) {
      return;
    }

    setHours(scheduleRule.hours);

    formScheduleRule.setFieldsValue({
      hours: scheduleRule.hours,
    });
  }, [scheduleRule?.hours]);

  const formatTime = (numero: number): string => {
    const formattedTime: string = numero < 10 ? `0${numero}` : `${numero}`;
    return `${formattedTime}:00`;
  };

  const renderHourOptions = () => {
    const hours = 24;

    const items = [];
    for (let index = 0; index < hours; index++) {
      items.push(
        <Option key={index} value={index}>{formatTime(index)}</Option>
      );
    }

    return items;
  };

  return (
    <LrvForm
      id='formScheduleRule'
      form={formScheduleRule}
      name='formScheduleRule'
      layout='vertical'
      theme={theme}
      onFinish={() => {
        if (!scheduleRule?._id) {
          return;
        }
        dispatch(updateCredential({ id: scheduleRule._id, hours: hours }));
      }}
    >
      <Form.Item>
        <div>
          {t('schedule.title', { hours: maxHours })}
        </div>
      </Form.Item>

      <Form.Item
        name='hours'
        required
        label={t('schedule.hours')}
      >
        <LrvSelect
          theme={theme}
          mode='multiple'
          value={hours}
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('schedule.placeholder')}
          onChange={setHours}
          removeIcon={<Icon name='close' />}
          showArrow
        >
          {renderHourOptions()}
        </LrvSelect>
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight}>
          <ActionButton
            id='submit_schedule_button'
            type='primary'
            htmlType='submit'
            disabled={disabledSaveButton}
          >
            {t('schedule.save')}
          </ActionButton>
        </div>
      </Form.Item>
    </LrvForm>
  );
}
