import React, { useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import { Store } from '../../state/store.interfaces';

import { setErrorCode, signInUser } from './authSlice';
import styles from './SignInForm.module.scss';
import { SignInForm as SignInFormInterface } from './interfaces';
import './SignInForm.scss';

export default function SignInForm () {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const errorCode = useSelector((state: Store) => state.auth.errorCodeLogin);
  const trialPhaseHasEnded = useSelector((state: Store) => state.auth.trialPhaseHasEnded);
  const isLoading = useSelector((state: Store) => state.auth.isLoading);
  const impersonate = localStorage.getItem('impersonate');

  useEffect(() => {
    if (impersonate) {
      localStorage.removeItem('isAssignedCompany');
      localStorage.removeItem('impersonate');
      localStorage.removeItem('refreshTokenSecondary');
      localStorage.removeItem('accessTokenSecondary');
      localStorage.removeItem('userSecondary');
    }
  }, [impersonate]);

  function renderError () {
    if (errorCode !== 0) {
      const message = errorCode === 401 ? trialPhaseHasEnded ? 'signIn.trialPhaseHasEnded' : 'signIn.error401' : 'signIn.error500';

      return <div id='alert_error_login' className={styles.alertError}>
        <div role='alert'>{t(message)}</div>
      </div>;
    }
    return null;
  }

  return (
    <Form
      name='normal_login'
      className={cx('loginForm', styles.loginForm)}
      onFinish={(data) => {
        dispatch(setErrorCode(0));
        dispatch(signInUser(data as SignInFormInterface));
      }}
    >
      <Form.Item
        name='email'
        rules={[{ required: true, message: t('signIn.emailError') }]}
        className={styles.formItem}
      >
        <Input
          className={styles.username}
          size='large'
          prefix={<UserOutlined color='#aaa' className={styles.loginIcon} />}
          autoFocus
          placeholder={t('signIn.email')}
        />
      </Form.Item>

      <Form.Item
        name='password'
        rules={[{ required: true, message: t('signIn.passwordError') }]}
      >
        <Input.Password
          size='large'
          prefix={<LockOutlined className={styles.loginIcon} />}
          type='password'
          placeholder={t('signIn.password')}
        />
      </Form.Item>

      <Form.Item>
        {renderError()}
      </Form.Item>

      <Form.Item>
        <div className={styles.formOptions}>
          <Form.Item>
            <Button
              id='submit_login'
              className={styles.submitButton}
              type='primary'
              htmlType='submit'
              loading={isLoading}
            >
              {t('signIn.signIn')}
            </Button>
          </Form.Item>

          <a className={styles.forgotPassword} href='/register'>
            {t('signIn.register')}
          </a>
        </div>
        <Form.Item>
          <div className={styles.formOptions}>
            <a className={styles.forgotPassword} href='/password/forgot'>
              {t('signIn.forgotPassword')}
            </a>
          </div>
        </Form.Item>
      </Form.Item>
    </Form>
  );
}
