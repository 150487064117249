import moment from 'moment';
import { useEffect } from 'react';
import { capitalize } from 'lodash';
import { Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { stockingPhaseTypes } from '../../../config/commons';
import { DropdownProps } from '../../../common/interfaces/commons';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { matchNamesWithSpecialCharacters } from '../../../utils/strings';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromStocking } from '../../../helpers/stocking.helpers';
import { openErrorNotification } from '../../../common/notification/Notification';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './GeneticsAnalysisFilters.module.scss';
import * as geneticsAnalysisSlice from './geneticsAnalysisSlice';
import { disabledMaximunDateTo, disabledMinimunDateTo } from './helpers';

interface Props {
  refFilters: React.RefObject<HTMLDivElement>;
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const GeneticsAnalysisFilters = (props: Props) => {
  const { refFilters, theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    dataSource,
    filters,
    isDownloadingFile,
    units,
    referenceCurves,
    firstStageZoom,
    lastStageZoom,
  } = useSelector((state: Store) => state.geneticsAnalysis);
  const accessToken = localStorage.getItem('accessToken') || '';

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    maximumDate,
    minimumDate,
    parameter,
    scale,
    unit,
    referenceCurve,
  } = filters;

  const {
    globals: globalReferenceCurves,
    companies: unitReferenceCurves,
    units: companyReferenceCurves,
  } = referenceCurves;

  useEffect(() => {
    const params = {
      companyId: selectedCompany._id,
      unitPhaseType: getUnitPhaseTypeFromStocking(phaseType),
    };
    dispatch(geneticsAnalysisSlice.setUnit(''));
    dispatch(geneticsAnalysisSlice.fetchUnits(params));
  }, [phaseType, selectedCompany]);

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    const unitsOptions = units.map((item) => {
      return { _id: item._id, value: item._id, label: item.name };
    });
    unitsOptions.unshift({ _id: '', value: '', label: t('geneticsInsights.allUnits') });

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unit}
        onChange={(value) => {
          dispatch(geneticsAnalysisSlice.setDataSource([]));
          dispatch(geneticsAnalysisSlice.setUnit(value));
          dispatch(geneticsAnalysisSlice.fetchUnitsReferenceCurves({ companyId: selectedCompany._id, type: parameter, phaseType, campusId: value }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('geneticsInsights.filters.unit')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        <Option key='' value=''>{t('geneticsInsights.allUnits')}</Option>

        {units.map((unit) =>
          <Option key={unit._id} value={unit._id}>{unit.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const renderParameterDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    const juvenileOptions = [
      { id: `parameter_${typeParam.AVG_WEIGHT.toLowerCase()}`, value: typeParam.AVG_WEIGHT, label: capitalize(t('shadedplot.type.avg_weight').toLowerCase()) },
      { id: `parameter_${typeParam.AVG_LENGTH.toLowerCase()}`, value: typeParam.AVG_LENGTH, label: capitalize(t('shadedplot.type.avg_length').toLowerCase()) },
      { id: `parameter_${typeParam.UNIFORMITY.toLowerCase()}`, value: typeParam.UNIFORMITY, label: capitalize(t('shadedplot.type.uniformity').toLowerCase()) },
      { id: `parameter_${typeParam.CV_LENGTH.toLowerCase()}`, value: typeParam.CV_LENGTH, label: capitalize(t('shadedplot.type.cv_length').toLowerCase()) },
    ];

    const larvaeOptions = [
      { id: `parameter_${typeParam.PLG.toLowerCase()}`, value: typeParam.PLG, label: capitalize(t('shadedplot.type.plg').toLowerCase()) },
      ...juvenileOptions,
    ];

    return (
      <LrvSelect
        id='dropdown_parameter'
        theme={theme}
        className={className}
        value={parameter}
        onChange={(value) => {
          dispatch(geneticsAnalysisSlice.setParameter(value));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('geneticsInsights.filters.parameter')}
        dropdownMatchSelectWidth={false}
        options={phaseType === stockingPhaseTypes.LARVAE ? larvaeOptions : juvenileOptions}
      />
    );
  };

  const renderGlobalRefOptions = () => {
    return globalReferenceCurves.map((item) => {
      return (
        <Option
          key={item._id}
          value={item._id}
          label={item.name}
        >
          {capitalize(item.name)}&nbsp;
          <Icon name='global' />
        </Option>
      );
    });
  };

  const renderCompanyRefOptions = () => {
    return companyReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderUnitRefOptions = () => {
    return unitReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderReferenceDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='reference_dropdown'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={referenceCurve?._id}
        showSearch
        title={t('production.filters.reference')}
        placeholder={t('production.filters.reference')}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
        onChange={(value) => {
          if (phaseType !== stockingPhaseTypes.LARVAE) {
            dispatch(geneticsAnalysisSlice.fetchReferenceCurveGroupedByDays({ referenceId: value, accessToken }));
            return;
          }
          const referenceCurves = [...globalReferenceCurves, ...unitReferenceCurves, ...companyReferenceCurves];
          const referenceCurve = referenceCurves.find(item => item._id === value);
          dispatch(geneticsAnalysisSlice.setReferenceCurve(referenceCurve));
        }}
      >
        {renderGlobalRefOptions()}
        {renderCompanyRefOptions()}
        {renderUnitRefOptions()}
      </LrvSelect>
    );
  };

  const renderScaleDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_scale'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={scale}
        onChange={(value) => {
          dispatch(geneticsAnalysisSlice.setScale(value));
        }}
        title={t('geneticsInsights.filters.scale')}
        options={[
          { id: `scale_${typeScale.LINEAR.toLowerCase()}`, value: typeScale.LINEAR, label: capitalize(t('shadedplot.scale.linear').toLowerCase()) },
          { id: `scale_${typeScale.LOGARITHMIC.toLowerCase()}`, value: typeScale.LOGARITHMIC, label: capitalize(t('shadedplot.scale.logarithmic').toLowerCase()) },
        ]}
      />
    );
  };

  const renderMinimumDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='minimunDate_datePicker'
        theme={theme}
        className={className}
        value={moment(minimumDate)}
        defaultValue={moment(minimumDate)}
        allowClear={false}
        disabledDate={(value) => disabledMinimunDateTo(value, maximumDate)}
        placeholder={t('stockings.growthDelta.dateTo')}
        title={t('geneticsInsights.filters.minimunDate')}
        onChange={(date, dateString) => {
          dispatch(geneticsAnalysisSlice.setMinimumDate(dateString));
        }}
      />
    );
  };

  const renderMaximumDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvDatePicker
        id='maximunDate_datePicker'
        theme={theme}
        className={className}
        value={moment(maximumDate)}
        defaultValue={moment(maximumDate)}
        allowClear={false}
        disabledDate={disabledMaximunDateTo}
        placeholder={t('stockings.growthDelta.dateTo')}
        title={t('geneticsInsights.filters.maximunDate')}
        onChange={(date, dateString) => {
          dispatch(geneticsAnalysisSlice.setMaximumDate(dateString));
        }}
      />
    );
  };

  const renderButtonGeneratePdf = () => {
    return (
      <IconButton
        iconName='download'
        className={styles.generatePdf}
        loading={isDownloadingFile}
        onClick={generatePdf}
        placement='left'
        tooltipText={t('shadedplot.generate')}
      />
    );
  };

  const generatePdf = () => {
    const maturationsName = matchNamesWithSpecialCharacters(dataSource);

    if (maturationsName === '') {
      openErrorNotification(t('stockings.pdf.noLegendError'));
      return;
    }

    const unitName = units.find((item) => item._id === unit)?.name || '';

    const params = {
      companyId: selectedCompany._id,
      parameter,
      scale,
      phaseType,
      maturationsName,
      unitId: unit,
      unitName,
      firstStage: firstStageZoom,
      lastStage: lastStageZoom,
      minimumDate,
      maximumDate,
      companyName: selectedCompany.name,
      referenceId: referenceCurve?._id,
    };

    dispatch(geneticsAnalysisSlice.fetchUrlMaturationComparisonPdf(params));
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<div className={styles.title}>{t('geneticsInsights.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(geneticsAnalysisSlice.resetGeneticsFilters({ phaseType, parameter }));
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderParameterDropdown({ theme: 'light' })}
            {renderReferenceDropdown({ theme: 'light' })}
            {renderScaleDropdown({ theme: 'light' })}
            {renderMinimumDatePicker({ theme: 'light' })}
            {renderMaximumDatePicker({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        onClick={() => dispatch(geneticsAnalysisSlice.resetGeneticsFilters({ phaseType, parameter }))}
      />
    );
  };

  return (
    <Row className={styles.container} ref={refFilters}>
      <Space className={styles.filters} align='end'>
        {renderUnitsDropdown({ className: styles.select, theme })}
        {renderParameterDropdown({ className: styles.select, theme })}
        {renderReferenceDropdown({ className: styles.select, theme })}
        {renderScaleDropdown({ className: styles.select, theme })}
        {renderMinimumDatePicker({ className: styles.select, theme })}
        {renderMaximumDatePicker({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}

      <Row className={styles.options}>
        {renderButtonGeneratePdf()}
      </Row>
    </Row>
  );
};
