import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Row, Select, Space } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { RootState } from '../../../state/store';
import { getDividedReferences } from '../helpers';
import Icon from '../../../common/components/Icon/Icon';
import { ReferenceCurves } from '../../Units/interfaces';
import { formatLongDateWithOffset } from '../../../utils/date';
import { filterOptionSelect } from '../../../utils/select';
import { ReferenceCurvesModal } from '../ReferenceCurvesModal';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { ExtraActionsButton } from '../../../common/components/buttons/ExtraActionsButton';
import { stockingPhaseTypes, unitStatuses, commonOptions, referenceOptions } from '../../../config/commons';

import styles from './UnitsReferenceCurves.module.scss';
import * as unitsReferenceCurvesSlice from './UnitsReferenceCurvesSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

const UnitsReferenceCurves = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { _id: companyId } = useSelector((state: RootState) => state.header.company);
  const { phaseType, unitSelected, units, isCreating, isUpdating, referenceCurves, isLoading, showModal: showReferenceCurvesModal, referenceCurveSelected } = useSelector((state: RootState) => state.unitsReferenceCurves);

  useEffect(() => {
    dispatch(unitsReferenceCurvesSlice.fetchUnits(companyId));
    dispatch(unitsReferenceCurvesSlice.fetchAllUnitsReferenceCurves({ companyId }));

    return () => {
      dispatch(unitsReferenceCurvesSlice.setUnits([]));
      dispatch(unitsReferenceCurvesSlice.setReferenceCurves([]));
    };
  }, [dispatch, companyId]);

  const columns: ColumnProps<ReferenceCurves>[] = [
    {
      key: 1,
      title: t('references.table.name'),
      dataIndex: 'name',
      width: '22%',
    },
    {
      key: 2,
      title: t('references.table.phase'),
      dataIndex: 'phaseType',
      width: '14%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: ReferenceCurves) => t(`stockings.phaseTypes.${record.phaseType}`),
    },
    {
      key: 3,
      title: t('references.metricTypeSelect'),
      dataIndex: 'type',
      width: '18%',
      render: (_, record: ReferenceCurves) => getParameter({ parameter: record.type, stockingPhaseType: '', addUnit: false }),
    },
    {
      key: 4,
      title: t('references.table.creationDate'),
      width: '18%',
      render: (_, record: ReferenceCurves) => formatLongDateWithOffset(record.createdAt),
    },
    {
      key: 5,
      title: t('references.table.lastUpdate'),
      width: '18%',
      render: (_, record: ReferenceCurves) => formatLongDateWithOffset(record.updatedAt),
    },
    {
      key: 6,
      width: '12%',
      render: (_, record: ReferenceCurves) => {
        return (
          <Space size='small' className={styles.space}>
            {!record.active && <LrvTag type='active' theme={theme}>{t('references.inactive').toLowerCase()}</LrvTag>}

            <Dropdown
              overlay={() => menuItemOptions(record)}
              trigger={['click']}
              placement='bottomRight'
            >
              <ExtraActionsButton
                id={`button_${record._id}`}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </Space>
        );
      },
    }
  ];

  const existsCampusName = referenceCurves.some((referenceCurve) => !!referenceCurve.campusName);

  if (existsCampusName) {
    columns.splice(1, 0, {
      key: 2,
      title: t('references.unitSelect'),
      dataIndex: 'campusName',
      width: '18%',
    });
  }

  const showConfirmEnableReference = (record: ReferenceCurves) => {
    const title = record.active ? t('references.disableConfirm.title') : t('references.enableConfirm.title');
    const content = record.active ? t('references.disableConfirm.description') : t('references.enableConfirm.description');
    const idOkButton = record.active ? 'submit_disable_reference' : 'submit_enable_reference';
    const idCancelButton = record.active ? 'cancel_disable_reference' : 'cancel_enaable_reference';

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const referenceCurveCopy = cloneDeep(record);
        referenceCurveCopy.active = !referenceCurveCopy.active;
        dispatch(unitsReferenceCurvesSlice.enableReferenceCurve({ referenceCurve: referenceCurveCopy, fetchAllUnits: !unitSelected._id, phaseType }));
      },
    });
  };


  const menuItemOptions = (record: ReferenceCurves) => {
    return (
      // eslint-disable-next-line
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT: {
              const reference = getDividedReferences({ referenceCurves: cloneDeep(record) });
              dispatch(unitsReferenceCurvesSlice.setSelectedReferenceCurve(reference));
              dispatch(unitsReferenceCurvesSlice.setShowModal(true));
              break;
            }

            case referenceOptions.ENABLE:
            case referenceOptions.DISABLE: {
              showConfirmEnableReference(record);
              break;
            }
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' type='line' theme={theme} className={styles.icon} />
          <span>{t('references.edit')}</span>
        </Menu.Item>

        {
          record.active ?
            <Menu.Item id='menu_option_disable' key={referenceOptions.DISABLE} className={styles.menuItemOptions}>
              <Icon name='close' type='line' theme={theme} className={styles.icon} />
              <span>{t('references.disable')}</span>
            </Menu.Item>
            :
            <Menu.Item id='menu_option_enable' key={referenceOptions.ENABLE} className={styles.menuItemOptions}>
              <Icon name='checkbox' type='line' theme={theme} className={styles.icon} />
              <span>{t('references.enable')}</span>
            </Menu.Item>
        }
      </Menu>
    );
  };

  const renderPhaseTypeSelect = () => {
    return (
      <LrvSelect
        id='dropdown_phaseType'
        theme={theme}
        className={styles.select}
        disabled={!unitSelected?._id}
        value={phaseType || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('references.phaseSelect')}
        placeholder={t('references.phaseSelect')}
        onChange={(value) => {
          dispatch(unitsReferenceCurvesSlice.setPhaseType(value));
          dispatch(unitsReferenceCurvesSlice.fetchUnitsReferenceCurves({ companyId, campusId: unitSelected._id, phaseType: value }));
        }}
      >
        <Option key={undefined} value={undefined}>
          {t('references.all')}
        </Option>

        <Option key={stockingPhaseTypes.LARVAE} value={stockingPhaseTypes.LARVAE}>
          {t('header.phaseTypes.larvae')}
        </Option>

        <Option key={stockingPhaseTypes.JUVENILE} value={stockingPhaseTypes.JUVENILE}>
          {t('header.phaseTypes.juveniles')}
        </Option>

        <Option key={stockingPhaseTypes.ADULT} value={stockingPhaseTypes.ADULT}>
          {t('header.phaseTypes.growOut')}
        </Option>
      </LrvSelect>
    );
  };

  const onChangeUnit = (value: string) => {
    const unit = units.find(item => item._id === value);

    if (!unit?._id) {
      dispatch(unitsReferenceCurvesSlice.fetchAllUnitsReferenceCurves({ companyId }));
      dispatch(unitsReferenceCurvesSlice.setSelectedUnit({ _id: '', name: t('references.all'), status: '' }));
      return;
    }

    dispatch(unitsReferenceCurvesSlice.setSelectedUnit(unit));
    dispatch(unitsReferenceCurvesSlice.fetchUnitsReferenceCurves({ companyId, campusId: unit._id, phaseType }));
  };

  const renderUnitsSelect = () => {
    return (
      <LrvSelect
        id='dropdown_units'
        showSearch
        theme={theme}
        className={styles.select}
        value={unitSelected?.name || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('references.unitSelect')}
        placeholder={t('references.unitSelect')}
        onChange={onChangeUnit}
        filterOption={filterOptionSelect}
      >
        <Option key={undefined} value={undefined}>
          {t('references.all')}
        </Option>

        {units.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.rowHeader}>
        <Space>
          {renderUnitsSelect()}
          {renderPhaseTypeSelect()}
        </Space>

        <ActionButton
          id='btn_new_reference_curve'
          type='primary'
          theme={theme}
          icon={<Icon name='add' />}
          disabled={!unitSelected?._id || isCreating}
          className={styles.btnAddReferenceCurve}
          onClick={() => dispatch(unitsReferenceCurvesSlice.setShowModal(true))}
        >
          {t('references.button')}
        </ActionButton>
      </Row>
    );
  };

  const unit = unitSelected?._id ? unitSelected : units.find(item => item._id === referenceCurveSelected?.campusId);

  return (
    <div className={styles.container}>
      {renderSubHeader()}

      <Row className={styles.rowFlex} >
        <LrvTable
          columns={columns}
          className={styles.table}
          rowClassName={styles.tableRow}
          dataSource={referenceCurves}
          loading={isLoading}
          scroll={{ y: '' }}
          size='small'
          theme={theme}
          pagination={false}
        />

        <ReferenceCurvesModal
          isLoading={isCreating || isUpdating}
          campusId={unit?._id}
          campusName={unit?.name}
          currentTab='UNIT_REFERENCE_CURVES'
          fetchAllUnits={!unitSelected._id}
          onClose={() => {
            dispatch(unitsReferenceCurvesSlice.setShowModal(false));
            dispatch(unitsReferenceCurvesSlice.setSelectedReferenceCurve({} as ReferenceCurves));
          }}
          showModal={showReferenceCurvesModal}
        />
      </Row>
    </div>
  );
};

export default UnitsReferenceCurves;