import { frequencyTypes, stockingParameterTypes } from '../../../config/commons';

import { CompanyStockingParameter } from './interfaces';

export const getKeysStockingParameterCustom = (props: { companyStockingParameters: CompanyStockingParameter[]; fields: string[] }) => {
  const { companyStockingParameters, fields } = props;
  const companyStockingParametersFiltered = companyStockingParameters.filter(element => element.type === stockingParameterTypes.NUMERIC);

  const keysSelected = companyStockingParametersFiltered.filter(element => fields.includes(element.key));
  const otherKeys = companyStockingParametersFiltered.filter(element => !fields.includes(element.key));

  return {
    keysSelected,
    otherKeys,
  };
};

export const getFrequencyType = (props: { options: string[]; frequencyType?: string }) => {
  const { frequencyType, options } = props;

  if (options.length <= 1) {
    return frequencyTypes.INDIVIDUAL;
  }

  return frequencyType || frequencyTypes.INDIVIDUAL;
};

export const getAggregationMethod = (props: { options: string[]; frequencyType?: string; aggregationMethod?: string }) => {
  const { frequencyType, options, aggregationMethod } = props;

  if (options.length <= 1 || frequencyType === frequencyTypes.INDIVIDUAL) {
    return undefined;
  }

  return aggregationMethod;
};

export const disabledForm = (props: { parameterType?: string; options: string[]; aggregationMethod?: string; frequencyType?: string; stockingParameter?: string; minimum?: number; maximum?: number }) => {
  const { parameterType, options, aggregationMethod, frequencyType, stockingParameter, minimum, maximum } = props;

  switch (parameterType) {
    case stockingParameterTypes.CATEGORICAL:
      return !(!!stockingParameter && options.length > 0);

    case stockingParameterTypes.NUMERIC: {
      const isValid = (!!stockingParameter && ((minimum !== undefined) && minimum >= 0) && (maximum && maximum > minimum));
      if (options.length <= 1) {
        return !isValid;
      }

      if (!frequencyType) {
        return true;
      }

      if (frequencyType === frequencyTypes.INDIVIDUAL) {
        return !isValid;
      }

      return !(isValid && !!aggregationMethod);
    }

    default:
      return true;
  }
};
