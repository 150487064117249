import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import FinishStockingInfo from '../../common/components/FinishSowingInfo/FinishSowingInfo';
import { calcDensity, getAnimals, getStartDateStocking, getUnitDensity } from '../../helpers/stocking.helpers';

import * as finishStockingSlice from './finishStockingSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const FinishStockingInfoModal = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { phaseType } = useSelector((state: Store) => state.header);
  const { selectedStocking } = useSelector((state: Store) => state.stockings);
  const { showFinishStockingInfo } = useSelector((state: Store) => state.finishStocking);

  return (
    <LrvModal
      theme={theme}
      title={t('stockings.finishStockingInfo')}
      open={showFinishStockingInfo}
      destroyOnClose={true}
      onCancel={() => dispatch(finishStockingSlice.setShowFinishStockingInfo(false))}
      onOk={() => dispatch(finishStockingSlice.setShowFinishStockingInfo(false))}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <FinishStockingInfo
        theme={theme}
        name={selectedStocking.name}
        status={selectedStocking.status}
        phaseType={selectedStocking.phaseType}
        harvestQuantity={selectedStocking.harvestQuantity}
        larvaePerGram={selectedStocking.larvaePerGram}
        startDate={getStartDateStocking({ stocking: selectedStocking, phaseType })}
        endDate={selectedStocking.endDate}
        comment={selectedStocking.comment}
        maturation={selectedStocking.maturationId?.name}
        density={calcDensity(selectedStocking)}
        unitDensity={getUnitDensity(selectedStocking)}
        averageHarvestedWeight={selectedStocking.averageHarvestedWeight}
        poundsHarvested={selectedStocking.poundsHarvested}
        survivalRate={selectedStocking.survivalRate}
        animals={getAnimals({ stocking: selectedStocking, phaseTypeSelected: phaseType })}
      />
    </LrvModal>
  );
};
