import cx from 'classnames';
import { RadioChangeEvent } from 'antd';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import { getCurrentTheme } from '../../helpers/theme';
import { GenericParam } from '../../common/interfaces/commons';
import { THEME, stockingSearchTypes } from '../../config/commons';
import { LrvRadioGroup } from '../../common/components/LrvRadioGroup/LrvRadioGroup';

import * as stockingSlice from './sowingsSlice';
import styles from './RadioGroupSearchType.module.scss';

interface Props {
  show?: boolean;
  onClose: () => void;
}

function RadioGroupSearchType (props: Props) {
  const { show = false, onClose } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const divRef = useRef<HTMLDivElement | null>(null);
  const { searchType } = useSelector((state: RootState) => state.stockings.filters);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    const handleClickOutside = (event: GenericParam) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const onChangeTypeSearch = (event: RadioChangeEvent) => {
    const value = event.target.value;
    dispatch(stockingSlice.setSearchType(value));
  };

  const options = [
    { label: t('stockings.typeSearch.name'), value: stockingSearchTypes.NAME },
    { label: t('stockings.typeSearch.cycle'), value: stockingSearchTypes.CYCLE },
    { label: t('stockings.typeSearch.lab'), value: stockingSearchTypes.LAB },
  ];

  return (
    <div
      ref={divRef}
      className={cx(
        styles.typeSearch,
        isLightTheme ? styles.typeSearchLight : styles.typeSearchDark,
        show ? styles.showTypeSearch : styles.hideTypeSearch
      )}
    >
      <LrvRadioGroup
        title={t('stockings.typeSearch.title')}
        theme='light'
        id='type_search'
        value={searchType}
        onChange={onChangeTypeSearch}
        options={options}
        className={styles.radioGroup}
      >
      </LrvRadioGroup>
    </div>
  );
}

export default RadioGroupSearchType;