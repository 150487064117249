import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { fetchStocking } from '../../Sowings/sowingsSlice';
import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { stockingPhaseTypes } from '../../../config/commons';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';

import Plg from './Plg';
import './ChartsPdf.scss';
import Uniformity from './Uniformity';
import GrowthDelta from './GrowthDelta';
import Pigmentation from './Pigmentation';
import AverageWeight from './AverageWeight';
import styles from './ChartsPdf.module.scss';
import { fetchAnalysis, fetchGrowthDelta, fetchGlobalPlgReferenceCurves, fetchGlobalUniformityReferenceCurves, fetchGlobalAverageWeightReferenceCurves } from './chartsSlice';

type TParams = {
  stockingId: string;
  accessToken: string;
  language: string;
};

export default function ChartsPdf ({ match }: RouteComponentProps<TParams>) {
  const {
    stockingId,
    accessToken,
    language,
  } = match.params;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const metrics = useSelector((state: Store) => state.stockingCharts);
  const stocking = useSelector((state: Store) => state.stockings.selectedStocking);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(fetchStocking({ id: stockingId, token: accessToken }));
  }, [dispatch, stockingId, accessToken]);

  useEffect(() => {
    if (!stocking.companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(stocking.companyId, accessToken));
  }, [dispatch, stocking.companyId, accessToken]);

  useEffect(() => {
    if (!stocking.companyId) {
      return;
    }

    dispatch(fetchGrowthDelta({ stockingId: stocking._id, token: accessToken }));

    dispatch(fetchGlobalPlgReferenceCurves({ phaseType: stocking.phaseType, token: accessToken }));
    dispatch(fetchGlobalUniformityReferenceCurves({ phaseType: stocking.phaseType, token: accessToken }));
    dispatch(fetchGlobalAverageWeightReferenceCurves({ phaseType: stocking.phaseType, token: accessToken }));
  }, [dispatch, stocking.companyId, stocking._id, stocking.phaseType, accessToken]);

  useEffect(() => {
    const showLoading: boolean = metrics.analysis.length === 0;

    if (stocking.companyId) {
      dispatch(fetchAnalysis({ stockingId, showLoading, token: accessToken }));
    }
  }, [dispatch, stockingId, stocking.companyId, metrics.analysis.length, accessToken]);

  if (stocking.phaseType === stockingPhaseTypes.LARVAE && (metrics.plg.length === 0 || metrics.uniformity.length === 0 || metrics.analysis.length === 0 || metrics.growthDelta.length === 0)) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  if (stocking.phaseType !== stockingPhaseTypes.LARVAE && (metrics.analysis.length === 0 || metrics.growthDelta.length === 0)) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  const renderChartLarvae = () => {
    return (
      <div id='charts'>
        <Plg
          language={language}
          stockingId={stockingId}
        />
        <Uniformity
          language={language}
          stockingId={stockingId}
        />
        <AverageWeight
          language={language}
          stockingId={stockingId}
        />
        <GrowthDelta
          language={language}
          stockingId={stockingId}
        />
        <Pigmentation
          language={language}
          stockingId={stockingId}
        />
      </div>
    );
  };

  const renderChartJuvenileAndGrowOut = () => {
    return (
      <div id='charts'>
        <Uniformity
          language={language}
          stockingId={stockingId}
        />
        <AverageWeight
          language={language}
          stockingId={stockingId}
        />
        <GrowthDelta
          language={language}
          stockingId={stockingId}
        />
        <Pigmentation
          language={language}
          stockingId={stockingId}
        />
      </div>
    );
  };

  return (
    <div className='stockingChartsPdf'>
      {stocking.phaseType === stockingPhaseTypes.LARVAE ? renderChartLarvae() : renderChartJuvenileAndGrowOut()}
    </div>
  );
}