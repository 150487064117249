import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Campus } from '../../Units/interfaces';
import { DataSource } from '../../home/interfaces';
import { Company } from '../../AppHeader/interfaces';
import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { stockingPhaseTypes, unitStatuses } from '../../../config/commons';
import { CAMPUS_URL, LABORATORY_COMPARISON_PDF_URL, LABORATORY_METRIC_URL } from '../../../config/config.api';

import { initialFilterState } from './helpers';
import { LaboratoryChartState, LaboratoryFilter, LaboratoryMetricsPayload } from './interfaces';

export const lastDaysGrowthDelta = 30;

const initialState: LaboratoryChartState = {
  laboratoryMetrics: {},
  laboratoryMetricsByUnits: {},
  isLoading: false,
  filters: initialFilterState,
  isDownloadingFile: false,
  firstStage: 0,
  lastStage: 0,
  firstStageZoom: 0,
  lastStageZoom: 0,
  dataSource: [],
  units: [],
};

const laboratoryChartSlice = createSlice({
  initialState,
  name: 'laboratoryChart',
  reducers: {
    setLaboratoryMetrics: (state: LaboratoryChartState, action: PayloadAction<LaboratoryMetricsPayload>) => {
      state.laboratoryMetrics = action.payload.laboratoryMetrics;
      state.laboratoryMetricsByUnits = action.payload.laboratoryMetricsByUnits;
    },
    setIsLoading: (state: LaboratoryChartState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsDownloadingFile: (state: LaboratoryChartState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },

    setFirstStage: (state: LaboratoryChartState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: LaboratoryChartState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },

    setFirstStageZoom: (state: LaboratoryChartState, action: PayloadAction<number>) => {
      state.firstStageZoom = action.payload;
    },
    setLastStageZoom: (state: LaboratoryChartState, action: PayloadAction<number>) => {
      state.lastStageZoom = action.payload;
    },

    setMaximumDate: (state: LaboratoryChartState, action: PayloadAction<string>) => {
      state.filters.maximumDate = action.payload;
    },
    setMinimumDate: (state: LaboratoryChartState, action: PayloadAction<string>) => {
      state.filters.minimumDate = action.payload;
    },
    setParameter: (state: LaboratoryChartState, action: PayloadAction<string>) => {
      state.filters.parameter = action.payload;
    },
    setScale: (state: LaboratoryChartState, action: PayloadAction<string>) => {
      state.filters.scale = action.payload;
    },
    setUnitId: (state: LaboratoryChartState, action: PayloadAction<string>) => {
      state.filters.unitId = action.payload;
    },
    setLaboratoryFilters: (state: LaboratoryChartState, action: PayloadAction<LaboratoryFilter>) => {
      state.filters = action.payload;
    },

    setDataSource: (state: LaboratoryChartState, action: PayloadAction<DataSource[]>) => {
      state.dataSource = action.payload;
    },
    setUnits: (state: LaboratoryChartState, action: PayloadAction<Campus[]>) => {
      state.units = action.payload;
    },
  },
});

export const {
  setLaboratoryMetrics,
  setIsLoading,
  setIsDownloadingFile,

  setFirstStage,
  setLastStage,
  setFirstStageZoom,
  setLastStageZoom,

  setMaximumDate,
  setMinimumDate,
  setParameter,
  setUnitId,
  setScale,
  setLaboratoryFilters,
  setDataSource,
  setUnits,
} = laboratoryChartSlice.actions;

export const fetchLaboratoryMetrics = (props: { companyId: string; parameter: string; minimumDate: string; maximumDate: string; unitId: string; phaseType: string; accessToken?: string }) => async (dispatch: Function) => {
  const { companyId, parameter, minimumDate, maximumDate, unitId, phaseType, accessToken } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    dispatch(setDataSource([]));
    dispatch(setLaboratoryMetrics({ laboratoryMetrics: {}, laboratoryMetricsByUnits: {} }));
    return;
  }

  dispatch(setIsLoading(true));
  const url = `${LABORATORY_METRIC_URL}/${companyId}/`;
  const params = {
    type: parameter,
    minimumDate,
    maximumDate,
    phaseType,
    unitId,
  };

  try {
    if (accessToken) {
      const response = await axios.get(url, {
        params,
        headers: { Authorization: accessToken },
      });

      dispatch(setLaboratoryMetrics(response.data));
      dispatch(setIsLoading(false));
      return;
    }

    const response = await axios.get<LaboratoryMetricsPayload>(url, { params });
    dispatch(setLaboratoryMetrics(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const resetLaboratoryFilters = (props: { company: Company; phaseType: string }) => (dispatch: Function) => {
  const { company, phaseType } = props;
  dispatch(setLaboratoryFilters(initialFilterState));

  const params = {
    ...initialFilterState,
    companyId: company._id,
    phaseType,
  };

  dispatch(fetchLaboratoryMetrics(params));
};

export interface UrlLaboratoryProps {
  companyId: string;
  parameter: string;
  scale: string;
  phaseType: string;
  firstStage: number;
  lastStage: number;
  minimumDate: string;
  maximumDate: string;
  laboratories: string;
  companyName: string;
  unitId?: string;
  unitName?: string;
}

export const fetchUrlLaboratoryComparisonPdf = (params: UrlLaboratoryProps) => async (dispatch: Function) => {
  const { companyName, companyId, phaseType, parameter, scale, firstStage, lastStage, minimumDate, maximumDate, laboratories, unitId, unitName } = params;
  dispatch(setIsDownloadingFile(true));

  const language = getLanguage();
  const accessToken = localStorage.getItem('accessToken');

  try {
    const response = await axios.get(LABORATORY_COMPARISON_PDF_URL);
    let url = `${response.data.url}?companyId=${companyId}&laboratories=${laboratories}&language=${language}&parameter=${parameter}&scale=${scale}&phaseType=${phaseType}&minimumDate=${minimumDate}&maximumDate=${maximumDate}&firstStage=${firstStage}&lastStage=${lastStage}&accessToken=${accessToken}`;
    if (unitId !== '') {
      url += `&unitId=${unitId}&unitName=${unitName}`;
    }
    await downloadFile(url, companyName, 'pdf');
    dispatch(setIsDownloadingFile(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    '$sort[name]': 1
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

const laboratoryChartReducer = laboratoryChartSlice.reducer;
export default laboratoryChartReducer;
