import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { THEME } from '../../../../../config/commons';
import { LrvText } from '../../../../../common/components/LrvText/LrvText';

import Subtitle from './Subtitle';
import DiseaseDropdown from './DiseaseDropdown';
import styles from './NewAnimalPanel.module.scss';

interface Props {
  addDisease: () => void;
  theme?: 'dark' | 'light';
}

export default function DiseasePanel (props: Props) {
  const {
    addDisease,
    theme = 'dark',
  } = props;

  const [t] = useTranslation();
  const isLightTheme = theme === THEME.LIGHT;

  return (
    <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark)}>
      <LrvText theme={theme} text={t('detail.manageAnimals.addDisease')} />

      <Subtitle
        addDisease={addDisease}
        theme={theme}
      />
      <DiseaseDropdown theme={theme} />
    </div>
  );
}
