import cx from 'classnames';
import { Empty, List } from 'antd';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LrvEmpty } from '../LrvEmpty/LrvEmpty';
import { lrvListProps } from '../../interfaces/lrvList';

import styles from './LrvList.module.scss';

export const LrvList = forwardRef<HTMLDivElement, lrvListProps>((props, ref) => {
  const { className, containerClassName, theme = 'dark', title, titleClassName } = props;
  const isDarkTheme = theme === 'dark';
  const [t] = useTranslation();

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkList : styles.lightList, className),
  };

  return (
    <div
      ref={ref}
      className={cx(styles.container, isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}
    >
      <div className={cx(isDarkTheme ? styles.darkTitle : styles.lightTitle, titleClassName)}>{title}</div>
      <List
        {...lrvProps}
        locale={{
          emptyText: (
            <LrvEmpty
              description={t('empty')}
              theme={theme}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )
        }}
      />
    </div>
  );
});

