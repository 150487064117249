import axios from 'axios';

import { UserSession } from '../../common/interfaces/auth';

axios.interceptors.request.use((request) => {
  const impersonate = localStorage.getItem('impersonate');
  if (impersonate) {
    const userSession: UserSession = JSON.parse(localStorage.getItem('userSecondary') || '{}');
    request.headers['Impersonate-Id'] = userSession._id;
  }
  return request;
},(error) => Promise.reject(error));