import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';

import './AnalysisWithoutStocking.scss';
import styles from './AssignStocking.module.scss';
import * as analysisWithoutStockingSlice from './analysisWithoutStockingSlice';

interface Props {
  theme?: 'dark' | 'light';
}

function AssignStocking (props: Props) {
  const { theme } = props;
  const { Option } = Select;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [newStocking, setNewStocking] = useState('');
  const [newTank, setNewTank] = useState('');
  const [newModule, setNewModule] = useState('');
  const [newCampus, setNewCampus] = useState('');
  const [disabledButtonForm, setDisabledButtonForm] = useState(true);

  const {
    assignStocking,
    analysisSelected,
  } = useSelector((state: Store) => state.analysisWithoutStocking);

  const {
    isLoading,
    currentPage,
    showModal,
    campuses,
    modules,
    containers,
    stockings,
  } = assignStocking;

  const {
    company,
    phaseType,
  } = useSelector((state: Store) => state.header);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    const params = { companyId: company._id, phaseType: getUnitPhaseTypeFromStocking(phaseType) };
    dispatch(analysisWithoutStockingSlice.fetchCampuses(params));
  }, [dispatch, company._id, phaseType]);

  function validateAssignAnalysis () {
    return newCampus && newModule && newTank && newStocking;
  }

  return (
    <LrvModal
      className='assignStockingModal'
      theme={theme}
      title={t('analysisWithoutStocking.assignStocking')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_assign_analysis', htmlType: 'submit', form: 'formAssignAnalysis', loading: isLoading, disabled: disabledButtonForm }}
      cancelButtonProps={{ id: 'cancel_assign_analysis' }}
      onOk={() => {
        if (validateAssignAnalysis() && analysisSelected?._id) {
          const paramsFetchAnalysis = { companyId: company._id, page: currentPage, stockingPhaseType: phaseType };
          const body = { stockingId: newStocking };
          const params = { analysisId: analysisSelected._id, body, paramsFetchAnalysis };
          dispatch(analysisWithoutStockingSlice.assignAnalysis(params));
        }
      }}
      okText={t('analysisWithoutStocking.accept')}
      cancelText={t('analysisWithoutStocking.cancel').toUpperCase()}
      onCancel={() => {
        setNewCampus('');
        setNewModule('');
        setNewTank('');
        setNewStocking('');
        dispatch(analysisWithoutStockingSlice.setShowModal(false));
      }}
      width={760}
    >
      <LrvForm
        layout='vertical'
        theme={theme}
      >
        <div className={styles.item}>
          <div className={styles.description}>
            <LrvText
              theme={theme}
              text={`* ${t('analysisWithoutStocking.description', { analysisCode: analysisSelected?.code })}`}
            />
          </div>
          <div className={styles.row}>
            <Form.Item
              id='dropdown_campus_assign'
              label={t('analysisWithoutStocking.campus')}
              className={styles.select}
            >
              <LrvSelect
                showSearch
                theme={theme}
                value={newCampus}
                onChange={(value: string) => {
                  setNewCampus(value);
                  setNewModule('');
                  setNewTank('');
                  setNewStocking('');

                  dispatch(analysisWithoutStockingSlice.fetchModules(value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                dropdownMatchSelectWidth={false}
                filterOption={filterOptionSelect}
              >
                {campuses.map((campus) =>
                  <Option key={campus._id} value={campus._id}>{campus.name}</Option>)
                }
              </LrvSelect>
            </Form.Item>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <Form.Item
              id='dropdown_modules_assign'
              label={t('analysisWithoutStocking.module')}
              className={styles.select}
            >
              <LrvSelect
                showSearch
                theme={theme}
                value={newModule}
                onChange={(value: string) => {
                  setNewModule(value);
                  setNewTank('');
                  setNewStocking('');

                  dispatch(analysisWithoutStockingSlice.fetchContainers(newCampus, value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newCampus}
                dropdownMatchSelectWidth={false}
                filterOption={filterOptionSelect}
              >
                {modules.map((module) =>
                  <Option key={module._id} value={module._id}>{module.name}</Option>)
                }
              </LrvSelect>
            </Form.Item>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <Form.Item
              id='dropdown_tanks_assign'
              label={t('analysisWithoutStocking.tank')}
              className={styles.select}
            >
              <LrvSelect
                showSearch
                theme={theme}
                value={newTank}
                onChange={(value: string) => {
                  setNewStocking('');
                  setNewTank(value);

                  dispatch(analysisWithoutStockingSlice.fetchStockings(newCampus, newModule, value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newModule}
                dropdownMatchSelectWidth={false}
                filterOption={filterOptionSelect}
              >
                {containers.map((tank) =>
                  <Option key={tank._id} value={tank._id}>{tank.name}</Option>)
                }
              </LrvSelect>
            </Form.Item>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <Form.Item
              id='dropdown_stockings_assign'
              label={t('analysisWithoutStocking.stocking')}
              className={styles.select}
            >
              <LrvSelect
                showSearch
                theme={theme}
                value={newStocking}
                onChange={(value: string) => {
                  setNewStocking(value);
                  const disabled = !(newCampus && newModule && newTank && value);
                  setDisabledButtonForm(disabled);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newTank}
                dropdownMatchSelectWidth={false}
                filterOption={filterOptionSelect}
              >
                {stockings.map((stocking) =>
                  <Option key={stocking._id} value={stocking._id}>{stocking.name}</Option>)
                }
              </LrvSelect>
            </Form.Item>
          </div>
        </div>
      </LrvForm>
    </LrvModal>
  );
}

export default AssignStocking;
