import { Animal, WeightGroups, Percentile, AnimalsByGroup } from './interfaces';

export const palette = ['#FF1493', '#FFA52A', '#2B9A21', '#00BFFC', '#1B62E6'];

export function groupLabels (labels: number[] | number[]) {
  const labelsGroup: string[] = [];

  let rank: string;
  for (let i = 0; i < labels.length; i++) {
    if (i === 0) {
      rank = `< ${labels[i + 1]}`;
    } else if (i === labels.length - 1) {
      rank = `> ${labels[i]}`;
    } else if (i === 1) {
      rank = labels[i].toFixed(2) + ` - ${labels[i + 1]}`;
    } else {
      rank = (labels[i] + 0.01).toFixed(2) + ` - ${labels[i + 1]}`;
    }

    labelsGroup.push(rank);
  }
  return labelsGroup;
}

export function groupAnimals (listPercentile: Percentile[], weightGroups: WeightGroups) {
  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const { animals } = value;

      animals.forEach((animal: Animal) => {
        const larvae: Animal = {
          x: animal.x * 1.0,
          y: animal.y * 1.0,
          added: animal.added,
          removed: animal.removed,
          previousGroup: animal.previousGroup,
          disease: animal.disease,
        };
        listPercentile[percentileGroup].animals.push(larvae);
      });
    },
  );

  return listPercentile;
}

export function createListAnimalsByGroup (props: { colors: string[]; imageSize: number; pointSizeRatio: number; weightGroups: WeightGroups }) {
  const { colors, imageSize, pointSizeRatio, weightGroups } = props;
  const animalsByGroup: AnimalsByGroup[] = [];

  for (let i = 0; i < colors.length; i++) {
    const weightGroup = weightGroups[i + 1];
    const area = weightGroup.areaBaseOne || weightGroup.area;

    const avgArea = pointSizeRatio * area * imageSize * 10;
    const diameter = Math.sqrt(avgArea / Math.PI);
    const radius = diameter / 2;

    const percentile: AnimalsByGroup = {
      isActive: true,
      color: colors[i],
      animals: [],
      group: i + 1,
      radius,
    };
    animalsByGroup.push(percentile);
  }
  return animalsByGroup;
}

function _fillAnimalsByGroup (props: { animals: Animal[]; animalsByGroup: AnimalsByGroup[]; percentileGroup: number; scaleFactor: number }) {
  const { animals, animalsByGroup, percentileGroup, scaleFactor = 1 } = props;

  animals.forEach(animal => {
    const larvae: Animal = {
      x: animal.x * 1.0 / scaleFactor,
      y: animal.y * 1.0 / scaleFactor,
      added: animal.added,
      removed: animal.removed,
      previousGroup: animal.previousGroup,
    };

    animalsByGroup[percentileGroup].animals.push(larvae);
  });

  return animalsByGroup;
}

export function fillAnimalsByGroupOld (props: { animalsByGroup: AnimalsByGroup[]; weightGroups: WeightGroups; scaleFactor: number }) {
  const { weightGroups, scaleFactor } = props;
  let { animalsByGroup } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Animal[] = value.animals;

      animalsByGroup = _fillAnimalsByGroup({ animals, animalsByGroup, percentileGroup, scaleFactor });
    }
  );

  return animalsByGroup;
}

export function fillAnimalsByGroup (props: { animalsByGroup: AnimalsByGroup[]; weightGroups: WeightGroups; scaleFactor: number }) {
  const { weightGroups, scaleFactor } = props;
  let { animalsByGroup } = props;

  Object.entries(weightGroups).forEach(
    ([key, value]) => {
      const percentileGroup = parseInt(key) - 1;
      const animals: Animal[] = value.animals;

      animalsByGroup = _fillAnimalsByGroup({ animals, animalsByGroup, percentileGroup, scaleFactor });
    }
  );

  return animalsByGroup;
}

export const createListPercentiles = (props: { labelsSort: string[]; pointSizeRatio: number; weightGroups: WeightGroups; imageSize: number }) => {
  const { labelsSort, pointSizeRatio, weightGroups, imageSize } = props;
  const listPercentile: Percentile[] = [];

  for (let i = 0; i < labelsSort.length; i++) {
    const weightGroup = weightGroups[i + 1];
    const area = weightGroup.areaBaseOne || weightGroup.area;

    const avgArea = pointSizeRatio * area * imageSize * 100;
    const diameter = Math.sqrt(avgArea / Math.PI);
    const radius = diameter / 2;

    const percentile: Percentile = {
      isActive: true,
      rank: labelsSort[i],
      color: palette[i] + '80',
      animals: [],
      group: i + 1,
      area,
      radius: radius,
    };
    listPercentile.push(percentile);
  }
  return listPercentile;
};

export const createListPercentilesOld = (props: { labelsSort: string[]; areaPercentIncrease: number; weightGroups: WeightGroups }) => {
  const { labelsSort, areaPercentIncrease, weightGroups } = props;
  const listPercentile: Percentile[] = [];

  for (let i = 0; i < labelsSort.length; i++) {
    const weightGroup = weightGroups[i + 1];
    const area = weightGroup.areaBaseOne || weightGroup.area;

    const avgArea = increaseArea(area, areaPercentIncrease);
    const diameter = avgArea / Math.PI;
    const radius = diameter / 2;

    const percentile: Percentile = {
      isActive: true,
      rank: labelsSort[i],
      color: palette[i] + '80',
      animals: [],
      group: i + 1,
      area,
      radius: radius,
    };
    listPercentile.push(percentile);
  }
  return listPercentile;
};

export const getPointsUnremoved = (animals: Animal[]) => {
  let count = 0;

  animals.forEach((animal: Animal) => {
    if (!animal.removed) {
      count++;
    }
  });

  return count;
};

function increaseArea (area: number, percentIncrease: number) {
  const increase = (area * percentIncrease) / 100;
  return area + increase;
}