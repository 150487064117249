import i18next from 'i18next';

import { analysisTypes, stockingPhaseTypes } from '../../../config/commons';

export const getAlgorithmUsed = (type: string) => {
  switch (type) {
    case analysisTypes.GENERAL_LARVAE_ANALYSIS:
      return i18next.t('header.phaseTypes.larvae');

    case analysisTypes.GENERAL_JUVENILE_ANALYSIS:
      return i18next.t('header.phaseTypes.juveniles');

    case analysisTypes.GENERAL_ADULT_ANALYSIS:
      return i18next.t('header.phaseTypes.growOut');

    default:
      return '-';
  }
};

export const getStageLabel = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('detail.extraInformation.editedStage');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('detail.extraInformation.editedDay');
  }
};
