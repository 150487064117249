import React from 'react';
import { Pagination } from 'antd';

import { LrvPaginationProps } from '../../interfaces/lrvPagination';

import styles from './LrvPagination.module.scss';

export const LrvPagination = (props: LrvPaginationProps) => {
  const { theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  return (
    <div className={isDarkTheme ? styles.darkContainer : styles.lightContainer}>
      <Pagination {...props} />
    </div>
  );
};
