import React from 'react';
import cx from 'classnames';
import { Typography } from 'antd';

import { LrvTextProps } from '../../interfaces/lrvText';

import styles from './LrvText.module.scss';

const { Text } = Typography;

export const LrvText = (props: LrvTextProps) => {
  const { className, theme = 'dark', text } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkText : styles.lightText, className),
  };

  return (
    <Text {...lrvProps}>
      {text}
    </Text>
  );
};