import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Particles from 'react-tsparticles';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Store } from '../../state/store.interfaces';
import { options } from '../../config/ts-particles';
import { GenericParam } from '../../common/interfaces/commons';
import { ReactComponent as Logo } from '../../assets/larvia-dark.svg';
import DotSpinner from '../../common/components/DotSpinner/DotSpinner';

import './ActivateAccount.scss';
import styles from './index.module.scss';
import { activateAccount } from './activateAccountSlice';

type TParams = { token: string };

export default function ActivateAccount ({ match }: RouteComponentProps<TParams>) {
  const token = match.params.token;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const errorCode = useSelector((state: Store) => state.activateAccount.errorCode);
  const okCode = useSelector((state: Store) => state.activateAccount.okCode);
  const isLoading = useSelector((state: Store) => state.activateAccount.isLoading);

  useEffect(() => {
    if (token) {
      dispatch(activateAccount(token));
    }
  }, [dispatch, token]);

  function renderResults () {
    if (isLoading) {
      return (
        <DotSpinner />
      );
    }

    if (okCode !== 0) {
      return (
        <div className={styles.accountTitle}>{t('activateAccount.success')}</div>
      );
    }

    if (errorCode !== 0) {
      return (
        <div className={styles.accountTitle}>{t('activateAccount.error')}</div>
      );
    }

    return null;
  }

  return <>
    <Particles id='tsparticles' options={options as GenericParam} />
    <div className={styles.activateAccount}>
      <div className={styles.activateAccountWrapper}>
        <div className={styles.activateAccountCenter}>
          <div className={styles.accountLogo}><Logo /></div>
          <div className={styles.spinner}>
            {renderResults()}
          </div>
          <Button
            type='primary'
            className={styles.button}
            onClick={() => history.push('/login')}
          >
            {t('activateAccount.signIn')}
          </Button>
        </div>
      </div>
    </div>
  </>;
}
