import queryString from 'query-string';
import HttpStatus from 'http-status-codes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PASSWORD_RENEW, PASSWORD_REQUEST, PASSWORD_RESET } from '../../config/config.api';
import { ApiClient } from '../../utils/axios_instance';
import { catchPasswordErrors } from '../Settings/settingsSlice';

import { PasswordState, PasswordRequestPayload, PasswordResetPayload } from './interfaces';

const initialState: PasswordState = {
  isRequestSuccessful: null,
  isResetSuccessful: null
};

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    setPasswordRequestResponse: (state: PasswordState, action: PayloadAction<PasswordRequestPayload>) => {
      state.isRequestSuccessful = action.payload.isSuccessful;
    },
    setPasswordResetResponse: (state: PasswordState, action: PayloadAction<PasswordResetPayload>) => {
      state.isResetSuccessful = action.payload.isSuccessful;
    },
  },
});

export const { setPasswordResetResponse, setPasswordRequestResponse } = passwordSlice.actions;

export const passwordRequest = (emailData: { email: string }) => async (dispatch: Function) => {
  try {
    const response = await axios.post(PASSWORD_REQUEST, emailData);
    if (response.status === HttpStatus.NO_CONTENT) {
      dispatch(setPasswordRequestResponse({ isSuccessful: true }));
    } else {
      dispatch(setPasswordRequestResponse({ isSuccessful: false }));
    }
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
};

export const passwordReset = (passwordData: { newPassword: string; confirmPassword?: string; token?: string }) => async (dispatch: Function) => {
  delete passwordData.confirmPassword;

  try {
    // eslint-disable-next-line no-restricted-globals
    const params = queryString.parse(location.search);
    passwordData.token = String(params.lrvtoken);
    const response = await axios.post(PASSWORD_RESET, passwordData);
    if (response.status === HttpStatus.NO_CONTENT) {
      dispatch(setPasswordResetResponse({ isSuccessful: true }));
    } else {
      dispatch(setPasswordResetResponse({ isSuccessful: true }));
    }
  } catch (e) {
    catchPasswordErrors(e?.response?.data);
    console.log('ERROR', e);
    return;
  }
};

export const renewPassword = (passwordData: { newPassword: string, accessToken: string }) => async (dispatch: Function) => {
  try {
    const response = await axios.patch(PASSWORD_RENEW, passwordData);
    if (response.status === HttpStatus.NO_CONTENT) {
      dispatch(setPasswordResetResponse({ isSuccessful: true }));
    } else {
      dispatch(setPasswordResetResponse({ isSuccessful: true }));
    }
  } catch (e) {
    console.log('ERROR', e?.response?.data);
    catchPasswordErrors(e?.response?.data);
    return;
  }
};

export default passwordSlice.reducer;