import { environments } from './commons';
import { ENVIRONMENT } from './config.api';

const COLLECTIONS_NAME = {
  [environments.DEV]: 'dev-dashboard',
  [environments.STG]: 'stg-dashboard',
  [environments.PROD]: 'dashboard',
};

export const FIRESTORE_API_KEY = process.env.REACT_APP_FIRESTORE;
export const FIRESTORE_PROJECT_ID = process.env.REACT_APP_FB_PROJECT_ID;
export const FIRESTORE_AUTH_DOMAIN = `${FIRESTORE_PROJECT_ID}.firebaseapp.com`;
export const FIRESTORE_DATA_BASE = `https://${FIRESTORE_PROJECT_ID}.firebaseio.com`;
export const FIRESTORE_STORAGE_BUCKET = `${FIRESTORE_PROJECT_ID}.appspot.com`;
export const FIRESTORE_MESSAGING_SENDER_ID = process.env.REACT_APP_FB_MESSAGING_SENDER_ID;
export const FIRESTORE_APP_ID = process.env.REACT_APP_FB_APP_ID;

export const COLLECTION_NAME = COLLECTIONS_NAME[ENVIRONMENT];
export const FIRESTORE_KEY = 'visibility';
export const FIRESTORE_VALUE = 'Larvia-2021*';
