import cx from 'classnames';
import { Row, Col } from 'antd';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';

import { Percentile } from '../interfaces';
import { THEME } from '../../../../config/commons';
import { getPointsUnremoved } from '../math_percentile';
import Icon from '../../../../common/components/Icon/Icon';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvCheckbox } from '../../../../common/components/LrvCheckbox/LrvCheckbox';
import { CloseIcon } from '../../../../common/components/Icon/CloseIcon';

import * as sampleSlice from './sampleSlice';
import styles from './QuantityAnimals.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  listPercentile: Percentile[];
  labelsUnit: string;
  showAnimalsRemoved: boolean;
  changeListPercentile: (percentiles: Percentile[]) => void;
  showQuantityAnimalsPanel: boolean;
}

export default function QuantityAnimals (props: Props) {
  const { theme = 'dark', listPercentile, labelsUnit, showAnimalsRemoved, changeListPercentile, showQuantityAnimalsPanel } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isLightTheme = theme === THEME.LIGHT;

  const changeActivePercentile = (index: number) => {
    const percentiles: Percentile[] = cloneDeep(listPercentile);
    percentiles[index].isActive = !percentiles[index].isActive;
    changeListPercentile(percentiles);
  };

  const renderCheckboxPercentile = () => {
    const percentiles = listPercentile.map((percentile, index) => {
      return (
        <Row key={index} gutter={24} className={styles.toolRow}>
          <Col span={19} className={styles.col}>
            <LrvCheckbox
              id={`percentile_${index}`}
              onChange={() => changeActivePercentile(index)}
              theme={theme}
              checked={percentile.isActive}
              className={styles.toolCheckbox}
            >
              <div className={styles.colorBox} style={{ background: `${percentile.color}` }} />
              <span className={styles.label}>{percentile.rank} {labelsUnit}</span>
            </LrvCheckbox>
          </Col>

          <Col span={5} className={styles.col}>
            <LrvText className={styles.value} text={getPointsUnremoved(percentile.animals).toString()} theme={theme} />
          </Col>
        </Row>
      );
    });

    percentiles.unshift(
      <div key='header-quantity-animals' className={styles.header}>
        <div className={styles.group}>
          <div
            className={styles.iconContainer}
            onClick={() => {
              dispatch(sampleSlice.setShowQuantityAnimalsPanel(false));
            }}
          >
            {!isDesktop && <Icon name='close' />}
          </div>
          <div className={cx(styles.label, isLightTheme ? styles.labelLight : styles.labelDark)}>{t('detail.manageAnimals.group')}</div>
        </div>

        <div className={cx(styles.label, isLightTheme ? styles.labelLight : styles.labelDark)}>{t('detail.manageAnimals.quantity')}</div>
      </div>
    );

    const key = 'removed_animal';

    percentiles.push(
      <Row key={key} gutter={24} className={styles.toolRow}>
        <Col span={24} className={styles.col}>
          <LrvCheckbox
            id={`percentile_${key}`}
            onChange={event => dispatch(sampleSlice.setShowAnimalsRemoved(event.target.checked))}
            checked={showAnimalsRemoved}
            className={styles.toolCheckbox}
            theme={theme}
          >
            <div className={styles.colorBox}>
              <CloseIcon width={38} height={38} fill={isLightTheme ? '#333333' : 'white'} />
            </div>

            <span className={styles.label}>{t('detail.animalsRemoved')} </span>
          </LrvCheckbox>
        </Col>
      </Row>
    );

    return percentiles;
  };

  if (!showQuantityAnimalsPanel) {
    return null;
  }

  return (
    <div className={cx(styles.tools, isLightTheme ? styles.toolsLight : styles.toolsDark)}>
      {renderCheckboxPercentile()}
    </div>
  );
}
