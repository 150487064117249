import { Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { plansTypes } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';

import styles from './BalanceConsumption.module.scss';
import BalanceFilterPanel from './BalanceFilterPanel';
import PrepaidBalanceConsumption from './PrepaidBalanceConsumption';
import PostpaidBalanceConsumption from './PostpaidBalanceConsumption';
import * as balanceConsumptionSlice from './BalanceConsumptionSlice';

const { TabPane } = Tabs;

export default function BalanceConsumption () {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const tabSelected = useSelector((state: Store) => state.balanceConsumption.tabSelected);

  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'balances.title' }));

    return () => {
      dispatch(balanceConsumptionSlice.resetBalanceConsumption());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(balanceConsumptionSlice.fetchSellers());
    dispatch(balanceConsumptionSlice.fetchPromoters());
  }, [dispatch]);

  const renderBalanceTabs = () => {
    return (
      <LrvTabs
        defaultActiveKey={tabSelected}
        tabPosition='top'
        onChange={(key) => dispatch(balanceConsumptionSlice.setTabSelected(key))}
        theme={theme}
      >
        <TabPane tab={<div id='tab_postpaid_balance'>{t('balances.tabs.postpaid')}</div>} key={plansTypes.POSTPAID}>
          <PostpaidBalanceConsumption theme={theme} />
        </TabPane>

        <TabPane tab={<div id='tab_prepaid_balance'>{t('balances.tabs.prepaid')}</div>} key={plansTypes.PREPAID}>
          <PrepaidBalanceConsumption theme={theme} />
        </TabPane>
      </LrvTabs>
    );
  };

  return (
    <div className={styles.content}>
      <Row className={styles.rowFlex} >
        <BalanceFilterPanel theme={theme} />
        {renderBalanceTabs()}
      </Row>
    </div>
  );
}
