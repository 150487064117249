import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { getCurrentTheme } from '../../helpers/theme';
import { defaultStockingData } from '../../config/commons';
import { formatLongDateWithOffset } from '../../utils/date';
import { GenericParam } from '../../common/interfaces/commons';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import * as detailStockingParameterSlice from '../StockingParameter/Detail/detailStockingParameterSlice';

import styles from './Sowing.module.scss';
import { StockingParameterData } from './interfaces';
import * as stockingParameterSlice from './stockingParameterSlice';

interface Props {
  stockingId: string;
}

const maxItemsToShow = 4;

export const ParameterTable = (props: Props) => {
  const { stockingId } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    total,
    limit,
    isLoading,
    data: stockingParameterData,
    currentPage,
    stockingParameterCustom,
    companyStockingParameters,
  } = useSelector((state: Store) => state.stockingParameter);

  const { selectedStocking: stocking } = useSelector((state: Store) => state.stockings);

  const theme = getCurrentTheme();

  const onChangePage = (page: number) => {
    dispatch(stockingParameterSlice.setCurrentPage(page));
    dispatch(stockingParameterSlice.fetchStockingParameters({ page, stockingId, companyId: stocking.companyId }));
  };

  const getCustomColumns = () => {
    const columns: ColumnsType<StockingParameterData> = [];

    for (let index = 0; index < stockingParameterCustom.fields.length; index++) {
      const field = stockingParameterCustom.fields[index];

      const column = {
        key: index,
        title: field,
        width: '20%',
        dataIndex: field,
      };

      columns.push(column);
    }

    columns.push(
      {
        key: 4,
        title: t('stockingParameter.date'),
        dataIndex: 'date',
        width: '20%',
      },
    );

    return columns;
  };

  const getDefaultColumnsFromCompany = () => {
    const columns: ColumnsType<StockingParameterData> = [];

    for (let index = 0; index < maxItemsToShow && index < companyStockingParameters.length; index++) {
      const key = companyStockingParameters[index].key;

      const column = {
        key: index,
        title: key,
        width: '20%',
        dataIndex: key,
      };

      columns.push(column);
    }

    columns.push(
      {
        key: 4,
        title: t('stockingParameter.date'),
        dataIndex: 'date',
        width: '20%',
      },
    );

    return columns;
  };

  const getDefaultColumns = () => {
    const columns: ColumnsType<StockingParameterData> = [
      {
        key: 0,
        title: defaultStockingData.TEMPERATURE,
        dataIndex: defaultStockingData.TEMPERATURE,
        width: '20%',
      },
      {
        key: 1,
        title: defaultStockingData.PH,
        dataIndex: defaultStockingData.PH,
        width: '20%',
      },
      {
        key: 2,
        title: defaultStockingData.OXYGEN,
        dataIndex: defaultStockingData.OXYGEN,
        width: '20%',
      },
      {
        key: 3,
        title: defaultStockingData.TOTAL_FEED,
        dataIndex: defaultStockingData.TOTAL_FEED,
        width: '20%',
      },
      {
        key: 4,
        title: t('stockingParameter.date'),
        dataIndex: 'date',
        width: '20%',
      },
    ];

    return columns;
  };

  const getColumns = () => {
    if (stockingParameterCustom.fields.length > 0) {
      return getCustomColumns();
    }

    if (companyStockingParameters.length > 0) {
      return getDefaultColumnsFromCompany();
    }

    return getDefaultColumns();
  };

  const getDataSource = () => {
    const dataItems: GenericParam[] = [];

    for (let i = 0; i < stockingParameterData.length; i++) {
      const rowItems: GenericParam[] = [];
      const stockingParameterDataItem = stockingParameterData[i];

      for (let j = 0; j < stockingParameterDataItem.data.length; j++) {
        const dataItem = stockingParameterDataItem.data[j];
        const key = companyStockingParameters.find((item) => item._id === dataItem.key)?.key;

        /* eslint-disable max-depth*/
        if (!key) {
          continue;
        }

        rowItems.push({
          [key]: dataItem.value,
        });
      }

      rowItems.push({
        companyId: stockingParameterDataItem.companyId,
      });
      rowItems.push({
        _id: stockingParameterDataItem._id,
      });
      rowItems.push({
        date: formatLongDateWithOffset(stockingParameterDataItem.date),
      });

      const fields = rowItems.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});

      dataItems.push(fields);
    }

    return dataItems;
  };

  return (
    <LrvTable
      columns={getColumns()}
      className={styles.table}
      dataSource={getDataSource()}
      loading={isLoading}
      size='small'
      theme={theme}
      scroll={{ y: '' }}
      onRow={(record: StockingParameterData) => {
        return {
          onClick: (e) => {
            e.stopPropagation();

            dispatch(detailStockingParameterSlice.fetchCompanyStockingParameters({ companyId: record.companyId }));
            dispatch(detailStockingParameterSlice.fetchStockingParameter({ _id: record._id }));
            dispatch(detailStockingParameterSlice.setShowEditStockingParameterModal(true));
          },
        };
      }}
      pagination={{
        size: 'default',
        defaultPageSize: limit,
        pageSize: limit,
        total: total,
        onChange: onChangePage,
        showSizeChanger: false,
        current: currentPage
      }}
    />
  );
};
