import React from 'react';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';

import Icon from '../components/Icon/Icon';

import './Notification.scss';

export const openSuccessNotification = (title: string, body?: string) => {
  const placement: NotificationPlacement = 'topRight';
  notification.success({
    className: 'successNotification',
    duration: 8,
    message: title,
    description: body,
    placement,
    style: { border: '2px solid #0000ff', borderRadius: '6px' },
    icon: <Icon name='check' />,
    closeIcon: <Icon id='close_success_notification' name='close' />
  });
};

export const openErrorNotification = (title: string, body?: string) => {
  const placement: NotificationPlacement = 'topRight';
  notification.error({
    className: 'error',
    duration: 8,
    message: title,
    description: body,
    placement,
    style: { border: '2px solid #$orange-red', borderRadius: '6px' },
    icon: <Icon name='error-warning' />,
    closeIcon: <Icon id='close_error_notification' name='close' />
  });
};

export const openWarningNotification = (title: string, body?: string) => {
  const placement: NotificationPlacement = 'topRight';
  notification.warning({
    className: 'warningNotification',
    message: title,
    description: body,
    placement,
    style: { border: '2px solid #ff9000', borderRadius: '6px' },
    icon: <Icon name='error-warning' />,
    closeIcon: <Icon id='close_warning_notification' name='close' />
  });
};
