import cx from 'classnames';
import { ReactNode } from 'react';

import { THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';

import styles from './DataItemDetail.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  value: string | number | ReactNode;
  label: string;
}

export default function DataItemDetail (props: Props) {
  const { theme, value, label } = props;

  const isLightTheme = theme === THEME.LIGHT;

  const renderValue = (value: string | number | ReactNode) => {
    return (
      <LrvText
        className={styles.value}
        theme={theme}
        text={value}
      />
    );
  };

  const renderFieldText = (text: string) => {
    return (
      <LrvText
        className={cx(styles.field, isLightTheme ? styles.fieldLight : styles.fieldDark)}
        text={text}
        theme={theme}
      />
    );
  };

  return (
    <div className={styles.gridItem}>
      {renderValue(value)}
      {renderFieldText(label)}
    </div>
  );
}
