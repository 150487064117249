import cx from 'classnames';
import React, { useState, ReactNode } from 'react';

import styles from './MobileFloatingButton.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  onClose: () => void;
}

const MobileFloatingButton = (props: Props) => {
  const { children, className, onClose } = props;

  const [position, setPosition] = useState({ x: window.innerWidth - 70, y: 20 });
  const [isDragging, setIsDragging] = useState(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    const touch = e.touches[0];
    const xOffset = touch.clientX - position.x;
    const yOffset = touch.clientY - position.y;

    const handleTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0];
      const newX = touch.clientX - xOffset;
      const newY = touch.clientY - yOffset;
      setPosition({ x: newX, y: newY });
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      document.body.style.cursor = 'default';
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };

    document.body.style.cursor = 'grabbing';
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
  };

  return (
    <div
      className={cx(styles.floatingButton, className)}
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
      }}
      onTouchStart={handleTouchStart}
      onClick={onClose}
    >
      {children}
    </div>
  );
};

export default MobileFloatingButton;
