export const HISTOGRAM_HEIGHT = 354;
export const HISTOGRAM_WIDTH = 640;

export const calculateBinCenters = (bins: number[]): number[] => {
  const binCenters = [];
  for (let i = 0; i < bins.length - 1; i++) {
    binCenters.push((bins[i] + bins[i + 1]) / 2);
  }
  return binCenters;
};

export const calculateWeightedAverage = (values: number[], weights: number[]): number => {
  const weightedSum = values.reduce((sum, value, index) => sum + (value * weights[index]), 0);
  const totalWeights = weights.reduce((sum, weight) => sum + weight, 0);
  return weightedSum / totalWeights;
};

export const calculateWeightedStdDev = (values: number[], weights: number[], mean: number): number => {
  const variance = values.reduce((sum, value, index) => {
    return sum + (weights[index] * Math.pow((value - mean), 2));
  }, 0);

  const totalWeights = weights.reduce((sum, weight) => sum + weight, 0);
  return Math.sqrt(variance / totalWeights);
};

export const calculateCoefficientOfVariation = (stdDev: number, mean: number): number => {
  return stdDev / mean;
};
