import React from 'react';
import cx from 'classnames';
import { Radio } from 'antd';

import { LrvRadioProps } from '../../interfaces/lrvRadio';

import styles from './LrvRadio.module.scss';

export const LrvRadio = (props: LrvRadioProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkRadio : styles.lightRadio, className),
  };

  return (
    <Radio {...lrvProps}>
    </Radio>
  );
};
