import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ANALYSIS_BY_CATEGORY_URL,
  CAMPUS_URL,
  CAMPUS_USAGE_URL,
  CATEGORY_USAGE_URL,
  MATURATIONS_URL,
  STAGE_USAGE_URL,
  UNASSIGNED_STOCKINGS_URL,
  USERS_USAGE_URL
} from '../../config/config.api';
import { getHoursOffset } from '../../utils/date';
import { axiosClient as axios } from '../../utils/axios_instance';
import { DATE_FORMATS, unitStatuses } from '../../config/commons';
import { GenericParam } from '../../common/interfaces/commons';
import { getUserSession } from '../../utils/userSession';

import { getFromDateDefault, getToDateDefault, initialActivityFiltersState } from './helpers';
import { Campus, ActivityDashboardState, StageActivity, ActivityFiltersPayload } from './interfaces';

const initialState: ActivityDashboardState = {
  usersActivity: [],
  campusActivity: [],
  stagesActivity: [],
  stagesFullActivity: [],
  unassignedStockings: [],
  categoriesActivity: [],
  analysisCategory: [],
  companyMaturations: [],
  isUsersActivityLoading: false,
  isCampusActivityLoading: false,
  isStagesActivityLoading: false,
  isStagesFullActivityLoading: false,
  isCategoriesActivityLoading: false,
  isUnassignedStockingsLoading: false,
  isAnalysisCategoryLoading: false,
  filters: {
    fromDate: getFromDateDefault(),
    toDate: getToDateDefault(),
    campus: {
      _id: '',
      name: ''
    },
    campuses: [],
  },
};

export const clientDashboardSlice = createSlice({
  name: 'activityDashboard',
  initialState,
  reducers: {
    setUsersActivity: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.usersActivity = action.payload;
    },
    setCampusActivity: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.campusActivity = action.payload;
    },
    setStagesActivity: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.stagesActivity = action.payload;
    },
    setStagesFullActivity: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.stagesFullActivity = action.payload;
    },
    setCategoriesActivity: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.categoriesActivity = action.payload;
    },
    setUnassignedStockings: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.unassignedStockings = action.payload;
    },
    setAnalysisCategory: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.analysisCategory = action.payload;
    },
    setCompanyMaturations: (state: ActivityDashboardState, action: PayloadAction<[]>) => {
      state.companyMaturations = action.payload;
    },
    setIsUsersActivityLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isUsersActivityLoading = action.payload;
    },
    setIsCampusActivityLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isCampusActivityLoading = action.payload;
    },
    setIsStagesActivityLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isStagesActivityLoading = action.payload;
    },
    setIsStagesFullActivityLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isStagesFullActivityLoading = action.payload;
    },
    setIsCategoriesActivityLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isCategoriesActivityLoading = action.payload;
    },
    setIsUnassignedStockingsLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isUnassignedStockingsLoading = action.payload;
    },
    setIsAnalysisCategoryLoading: (state: ActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isAnalysisCategoryLoading = action.payload;
    },

    setCampuses: (state: ActivityDashboardState, action: PayloadAction<Campus[]>) => {
      state.filters.campuses = action.payload;
    },
    setFromDate: (state: ActivityDashboardState, action: PayloadAction<string>) => {
      state.filters.fromDate = action.payload;
    },
    setToDate: (state: ActivityDashboardState, action: PayloadAction<string>) => {
      state.filters.toDate = action.payload;
    },
    setSelectedCampus: (state: ActivityDashboardState, action: PayloadAction<Campus>) => {
      state.filters.campus._id = action.payload._id;
      state.filters.campus.name = action.payload.name;
    },
    setActivityDashboardFilters: (state: ActivityDashboardState, action: PayloadAction<ActivityFiltersPayload>) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setCampuses,
  setUsersActivity,
  setIsUsersActivityLoading,
  setCampusActivity,
  setIsCampusActivityLoading,
  setIsStagesActivityLoading,
  setStagesActivity,
  setStagesFullActivity,
  setIsCategoriesActivityLoading,
  setCategoriesActivity,
  setIsUnassignedStockingsLoading,
  setUnassignedStockings,
  setIsAnalysisCategoryLoading,
  setAnalysisCategory,
  setIsStagesFullActivityLoading,
  setCompanyMaturations,

  setFromDate,
  setToDate,
  setSelectedCampus,
  setActivityDashboardFilters,
} = clientDashboardSlice.actions;

export const fetchCampuses = (phaseType: string, companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType,
    active: true,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    '$sort[name]': 1
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchMaturations = (companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();
  const params = {
    $limit: -1,
    '$sort[name]': 1,
    $select: ['name'],
    active: true,
    companyId: companyId ? companyId : userSession.companyId
  };

  try {
    const response = await axios.get(MATURATIONS_URL, { params });
    dispatch(setCompanyMaturations(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchUsersActivity = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string }) => async (dispatch: Function) => {
  let usersActivity = [];
  dispatch(setIsUsersActivityLoading(true));
  try {
    const params: GenericParam = {
      fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
      toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
      phaseType: filterData.phaseType,
      hoursOffset: getHoursOffset()
    };

    if (filterData.companyId) {
      params.companyId = filterData.companyId;
    }

    if (filterData.campusId) {
      params.campusId = filterData.campusId;
    }

    const response = await axios.get(USERS_USAGE_URL, { params });
    usersActivity = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setUsersActivity(usersActivity));
  dispatch(setIsUsersActivityLoading(false));
};

export const fetchCampusActivity = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string; unitPhaseType: string }) => async (dispatch: Function) => {
  let campusActivity = [];
  dispatch(setIsCampusActivityLoading(true));

  const params: GenericParam = {
    fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
    toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
    phaseType: filterData.phaseType,
    hoursOffset: getHoursOffset(),
    unitPhaseType: filterData.unitPhaseType
  };

  if (filterData.companyId) {
    params.companyId = filterData.companyId;
  }

  if (filterData.campusId) {
    params.campusId = filterData.campusId;
  }

  try {
    const response = await axios.get(CAMPUS_USAGE_URL, { params });
    campusActivity = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setCampusActivity(campusActivity));
  dispatch(setIsCampusActivityLoading(false));
};

export const fetchStagesActivity = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string }) => async (dispatch: Function) => {
  dispatch(setIsStagesActivityLoading(true));
  try {
    const params: GenericParam = {
      fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
      toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
      phaseType: filterData.phaseType,
      hoursOffset: getHoursOffset()
    };

    if (filterData.companyId) {
      params.companyId = filterData.companyId;
    }

    if (filterData.campusId) {
      params.campusId = filterData.campusId;
    }

    const response = await axios.get(STAGE_USAGE_URL, { params });
    dispatch(setStagesActivity(response.data));
    dispatch(setIsStagesActivityLoading(false));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchStagesFullActivity = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string; maturationId?: string }, maxStage: number) => async (dispatch: Function) => {
  let stagesActivity = [];
  dispatch(setIsStagesFullActivityLoading(true));
  try {
    const params: GenericParam = {
      fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
      toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
      phaseType: filterData.phaseType,
      hoursOffset: getHoursOffset()
    };

    if (filterData.companyId) {
      params.companyId = filterData.companyId;
    }

    if (filterData.campusId) {
      params.campusId = filterData.campusId;
    }

    if (filterData.maturationId) {
      params.maturationId = filterData.maturationId;
    }

    const response = await axios.get(STAGE_USAGE_URL, { params });

    stagesActivity = response.data.filter((element: StageActivity) => element.stage <= maxStage);
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setStagesFullActivity(stagesActivity));
  dispatch(setIsStagesFullActivityLoading(false));
};

export const fetchCategoriesActivity = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string }) => async (dispatch: Function) => {
  let categoriesActivity = [];
  dispatch(setIsCategoriesActivityLoading(true));
  const params: GenericParam = {
    fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
    toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
    phaseType: filterData.phaseType,
    hoursOffset: getHoursOffset()
  };

  try {
    if (filterData.companyId) {
      params.companyId = filterData.companyId;
    }

    if (filterData.campusId) {
      params.campusId = filterData.campusId;
    }

    const response = await axios.get(CATEGORY_USAGE_URL, { params });
    categoriesActivity = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setCategoriesActivity(categoriesActivity));
  dispatch(setIsCategoriesActivityLoading(false));
};

export const fetchUnassignedStockings = (companyId?: string) => async (dispatch: Function) => {
  let stockings = [];
  dispatch(setIsUnassignedStockingsLoading(true));
  const userSession = getUserSession();
  try {
    const params = {
      companyId: companyId ? companyId : userSession.companyId
    };
    const response = await axios.get(UNASSIGNED_STOCKINGS_URL, { params });
    stockings = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setUnassignedStockings(stockings));
  dispatch(setIsUnassignedStockingsLoading(false));
};

export const fetchAnalysisCategory = (filterData: { fromDate: string; toDate: string; phaseType: string; companyId?: string; campusId?: string; category: string }) => async (dispatch: Function) => {
  let analysis = [];
  dispatch(setIsAnalysisCategoryLoading(true));
  try {
    const params: GenericParam = {
      fromDate: moment(filterData.fromDate).format(DATE_FORMATS.YYYY_MM_DD),
      toDate: moment(filterData.toDate).format(DATE_FORMATS.YYYY_MM_DD),
      phaseType: filterData.phaseType,
      hoursOffset: getHoursOffset(),
      category: filterData.category
    };

    if (filterData.companyId) {
      params.companyId = filterData.companyId;
    }

    if (filterData.campusId) {
      params.campusId = filterData.campusId;
    }
    const response = await axios.get(ANALYSIS_BY_CATEGORY_URL, { params });
    analysis = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setAnalysisCategory(analysis));
  dispatch(setIsAnalysisCategoryLoading(false));
};

export const resetActivityDashboardFilters = () => (dispatch: Function) => {
  dispatch(setActivityDashboardFilters(initialActivityFiltersState));
};

export default clientDashboardSlice.reducer;
