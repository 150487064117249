import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { RootState } from '../../../../state/store';
import { UpdateBaseMaturation } from '../interfaces';
import { getCurrentTheme } from '../../../../helpers/theme';
import * as baseMaturationsSlice from '../baseMaturationsSlice';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import DotSpinner from '../../../../common/components/DotSpinner/DotSpinner';
import Breadcrumbs from '../../../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../../../AppHeader/headerSlice';
import { LrvCheckbox } from '../../../../common/components/LrvCheckbox/LrvCheckbox';

import styles from './GeneticsGlobalDetail.module.scss';

type TParams = { id: string };

const GeneticsGlobalDetail = ({ match }: RouteComponentProps<TParams>) => {
  const maturationBaseId = match.params.id;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const [form] = Form.useForm();

  const [name, setName] = useState('');
  const [active, setActive] = useState(false);
  const [newName, setNewName] = useState<string | undefined>('');

  const { breadcrumb } = useSelector((state: RootState) => state.header);
  const { baseMaturation, isLoading } = useSelector((state: RootState) => state.baseMaturations);

  useEffect(() => {
    dispatch(changeHeader({ title: 'maturations.title' }));
  }, [dispatch]);

  useEffect(() => {
    const breadcrumb = [
      { id: 'maturations', name: t('maturations.title'), url: '/genetics/global' },
      { id: 'maturation-detail', name: `${t('maturations.name')} ${baseMaturation.name}`, url: `/genetics/global/${maturationBaseId}` },
    ];
    dispatch(changeBreadcrumb(breadcrumb));
  }, [dispatch, maturationBaseId, baseMaturation.name]);

  useEffect(() => {
    if (!baseMaturation._id) {
      dispatch(baseMaturationsSlice.fetchBaseMaturation(maturationBaseId));
      return;
    }

    setName(baseMaturation.name);
    setNewName(baseMaturation.newName);
    setActive(baseMaturation.active);

    form.setFieldsValue({
      name: baseMaturation.name,
      newName: baseMaturation.newName,
    });
  }, [dispatch, baseMaturation, maturationBaseId, form]);

  if (isLoading) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.geneticsGlobal}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />

      <LrvForm
        id='form'
        className={styles.form}
        form={form}
        name='form'
        layout='vertical'
        theme={theme}
        onFinish={() => {
          const params: UpdateBaseMaturation = {
            id: baseMaturation._id,
            active,
            name,
            newName,
            companyId: baseMaturation.companyId,
            companyName: baseMaturation.companyName,
            codes: baseMaturation.codes,
          };
          dispatch(baseMaturationsSlice.updateBaseMaturation(params));
        }}
      >
        <Form.Item>
          <LrvText text={t('maturations.update')} className={styles.title} theme={theme} />
        </Form.Item>

        <Form.Item
          name='name'
          label={t('maturations.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <LrvInput
            theme={theme}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Item>

        {
          baseMaturation.newName &&
          <Form.Item
            name='newName'
            label={t('maturations.newMaturation')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvInput
              theme={theme}
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Form.Item>
        }

        <Form.Item>
          <LrvCheckbox
            theme={theme}
            onChange={event => setActive(event.target.checked)}
            checked={active}
          >
            {t('maturations.activate')}
          </LrvCheckbox>
        </Form.Item>

        <Form.Item>
          <div className={styles.alignButtonRight} >
            <Button
              id='submit_button'
              htmlType='submit'
              type='primary'
            >
              &nbsp;{t('maturations.accept')}
            </Button>
          </div>
        </Form.Item>
      </LrvForm>
    </div>
  );
};

export default GeneticsGlobalDetail;
