import i18next from 'i18next';
import { groupBy, mean } from 'lodash';

import { typeParam } from '../../../common/components/charts/ShadedPlot/helpers';
import { animalDaysUnit, stockingPhaseTypes, roundWeight, roundLength, roundTwoDecimals, weightUnits, roundFourDecimals, lengthUnits } from '../../../config/commons';

import { AnalysisMultiphase } from './interfaces';

export const subtractWidth = 80;
export const subtractHeight = 142;

export const getMarginArea = () => {
  const marginDefault = 0.1;
  return marginDefault;
};

export const formatter = (phaseType: string, value: number) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return `${animalDaysUnit.PL}${value}`;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return `${value}${animalDaysUnit.D}`;
  }
};

export const getStageUnit = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return animalDaysUnit.PL;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('stockings.days');
  }
};

export const getMinY = (points: AnalysisMultiphase[]) => {
  let minY = points[0].y;

  for (let index = 0; index < points.length; index++) {
    const point = points[index];

    if (minY > point.y) {
      minY = point.y;
    }
  }

  return minY;
};

export const getMaxY = (points: AnalysisMultiphase[]) => {
  let maxY = 0;

  for (let index = 0; index < points.length; index++) {
    const point = points[index];

    if (maxY < point.y) {
      maxY = point.y;
    }
  }

  return maxY;
};

export const filterPointByDay = (points: AnalysisMultiphase[], x: number) => {
  return points.filter((point) => point.x === x);
};

export const getNumberTicksXAxis = (firstDay: number, lastDay: number) => {
  return lastDay - firstDay + 1;
};

export const renderTickLeftFormat = (format: d3.AxisDomain, parameter: string, phaseType: string) => {
  const value: string | number = format.valueOf();

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return value;
  }

  if (parameter === typeParam.AVG_WEIGHT) {
    return roundWeight({ value: parseInt(`${value}`), showUnit: false, forceCast: true });
  }

  if (parameter === typeParam.AVG_LENGTH) {
    return roundLength({ value: parseInt(`${value}`), showUnit: false, forceCast: true });
  }

  return value;
};

export const getParameterValue = (point: AnalysisMultiphase, parameter: string) => {
  switch (parameter) {
    case typeParam.AVG_WEIGHT:
      return point.resultData.averageWeight;

    case typeParam.AVG_LENGTH:
      return point.resultData.averageLength;

    case typeParam.CV_LENGTH:
      return point.resultData.variationCoefficientLength;

    case typeParam.PIGMENTATION:
      return point.resultData.pigmentation;

    case typeParam.PLG:
      return point.resultData.larvaePerGram;

    case typeParam.UNIFORMITY:
      return point.resultData.uniformity;

    default:
      return 0;
  }
};

const getYValue = (points: AnalysisMultiphase[]) => {
  const values = points.map((point) => point.y);
  const average = mean(values);
  return roundTwoDecimals(average);
};

const getAverageWeight = (points: AnalysisMultiphase[]) => {
  const weights = points.map((point) => point.resultData.averageWeight);
  const average = mean(weights);
  return roundTwoDecimals(average);
};

const getAverageLength = (points: AnalysisMultiphase[]) => {
  const lengths = points.map((point) => point.resultData.averageLength);
  const average = mean(lengths);
  return roundTwoDecimals(average);
};

const getAverageLarvaePerGram = (points: AnalysisMultiphase[]) => {
  const larvaePerGram = points.map((point) => point.resultData.larvaePerGram);
  const average = mean(larvaePerGram);
  return roundTwoDecimals(average);
};

const getAverageUniformity = (points: AnalysisMultiphase[]) => {
  const uniformity = points.map((point) => point.resultData.uniformity);
  const average = mean(uniformity);
  return roundTwoDecimals(average);
};

const getAverageCVLength = (points: AnalysisMultiphase[]) => {
  const variationCoefficientLength = points.map((point) => point.resultData.variationCoefficientLength);
  const average = mean(variationCoefficientLength);
  return roundTwoDecimals(average);
};

const getAveragePigmentationByStage = (points: AnalysisMultiphase[]) => {
  const pigmentation = points.map((point) => point.resultData.pigmentation);
  const average = mean(pigmentation);
  return roundTwoDecimals(average);
};

export const getAnalysisMultiphaseFilter = (points: AnalysisMultiphase[], phaseTypesSelected: string[], parameter: string) => {
  if (!phaseTypesSelected.length) {
    return [];
  }

  const pointsFilterByPhase: AnalysisMultiphase[] = points.filter((point) => phaseTypesSelected.includes(point.phaseType));
  const pointsFilter: AnalysisMultiphase[] = [];

  for (let index = 0; index < pointsFilterByPhase.length; index++) {
    const point: AnalysisMultiphase = { ...pointsFilterByPhase[index] };

    point.y = getParameterValue(point, parameter);
    pointsFilter.push(point);
  }

  return pointsFilter;
};

export const groupPointsByCreatedAt = (points: AnalysisMultiphase[]) => {
  const pointsGroupByCreatedAt: AnalysisMultiphase[] = [];
  const pointsGroupByDate = groupBy(points, 'createdAt');

  for (const key in pointsGroupByDate) {
    if (Object.prototype.hasOwnProperty.call(pointsGroupByDate, key)) {
      const dataByDate = pointsGroupByDate[key];
      const point: AnalysisMultiphase = {
        ...dataByDate[0],
        y: getYValue(dataByDate),
        resultData: {
          averageWeight: getAverageWeight(dataByDate),
          averageLength: getAverageLength(dataByDate),
          larvaePerGram: getAverageLarvaePerGram(dataByDate),
          uniformity: getAverageUniformity(dataByDate),
          variationCoefficientLength: getAverageCVLength(dataByDate),
          pigmentation: getAveragePigmentationByStage(dataByDate),
          histogramPigmentation: [],
        },
      };
      pointsGroupByCreatedAt.push(point);
    }
  }

  return pointsGroupByCreatedAt;
};

export const roundWeightMultiphase = (value: number) => {
  const constantToConvert = 1000;

  const number = roundFourDecimals(value / constantToConvert);
  return `${number} ${weightUnits.G}`;
};

export const roundLengthMultiphase = (value: number) => {
  const constantToConvert = 10;

  const number = roundFourDecimals(value / constantToConvert);
  return `${number} ${lengthUnits.CM}`;
};