import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { getCurrentTheme } from '../../../helpers/theme';
import { getUserSession } from '../../../utils/userSession';
import { formatLongDateWithOffset } from '../../../utils/date';
import { isConsolidatedAnalysis } from '../../Analysis/helpers';
import { Area, DataSource, Point } from '../../home/interfaces';
import { roundOneDecimal, roundTwoDecimals, roundWeight, roundLength, stockingPhaseTypes, THEME, weightUnits, lengthUnits } from '../../../config/commons';
import { typeScale, BLUE_COLOR, typeParam, typesChart, getPoints, getPointX, getPointY, getFill, palette, metricsStatuses } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './MultiphaseChart.module.scss';
import { AnalysisMultiphase, DataMultiphaseByDay } from './interfaces';
import { getMaxY, getMinY, getMarginArea, renderTickLeftFormat, formatter, groupPointsByCreatedAt, filterPointByDay, roundWeightMultiphase, roundLengthMultiphase, getStageUnit } from './multiphase-helpers';

let currentDateActive: string;
let currentDateConsolidatedActive: string;

const DEFAULT_CIRCLE = 9;
const CONSOLIDATED_CIRCLE = 11;
const CONSOLIDATED_CIRCLE_ACTIVE = CONSOLIDATED_CIRCLE + 3;
const DEFAULT_CIRCLE_ACTIVE = DEFAULT_CIRCLE + 3;

const Y0 = 16;

interface Props {
  colorFillRect?: string;
  colorLine: string;
  container: HTMLDivElement | null;
  analysisMultiphaseData: AnalysisMultiphase[];
  showReferenceArea: boolean;
  phaseTypeSelected: string;
  height: number;
  typeMetric?: string;
  parameter: string;
  phaseType: string;
  scale: string;
  showLabels?: boolean;
  width: number;
}

export default class D3Multiphase {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined>;

  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  fill: string = palette.GLOBAL;

  margin = { top: 5, right: 24, bottom: 50, left: 50 };
  axisY: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  scale: string;
  typeMetric: string;
  parameter: string;
  phaseType: string;
  analysisMultiphaseData: AnalysisMultiphase[] = [];

  showReferenceArea = false;
  phaseTypeSelected = '';
  dataSource: DataSource[] = [];
  allPoints: Point[] = [];

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  width: number;
  height: number;
  timeTransition = 300;

  containerLeft: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerRight: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerTop: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerBottom: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  colorFillRect = '';
  colorLine = '';

  showLabels: boolean;

  // eslint-disable-next-line
  constructor(props: Props) {
    const { colorFillRect = '', typeMetric = metricsStatuses.GLOBAL, phaseTypeSelected, showReferenceArea, colorLine, container, analysisMultiphaseData, height, parameter, phaseType, scale, showLabels = false, width } = props;

    this.container = container;
    this.scale = scale;
    this.typeMetric = typeMetric;
    this.parameter = parameter;
    this.phaseType = phaseType;
    this.analysisMultiphaseData = analysisMultiphaseData;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.phaseTypeSelected = phaseTypeSelected;
    this.showReferenceArea = showReferenceArea;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.showLabels = showLabels;

    d3.select(container).select('#tooltip').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom)
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.sortData();

    this.fill = getFill({ dataSource: typeMetric });
    this.updateDataPoints();
    this.updateBorders();
    this.renderTooltips();
  }

  getDataLarvae (analysisMultiphaseData: AnalysisMultiphase[]) {
    return analysisMultiphaseData.filter((point: AnalysisMultiphase) => point.phaseType === stockingPhaseTypes.LARVAE);
  }

  getDataJuvenile (analysisMultiphaseData: AnalysisMultiphase[]) {

    return analysisMultiphaseData.filter((point: AnalysisMultiphase) => point.phaseType === stockingPhaseTypes.JUVENILE);
  }

  getDataGrowOut (analysisMultiphaseData: AnalysisMultiphase[]) {
    return analysisMultiphaseData.filter((point: AnalysisMultiphase) => point.phaseType === stockingPhaseTypes.ADULT);
  }

  getLimitArea () {
    const { analysisMultiphaseData } = this;
    const dataLarvae = this.getDataLarvae(analysisMultiphaseData);
    const dataJuvenile = this.getDataJuvenile(analysisMultiphaseData);
    const dataGrowOut = this.getDataGrowOut(analysisMultiphaseData);

    const response = [];
    let pointOne = 0;
    let pointTwo = 0;
    let pointThree = 0;
    let pointFour = 0;
    let extraValueX = 0.5;

    if (dataLarvae.length > 0) {
      if (dataJuvenile.length === 0 && dataGrowOut.length === 0) {
        extraValueX = 0;
      }

      pointOne = this.x(dataLarvae[0].x);
      pointTwo = this.x(dataLarvae[dataLarvae.length - 1].x + extraValueX);

      const item = { x1: pointOne, x2: pointTwo, phaseType: stockingPhaseTypes.LARVAE, color: '#5C95FF1F' };
      response.push(item);
    }

    if (dataJuvenile.length > 0) {
      extraValueX = 0.5;
      if (dataGrowOut.length === 0) {
        extraValueX = 0;
      }

      pointThree = this.x(dataJuvenile[dataJuvenile.length - 1].x + extraValueX);

      const item = { x1: pointTwo, x2: pointThree, phaseType: stockingPhaseTypes.JUVENILE, color: '#3AFFAC1C' };
      response.push(item);
    }

    if (dataGrowOut.length > 0) {
      pointFour = this.x(dataGrowOut[dataGrowOut.length - 1].x);

      const x1 = dataJuvenile.length > 0 ? pointThree : pointTwo;
      const item = { x1, x2: pointFour, phaseType: stockingPhaseTypes.ADULT, color: '#FF568926' };
      response.push(item);
    }

    return response;
  }

  sortDataSource () {
    const { dataSource, parameter } = this;
    const chart = typesChart.STOCKINGS;

    for (let i = 0; i < dataSource.length; i++) {
      const points = getPoints(dataSource[i], chart);
      const avgPoints: Point[] = dataSource[i].avgPoint;

      const pointsList: Point[] = [];
      const avgPointsList: Point[] = [];

      if (points) {
        // eslint-disable-next-line
        for (let index = 0; index < points.length; index++) {
          const item: Point = {
            ...points[index],
            x: getPointX(points[index], chart),
            y: getPointY(points[index], chart, parameter),
          };
          pointsList.push(item);
        }

        dataSource[i].points = pointsList;
        dataSource[i].points?.sort((a, b) => a.x - b.x);
      }

      for (let index = 0; index < avgPoints.length; index++) {
        const item = {
          ...avgPoints[index],
          x: getPointX(avgPoints[index], chart),
          y: getPointY(avgPoints[index], chart, parameter),
        };
        avgPointsList.push(item);
      }

      dataSource[i].avgPoint = avgPointsList;
      dataSource[i].avgPoint?.sort((a, b) => a.x - b.x);
    }
  }

  sortAllPoints () {
    const { dataSource, parameter } = this;
    const pointsList: Point[] = [];
    const chart = typesChart.STOCKINGS;

    for (let i = 0; i < dataSource.length; i++) {
      const enabled = dataSource[i].enabled;

      if (!enabled) {
        continue;
      }

      const points = getPoints(dataSource[i], chart);
      if (!points) {
        continue;
      }

      for (let index = 0; index < points.length; index++) {
        const item: Point = {
          ...points[index],
          x: getPointX(points[index], chart),
          y: getPointY(points[index], chart, parameter),
        };
        pointsList.push(item);
      }
    }

    this.allPoints = pointsList;
    this.allPoints.sort((a, b) => a.x - b.x);
  }

  sortData () {
    this.sortDataSource();
    this.sortAllPoints();
  }

  fillAreaChart = () => {
    const { svg, height } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const limits = this.getLimitArea();
    for (let index = 0; index < limits.length; index++) {
      const limit = limits[index];

      svg.append('rect')
        .attr('class', 'areaByPhaseType')
        .attr('x', limit.x1)
        .attr('y', Y0)
        .attr('width', limit.x2 - limit.x1)
        .attr('height', height - Y0)
        .style('fill', limit.color);

      if (index + 1 < limits.length) {
        svg.append('line')
          .attr('class', 'lineByPhaseType')
          .attr('stroke', isLightTheme ? '#737373' : '#4F4F4F')
          .attr('fill', 'transparent')
          .attr('stroke-width', 1)
          .attr('y1', Y0)
          .attr('y2', height)
          .attr('x1', limit.x2)
          .attr('x2', limit.x2);
      }
    }
  };

  updateDataPoints = () => {
    const { svg, height, margin, phaseTypeSelected, typeMetric } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.buildAxisX();
    this.buildAxisY();

    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentDateActive).attr('r', () => DEFAULT_CIRCLE_ACTIVE);
        d3.selectAll(currentDateConsolidatedActive).attr('r', () => DEFAULT_CIRCLE_ACTIVE);
      });

    if (phaseTypeSelected !== stockingPhaseTypes.ADULT && typeMetric !== metricsStatuses.REFERENCE_CURVE) {
      svg.append('line')
        .attr('stroke', '#6b6b6b99')
        .attr('fill', 'transparent')
        .attr('stroke-width', 0.5)
        .attr('stroke-dasharray', 4)
        .attr('y1', -1)
        .attr('y2', - margin.top * 2)
        .attr('x1', 0.5)
        .attr('x2', 0.5);
    }

    this.renderLines();
    this.hideBorders();

    this.drawYAxis();

    this.fillAreaChart();
    this.renderPoints();
    this.renderDays();
    this.renderAxisX();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }

    svg.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', isLightTheme ? '#737373' : '#4F4F4F')
      .attr('stroke-width', 1)
      .attr('y1', Y0)
      .attr('y2', height)
      .style('display', 'none');
  };

  hideBorders () {
    const { svg, width, height, margin } = this;

    // container bottom
    this.containerBottom = svg.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', height)
      .attr('width', width + margin.left + + margin.right)
      .attr('height', margin.bottom)
      .style('fill', this.colorFillRect);

    // container top
    this.containerTop = svg.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', 0)
      .attr('width', width + margin.left + + margin.right)
      .attr('height', margin.top * 4.5)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft = svg.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', margin.left)
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight = svg.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', width + 1)
      .attr('y', 0)
      .attr('width', margin.right)
      .attr('height', height + 1)
      .style('fill', this.colorFillRect);
  }

  updateBorders () {
    const { width, height, margin } = this;

    // container bottom
    this.containerBottom
      .attr('y', height + 1)
      .attr('width', width + margin.left + margin.right)
      .style('fill', this.colorFillRect);

    // container top
    this.containerTop
      .attr('class', 'hideBorder')
      .attr('y', -margin.top)
      .attr('width', width + margin.left + + margin.right)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight
      .attr('x', width + 1)
      .attr('height', height + 1)
      .style('fill', this.colorFillRect);
  }

  renderTooltips () {
    const { analysisMultiphaseData, svg, x, y, tooltip, width, height, renderMultiphaseTooltips, getDataJuvenile, getDataGrowOut } = this;
    const bisect = d3.bisector((point: AnalysisMultiphase) => point.x).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    let previousDate = -1;

    svg.append('rect')
      .attr('id', 'rect')
      .attr('x', 0)
      .attr('y', Y0)
      .attr('width', width)
      .attr('height', height - Y0)
      .style('fill', 'transparent')
      .style('pointer-events', 'all')
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentDateActive).attr('r', () => DEFAULT_CIRCLE);
        d3.selectAll(currentDateConsolidatedActive).attr('r', () => CONSOLIDATED_CIRCLE);
      })
      .on('mousemove', function (event) {
        const x0 = x.invert((d3).pointer(event)[0]);
        const index = bisect(analysisMultiphaseData, x0, 1);

        const previousPoint = analysisMultiphaseData[index - 1];
        const currentPoint = analysisMultiphaseData[index];
        let selectedPoint: AnalysisMultiphase;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.x < analysisMultiphaseData[0].x || selectedPoint.x > analysisMultiphaseData[analysisMultiphaseData.length - 1].x)) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        if (!(previousDate !== selectedPoint.x)) {
          if (currentDateActive) {
            d3.selectAll(currentDateActive).attr('r', () => DEFAULT_CIRCLE_ACTIVE);
          }

          if (currentDateConsolidatedActive) {
            d3.selectAll(currentDateConsolidatedActive).attr('r', () => CONSOLIDATED_CIRCLE_ACTIVE);
          }
          return;
        }

        previousDate = selectedPoint.x;
        const dataMultiphaseByDay: DataMultiphaseByDay[] = [];
        const pointsList: AnalysisMultiphase[] = [];

        const pointsByDay = filterPointByDay(analysisMultiphaseData, selectedPoint.x);
        pointsByDay.forEach((point: AnalysisMultiphase) => {
          dataMultiphaseByDay.push({ point, index });
          pointsList.push(point);
        });

        d3.selectAll('.points circle').attr('r', () => DEFAULT_CIRCLE);
        d3.selectAll('.points .consolidated').attr('r', CONSOLIDATED_CIRCLE);
        d3.selectAll('.triangles circle').attr('r', () => DEFAULT_CIRCLE);
        d3.selectAll('.triangles .consolidated').attr('r', CONSOLIDATED_CIRCLE);

        if (pointsList.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: AnalysisMultiphase = pointsList.reduce(function (prev: AnalysisMultiphase, current: AnalysisMultiphase) {
          return (prev.y > current.y) ? prev : current;
        });
        const lowestValue: AnalysisMultiphase = pointsList.reduce(function (prev: AnalysisMultiphase, current: AnalysisMultiphase) {
          return (prev.y < current.y) ? prev : current;
        });

        const marginLeft = x(higherValue.x);
        const marginBottom = y(lowestValue.y);
        currentDateActive = `.date-${higherValue.x}`;
        currentDateConsolidatedActive = `.date-consolidated-${higherValue.x}`;

        d3.selectAll(currentDateActive).attr('r', () => DEFAULT_CIRCLE_ACTIVE);
        d3.selectAll(currentDateConsolidatedActive).attr('r', () => CONSOLIDATED_CIRCLE_ACTIVE);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 28;
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', () => {
            let value = 0;
            const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

            if ((marginLeft + tooltipTotalWidth) < width) {
              value = -16; // has to be the same that width, but negative
            } else {
              value = tooltipTotalWidth;
            }

            return `${value}px`;
          });

        tooltip
          .style('left', () => {
            if (marginLeft + tooltipTotalWidth < width) {
              const tooltipXCoordinate = marginLeft + (tooltipDialogWidth / 2) + (bubbleWidth / 2);
              return `${tooltipXCoordinate}px`;
            }
            const tooltipXCoordinate = marginLeft - (tooltipDialogWidth / 2) - (bubbleWidth * 2);
            return `${tooltipXCoordinate}px`;

          })
          .style('bottom', () => {
            let value = 0;
            const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;

            if (marginBottom + tooltipTotalHeight > height) {
              value = (height - marginBottom) + (tooltipTotalHeight / 2.5);
            } else {
              value = height - marginBottom;
            }
            return `${value}px`;
          });

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderMultiphaseTooltips(dataMultiphaseByDay, tooltipContent, getDataJuvenile(analysisMultiphaseData), getDataGrowOut(analysisMultiphaseData));
      });
  }

  renderMultiphaseTooltips (dataMultiphaseByDate: DataMultiphaseByDay[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>, dataJuvenile: AnalysisMultiphase[], dataGrowOut: AnalysisMultiphase[]) {
    const userSession = getUserSession();

    for (let index = 0; index < dataMultiphaseByDate.length; index++) {
      const data = dataMultiphaseByDate[index];

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryTitle = entry.append('div')
        .attr('class', styles.entryHeader);

      entryTitle.append(data.point.companyId === userSession.companyId ? 'a' : 'div')
        .attr('class', cx(styles.entryTitle, data.point.companyId === userSession.companyId ? styles.hover : ''))
        .style('color', 'royalblue')
        .attr('href', `/production/analysis/${data.point._id}`)
        .attr('target', '_blank')
        .html(data.point.code);

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      let pigmentationLabel = i18next.t('shadedplot.type.pigmentation');
      pigmentationLabel = pigmentationLabel.toLowerCase();
      pigmentationLabel = pigmentationLabel.charAt(0).toUpperCase() + pigmentationLabel.slice(1);

      if (data.point.phaseType === stockingPhaseTypes.LARVAE) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.inputData.stage')}: <strong>${formatter(data.point.phaseType, data.point.inputData.stage)}</strong>`);
      } else {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.inputData.days')}: <strong>${formatter(data.point.phaseType, data.point.inputData.stage)}</strong>`);
      }

      if (dataJuvenile.length === 0 && dataGrowOut.length === 0) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${data.point.resultData.averageWeight} ${weightUnits.MG}</strong>`);
      } else {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${roundWeightMultiphase(data.point.resultData.averageWeight)}</strong>`);
      }

      if (dataJuvenile.length === 0 && dataGrowOut.length === 0) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageLength')}: <strong>${data.point.resultData.averageLength} ${lengthUnits.MM}</strong>`);
      } else {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.averageLength')}: <strong>${roundLengthMultiphase(data.point.resultData.averageLength)}</strong>`);
      }

      if (data.point.phaseType === stockingPhaseTypes.LARVAE) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('analysis.resultData.larvaePerGram')}: <strong>${data.point.resultData.larvaePerGram}</strong>`);
      }

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('detail.weight')} ${i18next.t('analysis.resultData.uniformity')}: <strong>${data.point.resultData.uniformity} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.variationCoefficientLength')}: <strong>${data.point.resultData.variationCoefficientLength} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${pigmentationLabel}: <strong>${data.point.resultData.pigmentation}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.date')}: <strong>${formatLongDateWithOffset(data.point.createdAt)}</strong>`);

      if (index !== dataMultiphaseByDate.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  renderLines () {
    const { x, y, analysisMultiphaseData, svg, colorLine } = this;
    const pointsGroupByCreatedAt = groupPointsByCreatedAt(analysisMultiphaseData);

    const line = d3.line<AnalysisMultiphase>()
      .x((d: AnalysisMultiphase) => { return x(d.x); })
      .y((d: AnalysisMultiphase) => { return y(d.y); })
      .curve(d3.curveMonotoneX);

    svg.append('g').append('path')
      .datum(pointsGroupByCreatedAt)
      .attr('class', cx('lines'))
      .attr('stroke', colorLine)
      .attr('d', line);
  }

  getParameterValue (point: AnalysisMultiphase) {
    const { parameter } = this;

    if (parameter === typeParam.PIGMENTATION) {
      return roundTwoDecimals(point.y);
    }

    if (parameter === typeParam.AVG_WEIGHT) {
      return roundWeight({ value: point.y, showUnit: false });
    }

    if (parameter === typeParam.CV_LENGTH) {
      return roundLength({ value: point.y, showUnit: false });
    }

    return roundOneDecimal(point.y);
  }

  renderLabelsOfPoints () {
    const { svg, analysisMultiphaseData } = this;
    const pointsGroupByCreatedAt = groupPointsByCreatedAt(analysisMultiphaseData);

    for (let i = 0; i < pointsGroupByCreatedAt.length; i++) {
      const point = pointsGroupByCreatedAt[i];
      const value = this.getParameterValue(point);

      svg.append('text')
        .attr('class', 'labelsPoints')
        .attr('x', this.x(point.x))
        .attr('y', this.y(point.y) - 12)
        .style('text-anchor', 'middle')
        .text(`${value}`);
    }
  }

  fillPoints (point: AnalysisMultiphase) {
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const defaultFillColor = isConsolidatedAnalysis(point.type) ? BLUE_COLOR : isLightTheme ? '#a8b7ec' : '#d9d9d9';
    return defaultFillColor;
  }

  strokePoint (point: AnalysisMultiphase) {
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const defaultStrokeColor = isConsolidatedAnalysis(point.type) ? BLUE_COLOR : isLightTheme ? '#ffffff' : '#131B55';
    return defaultStrokeColor;
  }

  getAnalysisMultiphaseWithUniqueStage () {
    const { analysisMultiphaseData } = this;
    const uniqueSet = new Set<number>();
    const analysisMultiphase: AnalysisMultiphase[] = [];

    for (const data of analysisMultiphaseData) {
      if (!uniqueSet.has(data.x)) {
        uniqueSet.add(data.x);
        analysisMultiphase.push(data);
      }
    }

    return analysisMultiphase;
  }

  getNewAreaValues (area: Area[]) {
    const { analysisMultiphaseData } = this;

    if (analysisMultiphaseData.length < 2 || area.length === 0) {
      return [];
    }

    const newArea: Area[] = [];
    const firstValue = analysisMultiphaseData[0];
    const lastValue = analysisMultiphaseData[analysisMultiphaseData.length - 1];

    let index = area.findIndex(item => item.x === firstValue.inputData.stage);
    let initialX = firstValue.x;

    for (index; area[index]?.x <= lastValue.inputData.stage; index++) {
      const element = area[index];
      const analysisMultiphaseElement = analysisMultiphaseData.find(item => item.inputData.stage === element?.x);
      let x = initialX;
      if (analysisMultiphaseElement?.x) {
        x = analysisMultiphaseElement.x;
      }
      newArea.push({
        ...element,
        x,
      });
      initialX++;
    }
    return newArea;
  }

  getXDayPosition (phaseType: string, analysisMultiphase: AnalysisMultiphase[]) {
    const analysisData = analysisMultiphase.filter(item => item.phaseType === phaseType);
    if (analysisData.length === 0) {
      return 0;
    }

    const firstItem = analysisData[0];
    const lastItem = analysisData[analysisData.length - 1];
    const diff = (lastItem.x - firstItem.x) / 2;
    return firstItem.x + diff;
  }

  renderDays () {
    const { svg, x, height, margin } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    const analysisMultiphase = this.getAnalysisMultiphaseWithUniqueStage();
    const limits = this.getLimitArea();

    svg.append('g')
      .attr('class', 'days')
      .style('pointer-events', 'none')
      .selectAll('days')
      .data(limits)
      .enter()
      .append('text')
      .attr('class', isLightTheme ? styles.daysLabelLight : styles.daysLabelDark)
      .attr('x', (limit) => x(this.getXDayPosition(limit.phaseType, analysisMultiphase)))
      .attr('y', height + margin.bottom - 5)
      .style('text-anchor', 'middle')
      .text((limit) => getStageUnit(limit.phaseType));
  }

  renderAxisX () {
    const { svg, x, margin, height } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const analysisMultiphase = this.getAnalysisMultiphaseWithUniqueStage();

    svg.append('g')
      .attr('class', 'days')
      .style('pointer-events', 'none')
      .selectAll('days')
      .data(analysisMultiphase)
      .enter()
      .append('text')
      .attr('class', isLightTheme ? styles.daysLabelLight : styles.daysLabelDark)
      .attr('x', (point: AnalysisMultiphase) => x(point.x))
      .attr('y', height + (margin.bottom / 1.9))
      .style('text-anchor', 'middle')
      .text((point: AnalysisMultiphase) => point.inputData.stage);
  }

  renderPoints () {
    const { svg, x, y, analysisMultiphaseData } = this;
    const theme = getCurrentTheme();
    const points = analysisMultiphaseData.filter((data) => data.shape === 'circle');
    const triangles = analysisMultiphaseData.filter((data) => data.shape === 'triangle');
    const isLightTheme = theme === THEME.LIGHT;

    svg.append('g')
      .attr('class', 'points')
      .attr('fill', 'transparent')
      .style('pointer-events', 'none')
      .selectAll('points')
      .data(points)
      .enter()
      .append('circle')
      .attr('id', (point: AnalysisMultiphase) => `point_${point.code}`)
      .attr('class', (point: AnalysisMultiphase) => {
        return isConsolidatedAnalysis(point.type) ? `date-consolidated-${point.x} consolidated` : `date-${point.x}`;
      })
      .attr('r', (point: AnalysisMultiphase) => {
        return isConsolidatedAnalysis(point.type) ? CONSOLIDATED_CIRCLE : DEFAULT_CIRCLE;
      })
      .attr('cx', (point: AnalysisMultiphase) => x(point.x))
      .attr('cy', (point: AnalysisMultiphase) => y(point.y))
      .attr('fill', (point: AnalysisMultiphase) => this.fillPoints(point))
      .attr('stroke', isLightTheme ? '#ffffff' : '#131B55')
      .attr('stroke-width', 2);

    svg.append('g')
      .attr('class', 'triangles')
      .attr('fill', 'transparent')
      .style('pointer-events', 'none')
      .selectAll('triangles')
      .data(triangles)
      .enter()
      .append('polygon')
      .attr('id', (triangle: AnalysisMultiphase) => `point_${triangle.code}`)
      .attr('points', (triangle: AnalysisMultiphase) => {
        const valueX = x(triangle.x);
        const valueY = y(triangle.y);
        const size = isConsolidatedAnalysis(triangle.type) ? CONSOLIDATED_CIRCLE : DEFAULT_CIRCLE;
        return `${valueX},${valueY - size} ${valueX - size},${valueY + size} ${valueX + size},${valueY + size}`;
      })
      .attr('class', (point: AnalysisMultiphase) => {
        return isConsolidatedAnalysis(point.type) ? `date-consolidated-${point.x} consolidated` : `date-${point.x}`;
      })
      .attr('fill', (triangle: AnalysisMultiphase) => this.fillPoints(triangle))
      .attr('stroke', isLightTheme ? '#ffffff' : '#131B55')
      .attr('stroke-width', 2);
  }

  deleteDomainAxisY () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisY').selectAll('.domain').remove();
    d3.select(container).select('#content').selectAll('#triangle').remove();
  }

  updateAxis () {
    const { timeTransition, margin, container, parameter, phaseType, analysisMultiphaseData, svg, height, width } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.buildAxisX();
    this.buildAxisY();

    const dataJuvenile = this.getDataJuvenile(analysisMultiphaseData);
    const dataGrowOut = this.getDataGrowOut(analysisMultiphaseData);
    let stockingPhaseType = phaseType;

    if (phaseType !== stockingPhaseTypes.LARVAE && dataJuvenile.length === 0 && dataGrowOut.length === 0) {
      stockingPhaseType = stockingPhaseTypes.LARVAE;
    }

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => `${renderTickLeftFormat(d, parameter, stockingPhaseType)}`)
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.axisY
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition().duration(timeTransition).call(axisLeft);

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 5),
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);

    svg.append('line')
      .attr('class', 'lineAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', Y0 / 2)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    svg.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width + (Y0 / 2));

    this.deleteDomainAxisY();
  }

  updateData () {
    this.updateAxis();
    this.fillAreaChart();

    this.renderLines();
    this.renderPoints();
    this.renderDays();
    this.renderAxisX();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
    this.updateBorders();
  }

  refreshChart (props: { analysisMultiphaseData: AnalysisMultiphase[]; phaseTypeSelected: string; showReferenceArea: boolean; width: number; height: number; scale: string; parameter: string; colorFillRect: string; colorLine: string }) {
    const { analysisMultiphaseData, phaseTypeSelected, showReferenceArea, width, height, parameter, scale, colorFillRect, colorLine } = props;
    const { tooltip, container } = this;

    this.parameter = parameter;
    this.analysisMultiphaseData = analysisMultiphaseData;
    this.scale = scale;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.showReferenceArea = showReferenceArea;
    this.phaseTypeSelected = phaseTypeSelected;

    d3.select(container).select('#rect').remove();
    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();

    d3.select(container).selectAll('.labelsPoints').remove();
    d3.select(container).selectAll('.labelsXAxis').remove();
    d3.select(container).selectAll('.points').remove();
    d3.select(container).selectAll('.days').remove();
    d3.select(container).selectAll('.triangles').remove();
    d3.select(container).selectAll('.lines').remove();
    d3.select(container).selectAll('.lineByPhaseType').remove();
    d3.select(container).selectAll('.areaByPhaseType').remove();
    d3.select(container).selectAll('.separatorLine').remove();
    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);
    this.sortData();
    this.fill = getFill({ dataSource: this.typeMetric });
    this.updateData();
    this.renderTooltips();
  }

  buildAxisX () {
    const { width, analysisMultiphaseData } = this;

    const x0 = analysisMultiphaseData[0].x;
    const x1 = analysisMultiphaseData[analysisMultiphaseData.length - 1].x;

    this.x = d3.scaleLinear()
      .domain([x0, x1])
      .range([0, width]);
  }

  buildAxisY () {
    const { scale, height, analysisMultiphaseData } = this;
    const margin = getMarginArea();
    let minY = getMinY(analysisMultiphaseData);
    let maxY = getMaxY(analysisMultiphaseData);

    minY = minY - (minY * margin) < 0 ? 0 : minY - (minY * margin);
    maxY = maxY + (maxY * margin);

    if (scale === typeScale.LOGARITHMIC) {
      this.y = d3.scaleSymlog()
        .domain([minY, maxY])
        .range([height, 0]);
    } else {
      this.y = d3.scaleLinear()
        .domain([minY, maxY])
        .range([height, 0]);
    }
  }

  drawYAxis () {
    const { svg, parameter, phaseType, analysisMultiphaseData } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const dataJuvenile = this.getDataJuvenile(analysisMultiphaseData);
    const dataGrowOut = this.getDataGrowOut(analysisMultiphaseData);
    let stockingPhaseType = phaseType;

    if (phaseType !== stockingPhaseTypes.LARVAE && dataJuvenile.length === 0 && dataGrowOut.length === 0) {
      stockingPhaseType = stockingPhaseTypes.LARVAE;
    }

    const axis = d3.axisLeft(this.y)
      .tickFormat((d) => `${renderTickLeftFormat(d, parameter, stockingPhaseType)}`)
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.axisY = svg.append('g')
      .attr('id', 'axisY')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);

    this.deleteDomainAxisY();
  }

  resize = (width: number, height: number) => {
    const { container, typeMetric } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltip').remove();

    this.updateSize(width, height);
    this.sortData();
    this.fill = getFill({ dataSource: typeMetric });
    this.updateDataPoints();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };
}