import 'firebase/firestore';
import firebase from 'firebase/app';

import { COLLECTION_NAME, FIRESTORE_API_KEY, FIRESTORE_APP_ID, FIRESTORE_AUTH_DOMAIN, FIRESTORE_DATA_BASE,
  FIRESTORE_MESSAGING_SENDER_ID,FIRESTORE_PROJECT_ID, FIRESTORE_STORAGE_BUCKET } from './config/firestore';

const config = {
  apiKey: FIRESTORE_API_KEY,
  authDomain: FIRESTORE_AUTH_DOMAIN,
  databaseURL: FIRESTORE_DATA_BASE,
  projectId: FIRESTORE_PROJECT_ID,
  storageBucket: FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: FIRESTORE_MESSAGING_SENDER_ID,
  appId: FIRESTORE_APP_ID,
};

firebase.initializeApp(config);
export default firebase;
export const firestore = firebase.firestore();
export const collection = firestore.collection(COLLECTION_NAME);