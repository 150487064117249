import { MenuProps } from 'antd';

type MenuItemAntd = Required<MenuProps>['items'][number];

export const getKeysByPathname = (pathname: string) => {
  switch (pathname) {
    case '/production/stockings':
    case '/production/insights':
    case '/production/parameters':
    case '/production/harvests':
      return ['/production'];

    case '/reports/current-state':
    case '/reports/growth-delta':
    case '/reports/survival-rate':
    case '/reports/laboratory':
    case '/reports/parameter-state':
      return ['/reports'];

    case '/genetics/global':
    case '/genetics/insights':
    case '/genetics/setup':
      return ['/genetics'];

    case '/company/administration-history':
    case '/company/weight-units':
    case '/company/analysis-card':
    case '/company/stocking-parameters':
    case '/company/transports':
      return ['/company'];
  }

  return [];
};

export const getMenuItem = (
  props: {
    id: string;
    label: React.ReactNode;
    key: React.Key;
    icon?: React.ReactNode;
    children?: MenuItemAntd[];
    type?: 'group';
    disabled?: boolean;
    onClick?: Function;
    selectedKeys?: string[];
    className?: string;
  }
): MenuItemAntd => {
  const { id, label, key, icon, children, type, disabled, onClick, selectedKeys, className } = props;

  return {
    id,
    key,
    icon,
    children,
    label,
    type,
    disabled,
    onClick,
    selectedKeys,
    className,
  } as MenuItemAntd;
};