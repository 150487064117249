import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TRANSPORT_URL } from '../../../config/config.api';
import { paginationTransports } from '../../../config/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { Transport, TransportPayload, TransportState } from './interfaces';

const initialState: TransportState = {
  currentPage: 0,
  isLoading: false,
  limit: 0,
  showEditModal: false,
  showNewModal: false,
  skip: 0,
  total: 0,
  transports: [],
  transport: {
    active: false,
    plate: '',
    type: 'CAPSULE',
    pumpName: '',
  },
};

export const transportSlice = createSlice({
  name: 'transports',
  initialState,
  reducers: {
    setTransports: (state: TransportState, action: PayloadAction<TransportPayload>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.transports = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },
    setIsLoading: (state: TransportState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowNewModal: (state: TransportState, action: PayloadAction<boolean>) => {
      state.showNewModal = action.payload;
    },
    setShowEditModal: (state: TransportState, action: PayloadAction<boolean>) => {
      state.showEditModal = action.payload;
    },
    setTransport: (state: TransportState, action: PayloadAction<Transport | undefined>) => {
      state.transport = action.payload;
    },
    setCurrentPage: (state: TransportState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
});

export const {
  setTransports,
  setIsLoading,
  setShowNewModal,
  setShowEditModal,
  setTransport,
  setCurrentPage,
} = transportSlice.actions;

export const fetchTransports = (props: { currentPage: number }) => async (dispatch: Function) => {
  const { currentPage } = props;

  let skip = 0;
  if (currentPage !== 0) {
    skip = paginationTransports * (currentPage - 1);
  }

  const params = {
    $limit: paginationTransports,
    $skip: skip,
    active: true,
  };

  try {
    const response = await axios.get<TransportPayload>(TRANSPORT_URL, { params });
    dispatch(setTransports(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createTransport = (body: Transport) => async (dispatch: Function) => {
  try {
    await axios.post(TRANSPORT_URL, body);
    dispatch(fetchTransports({ currentPage: 0 }));
    openSuccessNotification(i18next.t('weightUnit.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateTransport = (body: Transport) => async (dispatch: Function) => {
  const url = `${TRANSPORT_URL}/${body._id}`;

  try {
    await axios.patch(url, body);
    dispatch(fetchTransports({ currentPage: 0 }));
    openSuccessNotification(i18next.t('weightUnit.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default transportSlice.reducer;
