import React from 'react';
import { useTranslation } from 'react-i18next';
import { StopOutlined } from '@ant-design/icons';

import { LrvResult } from '../LrvResult/LrvResult';

import styles from './NotPermission.module.scss';

export default function NotPermission () {

  const [t] = useTranslation();

  return (
    <div id='not_authorized' className={styles.showResult}>
      <LrvResult
        icon={<StopOutlined className={styles.icon} />}
        title={<div className={styles.message}> {t('unauthorizedPage.subTitle')}</div>}
      />
    </div>
  );
}
