import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { RootState } from '../../../state/store';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { Breadcrumb } from '../../AppHeader/interfaces';
import { validatePIN } from '../../../utils/validations';
import * as headerSlice from '../../AppHeader/headerSlice';
import larviaLogoSvg from '../../../assets/larvia-light.svg';
import { getUserSession } from '../../../utils/userSession';
import Content from '../../../common/components/Content/Content';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvTabs } from '../../../common/components/LrvTabs/LrvTabs';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvResult } from '../../../common/components/LrvResult/LrvResult';
import Breadcrumbs from '../../../common/components/Breadcrumb/Breadcrumbs';
import { getTitleCountAnimalChart, isConsolidatedAnalysis } from '../helpers';
import { getTitleByStockingPhaseType } from '../../../helpers/stocking.helpers';
import { LrvPassword } from '../../../common/components/LrvPassword/LrvPassword';
import NotPermission from '../../../common/components/NotPermission/NotPermission';
import { handleResizeEvent } from '../../../utils/dimensions';
import ResultAnalysis from '../../../common/components/ResultAnalysis/ResultAnalysis';
import { setTheme } from '../../Settings/settingsSlice';
import { analysisErrors, analysisTypes, shareAnalysisError, THEME } from '../../../config/commons';

import './Detail.scss';
import Map from './Map';
import Sample from './Sample/Sample';
import Histogram from './Histogram';
import DataDetail from './DataDetail';
import { getSampleTitle } from './helper';
import styles from './Detail.module.scss';
import AnalysisOptions from './AnalysisOptions';
import GrowOutSizesChart from './GrowOutSizesChart';
import { fetchDiseases } from './Sample/sampleSlice';
import HorizontalBarsChart from './HorizontalBarsChart';
import { fetchAllAnalysis, fetchAnalysis, fetchPinAnalysis, fetchStocking, fetchUser, resetAnalysis, resetShareAnalysis, setAnalysisPaginate, setTotalAnalysis } from './detailAnalysisSlice';
import * as detailCustomizableSlice from './detailCustomizableSlice';


type TParams = { id: string };

export const DetailAnalysis = ({ match }: RouteComponentProps<TParams>) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [formPin] = Form.useForm();

  const [pin, setPin] = useState('');
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);
  const analysisWithoutStockingNameRoute = t('analysisWithoutStocking.header').toUpperCase();

  const { analysis: analysisOriginal, isLoading, hasPermission, allAnalysis, currentIndex, share: shareAnalysis } = useSelector((state: Store) => state.detailAnalysis);
  const { error, campusId, sowingId, companyId } = analysisOriginal;
  const { breadcrumb, phaseType: phaseTypeGlobal, company } = useSelector((state: RootState) => state.header);

  const stockingId = sowingId?._id;
  const stockingIsArchived = sowingId?.isArchived;
  const stockingCompanyId = sowingId?.companyId;
  const stockingName = sowingId?.name;
  const stockingPhaseType = sowingId?.phaseType;
  const currentAnalysis = (allAnalysis && allAnalysis.length > 0) ? allAnalysis[currentIndex] : analysisOriginal;
  const analysisPhaseType = analysisOriginal.phaseType;

  const userSession = getUserSession();

  const analysisId = match.params.id;
  const accessToken = localStorage.getItem('accessToken');
  const hasError = error && error.subType && error.subType !== '';

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    const lrvTabsContainer = document.querySelector('.lrv-tabs-container .ant-tabs-content');
    const width = lrvTabsContainer?.clientWidth || 0;
    const height = lrvTabsContainer?.clientHeight || 0;
    setChartWidth(width);
    setChartHeight(height);
  }, [chartContainerRef.current]); // Initialize width and height when chartContainer was rendered

  useEffect(() => {
    handleResizeEvent(() => {
      const lrvTabsContainer = document.querySelector('.lrv-tabs-container .ant-tabs-content');
      const width = lrvTabsContainer?.clientWidth || 0;
      const height = lrvTabsContainer?.clientHeight || 0;
      setChartWidth(width);
      setChartHeight(height);
    });
  }, []);

  useEffect(() => {
    if (!userSession?._id || !stockingCompanyId || stockingCompanyId === company._id) {
      return;
    }

    dispatch(headerSlice.fetchCompany(stockingCompanyId));
  }, [dispatch, stockingCompanyId, company._id, userSession._id]);

  useEffect(() => {
    if (stockingPhaseType === phaseTypeGlobal) {
      return;
    }

    dispatch(headerSlice.setPhaseType(stockingPhaseType));
  }, [dispatch, stockingPhaseType, phaseTypeGlobal]);

  useEffect(() => {
    if (accessToken) {
      dispatch(headerSlice.showPagination({ show: true }));
    }
    return () => {
      dispatch(resetShareAnalysis());
      dispatch(resetAnalysis());
      dispatch(headerSlice.resetHeader());

      dispatch(setTotalAnalysis({ total: 0, limit: 0, skip: 0 }));
      dispatch(setAnalysisPaginate([]));
      dispatch(detailCustomizableSlice.resetDetailCustomizable());
    };
  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!userSession?._id) {
      return;
    }
    dispatch(fetchDiseases());
  }, [dispatch, userSession._id]);


  useEffect(() => {
    if (!company?._id) {
      return;
    }

    dispatch(detailCustomizableSlice.fetchDetailCustomizable({ companyId: company._id, phaseType: analysisPhaseType }));
  }, [dispatch, company._id, analysisPhaseType]);

  useEffect(() => {
    if (!company?._id || !analysisOriginal.userId) {
      return;
    }

    dispatch(fetchUser({ userId: analysisOriginal.userId }));
  }, [dispatch, company?._id, analysisOriginal.userId]);

  useEffect(() => {
    dispatch(fetchAnalysis({ analysisId }));
  }, [dispatch, analysisId]);

  useEffect(() => {
    if (accessToken) {
      const existCode = analysisOriginal.code !== undefined && analysisOriginal.code !== '';
      const dontExistError = !analysisOriginal.error?.subType;
      const showOptionPdf = existCode && dontExistError;

      dispatch(headerSlice.showIconPdf({ show: showOptionPdf }));
      dispatch(headerSlice.showIconSetting({ show: hasPermission && existCode && dontExistError, analysisId: analysisId, analysisCode: analysisOriginal.code }));
    }
  }, [dispatch, analysisId, accessToken, analysisOriginal.code, analysisOriginal.error, hasPermission]);

  useEffect(() => {
    if (!accessToken) {
      dispatch(setTheme('light'));
      localStorage.setItem('user', JSON.stringify({ ...userSession, theme: 'light' }));
      return;
    }

  }, [dispatch, accessToken]);

  useEffect(() => {
    if (!stockingId || !companyId) {
      return;
    }

    dispatch(fetchStocking({ stockingId, accessToken }));
  }, [dispatch, stockingId, companyId, accessToken]);

  useEffect(() => {
    dispatch(headerSlice.changeHeader({ title: 'detail.title' }));

    if (!stockingId && !campusId && !analysisOriginal.inputData?.maturationId) {
      const breadcrumb: Breadcrumb[] = [
        {
          id: 'analysis-without-stockings',
          name: analysisWithoutStockingNameRoute,
          url: '/analysis-without-stockings',
        },
        {
          id: analysisOriginal.code,
          name: analysisOriginal.code,
          url: `/production/analysis/${analysisId}`,
        }
      ];

      dispatch(headerSlice.changeBreadcrumb(breadcrumb));
      return;
    }

    if (!stockingId || !stockingName || !analysisOriginal.code) {
      if (shareAnalysis.status) {
        dispatch(headerSlice.changeHeader({ title: '' }));
      } else {
        dispatch(headerSlice.changeHeader({ title: shareAnalysis.title }));
      }
      return;
    }

    const breadcrum: Breadcrumb[] = [
      {
        id: 'sowing',
        name: getTitleByStockingPhaseType(stockingPhaseType),
        url: '/production/stockings',
      },
      {
        id: stockingName,
        name: `${t('stockings.stocking')} ${stockingName}`,
        url: `/production/stockings/${stockingId}`,
      },
      {
        id: analysisOriginal.code,
        name: `${t('analysis.analysis')} ${analysisOriginal.code}`,
        url: `/production/analysis/${analysisId}`,
      }
    ];

    dispatch(headerSlice.changeBreadcrumb(breadcrum));
  }, [dispatch, analysisId, campusId, stockingId, analysisOriginal.code, stockingName, stockingPhaseType, analysisWithoutStockingNameRoute, shareAnalysis?.status, shareAnalysis?.title, analysisOriginal.inputData?.maturationId]);

  useEffect(() => {
    const notError = hasError === false || hasError === undefined;
    if (stockingId && notError && accessToken) {
      dispatch(fetchAllAnalysis({ stockingId: stockingId, analysisId: analysisId, page: 0, companyId }));
    }
  }, [dispatch, analysisId, stockingId, hasError, accessToken, companyId]);

  if (!hasPermission) {
    return <NotPermission />;
  }

  if (isLoading) {
    return <div className={accessToken && isDesktop ? styles.analysisSpinner : styles.fullSpinner}>
      <DotSpinner />
    </div>;
  }

  const renderArchivedTag = () => {
    return (
      <Space className={styles.archivedTag} align='end' size='middle'>
        <LrvText text={currentAnalysis?.code} theme={theme} />
        <LrvTag color='#f50'>{t('detail.archived')}</LrvTag>
      </Space>
    );
  };

  const renderSubHeader = () => {
    if (!accessToken) {
      return null;
    }

    return (
      <Row className={styles.rowHeader} align='middle' justify='space-between'>
        {currentAnalysis?.isArchived || stockingIsArchived ? renderArchivedTag() : <Breadcrumbs breadcrumb={breadcrumb} noCapitalize theme={theme} />}

        <Row className={styles.rightRow} justify='end' align='middle'>
          <Col className={styles.col}>
            <div className={styles.containerAnalysisOptions}>
              <AnalysisOptions theme={theme} />
            </div>
          </Col>
        </Row>
      </Row>
    );
  };

  if (shareAnalysis?.status) {
    if (shareAnalysis.status === shareAnalysisError.PIN_REQUIRED || shareAnalysis.status === shareAnalysisError.INVALID_PIN) {
      return renderFormPin();
    }

    if (shareAnalysis.status === shareAnalysisError.NOT_SHARED) {
      const message: string = t('share.errors.notAvailable');
      return (
        <div className={styles.result}>
          {renderSubHeader()}
          <ResultAnalysis id='not_shared' message={message} />
        </div>
      );
    }

    if (shareAnalysis.status === shareAnalysisError.SHARE_EXPIRED) {
      const message: string = t('share.errors.notAvailable');
      return (
        <div className={styles.result}>
          {renderSubHeader()}
          <ResultAnalysis id='shared_expired' message={message} />
        </div>
      );
    }

    if (shareAnalysis.status === shareAnalysisError.NOT_AVAILABLE) {
      const message: string = t('share.errors.notAvailable');
      return (
        <div className={styles.result}>
          {renderSubHeader()}
          <ResultAnalysis id='not_available' message={message} theme={theme} />
        </div>
      );
    }
  }

  if (analysisOriginal.error && analysisOriginal.error.subType) {
    const message: string = typeErrorAnalysis(analysisOriginal.error.subType);

    return (
      <div className={styles.result}>
        {renderSubHeader()}
        <div id='invalid_sample' className={styles.showResult}>
          <LrvResult status='error' title={message} />
        </div>
      </div>
    );
  }

  function typeErrorAnalysis (typeError: string) {
    let message = '';
    switch (typeError) {
      case analysisErrors.NOT_A_LARVAE_SAMPLE:
        message = t('detail.error.notALarvaeSample');
        break;

      case analysisErrors.NO_LARVAE_FOUND:
        message = t('detail.error.noLarvaeFound');
        break;

      case analysisErrors.LESS_MINIMUM_LARVAE:
        message = t('detail.error.lessMinimumLarvae');
        break;

      case analysisErrors.BLURRED_IMAGE:
        message = t('detail.error.blurredImage');
        break;

      case analysisErrors.DARK_IMAGE:
        message = t('detail.error.darkImage');
        break;
    }

    return message;
  }

  function renderFormPin () {
    return <Content
      className={styles.marginForm}
      headerClassName={styles.headerClassName}
      title={<div className={styles.contentTitle}>{shareAnalysis.title}</div>}
      actions={<img src={larviaLogoSvg} height={40} alt='Larvia logo' />}
    >
      <LrvForm
        form={formPin}
        id='formPin'
        theme='light'
        name='formPin'
        layout='vertical'
        onFinish={() => {
          if (pin !== '') {
            const params = { analysisId: analysisId, pin: pin };
            dispatch(fetchPinAnalysis(params));
          }
        }}
      >
        <Form.Item
          name='pin'
          required
          label={t('share.pin')}
          validateStatus={shareAnalysis.status === shareAnalysisError.INVALID_PIN ? 'error' : undefined}
          help={shareAnalysis.status === shareAnalysisError.INVALID_PIN ? t('share.errors.invalidPin') : undefined}
          rules={[() => ({
            validator (rule, value) {
              return validatePIN(value, true);
            }
          })]}
        >
          <LrvPassword
            theme='light'
            autoFocus
            value={pin}
            maxLength={4}
            onChange={(e) => setPin(e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <div className={styles.alignButtonRight} >
            <Button id='submit_form_pin' type='primary' htmlType='submit' form='formPin'>
              {t('share.access').toUpperCase()}
            </Button>
          </div>
        </Form.Item>
      </LrvForm>
    </Content>;
  }

  if (analysisOriginal._id === '') {
    return <div className={isDesktop ? styles.analysisSpinner : styles.fullSpinner}>
      <DotSpinner />
    </div>;
  }

  const renderBarsCharts = () => {
    const animalPerGroup = getTitleCountAnimalChart(analysisOriginal.phaseType);

    if (analysisOriginal.type === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS && !!analysisOriginal.growOutSizes?.whole?.labels) {
      return (
        <div ref={chartContainerRef} className={styles.postlarvaePerGroup}>
          <Content className='lrv-tabs-container' title={t('detail.chart.commercialSizes')} noPadding theme={theme}>
            <LrvTabs
              className='commercialSizesTabs'
              theme={theme}
              items={
                [
                  {
                    key: '1',
                    label: t('detail.growOutSizes.whole'),
                    children: <GrowOutSizesChart
                      containerClassName={styles.containerBarsChart}
                      textClassName={styles.chartTextBarsChart}
                      textInsideBarClassName={styles.chartTextFillWhite}
                      analysisData={analysisOriginal}
                      typeChart='GROW_OUT_WHOLE'
                      width={chartWidth}
                      height={chartHeight}
                      theme={theme}
                    />

                  },
                  {
                    key: '2',
                    label: t('detail.growOutSizes.tail'),
                    children: <GrowOutSizesChart
                      containerClassName={styles.containerBarsChart}
                      textClassName={styles.chartTextBarsChart}
                      textInsideBarClassName={styles.chartTextFillWhite}
                      analysisData={analysisOriginal}
                      typeChart='GROW_OUT_TAIL'
                      width={chartWidth}
                      height={chartHeight}
                      theme={theme}
                    />
                  },
                  {
                    key: '3',
                    label: t('detail.chart.weightGroups'),
                    children: <div className={styles.weightGroups}>
                      <Content
                        headerClassName={styles.headerChart}
                        titleClassName={isLightTheme ? styles.boldTitle : ''}
                        title={animalPerGroup}
                        noPadding
                        theme={theme}
                      >
                        <HorizontalBarsChart
                          width={chartWidth}
                          height={(chartHeight / 2) - 40}
                          analysisData={analysisOriginal}
                          typeChart='SHRIPMS_PER_GROUP'
                          theme={theme}
                        />
                      </Content>
                      <Content
                        headerClassName={styles.headerChart}
                        titleClassName={isLightTheme ? styles.boldTitle : ''}
                        title={t('detail.chart.weightGroup')}
                        noPadding
                        theme={theme}
                      >
                        <HorizontalBarsChart
                          width={chartWidth}
                          height={(chartHeight / 2) - 40}
                          analysisData={analysisOriginal}
                          typeChart='CUMMULATIVE_WEIGHT_PER_GROUP'
                          theme={theme}
                        />
                      </Content>
                    </div>
                  }
                ]
              }
            >
            </LrvTabs>
          </Content>
        </div>
      );
    }

    return (
      <>
        <div className={styles.postlarvaePerGroup}>
          <Content
            titleClassName={isLightTheme ? styles.boldTitle : ''}
            title={animalPerGroup}
            noPadding
            theme={theme}
          >
            <HorizontalBarsChart
              analysisData={analysisOriginal}
              typeChart='SHRIPMS_PER_GROUP'
              theme={theme}
            />
          </Content>
        </div>
        <div className={styles.weightPerGroup}>
          <Content
            titleClassName={isLightTheme ? styles.boldTitle : ''}
            title={t('detail.chart.weightGroup')}
            noPadding
            theme={theme}
          >
            <HorizontalBarsChart
              analysisData={analysisOriginal}
              typeChart='CUMMULATIVE_WEIGHT_PER_GROUP'
              theme={theme}
            />
          </Content>
        </div>
      </>
    );
  };

  return (
    <div className={cx(styles.containerMain, 'detailAnalysis')}>
      {renderSubHeader()}
      <Row className={accessToken ? styles.bodyWithHeader : styles.bodyWithoutHeader}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} className={cx(styles.histogramColumn, 'histogramColumn')}>
          <div className={styles.histogram} >
            <Content title={t('detail.histogram')} titleClassName={styles.title} noPadding theme={theme}>
              <Histogram theme={theme} />
            </Content>
          </div>

          <div className={styles.detail} >
            <DataDetail theme={theme} />
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} className={styles.sampleColumn}>
          <div className={styles.sample}>
            <Content titleClassName={isLightTheme ? styles.boldTitle : ''} title={getSampleTitle(isConsolidatedAnalysis(analysisOriginal.type) ? currentAnalysis?.code : '')} noPadding theme={theme}>
              <Sample theme={theme} />
            </Content>
          </div>
          <div className={styles.map}>
            <Content titleClassName={isLightTheme ? styles.boldTitle : ''} title={t('detail.map')} noPadding theme={theme}>
              <Map theme={theme} />
            </Content>
          </div>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8} className={styles.barsChartColumn}>
          {renderBarsCharts()}
        </Col>
      </Row>
    </div>
  );
};
