import { stockingPhaseTypes, unitPhaseTypes } from '../config/commons';

export const getUnitPhaseTypeFromAnalysis = (analysisPhaseType: string) => {
  switch (analysisPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return unitPhaseTypes.LARVAE;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return unitPhaseTypes.PRODUCTION;
  }
};