export const getBackgroundColorCell = (count: number) => {
  switch (count) {
    case 0:
      return 'transparent';

    case 1:
      return '#a5e89d';

    case 2:
      return '#96e58c';

    case 3:
      return '#87e17c';

    case 4:
      return '#78dd6c';

    case 5:
      return '#69da5c';

    case 6:
      return '#5ec452';

    default:
      return '#54ae49';
  }
};
