import cx from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { Space, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../../utils/select';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { Maturation, MaturationCode } from '../../../Clients/interfaces';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import { LrvDivider } from '../../../../common/components/LrvDivider/LrvDivider';
import { LrvCheckbox } from '../../../../common/components/LrvCheckbox/LrvCheckbox';
import { CreateMaturationFromCatalogProps, CreateMaturationProps } from '../interfaces';
import * as onboardingSlice from '../../../../common/components/Onboarding/OnboardingSlice';

import styles from './NewMaturation.module.scss';
import * as companyMaturationsSlice from './companyMaturationsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export default function NewMaturation (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [selectedMaturations, setSelectedMaturations] = useState<Maturation[]>([]);

  const { company } = useSelector((state: Store) => state.header);
  const { activeBaseMaturations } = useSelector((state: Store) => state.baseMaturations);
  const { isCreating, companyMaturations, showNewMaturationModal } = useSelector((state: Store) => state.companyMaturations);
  const { run: isRunningOnboarding } = useSelector((state: Store) => state.onboarding);

  const companyId = company._id;

  const newMaturations = selectedMaturations.filter((maturation) => maturation.isNewMaturation);

  const closeModal = () => {
    dispatch(companyMaturationsSlice.setShowNewMaturationModal(false));
    setSelectedMaturations([]);
    form.resetFields();
  };

  const getMaturationsFromCatalog = () => {
    const catalogMaturations = selectedMaturations.filter((maturation) => !maturation.isNewMaturation);
    const maturations: CreateMaturationFromCatalogProps[] = catalogMaturations.map((maturation) => {
      return {
        name: maturation.name,
        codes: maturation.codes ? maturation.codes.map((code) => {
          return {
            ...code,
            isCodeBase: true
          };
        }) : [],
        maturationBaseId: maturation._id,
        companyId,
        companyBaseId: maturation.companyBaseId,
      };
    });
    return maturations;
  };

  const getNewMaturations = () => {
    const maturations: CreateMaturationProps[] = [];

    for (let index = 0; index < newMaturations.length; index++) {
      const newMaturation = newMaturations[index];
      const { name, public: isPublic, codes } = newMaturation;

      const item = {
        name,
        public: isPublic,
        codes: returnValidateGeneticCodes(codes),
        companyId,
        companyName: company.name,
      };

      maturations.push(item);
    }

    return maturations;
  };

  const returnValidateGeneticCodes = (maturationCodes: MaturationCode[]) => {
    if (maturationCodes.length === 0) {
      return [];
    }

    const data: MaturationCode[] = [];
    maturationCodes.forEach(maturationCode => {
      if (maturationCode.code.trim().length > 0) {
        data.push({
          _id: maturationCode._id,
          code: maturationCode.code,
          active: maturationCode.active,
        });
      }
    });

    return data;
  };

  const addMaturationCode = (props: { maturation: Maturation, index: number }) => {
    const { maturation, index } = props;
    const { codes } = maturation;

    if (!isValidMaturationCode({ maturation, index })) {
      return;
    }

    const maturationCopy: Maturation[] = cloneDeep(selectedMaturations);
    const codeCopy: MaturationCode[] = cloneDeep(codes);

    codeCopy.push({ _id: '', code: '', active: true });
    maturationCopy[index].codes = codeCopy;

    setSelectedMaturations(maturationCopy);
  };

  const renderAddCodeButton = (props: { maturation: Maturation, index: number }) => {
    const { maturation, index } = props;

    return (
      <ActionButton
        id='btn_add_maturation_code'
        className={styles.addCodeButton}
        icon={<Icon name='add' />}
        onClick={() => {
          addMaturationCode({ maturation, index });

          if (isRunningOnboarding) {
            dispatch(onboardingSlice.goToOnboardingNextStep(500));
          }
        }}
        type='primary'
      >
        {t('maturations.newMaturationCode')}
      </ActionButton>
    );
  };

  const renderPublicCheckbox = (props: { maturation: Maturation, index: number }) => {
    const { maturation, index } = props;

    return (
      <LrvCheckbox
        id='checkbox_maturation_public'
        theme={theme}
        onChange={event => {
          const maturationCopy: Maturation[] = cloneDeep(selectedMaturations);
          const value = event.target.checked;
          maturationCopy[index].public = value;

          setSelectedMaturations(maturationCopy);
        }}
        checked={maturation.public}
      >
        {t('maturations.status.public')}
      </LrvCheckbox>
    );
  };

  const isValidMaturationName = () => {
    for (let index = 0; index < newMaturations.length; index++) {
      const newMaturation = newMaturations[index];
      const { name } = newMaturation;

      if (!name) {
        return false;
      }
    }

    return true;
  };

  const isValidMaturationCodes = () => {
    for (let index = 0; index < newMaturations.length; index++) {
      const maturation = newMaturations[index];
      const { codes } = maturation;

      if (!codes) {
        continue;
      }

      for (let index = 0; index < codes.length; index++) {
        const code = codes[index];
        // eslint-disable-next-line
        if (!code.code) {
          return false;
        }
      }
    }

    return true;
  };

  const isValidMaturationCode = (props: { maturation: Maturation, index: number }) => {
    const { maturation } = props;
    const { codes } = maturation;

    if (!codes) {
      return true;
    }

    for (let index = 0; index < codes.length; index++) {
      const code = codes[index];
      if (!code.code) {
        return false;
      }
    }

    return true;
  };

  const renderCodeInputs = (props: { maturation: Maturation, index: number }) => {
    const { maturation, index } = props;
    const { codes } = maturation;

    return codes.map((maturationCode: MaturationCode, codeIndex: number) => {
      return (
        <Space className={styles.codeRow}>
          <LrvInput
            id='txt_new_maturation_code'
            theme='light'
            value={maturationCode.code}
            placeholder={t('maturations.code')}
            autoFocus
            onChange={(e) => {
              const maturationCopy: Maturation[] = cloneDeep(selectedMaturations);
              const codeCopy: MaturationCode[] = cloneDeep(codes);
              const value = e.target.value;

              codeCopy[codeIndex].code = value;
              maturationCopy[index].codes = codeCopy;

              setSelectedMaturations(maturationCopy);
            }}
          />
          <ActionButton
            icon={<Icon name='close' theme='light' />}
            type='text'
            onClick={() => {
              const maturationCopy: Maturation[] = cloneDeep(selectedMaturations);
              const codeCopy: MaturationCode[] = cloneDeep(codes);

              codeCopy.splice(codeIndex, 1);
              maturationCopy[index].codes = codeCopy;

              setSelectedMaturations(maturationCopy);
            }}
          />
        </Space>
      );
    });
  };

  const disabledForm = () => {
    return !(isValidMaturationName() && isValidMaturationCodes());
  };

  const companyMaturationIds = companyMaturations.map((maturation) => maturation.maturationBaseId);
  const activeMaturations = activeBaseMaturations.filter(maturation => !companyMaturationIds.includes(maturation._id));

  const onChangeMaturations = (values: string[]) => {
    const maturations: Maturation[] = [];
    for (let index = 0; index < values.length; index++) {
      const value = values[index];
      const activeBaseMaturation = activeBaseMaturations.find((maturation) => maturation._id === value);

      if (activeBaseMaturation) {
        const maturation: Maturation = {
          _id: activeBaseMaturation._id,
          active: activeBaseMaturation.active,
          codes: activeBaseMaturation.codes ? activeBaseMaturation.codes.map((code) => {
            return {
              ...code,
              isCodeBase: true
            };
          }) : [],
          companyId,
          name: activeBaseMaturation.name,
          public: false,
          maturationBaseId: activeBaseMaturation._id,
          companyBaseId: activeBaseMaturation.companyId,
        };

        maturations.push(maturation);
      } else {
        const newMaturation = newMaturations.find((maturation) => maturation._id === value);
        const maturation: Maturation = {
          _id: value,
          active: true,
          codes: newMaturation?.codes || [],
          companyId,
          name: value,
          public: newMaturation?.public || false,
          isNewMaturation: true,
        };

        maturations.push(maturation);
      }
    }

    setSelectedMaturations(maturations);

    if (isRunningOnboarding) {
      dispatch(onboardingSlice.goToOnboardingNextStep(500));
    }
  };

  const renderMaturations = () => {
    return (
      <LrvSelect
        id='maturations-dropdown'
        theme='light'
        showSearch
        mode='tags'
        autoFocus
        placeholder={t('maturations.placeholder')}
        onChange={onChangeMaturations}
        removeIcon={<Icon name='close' />}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
      >
        {activeMaturations.map((activeMaturation) =>
          <Option key={activeMaturation._id} value={activeMaturation._id}>{activeMaturation.name}</Option>)
        }
      </LrvSelect>
    );
  };

  const saveMaturation = () => {
    const maturationsFromCatalog = getMaturationsFromCatalog();
    const maturations = getNewMaturations();

    const params = {
      maturations,
      maturationsFromCatalog,
      companyId,
      onSuccess: closeModal,
    };
    dispatch(companyMaturationsSlice.createMaturations(params));

    if (isRunningOnboarding) {
      dispatch(onboardingSlice.goToOnboardingNextStep(500));
    }
  };

  return (
    <LrvModal
      theme='light'
      className={cx(styles.newMaturation, 'newMaturationModal')}
      title={t('maturations.add')}
      open={showNewMaturationModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_maturation', htmlType: 'submit', form: 'form', loading: isCreating, disabled: disabledForm() }}
      onOk={saveMaturation}
      okText={t('maturations.accept')}
      cancelText={t('maturations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form_new_maturations'
        layout='vertical'
      >
        <Form.Item
          name='maturation'
          label={t('maturations.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          {renderMaturations()}
        </Form.Item>

        <div className={styles.maturations}>
          {
            selectedMaturations.map((maturation, index) => {
              const { codes, isNewMaturation, name } = maturation;

              if (!isNewMaturation) {
                return null;
              }

              return (
                <>
                  <LrvDivider theme={theme} />

                  <Form.Item className={styles.maturationPublic}>
                    <LrvText text={name} theme={theme} />
                    {renderPublicCheckbox({ maturation, index })}
                  </Form.Item>

                  <Form.Item
                    name='codes'
                    label={codes.length > 0 ? t('maturations.codes') : ''}
                  >
                    <div className={styles.codes}>
                      {renderCodeInputs({ maturation, index })}
                    </div>
                    {renderAddCodeButton({ maturation, index })}
                  </Form.Item>
                </>
              );
            })
          }
        </div>

      </LrvForm>
    </LrvModal>
  );
}