import React, { MouseEventHandler } from 'react';
import cx from 'classnames';

import styles from './ProgressBar.module.scss';

const ProgressBar = (props: {
  id?: string;
  name?: string;
  percent: number;
  error?: boolean;
  classNameContainer?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  backgroundColorLabel?: string;
}) => {

  const { id, name, percent, error, classNameContainer, onClick, backgroundColorLabel } = props;

  return <div className={cx(styles.containerStyles, classNameContainer)}>
    <div
      className={error === true ? cx(styles.fillerStyles, styles.hasError) : styles.fillerStyles}
      style={{ width: `${percent > 100 ? 100 : percent}%`, backgroundColor: backgroundColorLabel }}
    >
      <span
        id={id}
        className={cx(styles.labelStyles, onClick ? styles.cursorPointer : '')}
        onClick={onClick}
      >
        {name?.toLowerCase()}
      </span>
    </div>
  </div>;
};

export default ProgressBar;
