import { formatYearMonthDay } from '../../utils/date';

import { CompanyPlan, DetailDistributorCompany, PlanPrice } from './interfaces';

export const editLocalStorage = (props: { paymentType: string; months: string; prices: string; quota: number; url: string }) => {
  const { paymentType, months, prices, quota, url } = props;

  localStorage.setItem('paymentType', paymentType);
  localStorage.setItem('months', months);
  localStorage.setItem('prices', prices);
  localStorage.setItem('quota', quota.toString());
  window.location.href = url;
};

export const clearLocalStorage = () => {
  localStorage.removeItem('paymentType');
  localStorage.removeItem('months');
  localStorage.removeItem('quota');
  localStorage.removeItem('prices');
};

export const calcIndividualPlanPrice = (companyPlan: CompanyPlan | DetailDistributorCompany): PlanPrice => {
  const subtotalPrice = companyPlan.planPriceOverride && companyPlan.planPriceOverride > 0 ? companyPlan.planPriceOverride : companyPlan.planPrice;
  if (!subtotalPrice) {
    return {
      bonusPrice: 0,
      totalPrice: 0,
      subtotalPrice: 0,
    };
  }

  const pricePerAnalysis = subtotalPrice / companyPlan.quota;
  const bonusPrice = companyPlan.bonusQuota * pricePerAnalysis;

  return {
    bonusPrice,
    subtotalPrice,
    totalPrice: subtotalPrice + bonusPrice,
  };
};

export const calcPlanPrice = (companyPlans: CompanyPlan[]): PlanPrice[] => {
  return companyPlans.map((companyPlan) => calcIndividualPlanPrice(companyPlan));
};

export const generateIntermediateDates = (range: [string, string]): string[] => {
  const [startDateStr, endDateStr] = range;
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);

  const intermediateDates: string[] = [];
  const currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const intermediateDate = formatYearMonthDay(currentDate.toISOString());
    intermediateDates.push(intermediateDate);
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return intermediateDates;
};
