import React from 'react';
import cx from 'classnames';
import { Calendar } from 'antd';

import { LrvCalendarProps } from '../../interfaces/lrvCalendar';

import styles from './LrvCalendar.module.scss';

export const LrvCalendar = (props: LrvCalendarProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkCalendar : styles.lightCalendar, className),
  };

  return (
    <Calendar {...lrvProps} >
    </Calendar>
  );
};
