import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { unitStatuses } from '../../../config/commons';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { CAMPUS_URL, MODULES_URL, TANKS_URL } from '../../../config/config.api';

import { ContainerMapState, Unit, Module, Container } from './interfaces';

const initialState: ContainerMapState = {
  isLoading: false,
  isSaving: false,
  filters: {
    unitId: '',
    units: [],
    moduleId: '',
    modules: [],
    containers: {
      located: [],
      unlocated: [],
    },
    selectedContainer: {},
  },
};

const containerMapSlice = createSlice({
  initialState,
  name: 'containerMap',
  reducers: {
    setUnits: (state: ContainerMapState, action: PayloadAction<Unit[]>) => {
      state.filters.units = action.payload;
    },
    setUnitId: (state: ContainerMapState, action: PayloadAction<string>) => {
      state.filters.unitId = action.payload;
    },

    setModules: (state: ContainerMapState, action: PayloadAction<Module[]>) => {
      state.filters.modules = action.payload;
    },
    setModuleId: (state: ContainerMapState, action: PayloadAction<string>) => {
      state.filters.moduleId = action.payload;
    },

    setLocatedContainers: (state: ContainerMapState, action: PayloadAction<Container[]>) => {
      state.filters.containers.located = action.payload;
    },

    setUnlocatedContainers: (state: ContainerMapState, action: PayloadAction<Container[]>) => {
      state.filters.containers.unlocated = action.payload;
    },

    setSelectedUnLocatedContainer: (state: ContainerMapState, action: PayloadAction<Container | undefined>) => {
      state.filters.selectedContainer.unlocated = action.payload;
    },

    resetFilters: (state: ContainerMapState) => {
      state.filters.unitId = '';
      state.filters.containers.located = [];
      state.filters.containers.unlocated = [];
      state.filters.moduleId = '';
      state.filters.modules = [];
      state.filters.selectedContainer.unlocated = undefined;
    },

    setIsLoading: (state: ContainerMapState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setIsSaving: (state: ContainerMapState, action: PayloadAction<boolean>) => {
      state.isSaving = action.payload;
    },

  },
});

export const {
  setUnits,
  setUnitId,
  setModules,
  setModuleId,
  resetFilters,
  setLocatedContainers,
  setUnlocatedContainers,
  setIsLoading,
  setSelectedUnLocatedContainer,
  setIsSaving,
} = containerMapSlice.actions;

export const fetchUnits = (props: { companyId: string; unitPhaseType: string; }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = props;
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    status: [unitStatuses.ACTIVE],
    '$sort[name]': 1,
    $select: ['name', 'phaseType'],
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params });
    dispatch(setUnits(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (props: { unitId: string; phaseType: string; }) => async (dispatch: Function) => {
  const { unitId, phaseType } = props;

  const params = {
    $limit: -1,
    campusId: unitId,
    phaseType: phaseType,
    active: true,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchContainers = (props: { unitId: string; moduleId: string; }) => async (dispatch: Function) => {
  const { unitId, moduleId } = props;

  const params = {
    $limit: -1,
    campusId: unitId,
    moduleId,
    active: true,
    '$sort[name]': 1,
    $select: ['name', 'latitude', 'longitude', 'type', 'volume'],
  };

  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params });

    const locatedContainers = response.data.filter((container) => !!container.latitude && !!container.longitude);
    const unlocatedContainers = response.data.filter((container) => !container.latitude && !container.longitude);

    dispatch(setLocatedContainers(locatedContainers));
    dispatch(setUnlocatedContainers(unlocatedContainers));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const updateLocationContainers = (props: { unitId: string; moduleId: string; containers: Container[] }) => async (dispatch: Function) => {
  const { containers, unitId, moduleId } = props;

  const data = {
    unitId,
    moduleId,
    containers,
  };

  dispatch(setIsSaving(true));
  const url = `${TANKS_URL}/update-location/batch`;

  try {
    await axios.patch(url, data);
    dispatch(fetchContainers({ unitId, moduleId }));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsSaving(false));
};

const containerMapReducer = containerMapSlice.reducer;
export default containerMapReducer;
