/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';

import { THEME } from '../../../config/commons';

import styles from './LineaD3.module.scss';
import { typeStyles } from './histogram.helpers';


interface LineaD3Props {
  container: HTMLDivElement | null;
  histogram: string;
  limits: number[];
  average: number;
  animalsAboveAverage: number;
  animalsBelowAverage: number;
  percentageAnimalsAboveAverage: number;
  percentageAnimalsBelowAverage: number;
  width: number;
  height: number;
  typeStyles: string;
  theme: 'dark' | 'light';
}

export default class LineaD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));

  xScaleLinear: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  width = 0;
  height = 0;
  margin = { top: 20, right: 12, bottom: 0, left: 12 };

  average = 0;
  animalsAboveAverage = 0;
  animalsBelowAverage = 0;
  percentageAnimalsAboveAverage = 0;
  percentageAnimalsBelowAverage = 0;

  histogram: string;
  limits: number[];

  //styles
  typeStyles = '';
  lineasStyles = '';
  animalsStyles = '';
  theme?: 'dark' | 'light' = 'dark';

  // eslint-disable-next-line
  constructor(props: LineaD3Props) {
    const {
      container,
      histogram,
      limits,
      average,
      animalsAboveAverage,
      animalsBelowAverage,
      percentageAnimalsAboveAverage,
      percentageAnimalsBelowAverage,
      width,
      height,
      typeStyles,
      theme,
    } = props;

    this.container = container;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.histogram = histogram;
    this.limits = limits;

    this.average = average;
    this.animalsAboveAverage = animalsAboveAverage;
    this.animalsBelowAverage = animalsBelowAverage;
    this.percentageAnimalsAboveAverage = percentageAnimalsAboveAverage;
    this.percentageAnimalsBelowAverage = percentageAnimalsBelowAverage;

    this.typeStyles = typeStyles;
    this.theme = theme;

    this.updateStyles();
    this.createSvgElement(this.width, this.height);
    this.calcScaleLinear();
    this.renderAnimalsBelowAverage();
    this.renderAnimalsAboveAverage();
  }

  updateStyles () {
    const isLightTheme = this.theme === THEME.LIGHT;

    if (this.typeStyles === typeStyles.DEFAULT) {
      this.lineasStyles = isLightTheme ? styles.lineasStylesLight : styles.lineasStylesDark;
      this.animalsStyles = isLightTheme ? styles.animalsStylesLight : styles.animalsStylesDark;
    } else if (this.typeStyles === typeStyles.PDF) {
      this.lineasStyles = styles.lineasPdf;
      this.animalsStyles = styles.animalsPdf;
    }
  }

  createSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('id', 'content')
      .append('g')
      .attr('fill', 'pink')
      .attr('transform', `translate( ${margin.left}, ${margin.top} )`);
  }

  renderAnimalsBelowAverage () {
    const { svg, average, animalsBelowAverage, percentageAnimalsBelowAverage, lineasStyles, animalsStyles } = this;

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('stroke', 'cyan')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('x1', 0)
      .attr('x2', this.xScaleLinear(average) - 2)
      .attr('y1', 16)
      .attr('y2', 16);

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('stroke', 'cyan')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 16)
      .attr('y2', 22);

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('stroke', 'cyan')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('x1', this.xScaleLinear(average) - 2)
      .attr('x2', this.xScaleLinear(average) - 2)
      .attr('y1', 16)
      .attr('y2', 22);

    svg.append('text')
      .attr('class', animalsStyles)
      .attr('x', (this.xScaleLinear(average) - 2) / 2)
      .attr('y', 0)
      .style('text-anchor', 'middle')
      .text(`${animalsBelowAverage} individuos`);

    svg.append('text')
      .attr('class', animalsStyles)
      .attr('x', (this.xScaleLinear(average) - 2) / 2)
      .attr('y', 12)
      .style('text-anchor', 'middle')
      .text(`${percentageAnimalsBelowAverage} %`);
  }

  renderAnimalsAboveAverage () {
    const { svg, average, width, animalsAboveAverage, percentageAnimalsAboveAverage, lineasStyles, animalsStyles } = this;

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('x1', this.xScaleLinear(average) + 2)
      .attr('x2', width)
      .attr('y1', 16)
      .attr('y2', 16);

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('x1', this.xScaleLinear(average) + 2)
      .attr('x2', this.xScaleLinear(average) + 2)
      .attr('y1', 16)
      .attr('y2', 22);

    svg.append('line')
      .attr('class', lineasStyles)
      .attr('x1', width)
      .attr('x2', width)
      .attr('y1', 16)
      .attr('y2', 22);

    svg.append('text')
      .attr('class', animalsStyles)
      .attr('x', (this.xScaleLinear(average) + width) / 2)
      .attr('y', 0)
      .style('text-anchor', 'middle')
      .text(`${animalsAboveAverage} individuos`);

    svg.append('text')
      .attr('class', animalsStyles)
      .attr('x', (this.xScaleLinear(average) + width) / 2)
      .attr('y', 12)
      .style('text-anchor', 'middle')
      .text(`${percentageAnimalsAboveAverage} %`);
  }

  calcScaleLinear () {
    const { limits, width } = this;

    this.xScaleLinear = d3.scaleLinear()
      .domain([limits[0], limits[limits.length - 1]])
      .range([0, width]);
  }

  refresh (props: { histogram: string; limits: number[]; average: number; theme: 'dark' | 'light'; animalsAboveAverage: number; animalsBelowAverage: number; percentageAnimalsAboveAverage: number; percentageAnimalsBelowAverage: number; }) {
    const { histogram, limits, average, theme, animalsAboveAverage, animalsBelowAverage, percentageAnimalsAboveAverage, percentageAnimalsBelowAverage } = props;
    const { container, width, height } = this;

    this.histogram = histogram;
    this.limits = limits;

    this.average = average;
    this.animalsAboveAverage = animalsAboveAverage;
    this.animalsBelowAverage = animalsBelowAverage;
    this.percentageAnimalsAboveAverage = percentageAnimalsAboveAverage;
    this.percentageAnimalsBelowAverage = percentageAnimalsBelowAverage;

    this.theme = theme;

    d3.select(container).select('#content').selectAll('*').remove();

    this.createSvgElement(width, height);
    this.calcScaleLinear();
    this.renderAnimalsBelowAverage();
    this.renderAnimalsAboveAverage();
  }

  resize (width: number, height: number) {
    const { container } = this;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#content').selectAll('*').remove();

    this.createSvgElement(this.width, this.height);
    this.calcScaleLinear();
    this.renderAnimalsBelowAverage();
    this.renderAnimalsAboveAverage();
  }
}

