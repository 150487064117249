import moment from 'moment';
import { cloneDeep } from 'lodash';
import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { validateString } from '../../utils/validations';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';

import * as harvestsSlice from './harvestsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const ConsolidateHarvests = (props: Props) => {
  const {
    theme = 'light',
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [harvestName, setHarvestName] = useState('');
  const [endDate, setEndDate] = useState(moment().toISOString());

  const { consolidateHarvests, currentPage } = useSelector((state: Store) => state.harvests);
  const { company } = useSelector((state: Store) => state.header);
  const { showModal, selectedHarvests, isLoading } = consolidateHarvests;

  useEffect(() => {
    if (!showModal) {
      return;
    }

    form.setFieldsValue({
      endDate: moment(endDate),
    });
  }, [form, showModal]);

  const onCloseModal = () => {
    setHarvestName('');
    setEndDate(moment().toISOString());

    dispatch(harvestsSlice.setShowConsolidateHarvestsModal(false));
    dispatch(harvestsSlice.setIsLoadingConsolidateHarvests(false));
    dispatch(harvestsSlice.setConsolidateHarvest(false));
    dispatch(harvestsSlice.setSelectedHarvests([]));
    dispatch(harvestsSlice.setSelectedRowKeys([]));
    form.resetFields();
  };

  const renderHarvestName = () => {
    return (
      <Col span={24}>
        <Form.Item
          name='name'
          label={t('harvests.consolidateModal.name')}
          required
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvInput
            theme={theme}
            value={harvestName}
            onChange={(e) => setHarvestName(e.target.value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderHarvestDate = () => {
    return (
      <Col span={24}>
        <Form.Item
          name='endDate'
          label={t('harvests.harvestDate')}
          rules={[{ required: true, message: t('stockings.form.startDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            value={moment(endDate)}
            onChange={(date) => setEndDate(date?.toISOString() || '')}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderHarvests = () => {
    return selectedHarvests.map((harvest, index) => {
      return (
        <>
          <Col span={12}>
            <Form.Item
              label={t('harvests.harvest')}
            >
              <LrvInput
                theme={theme}
                value={harvest.name}
                readOnly
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('harvests.animalsHarvested')}
            >
              <LrvInputNumber
                theme={theme}
                value={harvest.harvestQuantityEdited}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                min={1}
                max={harvest.harvestQuantity}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }

                  const harvests = cloneDeep(selectedHarvests);
                  harvests[index].harvestQuantityEdited = parseInt(value.toString());
                  dispatch(harvestsSlice.setSelectedHarvests(harvests));
                }}
              />
            </Form.Item>
          </Col>
        </>
      );
    });
  };

  const getDisabledSubmitButton = () => {
    return !harvestName || !endDate || !selectedHarvests.length;
  };

  return (
    <LrvModal
      theme={theme}
      title={t('harvests.consolidateModal.title')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading, disabled: getDisabledSubmitButton() }}
      onOk={() => {
        const harvestedAnimals = selectedHarvests.map((harvest) => harvest.harvestQuantityEdited);
        const stockingIds = selectedHarvests.map((harvest) => harvest._id);

        dispatch(harvestsSlice.createConsolidatedHarvest({
          body: { endDate, harvestedAnimals, name: harvestName, stockingIds },
          onCloseModal,
          companyId: company._id,
          currentPage,
        }));
      }}
      okText={t('harvests.accept')}
      onCancel={onCloseModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form'
        layout='vertical'
      >
        <Row gutter={16}>
          {renderHarvestName()}
          {renderHarvestDate()}
          {renderHarvests()}
        </Row>
      </LrvForm>
    </LrvModal>
  );
};
