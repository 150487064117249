import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header';
import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { getDataParameter } from '../../Reports/ParameterState/helpers';
import { ParameterTypes } from '../../Reports/ParameterState/interfaces';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import * as parameterStateSlice from '../../Reports/ParameterState/parameterStateSlice';

import { Legends } from './Legends';
import styles from './ParameterStatePdf.module.scss';
import { StockingParameterGraph } from './ParameterGraph';

type TParams = {
  companyId: string;
  unitId: string;
  moduleId: string;
  phaseType: string;
  parameter: ParameterTypes;
  dateOption: string;
  language: string;
  accessToken: string;
  referenceId?: string;
};

export const ParameterStatePdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId,
    unitId,
    moduleId,
    accessToken,
    language,
    parameter,
    dateOption,
    phaseType,
    referenceId,
  } = match.params;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const {
    stockingParameterGraphs,
    selectedUnit,
    isLoading,
    selectedModule,
  } = useSelector((state: Store) => state.parameterState);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
    dispatch(parameterStateSlice.fetchUnit({ unitId, accessToken }));
    dispatch(parameterStateSlice.fetchModule({ moduleId, accessToken }));
    dispatch(parameterStateSlice.fetchStockingParameterGraphs({
      campusId: unitId,
      companyId,
      moduleId,
      phaseType,
      accessToken,
    }));

    if (referenceId && parameter === 'averageWeight') {
      dispatch(parameterStateSlice.fetchReferenceCurve({ referenceId, accessToken }));
    }
  }, [dispatch, companyId, unitId, moduleId, accessToken, phaseType, referenceId, parameter]);

  if (isLoading || !selectedUnit?._id || !selectedModule?._id) {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  }

  const renderTitle = () => {
    return `${company.name} - ${selectedUnit.name}`;
  };

  return (
    <div
      id='parameterState'
      className={styles.parameterState}
    >
      <Header
        title={renderTitle()}
        subtitle={selectedModule.name}
      />

      <div className={styles.item}>
        <span>{t('parameterStateGraph.title')}</span>
      </div>

      <Legends
        selectedParameterName={parameter}
        stockingParameterGraphs={stockingParameterGraphs}
      />

      <div className={styles.graphs}>
        {stockingParameterGraphs.map((item) => (
          <StockingParameterGraph
            key={item.containerId}
            data={getDataParameter({ item, selectedParameterName: parameter })}
            stockingName={item.stockingName}
            parameter={parameter}
            dateOption={dateOption}
            phaseType={phaseType}
          />
        ))}
      </div>
    </div>
  );
};
