import React from 'react';
import cx from 'classnames';
import { Radio } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { isConsolidatedAnalysis } from '../helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { THEME } from '../../../config/commons';

import styles from './PreviousNextButtons.module.scss';
import { setCurrentIndex } from './detailAnalysisSlice';

interface Props {
  theme?: 'light' | 'dark';
}

export default function PreviousNextButtons ({ theme = getCurrentTheme() }: Props) {
  const dispatch = useDispatch();
  const isLightTheme = theme === THEME.LIGHT;

  const { allAnalysis, analysis: analysisOriginal, currentIndex } = useSelector((state: Store) => state.detailAnalysis);
  const analysisType = analysisOriginal.type;

  if (!isConsolidatedAnalysis(analysisType)) {
    return null;
  }

  const disabledPreviousButton = () => {
    return currentIndex === 0;
  };

  const disabledNextButton = () => {
    return allAnalysis?.length !== undefined && currentIndex === (allAnalysis.length - 1);
  };

  return (
    <Radio.Group className={cx(styles.previousNextButtons, isLightTheme ? styles.lightTheme : styles.darkTheme)} size='small'>
      <Radio.Button
        className={cx(styles.leftButton, disabledPreviousButton() ? styles.disabledButton : '')}
        onClick={() => dispatch(setCurrentIndex(currentIndex - 1))}
        disabled={disabledPreviousButton()}
      >
        <Icon name='arrow-left-s' className={cx(styles.icon, disabledPreviousButton() ? styles.disabledIcon : '')} />
      </Radio.Button>

      <Radio.Button
        className={cx(styles.rightButton, disabledNextButton() ? styles.disabledButton : '')}
        onClick={() => dispatch(setCurrentIndex(currentIndex + 1))}
        disabled={disabledNextButton()}
      >
        <Icon name='arrow-right-s' className={cx(styles.icon, disabledNextButton() ? styles.disabledIcon : '')} />
      </Radio.Button>
    </Radio.Group>
  );
}
