import i18next from 'i18next';

import { typeExtension } from '../config/commons';
import { openErrorNotification } from '../common/notification/Notification';

import { ApiClient } from './axios_instance';

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const downloadData = (data: BlobPart, nameFile: string, format: string) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', `${nameFile}.${format}`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadFile = async (url: string, nameFile: string, format: string) => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    downloadData(response.data, nameFile, format);
  } catch (error) {
    openErrorNotification(i18next.t('download.error'));
    console.log(error?.response);
  }
};

interface XslsData {
  extension: string;
  mimeType: string;
  data: string;
}

export const downloadXlsxFile = (props: { xlsxData: XslsData; fileName: string }) => {
  const { fileName, xlsxData } = props;

  try {
    const link = document.createElement('a');
    if (xlsxData.extension === `.${typeExtension.XLSX}`) {
      link.href = `data:${xlsxData.mimeType};base64,${xlsxData.data}`;
    }

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (e) {
    console.log(e);
    openErrorNotification(i18next.t('download.error'));
  }
};