import moment from 'moment';

import { formatLongDateWithZone, getHoursOffset } from '../utils/date';
import { roundTwoDecimals, stockingPhaseTypes, transferTypes, stockingStatuses } from '../config/commons';
import PopulationTypes, { PopulationData, Stocking, StockingPopulation } from '../pages/Sowings/interfaces';

import { getVolume, getAnimals, getStartDateStocking } from './stocking.helpers';

export const calcNewDensity = (props: { animals: number; stocking: Stocking }) => {
  const { animals, stocking } = props;
  return Math.round(animals / getVolume(stocking));
};

export const calcNewNumberAnimalsByDensity = (props: { density: number; stocking: Stocking }) => {
  const { density, stocking } = props;
  return getVolume(stocking) * density;
};

export const calcNewNumberAnimalsBySurvivalRate = (props: { population: PopulationData; stocking: Stocking; populations: PopulationData[]; index: number; }) => {
  const { population, stocking, populations, index } = props;
  let totalAnimalsTransferred = 0;
  for (let i = index; i >= 0; i--) {
    const element = populations[i];
    totalAnimalsTransferred += (element?.animalsTransferred || 0) + (element?.harvestQuantity || 0);
  }

  const survivalRate = population.survivalRate;
  const maxAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });

  return Math.round(((survivalRate / 100) * maxAnimals) - totalAnimalsTransferred);

};

export const calcNewSurvivalRate = (props: { population: StockingPopulation; stocking: Stocking; populations: PopulationData[], index: number }) => {
  const { population, stocking, populations, index } = props;
  let totalAnimals = 0;
  for (let i = index; i >= 0; i--) {
    const element = populations[i];
    if (element.type === PopulationTypes.HARVEST) {
      totalAnimals += element?.harvestQuantity || 0;
    }

    if (element.type === PopulationTypes.TRANSFER) {
      totalAnimals += element?.animalsTransferred || 0;
    }
  }

  const animalsNumber = population.animalsNumber;
  const animalsSown = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });
  const survivalRate = roundTwoDecimals(((animalsNumber + totalAnimals) / animalsSown) * 100);

  if (survivalRate > 100) {
    return 100;
  }

  return survivalRate;
};

const buildPopulationDataForTransfers = (params: { index: number, stocking: Stocking, populations: PopulationData[] }) => {
  const { populations, stocking, index } = params;
  const currentPopulation = populations[index];
  const stockingAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });
  const animalsTransferred = currentPopulation?.animalsTransferred || 0;
  const volume = getVolume(stocking);

  let survivalRate = 100;
  let animalsNumber = stockingAnimals - animalsTransferred;
  let density = animalsNumber / volume;

  const prevIndex = index - 1;

  if (prevIndex >= 0) {
    const prevPopulation = populations[prevIndex];
    animalsNumber = prevPopulation.animalsNumber - animalsTransferred;
    density = animalsNumber / volume;
    survivalRate = prevPopulation.survivalRate;
  }

  const population: PopulationData = {
    ...currentPopulation,
    animalsNumber,
    density: Math.round(density),
    survivalRate,
  };

  return population;
};

const buildPopulationDataForPartialHarvests = (params: { index: number, stocking: Stocking, populations: PopulationData[] }) => {
  const { populations, stocking, index } = params;
  const currentPopulation = populations[index];
  const stockingAnimals = getAnimals({ stocking, phaseTypeSelected: stocking.phaseType });
  const harvestQuantity = currentPopulation?.harvestQuantity || 0;
  const volume = getVolume(stocking);

  let survivalRate = 100;
  let animalsNumber = stockingAnimals - harvestQuantity;
  let density = animalsNumber / volume;

  const prevIndex = index - 1;

  if (prevIndex >= 0) {
    const prevPopulation = populations[prevIndex];
    animalsNumber = prevPopulation.animalsNumber - harvestQuantity;
    density = animalsNumber / volume;
    survivalRate = prevPopulation.survivalRate;
  }

  const population: PopulationData = {
    ...currentPopulation,
    animalsNumber,
    density: Math.round(density),
    survivalRate,
  };

  return population;
};

const buildHarvestPopulationInfo = (params: { stocking: Stocking }) => {
  const { stocking } = params;
  const { harvestQuantity, endDate } = stocking;

  const populationDate = moment(endDate).toISOString();
  const productionDays = calcDaysInProduction(stocking, moment(populationDate).startOf('day'));
  const survivalRate = roundTwoDecimals(stocking?.survivalRate || 0);

  const density = calcNewDensity({ animals: harvestQuantity, stocking });

  const harvestItem: PopulationData = { animalsNumber: 0, type: PopulationTypes.FULL_TRANSFER, harvestQuantity, productionDays, populationDate, density, survivalRate };
  return harvestItem;
};

const getHarvestsItems = (stocking: Stocking, stockingStartDate: moment.Moment) => {
  const { harvests } = stocking;

  if (!harvests || harvests?.length === 0) {
    return [];
  }

  const harvestsItems: PopulationData[] = harvests.map(item => {
    const harvestDate = moment(formatLongDateWithZone(item.harvestDate)).startOf('day');
    const productionDays = Math.abs(harvestDate.diff(stockingStartDate, 'day'));

    return {
      type: PopulationTypes.HARVEST,
      animalsNumber: 0,
      productionDays,
      harvestQuantity: item.harvestQuantity,
      density: 0,
      populationDate: harvestDate.toISOString(),
      survivalRate: 0,
    };
  });

  return harvestsItems;
};

const getTransfersItems = (stocking: Stocking, stockingStartDate: moment.Moment) => {
  const { transfers } = stocking;

  if (!transfers || transfers?.length === 0) {
    return [];
  }

  const transfersItems: PopulationData[] = transfers.map(item => {
    const transferDate = moment(formatLongDateWithZone(item.transferDate)).startOf('day');
    const productionDays = Math.abs(transferDate.diff(stockingStartDate, 'day'));

    return {
      type: PopulationTypes.TRANSFER,
      animalsNumber: 0,
      productionDays,
      stockingId: item.stockingId,
      animalsTransferred: item.animalsTransferred,
      density: 0,
      populationDate: transferDate.toISOString(),
      survivalRate: 0,
    };
  });

  return transfersItems;
};

const getPopulationItems = (stocking: Stocking, stockingStartDate: moment.Moment) => {
  const { populations } = stocking;

  if (!populations || populations?.length === 0) {
    return [];
  }

  const populationItems: PopulationData[] = populations.map(item => {
    const productionDays = Math.abs(moment(item.populationDate).startOf('day').diff(stockingStartDate, 'day'));

    return {
      ...item,
      density: 0,
      survivalRate: 0,
      type: PopulationTypes.MANUAL_POPULATION,
      productionDays,
    };
  });

  return populationItems;
};

// Custom compare function for populationData[]
const comparePopulationData = (a: PopulationData, b: PopulationData): number => {
  const dateA = moment(a.populationDate).startOf('day');
  const dateB = moment(b.populationDate).startOf('day');

  if (dateA.isSame(dateB)) {
    if (a.type === PopulationTypes.MANUAL_POPULATION && b.type !== PopulationTypes.MANUAL_POPULATION) {
      return -1;
    }

    return 1;
  }

  if (dateA.isBefore(dateB)) {
    return -1;
  }
  return 1;
};

export const sortAndBuildPopulationData = (stocking: Stocking) => {
  const startDate = getStartDateStocking({ stocking, phaseType: stocking.phaseType });
  const startDateZone = formatLongDateWithZone(startDate);
  const stockingStartDate = moment(startDateZone).startOf('day');

  const transfersData = getTransfersItems(stocking, stockingStartDate);
  const populationsData = getPopulationItems(stocking, stockingStartDate);
  const harvestsData = getHarvestsItems(stocking, stockingStartDate);

  const joinedPopulationData = [...populationsData, ...transfersData, ...harvestsData];
  const sortedData = joinedPopulationData.sort(comparePopulationData);
  const auxSortedData = sortedData.slice();

  const newPopulationData = sortedData.map((item, index) => {
    let population = item;

    if (item.type === PopulationTypes.MANUAL_POPULATION) {
      population.density = calcNewDensity({ animals: population.animalsNumber, stocking });
      population.survivalRate = calcNewSurvivalRate({ population, stocking, populations: auxSortedData, index });
      auxSortedData[index] = population;
    }

    if (item.type === PopulationTypes.TRANSFER) {
      population = buildPopulationDataForTransfers({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    if (item.type === PopulationTypes.HARVEST) {
      population = buildPopulationDataForPartialHarvests({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    return population;
  });

  if (stocking.status === transferTypes.FULL_TRANSFER || stocking.status === stockingStatuses.HARVESTED) {
    const harvestPopulationInfo = buildHarvestPopulationInfo({ stocking });
    newPopulationData.push(harvestPopulationInfo);
  }

  return newPopulationData;
};

export const updatePopulationItems = (populations: PopulationData[], stocking: Stocking) => {
  const auxSortedData = populations.slice();
  const newPopulationData = populations.map((item, index) => {
    let population = item;

    if (item.type === PopulationTypes.HARVEST) {
      population = buildPopulationDataForPartialHarvests({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    if (item.type === PopulationTypes.TRANSFER) {
      population = buildPopulationDataForTransfers({ index, stocking, populations: auxSortedData });
      auxSortedData[index] = population;
    }

    return population;
  });
  return newPopulationData;
};

export const getLastPopulation = (populations: PopulationData[]) => {
  if (populations.length === 0) {
    return undefined;
  }

  return populations[populations.length - 1];
};

export const getPopulationTypeIcon = (populationType: PopulationTypes) => {
  switch (populationType) {
    case PopulationTypes.TRANSFER:
      return 'logout-circle-r';

    case PopulationTypes.HARVEST:
      return 'pie-chart-2';

    case PopulationTypes.MANUAL_POPULATION:
    default:
      return 'edit';
  }
};

export const getPopulationAnimalsNumber = (population: PopulationData) => {
  switch (population.type) {
    case PopulationTypes.HARVEST:
      return population.harvestQuantity;

    case PopulationTypes.FULL_HARVEST:
    case PopulationTypes.FULL_TRANSFER:
      return population.harvestQuantity;

    case PopulationTypes.TRANSFER:
      return population.animalsTransferred;

    default:
      return undefined;
  }
};

export const calcDaysInProduction = (stocking: Stocking, newDate?: moment.Moment | null) => {
  const date = newDate || moment();
  const startDate = getStartDateStocking({ stocking, phaseType: stocking.phaseType });
  const startDateZone = formatLongDateWithZone(startDate);

  const stockingStartDate = moment(startDateZone).startOf('date');
  const diffDays = date.startOf('day').diff(stockingStartDate, 'day');
  return Math.abs(diffDays);
};

export const getLastWeight = (lastWeight: number, phaseType: string) => {
  if (phaseType !== stockingPhaseTypes.LARVAE) {
    return roundTwoDecimals(lastWeight / 1000); // Divide for 1000 to convert to g
  }

  return lastWeight;
};

export const calcBiomass = (weight: number, animals: number) => {
  const biomass = (weight * animals) / 1000; // divide for 1000 to convert to kg
  return roundTwoDecimals(biomass);
};

export const disableDate = (params: { current: moment.Moment; index: number; populationData: PopulationData[]; selectedStocking: Stocking }) => {
  const { selectedStocking, index, populationData } = params;
  const hoursOffset = getHoursOffset();

  const isThereNextIndex = (index + 1) < populationData.length;
  const nextPopulation = isThereNextIndex ? populationData[index + 1] : undefined;
  const currentLocal = moment(params.current).startOf('day');
  const current = moment(params.current).add(hoursOffset, 'hours').startOf('day');
  
  /** When prevDate is the stockingStartDate */
  if (index === 0) {
    const stockingDate = getStartDateStocking({ stocking: selectedStocking, phaseType: selectedStocking.phaseType });
    const start = moment(stockingDate).add(hoursOffset, 'hours').startOf('day');

    if (nextPopulation && nextPopulation.type !== PopulationTypes.MANUAL_POPULATION) {
      const end = moment(nextPopulation.populationDate).add(hoursOffset, 'hours').startOf('day');
      return !(current.isAfter(start) && current.isSameOrBefore(end));
    }

    if (nextPopulation && nextPopulation.type === PopulationTypes.MANUAL_POPULATION) {
      const end = moment(nextPopulation.populationDate).startOf('day');
      return !(current.isAfter(start) && currentLocal.isBefore(end));
    }

    const end = moment().startOf('day');
    return !(current.isAfter(start) && currentLocal.isSameOrBefore(end));
  }

  const prevPopulation = populationData[index - 1];
  const prevDate = populationData[index - 1].populationDate;
  const start = moment(prevDate).startOf('day');

  /** when prevPopulation is MANUAL_POPULATION */
  if (prevPopulation.type === PopulationTypes.MANUAL_POPULATION) {
    if (nextPopulation && nextPopulation.type !== PopulationTypes.MANUAL_POPULATION) {
      const end = moment(nextPopulation.populationDate).add(hoursOffset, 'hours').startOf('day');
      return !(currentLocal.isAfter(start) && current.isSameOrBefore(end));
    }

    if (nextPopulation && nextPopulation.type === PopulationTypes.MANUAL_POPULATION) {
      const end = moment(nextPopulation.populationDate).startOf('day');
      return !(currentLocal.isAfter(start) && currentLocal.isBefore(end));
    }
  }

  /** when prevPopulation is not MANUAL_POPULATION */
  if (nextPopulation && nextPopulation.type !== PopulationTypes.MANUAL_POPULATION) {
    const end = moment(nextPopulation.populationDate).add(hoursOffset, 'hours').startOf('day');
    return !(currentLocal.isAfter(start) && current.isSameOrBefore(end));
  }

  if (nextPopulation && nextPopulation.type === PopulationTypes.MANUAL_POPULATION) {
    const end = moment(nextPopulation.populationDate).startOf('day');
    return !(currentLocal.isAfter(start) && currentLocal.isBefore(end));
  }

  const end = moment().startOf('day');
  return !(currentLocal.isAfter(start) && currentLocal.isSameOrBefore(end));
};

export const findAnimalsPopulationError = (params: { index: number; selectedStocking: Stocking; populationData: PopulationData[] }) => {
  const { index, populationData, selectedStocking } = params;
  const populationItem = populationData[index];
  const animalsPopulation = populationItem.animalsNumber;

  if (populationItem.animalsNumber < 0) {
    return true;
  }

  const isThereNextIndex = (index + 1) < populationData.length;
  const isTherePrevIndex = (index - 1) >= 0;
  const stockingAnimals = getAnimals({ stocking: selectedStocking, phaseTypeSelected: selectedStocking.phaseType });
  const prevAnimalsPopulation = isTherePrevIndex ? populationData[index - 1].animalsNumber : stockingAnimals;
  const nextAnimalsPopulation = isThereNextIndex ? populationData[index + 1].animalsNumber : undefined;

  if (nextAnimalsPopulation) {
    return !(animalsPopulation <= prevAnimalsPopulation && animalsPopulation >= nextAnimalsPopulation);
  }

  return !(animalsPopulation <= prevAnimalsPopulation);
};

export const checkIfDisableAddButton = (params: { selectedStocking?: Stocking, populations: PopulationData[] }) => {
  const { populations, selectedStocking } = params;
  const stockingStatusToHideButton = [stockingStatuses.HARVESTED, transferTypes.FULL_TRANSFER, stockingStatuses.DISCARDED];

  if (stockingStatusToHideButton.some(item => item === selectedStocking?.status)) {
    return true;
  }

  if (populations.length === 0) {
    return false;
  }

  const lastPopulation = populations[populations.length - 1];

  const { populationDate } = lastPopulation;
  const currentDate = moment().startOf('day');
  return moment(populationDate).startOf('day').isSameOrAfter(currentDate);
};
