import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getHoursOffset } from '../../../utils/date';
import { ApiClient } from '../../../utils/axios_instance';
import { GROWTH_DELTA_URL, CAMPUS_URL, STOCKINGS_URL, MODULES_URL } from '../../../config/config.api';

import { GrowthDeltaPdfState, Campus, Stocking, Module } from './interfaces';

export const lastDaysGrowthDelta = 30;
export const lastDaysXlsxReport = 60;

const initialState: GrowthDeltaPdfState = {
  data: [],
  selectedCampus: {
    _id: '',
    code: '',
    name: '',
    phaseType: '',
  },
  selectedModule: {
    _id: '',
    code: '',
    name: '',
    phaseType: '',
  },
  selectedStocking: {
    _id: '',
    code: '',
    name: '',
    phaseType: '',
    startDate: '',
    startDateGrowOut: '',
    startDateJuvenile: '',
    harvestQuantity: 0,
    litersNumber: 0,
    cubicMeters: 0,
    growOutNumber: 0,
    hectares: 0,
    juvenilesNumber: 0,
    naupliusNumber: 0,
    maturationId: {
      _id: '',
      name: '',
    }
  },
  isLoadingCampus: false,
  isLoadingStocking: false,
};

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const growthDeltaSlice = createSlice({
  name: 'growthDelta',
  initialState,
  reducers: {
    setGrowthDeltaData: (state: GrowthDeltaPdfState, action: PayloadAction<[]>) => {
      state.data = action.payload;
    },

    setSelectedCampus: (state: GrowthDeltaPdfState, action: PayloadAction<Campus>) => {
      state.selectedCampus._id = action.payload._id;
      state.selectedCampus.name = action.payload.name;
      state.selectedCampus.code = action.payload.code;
      state.selectedCampus.phaseType = action.payload.phaseType;
    },

    setSelectedModule: (state: GrowthDeltaPdfState, action: PayloadAction<Module>) => {
      state.selectedModule = action.payload;
    },

    setIsLoadingCampus: (state: GrowthDeltaPdfState, action: PayloadAction<boolean>) => {
      state.isLoadingCampus = action.payload;
    },

    setSelectedStocking: (state: GrowthDeltaPdfState, action: PayloadAction<Stocking>) => {
      state.selectedStocking._id = action.payload._id;
      state.selectedStocking.name = action.payload.name;
      state.selectedStocking.code = action.payload.code;
      state.selectedStocking.naupliusNumber = action.payload.naupliusNumber;
      state.selectedStocking.juvenilesNumber = action.payload.juvenilesNumber;
      state.selectedStocking.growOutNumber = action.payload.growOutNumber;
      state.selectedStocking.litersNumber = action.payload.litersNumber;
      state.selectedStocking.hectares = action.payload.hectares;
      state.selectedStocking.cubicMeters = action.payload.cubicMeters;
      state.selectedStocking.phaseType = action.payload.phaseType;
      state.selectedStocking.maturationId._id = action.payload.maturationId._id;
      state.selectedStocking.maturationId.name = action.payload.maturationId.name;
    },

    setIsLoadingStocking: (state: GrowthDeltaPdfState, action: PayloadAction<boolean>) => {
      state.isLoadingStocking = action.payload;
    },
  },
});

export const {
  setGrowthDeltaData,
  setSelectedCampus,
  setSelectedModule,
  setSelectedStocking,
  setIsLoadingCampus,
  setIsLoadingStocking,
} = growthDeltaSlice.actions;

export const fetchGrowthDeltaData = (
  props: { sowingId?: string; containerId?: string; moduleId?: string; fromDate?: string; toDate?: string; phaseType: string },
  token: string
) => async (dispatch: Function) => {

  const params = {
    ...props,
    hoursOffset: getHoursOffset(),
  };

  try {
    const response = await axios.get(GROWTH_DELTA_URL, {
      params,
      headers: { 'Authorization': token },
    });

    dispatch(setGrowthDeltaData(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampus = (campusId: string, token: string) => async (dispatch: Function) => {
  dispatch(setIsLoadingCampus(true));

  try {
    const response = await axios.get(`${CAMPUS_URL}/${campusId}`, {
      headers: { 'Authorization': token },
    });

    dispatch(setSelectedCampus(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingCampus(false));
};

export const fetchModule = (moduleId: string, token: string) => async (dispatch: Function) => {
  try {
    const response = await axios.get(`${MODULES_URL}/${moduleId}`, {
      headers: { 'Authorization': token },
    });

    dispatch(setSelectedModule(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStocking = (id: string, token?: string) => async (dispatch: Function) => {
  dispatch(setIsLoadingStocking(true));

  const params = {
    '$sort[startDate]': '-1',
    $select: [
      'name',
      'code',
      'naupliusNumber',
      'juvenilesNumber',
      'growOutNumber',
      'litersNumber',
      'cubicMeters',
      'hectares',
      'maturationId',
      'phaseType',
    ],
    $populate: ['maturationId'],
  };

  try {
    const response = await axios.get(`${STOCKINGS_URL}/${id}`, {
      headers: { 'Authorization': token },
      params: params,
    });

    dispatch(setSelectedStocking(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingStocking(false));
};

export default growthDeltaSlice.reducer;
