import i18next from 'i18next';
import { AxiosResponse } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getLanguage } from '../../utils/language';
import { downloadFile } from '../../utils/download';
import { paginationHarvests } from '../../config/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';
import { COMPANIES_URL, CONSOLIDATED_STOCKING_URL, HARVESTS_URL, HARVEST_HISTOGRAM_PDF_URL } from '../../config/config.api';

import { HarvestState, HistogramPayload, Harvest, Consolidated, HarvestPayload } from './interfaces';

const initialState: HarvestState = {
  consolidateHarvests: {
    consolidate: false,
    selectedHarvests: [],
    selectedRowKeys: [],
    showModal: false,
    isLoading: false,
  },
  total: 0,
  skip: 0,
  limit: paginationHarvests,
  currentPage: 0,
  harvestsToShow: 'CURRENT',
  currentHarvests: [],
  previousHarvests: [],
  isLoading: false,
  histograms: {
    isLoadingConsolidated: false,
    isLoadingHarvest: false,
    showModal: false,
    harvest: {
      histogramLength: {
        frequencies: [],
        limits: [],
      },
      histogramWeight: {
        frequencies: [],
        limits: [],
      },
    }
  },
  isDownloadingFile: false,
  harvestsXAxisConfig: {
    company: {
      harvestsXAxisMaxValue: 0,
      harvestsXAxisMinValue: 0,
    },
    isLoading: false,
    showModal: false,
  },
};

export const harvestsSlice = createSlice({
  name: 'harvests',
  initialState,
  reducers: {
    setCurrentHarvests: (state: HarvestState, action: PayloadAction<HarvestPayload>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.currentHarvests = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },

    setPreviousHarvests: (state: HarvestState, action: PayloadAction<HarvestPayload>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.previousHarvests = action.payload.data;
      state.currentPage = (action.payload.skip / action.payload.limit) + 1;
    },

    setCurrentPage: (state: HarvestState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    setHarvestsToShow: (state: HarvestState, action: PayloadAction<string>) => {
      state.currentPage = 1;
      state.harvestsToShow = action.payload;
    },

    setSelectedHarvest: (state: HarvestState, action: PayloadAction<Harvest>) => {
      state.selectedHarvest = action.payload;
    },

    setIsLoading: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setConsolidated: (state: HarvestState, action: PayloadAction<Consolidated | undefined>) => {
      state.histograms.consolidated = action.payload;
    },

    setHistograms: (state: HarvestState, action: PayloadAction<HistogramPayload>) => {
      state.histograms.harvest.histogramWeight = action.payload.histogramWeight;
      state.histograms.harvest.histogramLength = action.payload.histogramLength;
      state.histograms.harvest.stocking = action.payload.stocking;
    },

    resetHistograms: (state: HarvestState) => {
      state.histograms.harvest.histogramWeight.frequencies = [];
      state.histograms.harvest.histogramWeight.limits = [];

      state.histograms.harvest.histogramLength.frequencies = [];
      state.histograms.harvest.histogramLength.limits = [];

      state.histograms.harvest.stocking = undefined;
    },

    setIsLoadingConsolidated: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.histograms.isLoadingConsolidated = action.payload;
    },

    setIsLoadingHarvest: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.histograms.isLoadingHarvest = action.payload;
    },

    setShowModal: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.histograms.showModal = action.payload;
    },

    setConsolidateHarvest: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.consolidateHarvests.consolidate = action.payload;
    },

    setSelectedHarvests: (state: HarvestState, action: PayloadAction<Harvest[]>) => {
      state.consolidateHarvests.selectedHarvests = action.payload;
    },

    setSelectedRowKeys: (state: HarvestState, action: PayloadAction<React.Key[]>) => {
      state.consolidateHarvests.selectedRowKeys = action.payload;
    },

    setShowConsolidateHarvestsModal: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.consolidateHarvests.showModal = action.payload;
    },

    setIsLoadingConsolidateHarvests: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.consolidateHarvests.isLoading = action.payload;
    },

    setIsDownloadingFile: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },

    setShowHarvestsXAxisConfigModal: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.harvestsXAxisConfig.showModal = action.payload;
    },

    setHarvestsXAxisValues: (state: HarvestState, action: PayloadAction<{ harvestsXAxisMinValue: number; harvestsXAxisMaxValue: number }>) => {
      state.harvestsXAxisConfig.company.harvestsXAxisMaxValue = action.payload.harvestsXAxisMaxValue;
      state.harvestsXAxisConfig.company.harvestsXAxisMinValue = action.payload.harvestsXAxisMinValue;
    },

    setIsLoadingHarvestsXAxis: (state: HarvestState, action: PayloadAction<boolean>) => {
      state.harvestsXAxisConfig.isLoading = action.payload;
    },
  },
});

export const {
  setCurrentHarvests,
  setPreviousHarvests,
  setCurrentPage,
  setHarvestsToShow,
  setSelectedHarvest,
  setIsLoading,
  setConsolidated,
  setHistograms,
  resetHistograms,
  setIsLoadingConsolidated,
  setIsLoadingHarvest,
  setShowModal,
  setConsolidateHarvest,
  setSelectedHarvests,
  setSelectedRowKeys,
  setShowConsolidateHarvestsModal,
  setIsLoadingConsolidateHarvests,
  setIsDownloadingFile,
  setShowHarvestsXAxisConfigModal,
  setHarvestsXAxisValues,
  setIsLoadingHarvestsXAxis,
} = harvestsSlice.actions;

const getHarvestParams = (props: { companyId: string; currentPage: number }) => {
  const { companyId, currentPage } = props;

  let skip = 0;
  if (currentPage !== 0) {
    skip = paginationHarvests * (currentPage - 1);
  }

  const params = {
    companyId,
    $limit: paginationHarvests,
    $skip: skip,
  };

  return params;
};

export const fetchCurrentHarvests = (props: { companyId: string; currentPage: number }) => async (dispatch: Function) => {
  const { companyId, currentPage } = props;

  dispatch(setIsLoading(true));
  const url = `${HARVESTS_URL}/consolidated-and-harvested/current`;
  const params = getHarvestParams({ companyId, currentPage });

  try {
    const response = await axios.get<HarvestPayload>(url, { params });
    dispatch(setCurrentHarvests(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchPreviousHarvests = (props: { companyId: string; currentPage: number; }) => async (dispatch: Function) => {
  const { companyId, currentPage } = props;

  dispatch(setIsLoading(true));

  const url = `${HARVESTS_URL}/consolidated/previous`;
  const params = getHarvestParams({ companyId, currentPage });

  try {
    const response = await axios.get<HarvestPayload>(url, { params });
    dispatch(setPreviousHarvests(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchHarvestHistogram = (props: { harvestId: string; accessToken?: string; harvestedAnimal?: number }) => async (dispatch: Function) => {
  const { harvestId, accessToken, harvestedAnimal } = props;

  dispatch(setIsLoadingHarvest(true));
  dispatch(resetHistograms());

  const params = {
    harvestedAnimal
  };

  const url = `${HARVESTS_URL}/stocking/${harvestId}/histograms`;
  let response: AxiosResponse<HistogramPayload>;

  try {
    if (accessToken) {
      response = await axios.get<HistogramPayload>(url, {
        headers: { 'Authorization': accessToken },
        params
      });
    } else {
      response = await axios.get<HistogramPayload>(url, { params });
    }

    dispatch(setHistograms(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingHarvest(false));
};

export const fetchConsolidationHistogram = (props: { consolidatedId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { consolidatedId, accessToken } = props;

  dispatch(setIsLoadingConsolidated(true));

  const url = `${HARVESTS_URL}/${consolidatedId}`;
  const params = {
    $populate: ['stockingIds', 'campusIds', 'moduleIds', 'tankIds'],
  };
  let response: AxiosResponse<Consolidated>;

  try {
    if (accessToken) {
      response = await axios.get<Consolidated>(url, {
        headers: { 'Authorization': accessToken },
        params
      });
    } else {
      response = await axios.get<Consolidated>(url, { params });
    }
    dispatch(setConsolidated(response.data));
    dispatch(setIsLoadingConsolidated(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createConsolidatedHarvest = (props: {
  body: { stockingIds: string[]; harvestedAnimals: number[]; name: string; endDate: string; };
  onCloseModal: Function;
  companyId: string;
  currentPage: number;
}) => async (dispatch: Function) => {
  const { body, onCloseModal, companyId, currentPage } = props;

  dispatch(setIsLoadingConsolidateHarvests(true));

  try {
    await axios.post<Consolidated>(CONSOLIDATED_STOCKING_URL, body);
    onCloseModal();
    dispatch(fetchCurrentHarvests({ companyId, currentPage }));

    openSuccessNotification(i18next.t('harvests.consolidateModal.created'));
    dispatch(setIsLoadingConsolidateHarvests(false));
  } catch (e) {
    console.log(e?.response);
    openErrorNotification(i18next.t('harvests.consolidateModal.error'));
  }
};

export const getHarvestReportPdf = (props: { harvestId: string; companyId: string; histogram: string; harvestType: string; }) => async (dispatch: Function) => {
  const { harvestId, companyId, histogram, harvestType } = props;

  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(HARVEST_HISTOGRAM_PDF_URL);

    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');

    const url = `${response.data.url}?harvestId=${harvestId}&companyId=${companyId}&histogram=${histogram}&harvestType=${harvestType}&accessToken=${accessToken}&language=${language}`;
    await downloadFile(url, harvestId, 'pdf');
  } catch (e) {
    console.log(e.response);
  }

  dispatch(setIsDownloadingFile(false));
};

export const deleteHarvest = (props: { harvestId: string; companyId: string; currentPage: number; }) => async (dispatch: Function) => {
  const { harvestId, companyId, currentPage } = props;
  const url = `${HARVESTS_URL}/${harvestId}`;

  try {
    await axios.delete(url);
    openSuccessNotification(i18next.t('harvests.deconsolidated'));
    dispatch(fetchCurrentHarvests({ companyId, currentPage }));
  } catch (e) {
    console.log(e.response);
    openErrorNotification(i18next.t('harvests.deconsolidatedError'));
  }
};

export const fetchHarvestsXAxisValues = (props: { companyId: string; }) => async (dispatch: Function) => {
  const { companyId } = props;

  const url = `${COMPANIES_URL}/${companyId}`;
  const params = {
    $select: ['harvestsXAxisMinValue', 'harvestsXAxisMaxValue'],
  };

  try {
    const response = await axios.get<{ harvestsXAxisMinValue: number; harvestsXAxisMaxValue: number }>(url, { params });
    dispatch(setHarvestsXAxisValues(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateHarvestsXAxisValues = (props: {
  body: { harvestsXAxisMinValue: number; harvestsXAxisMaxValue: number; };
  onCloseModal: Function;
  companyId: string;
}) => async (dispatch: Function) => {
  const { companyId, body, onCloseModal } = props;

  dispatch(setIsLoadingHarvestsXAxis(true));
  const url = `${COMPANIES_URL}/${companyId}`;

  try {
    const response = await axios.patch(url, body);
    onCloseModal();
    dispatch(setHarvestsXAxisValues(response.data));
    openSuccessNotification(i18next.t('weightUnit.success'));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingHarvestsXAxis(false));
};

export default harvestsSlice.reducer;
