import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../common/components/LrvEmpty/LrvEmpty';
import ActionButton from '../../common/components/buttons/ActionButton';
import { CompanyStockingParameter } from '../Company/StockingParameters/interfaces';

import { disabledStartButton } from './helpers';
import { StockingParameter } from './interfaces';
import styles from './CompanyStockingParameterForm.module.scss';
import * as newStockingParameterSlice from './newStockingParameterSlice';

interface Props {
  setEnterParameters: Dispatch<SetStateAction<boolean>>;
  setAuxiliarStockingParameters: Dispatch<SetStateAction<StockingParameter[]>>;
}

export const CompanyStockingParameterForm = (props: Props) => {
  const {
    setEnterParameters,
    setAuxiliarStockingParameters,
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyStockingParameters } = useSelector((state: Store) => state.newStockingParameter);

  const getStylesItem = (companyStockingParameter: CompanyStockingParameter) => {
    if (companyStockingParameter.selected) {
      return cx(styles.gridItem, styles.itemSelected);
    }

    return styles.gridItem;
  };

  const renderItem = (props: { companyStockingParameter: CompanyStockingParameter; index: number; }) => {
    const { companyStockingParameter, index } = props;

    return (
      <div
        key={`${companyStockingParameter.key}${index}`}
        className={getStylesItem(companyStockingParameter)}
        onClick={() => {
          const companyStockingParametersCopy: CompanyStockingParameter[] = cloneDeep(companyStockingParameters);
          companyStockingParametersCopy[index].selected = !companyStockingParameter.selected;
          dispatch(newStockingParameterSlice.setCompanyStockingParameters(companyStockingParametersCopy));
        }}
      >
        <LrvText text={companyStockingParameter.key} theme='light' />
      </div>
    );
  };

  const renderStartButton = () => {
    return (
      <div
        className={styles.containerStartButton}
      >
        <ActionButton
          id='btn_start_stocking'
          type='primary'
          theme='light'
          disabled={disabledStartButton(companyStockingParameters)}
          onClick={() => {
            setEnterParameters(true);
            const selectedItems = companyStockingParameters.filter((parameter) => !!parameter.selected);
            const newStockingParameters: StockingParameter[] = [];

            for (let index = 0; index < selectedItems.length; index++) {
              const parameter = selectedItems[index];
              const item: StockingParameter = {
                _id: parameter._id,
                key: parameter.key,
                type: parameter.type,
                value: '',
                frequency: parameter.frequency,
                options: parameter.options,
                max: parameter.max,
                min: parameter.min,
              };

              newStockingParameters.push(item);
            }

            setAuxiliarStockingParameters(newStockingParameters);
          }}
        >
          {t('stockingParameter.formNew.start')}
        </ActionButton>
      </div>
    );
  };

  const renderBody = () => {
    if (companyStockingParameters.length === 0) {
      return (
        <LrvEmpty description={t('stockingParameter.formNew.empty')} theme='light' />
      );
    }

    return (
      <>
        <div className={styles.title}>
          <LrvText text={t('stockingParameter.formNew.description')} theme='light' />
        </div>

        <div className={styles.gridContainer}>
          {companyStockingParameters.map((companyStockingParameter, index) => {
            return renderItem({ companyStockingParameter, index });
          })}
        </div>

        {renderStartButton()}
      </>
    );
  };

  return (
    <LrvForm
      className={styles.companyStockingParameterForm}
      theme='light'
      layout='vertical'
    >
      {renderBody()}
    </LrvForm>
  );
};
