import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../utils/axios_instance';
import { showErrorStocking } from '../../helpers/stocking.helpers';
import { STOCKINGS_URL, STOCKING_FINISHED_DATA_URL } from '../../config/config.api';
import { openErrorNotification, openSuccessNotification } from '../../common/notification/Notification';

import * as sowingsSlice from './sowingsSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import { setShowTransferStockingInfo } from './transferStockingSlice';
import { FinishStockingsState, StockingFinished, FinishStockingProps, EnableStockingProps, DataDestinationStocking, FetchStockingProps } from './interfaces';

const initialState: FinishStockingsState = {
  isLoadingFinish: false,
  showFinishStockingInfo: false,
  showModalFinish: false,
  showModalEnable: false,
  plgHarvest: 0,
  averageWeight: 0,
  hasErrorEnable: false,
  isLoadingEnable: false,
  stockingFinishedData: {
    larvae: {
      larvaePerGram: {
        max: 0,
        min: 0,
      }
    },
    juvenile: {
      averageHarvestedWeight: {
        min: 0,
      }
    },
    growOut: {
      averageHarvestedWeight: {
        min: 0,
      }
    }
  },
  dataDestinationStockings: [
    defaultDestinationStocking
  ],
};

export const transferStockingsSlice = createSlice({
  name: 'finishStocking',
  initialState,
  reducers: {
    setPlgHarvest: (state: FinishStockingsState, action: PayloadAction<number>) => {
      state.plgHarvest = action.payload;
    },
    setAverageWeight: (state: FinishStockingsState, action: PayloadAction<number>) => {
      state.averageWeight = action.payload;
    },
    setIsLoadingFinish: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingFinish = action.payload;
    },
    setShowModalFinish: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.showModalFinish = action.payload;
    },
    setShowModalEnable: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.showModalEnable = action.payload;
    },
    setShowFinishStockingInfo: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.showFinishStockingInfo = action.payload;
    },
    setStockingFinishedData: (state: FinishStockingsState, action: PayloadAction<StockingFinished>) => {
      state.stockingFinishedData = action.payload;
    },
    setHasErrorEnable: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.hasErrorEnable = action.payload;
    },
    setIsLoadingEnable: (state: FinishStockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingEnable = action.payload;
    },
    setDataDestinationStocking: (state: FinishStockingsState, action: PayloadAction<DataDestinationStocking[]>) => {
      state.dataDestinationStockings = action.payload;
    },
  },
});

export const {
  setPlgHarvest,
  setAverageWeight,
  setIsLoadingFinish,
  setStockingFinishedData,
  setShowModalFinish,
  setShowModalEnable,
  setShowFinishStockingInfo,
  setHasErrorEnable,
  setIsLoadingEnable,
  setDataDestinationStocking,
} = transferStockingsSlice.actions;

export const getAveragePLG = (id: string) => async (dispatch: Function) => {
  try {
    const response = await axios.get(`${STOCKINGS_URL}/${id}/plg`);
    const plg = response.data.plg || 0;
    const averageWeight = response.data.averageWeight || 0;

    dispatch(setPlgHarvest(plg));
    dispatch(setAverageWeight(averageWeight));
  } catch (e) {
    console.log(e?.response);
  }
};

export const finishStocking = (props: FinishStockingProps) => async (dispatch: Function) => {
  const { stockingId, stockingData, makeFetchStockings, paramsToFetchStocking, onCreateBindingCode } = props;
  dispatch(setIsLoadingFinish(true));

  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}/finish`, stockingData);
    openSuccessNotification(i18next.t('stockings.updated'));

    if (onCreateBindingCode) {
      onCreateBindingCode();
    }
  } catch (e) {
    console.log(e?.response);
    dispatch(setIsLoadingFinish(false));
    if (e?.response?.data?.data?.error) {
      const minimumDate = e.response.data.data.minimumDate;
      const error = e.response.data.data.error;
      showErrorStocking({ error, minimumDate });
    }
    return;
  }

  dispatch(setIsLoadingFinish(false));
  dispatch(setShowModalFinish(false));
  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: stockingId }));
  }
};

export const finishTransferredStocking = (props: { endDate: string; stockingId: string; paramsToFetchStocking: FetchStockingProps; onSuccess?: () => void; }) => async (dispatch: Function) => {
  const { endDate, stockingId, paramsToFetchStocking, onSuccess } = props;
  dispatch(setIsLoadingFinish(true));
  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}/finish-transferred-stocking`, { endDate });
    openSuccessNotification(i18next.t('stockings.updated'));
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
    dispatch(setShowTransferStockingInfo(false));
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
    } else {
      openErrorNotification(i18next.t('stockings.finishStockingError'));
    }
  }
  dispatch(setIsLoadingFinish(false));
};

export const enableStocking = (props: EnableStockingProps) => async (dispatch: Function) => {
  const { stockingId, makeFetchStockings, paramsToFetchStocking } = props;
  dispatch(setHasErrorEnable(false));
  dispatch(setIsLoadingEnable(true));

  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}/enable`);
    openSuccessNotification(i18next.t('stockings.updated'));
  } catch (e) {
    dispatch(setHasErrorEnable(true));
    dispatch(setIsLoadingEnable(false));

    if (e.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
      return;
    }

    openErrorNotification(i18next.t('stockings.finishStockingError'));
    console.log(e?.response);
    return;
  }

  dispatch(setHasErrorEnable(false));
  dispatch(setIsLoadingEnable(false));
  dispatch(setShowModalEnable(false));
  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: stockingId }));
  }
};

export const fetchStockingFinishedData = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<StockingFinished>(STOCKING_FINISHED_DATA_URL);
    dispatch(setStockingFinishedData(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default transferStockingsSlice.reducer;
