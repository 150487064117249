const transparency = 80;

const decimalToHex = (decimal: number): string => {
  const hex = decimal.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

const randomNumber = () => {
  return Math.floor(Math.random() * 256);
};

export const generateColorHex = (): string => {
  const red = randomNumber();
  const green = randomNumber();
  const blue = randomNumber();
  return `#${decimalToHex(red)}${decimalToHex(green)}${decimalToHex(blue)}${transparency}`;
};
