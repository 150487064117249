export const calculatePercentile = (arr: number[], percentile: number): number => {
  const sortedArr = arr.slice().sort((a, b) => a - b);
  const index = (percentile / 100) * (sortedArr.length - 1);
  const lowerIndex = Math.floor(index);
  const upperIndex = lowerIndex + 1;
  const weight = index % 1;

  if (upperIndex >= sortedArr.length) {
    return sortedArr[lowerIndex];
  }

  return (sortedArr[lowerIndex] * (1 - weight)) + (sortedArr[upperIndex] * weight);
};
