import React, { ReactNode } from 'react';
import { Button } from 'antd';

import styles from './FunctionalitiesModal.module.scss';

export default function FunctionalitiesModal (props: {
  children: ReactNode;
  titleModal: string | ReactNode;
  titleButton: string;
  titleSecondaryButton?: string;
  show: boolean;
  onClose: Function;
  onClickSecondary?: Function;
}) {
  const { children, titleModal, titleButton, show, onClose, titleSecondaryButton, onClickSecondary } = props;
  if (!show) {
    return null;
  }

  const hasSecondaryButton = !!titleSecondaryButton && !!onClickSecondary;
  const footerStyle = {
    justifyContent: hasSecondaryButton ? 'space-between' : 'flex-end'
  };

  return <div className={styles.container}>
    <div className={styles.modal}>
      <div className={styles.header}>
        <div className={styles.title}>
          {titleModal}
        </div>
      </div>

      <div className={styles.body}>
        {children}
      </div>

      <div className={styles.footer} style={footerStyle}>
        {!!titleSecondaryButton && !!onClickSecondary ?
          <Button
            id='secondary_got_it'
            type='text'
            className={styles.secondaryButton}
            onClick={() => onClickSecondary()}
          >
            {titleSecondaryButton}
          </Button> :
          null}
        <Button
          id='submit_got_it'
          type='primary'
          onClick={() => onClose()}
        >
          {titleButton}
        </Button>
      </div>
    </div>
  </div>;
}
