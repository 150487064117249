import React from 'react';
import { Descriptions } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TinyLabel from '../TinyLabel/TinyLabel';
import { Store } from '../../../state/store.interfaces';
import { formatLongDateWithZone } from '../../../utils/date';
import { applyThousandsSeparator } from '../../../utils/strings';
import { LrvDescriptions } from '../LrvDescriptions/LrvDescriptions';
import { convertPoundsToKilograms } from '../../../helpers/stocking.helpers';
import { stockingPhaseTypes, stockingStatuses, roundTwoDecimals, transferTypes, weightUnitsByCompany } from '../../../config/commons';

import './FinishSowingInfo.scss';
import styles from './FinishSowingInfo.module.scss';

interface Props {
  name: string;
  status: string;
  phaseType: string;
  harvestQuantity: number;
  larvaePerGram: number;
  startDate: string;
  endDate: string;
  maturation: string;
  comment: string | undefined;
  density: number;
  unitDensity: string;
  averageHarvestedWeight?: number | undefined;
  poundsHarvested?: number | undefined;
  survivalRate?: number;
  animals: number;
  theme?: 'dark' | 'light';
}

export default function FinishStockingInfo (props: Props) {
  const { status, phaseType, harvestQuantity, larvaePerGram, startDate, endDate, comment, name, maturation, density, unitDensity, averageHarvestedWeight, poundsHarvested, theme, survivalRate, animals } = props;

  const [t] = useTranslation();
  const { company } = useSelector((state: Store) => state.header);

  return <>
    <LrvDescriptions
      column={1}
      theme={theme}
    >
      <Descriptions.Item label={t('stockings.stocking')}>
        {name}
      </Descriptions.Item>

      <Descriptions.Item label={t('stockings.finishStockingLabels.status')}>
        {t(`stockings.stockingStatuses.${status}`)}
      </Descriptions.Item>

      <Descriptions.Item label={t('stockings.maturation')}>
        {maturation}
      </Descriptions.Item>

      <Descriptions.Item label={t('analysis.resultData.shrimpsNumber')}>
        {applyThousandsSeparator(animals)}
      </Descriptions.Item>

      <Descriptions.Item label={t('stockings.density')}>
        {applyThousandsSeparator(density) + ' ' + unitDensity}
      </Descriptions.Item>

      {(status === stockingStatuses.HARVESTED || status === transferTypes.FULL_TRANSFER) &&
        <Descriptions.Item label={t(`stockings.finishStockingLabels.${phaseType}.harvested`)}>
          {applyThousandsSeparator(harvestQuantity)}
        </Descriptions.Item>
      }

      {status === stockingStatuses.HARVESTED && phaseType === stockingPhaseTypes.LARVAE &&
        <Descriptions.Item label={t(`stockings.finishStockingLabels.${phaseType}.plg`)}>
          {larvaePerGram}
        </Descriptions.Item>
      }

      {status === stockingStatuses.HARVESTED && phaseType !== stockingPhaseTypes.LARVAE &&
        <Descriptions.Item label={t(`stockings.finishStockingLabels.${phaseType}.averageWeight`)}>
          {averageHarvestedWeight}
        </Descriptions.Item>
      }

      {company.weightUnit !== weightUnitsByCompany.KILOGRAM && poundsHarvested && status === stockingStatuses.HARVESTED && phaseType === stockingPhaseTypes.ADULT &&
        <Descriptions.Item label={t(`stockings.finishStockingLabels.${phaseType}.pounds`)}>
          {poundsHarvested}
        </Descriptions.Item>
      }

      {company.weightUnit !== weightUnitsByCompany.POUND && poundsHarvested && status === stockingStatuses.HARVESTED && phaseType === stockingPhaseTypes.ADULT &&
        <Descriptions.Item label={t(`stockings.finishStockingLabels.${phaseType}.kilograms`)}>
          {convertPoundsToKilograms(poundsHarvested)}
        </Descriptions.Item>
      }

      <Descriptions.Item label={t('stockings.startDate')}>
        {formatLongDateWithZone(startDate)}
      </Descriptions.Item>

      {
        endDate &&
        <Descriptions.Item label={t('stockings.finishStockingLabels.endDate')}>
          {formatLongDateWithZone(endDate)}
        </Descriptions.Item>
      }

      {
        survivalRate &&
        <Descriptions.Item label={t('survivalRate.title')}>
          {roundTwoDecimals(survivalRate)} %
        </Descriptions.Item>
      }

      {
        comment &&
        <Descriptions.Item label={t('stockings.finishStockingLabels.comment')}>
          {comment}
        </Descriptions.Item>
      }
    </LrvDescriptions>
    {!comment && <TinyLabel text={t('stockings.defaultComment')} className={styles.defaultComment} />}
  </>;
}
