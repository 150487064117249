import moment from 'moment';

import { companiesTypes, DATE_FORMATS, INITIAL_DATE } from '../../config/commons';

import { FilterAdminDashboard } from './interfaces';

export const LAST_DAYS = 7;

export const getFromDate = (lastDays: number) => {
  return moment(new Date()).subtract(lastDays, 'days').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDate = () => {
  return moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD);
};

export const disabledDateFrom = (current: moment.MomentInput, toDate: Date) => {
  const minDays = 14;
  const start = moment().subtract(minDays, 'days');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledDateTo = (current: moment.MomentInput, fromDate: Date) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledMonthlyDateFrom = (current: moment.MomentInput, toDate: Date) => {
  const start = moment(INITIAL_DATE);
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledRangeDate = (current: moment.MomentInput) => {
  const minDays = 14;
  const start = moment().subtract(minDays, 'day');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const initialAdminDashboardFiltersState: FilterAdminDashboard = {
  companyType: companiesTypes.ALL,
  activityType: 'DAILY',
  showActivityByPhaseType: true,
  daily: {
    fromDate: getFromDate(LAST_DAYS),
    toDate: getToDate(),
  },
  monthly: {
    fromDate: moment().startOf('month').subtract(1, 'year').format(DATE_FORMATS.YYYY_MM_DD),
    toDate: moment().endOf('month').format(DATE_FORMATS.YYYY_MM_DD),
  },
  dateSelected: getToDate(),
};
