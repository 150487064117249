import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';

import resetIconSvg from '../../../../assets/reset.svg';
import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { Company } from '../../../../pages/AppHeader/interfaces';
import { Area, GrowthDeltaDataByStage } from '../../../../pages/home/interfaces';
import { GrowthDeltaStocking, GrowthDeltaStage } from '../../../../pages/Sowings/interfaces';
import * as growthDeltaSlice from '../../../../pages/Reports/GrowthDelta/growthDeltaSlice';
import { roundWeight, roundOneDecimal, stockingPhaseTypes, THEME } from '../../../../config/commons';

import './ShadedPlot.scss';
import styles from './ShadedPlot.module.scss';
import { colorsPoints, colorsStroke, fillArea, getFill, getMarginArea, palette, typeScale, typesChart, metricsStatuses, calcDeltaStages, getLabelStage, renderTickFormat, getNumberTicks, getGrowthDeltaChartLeftPosition, getGrowthDeltaValue } from './helpers';

let zoomDone = false;
let numbersTicks: number;
let currentStageActive: string;
let idleTimeout: NodeJS.Timeout | null;

const TIME_TRANSITION = 300;
const TICK_PADDING = 8;

interface Props {
  colorFillRect?: string;
  colorLine: string;
  companyData: Company;
  container: HTMLDivElement | null;
  dataMetric: GrowthDeltaStocking[];
  firstStage: number;
  height: number;
  lastStage: number;
  parameter: string;
  phaseType: string;
  scale: string;
  showLabels?: boolean;
  typeChart: string;
  width: number;
  movingAverage?: number;
  showMovingAverage?: boolean;
  selectedTickStoke?: string;
  dispatch?: Dispatch<GenericParam>;
}

class GrowthDeltaChart {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  area: Area[] = [];

  margin = { top: 16, right: 24, bottom: 20, left: 45 };
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  scale: string;
  parameter: string;
  chart: string;

  dataMetric: GrowthDeltaStocking[] = [];
  dataY1: number[] = [];
  dataY2: number[] = [];
  allPoints: GrowthDeltaStage[] = [];

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  fill: string = palette.GLOBAL;
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;
  shouldShowAllPoints = true;
  maxStage = 0;
  companyId = '';

  containerLeft: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerRight: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerTop: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerBottom: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  colorFillRect: string;
  colorLine = '';

  phaseType: string;
  showLabels: boolean;

  movingAverage?: number;
  showMovingAverage?: boolean;
  selectedTickStoke: string;

  clip: d3.Selection<d3.BaseType, unknown, null, undefined> = d3.select<d3.BaseType, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<unknown> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  dispatch?: Dispatch<GenericParam>;

  // eslint-disable-next-line
  constructor(props: Props) {
    const { colorFillRect = '', colorLine, companyData, container, dataMetric, firstStage, height, lastStage, parameter, phaseType, scale, showLabels = false, typeChart, width, movingAverage = 2, showMovingAverage = true, selectedTickStoke = 'white', dispatch } = props;

    this.container = container;
    this.scale = scale;
    this.parameter = parameter;
    this.dataMetric = dataMetric;
    this.chart = typeChart;
    this.maxStage = companyData.maxStage;
    this.companyId = companyData._id;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.selectedTickStoke = selectedTickStoke;
    this.shouldShowAllPoints = dataMetric.length === 1 && typeChart === typesChart.STOCKINGS;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.phaseType = phaseType;
    this.showLabels = showLabels;
    this.movingAverage = movingAverage;
    this.showMovingAverage = showMovingAverage;

    this.dispatch = dispatch;

    d3.select(container).select('#tooltipFactorK').remove();
    d3.select(container).select('#tooltipGrowthDelta').remove();
    d3.select(container).select('#tooltipComparisionChart').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.sortAllPoints();
    if (firstStage === lastStage && firstStage === 0) {
      const stages = calcDeltaStages(dataMetric);
      this.firstStage = stages[0];
      this.lastStage = stages[1];
      this.fill = getFill({ dataSource: metricsStatuses.GLOBAL });
    } else {
      this.firstStage = firstStage;
      this.lastStage = lastStage;
      this.fill = palette.GLOBAL;
    }

    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width + ((8 - 1) * 2))
      .attr('height', height)
      .attr('x', - 8 + 1)
      .attr('y', 0);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStage, lastStage } = this;
      const { maxY, minY } = this.getYDomainData();

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }
      this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;

    if (this.dispatch) {
      this.dispatch(growthDeltaSlice.setFirstStageZoom(this.x.invert(x0)));
      this.dispatch(growthDeltaSlice.setLastStageZoom(this.x.invert(x1)));
    }

    this.x.domain([x0, x1].map(this.x.invert));
    this.y.domain([y1, y0].map(this.y.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = false;
    this.x.domain([x0, x1]);
    this.y.domain([y0, y1]);

    if (this.dispatch) {
      this.dispatch(growthDeltaSlice.setFirstStageZoom(x0));
      this.dispatch(growthDeltaSlice.setLastStageZoom(x1));
    }

    this.refreshBrush();
    this.hideTooltipOption();
  }

  refreshBrush = () => {
    const { height } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat(renderTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    const { line } = this.generateLines();

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.gClipPath
      .selectAll('.pathLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', line as any);

    const movingAverageLine = this.getMovingAverageLine();
    this.gClipPath
      .select('#movingAverageLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', movingAverageLine as any);


    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.x(point.x))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.y(getGrowthDeltaValue(this.phaseType, point.y)));

    this.renderResetBrush();
  }

  renderLineSelected () {
    const { container, groupMain, margin, height, selectedTickStoke } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke)
      .attr('stroke-width', 1)
      .attr('y1', -(margin.top / 2))
      .attr('y2', height)
      .style('display', 'none');
  }

  updateDataPoints = () => {
    const { groupMain, height, selectedTickStoke } = this;

    this.area = fillArea(this.dataY1, this.dataY2, 1);
    this.buildAxisX();
    this.buildAxisY();
    this.renderResetBrush();

    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipGrowthDelta')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentStageActive).attr('r', () => this.shouldShowAllPoints ? 11 : 8);
      });

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke)
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');

    this.renderLineSelected();
    this.createGClipPathElement();
    this.renderLines();
    this.renderMovingAverageLine();
    this.hideBorders();

    this.drawXAxis();
    this.drawYAxis();
    this.renderPoints();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  };

  hideBorders () {
    const { container, groupMain, width, height, margin } = this;

    const hideBorder = groupMain.append('g')
      .attr('id', 'hideBorderContainer');

    d3.select(container).selectAll('.hideBorder').remove();

    // container bottom
    this.containerBottom = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', height + 4)
      .attr('width', width + 90)
      .attr('height', margin.top)
      .style('fill', this.colorFillRect);

    // container top
    this.containerTop = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', width + margin.left + margin.right)
      .attr('height', margin.top)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', margin.left)
      .attr('height', height + margin.bottom + margin.top)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', width + 1)
      .attr('y', 0)
      .attr('width', margin.right)
      .attr('height', height + 1)
      .style('fill', this.colorFillRect);
  }

  updateBorders () {
    const { width, height, margin } = this;

    // container bottom
    this.containerBottom
      .attr('y', height + 4)
      .attr('width', width + margin.left + margin.right)
      .style('fill', this.colorFillRect);

    // container top
    this.containerTop
      .attr('width', width + margin.left + margin.right)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft
      .attr('height', height + margin.bottom + margin.top)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight
      .attr('x', width + 1)
      .attr('height', height + 1)
      .style('fill', this.colorFillRect);
  }

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  /* eslint-disable max-depth*/
  renderTooltips () {
    const { dataMetric, groupMain, x, y, tooltip,
      shouldShowAllPoints,
      firstStage, lastStage, allPoints,
      width, height, renderTooltipsForDelta, phaseType
    } = this;

    const bisect = d3.bisector(function (point: GrowthDeltaStage) {
      return point.stage;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentStageActive).attr('r', () => shouldShowAllPoints ? 9 : 6);
      })
      .on('mousemove', function (event) {
        const amountEnabled = dataMetric.filter((data) => data.enabled === true).length;

        if (amountEnabled === 0) {
          return;
        }

        //const x0 = x.invert((d3).pointer(event)[0]);
        let x0 = x.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= x.domain()[0]) && (x0 <= x.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const index = bisect(allPoints, x0, 1);

        const previousPoint = allPoints[index - 1];
        const currentPoint = allPoints[index];
        let selectedPoint: GrowthDeltaStage;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.stage > currentPoint.stage - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.stage < firstStage || selectedPoint.stage > lastStage)) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        const dataByStage: GrowthDeltaDataByStage[] = [];
        const pointsList: { x: number; y: number; }[] = [];

        for (let index = 0; index < dataMetric.length; index++) {
          const data = dataMetric[index];
          if (!data.enabled) {
            continue;
          }

          const points = data.data.map(metric => { return { x: metric.stage, y: metric.delta }; });
          if (points && points.length > 0) {
            points.forEach((point) => {
              if (point.x === selectedPoint.stage) {
                const _dataByStage: GrowthDeltaDataByStage = { point, name: data.name || '', index: index };
                dataByStage.push(_dataByStage);
                pointsList.push(point);
              }
            });
          }
        }

        d3.selectAll('.points circle').attr('r', () => shouldShowAllPoints ? 9 : 6);
        if (pointsList.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: { x: number; y: number; } = pointsList.reduce(function (prev, current) {
          return (prev.y > current.y) ? prev : current;
        });
        const lowestValue: { x: number; y: number; } = pointsList.reduce(function (prev, current) {
          return (prev.y < current.y) ? prev : current;
        });

        const marginLeft = x(higherValue.x);
        const marginBottom = y(getGrowthDeltaValue(phaseType, lowestValue.y));
        currentStageActive = `.stage${higherValue.x}`;
        d3.selectAll(currentStageActive).attr('r', () => shouldShowAllPoints ? 11 : 8);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', () => {
            let value = 0;
            const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

            if ((marginLeft + tooltipTotalWidth) < width) {
              value = -16; // has to be the same that width, but negative
            } else {
              value = tooltipTotalWidth;
            }

            return `${value}px`;
          });

        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltip
          .style('left', getGrowthDeltaChartLeftPosition(leftPositionProps))
          .style('bottom', () => {
            let value = 0;
            const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;

            if (marginBottom > 0) {
              value = (height - marginBottom);
            } else {
              value = (height - marginBottom) - (tooltipTotalHeight);
            }
            return `${value}px`;
          });

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsForDelta(dataByStage, tooltipContent, shouldShowAllPoints, phaseType);
      });
  }

  renderTooltipsForDelta (dataByStage: GrowthDeltaDataByStage[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>, shouldShowAllPoints: boolean, phaseType: string) {
    const forceCast = phaseType !== stockingPhaseTypes.LARVAE;
    for (let index = 0; index < dataByStage.length; index++) {
      const data = dataByStage[index];

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      if (data.name) {
        const entryTitle = entry.append('div')
          .attr('class', styles.entryHeader);

        entryTitle.append('a')
          .attr('class', styles.entryTitle)
          .style('color', shouldShowAllPoints ? 'royalblue' : colorsPoints[data.index])
          .html(data.name);
      }

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      const stageLabel = getLabelStage(phaseType);
      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${stageLabel}: <strong>${data.point.x}</strong>`);

      entryContent.append('div')
        .attr('class', cx(styles.stat, 'growthDelta'))
        .html(`Delta: <strong>${roundWeight({ value: data.point.y, forceCast })}</strong>`);

      if (index !== dataByStage.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  generateLines () {
    const { x, y, phaseType, dataMetric } = this;

    const linePoints: { x: number; y: number }[][] = [];

    const line = d3.line<{ x: number; y: number }>()
      .x((d) => x(d.x))
      .y((d) => y(getGrowthDeltaValue(phaseType, d.y)))
      .curve(d3.curveMonotoneX);

    if (this.firstStage === this.lastStage) {
      return {
        linePoints,
        line,
      };
    }

    for (let index = 0; index < dataMetric.length; index++) {
      const deltaData = dataMetric[index].data;
      const points = deltaData.map(stage => { return { x: stage.stage, y: stage.delta }; });
      const isEnabled = dataMetric[index].enabled;

      if (!isEnabled) {
        continue;
      }

      linePoints.push(points);
    }

    return {
      linePoints,
      line,
    };
  }

  renderLines () {
    const { brush, container, gClipPath, shouldShowAllPoints } = this;

    const { linePoints, line } = this.generateLines();

    d3.select(container).selectAll('.brush').remove();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    for (let index = 0; index < linePoints.length; index++) {
      const linePoint = linePoints[index];
      gClipPath
        .append('path')
        .datum(linePoint)
        .attr('class', cx('pathLine', shouldShowAllPoints ? 'line' : 'lines'))
        .attr('stroke', shouldShowAllPoints ? this.colorLine : colorsPoints[index])
        .attr('d', line);
    }
  }

  renderLabelOfPoint (props: { avgPoint: { x: number; y: number; }; isEnabled?: boolean }) {
    const { groupMain } = this;
    const { avgPoint, isEnabled } = props;

    if (!isEnabled) {
      return;
    }

    groupMain.append('text')
      .attr('class', 'labelsPoints')
      .attr('x', this.x(avgPoint.x))
      .attr('y', this.y(avgPoint.y) - 12)
      .style('text-anchor', 'middle')
      .text(roundOneDecimal(avgPoint.y));
  }

  renderLabelsOfPoints () {
    const { dataMetric } = this;

    for (let i = 0; i < dataMetric.length; i++) {
      const deltaData = dataMetric[i].data;
      const points = deltaData.map(stage => { return { x: stage.stage, y: stage.delta }; });
      const isEnabled = dataMetric[i].enabled;

      for (let j = 0; j < points.length; j++) {
        const avgPoint = points[j];
        this.renderLabelOfPoint({ avgPoint, isEnabled });
      }
    }
  }


  renderPoints () {
    const { gClipPath, x, y, dataMetric, shouldShowAllPoints, phaseType } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    for (let index = 0; index < dataMetric.length; index++) {
      const deltaData = dataMetric[index].data;
      const points = deltaData.map(stage => { return { x: stage.stage, y: stage.delta }; });
      const isEnabled = dataMetric[index].enabled;

      if (!isEnabled) {
        continue;
      }

      gClipPath.append('g')
        .attr('class', 'points')
        .attr('fill', 'transparent')
        .style('pointer-events', 'none')
        .selectAll('circle')
        .data(points)
        .enter()
        .append('circle')
        .attr('id', (point) => `point_${point.x}`)
        .attr('class', (point) => {
          if (this.firstStage <= point.x && point.x <= this.lastStage) {
            return `stage${point.x}`;
          }
          return styles.hideCircle;
        })
        .attr('r', () => {
          if (shouldShowAllPoints) {
            return 9;
          }
          return 6;
        })
        .attr('cx', (point) => x(point.x))
        .attr('cy', (point) => y(getGrowthDeltaValue(phaseType, point.y)))
        .attr('fill', () => {
          if (!shouldShowAllPoints) {
            return colorsPoints[index];
          }

          return isLightTheme ? '#a8b7ec' : '#d9d9d9';
        })
        .attr('stroke', () => {
          if (!shouldShowAllPoints) {
            return colorsStroke[index];
          }

          return isLightTheme ? '#ffffff' : '#131B55';
        })
        .attr('stroke-width', 2);
    }
  }

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', 'lineAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', -8)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderTriangle = () => {
    const { container, margin, height } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 11),
    };

    d3.select(container).selectAll('.triangleComparisionChart').remove();
    d3.select(container).selectAll('.triangleGrowthDelta').remove();
    d3.select(container).selectAll('.triangleLeftAxisY').remove();
    d3.select(container).selectAll('.triangleRightAxisY').remove();

    d3.select(container)
      .attr('id', 'triangleGrowthDelta')
      .append('div')
      .attr('class', cx('triangleGrowthDelta', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);
  };

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 28) + 'px')
      .style('top', '-8px');
  };

  renderResetBrush = () => {
    const { margin, width, svg, colorLine } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#resetBrushButton').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft)
      .attr('y', 0)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        const { firstStage, lastStage } = this;
        const { maxY, minY } = this.getYDomainData();
        this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', colorLine)
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  updateAxis () {
    const { scale, height, phaseType, firstStage, lastStage, dataMetric } = this;

    this.renderTriangle();
    this.renderLinesAxis();
    this.renderResetBrush();

    this.buildAxisX();
    this.buildAxisY();

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage, typeMetric: metricsStatuses.GROWTH_DELTA, dataMetric });

    const axis = d3.axisBottom(this.x)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(TIME_TRANSITION)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    if (scale === typeScale.LOGARITHMIC) {
      const axis = d3.axisLeft(this.y)
        .tickFormat((d) => d3.format('')(d as number))
        .ticks(10)
        .tickSize(0)
        .tickPadding(TICK_PADDING);

      this.yAxis.transition()
        .duration(TIME_TRANSITION)
        .call(axis);
    } else {
      const axis = d3.axisLeft(this.y)
        .tickFormat((d) => d3.format('')(d as number))
        .tickSize(0)
        .tickPadding(TICK_PADDING);
      this.yAxis.transition()
        .duration(TIME_TRANSITION)
        .call(axis);
    }
  }

  showOrHideFirstLineOfAxisXTick () {
    const tickG = d3.selectAll<HTMLElement, undefined>('#axisX>.tick').nodes()[0];
    const transformProperty: string = tickG?.getAttribute('transform') || '';
    const match = transformProperty.match(/translate\(([\d.]+),[\d.]+\)/);

    if (match) {
      const xPosition = parseFloat(match[1]);
      if (xPosition === 0) {
        d3.select(tickG).select('line').style('display', 'none');
      } else {
        d3.select(tickG).select('line').style('display', 'flex');
      }
    } else {
      d3.select(tickG).select('line').style('display', 'flex');
    }
  }

  updateData () {
    this.updateAxis();
    this.createGClipPathElement();
    this.renderLines();
    this.renderMovingAverageLine();
    this.renderPoints();
    this.updateBorders();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  }

  refreshChart (props: { dataMetric: GrowthDeltaStocking[]; scale: string; firstStage: number; lastStage: number; width: number; height: number; movingAverage?: number; showMovingAverage?: boolean; colorFillRect: string; colorLine: string }) {
    const { dataMetric, colorFillRect, colorLine, scale, firstStage, lastStage, width, height, movingAverage = 2, showMovingAverage = true } = props;
    const { tooltip, container } = this;

    zoomDone = false;
    this.dataMetric = dataMetric;
    this.scale = scale;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.movingAverage = movingAverage;
    this.showMovingAverage = showMovingAverage;
    this.shouldShowAllPoints = dataMetric.length === 1 && this.chart === typesChart.STOCKINGS;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();
    d3.select(container).select('#movingAverageLine').remove();

    d3.select(container).selectAll('.labelsPoints').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);
    this.sortAllPoints();
    this.fill = getFill({ dataSource: metricsStatuses.GLOBAL });
    this.renderLineSelected();
    this.updateData();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    this.x = d3.scaleLinear()
      .domain([firstStage, lastStage])
      .range([0, width]);
  }

  buildAxisY () {
    const { scale, height } = this;
    const { maxY, minY } = this.getYDomainData();

    if (scale === typeScale.LOGARITHMIC) {
      this.y = d3.scaleSymlog()
        .domain([minY, maxY])
        .range([height, 0]);
    } else {
      this.y = d3.scaleLinear()
        .domain([minY, maxY])
        .range([height, 0]);
    }
  }

  getYDomainData = () => {
    const { parameter, dataMetric, phaseType } = this;

    const margin = getMarginArea(parameter);
    const dataY: number[] = [0.5];
    dataMetric.forEach(metric => {
      metric.data.forEach(stage => {
        dataY.push(stage.delta);
      });
    });
    let minY = 0;
    let maxY = Math.max(...dataY);

    minY = minY - margin < 0 ? 0 : minY - margin;
    maxY = maxY + margin;

    minY = getGrowthDeltaValue(phaseType, minY) as number;
    maxY = getGrowthDeltaValue(phaseType, maxY) as number;

    return {
      maxY,
      minY,
    };
  }

  drawXAxis () {
    const { height, groupMain, phaseType, firstStage, lastStage, dataMetric } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    numbersTicks = getNumberTicks({ phaseType, firstStage, lastStage, typeMetric: metricsStatuses.GROWTH_DELTA, dataMetric });

    const axis = d3.axisBottom(this.x)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();
  }

  drawYAxis () {
    const { scale, groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    if (scale === typeScale.LOGARITHMIC) {
      const axis = d3.axisLeft(this.y)
        .ticks(10)
        .tickFormat(function (d) {
          return d3.format('')(d as number);
        })
        .tickSize(0)
        .tickPadding(TICK_PADDING);

      this.yAxis = groupMain.append('g')
        .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
        .call(axis);

    } else {
      const axis = d3.axisLeft(this.y)
        .tickFormat(function (d) {
          return d3.format('')(d as number);
        })
        .ticks(10)
        .tickSize(0)
        .tickPadding(TICK_PADDING);

      this.yAxis = groupMain.append('g')
        .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
        .call(axis);
    }
  }

  sortAllPoints () {
    const { dataMetric } = this;

    const points: GrowthDeltaStage[] = [];
    dataMetric.forEach(metric => {
      metric.data.forEach(stage => {
        points.push(stage);
      });
    });

    this.allPoints = points;
    this.allPoints.sort((a: GrowthDeltaStage, b: GrowthDeltaStage) => a.stage - b.stage);
  }

  resize = (width: number, height: number) => {
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltipGrowthDelta').remove();

    this.updateSize(width, height);
    this.sortAllPoints();
    this.fill = getFill({ dataSource: metricsStatuses.GLOBAL });
    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };

  calcularMovingAverage = () => {
    const { allPoints, movingAverage: window = 2, phaseType } = this;
    const movingAverage = [];

    for (let i = window - 1; i < allPoints.length; i++) {
      let sum = 0;
      for (let j = i - window + 1; j <= i; j++) {
        sum += getGrowthDeltaValue(phaseType, allPoints[j].delta) as number;
      }
      const mean = sum / window;
      movingAverage.push({ stage: allPoints[i].stage, mean });
    }

    return movingAverage;
  };

  getMovingAverageLine = () => {
    const { x, y } = this;

    const movingAverageLine = d3.line<{ stage: number; mean: number; }>()
      .x((d) => x(d.stage))
      .y((d) => y(d.mean));

    return movingAverageLine;
  };

  renderMovingAverageLine = () => {
    const { gClipPath, showMovingAverage } = this;

    if (!showMovingAverage) {
      return;
    }

    const movingAverageLine = this.getMovingAverageLine();
    const movingAverageData = this.calcularMovingAverage();

    gClipPath.append('path')
      .datum(movingAverageData)
      .attr('id', 'movingAverageLine')
      .attr('class', styles.movingAverageLine)
      .attr('d', movingAverageLine);
  };
}

export default GrowthDeltaChart;
