
export type MoonPhase = 'NEW_MOON' | 'FIRST_QUARTER_MOON' | 'LAST_QUARTER_MOON' | 'FULL_MOON' | undefined;

export interface LunarPhaseStages {
  [key: string]: number[][];
}

export const lunarPhaseAgeRanges = {
  NEW: {
    MIN: 0,
    MAX: 7.38264692644,
  },
  FIRST_QUARTER: {
    MIN: 7.38264692644,
    MAX: 14.76529385288,
  },
  FULL: {
    MIN: 14.76529385288,
    MAX: 22.14794077932,
  },
  LAST_QUARTER: {
    MIN: 22.14794077932,
    MAX: 29.53058770576,
  },
};

export const getMoonPhase = (lunarAge: number): MoonPhase => {
  if (lunarPhaseAgeRanges.NEW.MIN <= lunarAge && lunarAge <= lunarPhaseAgeRanges.NEW.MAX) {
    return 'NEW_MOON';
  }

  if (lunarPhaseAgeRanges.FIRST_QUARTER.MIN <= lunarAge && lunarAge <= lunarPhaseAgeRanges.FIRST_QUARTER.MAX) {
    return 'FIRST_QUARTER_MOON';
  }

  if (lunarPhaseAgeRanges.LAST_QUARTER.MIN <= lunarAge && lunarAge <= lunarPhaseAgeRanges.LAST_QUARTER.MAX) {
    return 'LAST_QUARTER_MOON';
  }

  if (lunarPhaseAgeRanges.FULL.MIN <= lunarAge && lunarAge <= lunarPhaseAgeRanges.FULL.MAX) {
    return 'FULL_MOON';
  }
};

export const groupStagesByLunarPhase = (props: { lunarAge: number; minDay: number; maxDay: number }): LunarPhaseStages => {
  const { minDay, maxDay } = props;
  let { lunarAge } = props;

  const lunarPhaseStages: LunarPhaseStages = {};
  let previousMoonPhase: MoonPhase;

  for (let day = minDay; day < maxDay; day++) {
    if (lunarAge > lunarPhaseAgeRanges.LAST_QUARTER.MAX) {
      lunarAge = lunarAge % 1;
    }

    const moonPhase = getMoonPhase(lunarAge);
    if (previousMoonPhase !== moonPhase) {
      previousMoonPhase = moonPhase;
    }

    if (!moonPhase) {
      lunarAge++;
      continue;
    }

    if (!lunarPhaseStages[moonPhase]) {
      lunarPhaseStages[moonPhase] = [];
    }

    const lastPhaseRange = lunarPhaseStages[moonPhase][lunarPhaseStages[moonPhase].length - 1];
    if (lastPhaseRange && lastPhaseRange[1] === day - 1) {
      lastPhaseRange[1] = day;
    } else {
      lunarPhaseStages[moonPhase].push([day, day]);
    }
    lunarAge++;
  }

  return lunarPhaseStages;
};

export const incrementLastItem = (lunarPhaseStages: LunarPhaseStages): LunarPhaseStages => {
  const updatedLunarPhaseStages: LunarPhaseStages = {};

  for (const lunarPhase in lunarPhaseStages) {
    updatedLunarPhaseStages[lunarPhase] = lunarPhaseStages[lunarPhase].map(range => {
      const updatedRange = [...range];
      updatedRange[1]++;
      return updatedRange;
    });
  }

  return updatedLunarPhaseStages;
};