import { cloneDeep } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { Form, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';

import { Maturation } from '../Units/interfaces';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { MaturationCode } from '../Clients/interfaces';
import { filterOptionSelect } from '../../utils/select';
import { sortByName, sortTankStatus } from '../../utils/sort';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { isNumber, validateNumber } from '../../utils/validations';
import { disabledDateLarvae } from '../../helpers/stocking.helpers';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { tankStatuses, typeFetchModule, typeFetchTank, stockingCodeLength, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE, stockingStageOptions } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import * as stockingSlice from './sowingsSlice';
import { Campus, Module, StockingsInBatch, TankOfStocking } from './interfaces';

const { Option } = Select;

interface Props {
  modules: Module[];
  campuses: Campus[];
  theme?: 'dark' | 'light';
  validateFormBatch: () => void;
  stockingsInBatch: StockingsInBatch;
  setStockingsInBatch: React.Dispatch<React.SetStateAction<StockingsInBatch>>;
}

export const NewStockingLarvaeBatch = (props: Props) => {
  const { campuses, modules, theme = 'dark', validateFormBatch, stockingsInBatch, setStockingsInBatch } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [formStockingLarvaeBatch] = Form.useForm();

  const {
    showCreateModalLarvae,
    tanksForm,
    maturations,
    stockingDateRanges,
    stageOptions,
  } = useSelector((state: Store) => state.stockings);
  const { company } = useSelector((state: Store) => state.header);

  useEffect(() => {
    if (!showCreateModalLarvae || !tanksForm.length || !stockingsInBatch.moduleId) {
      return;
    }

    const tanksOfStocking: TankOfStocking[] = [];
    const tanks = cloneDeep(tanksForm).filter((tank) => tank.status === tankStatuses.AVAILABLE);

    tanks.sort(sortByName);
    tanks.sort(sortTankStatus);

    for (let i = 0; i < tanks.length; i++) {
      const tank = tanks[i];

      const tankOfStocking: TankOfStocking = {
        key: i,
        litersNumber: tank.volume,
        maturationCode: '',
        maturationId: '',
        name: '',
        naupliusNumber: 0,
        tankId: tank._id,
        tankName: tank.name,
      };

      tanksOfStocking.push(tankOfStocking);
    }

    const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
    stockingsInBatchCopy.tanks = tanksOfStocking;
    setStockingsInBatch(stockingsInBatchCopy);
  }, [showCreateModalLarvae, tanksForm]);


  useEffect(() => {
    if (!company._id || !company.daysToInitialStage) {
      return;
    }

    const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
    stockingsInBatchCopy.customStage = { key: 'NAUPLII', value: stockingStageOptions.NAUPLII };
    stockingsInBatchCopy.daysToInitialStage = company.daysToInitialStage;
    setStockingsInBatch(stockingsInBatchCopy);

    formStockingLarvaeBatch.setFieldsValue({
      customStage: 'NAUPLII',
      daysToInitialStage: company.daysToInitialStage,
    });
  }, [formStockingLarvaeBatch, company._id, company.daysToInitialStage]);
  //#endregion

  const renderRowsTanksOfStocking = () => {
    const columns: ColumnProps<TankOfStocking>[] = [
      {
        title: t('stockings.containerTypes.LARVAE'),
        dataIndex: 'tankName',
        width: '18%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <LrvText
              theme='light'
              text={record.tankName}
            />
          );
        },
      },
      {
        title: t('stockings.liters'),
        dataIndex: 'litersNumber',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <LrvInputNumber
              theme='light'
              value={record.litersNumber}
              className={styles.input}
              min={1}
              formatter={value => applyThousandsSeparator(value)}
              parser={value => applyParserThousandsSeparator(value)}
              onChange={(value) => {
                if (value == null || value == undefined || !isNumber(value)) {
                  return;
                }

                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                record.litersNumber = parseInt(value.toString());
                stockingsInBatchCopy.tanks[record.key] = record;
                setStockingsInBatch(stockingsInBatchCopy);
                validateFormBatch();
              }}
            />
          );
        },
      },
      {
        title: t('stockings.name'),
        dataIndex: 'stockingName',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <LrvInput
              theme='light'
              value={record.name || undefined}
              className={styles.input}
              placeholder={t('stockings.name')}
              onChange={(e) => {
                const value = e.target.value;
                if (value == null || value == undefined) {
                  return;
                }

                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                record.name = value;
                stockingsInBatchCopy.tanks[record.key] = record;
                setStockingsInBatch(stockingsInBatchCopy);
                validateFormBatch();
              }}
            />
          );
        },
      },
      {
        title: t('stockings.numberNauplii'),
        dataIndex: 'numberNauplii',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <LrvInputNumber
              theme='light'
              value={record.naupliusNumber}
              className={styles.input}
              min={0}
              formatter={value => applyThousandsSeparator(value)}
              parser={value => applyParserThousandsSeparator(value)}
              onChange={(value) => {
                if (value == null || value == undefined || !isNumber(value)) {
                  return;
                }

                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                record.naupliusNumber = parseInt(value.toString());
                stockingsInBatchCopy.tanks[record.key] = record;
                setStockingsInBatch(stockingsInBatchCopy);
                validateFormBatch();
              }}
            />
          );
        },
      },
      {
        title: t('stockings.maturation'),
        dataIndex: 'maturation',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <LrvSelect
              theme='light'
              id='dropdown_maturation'
              value={record.maturationId || undefined}
              className={styles.input}
              showSearch
              suffixIcon={<Icon name='arrow-down-s' />}
              placeholder={t('stockings.maturation')}
              optionFilterProp='children'
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
              onChange={(value: string) => {
                if (value == null || value == undefined) {
                  return;
                }

                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                record.maturationId = value;
                record.maturationCode = undefined;
                stockingsInBatchCopy.tanks[record.key] = record;
                setStockingsInBatch(stockingsInBatchCopy);
                validateFormBatch();
              }}
            >
              {maturations.map((maturation: Maturation, index: number) =>
                <Option key={index} value={maturation._id}>{maturation.name}</Option>
              )}
            </LrvSelect>
          );
        },
      },
      {
        title: t('stockings.maturationCode'),
        dataIndex: 'maturationCode',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: TankOfStocking) => {
          return (
            <GeneticCode
              setStockingsInBatch={setStockingsInBatch}
              stockingsInBatch={stockingsInBatch}
              tank={stockingsInBatch.tanks[record.key]}
            />
          );
        },
      },
    ];

    return (
      <Form.Item>
        <LrvTable
          theme='light'
          className={styles.table}
          columns={columns}
          dataSource={stockingsInBatch.tanks}
          size='small'
          pagination={false}
          scroll={{ y: 240 }}
        />
      </Form.Item>
    );
  };

  return (
    <LrvForm
      theme={theme}
      name='formNewStockingsInBatch'
      id='formNewStockingsInBatch'
      layout='vertical'
      form={formStockingLarvaeBatch}
      onFieldsChange={() => {
        validateFormBatch();
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='campusId'
            label={t('stockings.campus')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={stockingsInBatch?.campusId}
              onChange={(value: string) => {
                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.campusId = value;
                setStockingsInBatch(stockingsInBatchCopy);

                dispatch(stockingSlice.fetchModules(value, typeFetchModule.FORM));
              }}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              {campuses.map((campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='moduleId'
            label={t('stockings.module')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvSelect
              theme={theme}
              showSearch
              value={stockingsInBatch?.moduleId}
              onChange={(value: string) => {
                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.moduleId = value;
                setStockingsInBatch(stockingsInBatchCopy);

                dispatch(stockingSlice.fetchTanks(stockingsInBatch?.campusId, value, typeFetchTank.FORM));
              }}
              suffixIcon={<Icon name='arrow-down-s' />}
              disabled={!stockingsInBatch?.campusId}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              {modules.map((module: Module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='startDate'
            label={t('stockings.startDate')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvDatePicker
              theme={theme}
              disabledDate={(currentDate) => disabledDateLarvae({ currentDate, stockingDateRanges })}
              placeholder=''
              onChange={(date, dateString) => {
                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.startDate = dateString;
                setStockingsInBatch(stockingsInBatchCopy);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='customStage'
            label={t('stockings.customStage')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvSelect
              theme={theme}
              showSearch
              value={stockingsInBatch?.customStage}
              onChange={(key) => {
                const value = stageOptions[key];
                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.customStage = { key, value };
                setStockingsInBatch(stockingsInBatchCopy);
              }}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              {Object.entries(stageOptions).map(([key,]) => (
                <Option key={key} value={key}>{key}</Option>
              ))}
            </LrvSelect>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='code'
            label={t('stockings.productionCycle')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvInput
              theme={theme}
              value={stockingsInBatch?.code}
              style={{ textTransform: 'uppercase' }}
              maxLength={stockingCodeLength}
              onChange={(e) => {
                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.code = e.target.value;
                setStockingsInBatch(stockingsInBatchCopy);
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='daysToInitialStage'
            label={t('clients.daysToInitialStage')}
            required
            rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
          >
            <LrvInputNumber
              theme='light'
              min={MIN_DAYS_TO_INITIAL_STAGE}
              max={MAX_DAYS_TO_INITIAL_STAGE}
              value={stockingsInBatch.daysToInitialStage}
              onChange={(value) => {
                if (!value) {
                  return;
                }

                const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
                stockingsInBatchCopy.daysToInitialStage = parseInt(value.toString());
                setStockingsInBatch(stockingsInBatchCopy);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {renderRowsTanksOfStocking()}
    </LrvForm>
  );
};

const GeneticCode = (props: { tank: TankOfStocking; stockingsInBatch: StockingsInBatch; setStockingsInBatch: React.Dispatch<React.SetStateAction<StockingsInBatch>> }) => {
  const { tank, stockingsInBatch, setStockingsInBatch } = props;

  const [t] = useTranslation();
  const [geneticCodes, setGeneticCodes] = useState<MaturationCode[]>([]);

  const fetchGeneticCodes = useCallback(async () => {
    if (!tank?.maturationId) {
      return;
    }

    try {
      const codes = await stockingSlice.fetchGeneticCodes(tank.maturationId);
      if (codes) {
        setGeneticCodes(codes);
      }
    } catch (error) {
      console.log(error?.response);
    }
  }, [tank?.maturationId]);

  useEffect(() => {
    fetchGeneticCodes();
  }, [fetchGeneticCodes]);

  return (
    <LrvSelect
      theme='light'
      id='dropdown_genetic_code'
      value={tank?.maturationCode || undefined}
      className={styles.input}
      showSearch
      mode='tags'
      suffixIcon={<Icon name='arrow-down-s' />}
      placeholder={t('stockings.maturationCode')}
      optionFilterProp='children'
      filterOption={filterOptionSelect}
      dropdownMatchSelectWidth={false}
      disabled={!tank?.maturationId}
      onSelect={value => {
        const valueFound: MaturationCode | undefined = geneticCodes.find(item => item._id === value || item.code.toLowerCase() === value.toLowerCase());
        const newValue: string = valueFound?._id ?? value;

        const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
        tank.maturationCode = newValue;
        stockingsInBatchCopy.tanks[tank.key] = tank;
        setStockingsInBatch(stockingsInBatchCopy);
      }}
      onDeselect={() => {
        const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
        tank.maturationCode = undefined;
        stockingsInBatchCopy.tanks[tank.key] = tank;
        setStockingsInBatch(stockingsInBatchCopy);
      }}
    >
      {geneticCodes.map((code: MaturationCode, index: number) =>
        <Option key={index} value={code._id}>{code.code}</Option>
      )}
    </LrvSelect>
  );
};
