import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { Form, Select, Row, Col, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { sortTankStatus } from '../../utils/sort';
import { useDragger } from '../../hooks/useDragger';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { MaturationCode } from '../Clients/interfaces';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';
import { openErrorNotification } from '../../common/notification/Notification';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { isFieldsTouchedCustom, isNumber, validateNumber } from '../../utils/validations';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { disabledDateGrowOut, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { ProductionCycleInfo } from '../../common/components/ProductionCycleInfo/ProductionCycleInfo';
import { tankStatuses, typeFetchModule, typeFetchTank, stockingCodeLength, stockingPhaseTypes, containerTypes, stockingTypes, getKeyValue, THEME, maximumNumberOfStockings } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import * as stockingSlice from './sowingsSlice';
import { BindingStocking, Campus, Container, StockingGrowOut } from './interfaces';
import { BindingStockingTable, NewBindingStockingButton, ErrorLabelRow } from './BindingStocking';
import { getBindingStockingByMaxAnimals, getBindingStockingData, getTotalAnimals, initialBindingStocking, isValidStockingBinding, isValidStockingBindingRow, stockingBindingRowIsFull, defaultContainerData } from './sowings.helpers';

interface Props {
  theme?: 'dark' | 'light';
}

export const NewStockingGrowOut = (props: Props) => {
  const { theme = 'dark' } = props;
  const { Option } = Select;

  const [formNewStockingGrowOut] = Form.useForm();

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({ applyBlackWhite: true });

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    limit, skip,
    selectedCampus,
    selectedModuleId: moduleId,
    selectedTankId: tankId,
    showCreateModalGrowOut,
    campusesForm,
    modulesForm,
    tanksForm,
    maturations,
    maturationCodes,
    stockingDateRanges,
    referenceCurves,
    filters,
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow
  } = filters;

  const tanks = cloneDeep(tanksForm);
  tanks.sort(sortTankStatus);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const currentPage = ((skip / limit) + 1) || 1;
  const volumeRanges = useSelector((state: Store) => state.campus.volumeRanges);
  const { company, phaseType } = useSelector((state: Store) => state.header);

  const [disabledButtonFormNewStocking, setDisabledButtonFormNewStocking] = useState(true);

  const [newStockingNumberGrowOut, setNewStockingNumberGrowOut] = useState<number | string>(0);
  const [newStockingCodeGrowOut, setNewStockingCodeGrowOut] = useState('');
  const [newStockingNameGrowOut, setNewStockingNameGrowOut] = useState('');
  const [newStockingStartDateGrowOut, setNewStockingStartDateGrowOut] = useState('');
  const [newStockingMaturationGrowOut, setNewStockingMaturationGrowOut] = useState('');
  const [newStockingMaturationCodeGrowOut, setNewStockingMaturationCodeGrowOut] = useState<string | undefined>('');
  const [newStockingCampusGrowOut, setNewStockingCampusGrowOut] = useState('');
  const [newStockingModuleGrowOut, setNewStockingModuleGrowOut] = useState('');
  const [newStockingTankGrowOut, setNewStockingTankGrowOut] = useState('');
  const [newStockingParameter, setNewStockingParameter] = useState<string | undefined>(undefined);
  const [newStockingReference, setNewStockingReference] = useState<string | undefined>(undefined);
  const [newStockingVolumeGrowOut, setNewStockingVolumeGrowOut] = useState<number | string>(0);
  const [newStockingLabNameGrowOut, setNewStockingLabNameGrowOut] = useState('');
  const [newStockingTypeGrowOut, setNewStockingTypeGrowOut] = useState(stockingTypes.DIRECT_STOCKING);
  const [newBindingStockingsGrowOut, setNewBindingStockingsGrowOut] = useState<BindingStocking[]>([initialBindingStocking]);
  const [hasBindingStockingGrowOut, setHasBindingStockingGrowOut] = useState<boolean>(false);

  const [campusListForStockingCreation, setCampusListForStockingCreation] = useState<Campus[]>([]);
  const [containerSelected, setContainerSelected] = useState<Container>(defaultContainerData);

  const userSession = getUserSession();
  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType });

  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (!showCreateModalGrowOut) {
      return;
    }

    const currentTab = getUnitPhaseTypeFromStocking(phaseType);
    const campusesList = campusesForm.filter((campus: Campus) => campus.phaseType === currentTab);
    setCampusListForStockingCreation(campusesList);
  }, [campusesForm, showCreateModalGrowOut]);

  useEffect(() => {
    if (!showCreateModalGrowOut) {
      return;
    }

    if (containerSelected.volume !== undefined && containerSelected.volume !== 0) {
      formNewStockingGrowOut.setFieldsValue({
        measureUnitGrowOut: containerSelected.volume
      });
      setNewStockingVolumeGrowOut(containerSelected.volume);

      return;
    }

    if (containerSelected.type !== '') {
      formNewStockingGrowOut.setFieldsValue({
        measureUnitGrowOut: containerVolumeLimits[containerSelected.type].min
      });
      setNewStockingVolumeGrowOut(containerVolumeLimits[containerSelected.type].min);
    }

    // for containerVolumeLimits
    // eslint-disable-next-line
  }, [formNewStockingGrowOut, containerSelected._id, containerSelected.type, containerSelected.volume, showCreateModalGrowOut]);
  //#endregion

  //#region Commons functions
  function onChangeStockingCampusGrowOut (value: string) {
    formNewStockingGrowOut.setFieldsValue({
      campusIdGrowOut: value,
      moduleIdGrowOut: undefined,
      tankIdGrowOut: undefined,
      measureUnitGrowOut: undefined,
    });

    setNewStockingVolumeGrowOut(0);
    setNewStockingCampusGrowOut(value);
    setNewStockingModuleGrowOut('');
    setNewStockingTankGrowOut('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchModules(value, typeFetchModule.FORM));
  }

  function onChangeStockingModuleGrowOut (value: string) {
    formNewStockingGrowOut.setFieldsValue({
      moduleIdGrowOut: value,
      tankIdGrowOut: undefined,
      measureUnitGrowOut: undefined,
    });

    setNewStockingVolumeGrowOut(0);
    setNewStockingModuleGrowOut(value);
    setNewStockingTankGrowOut('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchTanks(newStockingCampusGrowOut, value, typeFetchTank.FORM));
  }

  function onChangeMaturationGrowOut (value: string) {
    formNewStockingGrowOut.setFieldsValue({
      maturationCode: undefined,
    });

    setNewStockingMaturationGrowOut(value);
    setNewStockingMaturationCodeGrowOut('');
    dispatch(stockingSlice.fetchMaturationCodes(value));
  }

  function onChangeStockingTankGrowOut (value: string) {
    setNewStockingTankGrowOut(value);

    const tank = tanks.find((tank) => tank._id === value);
    if (tank) {
      setContainerSelected(tank);
    }
  }
  //#endregion

  const closeModal = () => {
    formNewStockingGrowOut.resetFields();

    dispatch(stockingSlice.setShowCreateModalGrowOut(false));
    setNewStockingCampusGrowOut('');
    setNewStockingModuleGrowOut('');
    setNewStockingTankGrowOut('');
    setNewStockingLabNameGrowOut('');
    setNewStockingMaturationGrowOut('');
    cleanImage();
    setNewStockingTypeGrowOut(stockingTypes.DIRECT_STOCKING);
    setHasBindingStockingGrowOut(false);
    setNewBindingStockingsGrowOut([initialBindingStocking]);
    setContainerSelected(defaultContainerData);
  };

  const selectedGrowOutContainerType = (tanks.find((tank) => tank._id === newStockingTankGrowOut))?.type;

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p>{t('payments.drag')}</p>
      </div>
    );
  };

  const renderBindingStocking = () => {
    return (
      <Form.Item>
        <LrvCheckbox
          theme='light'
          checked={hasBindingStockingGrowOut}
          onChange={event => {
            setHasBindingStockingGrowOut(event.target.checked);
          }}
        >
          {t('stockings.binding')}
        </LrvCheckbox>
      </Form.Item>
    );
  };

  const validateFormAdult = () => {
    const formFields = Object.keys(formNewStockingGrowOut.getFieldsValue());
    const fieldsNotRequired = ['invoiceImg', 'labName', 'maturationCode', 'bindingCodes', 'parameter', 'referenceCurveId'];
    const validFormFields = formFields.filter((element) => !fieldsNotRequired.includes(element));
    validateCreateStockingForm(validFormFields);
  };

  const getDataStockingGrowOut = async () => {
    const newStocking: StockingGrowOut = {
      startDateGrowOut: newStockingStartDateGrowOut,
      growOutNumber: Number(newStockingNumberGrowOut),
      campusId: newStockingCampusGrowOut,
      tankId: newStockingTankGrowOut,
      name: newStockingNameGrowOut,
      code: newStockingCodeGrowOut,
      userId: userSession._id,
      maturationId: newStockingMaturationGrowOut,
      type: newStockingTypeGrowOut,
      phaseType: stockingPhaseTypes.ADULT,
      referenceCurveId: newStockingReference,
    };

    if (newStockingTypeGrowOut === stockingTypes.DIRECT_STOCKING && reducedImage) {
      newStocking.invoiceImg = reducedImage;
    }

    if (newStockingTypeGrowOut === stockingTypes.DIRECT_STOCKING && newStockingLabNameGrowOut) {
      newStocking.labName = newStockingLabNameGrowOut;
    }

    if (selectedGrowOutContainerType === containerTypes.RACEWAY) {
      newStocking.cubicMeters = Number(newStockingVolumeGrowOut);
    } else if (selectedGrowOutContainerType === containerTypes.POOL) {
      newStocking.hectares = Number(newStockingVolumeGrowOut);
    }

    if (newStockingMaturationCodeGrowOut) {
      newStocking.maturationCode = newStockingMaturationCodeGrowOut;
    }

    if (hasBindingStockingGrowOut) {
      const bindingStockings: BindingStocking[] = cloneDeep(newBindingStockingsGrowOut);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await stockingSlice.fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;

          validateFormAdult();
        }
      }

      setNewBindingStockingsGrowOut(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);

      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      newStocking.bindingStockings = bindingStockings;
      newStocking.maturationId = bindingStocking.maturationId;
      newStocking.maturationCode = bindingStocking.maturationCode;
      newStocking.growOutNumber = animales;
    }

    return newStocking;
  };

  const createStocking = async () => {
    const newStocking: StockingGrowOut | undefined = await getDataStockingGrowOut();

    if (newStocking) {
      const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId, tankId, page: currentPage, stockingsToShow, phaseType: phaseType };
      dispatch(stockingSlice.createStocking({ stockingData: newStocking, paramsToFetchStocking, closeModal }));
    }

    if (isRunningOnboarding) {
      dispatch(goToOnboardingNextStep(2000));
    }
  };

  const renderBindingCodeWarning = () => {
    if (!hasBindingStockingGrowOut) {
      return null;
    }

    return (
      <Form.Item>
        <TinyLabel className={styles.warning} text={t('stockings.bindingCodeWarning')} />
      </Form.Item>
    );
  };

  const renderBindingStockingRows = () => {
    const columns: ColumnsType<BindingStocking> = [
      {
        title: t('stockings.bindingCode'),
        dataIndex: 'bindingCode',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInput
                theme='light'
                value={record.bindingCode}
                className={styles.input}
                maxLength={5}
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == undefined) {
                    return;
                  }

                  onChangeBindingCodeGrowOut(record, e.target.value);
                }}
              />

              <ErrorLabelRow hasError={record.validatedCode && !record.isValid} />
            </>
          );
        },
      },
      {
        title: t('stockings.sownAnimals'),
        dataIndex: 'animals',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.animals}
                className={styles.input}
                min={0}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  onChangeAnimalsGrowOut(record, value.toString());
                }}
              />
              <ErrorLabelRow />
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: 'remove',
        width: '10%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <Space className={styles.bindingCode} size='small'>
                <LrvTooltip
                  themeStyle={isLightTheme ? 'dark' : 'light'}
                  title={t('stockings.delete')}
                >
                  <ActionButton
                    type='text'
                    icon={<Icon name='delete-bin' type='line' theme={theme} />}
                    onClick={() => {
                      onDeleteGrowOutRow(record.key);
                    }}
                  />
                </LrvTooltip>
              </Space>
              <ErrorLabelRow />
            </>
          );
        },
      },
    ];

    return (
      <BindingStockingTable columns={columns} dataSource={getDataBindingStocking()} />
    );
  };

  const validateCreateStockingForm = (validFormFields: string[]) => {
    setDisabledButtonFormNewStocking(
      !isFieldsTouchedCustom(formNewStockingGrowOut, validFormFields) ||
      formNewStockingGrowOut.getFieldsError().filter(({ errors }) => errors.length).length > 0
    );
  };

  const onChangeBindingCodeGrowOut = (record: BindingStocking, bindingCode: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsGrowOut);
    record.isValid = false;
    record.bindingCode = bindingCode;
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsGrowOut(bindingStockingCopy);
  };

  const onChangeAnimalsGrowOut = (record: BindingStocking, value: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsGrowOut);
    record.isValid = false;
    record.animals = parseInt(value);
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsGrowOut(bindingStockingCopy);
  };

  const onDeleteGrowOutRow = (key: number) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsGrowOut);
    const newBindingStocking = bindingStockingCopy.filter((item, index) => index !== key);

    if (newBindingStocking.length === 0) {
      newBindingStocking.push(initialBindingStocking);
    }

    setNewBindingStockingsGrowOut(newBindingStocking);
  };

  const getDataBindingStocking = () => {
    return getBindingStockingData(newBindingStockingsGrowOut);
  };

  const getBindingStocking = () => {
    return newBindingStockingsGrowOut;
  };

  const addRowBindingStockingGrowOut = () => {
    if (newBindingStockingsGrowOut.length >= maximumNumberOfStockings) {
      openErrorNotification(t('stockings.maximumNumberOfStockings'));
      return;
    }

    const data: BindingStocking[] = [...newBindingStockingsGrowOut];
    data.push(initialBindingStocking);
    setNewBindingStockingsGrowOut(data);
  };

  const renderNewBindingStockingButton = () => {
    return (
      <NewBindingStockingButton
        theme={theme}
        bindingStockings={getBindingStocking()}
        onClick={() => {
          if (stockingBindingRowIsFull(newBindingStockingsGrowOut)) {
            addRowBindingStockingGrowOut();
          }
        }}
      />
    );
  };

  const onChangeStockingParameter = (value: string) => {
    formNewStockingGrowOut.setFieldsValue({
      parameter: value,
      referenceCurveId: undefined,
    });

    setNewStockingParameter(value);
    setNewStockingReference(undefined);
    dispatch(stockingSlice.fetchReferenceCurves({ parameter: value, phaseType, companyId: company._id }));
  };

  const onChangeStockingReference = (value: string) => {
    formNewStockingGrowOut.setFieldsValue({
      referenceCurveId: value,
    });

    setNewStockingReference(value);
  };

  const renderReferenceCurve = () => {
    return (
      <Row gutter={16} className={styles.referenceCurve}>
        <Col span={24}>
          <Form.Item className={styles.formItem}>
            <LrvText
              className={styles.text}
              theme='light'
              text={t('stockings.referenceDescription')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='parameter'
            label={`${t('production.filters.parameter')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingParameter}
              onChange={onChangeStockingParameter}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
              <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
              <Option value={typeParam.UNIFORMITY}>{t('analysis.resultData.uniformity')}</Option>
            </LrvSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='referenceCurveId'
            label={`${t('stockings.reference')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingReference}
              onChange={onChangeStockingReference}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
              disabled={!newStockingParameter}
            >
              {referenceCurves.map((reference) => <Option key={reference._id} value={reference._id}>{reference.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <LrvModal
      title={t('stockings.newStocking')}
      theme={theme}
      className={cx(styles.newStockingModal, 'newStockingModal')}
      open={showCreateModalGrowOut}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_new_stocking', disabled: disabledButtonFormNewStocking, htmlType: 'submit', form: 'formNewStocking' }}
      cancelButtonProps={{ id: 'cancel_new_stocking' }}
      onOk={createStocking}
      okText={t('stockings.create')}
      cancelText={t('stockings.cancel')}
      onCancel={() => {
        closeModal();
      }}
    >
      <LrvForm
        theme={theme}
        form={formNewStockingGrowOut}
        name='formNewStockingGrowOut'
        id='formNewStockingGrowOut'
        layout='vertical'
        onFieldsChange={() => {
          validateFormAdult();
        }}
      >
        {renderBindingStocking()}

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='campusIdGrowOut'
              label={t('stockings.campus')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                autoFocus
                showSearch
                value={newStockingCampusGrowOut}
                onChange={onChangeStockingCampusGrowOut}
                suffixIcon={<Icon name='arrow-down-s' />}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {campusListForStockingCreation.map((campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
              </LrvSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='moduleIdGrowOut'
              label={t('stockings.module')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                showSearch
                value={newStockingModuleGrowOut}
                onChange={onChangeStockingModuleGrowOut}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newStockingCampusGrowOut}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {modulesForm.map((module) => {
                  if (module.phaseType === phaseType) {
                    return <Option key={module._id} value={module._id}>{module.name}</Option>;
                  }
                })}
              </LrvSelect>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='tankIdGrowOut'
              label={t('stockings.containerTypes.ADULT')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                showSearch
                value={newStockingTankGrowOut}
                onChange={onChangeStockingTankGrowOut}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newStockingModuleGrowOut}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {tanks.map((tank) => <Option disabled={tank.status !== tankStatuses.AVAILABLE} key={tank._id} value={tank._id}>{tank.name}</Option>)}
              </LrvSelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='codeGrowOut'
              label={<ProductionCycleInfo tankId={newStockingTankGrowOut} label={t('stockings.productionCycle')} />}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInput
                theme={theme}
                value={newStockingCodeGrowOut}
                style={{ textTransform: 'uppercase' }}
                maxLength={stockingCodeLength}
                onChange={(e) => setNewStockingCodeGrowOut(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='nameGrowOut'
              label={t('stockings.name')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvInput
                theme={theme}
                value={newStockingNameGrowOut}
                onChange={(e) => setNewStockingNameGrowOut(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name='startDateGrowOut'
              label={t('stockings.startDateGrowOut')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvDatePicker
                theme={theme}
                disabledDate={(currentDate) => disabledDateGrowOut({ currentDate, stockingDateRanges })}
                placeholder=''
                onChange={(date, dateString) => setNewStockingStartDateGrowOut(dateString)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name='measureUnitGrowOut'
              label={selectedGrowOutContainerType === containerTypes.RACEWAY ? t('stockings.cubicMeters') : selectedGrowOutContainerType === containerTypes.POOL ? t('stockings.hectares') : t('stockings.defaultMeasureAdult')}
              required
              rules={
                containerSelected.type === '' ?
                  [() => ({ validator (rule, value) { return validateNumber(value, false, 0); } })] :
                  [() => ({ validator (rule, value) { return validateNumber(value, false, containerVolumeLimits[containerSelected.type].min, containerVolumeLimits[containerSelected.type].max, t('stockings.volumeError', { min: containerVolumeLimits[containerSelected.type].min, max: containerVolumeLimits[containerSelected.type].max })); } })]
              }
            >
              <LrvInputNumber
                theme={theme}
                value={newStockingVolumeGrowOut}
                onChange={(value) => {
                  if (value) {
                    setNewStockingVolumeGrowOut(value);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='type'
              label={t('stockings.type')}
              rules={[{ required: true, message: t('common.requiredField') }]}
            >
              <LrvSelect
                theme={theme}
                id='dropdown_types'
                showSearch
                className={styles.select}
                suffixIcon={<Icon name='arrow-down-s' />}
                placeholder={t('stockings.type')}
                optionFilterProp='children'
                value={newStockingTypeGrowOut}
                onChange={(value) => setNewStockingTypeGrowOut(value)}
                filterOption={filterOptionSelect}
                dropdownMatchSelectWidth={false}
              >
                {
                  Object.keys(stockingTypes).map((key, index) => {
                    const typedKey = key as 'TRANSFER' | 'DIRECT_STOCKING';
                    return (
                      <Option key={index} value={getKeyValue(stockingTypes, typedKey)}>{t(`stockings.stockingTypes.${typedKey}`)}</Option>
                    );
                  })
                }
              </LrvSelect>
            </Form.Item>
          </Col>
        </Row>

        {
          !hasBindingStockingGrowOut &&
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='maturationGrowOut'
                label={t('stockings.maturation')}
                rules={[{ required: true, message: t('common.requiredField') }]}
              >
                <LrvSelect
                  theme={theme}
                  showSearch
                  value={newStockingMaturationGrowOut}
                  onChange={onChangeMaturationGrowOut}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {maturations.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.name}</Option>)}
                </LrvSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name='maturationCode'
                label={`${t('stockings.maturationCode')} (${t('common.optional')})`}
              >
                <LrvSelect
                  theme={theme}
                  showSearch
                  mode='tags'
                  disabled={!newStockingMaturationGrowOut}
                  value={newStockingMaturationCodeGrowOut || undefined}
                  onSelect={value => {
                    const valueFound: MaturationCode | undefined = maturationCodes.find(item => item._id === value || item.code.toLowerCase() === value.toLowerCase());
                    const newValue: string = valueFound?._id ?? value;

                    setNewStockingMaturationCodeGrowOut(newValue);
                    formNewStockingGrowOut.setFieldsValue({ maturationCode: newValue });
                  }}
                  onDeselect={() => {
                    setNewStockingMaturationCodeGrowOut(undefined);
                    formNewStockingGrowOut.setFieldsValue({ maturationCode: undefined });
                  }}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                  optionFilterProp='children'
                >
                  {maturationCodes && maturationCodes.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>)}
                </LrvSelect>
              </Form.Item>
            </Col>
          </Row>
        }

        {
          !hasBindingStockingGrowOut &&
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='numberGrowOut'
                label={t('stockings.numberGrowOut')}
                required
                rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
              >
                <LrvInputNumber
                  theme={theme}
                  value={newStockingNumberGrowOut}
                  formatter={value => applyThousandsSeparator(value)}
                  parser={value => applyParserThousandsSeparator(value)}
                  onChange={(value) => {
                    if (value) {
                      setNewStockingNumberGrowOut(value);
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        }

        {
          newStockingTypeGrowOut === stockingTypes.DIRECT_STOCKING &&
          <Col span={12}>
            <Form.Item
              name='labName'
              label={`${t('stockings.labName')} (${t('common.optional')})`}
            >
              <LrvInput
                theme={theme}
                value={newStockingLabNameGrowOut}
                onChange={(e) => setNewStockingLabNameGrowOut(e.target.value)}
              />
            </Form.Item>
          </Col>
        }

        {
          hasBindingStockingGrowOut &&
          <>
            {renderBindingStockingRows()}
            {renderNewBindingStockingButton()}
          </>
        }

        {renderReferenceCurve()}

        {newStockingTypeGrowOut === stockingTypes.DIRECT_STOCKING &&
          <Form.Item
            name='invoiceImg'
            rules={[{ required: false }]}
            label={`${t('stockings.juvenileReferralGuide')} (${t('common.optional')})`}
          >
            <div id='juvenile_referral_guide'>
              <LrvDragger
                theme={theme}
                name='file'
                accept='image/png, image/jpeg'
                maxCount={1}
                listType='picture-card'
                showUploadList={false}
                beforeUpload={() => true}
                onChange={handleOnChange}
                customRequest={handleCustomRequest}
              >
                {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
              </LrvDragger>

              <canvas className={styles.canvasInvoice} ref={canvasRef} width={voucherWidth} height={voucherHeight} id='canvas' />
            </div>
          </Form.Item>
        }

        {renderBindingCodeWarning()}
      </LrvForm>
    </LrvModal >
  );
};
