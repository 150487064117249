import cx from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Row, Space } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { formatLongDateWithOffset } from '../../utils/date';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { isConsolidatedAnalysis } from '../Analysis/helpers';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { hasSameAnalysis } from '../../helpers/analysis.helpers';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { analysisPatch, analysisStatuses, THEME } from '../../config/commons';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';

import './AnalysisWithoutStocking.scss';
import { Analysis } from './interfaces';
import AssignStocking from './AssignStocking';
import styles from './AnalysisWithoutStocking.module.scss';
import * as analysisWithoutStockingSlice from './analysisWithoutStockingSlice';

export default function AnalysisWithoutStocking () {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    currentPage,
    limit,
    total,
    isLoading,
    analysis,
  } = useSelector((state: Store) => state.analysisWithoutStocking);

  const {
    company,
    phaseType,
  } = useSelector((state: Store) => state.header);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const [consolidatedAnalysisSelected, setConsolidatedAnalysisSelected] = useState<{ _id: string; analysisIds: string[] }>({ _id: '', analysisIds: [] });

  useEffect(() => {
    dispatch(changeHeader({ title: 'analysisWithoutStocking.header' }));
  }, [dispatch]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    dispatch(analysisWithoutStockingSlice.fetchAnalysis({ page: currentPage, stockingPhaseType: phaseType, companyId: company._id }));
  }, [dispatch, currentPage, phaseType, company._id]);

  const onChangePage = (page: number) => {
    dispatch(analysisWithoutStockingSlice.setCurrentPage(page));
    dispatch(analysisWithoutStockingSlice.fetchAnalysis({ page, stockingPhaseType: phaseType, companyId: company._id }));
  };

  const renderDropdownAnalysisOption = (record: Analysis) => {
    return (
      <Dropdown
        overlay={() => menuItemAnalysisOption(record)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record.code}`}
          className={consolidatedAnalysisSelected._id === record._id || consolidatedAnalysisSelected.analysisIds.includes(record._id) ? styles.icon : ''}
        />
      </Dropdown>
    );
  };

  function showConfirmDesconsolidateAnalysis (record: Analysis) {
    const title = t('analysis.deconsolidateConfirm.title');
    const content = t('analysis.deconsolidateConfirm.description');
    const idOkButton = 'submit_desconsolidate';
    const idCancelButton = 'cancel_desconsolidate';

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        dispatch(analysisWithoutStockingSlice.desconsolidateAnalysis({ analysisId: record._id, stockingPhaseType: phaseType, currentPage, companyId: company._id }));
      },
    });
  }

  const menuItemAnalysisOption = (record: Analysis) => {
    return (
      // eslint-disable-next-line
      <Menu onClick={({ item, key, domEvent }) => {
        domEvent.stopPropagation();
        if (key === analysisPatch.WITHOUT_STOCKING) {
          dispatch(analysisWithoutStockingSlice.setAnalysisSelected(record));
          dispatch(analysisWithoutStockingSlice.setShowModal(true));
        }

        if (key === analysisPatch.DESCONSOLIDATE) {
          showConfirmDesconsolidateAnalysis(record);
        }
      }}>
        <Menu.Item id='menu_option_assign' key={analysisPatch.WITHOUT_STOCKING} className={styles.menuItemOptions}>
          <span>{t('analysisWithoutStocking.assignStocking')}</span>
        </Menu.Item>
        {isConsolidatedAnalysis(record.type) && <Menu.Item id='menu_option_desconsolidate' key={analysisPatch.DESCONSOLIDATE} className={styles.menuItemOptions}>
          <span>{t('analysis.deconsolidate')}</span>
        </Menu.Item>}
      </Menu>
    );
  };

  const updateConsolidatedAnalysis = (analysis: Analysis) => {
    if (hasSameAnalysis(consolidatedAnalysisSelected.analysisIds, analysis.analysisIds)) {
      setConsolidatedAnalysisSelected({ _id: '', analysisIds: [] });
      return;
    }

    setConsolidatedAnalysisSelected({ _id: analysis._id, analysisIds: analysis.analysisIds });
  };

  const renderTagsColumn = (record: Analysis) => {
    const { code, type } = record;
    const hasError = record.status === analysisStatuses.ERROR;
    if (hasError) {
      return <LrvTag type='error'>{t('analysis.error')}</LrvTag>;
    }

    if (isConsolidatedAnalysis(type)) {
      return (
        <>
          <LrvTag id={`consolidated_${code}`} className={styles.consolidated}>{t('analysis.consolidated')}</LrvTag>
          <ExtraActionsButton
            id={`consolidated_analysis_${code}`}
            className={styles.eyeIcon}
            onClick={(e) => {
              e.stopPropagation();
              updateConsolidatedAnalysis(record);
            }}
            icon={
              <Icon
                name={consolidatedAnalysisSelected._id === record._id ? 'eye' : 'eye-off'}
                theme={theme}
                className={consolidatedAnalysisSelected._id === record._id ? styles.icon : ''}
              />
            }
          />
        </>
      );
    }

    return null;
  };

  const columns: ColumnsType<Analysis> = [
    {
      key: 1,
      title: t('analysisWithoutStocking.table.code'),
      dataIndex: 'code',
      width: '30%',
    },
    {
      key: 2,
      title: t('analysisWithoutStocking.table.sampleWeight'),
      dataIndex: ['inputData', 'sampleWeight'],
      width: '12%',
      responsive: ['md'] as Breakpoint[],
      render: (value: number) => value ? `${value} g` : '',
    },
    {
      key: 3,
      title: t('analysisWithoutStocking.table.stage'),
      dataIndex: ['inputData', 'stage'],
      width: '12%',
      responsive: ['sm'] as Breakpoint[],
    },
    {
      key: 4,
      title: t('analysisWithoutStocking.table.animals'),
      dataIndex: ['resultData', 'larvaeNumber'],
      width: '10%',
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 5,
      title: t('analysisWithoutStocking.table.date'),
      dataIndex: 'createdAt',
      width: '15%',
      responsive: ['sm'] as Breakpoint[],
      render: (value: string) => formatLongDateWithOffset(value),
    },
    {
      key: 6,
      width: '15%',
      render: (_, record: Analysis) => {
        return (
          <Space className={styles.tagsCell} align='center' size={8}>
            {renderTagsColumn(record)}
            {renderDropdownAnalysisOption(record)}
          </Space>
        );
      }
    }
  ];

  return (
    <div className={styles.analysisWithoutStocking} >
      <Row className='analysisWithoutStockingSection' >
        <LrvTable
          rowClassName={(record: Analysis) => {
            if (consolidatedAnalysisSelected._id === record._id) {
              return cx(styles.analysisRow, styles.selectedRow, isLightTheme ? styles.selectedRowLight : styles.selectedRowDark);
            }

            if (consolidatedAnalysisSelected.analysisIds.includes(record._id)) {
              return cx(styles.analysisRow, styles.showAnalysis, isLightTheme ? styles.showAnalysisLight : styles.showAnalysisDark);
            }

            return styles.analysisRow;
          }}
          className={styles.table}
          columns={columns}
          dataSource={analysis}
          loading={isLoading}
          scroll={{ y: '' }}
          size='small'
          theme={theme}
          onRow={(record: Analysis) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/production/analysis/${record._id}`);
              },
            };
          }}
          pagination={{
            size: 'default',
            defaultPageSize: limit,
            pageSize: limit,
            total: total,
            onChange: onChangePage,
            showSizeChanger: false,
            current: currentPage
          }}
        />
      </Row>

      <AssignStocking theme='light' />
    </div>
  );
}