import axios, { AxiosInstance } from 'axios';

export class ApiClient {
  axios: AxiosInstance;

  // eslint-disable-next-line
  constructor(addToken = true, key = 'accessToken') {
    this.axios = axios.create();

    this.axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem(key);

        if (addToken && token) {
          config.headers['Authorization'] = token;
        }

        return config;
      }, error => {
        return Promise.reject(error);
      }
    );
  }
}

const apiClient: ApiClient = new ApiClient();
export const axiosClient = apiClient.axios;