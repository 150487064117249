import React from 'react';
import { Form, Input, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { PATTERN } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import { ReactComponent as Logo } from '../../assets/larvia-dark.svg';

import styles from './PasswordReset.module.scss';
import { PasswordResetData } from './interfaces';
import { passwordReset, renewPassword } from './passwordSlice';

type TParams = { accessToken: string };

export default function ForgotPassword ({ match }: RouteComponentProps<TParams>) {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const isSuccessful: boolean | null = useSelector((state: Store) => state.password.isResetSuccessful);
  const isRenewPasswordPath = window.location.pathname.includes('/password/renew');
  const { accessToken } = match.params;

  return (
    <div className={styles.passwordResetWrapper}>
      <div className={styles.passwordResetFormWrapper}>
        <div className={styles.passwordResetForm}>
          <div className={styles.passwordResetLogo}><Logo /></div>
          <div className={styles.passwordResetFormTitle}>
            {isRenewPasswordPath ? t('password.renew.renew') : t('password.reset.reset')}
          </div>
          {renderForm({ isSuccessful, dispatch, t, accessToken, isRenewPasswordPath })}
        </div>
      </div>
    </div>
  );
}

function renderForm (props: { isSuccessful: boolean | null; dispatch: Function; t: Function; accessToken: string; isRenewPasswordPath: boolean }) {
  const { isSuccessful, dispatch, t, accessToken, isRenewPasswordPath } = props;

  if (isSuccessful === false) {
    return <Form
      name="normal_login"
      className="login-form"
    >
      <Form.Item
        name="text"
        className={styles.formItem}
      >
        {t('password.reset.errorText')}
      </Form.Item>

      <Form.Item>
        <div className={styles.formOptions}>
          <a className={styles.forgotPassword} href="/login">
            {t('password.reset.signIn')}
          </a>
        </div>
      </Form.Item>
    </Form>;
  }

  if (isSuccessful === true) {
    return <Form
      name="normal_login"
      className="login-form"
    >
      <Form.Item
        name="text"
        className={styles.formItem}
      >
        {isRenewPasswordPath ? t('password.renew.successText') : t('password.reset.sucessText')}
      </Form.Item>

      <Form.Item>
        <div className={styles.formOptions}>
          <a className={styles.forgotPassword} href="/login">
            {t('password.reset.signIn')}
          </a>
        </div>
      </Form.Item>
    </Form>;
  }

  return <Form
    name="normal_login"
    className="login-form"
    onFinish={(data) => {
      if (isRenewPasswordPath) {
        const { newPassword } = data;
        dispatch(renewPassword({ newPassword, accessToken }));
        return;
      }
      dispatch(passwordReset(data as PasswordResetData));
    }}
  >
    <Form.Item
      name="text"
      className={styles.formItem}
    >
      {isRenewPasswordPath && t('password.renew.descriptionText')}
      {isRenewPasswordPath && <><br /><br /></>}
      {isRenewPasswordPath ? t('password.renew.passwordPolicy') : t('password.reset.descriptionText')}
      <br /><br />
      <ul>
        <li>{t('password.reset.policy1')}</li>
        <li>{t('password.reset.policy2')}</li>
        <li>{t('password.reset.policy3')}</li>
      </ul>
    </Form.Item>

    <Form.Item
      name="newPassword"
      rules={[{ required: true, pattern: PATTERN.PASSWORD, message: t('password.reset.passwordError') }]}
      className={styles.formItem}
    >
      <Input.Password
        size="large"
        prefix={<LockOutlined className={styles.loginIcon} />}
        type="password"
        autoFocus
        placeholder={t('password.reset.newPassword')}
      />
    </Form.Item>

    <Form.Item
      name="confirmPassword"
      rules={
        [
          { required: true, pattern: PATTERN.PASSWORD, message: t('password.reset.passwordError') },
          ({ getFieldValue }) => ({
            validator (rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(t('password.reset.matchError'));
            },
          }),
        ]
      }
      className={styles.formItem}
    >
      <Input.Password
        size="large"
        prefix={<LockOutlined className={styles.loginIcon} />}
        type="password"
        placeholder={t('password.reset.confirmPassword')}
      />
    </Form.Item>

    <Form.Item>
      <div className={styles.formOptions}>
        <Form.Item>
          <Button
            className={styles.submitButton}
            type="primary"
            htmlType="submit"
          >
            {isRenewPasswordPath ? t('password.renew.renew') : t('password.reset.reset')}
          </Button>
        </Form.Item>

        {!isRenewPasswordPath && <a className={styles.forgotPassword} href="/login">
          {t('password.reset.signIn')}
        </a>}
      </div>
    </Form.Item>
  </Form>;
}