import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { history } from '../../../helpers/history';
import { LrvResult } from '../LrvResult/LrvResult';

import styles from './UnauthorizedPage.module.scss';

export default function UnauthorizedPage () {
  const [t] = useTranslation();

  return <LrvResult
    className={styles.container}
    status='403'
    title='403'
    subTitle={t('unauthorizedPage.subTitle')}
    extra={<Button type='primary' onClick={() => history.push('/')}>{t('unauthorizedPage.action')}</Button>}
  />;

}



