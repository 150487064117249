import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { history } from '../../../helpers/history';
import { LrvResult } from '../LrvResult/LrvResult';

export default function NotFoundPage () {
  const [t] = useTranslation();

  return <LrvResult
    status='404'
    title='404'
    subTitle={t('notFoundPage.subTitle')}
    extra={<Button type='primary' onClick={() => history.push('/')}>{t('notFoundPage.action')}</Button>}
  />;

}



