import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Tooltip, Input, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React, { ReactNode, useEffect, useState } from 'react';

import { relaunchAnalysis } from '../sowingSlice';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { validatePIN } from '../../../utils/validations';
import { formatLongDateWithZone } from '../../../utils/date';
import { GenericParam } from '../../../common/interfaces/commons';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { ENVIRONMENT, SHARE_ANALYSES } from '../../../config/config.api';
import { LrvSwitch } from '../../../common/components/LrvSwitch/LrvSwitch';
import { LrvButton } from '../../../common/components/LrvButton/LrvButton';
import { LrvDivider } from '../../../common/components/LrvDivider/LrvDivider';
import { LrvPassword } from '../../../common/components/LrvPassword/LrvPassword';
import { isConsolidatedAnalysis, isJuvenileOrGrowOutTypeAnalysis } from '../helpers';
import { LrvPagination } from '../../../common/components/LrvPagination/LrvPagination';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { animalDaysUnit, DATE_FORMATS, environments, isSuperAdmin } from '../../../config/commons';

import { DataToShare } from './interfaces';
import styles from './AnalysisOptions.module.scss';
import ExtraInformationModal from './ExtraInformationModal';
import { fetchShareAnalysis, fetchUrlAnalysisDetailPdf, resetAnalysis, resetShareAnalysis, setShowExtraInformationModal, storeSettings, updateSettings } from './detailAnalysisSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function AnalysisOptions (props: Props) {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formSettingsLink] = Form.useForm();

  const [pin, setPin] = useState('');
  const [title, setTitle] = useState('');
  const [copy, setCopy] = useState(t('share.copy'));

  const [linkExpired, setLinkExpired] = useState(false);
  const [validatePin, setValidatePin] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isPublicLink, setIsPublicLink] = useState(false);
  const [expireDate, setExpireDate] = useState<string | undefined>();
  const [showSettingsLinkModal, setShowSettingsLinkModal] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const showPagination = useSelector((state: Store) => state.header.showPagination);
  const showIconSetting = useSelector((state: Store) => state.header.showIconSetting);
  const showIconPdf = useSelector((state: Store) => state.header.showIconPdf);

  const {
    analysis: analysisOriginal, listAnalysisPaginate, isDownloadingFile,
    total, limit, skip, bin, hasPublicLink, share: shareAnalysis
  } = useSelector((state: Store) => state.detailAnalysis);

  const analysisId = showIconSetting.analysisId;
  const analysisCode = analysisOriginal.code;
  const analysisPhaseType = analysisOriginal.phaseType;
  const stocking = analysisOriginal.sowingId;
  const analysisIsArchived = analysisOriginal.isArchived;
  const stockingIsArchived = stocking?.isArchived;
  const analysisType = analysisOriginal.type;

  const urlAnalysis = `${SHARE_ANALYSES}/${showIconSetting.analysisId}`;

  useEffect(() => {
    if (shareAnalysis._id === '') {
      return;
    }

    let _pin = '';
    let _title = '';
    let _expireDate: string | undefined;

    if (shareAnalysis.title) {
      _title = shareAnalysis.title;
    }

    if (shareAnalysis.pin) {
      _pin = shareAnalysis.pin;
      setValidatePin(true);
    }

    if (shareAnalysis.expireDate) {
      const currentDate = moment();
      const date = formatLongDateWithZone(shareAnalysis.expireDate);
      _expireDate = date;

      setLinkExpired(currentDate > moment(date).add(1, 'd'));
    }

    setPin(_pin);
    setTitle(_title);
    setExpireDate(_expireDate);
    formSettingsLink.setFieldsValue({ pin: _pin, expireDate: _expireDate, title: _title });
    setIsPublicLink(hasPublicLink);
  }, [formSettingsLink, shareAnalysis, hasPublicLink]);

  function resetFormSetting () {
    dispatch(resetShareAnalysis());
    formSettingsLink.resetFields();
    setShowSettingsLinkModal(false);
    setLinkExpired(false);
    setValidatePin(false);
    setIsPublicLink(false);
    setPin('');
    setTitle('');
    setExpireDate(undefined);
  }

  function onChangePage (page: number) {
    dispatch(resetAnalysis());
    const id = listAnalysisPaginate[page]._id;
    history.push(`/production/analysis/${id}`);
  }

  const itemPaginateRender = (page: number, type: string, originalElement: ReactNode) => {
    if (type === 'prev') {
      return <Icon id='previous_analysis' name='arrow-left-s' className={styles.arrow} />;
    }

    if (type === 'next') {
      return <Icon id='next_analysis' name='arrow-right-s' className={styles.arrow} />;
    }

    if (type !== 'page') {
      return originalElement;
    }

    const analysisPaginate = listAnalysisPaginate[page - 1];

    if (!analysisPaginate) {
      return originalElement;
    }

    if (isJuvenileOrGrowOutTypeAnalysis(analysisPhaseType)) {
      const days = analysisPaginate.inputData.stage;
      const analysisType = analysisPaginate.type;
      const stageLabel = `${days}${animalDaysUnit.D}`;
      const stageTooltip = `${days} ${t('detail.days')}`;

      if (isConsolidatedAnalysis(analysisType)) {
        return (
          <a title={stageTooltip} className={styles.itemPaginate}>
            <div className={styles.days}>
              {stageLabel}
            </div>
            <div className={styles.consolidated}>
              cons.
            </div>
          </a>
        );
      }

      return <a title={stageTooltip}>{stageLabel}</a>;
    }

    const stageLabel = `${animalDaysUnit.PL}${analysisPaginate?.inputData?.stage}`;
    const stageTooltip = stageLabel;

    return <a title={stageTooltip}>{stageLabel}</a>;
  };

  const renderPagination = () => {
    if (!showPagination || listAnalysisPaginate.length === 0) {
      return null;
    }

    return <div id='pagination_analysis_detail'>
      <LrvPagination
        defaultPageSize={limit}
        pageSize={limit}
        onChange={(value) => onChangePage(value - 1)}
        total={total}
        current={skip + 1}
        itemRender={itemPaginateRender}
        theme={theme}
        showSizeChanger={false}
      />
    </div>;
  };

  function renderOptionSetting () {
    if (!showIconSetting.show) {
      return null;
    }

    return (
      <IconButton
        id='share_analysis_button'
        onClick={() => {
          dispatch(fetchShareAnalysis({ analysisId }));
          setShowSettingsLinkModal(true);
        }}
        iconName='share'
        tooltipText={t('share.setting')}
        placement='top'
      />
    );
  }

  const renderInfoIcon = () => {
    if (!showIconSetting.show) {
      return null;
    }

    return (
      <IconButton
        id='info_icon'
        onClick={() => {
          dispatch(setShowExtraInformationModal(true));
        }}
        iconName='information'
        tooltipText={t('detail.extraInformation.title')}
        placement='top'
      />
    );
  };

  function renderOptionRelaunch () {
    if (isSuperAdmin() && ENVIRONMENT === environments.DEV && analysisId && !isConsolidatedAnalysis(analysisType)) {
      return (
        <IconButton
          id='relaunch_button'
          onClick={() => {
            dispatch(relaunchAnalysis(analysisOriginal.code, { analysisId: analysisId }));
          }}
          icon={<Icon className='superAdminAction' name='rocket' type='fill' theme={theme} />}
          tooltipText={t('analysis.relaunch')}
          placement='top'
        />
      );
    }

    return null;
  }

  function disabledDate (currentDate: GenericParam) {
    return currentDate && currentDate < moment().subtract(1, 'd');
  }

  const renderSettingInputs = () => {
    if (isPublicLink) {
      const checkPin = validatePin || pin.length > 0;

      return <div>
        <Form.Item
          name='title'
          label={<span>
            {t('share.title')}&nbsp;
            <Tooltip id='title_tooltip' title={t('share.information.title')}>
              <QuestionCircleOutlined id='title_question_icon' />
            </Tooltip>
          </span>}>

          <LrvInput
            theme='light'
            value={title}
            autoComplete='off'
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name='pin'
          label={<span>
            {t('share.pin')}&nbsp;
            <Tooltip id='pin_tooltip' title={t('share.information.pin')}>
              <QuestionCircleOutlined id='pin_question_icon' />
            </Tooltip>
          </span>}
          rules={[(checkPin) ? () => ({
            validator (rule, value) {
              return validatePIN(value, checkPin);
            }
          }) : {}]}
        >
          <LrvPassword
            theme='light'
            value={pin}
            autoComplete='new-password'
            maxLength={4}
            onClick={() => setValidatePin(true)}
            onChange={(e) => {
              setPin(e.target.value);
              setValidatePin(true);
            }}
          />
        </Form.Item>

        <Form.Item
          name='expireDate'
          valuePropName='expireDate'
          className={styles.expireFormItem}
          label={<div className={styles.expireDateLabel}>
            <div>
              {t('share.expireDate')}&nbsp;
              <Tooltip id='expire_date_tooltip' title={t('share.information.expireDate')}>
                <QuestionCircleOutlined id='expire_date_question_icon' />
              </Tooltip>
            </div>
            {linkExpired && <LrvTag type='info'>{t('share.shareExpired')}</LrvTag>}
          </div>}
        >
          <LrvDatePicker
            theme='light'
            disabledDate={disabledDate}
            placeholder={t('stockings.selectEndDate')}
            value={expireDate ? moment(expireDate) : moment()}
            onChange={(date, dateString) => setExpireDate(dateString)}
          />
        </Form.Item>
      </div>;
    }
    return null;
  };

  function getParamsToShare () {
    const shareData = { analysisId: analysisId, active: isPublicLink, title: title } as DataToShare;
    if (expireDate) {
      shareData.expireDate = moment(expireDate).format(DATE_FORMATS.YYYY_MM_DD).toString();
    }

    if (pin && pin.length === 4) {
      shareData.pin = pin;
    }

    return shareData;
  }

  function storeSetting () {
    const shareData = getParamsToShare();
    storeSettings(shareData);
    resetFormSetting();
  }

  function updateSetting () {
    const shareData = getParamsToShare();

    updateSettings(shareAnalysis._id, shareData);
    resetFormSetting();
  }

  const generatePdf = () => {
    dispatch(fetchShareAnalysis({ analysisId, generatePDF: true }));
    dispatch(fetchUrlAnalysisDetailPdf({ bin, analysisId, analysisCode }));
  };

  function renderGeneratePDF () {
    if (!showIconPdf) {
      return null;
    }

    return (
      <IconButton
        id='download_pdf_button'
        loading={isDownloadingFile}
        onClick={generatePdf}
        iconName='download'
        tooltipText={t('detail.pdf.generate')}
        placement='top'
      />
    );
  }

  const renderOptions = () => {
    if (analysisIsArchived || stockingIsArchived) {
      return renderGeneratePDF();
    }

    return (
      <>
        {renderGeneratePDF()}
        {renderOptionRelaunch()}
        {renderOptionSetting()}
        {renderInfoIcon()}
        {renderPagination()}
      </>
    );
  };

  return <>
    <Space align='end' size='small'>
      {renderOptions()}
    </Space>

    <LrvModal
      theme='light'
      title={t('share.setting')}
      open={showSettingsLinkModal}
      destroyOnClose={true}
      className={styles.shareLinkModal}
      closeIcon={<Icon id='close_share_analysis_modal' name='close' />}
      okButtonProps={{ id: 'submit_share_analysis', htmlType: 'submit', form: 'formSettingsLink', disabled: disabledButton }}
      cancelButtonProps={{ id: 'cancel_share_analysis' }}
      onOk={() => {
        if (!shareAnalysis._id && isPublicLink) {
          storeSetting();
          return;
        }

        if (shareAnalysis._id !== '') {
          updateSetting();
        }
      }}
      okText={t('share.save').toUpperCase()}
      cancelText={t('share.cancel').toUpperCase()}
      onCancel={() => {
        resetFormSetting();
      }}
    >
      <LrvForm
        theme='light'
        form={formSettingsLink}
        name='formSettingsLink'
        id='formSettingsLink'
        layout='vertical'
        onFieldsChange={() => {
          const disabled = formSettingsLink.getFieldsError().filter(({ errors }) => errors.length).length > 0;
          setDisabledButton(disabled);
        }}
      >
        <Form.Item
          name='shareLink'
        >
          <Input.Group
            onMouseEnter={() => {
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setCopy(t('share.copy'));
              setShowTooltip(false);
            }}>
            <LrvInput
              theme='light'
              readOnly
              defaultValue={urlAnalysis}
              style={{ width: '88%', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            />
            <Tooltip
              className={styles.tooltip}
              open={showTooltip}
              placement='top'
              title={copy}
            >
              <LrvButton
                id='copy_url_button'
                className={styles.button}
                type='primary'
                onClick={() => {
                  setShowTooltip(true);
                  setCopy(t('share.copied'));
                  navigator.clipboard.writeText(urlAnalysis);
                }}
              >
                <Icon name='file-copy' />
              </LrvButton>
            </Tooltip>
          </Input.Group>
        </Form.Item>

        <LrvDivider theme='light' />

        <Form.Item
          name='publicLink'
        >
          <div className={styles.publicLink}>
            <div>
              <LrvText theme='light' text={t('share.publicLink')} /> &nbsp;
              <Tooltip id='public_link_tooltip' title={t('share.information.publicLink')}>
                <QuestionCircleOutlined id='public_link_question_icon' className={styles.icon} />
              </Tooltip>
            </div>

            <LrvSwitch
              theme='light'
              id='public_link_switch'
              checked={isPublicLink}
              onChange={(value) => {
                if (!value) {
                  setValidatePin(false);
                }
                setIsPublicLink(value);
              }}
            />

          </div>
        </Form.Item>

        {renderSettingInputs()}
      </LrvForm>
    </LrvModal>

    <ExtraInformationModal theme='light' />
  </>;
}
