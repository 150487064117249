import React from 'react';
import Qrcode from 'qrcode.react';

import { SHARE_ANALYSES } from '../../../config/config.api';

import styles from './AnalysisQRCode.module.scss';

export default function AnalysisQRCode (props: { id: string; pin?: string }) {

  const { id: analysisId, pin } = props;
  const url = `${SHARE_ANALYSES}/${analysisId}`;

  function renderPin () {
    if (pin) {
      return <div className={styles.pin}>PIN: {pin}</div>;
    }
    return null;
  }

  return (
    <a className={styles.containerQRCode} href={url}>
      <Qrcode
        className={styles.qrcode}
        value={url}
        fgColor='#000000'
        level='H'
        includeMargin={false}
        renderAs='svg'
      />
      {renderPin()}
    </ a>
  );
}
