import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ReferenceCurves } from '../../Units/interfaces';
import { REFERENCE_CURVES_URL } from '../../../config/config.api';
import { axiosClient as axios } from '../../../utils/axios_instance';

import { GlobalReferenceCurvesState } from './interfaces';

const initialState: GlobalReferenceCurvesState = {
  phaseType: '',
  isLoading: false,
  showModal: false,
  referenceCurves: [],
  referenceCurveSelected: {
    _id: '',
    campusId: '',
    companyId: '',
    name: '',
    phaseType: '',
    values: [],
    createdAt: '',
    updatedAt: '',
    type: '',
    active: false,
  },
};

export const globalReferenceCurvesSlice = createSlice({
  initialState,
  name: 'globalReferenceCurves',
  reducers: {
    setIsLoading: (state: GlobalReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setReferenceCurves: (state: GlobalReferenceCurvesState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves = action.payload;
    },
    setSelectedReferenceCurve: (state: GlobalReferenceCurvesState, action: PayloadAction<ReferenceCurves>) => {
      state.referenceCurveSelected = action.payload;
    },
    setPhaseType: (state: GlobalReferenceCurvesState, action: PayloadAction<string>) => {
      state.phaseType = action.payload;
    },
    setShowModal: (state: GlobalReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setReferenceCurves,
  setSelectedReferenceCurve,
  setPhaseType,
  setShowModal,
} = globalReferenceCurvesSlice.actions;

export default globalReferenceCurvesSlice.reducer;

export const fetchGlobalReferenceCurves = () => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  const params = {
    $limit: -1,
    'companyId[$exists]': false,
  };

  try {
    const response = await axios.get(REFERENCE_CURVES_URL, { params });
    dispatch(setReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};
