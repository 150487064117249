import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { Input, Spin, Tooltip, Tabs } from 'antd';
import esES from 'antd/es/date-picker/locale/es_ES';
import enUS from 'antd/es/date-picker/locale/en_US';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { handleResizeEvent } from '../../utils/dimensions';
import { companyBalanceLimits } from '../../config/commons';
import { GenericParam } from '../../common/interfaces/commons';
import { getMonthName, formatLongDateWithOffset } from '../../utils/date';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvEmpty } from '../../common/components/LrvEmpty/LrvEmpty';
import { LrvButton } from '../../common/components/LrvButton/LrvButton';
import ProgressBar from '../../common/components/ProgressBar/ProgressBar';
import { LrvPopconfirm } from '../../common/components/LrvPopconfirm/LrvPopconfirm';
import { balanceCurrentTab, isAdminUser, typeCompany } from '../../config/commons';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import HorizontalGradient from '../../common/components/HorizontalGradient/HorizontalGradient';

import './AssigmentBalances.scss';
import ChartBalance from './ChartBalance';
import * as balanceSlice from './balanceSlice';
import styles from './AssigmentBalances.module.scss';
import { UserBalance, CompanyBalance } from './interfaces';

let chartUserBalance: ChartBalance | null;
const DAYS_TO_RESET = 30;

interface Props {
  theme?: 'dark' | 'light';
}

function AssigmentBalances (props: Props) {
  const { theme = 'dark' } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const currentDate = moment();
  const { TabPane } = Tabs;

  const [usersBalanceErrors, setUsersBalanceErrors] = useState<GenericParam>({});
  const [companyBalanceErrors, setCompanyBalanceErrors] = useState<GenericParam>({});
  const [usersPrepaidBalanceErrors, setUsersPrepaidBalanceErrors] = useState<GenericParam>({});
  const [bonusQuotaErrors, setBonusQuotaErrors] = useState<GenericParam>({});

  const [userBalanceSelected, setUserBalanceSelected] = useState({ id: '', name: '' });
  const [month, setMonth] = useState(`${currentDate.month() + 1}`);
  const [year, setYear] = useState(`${currentDate.year()}`);
  const [showChartBalance, setShowChartBalance] = useState(false);

  const { company } = useSelector((state: Store) => state.header);
  const balance = useSelector((state: Store) => state.balance);

  const {
    currentBalanceTab,
    showBalanceModal,
    balance: clientBalance,
  } = balance;

  const {
    isBalanceLoading,
  } = clientBalance;

  const logUserBalance = balance.logUserBalance.logsBalances;
  const lastBalance = balance.logUserBalance.lastBalance;

  const [usersWithConsumptionLimit, setUsersWithConsumptionLimit] = useState<UserBalance[]>([]);
  const [usersWithFreeConsumption, setUsersWithFreeConsumption] = useState<UserBalance[]>([]);
  const [assignedCompanyBalances, setAssignedCompanyBalances] = useState<CompanyBalance[]>([]);
  const [companyBalance, setCompanyBalance] = useState({} as CompanyBalance);
  const [companyBalanceError, setCompanyBalanceError] = useState(false);
  const [companyPrepaidBalanceError, setCompanyPrepaidBalanceError] = useState(false);
  const [companyBonusQuotaError, setCompanyBonusQuotaError] = useState(false);
  const [quotaError, setQuotaError] = useState(false);
  const [clientNewBalance, setClientNewBalance] = useState(0);
  const [clientNewBonus, setClientNewBonus] = useState(0);
  const [descriptionPlan, setDescriptionPlan] = useState('');

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const refChartBalance = useRef<HTMLDivElement>(null);
  const refListBalance = useRef<HTMLDivElement>(null);
  const refChartTitleBalance = useRef<HTMLDivElement>(null);

  const prepaidBalanceExists = clientBalance.company.prepaid > 0;
  const bonusQuotaExists = clientBalance.company.bonusQuota > 0;
  const isDistributionCompany = clientBalance.company.isDistributor;
  const isActiveCompanyTab = currentBalanceTab === balanceCurrentTab.COMPANIES;

  useEffect(() => {
    setClientNewBalance(clientBalance.company.quota);
  }, [clientBalance, isActiveCompanyTab]);

  useEffect(() => {
    if (isDistributionCompany && clientBalance.assignedCompanyBalances) {
      setAssignedCompanyBalances(clientBalance.assignedCompanyBalances);
    }

    setUsersWithConsumptionLimit(clientBalance.usersWithConsumptionLimit);
    setUsersWithFreeConsumption(clientBalance.usersWithFreeConsumption);
    setCompanyBalance(clientBalance.company);
    setClientNewBonus(clientBalance.company.bonusQuota);
  }, [clientBalance, isDistributionCompany]);

  useEffect(() => {
    const initChartBalance = () => {
      const props = { container: refChartBalance.current, width: getWidth(), height: getHeight(), logsBalances: logUserBalance, lastBalance };
      chartUserBalance = new ChartBalance(props);
    };

    if (logUserBalance.length > 0) {
      initChartBalance();
    }
  }, [logUserBalance, lastBalance]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    });
  }, []);

  useEffect(updateScreenOnResize, [width, height]);

  const showPrepaidBalanceTab = !isActiveCompanyTab && prepaidBalanceExists;
  const showBonusTab = (isAdminUser() && !isActiveCompanyTab) || bonusQuotaExists;

  function renderBalanceColumns () {
    return (
      <div className={styles.gridContainer}>
        <div className={styles.balanceControls}>
          <div className={prepaidBalanceExists || bonusQuotaExists ? styles.progressBarSlim : styles.progressBar}>
          </div>
          <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', styles.balanceColumn)}>
            {t('users.balance.postpaid')}
          </div>

          {
            showPrepaidBalanceTab &&
            <Tooltip title={renderExpirateDateOfPrepaidBalance()}>
              <div className={cx(styles.gridItem, styles.gridPrepaidItem, styles.balanceColumn)}>
                {t('users.balance.prepaid')}
              </div>
            </Tooltip>
          }

          {
            showBonusTab &&
            <Tooltip title={renderBonusQuotaLabel()}>
              <div className={cx(styles.gridItem, styles.gridPrepaidItem, styles.balanceColumn)}>
                {t('users.balance.bonusQuota')}
              </div>
            </Tooltip>
          }
        </div>
      </div>
    );
  }

  function validatePostpaidBalance (planQuota: number) {
    let assignedQuota = 0;

    if (isActiveCompanyTab) {
      const quota = assignedCompanyBalances;
      assignedQuota = quota.map(item => item.quota).reduce((a, b) => a + b, 0);
    } else {
      const quota = usersWithConsumptionLimit;
      assignedQuota = quota.map(item => item.quota).reduce((a, b) => a + b, 0);
    }

    setCompanyBalanceError(!isActiveCompanyTab && planQuota < assignedQuota);
    const existsQuotaError = (planQuota < companyBalanceLimits.MIN || planQuota > companyBalanceLimits.MAX) && planQuota !== 0;
    setQuotaError(existsQuotaError);
  }

  function validateBonusQuota (bonusQuota: number) {
    let assignedBonusQuota = 0;

    if (isActiveCompanyTab) {
      const quota = [...assignedCompanyBalances];
      assignedBonusQuota = quota.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);
    } else {
      const quota = [...usersWithConsumptionLimit];
      assignedBonusQuota = quota.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);
    }

    setCompanyBonusQuotaError(bonusQuota < assignedBonusQuota);
  }

  function renderCompanyBalance () {
    const postpaidBalance = assignedCompanyBalances.map(companyBalance => companyBalance.quotaUsage).reduce((a, b) => a + b, 0);
    const postpaidUsage = isActiveCompanyTab ? postpaidBalance : companyBalance.quotaUsage;

    const prepaidBalance = assignedCompanyBalances.map(companyBalance => companyBalance.prepaidUsed).reduce((a, b) => a + b, 0);
    const prepaidUsage = isActiveCompanyTab ? prepaidBalance : companyBalance.prepaidUsed;

    const bonusBalance = assignedCompanyBalances.map(companyBalance => companyBalance.bonusQuotaUsed).reduce((a, b) => a + b, 0);
    const bonusUsage = isActiveCompanyTab ? bonusBalance : companyBalance.bonusQuotaUsed;

    return <div className={styles.gridContainer}>
      <div className={styles.balanceControls}>
        {
          isActiveCompanyTab ? null :
            <ProgressBar
              classNameContainer={prepaidBalanceExists || bonusQuotaExists ? styles.progressBarSlim : styles.progressBar}
              name={clientBalance.company.name}
              percent={100}
            />
        }

        {
          isActiveCompanyTab ? null :
            isAdminUser() && !clientBalance.company.isAssignedCompany ?
              <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', companyBalanceError ? styles.balanceBorderError : '')}>
                <div className={styles.quotaUsage}>{postpaidUsage}</div>
                <div className={styles.slash}> / </div>
                <LrvInput
                  theme={theme}
                  id='quota_balance_input'
                  className={styles.quota}
                  containerClassName={styles.containerQuota}
                  value={clientNewBalance}
                  onChange={(event) => {
                    const value = event.target.value ? parseInt(event.target.value) : 0;
                    setClientNewBalance(value);
                    validatePostpaidBalance(value);
                  }}
                />
              </div> :
              <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', companyBalanceError ? styles.balanceBorderError : '')}>
                <div className={styles.quotaUsage}>{postpaidUsage}</div>
                <div className={styles.slash}> / </div>
                <div className={styles.quotaCompany}>{clientNewBalance}</div>
              </div>
        }

        {
          showPrepaidBalanceTab &&
          <div className={cx(styles.gridItem, styles.gridPrepaidItem, companyPrepaidBalanceError ? styles.balanceBorderError : '')}>
            <div className={styles.quotaUsage}>{prepaidUsage}</div>
            <div className={styles.slash}> / </div>
            <div className={styles.quotaCompany}>{clientBalance.company.prepaid}</div>
          </div>
        }

        {
          (isAdminUser() && !isActiveCompanyTab) ?
            <div className={cx(styles.gridItem, styles.gridPrepaidItem, companyBonusQuotaError ? styles.balanceBorderError : '')}>
              <div className={styles.quotaUsage}>{bonusUsage}</div>
              <div className={styles.slash}> / </div>
              <LrvInput
                theme={theme}
                id='bonus_balance_input'
                className={styles.quota}
                containerClassName={styles.containerQuota}
                value={clientNewBonus}
                onChange={(event) => {
                  const value = event.target.value ? parseInt(event.target.value) : 0;
                  setClientNewBonus(value);
                  validateBonusQuota(value);
                }}
              />
            </div> :
            bonusQuotaExists ?
              <div className={cx(styles.gridItem, styles.gridPrepaidItem, companyBonusQuotaError ? styles.balanceBorderError : '')}>
                <div className={styles.quotaUsage}>{bonusUsage}</div>
                <div className={styles.slash}> / </div>
                <div className={styles.quotaCompany}>{clientNewBonus}</div>
              </div> : null
        }
      </div >
    </div >;
  }

  function renderCompanyBalanceError () {
    if (companyBalanceError) {
      return (
        <div style={{ height: 20 }} >
          <div className={cx(styles.alertError, 'alertError')} style={{ height: 20 }}>
            {clientNewBalance < companyBalance.quotaUsage ? t('users.balance.userError') : t('users.balance.companyError')}
          </div>
        </div>
      );
    }

    if (companyBonusQuotaError) {
      return (
        <div style={{ height: 20 }} >
          <div className={cx(styles.alertError, 'alertError')} style={{ height: 20 }}>
            {clientNewBonus < companyBalance.bonusQuotaUsed ? t('users.balance.bonusError') : t('users.balance.companyBonusError')}
          </div>
        </div>
      );
    }

    if (quotaError) {
      return (
        <div style={{ height: 20 }} >
          <div className={cx(styles.alertError, 'alertError')} style={{ height: 20 }}>
            {t('users.balance.quotaError', { minQuota: companyBalanceLimits.MIN, maxQuota: companyBalanceLimits.MAX })}
          </div>
        </div>
      );
    }

    return null;
  }

  const renderPostpaidBalance = (props: { user: UserBalance; index: number; allowEditBalance: boolean }) => {
    const { user, index, allowEditBalance } = props;
    const inputId = 'input_' + user.userId;
    const quotaUsedId = 'label_' + user.userId;

    return (
      <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', usersBalanceErrors[user.userId] ? styles.balanceBorderError : '')}>
        <div id={quotaUsedId} className={cx(styles.quotaUsage, allowEditBalance ? '' : styles.centerText)}>{user.quotaUsage}</div>
        {
          allowEditBalance &&
          <>
            <div className={styles.slash}> / </div>
            <LrvInput
              theme={theme}
              id={inputId}
              className={styles.quota}
              containerClassName={styles.containerQuota}
              value={clientNewBalance === 0 ? 0 : user.quota}
              defaultValue={user.quotaUsage}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                const quota = [...usersWithConsumptionLimit];
                const editQuota = { ...quota[index] };
                editQuota.quota = value;
                quota[index] = editQuota;

                const assignedQuota = quota.map(item => item.quota).reduce((a, b) => a + b, 0);
                let newUsersBalanceErrors: GenericParam = {};

                if (value < user.quotaUsage) {
                  newUsersBalanceErrors = { ...usersBalanceErrors };
                  newUsersBalanceErrors[user.userId] = t('users.balance.userError');
                } else {
                  newUsersBalanceErrors = { ...usersBalanceErrors };
                  delete newUsersBalanceErrors[user.userId];
                }

                setUsersBalanceErrors(newUsersBalanceErrors);
                setCompanyBalanceError(!isActiveCompanyTab && clientNewBalance < assignedQuota);
                setUsersWithConsumptionLimit(quota);
              }}
            />
          </>
        }
      </div>
    );
  };

  const renderPrepaidBalance = (props: { user: UserBalance; index: number; allowEditBalance: boolean }) => {
    const { user, index, allowEditBalance } = props;
    const inputPrepaidBalanceId = 'input_prepaid_balance_' + user.userId;
    const prepaidBalanceUsedId = 'label_prepaid_' + user.userId;

    if (!showPrepaidBalanceTab) {
      return null;
    }

    return (
      <div className={cx(styles.gridItem, prepaidBalanceExists ? styles.gridPostpaidItem : '', styles.gridPrepaidItem, usersPrepaidBalanceErrors[user.userId] ? styles.balanceBorderError : '')}>
        <div id={prepaidBalanceUsedId} className={cx(styles.quotaUsage, allowEditBalance ? '' : styles.centerText)}>{user.prepaidUsed}</div>
        {
          allowEditBalance &&
          <>
            <div className={styles.slash}> / </div>
            <LrvInput
              theme={theme}
              id={inputPrepaidBalanceId}
              className={cx(styles.quota)}
              containerClassName={styles.containerQuota}
              value={user.prepaid}
              defaultValue={user.prepaid}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                const prepaidBalance = [...usersWithConsumptionLimit];
                const editPrepaidBalance = { ...prepaidBalance[index] };
                editPrepaidBalance.prepaid = value;
                prepaidBalance[index] = editPrepaidBalance;

                const assignedPrepaidBalance = prepaidBalance.map(item => item.prepaid).reduce((a, b) => a + b, 0);

                let newUsersPrepaidBalanceErrors: GenericParam = {};
                if (value < user.prepaidUsed) {
                  newUsersPrepaidBalanceErrors = { ...usersPrepaidBalanceErrors };
                  newUsersPrepaidBalanceErrors[user.userId] = t('users.balance.userPrepaidError');
                } else {
                  newUsersPrepaidBalanceErrors = { ...usersPrepaidBalanceErrors };
                  delete newUsersPrepaidBalanceErrors[user.userId];
                }

                setUsersPrepaidBalanceErrors(newUsersPrepaidBalanceErrors);
                setCompanyPrepaidBalanceError(clientBalance.company.prepaid < assignedPrepaidBalance);
                setUsersWithConsumptionLimit(prepaidBalance);
              }}
            />
          </>
        }

      </div>
    );
  };

  const renderBonusQuota = (props: { user: UserBalance; index: number; allowEditBalance: boolean }) => {
    const { user, index, allowEditBalance } = props;
    const bonusQuotaUsedId = 'label_bonus_' + user.userId;
    const inputBonusQuotaId = 'input_bonus_quota_' + user.userId;

    if (!showBonusTab) {
      return null;
    }

    return (
      <div className={cx(styles.gridItem, bonusQuotaExists ? styles.gridPostpaidItem : '', styles.gridPrepaidItem, bonusQuotaErrors[companyBalance.companyId] ? styles.balanceBorderError : '')}>
        <div id={bonusQuotaUsedId} className={cx(styles.quotaUsage, allowEditBalance ? '' : styles.centerText)}>{user.bonusQuotaUsed}</div>
        {
          allowEditBalance &&
          <>
            <div className={styles.slash}> / </div>
            <LrvInput
              theme={theme}
              id={inputBonusQuotaId}
              className={styles.quota}
              containerClassName={styles.containerQuota}
              value={user.bonusQuota}
              defaultValue={user.bonusQuota}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                const bonusQuota = [...usersWithConsumptionLimit];
                const editBonusQuota = { ...bonusQuota[index] };
                editBonusQuota.bonusQuota = value;
                bonusQuota[index] = editBonusQuota;

                const assignedBonusQuota = bonusQuota.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);

                let newBonusQuotaErrors: GenericParam = {};
                if (value < user.bonusQuotaUsed) {
                  newBonusQuotaErrors = { ...bonusQuotaErrors };
                  newBonusQuotaErrors[user.userId] = t('users.balance.bonusQuotaError');
                } else {
                  newBonusQuotaErrors = { ...bonusQuotaErrors };
                  delete newBonusQuotaErrors[user.userId];
                }

                setBonusQuotaErrors(newBonusQuotaErrors);
                setCompanyBonusQuotaError(clientNewBonus < assignedBonusQuota);
                setUsersWithConsumptionLimit(bonusQuota);
              }}
            />
          </>
        }
      </div>
    );
  };

  function renderBalanceUser (props: { user: UserBalance; index: number; allowEditBalance: boolean }) {
    const { user, index, allowEditBalance } = props;

    return (
      <>
        {renderPostpaidBalance({ user, index, allowEditBalance })}
        {renderPrepaidBalance({ user, index, allowEditBalance })}
        {renderBonusQuota({ user, index, allowEditBalance })}
      </>
    );
  }

  function renderBalanceCompany (companyBalance: CompanyBalance, index: number) {
    const inputId = 'input_' + companyBalance.companyId;
    const quotaUsedId = 'label_' + companyBalance.companyId;
    const inputPrepaidBalanceId = 'input_prepaid_balance_' + companyBalance.companyId;
    const prepaidBalanceUsedId = 'label_prepaid_' + companyBalance.companyId;
    const bonusQuotaUsedId = 'label_bonus_' + companyBalance.companyId;
    const inputBonusQuotaId = 'input_bonus_quota_' + companyBalance.companyId;

    return (
      <>
        <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', companyBalanceErrors[companyBalance.companyId] ? styles.balanceBorderError : '')}>
          <div id={quotaUsedId} className={styles.quotaUsage}>{companyBalance.quotaUsage}</div>
          <div className={styles.slash}> / </div>
          <Input
            id={inputId}
            className={styles.quota}
            value={companyBalance.quota}
            defaultValue={companyBalance.quotaUsage}
            onChange={(event) => {
              const value = event.target.value ? parseInt(event.target.value) : 0;
              const quota = [...assignedCompanyBalances];
              const editQuota = { ...quota[index] };
              editQuota.quota = value;
              quota[index] = editQuota;

              const assignedQuota = quota.map(item => item.quota).reduce((a, b) => a + b, 0);

              let newCompanyBalanceErrors: GenericParam = {};
              if (value < companyBalance.quotaUsage) {
                newCompanyBalanceErrors = { ...companyBalanceErrors };
                newCompanyBalanceErrors[companyBalance.companyId] = t('users.balance.userError');
              } else {
                newCompanyBalanceErrors = { ...companyBalanceErrors };
                delete newCompanyBalanceErrors[companyBalance.companyId];
              }

              setCompanyBalanceErrors(newCompanyBalanceErrors);
              setCompanyBalanceError(!isActiveCompanyTab && clientNewBalance < assignedQuota);
              setAssignedCompanyBalances(quota);
            }}
          />
        </div>

        {
          showPrepaidBalanceTab &&
          <div className={cx(styles.gridItem, prepaidBalanceExists ? styles.gridPostpaidItem : '', styles.gridPrepaidItem, usersPrepaidBalanceErrors[companyBalance.companyId] ? styles.balanceBorderError : '')}>
            <div id={prepaidBalanceUsedId} className={styles.quotaUsage}>{companyBalance.prepaidUsed}</div>
            <div className={styles.slash}> / </div>
            <Input
              id={inputPrepaidBalanceId}
              className={styles.quota}
              value={companyBalance.prepaid}
              defaultValue={companyBalance.prepaid}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                const prepaidBalance = [...assignedCompanyBalances];
                const editPrepaidBalance = { ...prepaidBalance[index] };
                editPrepaidBalance.prepaid = value;
                prepaidBalance[index] = editPrepaidBalance;

                const assignedPrepaidBalance = prepaidBalance.map(item => item.prepaid).reduce((a, b) => a + b, 0);

                let newUsersPrepaidBalanceErrors: GenericParam = {};
                if (value < companyBalance.prepaidUsed) {
                  newUsersPrepaidBalanceErrors = { ...usersPrepaidBalanceErrors };
                  newUsersPrepaidBalanceErrors[companyBalance.companyId] = t('users.balance.userPrepaidError');
                } else {
                  newUsersPrepaidBalanceErrors = { ...usersPrepaidBalanceErrors };
                  delete newUsersPrepaidBalanceErrors[companyBalance.companyId];
                }

                setUsersPrepaidBalanceErrors(newUsersPrepaidBalanceErrors);
                setCompanyPrepaidBalanceError(clientBalance.company.prepaid < assignedPrepaidBalance);
                setAssignedCompanyBalances(prepaidBalance);
              }}
            />
          </div>}

        {
          showBonusTab &&
          <div className={cx(styles.gridItem, bonusQuotaExists ? styles.gridPostpaidItem : '', styles.gridPrepaidItem, bonusQuotaErrors[companyBalance.companyId] ? styles.balanceBorderError : '')}>
            <div id={bonusQuotaUsedId} className={styles.quotaUsage}>{companyBalance.bonusQuotaUsed}</div>
            <div className={styles.slash}> / </div>
            <Input
              id={inputBonusQuotaId}
              className={styles.quota}
              value={companyBalance.bonusQuota}
              defaultValue={companyBalance.bonusQuota}
              onChange={(event) => {
                const value = event.target.value ? parseInt(event.target.value) : 0;
                const bonusQuota = [...assignedCompanyBalances];
                const editBonusQuota = { ...bonusQuota[index] };
                editBonusQuota.bonusQuota = value;
                bonusQuota[index] = editBonusQuota;

                const assignedBonusQuota = bonusQuota.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);

                let newBonusQuotaErrors: GenericParam = {};
                if (value < companyBalance.bonusQuotaUsed) {
                  newBonusQuotaErrors = { ...bonusQuotaErrors };
                  newBonusQuotaErrors[companyBalance.companyId] = t('users.balance.bonusQuotaError');
                } else {
                  newBonusQuotaErrors = { ...bonusQuotaErrors };
                  delete newBonusQuotaErrors[companyBalance.companyId];
                }

                setBonusQuotaErrors(newBonusQuotaErrors);
                setCompanyBonusQuotaError(clientNewBonus < assignedBonusQuota);
                setAssignedCompanyBalances(bonusQuota);
              }}
            />
          </div>
        }
      </>
    );
  }

  function renderUsersList (props: { userBalances: UserBalance[]; allowEditBalance: boolean }) {
    const { userBalances, allowEditBalance } = props;

    return userBalances.map((user, index) => {
      const userId = 'balance_user_' + user.userId;

      const userPostpaidBalance = user.quota;
      const userPrepaidBalance = prepaidBalanceExists ? user.prepaid : 0;
      const companyPrepaidBalance = prepaidBalanceExists ? clientBalance.company.prepaid : 0;
      const userBonusQuota = bonusQuotaExists ? user.bonusQuota : 0;
      const companyBonusQuota = bonusQuotaExists ? clientBalance.company.bonusQuota : 0;
      const percent = ((userPostpaidBalance + userPrepaidBalance + userBonusQuota) * 100) / (clientNewBalance + companyPrepaidBalance + companyBonusQuota);

      const lastName = user.lastName.split(' ')[0];
      const userName = `${user.firstName} ${lastName}`;

      return (
        <div key={index} className={styles.gridContainer}>
          <div className={styles.balanceControls}>
            <div className={cx(styles.containerProgressBar, (prepaidBalanceExists || bonusQuotaExists) ? styles.progressBarSlim : styles.progressBar)}>
              <ProgressBar
                id={userId}
                name={userName}
                percent={percent}
                onClick={() => {
                  setUserBalanceSelected({ id: user.userId, name: userName });
                  setShowChartBalance(true);

                  const params = { userId: user.userId, month: `${month}`, year: `${year}`, companyId: company._id };
                  dispatch(balanceSlice.fetchLogsBalancesByUser(params));
                }}
              />
              <div className={styles.containerSwitch}>
                <LrvPopconfirm
                  theme='light'
                  title={allowEditBalance ? t('users.balance.confirmRemoveBalanceLimit.title') : t('users.balance.confirmAssignBalanceLimit.title')}
                  onConfirm={() => dispatch(balanceSlice.updateAllowCompanyBalanceConsumption({ allowCompanyBalanceConsumption: allowEditBalance, userId: user.userId, companyId: company._id }))}
                  okText={t('users.balance.confirmRemoveBalanceLimit.yes')}
                  cancelText={t('users.balance.confirmRemoveBalanceLimit.no')}
                >
                  <LrvButton
                    theme='light'
                    id={`user_${userId}_remove_user_balance_button`}
                    className={cx(styles.button, isDesktop ? styles.extraActions : styles.mobileExtraActions)}
                  >
                    {allowEditBalance ? t('users.balance.removeLimit') : t('users.balance.assignLimit')}
                  </LrvButton>
                </LrvPopconfirm>
              </div>
            </div>
            {renderBalanceUser({ user, index, allowEditBalance })}
          </div>
          {usersBalanceErrors[user.userId] && <div className={cx(styles.alertError, 'alertError')}>{usersBalanceErrors[user.userId]}</div>}
          {!usersBalanceErrors[user.userId] && usersPrepaidBalanceErrors[user.userId] && <div className={cx(styles.alertError, 'alertError')}>{usersPrepaidBalanceErrors[user.userId]}</div>}
          {!usersBalanceErrors[user.userId] && !usersPrepaidBalanceErrors[user.userId] && bonusQuotaErrors[user.userId] && <div className={cx(styles.alertError, 'alertError')}>{bonusQuotaErrors[user.userId]}</div>}
        </div>
      );
    });
  }

  function renderCompaniesList () {
    return assignedCompanyBalances.map((companyBalance, index) => {
      const companyId = 'balance_company_' + companyBalance.companyId;

      const userPostpaidBalance = companyBalance.quota;
      const userPrepaidBalance = prepaidBalanceExists ? companyBalance.prepaid : 0;
      const companyPrepaidBalance = prepaidBalanceExists ? clientBalance.company.prepaid : 0;
      const userBonusQuota = bonusQuotaExists ? companyBalance.bonusQuota : 0;
      const companyBonusQuota = bonusQuotaExists ? clientBalance.company.bonusQuota : 0;
      const percent = ((userPostpaidBalance + userPrepaidBalance + userBonusQuota) * 100) / (clientNewBalance + companyPrepaidBalance + companyBonusQuota);

      return (
        <div key={index} className={styles.gridContainer}>
          <div className={styles.balanceControls}>
            <ProgressBar
              id={companyId}
              classNameContainer={(prepaidBalanceExists || bonusQuotaExists) ? styles.progressBarSlim : styles.progressBar}
              name={companyBalance.name}
              percent={percent}
              onClick={() => {
                setUserBalanceSelected({ id: companyBalance.companyId, name: companyBalance.name });
                setShowChartBalance(true);

                const params = { companyId: companyBalance.companyId, month: `${month}`, year: `${year}` };
                dispatch(balanceSlice.fetchLogsBalancesByCompany(params));
              }}
            />
            {renderBalanceCompany(companyBalance, index)}
          </div>
          {companyBalanceErrors[companyBalance.companyId] && <div className={cx(styles.alertError, 'alertError')}>{companyBalanceErrors[companyBalance.companyId]}</div>}
          {!companyBalanceErrors[companyBalance.companyId] && usersPrepaidBalanceErrors[companyBalance.companyId] && <div className={cx(styles.alertError, 'alertError')}>{usersPrepaidBalanceErrors[companyBalance.companyId]}</div>}
          {!usersBalanceErrors[companyBalance.companyId] && !usersPrepaidBalanceErrors[companyBalance.companyId] && bonusQuotaErrors[companyBalance.companyId] && <div className={cx(styles.alertError, 'alertError')}>{bonusQuotaErrors[companyBalance.companyId]}</div>}
        </div>
      );
    });
  }

  function renderTotalBalance () {
    let totalPostpaid = 0;
    let totalPrepaid = 0;
    let totalBonusQuota = 0;

    if (isActiveCompanyTab) {
      totalPostpaid = assignedCompanyBalances.map(item => item.quota).reduce((a, b) => a + b, 0);
      totalPrepaid = assignedCompanyBalances.map(item => item.prepaid).reduce((a, b) => a + b, 0);
      totalBonusQuota = assignedCompanyBalances.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);
    } else {
      totalPostpaid = clientNewBalance === 0 ? 0 : usersWithConsumptionLimit.map(item => item.quota).reduce((a, b) => a + b, 0);
      totalPrepaid = usersWithConsumptionLimit.map(item => item.prepaid).reduce((a, b) => a + b, 0);
      totalBonusQuota = usersWithConsumptionLimit.map(item => item.bonusQuota).reduce((a, b) => a + b, 0);
    }

    const totalCompanyBalance = clientNewBalance + (prepaidBalanceExists ? clientBalance.company.prepaid : 0) + (bonusQuotaExists ? clientBalance.company.bonusQuota : 0);
    const totalBalance = totalPostpaid + totalPrepaid + totalBonusQuota;
    const percent = totalBalance * 100 / totalCompanyBalance;

    return (
      <div className={styles.gridContainer}>
        <div className={styles.balanceControls}>
          <ProgressBar
            classNameContainer={prepaidBalanceExists || bonusQuotaExists ? styles.progressBarSlim : styles.progressBar}
            name={t('users.balance.total')}
            percent={percent}
            error={companyBalanceError}
          />
          <div className={cx(styles.gridItem, prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', prepaidBalanceExists || bonusQuotaExists ? styles.gridPostpaidItem : '', companyBalanceError ? styles.balanceBorderError : '')}>
            {totalPostpaid}
          </div>

          {
            showPrepaidBalanceTab &&
            <div className={cx(styles.gridItem, styles.gridPrepaidItem)}>
              {totalPrepaid}
            </div>
          }

          {
            showBonusTab &&
            <div className={cx(styles.gridItem, styles.gridPrepaidItem)}>
              {totalBonusQuota}
            </div>
          }
        </div>
      </div>
    );
  }

  function renderBalanceError () {
    if (companyBalanceError || companyPrepaidBalanceError || companyBonusQuotaError) {
      return (
        <div className={cx(styles.alertError, 'alertError')} >
          <div role='alert'>{companyBalanceError ? t('users.balance.totalError') : companyPrepaidBalanceError ? t('users.balance.totalPrepaidError') : t('users.balance.totalBonusError')}</div>
        </div>
      );
    }
    return null;
  }

  function renderExpirateDateOfPrepaidBalance () {
    if (companyBalance.payment?.paymentDate) {
      const paymentDate = new Date(companyBalance.payment.paymentDate);
      paymentDate.setDate(paymentDate.getDate() + DAYS_TO_RESET);

      return t('users.balance.expirationDate', { expirationDate: formatLongDateWithOffset(paymentDate.toString()) });
    }
    return null;
  }

  function renderBonusQuotaLabel () {
    if (clientNewBonus > 0) {
      return t('users.balance.infoBonusQuota');
    }
    return null;
  }

  function getWidth () {
    if (refChartBalance.current && refChartBalance.current.parentElement && refChartBalance.current.parentElement.parentElement) {
      const width = refChartBalance.current.parentElement.parentElement.offsetWidth;
      if (width > 480) {
        return 480;
      }
      return width;
    }
    return 480;
  }

  function getHeight () {
    if (refListBalance.current && refListBalance.current.parentElement && refChartTitleBalance.current) {
      const height = refListBalance.current.parentElement.offsetHeight - refChartTitleBalance.current.offsetHeight;
      if (height < 320) {
        return 360;
      }
      return height;
    }
    return 360;
  }

  function onChangeDate (date: moment.Moment | null) {
    if (!date) {
      return;
    }

    const _month = date.month() + 1;
    const _year = date.year();

    getLogsBalancesByUser(_month.toString(), _year.toString());
  }

  function getLogsBalancesByUser (month: string, year: string) {
    setMonth(month);
    setYear(year);

    if (company.isDistributor) {
      const params = { companyId: userBalanceSelected.id, month: `${month}`, year: `${year}` };
      dispatch(balanceSlice.fetchLogsBalancesByCompany(params));
    } else {
      const params = { userId: userBalanceSelected.id, month: month, year: year, companyId: company._id };
      dispatch(balanceSlice.fetchLogsBalancesByUser(params));
    }
  }

  function getMonthNext () {
    let _month = parseInt(month) + 1;
    let _year = parseInt(year);

    if (_month > 12) {
      _month = 1;
      _year += 1;
    }

    getLogsBalancesByUser(_month.toString(), _year.toString());
  }

  function getMonthPrevious () {
    let _month = parseInt(month) - 1;
    let _year = parseInt(year);

    if (_month < 1) {
      _month = 12;
      _year -= 1;
    }

    getLogsBalancesByUser(_month.toString(), _year.toString());
  }

  function renderChartTitle () {
    const monthName = getMonthName(month);

    return (
      <LrvText
        theme='light'
        className={styles.title}
        text={`${t('users.balance.chart.activity')} - ${userBalanceSelected.name} - ${monthName} ${year}`}
      />
    );
  }

  function renderChartLegends () {
    return <div className={styles.legends}>
      <div className={cx(styles.legend, styles.usage)}>
        <div className={styles.line} />
        <LrvText
          theme='light'
          className={styles.text}
          text={t('users.balance.chart.quotaUsage')}
        />
      </div>

      <div className={cx(styles.legend, styles.accreditation)}>
        <div className={styles.line} />
        <LrvText
          theme='light'
          className={styles.text}
          text={t('users.balance.chart.quotaCredit')}
        />
      </div>

      <div className={cx(styles.legend, styles.debit)}>
        <div className={styles.line} />
        <LrvText
          theme='light'
          className={styles.text}
          text={t('users.balance.chart.quotaDebit')}
        />
      </div>
    </div>;
  }

  function renderDatePicker () {
    const currentMonth = currentDate.month() + 1;
    const currentYear = currentDate.year();

    let valueDatePicker = `${year}-${month}-01`;
    if (currentMonth < 10) {
      valueDatePicker = `${year}-0${month}-01`;
    }

    const disabledIcon = currentMonth.toString() === month && currentYear.toString() === year;
    let language = navigator.language;
    let locale = enUS;

    language = language.split('-')[0];
    if (language === 'es') {
      locale = esES;
    }

    return (
      <div className={styles.options}>
        <LrvButton
          theme='light'
          id='previous_month'
          type='default'
          className={styles.icon}
          icon={<Icon name='arrow-left-s' />}
          onClick={getMonthPrevious}
        />

        <LrvDatePicker
          theme='light'
          id='date_picker'
          locale={locale}
          onChange={onChangeDate}
          picker='month'
          format='MMMM YYYY'
          defaultValue={moment()}
          value={moment(valueDatePicker)}
          className={styles.dateSelected}
          disabledDate={disabledDate}
        />

        <LrvButton
          theme='light'
          id='next_month'
          type='default'
          className={cx(styles.icon, disabledIcon && styles.disabled)}
          icon={<Icon name='arrow-right-s' />}
          onClick={() => {
            if (disabledIcon) {
              return;
            }

            getMonthNext();
          }}
        />
      </div>
    );
  }

  function disabledDate (current: moment.Moment) {
    return current && current >= moment().endOf('day');
  }

  function hideTooltip () {
    const tooltip = document.getElementById('tooltip');
    if (tooltip) {
      tooltip.style.display = 'none';
    }
  }

  function renderChartBalance () {
    if (logUserBalance.length === 0 && !showChartBalance) {
      return (
        <div id='select_user_container' className={styles.prevChart}>
          <Icon name='funds' className={styles.icon} />

          <div className={styles.text}>
            <LrvText
              theme='light'
              text={isActiveCompanyTab ? t('users.balance.chart.selectCompany') : t('users.balance.chart.selectUser')}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.containerChartBalance}>
        <div ref={refChartTitleBalance} className={styles.header}>
          {renderChartTitle()}
          {renderDatePicker()}
          {renderChartLegends()}
        </div>

        {logUserBalance.length === 0 ?
          <div id='empty_balance_chart' ref={refChartBalance} className={styles.body}>
            <LrvEmpty theme='light' />
          </div> :
          <HorizontalGradient theme='light' id='chartBalance' showGradient={logUserBalance.length + 1 > 5} className={cx(styles.body)} width={getWidth()}>
            <div ref={refChartBalance} onMouseLeave={hideTooltip}> </div>
          </HorizontalGradient>
        }
      </div>
    );
  }

  function updateScreenOnResize () {
    chartUserBalance && chartUserBalance.resize(getWidth(), getHeight());
  }

  function resetChartBalance () {
    dispatch(balanceSlice.resetLogsBalancesByUser());
    dispatch(balanceSlice.setShowBalanceModal(false));
    dispatch(balanceSlice.setCurrentBalanceTab(balanceCurrentTab.USERS));
    dispatch(balanceSlice.resetClientBalances());

    setShowChartBalance(false);
    setYear(`${currentDate.year()}`);
    setMonth(`${currentDate.month() + 1}`);
  }

  function disabledBalanceButton () {
    return (
      companyBalanceError || companyBalanceError ||
      companyPrepaidBalanceError || companyBonusQuotaError ||
      quotaError || companyBonusQuotaError ||
      !!Object.keys(usersBalanceErrors).length || !!Object.keys(companyBalanceErrors).length ||
      !!Object.keys(usersPrepaidBalanceErrors).length || !!Object.keys(bonusQuotaErrors).length
    );
  }

  const editBalance = async () => {
    const canEditBalance = !companyBalanceError && !companyPrepaidBalanceError && !companyBonusQuotaError && !quotaError;
    if (!canEditBalance) {
      return;
    }

    const params = {
      clientId: company._id,
      balances: usersWithConsumptionLimit,
      assignedBalances: assignedCompanyBalances,
      companyBalance,
      clientNewQuota: clientNewBalance,
      clientNewBonus,
      companyType: isActiveCompanyTab ? typeCompany.DISTRIBUTOR : typeCompany.NORMAL,
    };

    await balanceSlice.updateClientBalances(params);
    dispatch(balanceSlice.resetLogsBalancesByUser());
    setDescriptionPlan('');
    resetChartBalance();
  };

  const cancelEditBalance = () => {
    setDescriptionPlan('');
    setUsersBalanceErrors({});
    setCompanyBalanceErrors({});
    resetChartBalance();
  };

  function renderTabs () {
    return (
      <LrvTabs
        theme={theme}
        activeKey={currentBalanceTab}
        onChange={(key) => dispatch(balanceSlice.setCurrentBalanceTab(key))}
        className={styles.column}
      >
        <TabPane
          tab={t('users.balance.tabs.users')}
          key={balanceCurrentTab.USERS}
        >
          {renderBalanceList({ currentTab: balanceCurrentTab.USERS })}
        </TabPane>

        <TabPane
          tab={t('users.balance.tabs.companies')}
          key={balanceCurrentTab.COMPANIES}
        >
          {renderBalanceList({ currentTab: balanceCurrentTab.COMPANIES })}
        </TabPane>
      </LrvTabs>
    );
  }

  const renderUsers = (props: { userBalances: UserBalance[]; allowEditBalance: boolean, separateTitle: string }) => {
    const { userBalances, allowEditBalance, separateTitle } = props;

    if (userBalances.length === 0) {
      return null;
    }

    return (
      <>
        <div className={styles.separate}>
          <LrvText
            theme='light'
            text={separateTitle}
          />
        </div>
        {renderUsersList({ userBalances, allowEditBalance })}
      </>
    );
  };

  function renderBalanceList (props: { currentTab: string; applyPadding?: boolean }) {
    const { currentTab, applyPadding = false } = props;

    return (
      <div className={styles.margin}>
        <Spin spinning={isBalanceLoading} >
          <div ref={refListBalance} className={applyPadding ? styles.column : undefined}>
            {renderBalanceColumns()}
            {renderCompanyBalance()}
            {renderCompanyBalanceError()}

            <div className={styles.grid}>
              {currentTab === balanceCurrentTab.COMPANIES ?
                renderCompaniesList() :
                <>
                  {renderUsers({ userBalances: usersWithConsumptionLimit, allowEditBalance: true, separateTitle: t('users.balance.usersWithConsumptionLimit') })}
                  {renderUsers({ userBalances: usersWithFreeConsumption, allowEditBalance: false, separateTitle: t('users.balance.usersWithFreeConsumption') })}
                </>
              }
            </div>

            <div style={{ height: 20 }} />
            {renderTotalBalance()}
            <div style={{ height: 5 }} />

            {renderBalanceError()}

            <div role='alert'>{descriptionPlan}</div>
          </div>
        </Spin>
      </div>
    );
  }

  function renderBodyBalancesModal () {
    return (
      <div className={styles.content}>
        <div className={cx(styles.column, logUserBalance.length === 0 && !showChartBalance ? styles.margin : '')}>
          {renderChartBalance()}
        </div>

        {isDistributionCompany ? renderTabs() : renderBalanceList({ currentTab: currentBalanceTab, applyPadding: true })}
      </div>
    );
  }

  return (
    <LrvModal
      theme={theme}
      title={t('users.balance.title')}
      open={showBalanceModal}
      className={cx(styles.balanceModal, 'editBalanceModal')}
      onOk={editBalance}
      okButtonProps={{ id: 'submit_edit_balance', disabled: disabledBalanceButton() }}
      okText={t('users.balance.create').toUpperCase()}
      cancelText={t('users.balance.cancel').toUpperCase()}
      closeIcon={<Icon id='close_balance_modal' name='close' />}
      cancelButtonProps={{ id: 'cancel_edit_balance' }}
      onCancel={cancelEditBalance}
    >
      {renderBodyBalancesModal()}
    </LrvModal>
  );
}

export default AssigmentBalances;