import * as d3 from 'd3';
import cx from 'classnames';
import React, { useRef, useEffect, useState } from 'react';

import { getWeightUnit, THEME } from '../../../config/commons';
import { getParentElementHeight, getParentElementWidth, handleResizeEvent } from '../../../utils/dimensions';

import styles from './Chart.module.scss';

const palleteDark = ['#FF1493', '#E67A00', '#2DA622', '#00BFFC', '#4971FF'];
const palleteLight = ['#FF66B8', '#F59F3E', '#6BD35A', '#50D5FF', '#9EAEFF'];
const minTextAnchor = 136;

interface Props {
  weightLabels: string[];
  data: number[];
  percents: string[];
  measureUnit: string;
  averageWeight?: number;
  textClassName?: string;
  textInsideBarClassName?: string;
  textOutsideBarClassName?: string;
  containerClassName?: string;
  colorBars?: string;
  typeStyles: 'DEFAULT' | 'PDF';
  chartType: 'GROW_OUT_SIZES' | 'WEIGHT_GROUPS';
  isConsolidatedGrowOut?: boolean;
  theme?: 'dark' | 'light';
  defaultWidth?: number;
  defaultHeight?: number;
}

export default function BarsChart (props: Props) {
  const {
    data,
    weightLabels,
    measureUnit,
    percents = [],
    colorBars,
    textInsideBarClassName = styles.textInsideBarClassName,
    containerClassName = styles.container,
    averageWeight = 0,
    typeStyles = 'DEFAULT',
    isConsolidatedGrowOut = false,
    chartType,
    theme,
    defaultWidth,
    defaultHeight,
    textClassName = theme === THEME.LIGHT ? styles.rangesLight : styles.rangesDark,
    textOutsideBarClassName = theme === THEME.LIGHT ? styles.textOutsideBarClassNameLight : styles.textOutsideBarClassNameDark,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const weightsUnit = getWeightUnit(averageWeight);
  const isLightTheme = theme === THEME.LIGHT;
  const isGrowOutSizesChart = chartType === 'GROW_OUT_SIZES';

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [heightScreen, setHeightScreen] = useState(window.innerHeight);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidthScreen(window.innerWidth);
      setHeightScreen(window.innerHeight);
    });
  }, []);

  useEffect(updateScreenOnResize, [widthScreen, heightScreen, data, weightLabels, measureUnit, percents]);

  const getPalleteColor = (index: number) => isLightTheme ? palleteLight[index] : palleteDark[index];

  function getChartData () {
    const chartData = [];
    if (isGrowOutSizesChart) {
      for (let index = 0; index < data.length; index++) {
        const color = typeStyles === 'PDF' ? '#2668AD' : colorBars || getPalleteColor(index);

        const d = { label: weightLabels[index], value: data[index], color };
        chartData.push(d);
      }

      return chartData;
    }
    for (let index = 0; index < data.length; index++) {
      const color = colorBars || getPalleteColor(index);
      const d = { label: weightLabels[index], value: data[index], color };
      chartData.push(d);
    }
    return chartData;
  }

  function updateScreenOnResize () {
    const margin = { top: 0, right: 10, bottom: 0, left: 10 };

    const width = (defaultWidth || getParentElementWidth(ref)) - margin.left - margin.right;
    const height = (defaultHeight || getParentElementHeight(ref)) - margin.top - margin.bottom;

    const chartData = getChartData();
    d3.select(ref.current).select('svg').remove();

    const svg = d3.select(ref.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(0, 0)');

    const x = d3.scaleLinear()
      .domain([0, d3.max(chartData, d => d.value) as number])
      .range([0, width]);

    const y = d3.scaleBand()
      .range([0, height])
      .domain(chartData.map(function (d) { return d.label; }))
      .padding(0.15);

    const bandWidth = y.bandwidth();
    const heightRect = isGrowOutSizesChart ? bandWidth - (bandWidth > 20 ? 20 : 0) : bandWidth - (bandWidth > 25 ? 25 : 0);

    svg.selectAll('rect')
      .data(chartData)
      .enter()
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, index) => {
        if (isGrowOutSizesChart) {
          return y(d.label) as number + (chartData.length * (index / 6));
        }
        return y(d.label) as number + (chartData.length) + 4;
      })
      .attr('width', d => x(d.value))
      .attr('height', heightRect)
      .attr('fill', d => d.color);

    if (!(isGrowOutSizesChart)) {
      svg.selectAll('.label')
        .data(chartData)
        .enter()
        .append('text')
        .text(d => `${d.label} ${weightsUnit}`)
        .classed(textClassName, true)
        .attr('dx', 5)
        .attr('fill', 'red')
        .attr('x', 0)
        .attr('y', (d) => {
          return y(d.label) as number + (chartData.length) + 0.5;
        });
    }

    svg.append('g')
      .attr('text-anchor', 'end')
      .selectAll('text')
      .data(chartData)
      .join('text')
      .attr('class', function (d) {
        if (x(d.value) < minTextAnchor) {
          return textOutsideBarClassName;
        }
        return textInsideBarClassName;
      })
      .attr('x', d => x(d.value))
      .attr('y', (d, index) => {
        if (isGrowOutSizesChart) {
          return y(d.label) as number + (y.bandwidth() / 2) + (chartData.length * (index / 6)) - 12;
        }
        return y(d.label) as number + (y.bandwidth() / 2) + (chartData.length) - 10;
      })
      .attr('dy', '0.5em')
      .attr('dx', -5)
      .html((d, index) => {
        if (isGrowOutSizesChart) {
          if (x(d.value) >= minTextAnchor) {
            return `${percents[index]}% - ${d.value} ${measureUnit}`;
          }
          return `${d.label} &nbsp; -> &nbsp; ${percents[index]}% - ${d.value} ${measureUnit}`;
        }
        return `${percents[index]}% - ${d.value} ${measureUnit}`;
      })
      .call(function (text) {
        return text.filter(function (d) {
          return x(d.value) < minTextAnchor;
        }) // short bars
          .attr('dx', 5)
          .attr('text-anchor', 'start');
      });

    if (isGrowOutSizesChart) {
      svg.append('g')
        .attr('text-anchor', 'end')
        .selectAll('text')
        .data(chartData)
        .join('text')
        .attr('class', function (d) {
          if (x(d.value) < minTextAnchor) {
            return textOutsideBarClassName;
          }
          return textInsideBarClassName;
        })
        .attr('x', 47)
        .attr('y', (d, index) => {
          return y(d.label) as number + (y.bandwidth() / 2) + (chartData.length * (index / 6)) - 12;
        })
        .attr('dy', '0.5em')
        .attr('dx', -5)
        .html((d) => {
          if (x(d.value) >= minTextAnchor) {
            return d.label;
          }
          return null;
        });
    }
  }

  return (
    <div className={cx(containerClassName, isConsolidatedGrowOut ? styles.consolidatedGrowOutChart : '')}>
      <div className={cx(styles.barChartContainer, isLightTheme ? styles.containerLight : styles.containerDark)} ref={ref} />
    </div>
  );
}
