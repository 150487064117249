import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Row, Col, Tabs, Space } from 'antd';

import { useDragger } from '../../hooks/useDragger';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { MaturationCode } from '../Clients/interfaces';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { sortByName, sortTankStatus } from '../../utils/sort';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { getContainerVolumeLimits } from '../../helpers/tanks.helpers';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';
import { openErrorNotification } from '../../common/notification/Notification';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { isFieldsTouchedCustom, isNumber, validateNumber } from '../../utils/validations';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { disabledDateLarvae, getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { ProductionCycleInfo } from '../../common/components/ProductionCycleInfo/ProductionCycleInfo';
import { tankStatuses, typeFetchModule, typeFetchTank, stockingCodeLength, stockingPhaseTypes, THEME, maximumNumberOfStockings, stockingStageOptions, DEFAULT_DAYS_TO_INITIAL_STAGE, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import * as stockingSlice from './sowingsSlice';
import { NewStockingLarvaeBatch } from './NewStockingLarvaeBatch';
import { BindingStockingTable, NewBindingStockingButton, ErrorLabelRow } from './BindingStocking';
import { BindingStocking, CustomStage, StockingsInBatch, Campus, Container, StockingLarvae } from './interfaces';
import { defaultContainerData, getBindingStockingByMaxAnimals, getBindingStockingData, getTotalAnimals, initialBindingStocking, initialStockingsInBatch, isValidStockingBinding, isValidStockingBindingRow, stockingBindingRowIsFull } from './sowings.helpers';

const { TabPane } = Tabs;
const { Option } = Select;

const TABS = {
  INDIVIDUAL: 'INDIVIDUAL',
  BATCH: 'BATCH',
};

interface Props {
  theme?: 'dark' | 'light';
}

export const NewStockingLarvae = (props: Props) => {
  const { theme = 'dark' } = props;

  const [formNewStockingLarvae] = Form.useForm();

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({ applyBlackWhite: true });

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    limit, skip,
    selectedCampus,
    selectedModuleId: moduleId,
    selectedTankId: tankId,
    showCreateModalLarvae,
    campusesForm,
    modulesForm,
    tanksForm,
    maturations,
    maturationCodes,
    stockingDateRanges,
    stageOptions,
    isLoadingCreate,
    referenceCurves,
  } = useSelector((state: Store) => state.stockings);

  const {
    stockingsToShow
  } = useSelector((state: Store) => state.stockings.filters);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const currentPage = ((skip / limit) + 1) || 1;
  const volumeRanges = useSelector((state: Store) => state.campus.volumeRanges);
  const { company, phaseType } = useSelector((state: Store) => state.header);

  const [newStockingCode, setNewStockingCode] = useState('');
  const [newStockingName, setNewStockingName] = useState('');
  const [newStockingStartDate, setNewStockingStartDate] = useState('');
  const [newStockingMaturation, setNewStockingMaturation] = useState('');
  const [newStockingMaturationCode, setNewStockingMaturationCode] = useState<string | undefined>('');
  const [newStockingCampus, setNewStockingCampus] = useState('');
  const [newStockingModule, setNewStockingModule] = useState('');
  const [newStockingTank, setNewStockingTank] = useState('');
  const [newStockingParameter, setNewStockingParameter] = useState<string | undefined>(undefined);
  const [newStockingReference, setNewStockingReference] = useState<string | undefined>(undefined);
  const [newStockingNumberNauplii, setNewStockingNumberNauplii] = useState<number | string>(0);
  const [newStockingLiters, setNewStockingLiters] = useState<number | string>(0);
  const [newStockingStage, setNewStockingStage] = useState<CustomStage>({ key: 'NAUPLII', value: stockingStageOptions.NAUPLII });
  const [newBindingStockingsLarvae, setNewBindingStockingsLarvae] = useState<BindingStocking[]>([initialBindingStocking]);
  const [disabledButtonFormNewStocking, setDisabledButtonFormNewStocking] = useState(true);
  const [hasBindingStockingLarvae, setHasBindingStockingLarvae] = useState<boolean>(false);
  const [newDaysToInitialStage, setNewDaysToInitialStage] = useState<number>(DEFAULT_DAYS_TO_INITIAL_STAGE);

  const [campusListForStockingCreation, setCampusListForStockingCreation] = useState<Campus[]>([]);
  const [currentTypeOfStockingCreation, setCurrentTypeOfStockingCreation] = useState(phaseType);
  const [currentTab, setCurrentTab] = useState(TABS.INDIVIDUAL);

  const [stockingsInBatch, setStockingsInBatch] = useState<StockingsInBatch>(initialStockingsInBatch);
  const [containerSelected, setContainerSelected] = useState<Container>(defaultContainerData);

  const userSession = getUserSession();
  const containerVolumeLimits = getContainerVolumeLimits({ volumeRanges, phaseType });
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (!showCreateModalLarvae) {
      return;
    }

    const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
    const campusesList = campusesForm.filter((campus: Campus) => campus.phaseType === unitPhaseType);
    campusesList.sort(sortByName);

    setCurrentTypeOfStockingCreation(phaseType);
    setCampusListForStockingCreation(campusesList);
  }, [campusesForm, showCreateModalLarvae]);

  useEffect(() => {
    if (!showCreateModalLarvae) {
      return;
    }

    if (containerSelected.volume !== undefined && containerSelected.volume !== 0) {
      formNewStockingLarvae.setFieldsValue({
        liters: containerSelected.volume
      });
      setNewStockingLiters(containerSelected.volume);

      return;
    }

    if (containerSelected.type !== '') {
      formNewStockingLarvae.setFieldsValue({
        liters: containerVolumeLimits[containerSelected.type].min
      });
      setNewStockingLiters(containerVolumeLimits[containerSelected.type].min);
    }

    // for containerVolumeLimits
    // eslint-disable-next-line
  }, [currentTypeOfStockingCreation, formNewStockingLarvae, containerSelected._id, containerSelected.type, containerSelected.volume, showCreateModalLarvae]);

  useEffect(() => {
    if (!showCreateModalLarvae) {
      return;
    }

    if (currentTypeOfStockingCreation !== stockingPhaseTypes.LARVAE) {
      return;
    }

    formNewStockingLarvae.setFieldsValue({
      customStage: 'NAUPLII',
    });
  }, [currentTypeOfStockingCreation, formNewStockingLarvae, showCreateModalLarvae]);

  useEffect(() => {
    if (!company._id || !company.daysToInitialStage) {
      return;
    }

    setNewDaysToInitialStage(company.daysToInitialStage);

    formNewStockingLarvae.setFieldsValue({
      daysToInitialStage: company.daysToInitialStage,
    });
  }, [formNewStockingLarvae, company._id, company.daysToInitialStage]);
  //#endregion

  //#region Commons functions
  function onChangeStockingCampus (value: string) {
    formNewStockingLarvae.setFieldsValue({
      campusId: value,
      moduleId: undefined,
      tankId: undefined,
      liters: undefined
    });

    setNewStockingLiters(0);
    setNewStockingCampus(value);
    setNewStockingModule('');
    setNewStockingTank('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchModules(value, typeFetchModule.FORM));
  }

  function onChangeStockingModule (value: string) {
    formNewStockingLarvae.setFieldsValue({
      moduleId: value,
      tankId: undefined,
      liters: undefined
    });

    setNewStockingLiters(0);
    setNewStockingModule(value);
    setNewStockingTank('');

    setContainerSelected(defaultContainerData);
    dispatch(stockingSlice.fetchTanks(newStockingCampus, value, typeFetchTank.FORM));
  }

  function onChangeStockingTank (value: string) {
    setNewStockingTank(value);

    const tank = tanksForm.find((tank) => tank._id === value);
    if (tank) {
      setContainerSelected(tank);
    }
  }

  function onChangeMaturationLarvae (value: string) {
    formNewStockingLarvae.setFieldsValue({
      maturationCode: undefined,
    });

    setNewStockingMaturation(value);
    setNewStockingMaturationCode('');
    dispatch(stockingSlice.fetchMaturationCodes(value));
  }

  const onChangeStockingParameter = (value: string) => {
    formNewStockingLarvae.setFieldsValue({
      parameter: value,
      referenceCurveId: undefined,
    });

    setNewStockingParameter(value);
    setNewStockingReference(undefined);
    dispatch(stockingSlice.fetchReferenceCurves({ parameter: value, phaseType, companyId: company._id }));
  };

  const onChangeStockingReference = (value: string) => {
    formNewStockingLarvae.setFieldsValue({
      referenceCurveId: value,
    });

    setNewStockingReference(value);
  };
  //#endregion

  const closeModal = () => {
    formNewStockingLarvae.resetFields();

    dispatch(stockingSlice.setShowCreateModalLarvae(false));
    setNewStockingModule('');
    setNewStockingTank('');
    setNewStockingStage({ key: 'NAUPLII', value: stockingStageOptions.NAUPLII });
    setNewStockingMaturation('');
    setNewStockingStartDate('');
    setStockingsInBatch(initialStockingsInBatch);
    setNewStockingParameter(undefined);
    setNewStockingReference(undefined);
    setNewDaysToInitialStage(company.daysToInitialStage);
    cleanImage();
    setCurrentTypeOfStockingCreation(phaseType);

    setHasBindingStockingLarvae(false);
    setNewBindingStockingsLarvae([initialBindingStocking]);
    setCurrentTab(TABS.INDIVIDUAL);
    setContainerSelected(defaultContainerData);

    formNewStockingLarvae.setFieldsValue({
      customStage: 'NAUPLII',
      daysToInitialStage: company.daysToInitialStage,
    });
  };

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p>{t('payments.drag')}</p>
      </div>
    );
  };

  const renderBindingStocking = () => {
    return (
      <Form.Item>
        <LrvCheckbox
          theme='light'
          checked={hasBindingStockingLarvae}
          onChange={event => {
            setHasBindingStockingLarvae(event.target.checked);
          }}
        >
          {t('stockings.binding')}
        </LrvCheckbox>
      </Form.Item>
    );
  };

  const validateFormLarvae = () => {
    const formFields = Object.keys(formNewStockingLarvae.getFieldsValue());
    const fieldsNotRequired = ['invoiceImg', 'maturationCode', 'bindingCodes', 'parameter', 'referenceCurveId'];
    if (hasBindingStockingLarvae) {
      fieldsNotRequired.push('maturation');
      fieldsNotRequired.push('numberNauplii');
    }

    const validFormFields = formFields.filter((element) => !fieldsNotRequired.includes(element));
    validateCreateStockingForm(validFormFields);
  };

  const isValidStockingsInBatchData = (stockingsInBatch: StockingsInBatch) => {
    const { tanks, campusId, code, moduleId, startDate, customStage } = stockingsInBatch;

    if (!campusId || !code || !moduleId || !startDate || !customStage?.key || !tanks.length) {
      return false;
    }

    for (const tank of tanks) {
      if (tank.name && (!tank.maturationId || tank.litersNumber <= 0 || tank.naupliusNumber <= 0)) {
        return false;
      }

      if (tank.naupliusNumber && (!tank.name || !tank.maturationId || tank.litersNumber <= 0)) {
        return false;
      }

      if (tank.maturationId && (!tank.name || tank.litersNumber <= 0 || tank.naupliusNumber <= 0)) {
        return false;
      }

      if (tank.litersNumber <= 0) {
        return false;
      }
    }

    return true;
  };

  const getStockingsInBatch = () => {
    const stockingsInBatchCopy = cloneDeep(stockingsInBatch);
    const tanks = stockingsInBatchCopy.tanks.filter((tank) => tank.name || tank.naupliusNumber > 0 || tank.maturationId);
    stockingsInBatchCopy.tanks = tanks;
    return stockingsInBatchCopy;
  };

  const validateFormBatch = () => {
    const stockingsInBatch = getStockingsInBatch();
    const disabled = !isValidStockingsInBatchData(stockingsInBatch);
    setDisabledButtonFormNewStocking(disabled);
  };

  const validateSelectedForm = (currentTab: string) => {
    switch (currentTab) {
      case TABS.INDIVIDUAL: {
        const formFields = Object.keys(formNewStockingLarvae.getFieldsValue());

        if (formFields.length) {
          validateFormLarvae();
        } else {
          setDisabledButtonFormNewStocking(true);
        }
        break;
      }

      case TABS.BATCH: {
        validateFormBatch();
        break;
      }
    }
  };

  const getDataStockingLarvae = async () => {
    const newStocking: StockingLarvae = {
      naupliusNumber: Number(newStockingNumberNauplii),
      startDate: newStockingStartDate,
      litersNumber: Number(newStockingLiters),
      campusId: newStockingCampus,
      tankId: newStockingTank,
      name: newStockingName,
      code: newStockingCode,
      userId: userSession._id,
      maturationId: newStockingMaturation,
      invoiceImg: reducedImage,
      customStage: {
        key: newStockingStage.key,
        value: newStockingStage.value,
      },
      phaseType: stockingPhaseTypes.LARVAE,
      referenceCurveId: newStockingReference,
      daysToInitialStage: newDaysToInitialStage,
    };

    if (newStockingMaturationCode) {
      newStocking.maturationCode = newStockingMaturationCode;
    }

    if (hasBindingStockingLarvae) {
      const bindingStockings: BindingStocking[] = cloneDeep(newBindingStockingsLarvae);
      for (let index = 0; index < bindingStockings.length; index++) {
        const newBindingStocking = bindingStockings[index];

        // eslint-disable-next-line
        if (isValidStockingBindingRow(newBindingStocking)) {
          const itemBindingStocking = await stockingSlice.fetchStockingByBindingCode({ code: newBindingStocking.bindingCode, index, bindingStockings });
          bindingStockings[index] = itemBindingStocking;

          validateFormLarvae();
        }
      }

      setNewBindingStockingsLarvae(bindingStockings);
      const isValid = isValidStockingBinding(bindingStockings);
      if (!isValid) {
        openErrorNotification(t('stockings.invalidBindingStocking'));
        return;
      }

      const animales = getTotalAnimals(bindingStockings);
      const bindingStocking = getBindingStockingByMaxAnimals(bindingStockings);

      newStocking.bindingStockings = bindingStockings;
      newStocking.maturationId = bindingStocking.maturationId;
      newStocking.maturationCode = bindingStocking.maturationCode;
      newStocking.naupliusNumber = animales;
    }

    return newStocking;
  };

  const createStocking = async () => {
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId, tankId, page: currentPage, stockingsToShow, phaseType: phaseType };

    switch (currentTab) {
      case TABS.INDIVIDUAL: {
        const newStocking = await getDataStockingLarvae();
        if (newStocking) {
          dispatch(stockingSlice.createStocking({ stockingData: newStocking, paramsToFetchStocking, closeModal }));
        }

        if (isRunningOnboarding) {
          dispatch(goToOnboardingNextStep(2000));
        }
        break;
      }

      case TABS.BATCH: {
        const stockingsInBatch = getStockingsInBatch();
        dispatch(stockingSlice.createStockings({ data: stockingsInBatch, paramsToFetchStocking, closeModal }));
        break;
      }
    }
  };

  const renderBindingStockingRows = () => {
    const columns: ColumnsType<BindingStocking> = [
      {
        title: t('stockings.bindingCode'),
        dataIndex: 'bindingCode',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInput
                theme='light'
                value={record.bindingCode}
                className={styles.input}
                maxLength={5}
                onChange={(e) => {
                  if (e.target.value == null || e.target.value == undefined) {
                    return;
                  }

                  onChangeBindingCodeLarvae(record, e.target.value);
                }}
              />

              <ErrorLabelRow hasError={record.validatedCode && !record.isValid} />
            </>
          );
        },
      },
      {
        title: t('stockings.sownAnimals'),
        dataIndex: 'animals',
        width: '45%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.animals}
                className={styles.input}
                min={0}
                formatter={value => applyThousandsSeparator(value)}
                parser={value => applyParserThousandsSeparator(value)}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  onChangeAnimalsLarvae(record, value.toString());
                }}
              />
              <ErrorLabelRow />
            </>
          );
        },
      },
      {
        title: '',
        dataIndex: 'remove',
        width: '10%',
        render: (_, record: BindingStocking) => {
          return (
            <>
              <Space className={styles.bindingCode} size='small'>
                <LrvTooltip
                  themeStyle={isLightTheme ? 'dark' : 'light'}
                  title={t('stockings.delete')}
                >
                  <ActionButton
                    type='text'
                    icon={<Icon name='delete-bin' type='line' theme={theme} />}
                    onClick={() => {
                      onDeleteLarvaeRow(record.key);
                    }}
                  />
                </LrvTooltip>
              </Space>
              <ErrorLabelRow />
            </>
          );
        },
      },
    ];

    return (
      <BindingStockingTable columns={columns} dataSource={getDataBindingStocking()} />
    );
  };

  const validateCreateStockingForm = (validFormFields: string[]) => {
    const disabled = !isFieldsTouchedCustom(formNewStockingLarvae, validFormFields) || formNewStockingLarvae.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledButtonFormNewStocking(disabled);
  };

  const onChangeBindingCodeLarvae = (record: BindingStocking, bindingCode: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsLarvae);
    record.isValid = false;
    record.bindingCode = bindingCode;
    record.phaseType = stockingPhaseTypes.LARVAE;
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsLarvae(bindingStockingCopy);
  };

  const onChangeAnimalsLarvae = (record: BindingStocking, value: string) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsLarvae);
    record.isValid = false;
    record.animals = parseInt(value);
    bindingStockingCopy[record.key] = record;
    setNewBindingStockingsLarvae(bindingStockingCopy);
  };

  const onDeleteLarvaeRow = (key: number) => {
    const bindingStockingCopy: BindingStocking[] = cloneDeep(newBindingStockingsLarvae);
    const newBindingStocking = bindingStockingCopy.filter((item, index) => index !== key);

    if (newBindingStocking.length === 0) {
      newBindingStocking.push(initialBindingStocking);
    }

    setNewBindingStockingsLarvae(newBindingStocking);
  };

  const getDataBindingStocking = () => {
    return getBindingStockingData(newBindingStockingsLarvae);
  };

  const addRowBindingStockingLarvae = () => {
    if (newBindingStockingsLarvae.length >= maximumNumberOfStockings) {
      openErrorNotification(t('stockings.maximumNumberOfStockings'));
      return;
    }

    const data: BindingStocking[] = [...newBindingStockingsLarvae];
    data.push(initialBindingStocking);
    setNewBindingStockingsLarvae(data);
  };

  const renderNewBindingStockingButton = () => {
    return (
      <NewBindingStockingButton
        theme={theme}
        bindingStockings={newBindingStockingsLarvae}
        onClick={() => {
          if (stockingBindingRowIsFull(newBindingStockingsLarvae)) {
            addRowBindingStockingLarvae();
          }
        }}
      />
    );
  };

  const renderReferenceCurve = () => {
    return (
      <Row gutter={16} className={styles.referenceCurve}>
        <Col span={24}>
          <Form.Item className={styles.formItem}>
            <LrvText
              className={styles.text}
              theme='light'
              text={t('stockings.referenceDescription')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name='parameter'
            label={`${t('production.filters.parameter')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingParameter}
              onChange={onChangeStockingParameter}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
            >
              <Option value={typeParam.PLG}>{t('analysis.resultData.larvaePerGram')}</Option>
              <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
              <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
              <Option value={typeParam.UNIFORMITY}>{t('analysis.resultData.uniformity')}</Option>
            </LrvSelect>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='referenceCurveId'
            label={`${t('stockings.reference')} (${t('common.optional')})`}
          >
            <LrvSelect
              theme={theme}
              autoFocus
              showSearch
              value={newStockingReference}
              onChange={onChangeStockingReference}
              suffixIcon={<Icon name='arrow-down-s' />}
              filterOption={filterOptionSelect}
              dropdownMatchSelectWidth={false}
              disabled={!newStockingParameter}
            >
              {referenceCurves.map((reference) => <Option key={reference._id} value={reference._id}>{reference.name}</Option>)}
            </LrvSelect>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const tanks = cloneDeep(tanksForm);
  tanks.sort(sortTankStatus);

  const renderNaupliusNumber = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='numberNauplii'
          label={t('stockings.numberNauplii')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={newStockingNumberNauplii}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setNewStockingNumberNauplii(value);
              }
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderDaysToInitialStage = () => {
    if (newStockingStage.key !== 'NAUPLII') {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='daysToInitialStage'
          label={t('clients.daysToInitialStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_TO_INITIAL_STAGE}
            max={MAX_DAYS_TO_INITIAL_STAGE}
            value={newDaysToInitialStage}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setNewDaysToInitialStage(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  return (
    <LrvModal
      title={t('stockings.newStocking')}
      theme={theme}
      className={cx(styles.newStockingModal, 'newStockingModal')}
      open={showCreateModalLarvae}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_new_stocking', disabled: disabledButtonFormNewStocking, htmlType: 'submit', form: 'formNewStockingLarvae', loading: isLoadingCreate }}
      cancelButtonProps={{ id: 'cancel_new_stocking' }}
      onOk={createStocking}
      okText={t('stockings.create')}
      cancelText={t('stockings.cancel')}
      onCancel={() => {
        closeModal();
      }}
    >
      <LrvTabs
        theme={theme}
        activeKey={currentTab}
        defaultActiveKey={currentTab}
        onChange={(activeKey) => {
          setCurrentTab(activeKey);

          const unitPhaseType = getUnitPhaseTypeFromStocking(activeKey);
          const campusesList: Campus[] = campusesForm.filter((campus) => campus.phaseType === unitPhaseType);
          campusesList.sort(sortByName);

          setCampusListForStockingCreation(campusesList);
          setCurrentTypeOfStockingCreation(activeKey);
          validateSelectedForm(activeKey);
          cleanImage();
        }}
      >
        <TabPane tab={t('stockings.tabs.individual')} key={TABS.INDIVIDUAL}>
          <LrvForm
            theme={theme}
            form={formNewStockingLarvae}
            name='formNewStockingLarvae'
            id='formNewStockingLarvae'
            layout='vertical'
            onFieldsChange={() => {
              validateFormLarvae();
            }}
          >
            {renderBindingStocking()}

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='campusId'
                  label={t('stockings.campus')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvSelect
                    theme={theme}
                    autoFocus
                    showSearch
                    value={newStockingCampus}
                    onChange={onChangeStockingCampus}
                    suffixIcon={<Icon name='arrow-down-s' />}
                    filterOption={filterOptionSelect}
                    dropdownMatchSelectWidth={false}
                  >
                    {campusListForStockingCreation.map((campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
                  </LrvSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='moduleId'
                  label={t('stockings.module')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvSelect
                    theme={theme}
                    showSearch
                    value={newStockingModule}
                    onChange={onChangeStockingModule}
                    suffixIcon={<Icon name='arrow-down-s' />}
                    disabled={!newStockingCampus}
                    filterOption={filterOptionSelect}
                    dropdownMatchSelectWidth={false}
                  >
                    {modulesForm.map((module) => {
                      if (module.phaseType === phaseType) {
                        return <Option key={module._id} value={module._id}>{module.name}</Option>;
                      }
                    })}
                  </LrvSelect>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='tankId'
                  label={t('stockings.containerTypes.LARVAE')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvSelect
                    theme={theme}
                    showSearch
                    value={newStockingTank}
                    onChange={onChangeStockingTank}
                    suffixIcon={<Icon name='arrow-down-s' />}
                    disabled={!newStockingModule}
                    filterOption={filterOptionSelect}
                    dropdownMatchSelectWidth={false}
                  >
                    {tanks.map((tank) => <Option disabled={tank.status !== tankStatuses.AVAILABLE} key={tank._id} value={tank._id}>{tank.name}</Option>)}
                  </LrvSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='code'
                  label={<ProductionCycleInfo tankId={newStockingTank} label={t('stockings.productionCycle')} />}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvInput
                    theme={theme}
                    value={newStockingCode}
                    style={{ textTransform: 'uppercase' }}
                    maxLength={stockingCodeLength}
                    onChange={(e) => setNewStockingCode(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='name'
                  label={t('stockings.name')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvInput
                    theme={theme}
                    value={newStockingName}
                    onChange={(e) => setNewStockingName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='startDate'
                  label={t('stockings.startDate')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvDatePicker
                    theme={theme}
                    disabledDate={(currentDate) => disabledDateLarvae({ currentDate, stockingDateRanges })}
                    placeholder=''
                    onChange={(date, dateString) => setNewStockingStartDate(dateString)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name='customStage'
                  label={t('stockings.customStage')}
                  rules={[{ required: true, message: t('common.requiredField') }]}
                >
                  <LrvSelect
                    theme={theme}
                    showSearch
                    value={newStockingStage}
                    onChange={(key) => {
                      const value = stageOptions[key];
                      setNewStockingStage({ key, value });
                    }}
                    suffixIcon={<Icon name='arrow-down-s' />}
                    filterOption={filterOptionSelect}
                    dropdownMatchSelectWidth={false}
                  >
                    {Object.entries(stageOptions).map(([key,]) => (
                      <Option key={key} value={key}>{key}</Option>
                    ))}
                  </LrvSelect>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name='liters'
                  label={t('stockings.liters')}
                  required
                  rules={
                    containerSelected.type === '' ?
                      [() => ({ validator (rule, value) { return validateNumber(value, true, 0); } })] :
                      [() => ({ validator (rule, value) { return validateNumber(value, true, containerVolumeLimits[containerSelected.type].min, containerVolumeLimits[containerSelected.type].max, t('stockings.volumeError', { min: containerVolumeLimits[containerSelected.type].min, max: containerVolumeLimits[containerSelected.type].max })); } })]
                  }
                >
                  <LrvInputNumber
                    theme={theme}
                    value={newStockingLiters}
                    formatter={value => applyThousandsSeparator(value)}
                    parser={value => applyParserThousandsSeparator(value)}
                    onChange={(value) => {
                      if (value) {
                        setNewStockingLiters(value);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {
              !hasBindingStockingLarvae &&
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name='maturation'
                    label={t('stockings.maturation')}
                    rules={[{ required: true, message: t('common.requiredField') }]}
                  >
                    <LrvSelect
                      theme={theme}
                      showSearch
                      value={newStockingMaturation}
                      onChange={onChangeMaturationLarvae}
                      suffixIcon={<Icon name='arrow-down-s' />}
                      filterOption={filterOptionSelect}
                      dropdownMatchSelectWidth={false}
                    >
                      {maturations.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.name}</Option>)}
                    </LrvSelect>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name='maturationCode'
                    label={`${t('stockings.maturationCode')} (${t('common.optional')})`}
                  >
                    <LrvSelect
                      theme={theme}
                      showSearch
                      mode='tags'
                      disabled={!newStockingMaturation}
                      value={newStockingMaturationCode || undefined}
                      onSelect={(value) => {
                        const valueFound: MaturationCode | undefined = maturationCodes.find(item => item._id === value || item.code.toLowerCase() === value.toLowerCase());
                        const newValue: string = valueFound?._id ?? value;

                        setNewStockingMaturationCode(newValue);
                        formNewStockingLarvae.setFieldsValue({ maturationCode: newValue });
                      }}
                      onDeselect={() => {
                        setNewStockingMaturationCode(undefined);
                        formNewStockingLarvae.setFieldsValue({ maturationCode: undefined });
                      }}
                      suffixIcon={<Icon name='arrow-down-s' />}
                      filterOption={filterOptionSelect}
                      dropdownMatchSelectWidth={false}
                      optionFilterProp='children'
                    >
                      {maturationCodes && maturationCodes.map((maturation) => <Option key={maturation._id} value={maturation._id}>{maturation.code}</Option>)}
                    </LrvSelect>
                  </Form.Item>
                </Col>
              </Row>
            }

            <Row gutter={16}>
              {!hasBindingStockingLarvae && renderNaupliusNumber()}
              {renderDaysToInitialStage()}
            </Row>

            {
              hasBindingStockingLarvae &&
              <>
                {renderBindingStockingRows()}
                {renderNewBindingStockingButton()}
              </>
            }

            {renderReferenceCurve()}

            <Form.Item
              name='invoiceImg'
              label={`${t('stockings.naupliusReferralGuide')} (${t('common.optional')})`}
              rules={[{ required: false }]}
            >
              <div id='nauplius_referral_guide'>
                <LrvDragger
                  theme={theme}
                  name='file'
                  accept='image/png, image/jpeg'
                  maxCount={1}
                  listType='picture-card'
                  showUploadList={false}
                  beforeUpload={() => true}
                  onChange={handleOnChange}
                  customRequest={handleCustomRequest}
                >
                  {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
                </LrvDragger>

                <canvas className={styles.canvasInvoice} ref={canvasRef} width={voucherWidth} height={voucherHeight} id='canvas' />
              </div>
            </Form.Item>
          </LrvForm>
        </TabPane>

        <TabPane tab={t('stockings.tabs.batch')} key={TABS.BATCH}>
          <NewStockingLarvaeBatch
            theme='light'
            campuses={campusListForStockingCreation}
            validateFormBatch={validateFormBatch}
            modules={modulesForm}
            stockingsInBatch={stockingsInBatch}
            setStockingsInBatch={setStockingsInBatch}
          />
        </TabPane>
      </LrvTabs>
    </LrvModal>
  );
};
