import cx from 'classnames';
import { Popover } from 'antd';

import { LrvPopoverProps } from '../../interfaces/lrvPopover';

import styles from './LrvPopover.module.scss';

export const LrvPopover = (props: LrvPopoverProps) => {
  const { className, overlayClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkPopover : styles.lightPopover, className),
    overlayClassName: cx(isDarkTheme ? styles.darkOverlay : styles.lightOverlay, overlayClassName),
  };

  return (
    <Popover
      {...lrvProps}
      openClassName={styles.test}
    >
    </Popover>
  );
};
