import cx from 'classnames';
import { Radio } from 'antd';

import { LrvRadioButtonProps } from '../../interfaces/lrvRadioButton';

import styles from './LrvRadioButton.module.scss';

const { Button } = Radio;

export const LrvRadioButton = (props: LrvRadioButtonProps) => {
  const { className, containerClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(styles.radioButton, isDarkTheme ? styles.darkRadioButton : styles.lightRadioButton, className),
  };

  return (
    <div className={cx(isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}>
      <Button {...lrvProps}>
      </Button>
    </div>
  );
};
