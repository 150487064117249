import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { plansTypes } from '../../config/commons';
import { ApiClient } from '../../utils/axios_instance';
import { CLIENTS_PUBLIC_COUPON_URL, CLIENTS_PUBLIC_URL } from '../../config/config.api';

import { RegisterClientState, RegisterForm } from './interfaces';

const initialState: RegisterClientState = {
  message: '',
  okCode: 0,
  isLoading: false,
};

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const registerSlice = createSlice({
  name: 'registerClient',
  initialState,
  reducers: {
    setIsLoading: (state: RegisterClientState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMessage: (state: RegisterClientState, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOkCode: (state: RegisterClientState, action: PayloadAction<number>) => {
      state.okCode = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setMessage,
  setOkCode,
} = registerSlice.actions;

const getErrorMessage = (errorData: string) => (dispatch: Function) => {
  switch (errorData) {
    case 'companyNameDuplicate':
      dispatch(setMessage('register.companyNameDuplicate'));
      break;

    case 'emailDuplicate':
      dispatch(setMessage('register.emailDuplicate'));
      break;

    default:
      dispatch(setMessage('register.error500'));
      break;
  }
};

export const registerClient = (data: RegisterForm) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  const body = {
    email: data.email,
    password: data.password,
    companyName: data.companyName,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    phonePrefix: data.phonePrefix,
    countryCode: data.countryCode,
    planType: plansTypes.POSTPAID,
  };

  try {
    const response = await axios.post(CLIENTS_PUBLIC_URL, body);
    dispatch(setOkCode(response.status));
  } catch (error) {
    if (error.response?.status) {
      const errorData = error.response.data?.data?.error;
      dispatch(getErrorMessage(errorData));
    }
  }

  dispatch(setIsLoading(false));
};

export const registerClientWithCoupon = (data: RegisterForm) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  const body = {
    email: data.email,
    password: data.password,
    companyName: data.companyName,
    firstName: data.firstName,
    lastName: data.lastName,
    phonePrefix: data.phonePrefix,
    phone: data.phone,
    countryCode: data.countryCode,
    planType: plansTypes.POSTPAID,
    code: data.code,
  };

  try {
    await axios.post(CLIENTS_PUBLIC_COUPON_URL, body);
    window.location.href = '/login';
  } catch (error) {
    if (error.response?.status) {
      const errorData = error.response.data?.data?.error;
      dispatch(getErrorMessage(errorData));
    }
  }

  dispatch(setIsLoading(false));
};

export default registerSlice.reducer;
