export function handleResizeEvent (callback: Function) {
  let resizeTimer: number;

  const handleResize = () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(callback, 300);
  };
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}

export function getParentElementWidth (ref: React.RefObject<HTMLDivElement>) {
  if (ref.current && ref.current.parentElement) {
    return ref.current.parentElement.offsetWidth;
  }
  return 1000;
}

export function getParentElementHeight (ref: React.RefObject<HTMLDivElement>) {
  if (ref.current && ref.current.parentElement) {
    return ref.current.parentElement.offsetHeight;
  }
  return 1000;
}


export const getCurrentElementHeight = (element: React.RefObject<HTMLDivElement>) => {
  return element?.current?.offsetHeight || 0;
};