import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { USERS_URL } from '../../../config/config.api';
import { Store } from '../../../state/store.interfaces';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { getPayloadToken, onboardingTypes } from '../../../config/commons';
import { GenericParam } from '../../interfaces/commons';

import { OnboardingState, OnboardingStep, OnboardingUserData } from './interfaces';

const initialState: OnboardingState = {
  run: false,
  steps: [],
  stepIndex: 0,
  shouldRun: false,
  onboardingType: '',
  user: {
    _id: '',
    firstName: '',
    lastName: '',
    roles: [],
    onboarding: {
      initConfig: false,
      maturationSection: false,
      unitSection: false,
      stockingSection: false,
      clientDashboard: false,
      usersSection: false,
      prepaidBalanceSection: false,
      clientsSection: false,
      paypalSection: false,
      administrationHistory: false,
    }
  },
  isContinuous: false,
  existsUserData: false,
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setRun: (state: OnboardingState, action: PayloadAction<boolean>) => {
      state.run = action.payload;
    },
    setShouldRun: (state: OnboardingState, action: PayloadAction<boolean>) => {
      state.shouldRun = action.payload;
    },
    setSteps: (state: OnboardingState, action: PayloadAction<OnboardingStep[]>) => {
      state.steps = action.payload;
    },
    setStepIndex: (state: OnboardingState, action: PayloadAction<number>) => {
      state.stepIndex = action.payload;
    },
    setUser: (state: OnboardingState, action: PayloadAction<OnboardingUserData>) => {
      state.user = action.payload;
    },
    setOnboardingType: (state: OnboardingState, action: PayloadAction<string>) => {
      state.onboardingType = action.payload;
    },
    setIsContinuous: (state: OnboardingState, action: PayloadAction<boolean>) => {
      state.isContinuous = action.payload;
    },
    setExistsUserData: (state: OnboardingState, action: PayloadAction<boolean>) => {
      state.existsUserData = action.payload;
    },
  },
});

export const {
  setRun,
  setShouldRun,
  setSteps,
  setStepIndex,
  setUser,
  setOnboardingType,
  setIsContinuous,
  setExistsUserData,
} = onboardingSlice.actions;

export const goToOnboardingStep = (step: number, delay?: number) => (dispatch: Function) => {
  if (delay) {
    setTimeout(() => {
      dispatch(setStepIndex(step));
    }, delay);
    return;
  }

  dispatch(setStepIndex(step));
};

export const goToOnboardingNextStep = (delay?: number) => (dispatch: Function, getState: Function) => {
  const currentStore: Store = getState();
  const currentStep = currentStore.onboarding.stepIndex;
  const nextStep = currentStep + 1;

  if (delay) {
    setTimeout(() => {
      dispatch(setStepIndex(nextStep));
    }, delay);
    return;
  }

  dispatch(setStepIndex(nextStep));
};

export const repeatOnboardingStep = () => (dispatch: Function, getState: Function) => {
  const currentStore: Store = getState();
  const currentStep = currentStore.onboarding.stepIndex;

  setTimeout(() => {
    dispatch(setStepIndex(currentStep));
  }, 500);
  return;
};

export const startOnboarding = () => (dispatch: Function) => {
  dispatch(setStepIndex(0));
  dispatch(setRun(true));
};

export const endOnboarding = () => (dispatch: Function) => {
  dispatch(setRun(false));
  dispatch(setShouldRun(false));
};

export const fetchOnboardingUserData = () => async (dispatch: Function) => {
  const paramsUser = {
    $select: ['_id', 'firstName', 'lastName', 'rolesId', 'onboarding']
  };
  dispatch(setExistsUserData(false));

  try {
    const sessionData = getPayloadToken();
    const response = await axios.get(`${USERS_URL}/${sessionData.sub}`, { params: paramsUser });
    dispatch(setUser(response.data));
    dispatch(setExistsUserData(true));
  } catch (e) {
    console.log(e?.response);
  }
};

const getOnboardingUserData = (props: { companyId: string; currentStore: Store }) => {
  const { companyId, currentStore } = props;

  const onboardingType = currentStore.onboarding.onboardingType;
  const onboardingData = currentStore.onboarding.user.onboarding;

  const patchData: GenericParam = {
    companyId,
    onboarding: {},
  };

  if (onboardingData) {
    patchData.onboarding = { ...onboardingData };
  }

  switch (onboardingType) {
    case onboardingTypes.INIT_CONFIG:
      patchData.onboarding.initConfig = true;
      break;
    case onboardingTypes.MATURATION_SECTION:
      patchData.onboarding.maturationSection = true;
      break;
    case onboardingTypes.UNIT_SECTION:
      patchData.onboarding.unitSection = true;
      break;
    case onboardingTypes.STOCKING_SECTION:
      patchData.onboarding.stockingSection = true;
      break;
    case onboardingTypes.CLIENT_DASHBOARD:
      patchData.onboarding.clientDashboard = true;
      break;
    case onboardingTypes.USERS_SECTION:
      patchData.onboarding.usersSection = true;
      break;
    case onboardingTypes.PREPAID_BALANCE_SECTION:
      patchData.onboarding.prepaidBalanceSection = true;
      break;
    case onboardingTypes.CLIENTS_SECTION:
      patchData.onboarding.clientsSection = true;
      break;
    case onboardingTypes.PAYPAL_SECTION:
      patchData.onboarding.paypalSection = true;
      break;
    case onboardingTypes.ADMINISTRATION_HISTORY:
      patchData.onboarding.administrationHistory = true;
      break;
  }

  return patchData;
};

export const saveOnboardingUserData = () => async (dispatch: Function, getState: Function) => {
  dispatch(endOnboarding());

  try {
    const sessionData = getPayloadToken();
    const currentStore: Store = getState();
    const patchData = getOnboardingUserData({ companyId: sessionData.companyId, currentStore });

    await axios.patch(`${USERS_URL}/${sessionData.sub}`, patchData);

    const newUserData = { ...currentStore.onboarding.user };
    newUserData.onboarding = patchData.onboarding;

    dispatch(setUser(newUserData));
  } catch (e) {
    console.log(e?.response);
  }
};

export default onboardingSlice.reducer;
