import { clone } from 'lodash';
import i18next from 'i18next';

import { stockingPhaseTypes } from '../../../config/commons';

import { AnalysisCardCustomMobile } from './interfaces';

export const commonDetailCustom: AnalysisCardCustomMobile[] = [
  'uniformityWeight', 'uniformityLength', 'larvaeNumber',
  'averageWeight', 'averageLength', 'stage',
  'variationCoefficientWeight', 'variationCoefficientLength'
];

export const larvaeDetailCustom: AnalysisCardCustomMobile[] = [...commonDetailCustom, 'larvaePerGram'];
export const juvenileDetailCustom: AnalysisCardCustomMobile[] = [...commonDetailCustom, 'avgGrowth'];
export const growOutDetailCustom: AnalysisCardCustomMobile[] = [...commonDetailCustom, 'avgGrowth'];

export const commonDetailDefault: AnalysisCardCustomMobile[] = [
  'stage', 'uniformityWeight', 'larvaeNumber', 'averageWeight',
];

export const larvaeAnalysisMobileDefault: AnalysisCardCustomMobile[] = clone(commonDetailDefault);
export const juvenileAnalysisMobileDefault: AnalysisCardCustomMobile[] = clone(commonDetailDefault);
export const growOutAnalysisMobileDefault: AnalysisCardCustomMobile[] = clone(commonDetailDefault);

export const getKeysAnalysisDetailCustomMobile = (props: { phaseType: string; fields: AnalysisCardCustomMobile[] }) => {
  const { phaseType, fields } = props;

  if (fields.length === 0) {
    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
      default: {
        const otherKeys = larvaeDetailCustom.filter(element => !larvaeAnalysisMobileDefault.includes(element));
        return {
          keysSelected: larvaeAnalysisMobileDefault,
          otherKeys,
        };
      }

      case stockingPhaseTypes.JUVENILE: {
        const otherKeys = juvenileDetailCustom.filter(element => !juvenileAnalysisMobileDefault.includes(element));
        return {
          keysSelected: juvenileAnalysisMobileDefault,
          otherKeys,
        };
      }

      case stockingPhaseTypes.ADULT: {
        const otherKeys = growOutDetailCustom.filter(element => !growOutAnalysisMobileDefault.includes(element));
        return {
          keysSelected: growOutAnalysisMobileDefault,
          otherKeys,
        };
      }
    }
  }

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default: {
      const otherKeys = larvaeDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }

    case stockingPhaseTypes.JUVENILE: {
      const otherKeys = juvenileDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }

    case stockingPhaseTypes.ADULT: {
      const otherKeys = growOutDetailCustom.filter(element => !fields.includes(element));

      return {
        keysSelected: fields,
        otherKeys,
      };
    }
  }
};

export const getLabelForKey = (props: { phaseType: string; field: AnalysisCardCustomMobile; }) => {
  const { phaseType, field } = props;

  switch (field) {
    case 'averageLength':
      return i18next.t('analysis.resultData.averageLength');

    case 'averageWeight':
      return i18next.t('analysis.resultData.averageWeight');

    case 'avgGrowth':
      return i18next.t('analysis.resultData.avgGrowth');

    case 'larvaePerGram':
      return i18next.t('analysis.resultData.larvaePerGram');

    case 'stage': {
      switch (phaseType) {
        case stockingPhaseTypes.LARVAE:
          return i18next.t('analysis.inputData.larvaeStage');

        default:
          return i18next.t('analysis.inputData.juvenileStage');
      }
    }

    case 'uniformityLength':
      return i18next.t('analysis.resultData.uniformityLength');

    case 'uniformityWeight':
      return i18next.t('analysis.resultData.uniformityWeight');

    case 'variationCoefficientWeight':
      return i18next.t('analysis.resultData.variationCoefficient');

    case 'variationCoefficientLength':
      return i18next.t('analysis.resultData.variationCoefficientLength');

    case 'larvaeNumber': {
      switch (phaseType) {
        case stockingPhaseTypes.LARVAE:
          return i18next.t('analysis.resultData.larvaeNumber');

        default:
          return i18next.t('analysis.resultData.juvenileNumber');
      }
    }

    default:
      return '';
  }
};
