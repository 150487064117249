import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { fetchTanksQR, setTanksQr } from '../../Tanks/tanksSlice';
import { Store } from '../../../state/store.interfaces';

import TankQRCode from './TankQRCode';
import styles from './TanksPdf.module.scss';

type TParams = { accessToken: string };

export default function TanksPdf ({ match }: RouteComponentProps<TParams>) {
  const pageHeight = 1123;
  const { accessToken } = match.params;

  const { tanksQR, isTanksQrLoading } = useSelector((state: Store) => state.tanks);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTanksQR(accessToken));
    return () => {
      dispatch(setTanksQr([]));
    };
  }, [dispatch, accessToken]);

  const renderPage = (props: { header: string; content: JSX.Element[]; numPage: number }) => {
    const { header, content, numPage } = props;
    return (
      <div style={{ height: pageHeight }} className={styles.containerPage}>
        <div className={styles.headerQrTanks}>{header}</div>
        {content}
        <div className={styles.pageNumber} >Pg. {numPage}</div>
      </div>
    );
  };

  const renderQrRow = (content: JSX.Element[]) => {
    return (
      <div className={styles.containerRow}>
        {content}
      </div>
    );
  };

  const renderQRTank = () => {
    const pages: JSX.Element[] = [];

    let prevModule = '';
    let content: JSX.Element[] = [];
    let rows: JSX.Element[] = [];
    let numPage = 1;
    let isNextANewModule = false;

    const tanksQRSorted = tanksQR.slice().sort((a, b) => a.campusId.name.localeCompare(b.campusId.name) || a.moduleId.name.localeCompare(b.moduleId.name));

    tanksQRSorted.forEach((tankQR, index) => {
      const { campusId, moduleId } = tankQR;

      if (index === 0) prevModule = moduleId._id;
      if (index < tanksQRSorted.length) isNextANewModule = prevModule !== tanksQRSorted[index + 1]?.moduleId?._id ?? '';

      const row = <TankQRCode id={tankQR._id} tankName={tankQR.name} />;
      rows.push(row);

      if (rows.length === 3 || (index + 1) === tanksQRSorted.length || isNextANewModule) {
        content.push(renderQrRow(rows));
        rows = [];
        if (content.length === 3 || isNextANewModule) {
          const header = `${campusId.name} > ${moduleId.name}`;
          const page = renderPage({ header, content, numPage });
          numPage += 1;
          content = [];
          prevModule = isNextANewModule ? tanksQRSorted[index + 1]?.moduleId?._id ?? '' : prevModule;
          pages.push(page);
        }
      }
    });
    return pages;
  };

  if (isTanksQrLoading) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  return (
    <div id='tanksQR'
      className={styles.containerMain}
    >
      {renderQRTank()}
    </div>
  );
}