import { Location } from '../../Tanks/interfaces';

import { Container } from './interfaces';

export const getLocationContainers = (props: { locatedContainers: Container[]; currentLocation: Location | undefined }) => {
  const { currentLocation, locatedContainers } = props;

  if (locatedContainers.length > 0) {
    return {
      latitude: locatedContainers[0]?.latitude || 0,
      longitude: locatedContainers[0]?.longitude || 0,
    };
  }

  return {
    latitude: currentLocation?.latitude || 0,
    longitude: currentLocation?.longitude || 0,
  };
};

export const getUpdatedContainers = (props: { locatedContainers: Container[]; }) => {
  const { locatedContainers } = props;

  const updatedContainers = locatedContainers.filter((container) => container.isLocationUpdated);
  return updatedContainers;
};

export const disabledSaveButton = (props: { locatedContainers: Container[]; }) => {
  const { locatedContainers } = props;

  const updatedContainers = getUpdatedContainers({ locatedContainers });
  return updatedContainers.length === 0;
};
