import React from 'react';
import cx from 'classnames';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

import { removeSpaces } from '../../../utils/strings';
import { Breadcrumb as IBreadcrumb } from '../../../pages/AppHeader/interfaces';

import styles from './Breadcrumbs.module.scss';

interface Props {
  breadcrumb: IBreadcrumb[];
  separator?: React.ReactNode;
  noCapitalize?: boolean;
  theme?: 'dark' | 'light';
}

export default function Breadcrumbs (props: Props) {
  const { breadcrumb, separator, noCapitalize = false, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const breadcrumbItems = breadcrumb.map((bread, index) => {
    const url = bread.url;
    const nameWithoutSpaces = removeSpaces(bread.id.toLowerCase());
    const idLink = `breadcrumb_${nameWithoutSpaces}`;
    const isLastBredcrumb = index === (breadcrumb.length - 1);

    if (isLastBredcrumb) {
      return (
        <Breadcrumb.Item className={isLastBredcrumb ? styles.lastBreadcrumb : ''} key={url}>
          <span className={cx(isDarkTheme ? styles.linkDark : styles.linkLight)} id={idLink}>{noCapitalize ? bread.name : (bread.name)}</span>
        </Breadcrumb.Item>
      );
    }

    return (
      <Breadcrumb.Item className={isLastBredcrumb ? styles.lastBreadcrumb : ''} key={url}>
        <Link className={cx(styles.link, isDarkTheme ? styles.linkDark : styles.linkLight)} id={idLink} to={url}>{noCapitalize ? bread.name : (bread.name)}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb
      className={cx(styles.breadcrumb, isDarkTheme ? styles.breadcrumbDark : styles.breadcrumbLight)}
      separator={separator || '/'}
    >
      {breadcrumbItems}
    </Breadcrumb>
  );
}
