import { Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import { LANGUAGES, stockingPhaseTypes } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { validateEmail, validateString } from '../../utils/validations';
import ActionButton from '../../common/components/buttons/ActionButton';
import { setPhaseType } from '../AppHeader/headerSlice';

import styles from './TabFormEdit.module.scss';
import { updateProfile } from './settingsSlice';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabFormEdit (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [formEditProfile] = Form.useForm();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [language, setLanguage] = useState<string>('');
  const [defaultPhase, setDefaultPhase] = useState<string>('');
  const [disabledButtonAccount, setDisabledButtonAccount] = useState(false);

  const profile = useSelector((state: Store) => state.profile);

  useEffect(() => {
    setEmail(profile.email);
    setFirstName(profile.firstName);
    setLastName(profile.lastName);
    setLanguage(profile.language);
    setDefaultPhase(profile.defaultPhase);

    formEditProfile.setFieldsValue({
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      language: t(`language.${profile.language.toLowerCase()}`),
      defaultPhase: profile.defaultPhase,
    });
  }, [formEditProfile, profile]);

  return (
    <LrvForm
      id='formEditProfile'
      form={formEditProfile}
      name='formEditProfile'
      layout='vertical'
      theme={theme}
      onFinish={() => {
        if (email !== '' &&
          firstName !== '' &&
          language !== '' &&
          lastName !== '' &&
          defaultPhase !== ''
        ) {
          const user = { firstName, lastName, language, defaultPhase };
          i18n.changeLanguage(language.toLowerCase());
          dispatch(setPhaseType(defaultPhase));
          updateProfile(user);
        }
      }}
      onFieldsChange={() =>
        setDisabledButtonAccount(
          formEditProfile.getFieldsError().filter(({ errors }) => errors.length).length > 0
        )
      }
    >
      <Form.Item
        name='email'
        required
        label={t('users.email')}
        rules={[() => ({ validator (rule, value) { return validateEmail(value); } })]}
      >
        <LrvInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled
          theme={theme}
        />
      </Form.Item>

      <Form.Item
        name='firstName'
        required
        label={t('users.firstName')}
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvInput
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          theme={theme}
        />
      </Form.Item>

      <Form.Item
        name='lastName'
        required
        label={t('users.lastName')}
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvInput
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          theme={theme}
        />
      </Form.Item>

      <Form.Item
        name='language'
        required
        label={t('language.language')}
      >
        <LrvSelect
          value={language}
          onChange={e => setLanguage(e)}
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('coupon.placeholder')}
          theme={theme}
        >
          <Option key={LANGUAGES.ENGLISH} value={LANGUAGES.ENGLISH}>{t('language.en')}</Option>
          <Option key={LANGUAGES.SPANISH} value={LANGUAGES.SPANISH}>{t('language.es')}</Option>
          <Option key={LANGUAGES.PORTUGUESE} value={LANGUAGES.PORTUGUESE}>{t('language.pt')}</Option>
        </LrvSelect>
      </Form.Item>

      <Form.Item
        name='defaultPhase'
        required
        label={t('defaultPhaseModal.title')}
      >
        <LrvSelect
          value={defaultPhase}
          onChange={e => setDefaultPhase(e)}
          suffixIcon={<Icon name='arrow-down-s' />}
          theme={theme}
        >
          <Option key={stockingPhaseTypes.LARVAE} value={stockingPhaseTypes.LARVAE}>{t('header.phaseTypes.larvae')}</Option>
          <Option key={stockingPhaseTypes.JUVENILE} value={stockingPhaseTypes.JUVENILE}>{t('header.phaseTypes.juveniles')}</Option>
          <Option key={stockingPhaseTypes.ADULT} value={stockingPhaseTypes.ADULT}>{t('header.phaseTypes.growOut')}</Option>
        </LrvSelect>
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight} >
          <ActionButton id='submit_profile_button' type='primary' htmlType='submit' disabled={disabledButtonAccount}>
            {t('users.save')}
          </ActionButton>
        </div>
      </Form.Item>
    </LrvForm>
  );
}
