import cx from 'classnames';

import SampleD3 from '../SampleD3';
import { Percentile } from '../../interfaces';
import { THEME } from '../../../../../config/commons';

import Title from './Title';
import Subtitle from './Subtitle';
import Percentiles from './Percentiles';
import styles from './NewAnimalPanel.module.scss';

interface Props {
  listPercentile: Percentile[];
  labelsUnit: string;
  sampleChartFullScreen?: SampleD3 | null;
  theme?: 'dark' | 'light';
  changeGroupAnimal: () => void;
  undoRemovedAnimal: () => void;
}

export default function ChangeGroupAnimalPanel (props: Props) {
  const {
    changeGroupAnimal,
    undoRemovedAnimal,
    labelsUnit,
    listPercentile,
    sampleChartFullScreen,
    theme = 'dark',
  } = props;

  const isLightTheme = theme === THEME.LIGHT;

  return (
    <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark)}>
      <Title theme={theme} />
      <Subtitle
        changeGroupAnimal={changeGroupAnimal}
        undoRemovedAnimal={undoRemovedAnimal}
        theme={theme}
      />
      <Percentiles
        labelsUnit={labelsUnit}
        listPercentile={listPercentile}
        theme={theme}
        sampleChartFullScreen={sampleChartFullScreen}
      />
    </div>
  );
}
