import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Stocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { getParameter } from '../../../helpers/stocking.helpers';

import styles from './LegendMultiphase.module.scss';
import * as multiphaseSlice from './multiphaseSlice';

interface Props {
  stockingDefault: Stocking;
  theme?: 'dark' | 'light';
}

export const LegendMultiphase = (props: Props) => {
  const { stockingDefault, theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { phaseType } = useSelector((state: Store) => state.header);
  const { parameter } = useSelector((state: Store) => state.stockingAnalysis);
  const { multiphase, phaseTypesSelected, phaseTypesLegend } = useSelector((state: Store) => state.stockingMultiphase);

  const { stockings } = multiphase;
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    const phaseTypes: string[] = [];

    if ((stockings.larvae || (stockings.larvaes && stockings.larvaes.length))) {
      phaseTypes.push(stockingPhaseTypes.LARVAE);
    }

    if ((stockings.juvenile || (stockings.juveniles && stockings.juveniles.length))) {
      phaseTypes.push(stockingPhaseTypes.JUVENILE);
    }

    if (stockingDefault.phaseType === stockingPhaseTypes.ADULT) {
      phaseTypes.push(stockingPhaseTypes.ADULT);
    }

    // # Start process to maintain legends if stocking details of OriginsMultiphase change
    const auxPhaseTypes = phaseTypes.slice();
    if (phaseTypesSelected.length > 0) {
      const isLarvaeLegendSelected = phaseTypesSelected.includes(stockingPhaseTypes.LARVAE);
      const isJuvenileLegendSelected = phaseTypesSelected.includes(stockingPhaseTypes.JUVENILE);
      const isGrowOuthLegendSelected = phaseTypesSelected.includes(stockingPhaseTypes.ADULT);

      if (!isLarvaeLegendSelected && phaseTypes.includes(stockingPhaseTypes.LARVAE) && phaseTypesLegend.includes(stockingPhaseTypes.LARVAE)) {
        phaseTypes.splice(phaseTypes.indexOf(stockingPhaseTypes.LARVAE), 1);
      }

      if (!isJuvenileLegendSelected && phaseTypes.includes(stockingPhaseTypes.JUVENILE) && phaseTypesLegend.includes(stockingPhaseTypes.JUVENILE)) {
        phaseTypes.splice(phaseTypes.indexOf(stockingPhaseTypes.JUVENILE), 1);
      }

      if (!isGrowOuthLegendSelected && phaseTypes.includes(stockingPhaseTypes.ADULT) && phaseTypesLegend.includes(stockingPhaseTypes.ADULT)) {
        phaseTypes.splice(phaseTypes.indexOf(stockingPhaseTypes.ADULT), 1);
      }
    }
    // # End Process

    // If phaseTypes lenght is equal to 0, it means that the legends must be reset. (The auxPhaseType constant contains the legends reset)
    dispatch(multiphaseSlice.setPhaseTypesSelected(phaseTypes.length > 0 ? phaseTypes : auxPhaseTypes));
    dispatch(multiphaseSlice.setPhaseTypesLegend(auxPhaseTypes)); // Save the status of available legends
  }, [stockings]);

  const getClassNames = (phaseType: string) => {
    return cx(
      styles.phaseType,
      (isLightTheme ? styles.phaseTypeLight : styles.phaseTypeDark),
      !phaseTypesSelected.includes(phaseType) && (isLightTheme ? styles.lineThroughLight : styles.lineThroughDark),
      phaseTypesSelected.includes(phaseType) && phaseTypesSelected.length === 1 && styles.notAllowed,
    );
  };

  const renderPhaseType = () => {
    return (
      <div className={styles.containerPhaseType}>
        <div className={styles.phaseTypes}>
          {
            (stockings.larvae || (stockings.larvaes && stockings.larvaes.length)) &&
            <div
              id='larvae_button'
              className={getClassNames(stockingPhaseTypes.LARVAE)}
              onClick={() => {
                if (phaseTypesSelected.length === 1 && phaseTypesSelected.includes(stockingPhaseTypes.LARVAE)) {
                  return;
                }

                if (phaseTypesSelected.includes(stockingPhaseTypes.LARVAE)) {
                  const phaseTypes = phaseTypesSelected.filter((phaseType) => phaseType !== stockingPhaseTypes.LARVAE);
                  dispatch(multiphaseSlice.setPhaseTypesSelected(phaseTypes));
                  return;
                }

                dispatch(multiphaseSlice.setPhaseTypesSelected([...phaseTypesSelected, stockingPhaseTypes.LARVAE]));
              }}
            >
              <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.phases.larvae')} theme={theme} />
            </div>
          }

          {
            (stockings.juvenile || (stockings.juveniles && stockings.juveniles.length)) &&
            <div
              id='juvenile_button'
              className={getClassNames(stockingPhaseTypes.JUVENILE)}
              onClick={() => {
                if (phaseTypesSelected.length === 1 && phaseTypesSelected.includes(stockingPhaseTypes.JUVENILE)) {
                  return;
                }

                if (phaseTypesSelected.includes(stockingPhaseTypes.JUVENILE)) {
                  const phaseTypes = phaseTypesSelected.filter((phaseType) => phaseType !== stockingPhaseTypes.JUVENILE);
                  dispatch(multiphaseSlice.setPhaseTypesSelected(phaseTypes));
                  return;
                }

                dispatch(multiphaseSlice.setPhaseTypesSelected([...phaseTypesSelected, stockingPhaseTypes.JUVENILE]));
              }}
            >
              <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.phases.juvenile')} theme={theme} />
            </div>
          }

          {
            stockingDefault.phaseType === stockingPhaseTypes.ADULT &&
            <div
              id='grow_out_button'
              className={getClassNames(stockingPhaseTypes.ADULT)}
              onClick={() => {
                if (phaseTypesSelected.length === 1 && phaseTypesSelected.includes(stockingPhaseTypes.ADULT)) {
                  return;
                }

                if (phaseTypesSelected.includes(stockingPhaseTypes.ADULT)) {
                  const phaseTypes = phaseTypesSelected.filter((phaseType) => phaseType !== stockingPhaseTypes.ADULT);
                  dispatch(multiphaseSlice.setPhaseTypesSelected(phaseTypes));
                  return;
                }

                dispatch(multiphaseSlice.setPhaseTypesSelected([...phaseTypesSelected, stockingPhaseTypes.ADULT]));
              }}
            >
              <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.phases.growOut')} theme={theme} />
            </div>
          }
        </div>
      </div>
    );
  };

  let stockingPhaseType = phaseType;
  if (phaseType !== stockingPhaseTypes.LARVAE && phaseTypesSelected.length === 1 && phaseTypesSelected[0] === stockingPhaseTypes.LARVAE) {
    stockingPhaseType = stockingPhaseTypes.LARVAE;
  }

  const renderYParameter = (props: { opacity?: number }) => {
    const { opacity = 1 } = props;

    return (
      <div className={styles.labelAxisY} style={{ opacity }}>
        <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={getParameter({ parameter, stockingPhaseType })} theme={theme} />
      </div>
    );
  };

  return (
    <div className={styles.containerMultiphaseLegend}>
      <div className={styles.legends}>
        {renderYParameter({})}
        {renderPhaseType()}
        {renderYParameter({ opacity: 0 })}
      </div>
      <div className={styles.rect} />
    </div>
  );
};