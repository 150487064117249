import moment from 'moment';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { collection } from '../../firebase';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { formatYearMonthDay } from '../../utils/date';
import * as headerSlice from '../AppHeader/headerSlice';
import { filterOptionSelect } from '../../utils/select';
import { validateString } from '../../utils/validations';
import { companiesTypes, THEME } from '../../config/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import ActionButton from '../../common/components/buttons/ActionButton';
import { FIRESTORE_KEY, FIRESTORE_VALUE } from '../../config/firestore';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvTooltip } from '../../common/components/LrvTooltip/LrvTooltip';
import { openSuccessNotification } from '../../common/notification/Notification';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';

import { Banner } from './interfaces';
import styles from './TabBanner.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabBanner (props: Props) {
  const { theme } = props;

  const { Option } = Select;
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const header = useSelector((state: Store) => state.header);

  const [messageEnglish, setMessageEnglish] = useState('');
  const [messageSpanish, setMessageSpanish] = useState('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [persistentMode, setPersistentMode] = useState<boolean>(false);
  const [messagePortuguese, setMessagePortuguese] = useState('');
  const [endDate, setEndDate] = useState<string | undefined>('');
  const [companies, setCompanies] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState<Banner | undefined>();
  const [backgroundColor, setBackgroundColor] = useState<string>('#e7bf34');
  const [icon, setIcon] = useState<string>('');

  const isLightTheme = theme === THEME.LIGHT;

  const colors = ['#5583fb', '#ff7875', '#52c41a', '#e7bf34'];
  const icons = ['alarm-warning', 'notification', 'check', 'error-warning', 'calendar'];

  useEffect(() => {
    const alertMessage: Banner = JSON.parse(localStorage.getItem('alertMessage') || '{}');
    setAlertMessage(alertMessage);

    return (() => {
      dispatch(headerSlice.setShowPreviousAlert(false));
    });
  }, [dispatch]);

  useEffect(() => {
    if (!alertMessage) {
      return;
    }

    setMessageEnglish(alertMessage.en);
    setMessageSpanish(alertMessage.es);
    setMessagePortuguese(alertMessage.es);
    setShowAlert(alertMessage.showAlert);
    setPersistentMode(alertMessage.persistentMode);
    setEndDate(alertMessage.endDate);
    setCompanies(alertMessage.companyIds);
    setCompanies(alertMessage.companyIds);
    setBackgroundColor(alertMessage.backgroundColor);
    setIcon(alertMessage.icon);

    form.setFieldsValue({
      english: alertMessage.en,
      spanish: alertMessage.es,
      portuguese: alertMessage.pt,
      companies: alertMessage.companyIds,
      endDate: moment(alertMessage.endDate),
    });
  }, [form, alertMessage]);

  const updateAlertMessage = useCallback(() => {
    if (!header.showPreviousAlert) {
      return;
    }

    const alertMessage: Banner = {
      companyIds: companies,
      en: messageEnglish,
      es: messageSpanish,
      pt: messagePortuguese,
      showAlert,
      persistentMode,
      endDate: endDate ? formatYearMonthDay(endDate) : '',
      backgroundColor,
      icon,
    };

    dispatch(headerSlice.setAlertMessage(alertMessage));
  }, [dispatch, header.showPreviousAlert, messageEnglish, messageSpanish, messagePortuguese, backgroundColor, icon]);

  useEffect(() => {
    updateAlertMessage();
  }, [updateAlertMessage]);

  const updateDocument = async () => {
    const query = collection.where(FIRESTORE_KEY, '==', FIRESTORE_VALUE);

    const querySnapshot = await query.get();
    if (querySnapshot.empty) {
      return;
    }

    const document = querySnapshot.docs[0];

    document.ref.update({
      ...document.data(),
      'alertMessage.companyIds': companies,
      'alertMessage.en': messageEnglish,
      'alertMessage.es': messageSpanish,
      'alertMessage.pt': messagePortuguese,
      'alertMessage.showAlert': showAlert,
      'alertMessage.persistentMode': persistentMode,
      'alertMessage.backgroundColor': backgroundColor,
      'alertMessage.icon': icon,
      'alertMessage.endDate': endDate ? formatYearMonthDay(endDate) : '',
    });

    updateAlertMessage();
    openSuccessNotification(t('share.config.updated'));
  };

  const renderActiveSwitch = () => {
    return (
      <Form.Item
        name='active'
        label={t('banner.active')}
      >
        <LrvSwitch
          theme={theme}
          checked={showAlert}
          onChange={setShowAlert}
        />
      </Form.Item>
    );
  };

  const renderPersistenMode = () => {
    return (
      <Form.Item
        name='active'
        label={<span>
          {t('banner.persistentMode')}&nbsp;
          <LrvTooltip id='title_tooltip' title={t('banner.persistentModeTooltip')}>
            <QuestionCircleOutlined id='title_question_icon' />
          </LrvTooltip>
        </span>}
      >
        <LrvSwitch
          theme={theme}
          checked={persistentMode}
          onChange={setPersistentMode}
        />
      </Form.Item>
    );
  };

  const renderCompanyDropdown = () => {
    return (
      <Form.Item
        name='companies'
        required
        label={t('banner.companies')}
      >
        <LrvSelect
          id='dropdown_company'
          theme={theme}
          showSearch
          suffixIcon={<Icon name='arrow-down-s' />}
          removeIcon={<Icon name='close' />}
          placeholder={t('banner.companies')}
          value={companies}
          mode='multiple'
          onChange={setCompanies}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
        >
          <Option key={companiesTypes.ALL} value={companiesTypes.ALL}>{t('adminDashboard.companyType.all')}</Option>)
          {header.companies.map((company) => <Option key={company._id} value={company._id}>{company.name}</Option>)}
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderEnglishDescription = () => {
    return (
      <Form.Item
        name='english'
        required
        label={t('banner.description.english')}
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvInput
          value={messageEnglish}
          onChange={(e) => setMessageEnglish(e.target.value)}
          theme={theme}
        />
      </Form.Item>
    );
  };

  const renderSpanishDescription = () => {
    return (
      <Form.Item
        name='spanish'
        required
        label={t('banner.description.spanish')}
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvInput
          value={messageSpanish}
          onChange={(e) => setMessageSpanish(e.target.value)}
          theme={theme}
        />
      </Form.Item>
    );
  };

  const renderPortugueseDescription = () => {
    return (
      <Form.Item
        name='portuguese'
        required
        label={t('banner.description.portuguese')}
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvInput
          value={messagePortuguese}
          onChange={(e) => setMessagePortuguese(e.target.value)}
          theme={theme}
        />
      </Form.Item>
    );
  };

  const renderMaxDate = () => {
    return (
      <Form.Item
        name='endDate'
        label={t('banner.deadline')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvDatePicker
          theme={theme}
          placeholder=''
          value={moment(endDate)}
          onChange={(date, dateString) => setEndDate(dateString)}
        />
      </Form.Item>
    );
  };

  const renderBackgroundColor = () => {
    return (
      <Form.Item
        name='backgroundColor'
        label={t('banner.backgroundColor')}
        required
      >
        <div className={styles.rowsCircles}>
          {colors.map((color) => {
            return (
              <div className={styles.containerCircle}>
                <div
                  className={cx(styles.circle, color === backgroundColor ? styles.circleSelected : '')}
                  onClick={() => setBackgroundColor(color)}
                  style={{
                    backgroundColor: color,
                    border: color === backgroundColor ? (isLightTheme ? '' : '2px solid #eeeeee') : '',
                  }}
                >
                  {
                    color === backgroundColor &&
                    <Icon
                      className={styles.icon}
                      name='check'
                      theme='light'
                    />
                  }
                </div>
              </div>
            );
          })}
        </div>
      </Form.Item>
    );
  };

  const renderIcons = () => {
    return (
      <Form.Item
        name='icons'
        label={t('banner.icon')}
        required
      >
        <div className={styles.rowsIcons}>
          {icons.map((iconName) => {
            return (
              <div className={styles.item}>
                <div
                  className={cx(styles.containerIcon, iconName === icon ? styles.containerIconSelected : '')}
                  onClick={() => setIcon(iconName)}
                  style={{
                    backgroundColor: iconName === icon ? isLightTheme ? 'blue' : '#eeeeee' : '',
                  }}
                >
                  <Icon
                    className={cx(styles.icon, iconName === icon ? isLightTheme ? styles.iconSelectedLight : styles.iconSelectedDark : '')}
                    name={iconName}
                    theme={theme}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Form.Item>
    );
  };

  const renderShowPreviousView = () => {
    return (
      <Form.Item>
        <ActionButton
          type='ghost'
          icon={
            <Icon
              name={header.showPreviousAlert ? 'eye-off' : 'eye'}
              theme={theme}
            />
          }
          onClick={() => {
            dispatch(headerSlice.setShowPreviousAlert(!header.showPreviousAlert));
          }}
        >
          {header.showPreviousAlert ? t('banner.hidePreviousView') : t('banner.showPreviousView')}
        </ActionButton>
      </Form.Item>
    );
  };

  const renderSaveButton = () => {
    return (
      <Form.Item>
        <ActionButton id='submit_config_banner_button' type='primary' htmlType='submit' >
          {t('users.save')}
        </ActionButton>
      </Form.Item>
    );
  };

  return (
    <LrvForm
      id='form'
      name='form'
      form={form}
      layout='vertical'
      theme={theme}
      onFinish={updateDocument}
    >
      <Row gutter={16}>
        <Col span={12}>
          {renderActiveSwitch()}
        </Col>
        <Col span={12}>
          {renderPersistenMode()}
        </Col>
      </Row>

      {renderCompanyDropdown()}
      {renderEnglishDescription()}
      {renderSpanishDescription()}
      {renderPortugueseDescription()}
      {renderMaxDate()}
      {renderBackgroundColor()}
      {renderIcons()}

      <Space className={styles.alignButtonRight} size='large'>
        {renderShowPreviousView()}
        {renderSaveButton()}
      </Space>
    </LrvForm >
  );
}
