import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByFirstName } from '../../utils/sort';
import { getUserSession } from '../../utils/userSession';
import { quotaErrors, typeCompany } from '../../config/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';
import { USERS_URL, CLIENT_BALANCES_URL, LOG_BALANCES_BY_USER_URL, LOG_BALANCES_BY_COMPANY_URL } from '../../config/config.api';

import { BalanceState, ClientBalancesPayload, UserBalance, CompanyBalance, UserBalancesPayload } from './interfaces';

const initiailBalance = {
  assignedCompanyBalances: [],
  company: {
    isDistributor: false,
    isAssignedCompany: false,
    name: '',
    quota: 0,
    bonusQuota: 0,
    quotaUsage: 0,
    bonusQuotaUsed: 0,
    availableQuota: 0,
    balanceId: '',
    companyId: '',
    prepaid: 0,
    prepaidUsed: 0,
    payment: {
      _id: '',
      amount: 0,
      companyPayerId: '',
      createdAt: '',
      orderId: '',
      paymentDate: '',
      transactionId: '',
      type: '',
      updatedAt: '',
      userPayerId: '',
    },
  },
  usersWithConsumptionLimit: [],
  usersWithFreeConsumption: [],
  isBalanceLoading: false
};

const initialState: BalanceState = {
  showBalanceModal: false,
  currentBalanceTab: 'USERS',
  balance: initiailBalance,
  logUserBalance: {
    logsBalances: [],
    lastBalance: {
      _id: '',
      quota: 0,
      quotaUsage: 0,
      bonusQuota: 0,
      bonusQuotaUsed: 0,
      availableQuota: 0,
      userId: '',
      createdAt: '',
      updatedAt: '',
    },
  },
  isLogsBalancesLoading: false,
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    setIsBalanceLoading: (state: BalanceState, action: PayloadAction<boolean>) => {
      state.balance.isBalanceLoading = action.payload;
    },
    setShowBalanceModal: (state: BalanceState, action: PayloadAction<boolean>) => {
      state.showBalanceModal = action.payload;
    },
    setCurrentBalanceTab: (state: BalanceState, action: PayloadAction<string>) => {
      state.currentBalanceTab = action.payload;
    },
    setClientBalances: (state: BalanceState, action: PayloadAction<ClientBalancesPayload>) => {
      state.balance.assignedCompanyBalances = action.payload.assignedCompanyBalances;
      state.balance.company = action.payload.company;
      state.balance.usersWithConsumptionLimit = action.payload.usersWithConsumptionLimit;
      state.balance.usersWithFreeConsumption = action.payload.usersWithFreeConsumption;
    },
    setIsLogsBalancesLoading: (state: BalanceState, action: PayloadAction<boolean>) => {
      state.isLogsBalancesLoading = action.payload;
    },
    setLogsBalances: (state: BalanceState, action: PayloadAction<UserBalancesPayload>) => {
      state.logUserBalance.logsBalances = action.payload.logsBalances;
      state.logUserBalance.lastBalance = action.payload.lastBalance;
    },
    resetClientBalances: (state: BalanceState) => {
      state.balance = initiailBalance;
    },
  },
});

export const {
  setIsBalanceLoading,
  setShowBalanceModal,
  setCurrentBalanceTab,
  setClientBalances,
  setIsLogsBalancesLoading,
  setLogsBalances,
  resetClientBalances,
} = balanceSlice.actions;

export const updateAllowCompanyBalanceConsumption = (props: { allowCompanyBalanceConsumption: boolean; userId: string; companyId: string }) => async (dispatch: Function) => {
  const { allowCompanyBalanceConsumption, userId, companyId } = props;

  const url = `${USERS_URL}/${userId}`;
  const data = {
    allowCompanyBalanceConsumption,
    companyId,
  };

  try {
    await axios.patch(url, data);
    dispatch(fetchClientBalances(companyId));
    openSuccessNotification(i18next.t('users.userUpdated'));
  } catch (e) {
    console.log(e?.response);
    return;
  }
};

export const fetchClientBalances = (companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();
  dispatch(setIsBalanceLoading(true));

  try {
    const clientId = companyId || userSession.companyId;
    const response = await axios.get<ClientBalancesPayload>(`${CLIENT_BALANCES_URL}/${clientId}`);

    response.data.usersWithConsumptionLimit.sort(sortByFirstName);
    response.data.usersWithFreeConsumption.sort(sortByFirstName);
    dispatch(setClientBalances(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  dispatch(setIsBalanceLoading(false));
};

interface Balance {
  balanceId: string;
  quota: number;
  prepaid: number;
  bonusQuota: number;
}

interface UpdateBalanceProps {
  clientId?: string;
  companyType: string;
  balances: UserBalance[];
  assignedBalances: CompanyBalance[];
  companyBalance: CompanyBalance;
  clientNewQuota: number;
  clientNewBonus: number;
}

interface ClientBalancePatch {
  balances: Balance[];
  typeCompany: string;
  newQuota?: number;
  newBonus?: number;
}

const getPatchData = (props: { balancesData: UpdateBalanceProps; balances: Balance[]; }) => {
  const { balancesData, balances } = props;

  const patchData: ClientBalancePatch = {
    balances,
    typeCompany: balancesData.companyType,
  };

  if (balancesData.companyType === typeCompany.NORMAL) {
    if (balancesData.companyBalance.quota !== balancesData.clientNewQuota) {
      patchData.newQuota = balancesData.clientNewQuota;
    }

    if (balancesData.companyBalance.bonusQuota !== balancesData.clientNewBonus) {
      patchData.newBonus = balancesData.clientNewBonus;
    }
  }

  return patchData;
};

export const updateClientBalances = async (balancesData: UpdateBalanceProps) => {
  try {
    const userSession = getUserSession();
    const clientId = balancesData.clientId || userSession.companyId;
    const balances: Balance[] = balancesData.companyType === typeCompany.DISTRIBUTOR ?
      balancesData.assignedBalances.map(balance => { return { balanceId: balance.balanceId, quota: balance.quota, prepaid: balance.prepaid, bonusQuota: balance.bonusQuota }; }) :
      balancesData.balances.map(balance => { return { balanceId: balance.balanceId, quota: balance.quota, prepaid: balance.prepaid, bonusQuota: balance.bonusQuota }; });

    const patchData = getPatchData({ balances, balancesData });

    await axios.patch(`${CLIENT_BALANCES_URL}/${clientId}`, patchData);
  } catch (e) {
    console.log(e?.response);
    const error = e?.response?.data?.data?.error;
    const totalQuota = e?.response?.data?.data?.totalQuota;

    switch (error) {
      case quotaErrors.minimumQuota:
        openErrorNotification(i18next.t('users.balance.quotaErrors.minimumQuota', { minQuota: 50 }));
        break;

      case quotaErrors.userBalances:
        openErrorNotification(i18next.t('users.balance.quotaErrors.userBalances', { userQuota: totalQuota }));
        break;

      default:
        break;
    }
    return;
  }
  openSuccessNotification(i18next.t('users.balancesUpdated'));
};

export const fetchLogsBalancesByUser = (params: { userId: string; month: string; year: string; companyId: string }) => async (dispatch: Function) => {
  const { userId, month, year, companyId } = params;
  dispatch(setIsLogsBalancesLoading(true));
  let logs;

  try {
    const paramsLogBalance = {
      'month': month,
      'year': year,
      'companyId': companyId,
    };

    const response = await axios.get(`${LOG_BALANCES_BY_USER_URL}/${userId}`, { params: paramsLogBalance });
    logs = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setLogsBalances(logs));
  dispatch(setIsLogsBalancesLoading(false));
};

export const fetchLogsBalancesByCompany = (params: { companyId: string; month: string; year: string }) => async (dispatch: Function) => {
  const { companyId, month, year } = params;
  dispatch(setIsLogsBalancesLoading(true));
  let logs;

  try {
    const paramsLogBalance = {
      'month': month,
      'year': year,
      'companyId': companyId,
    };

    const response = await axios.get(`${LOG_BALANCES_BY_COMPANY_URL}/${companyId}`, { params: paramsLogBalance });
    logs = response.data;
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  dispatch(setLogsBalances(logs));
  dispatch(setIsLogsBalancesLoading(false));
};

export const resetLogsBalancesByUser = () => (dispatch: Function) => {
  dispatch(setLogsBalances(initialState.logUserBalance));
};


export default balanceSlice.reducer;
