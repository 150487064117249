import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../utils/sort';
import { axiosClient as axios } from '../../utils/axios_instance';
import { unitStatuses, campusUpdateError } from '../../config/commons';
import { CAMPUS_URL, VOLUME_RANGES_URL } from '../../config/config.api';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';

import { CampusState, Campus, Volume } from './interfaces';

const initialState: CampusState = {
  campuses: [],
  selectedCampus: {
    _id: '',
    name: '',
    code: '',
    timezone: '',
    companyId: '',
    country: '',
    province: '',
    phaseType: '',
    status: '',
  },
  isCampusLoading: false,
  isCampusesLoading: false,
  volumeRanges: {
    larvae: {
      liters: {
        max: 0,
        min: 0
      },
    },
    juvenile: {
      cubicMeters: {
        max: 0,
        min: 0
      },
      hectares: {
        max: 0,
        min: 0
      }
    },
    growOut: {
      cubicMeters: {
        max: 0,
        min: 0
      },
      hectares: {
        max: 0,
        min: 0
      }
    },
  },
  showCreateCampusModal: false,
};

export const campusSlice = createSlice({
  name: 'campus',
  initialState,
  reducers: {
    setCampuses: (state: CampusState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },
    setSelectedCampus: (state: CampusState, action: PayloadAction<Campus>) => {
      state.selectedCampus._id = action.payload._id;
      state.selectedCampus.name = action.payload.name;
      state.selectedCampus.code = action.payload.code;
      state.selectedCampus.timezone = action.payload.timezone;
      state.selectedCampus.companyId = action.payload.companyId;
      state.selectedCampus.phaseType = action.payload.phaseType;
      state.selectedCampus.province = action.payload.province;
      state.selectedCampus.status = action.payload.status;
    },
    setIsCampusLoading: (state: CampusState, action: PayloadAction<boolean>) => {
      state.isCampusLoading = action.payload;
    },
    setIsCampusesLoading: (state: CampusState, action: PayloadAction<boolean>) => {
      state.isCampusesLoading = action.payload;
    },
    setVolumeRanges: (state: CampusState, action: PayloadAction<Volume>) => {
      state.volumeRanges = action.payload;
    },
    setShowCreateCampusModal: (state: CampusState, action: PayloadAction<boolean>) => {
      state.showCreateCampusModal = action.payload;
    },
  }
});

export const {
  setCampuses,
  setSelectedCampus,
  setIsCampusLoading,
  setIsCampusesLoading,
  setVolumeRanges,
  setShowCreateCampusModal,
} = campusSlice.actions;

export const fetchCampuses = (props: { companyId: string; phaseType: string; name?: string | number }) => async (dispatch: Function) => {
  const { companyId, phaseType, name } = props;
  dispatch(setIsCampusesLoading(true));

  const params = {
    $limit: -1,
    companyId: companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    '$sort[name]': 1,
    name: name || undefined,
    phaseType,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    response.data.sort(sortByName);
    dispatch(setCampuses(response.data));
    dispatch(setIsCampusesLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampus = (campusId: string) => async (dispatch: Function) => {
  dispatch(setIsCampusLoading(true));

  try {
    const response = await axios.get<Campus>(`${CAMPUS_URL}/${campusId}`);
    dispatch(setSelectedCampus(response.data));
    dispatch(setIsCampusLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createCampus = (props: { campusData: { name: string; code: string; timezone: string; companyId: string; phaseType: string; province: string; }; phaseType: string; }) => async (dispatch: Function) => {
  const { campusData, phaseType } = props;

  try {
    await axios.post(CAMPUS_URL, campusData);
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
  openSuccessNotification(i18next.t('campus.campusCreated'));
  dispatch(fetchCampuses({ companyId: campusData.companyId, phaseType }));
  dispatch(goToOnboardingNextStep());
};

export const updateCampus = (campusData: { _id: string; status: string; name: string; code: string; timezone: string; province: string; companyId: string; phaseType: string }) => async (dispatch: Function) => {
  try {
    await axios.patch(`${CAMPUS_URL}/${campusData._id}`, campusData);
    openSuccessNotification(i18next.t('campus.campusUpdated'));
  } catch (e) {
    console.log('ERROR', e);
    if (e?.response?.data?.data?.error) {
      showErrorUpdateCampus(e.response.data.data.error);
    }
    return;
  }
  dispatch(fetchCampuses({ companyId: campusData.companyId, phaseType: campusData.phaseType }));
};

export const updateCampusStatus = (props: { campusId: string, companyId: string, status: string; phaseType: string; }) => async (dispatch: Function) => {
  const { campusId, companyId, status, phaseType } = props;

  try {
    await axios.patch(`${CAMPUS_URL}/${campusId}`, { status: status });

    switch (status) {
      case unitStatuses.ACTIVE:
        openSuccessNotification(i18next.t('campus.campusActivated'));
        break;

      case unitStatuses.INACTIVE:
        openSuccessNotification(i18next.t('campus.campusDeactivated'));
        break;

      case unitStatuses.REMOVED:
        openSuccessNotification(i18next.t('campus.campusDeleted'));
        break;
    }

  } catch (e) {
    if (e?.response?.data?.data?.error) {
      showErrorUpdateCampus(e.response.data.data.error);
    }
    return;
  }
  dispatch(fetchCampuses({ companyId, phaseType }));
};

function showErrorUpdateCampus (error: string) {
  switch (error) {
    case campusUpdateError.ACTIVE_STOCKINGS:
      openErrorNotification(i18next.t('campus.updateCampusError.activeStockings'));
      break;
  }
}

export const fetchVolumeRanges = () => async (dispatch: Function) => {
  try {
    const response = await axios.get(VOLUME_RANGES_URL);
    dispatch(setVolumeRanges(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }
};

export default campusSlice.reducer;
