import cx from 'classnames';
import { Radio } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as harvestsSlice from '../harvestsSlice';
import { Store } from '../../../state/store.interfaces';
import { Dataset } from '../../Analysis/Detail/interfaces';
import { THEME, typeHistogram } from '../../../config/commons';
import { getDataset } from '../../Analysis/Detail/histogram.helpers';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';

import styles from './HistogramModal.module.scss';
import { ExtraInformation } from './ExtraInformation';
import { HarvestHistogram } from './HarvestHistogram';

interface Props {
  theme?: 'dark' | 'light';
}

export const HistogramModal = (props: Props) => {
  const {
    theme = 'light',
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [histogram, setHistogram] = useState(typeHistogram.WEIGHT);
  const [harvestDataset, setHarvestDataset] = useState<Dataset[]>([]);
  const [consolidatedDataset, setConsolidatedDataset] = useState<Dataset[]>([]);
  const [harvestLimits, setHarvestLimits] = useState<number[]>([]);
  const [consolidatedLimits, setConsolidatedLimits] = useState<number[]>([]);

  const { company } = useSelector((state: Store) => state.header);
  const { histograms, selectedHarvest, isDownloadingFile } = useSelector((state: Store) => state.harvests);
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (histograms.isLoadingConsolidated || !histograms.consolidated?._id) {
      setConsolidatedLimits([]);
      setConsolidatedDataset([]);
      return;
    }

    const frequency = histogram === typeHistogram.WEIGHT ? histograms.consolidated.histogramWeight : histograms.consolidated.histogramLength;
    const total = frequency.frequencies.reduce((accumulator, value) => accumulator + value, 0);
    const limits = histogram === typeHistogram.WEIGHT ? histograms.consolidated.histogramWeight.limits : histograms.consolidated.histogramLength.limits;
    const dataset: Dataset[] = getDataset(frequency, total);

    setConsolidatedLimits(limits);
    setConsolidatedDataset(dataset);
  }, [histogram, histograms.isLoadingConsolidated, histograms.consolidated]);

  useEffect(() => {
    if (histograms.isLoadingHarvest || !histograms.harvest?.stocking?._id) {
      setHarvestLimits([]);
      setHarvestDataset([]);
      return;
    }

    const frequency = histogram === typeHistogram.WEIGHT ? histograms.harvest.histogramWeight : histograms.harvest.histogramLength;
    const total = frequency.frequencies.reduce((accumulator, value) => accumulator + value, 0);
    const limits = histogram === typeHistogram.WEIGHT ? histograms.harvest.histogramWeight.limits : histograms.harvest.histogramLength.limits;
    const dataset: Dataset[] = getDataset(frequency, total);

    setHarvestLimits(limits);
    setHarvestDataset(dataset);
  }, [histogram, histograms.isLoadingHarvest, histograms.harvest]);

  const generatePdf = () => {
    if (!selectedHarvest?._id) {
      return;
    }

    const harvestType = selectedHarvest.stockingIds ? 'CONSOLIDATED' : 'HARVEST';
    dispatch(harvestsSlice.getHarvestReportPdf({ companyId: company._id, harvestId: selectedHarvest._id, harvestType, histogram }));
  };

  const renderGeneratePdf = () => {
    return (
      <IconButton
        loading={isDownloadingFile}
        disabled={histograms.isLoadingHarvest || histograms.isLoadingConsolidated}

        iconName='download'
        onClick={generatePdf}
        tooltipText={t('production.download')}
        theme='light'
      />
    );
  };

  const renderOptions = () => {
    return (
      <div className={styles.containerActions}>
        <div className={styles.actions}>
          <Radio.Group
            className={styles.typeHistogram}
            value={histogram}
            onChange={(event) => setHistogram(event.target.value)}
            size='small'
          >
            <Radio.Button
              className={cx(styles.weight, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, histogram === typeHistogram.WEIGHT ? styles.radioSelected : '')}
              value={typeHistogram.WEIGHT}
            >
              <div id='radio_button_weight'>{t('detail.weight')}</div>
            </Radio.Button>

            <Radio.Button
              className={cx(styles.length, styles.radioButton, isLightTheme ? styles.radioButtonLight : styles.radioButtonDark, histogram === typeHistogram.LENGTH ? styles.radioSelected : '')}
              value={typeHistogram.LENGTH}
            >
              <div id='radio_button_length'>{t('detail.length')}</div>
            </Radio.Button>
          </Radio.Group>
        </div>

        {renderGeneratePdf()}
      </div>
    );
  };

  const onCloseModal = () => {
    setHistogram(typeHistogram.WEIGHT);
    dispatch(harvestsSlice.setShowModal(false));
    dispatch(harvestsSlice.resetHistograms());
    dispatch(harvestsSlice.setConsolidated(undefined));

    setHarvestDataset([]);
    setConsolidatedDataset([]);
    setHarvestLimits([]);
    setConsolidatedLimits([]);
  };

  const getTitle = () => {
    return selectedHarvest?.stockingIds ? `${t('harvests.consolidatedHarvest')}: ${selectedHarvest?.name}` : `${t('harvests.harvest')}: ${selectedHarvest?.name}`;
  };

  const renderBody = () => {
    if (!harvestDataset.length && !consolidatedDataset.length) {
      return (
        <div className={styles.center}>
          <LrvEmpty description={t('harvests.detail.disabled')} theme={theme} />
        </div>
      );
    }

    return (
      <>
        {renderOptions()}

        <HarvestHistogram
          histogram={histogram}
          primaryLimits={selectedHarvest?.stockingIds && selectedHarvest.stockingIds.length > 0 ? consolidatedLimits : harvestLimits}
          secondaryLimits={selectedHarvest?.stockingIds && selectedHarvest.stockingIds.length > 0 ? harvestLimits : []}
          primaryDataset={selectedHarvest?.stockingIds && selectedHarvest.stockingIds.length > 0 ? consolidatedDataset : harvestDataset}
          secondaryDataset={selectedHarvest?.stockingIds && selectedHarvest.stockingIds.length > 0 ? harvestDataset : []}
        />

        <div className={styles.containerExtraInformation}>
          <ExtraInformation
            consolidated={histograms.consolidated}
            harvest={histograms.harvest}
          />
        </div></>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={getTitle()}
      open={histograms.showModal}
      destroyOnClose={true}
      isLoading={histograms.isLoadingConsolidated || histograms.isLoadingHarvest}
      onOk={onCloseModal}
      okText={t('harvests.accept')}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={onCloseModal}
      width={680}
    >
      {renderBody()}
    </LrvModal>
  );
};
