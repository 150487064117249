import React from 'react';
import cx from 'classnames';

import larviaLogo from '../../assets/powered-by.png';

import styles from './Header.module.scss';

interface Props {
  title: string;
  stockingBindingCode?: string;
  subtitle: string;
  rightSubtitle?: string;
}

export default function Header (props: Props) {
  const { title, stockingBindingCode, subtitle, rightSubtitle = '' } = props;

  return <div className={styles.header}>
    <div className={styles.left}>
      <div className={styles.row}>
        <div className={styles.company}>
          {title}
        </div>
        {
          stockingBindingCode &&
          <div className={cx(styles.company, styles.stockingBindingCode)}>
            &nbsp;{stockingBindingCode}
          </div>
        }

      </div>
      <div className={styles.row}>
        <div className={styles.code}>
          {subtitle}
        </div>
        <div className={styles.rightSubtitle}>
          &nbsp;{rightSubtitle}
        </div>
      </div>
    </div>

    <div className={styles.right}>
      <img src={larviaLogo} alt='larvia' />
    </div>

  </div>;
}
