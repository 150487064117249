import React from 'react';
import cx from 'classnames';
import { Switch } from 'antd';

import Icon from '../Icon/Icon';
import { LrvSwitchProps } from '../../interfaces/lrvSwitchProps';

import styles from './LrvSwitch.module.scss';

export const LrvSwitch = (props: LrvSwitchProps) => {
  const { containerClassName, theme = 'dark' } = props;

  const isDarkTheme = theme === 'dark';

  return (

    <div className={cx(styles.container, isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}>
      <Switch
        {...props}
        checkedChildren={<Icon name='check' />}
        unCheckedChildren={<Icon name='close' />}
      />
    </div>
  );
};
