import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ANALYSES_URL } from '../../config/config.api';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';

import * as analysisSlice from './sowingSlice';
import { AnalysisMapData, AnalysisFromConsolidated, AnalysisFromConsolidatedState } from './interfaces';

const initialState: AnalysisFromConsolidatedState = {
  analysis: [],
  isLoading: false,
  showModal: false,
};

export const analysisFromConsolidatedSlice = createSlice({
  name: 'analysisFromConsolidated',
  initialState,
  reducers: {
    setAnalysis: (state: AnalysisFromConsolidatedState, action: PayloadAction<AnalysisFromConsolidated[]>) => {
      state.analysis = action.payload;
    },
    setAnalysisConsolidated: (state: AnalysisFromConsolidatedState, action: PayloadAction<AnalysisMapData | undefined>) => {
      state.analysisConsolidated = action.payload;
    },
    setIsLoading: (state: AnalysisFromConsolidatedState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowModal: (state: AnalysisFromConsolidatedState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const {
  setAnalysis,
  setAnalysisConsolidated,
  setIsLoading,
  setShowModal,
} = analysisFromConsolidatedSlice.actions;

export const fetchAnalysis = (consolidatedId: string) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  const url = `${ANALYSES_URL}/${consolidatedId}/consolidated`;

  try {
    const response = await axios.get<AnalysisFromConsolidated[]>(url);
    dispatch(setAnalysis(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const removeAnalysis = (props: { analysisId: string; consolidatedId: string; currentPage: number; companyId: string; stockingId: string }) => async (dispatch: Function) => {
  const { analysisId, consolidatedId, currentPage, companyId, stockingId } = props;

  const url = `${ANALYSES_URL}/remove/${analysisId}/from/${consolidatedId}`;

  try {
    await axios.post<AnalysisFromConsolidated[]>(url);
    dispatch(fetchAnalysis(consolidatedId));
    openSuccessNotification(i18next.t('analysis.updated'));

    dispatch(analysisSlice.fetchAnalysis({ stockingId, page: currentPage, companyId }));
  } catch (e) {
    console.log(e?.response);
  }
};

export default analysisFromConsolidatedSlice.reducer;
