import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useRef, RefObject } from 'react';

import { THEME } from '../../../config/commons';
import { Stocking as IStocking } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { handleResizeEvent } from '../../../utils/dimensions';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { lineColor, rectColor } from '../../../common/components/charts/ShadedPlot/helpers';

import D3Multiphase from './D3Multiphase';
import { AnalysisMultiphase } from './interfaces';
import styles from './MultiphaseChart.module.scss';
import { getAnalysisMultiphaseFilter } from './multiphase-helpers';
import { fetchMultiphase, resetMultiphases } from './multiphaseSlice';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements } from './helpers';

let chart: D3Multiphase | null;

interface Props {
  refParentFilters: RefObject<HTMLDivElement>;
  refChildFilters: RefObject<HTMLDivElement>;
  refOriginsMultiphase: RefObject<HTMLDivElement>;
  stockingDefault: IStocking;
}

export const MultiphaseChart = (props: Props) => {
  const { stockingDefault, refParentFilters, refChildFilters, refOriginsMultiphase } = props;

  const dispatch = useDispatch();

  const refChartMain = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [analysisMultiphases, setAnalysisMultiphases] = useState<AnalysisMultiphase[]>([]);

  const { phaseType } = useSelector((state: Store) => state.header);
  const { parameter, scale, showStockingDetail, stockingSelected } = useSelector((state: Store) => state.stockingAnalysis);
  const { isFetchMultiphase, multiphase, phaseTypesSelected } = useSelector((state: Store) => state.stockingMultiphase);
  const metrics = useSelector((state: Store) => state.metrics);

  const { analysis } = multiphase;

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const showReferenceArea = stockingSelected?.bindingStockings?.length !== undefined && phaseTypesSelected.length === 1;

  useEffect(() => {
    return () => {
      chart = null;
      dispatch(resetMultiphases());
    };
  }, [dispatch]);

  useEffect(() => {
    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ childFilters: refChildFilters, parentFilters: refParentFilters, origins: refOriginsMultiphase, showStockingDetail }));
  }, [refOriginsMultiphase.current, refChildFilters.current, showStockingDetail]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ childFilters: refChildFilters, parentFilters: refParentFilters, origins: refOriginsMultiphase, showStockingDetail }));
    });
  }, [showStockingDetail]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    if (!analysis.length) {
      return;
    }

    const analysisMultiphaseList: AnalysisMultiphase[] = getAnalysisMultiphaseFilter(analysis, phaseTypesSelected, parameter);
    setAnalysisMultiphases(analysisMultiphaseList);
  }, [analysis, parameter, phaseTypesSelected]);

  useEffect(() => {
    if (!stockingDefault._id) {
      return;
    }

    dispatch(fetchMultiphase({ stockingId: stockingDefault._id, phaseType: stockingDefault.phaseType }));
  }, [dispatch, stockingDefault._id, stockingDefault.phaseType]);

  useEffect(() => {
    if (isFetchMultiphase || !analysisMultiphases.length) {
      return;
    }

    if (chart) {
      const props = { dataMetric: [], phaseTypeSelected: phaseTypesSelected[0], showReferenceArea, analysisMultiphaseData: analysisMultiphases, width, height, parameter, scale, colorFillRect, colorLine };
      chart.refreshChart(props);
      return;
    }

    const props = {
      colorFillRect,
      colorLine,
      container: refChartMain.current,
      analysisMultiphaseData: analysisMultiphases,
      height,
      parameter,
      scale,
      phaseTypeSelected: showReferenceArea ? phaseTypesSelected[0] : '',
      dataMetric: [],
      showReferenceArea,
      showLabels: false,
      width,
      phaseType,
    };
    chart = new D3Multiphase(props);
  }, [analysisMultiphases, metrics, showReferenceArea, phaseTypesSelected, isFetchMultiphase, stockingDefault, parameter, phaseType, scale, width, height, colorFillRect, theme, colorLine]);

  const renderSpinner = () => {
    if (isFetchMultiphase) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      {renderSpinner()}

      <div ref={refChartMain} />
    </div>
  );
};