import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';
import { capitalize } from 'lodash';

import resetIconSvg from '../../../../assets/reset.svg';
import { GenericParam } from '../../../interfaces/commons';
import { getCurrentTheme } from '../../../../helpers/theme';
import { LunarPhaseStages } from '../../../../helpers/lunar-age';
import { getMinStage } from '../../../../pages/Analysis/helpers';
import { applyThousandsSeparator } from '../../../../utils/strings';
import { getLabelAxisX } from '../../../../helpers/stocking.helpers';
import { ItemReferenceCurves } from '../../../../pages/Units/interfaces';
import { Point, DataSource, DataByStage } from '../../../../pages/home/interfaces';
import { formatter } from '../../../../pages/Sowings/Multiphase/multiphase-helpers';
import { roundLength, roundWeight, stockingPhaseTypes, THEME } from '../../../../config/commons';
import * as stockingAnalysisSlice from '../../../../pages/Sowings/Analysis/stockingAnalysisSlice';
import { calcStages, getPoints, getPointX, getPointY, renderTickLeftFormat, getNumberTicks, renderTickFormat, typeScale, getMarginArea, sortY1, sortY2, typeParam, typesChart, getLineData, getMinY, getMaxY, getStockingChartLeftPosition, filterPointByStage, filterPointNotConsolidated, filterPointConsolidated } from '../ShadedPlot/helpers';

import './FactorKChartD3.scss';
import { calcDerivativeData } from './helpers';
import styles from './FactorKChartD3.module.scss';

let zoomDone = false;
let idleTimeout: NodeJS.Timeout | null;
let numbersTicks: number;
let extentX0 = 0;
let extentX1 = 0;
let extentLeftAxisY0 = 0;
let extentLeftAxisY1 = 0;
let extentRightAxisY0 = 0;
let extentRightAxisY1 = 0;
let currentStageActive: string;

const DEFAULT_CIRCLE = 9;
const OTHER_CIRCLE = 9;
const OTHER_CIRCLE_ACTIVE = OTHER_CIRCLE + 3;
const DEFAULT_CIRCLE_ACTIVE = DEFAULT_CIRCLE + 3;
const TIME_TRANSITION = 300;
const TICK_PADDING = 8;

interface Props {
  colorFillRect?: string;
  colorLine: string;
  colorsPoints?: string[];
  colorsStroke?: string[];
  container: HTMLDivElement | null;
  dataMetric: ItemReferenceCurves[];
  dataSource?: DataSource[];
  firstStage: number;
  height: number;
  lastStage: number;
  scale?: string;
  typeMetric?: string;
  width: number;
  movingAverage?: number;
  lunarPhaseStages?: LunarPhaseStages;
  showMovingAverage?: boolean;
  selectedTickStoke?: string;
  showDerivative?: boolean;
  dispatch?: Dispatch<GenericParam>;
}

class FactorKChartD3 {
  container: HTMLDivElement | null;
  colorsPoints: string[];
  colorsStroke: string[];
  svg: d3.Selection<SVGSVGElement, Point, null, undefined>;
  groupMain: d3.Selection<SVGGElement, Point, null, undefined>;

  scaleLinearBottomAxisX: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  scaleLinearLeftAxisY: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  scaleLinearRightAxisY: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  margin = { top: 24, right: 40, bottom: 20, left: 50 };
  leftAxisY: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  rightAxisY: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  scale: string;
  typeMetric?: string;

  dataMetric: ItemReferenceCurves[] = [];
  dataY1: number[] = [];
  dataY2: number[] = [];
  dataSource: DataSource[] = [];
  allPoints: Point[] = [];

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  firstStage = 0;
  lastStage = 0;

  containerLeft: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerTop: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerRight: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerBottom: d3.Selection<SVGRectElement, Point, null, undefined> = d3.select<SVGRectElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  colorFillRect = '';
  colorLine = '';

  movingAverage?: number;
  selectedTickStoke: string;

  clip: d3.Selection<d3.BaseType, Point, null, undefined> = d3.select<d3.BaseType, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<Point> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, Point, null, undefined> = d3.select<SVGGElement, Point>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  lunarPhaseStages?: LunarPhaseStages;
  showMovingAverage = false;
  showDerivative = false;
  shouldShowAllPoints = true;
  dispatch?: Dispatch<GenericParam>;

  // eslint-disable-next-line
  constructor(props: Props) {
    const { colorFillRect = '', colorLine, colorsPoints = [], colorsStroke = [], container, dataMetric, dataSource = [], firstStage, height, lastStage, scale = typeScale.LINEAR, typeMetric, width, movingAverage = 2, lunarPhaseStages, showMovingAverage = false, showDerivative = false, selectedTickStoke = 'white', dispatch } = props;

    this.colorsPoints = colorsPoints;
    this.colorsStroke = colorsStroke;
    this.container = container;
    this.typeMetric = typeMetric;
    this.scale = scale;
    this.dataMetric = dataMetric;
    this.dataSource = dataSource;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.selectedTickStoke = selectedTickStoke;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.movingAverage = movingAverage;
    this.lunarPhaseStages = lunarPhaseStages;
    this.showMovingAverage = showMovingAverage;
    this.showDerivative = showDerivative;

    this.shouldShowAllPoints = dataSource.length === 1;
    this.dispatch = dispatch;

    d3.select(container).select('#tooltipFactorK').remove();
    d3.select(container).select('#tooltipGrowthDelta').remove();
    d3.select(container).select('#tooltipComparisionChart').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select<HTMLDivElement | null, Point>(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    if (firstStage === lastStage && firstStage === 0) {
      const minStage = getMinStage(stockingPhaseTypes.ADULT);
      const stages = calcStages(dataSource, minStage);
      this.firstStage = stages[0];
      this.lastStage = stages[1];
    } else {
      this.firstStage = firstStage;
      this.lastStage = lastStage;
    }

    extentX0 = this.firstStage;
    extentX1 = this.lastStage;
    zoomDone = false;

    this.sortData(this.dataMetric);

    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width + ((DEFAULT_CIRCLE - 1) * 2))
      .attr('height', height)
      .attr('x', - DEFAULT_CIRCLE + 1)
      .attr('y', 0);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush<Point>()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStage, lastStage } = this;

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }

      const { maxY, minY } = this.getYDomainData();
      const domainDerivative = this.getYDomainDerivative();

      extentX0 = 0;
      extentX1 = 0;
      extentLeftAxisY0 = 0;
      extentLeftAxisY1 = 0;
      extentRightAxisY0 = 0;
      extentRightAxisY1 = 0;
      this.resetBrush({ x0: firstStage, x1: lastStage, leftAxisY0: minY, leftAxisY1: maxY, rightAxisY0: domainDerivative.minY, rightAxisY1: domainDerivative.maxY });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, x1, leftAxisY0: y0, leftAxisY1: y1, rightAxisY0: y0, rightAxisY1: y1 });
  }

  applyBrush = (props: { x0: number; x1: number; leftAxisY0: number; leftAxisY1: number; rightAxisY0: number; rightAxisY1: number; }) => {
    const { x0, x1, leftAxisY0, leftAxisY1, rightAxisY0, rightAxisY1 } = props;

    zoomDone = true;
    extentX0 = this.scaleLinearBottomAxisX.invert(x0);
    extentX1 = this.scaleLinearBottomAxisX.invert(x1);
    extentLeftAxisY0 = this.scaleLinearLeftAxisY.invert(leftAxisY1);
    extentLeftAxisY1 = this.scaleLinearLeftAxisY.invert(leftAxisY0);

    extentRightAxisY0 = this.scaleLinearRightAxisY.invert(rightAxisY1);
    extentRightAxisY1 = this.scaleLinearRightAxisY.invert(rightAxisY0);

    if (this.dispatch) {
      this.dispatch(stockingAnalysisSlice.setFirstStageZoom(extentX0));
      this.dispatch(stockingAnalysisSlice.setLastStageZoom(extentX1));
    }

    this.scaleLinearBottomAxisX.domain([x0, x1].map(this.scaleLinearBottomAxisX.invert));
    this.scaleLinearLeftAxisY.domain([leftAxisY1, leftAxisY0].map(this.scaleLinearLeftAxisY.invert));
    this.scaleLinearRightAxisY.domain([rightAxisY1, rightAxisY0].map(this.scaleLinearRightAxisY.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; leftAxisY0: number; leftAxisY1: number; rightAxisY0: number; rightAxisY1: number; }) => {
    const { x0, x1, leftAxisY0, leftAxisY1, rightAxisY0, rightAxisY1 } = props;

    zoomDone = false;
    this.scaleLinearBottomAxisX.domain([x0, x1]);
    this.scaleLinearLeftAxisY.domain([leftAxisY0, leftAxisY1]);
    this.scaleLinearRightAxisY.domain([rightAxisY0, rightAxisY1]);

    if (this.dispatch) {
      this.dispatch(stockingAnalysisSlice.setFirstStageZoom(x0));
      this.dispatch(stockingAnalysisSlice.setLastStageZoom(x1));
    }

    this.refreshBrush();
  }

  refreshBrush = () => {
    const { height } = this;

    const axisBottom = d3.axisBottom(this.scaleLinearBottomAxisX)
      .tickFormat(renderTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    const scaleLinearLeftAxisY = d3.axisLeft(this.scaleLinearLeftAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    const scaleLinearRightAxisY = d3.axisRight(this.scaleLinearRightAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.leftAxisY
      .transition()
      .duration(TIME_TRANSITION)
      .call(scaleLinearLeftAxisY);

    this.rightAxisY
      .transition()
      .duration(TIME_TRANSITION)
      .call(scaleLinearRightAxisY);

    const { line } = this.generateNormalLines();

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.gClipPath
      .selectAll('.normalLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', line as any);

    const { line: derivativeLine } = this.generateDerivativeLines();

    this.gClipPath
      .selectAll('.derivativeDataLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', derivativeLine as any);

    const movingAverageLine = this.getMovingAverageLine();
    this.gClipPath
      .selectAll('.movingAverageLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', movingAverageLine as any);

    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.scaleLinearBottomAxisX(point.x))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.scaleLinearLeftAxisY(point.y));

    this.renderResetBrush();
  }

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipFactorK')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentStageActive).attr('r', this.shouldShowAllPoints ? OTHER_CIRCLE_ACTIVE : DEFAULT_CIRCLE_ACTIVE);
      });
  }

  renderLineSelected () {
    const { container, groupMain, height, selectedTickStoke } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke)
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  updateDataPoints = () => {
    this.renderTriangleLeftAxisY();
    this.renderResetBrush();
    this.renderLinesAxis();
    this.buildAxisX();
    this.buildLeftAxisY();
    this.buildRightAxisY();

    this.createTooltip();
    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.renderLineSelected();
    this.createGClipPathElement();
    this.createMoonPhases();
    this.renderNormalLines();
    this.renderMovingAverageLine();
    this.renderDerivativeLines();
    this.renderPoints();
    this.hideBorders();

    this.drawXAxis();
    this.drawAxisLeftY();
    this.drawAxisRightY();
  };

  hideBorders () {
    const { container, groupMain, width, height, margin } = this;

    const hideBorder = groupMain.append('g')
      .attr('id', 'hideBorderContainer');

    d3.select(container).selectAll('.hideBorder').remove();

    //container top
    this.containerTop = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', -margin.top)
      .attr('width', width + margin.left + margin.right)
      .attr('height', margin.top)
      .style('fill', this.colorFillRect);

    // container bottom
    this.containerBottom = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', height)
      .attr('width', width + margin.left + margin.right)
      .attr('height', margin.bottom)
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', -margin.left)
      .attr('y', 0)
      .attr('width', margin.left)
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight = hideBorder.append('rect')
      .attr('class', 'hideBorder')
      .attr('x', width + 1)
      .attr('y', 0)
      .attr('width', margin.right)
      .attr('height', height + margin.bottom)
      .style('fill', this.colorFillRect);
  }

  updateBorders () {
    this.containerTop
      .style('fill', this.colorFillRect);

    // container bottom
    this.containerBottom
      .style('fill', this.colorFillRect);

    //container left
    this.containerLeft
      .style('fill', this.colorFillRect);

    //container right
    this.containerRight
      .style('fill', this.colorFillRect);
  }

  renderTooltips () {
    const {
      dataSource,
      scaleLinearBottomAxisX,
      scaleLinearLeftAxisY,
      tooltip,
      shouldShowAllPoints,
      firstStage,
      lastStage,
      allPoints,
      width,
      height,
      colorsPoints,
      groupMain,
      renderTooltipsForStockings,
    } = this;

    const bisect = d3.bisector(function (point: Point) {
      return point.x;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentStageActive).attr('r', shouldShowAllPoints ? OTHER_CIRCLE : DEFAULT_CIRCLE);
      })
      .on('mousemove', function (event) {
        const amountEnabled = dataSource.filter((data) => {
          if (data.enabled && data.avgPoint) {
            return data.avgPoint.length > 0;
          }
          return false;
        }).length;

        if (amountEnabled === 0) {
          return;
        }

        let x0 = scaleLinearBottomAxisX.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= scaleLinearBottomAxisX.domain()[0]) && (x0 <= scaleLinearBottomAxisX.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const index = bisect(allPoints, x0, 1);

        const previousPoint = allPoints[index - 1];
        const currentPoint = allPoints[index];
        let selectedPoint: Point;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.x > currentPoint.x - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.x < firstStage || selectedPoint.x > lastStage)) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        const dataByStage: DataByStage[] = [];
        const pointsList: Point[] = [];

        for (let index = 0; index < dataSource.length; index++) {
          const data = dataSource[index];
          if (!data.enabled) {
            continue;
          }

          const points: Point[] | undefined = data.points;
          if (!points || points.length === 0) {
            continue;
          }

          const pointsByStage = filterPointByStage(points, selectedPoint.x);
          const pointsConsolidated = filterPointConsolidated(pointsByStage);
          const pointsNotConsolidated = filterPointNotConsolidated(pointsByStage);

          pointsConsolidated.forEach((point: Point) => {
            const _dataByStage: DataByStage = { point, name: data.name, index, density: data.density };
            dataByStage.push(_dataByStage);
            pointsList.push(point);
          });

          pointsNotConsolidated.forEach((point: Point) => {
            const _dataByStage: DataByStage = { point, name: data.name, index, density: data.density };
            dataByStage.push(_dataByStage);
            pointsList.push(point);
          });
        }

        d3.selectAll('.points circle').attr('r', () => shouldShowAllPoints ? OTHER_CIRCLE : DEFAULT_CIRCLE);

        if (pointsList.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: Point = pointsList.reduce(function (prev: Point, current: Point) {
          return (prev.y > current.y) ? prev : current;
        });
        const lowestValue: Point = pointsList.reduce(function (prev: Point, current: Point) {
          return (prev.y < current.y) ? prev : current;
        });

        const marginLeft = scaleLinearBottomAxisX(higherValue.x);
        const marginBottom = scaleLinearLeftAxisY(lowestValue.y);

        currentStageActive = `.stage${higherValue.x}`;
        d3.selectAll(currentStageActive).attr('r', () => shouldShowAllPoints ? OTHER_CIRCLE_ACTIVE : DEFAULT_CIRCLE_ACTIVE);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', () => {
            let value = 0;
            const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

            if ((marginLeft + tooltipTotalWidth) < width) {
              value = -16; // has to be the same that width, but negative
            } else {
              value = tooltipTotalWidth;
            }

            return `${value}px`;
          });

        tooltip
          .style('left', () => {
            return getStockingChartLeftPosition(leftPositionProps);
          })
          .style('bottom', () => {
            let value = 0;
            const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;

            if (marginBottom + tooltipTotalHeight > height) {
              value = (height - marginBottom) + (tooltipTotalHeight / 4);
            } else {
              value = (height - marginBottom) - (tooltipTotalHeight / 4);
            }
            return `${value}px`;
          });

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsForStockings(dataByStage, tooltipContent, shouldShowAllPoints, colorsPoints, stockingPhaseTypes.ADULT);
      });
  }

  renderTooltipsForStockings (dataByStage: DataByStage[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>, shouldShowAllPoints: boolean, colorsPoints: string[], phaseType: string) {
    for (let index = 0; index < dataByStage.length; index++) {
      const data = dataByStage[index];

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryTitle = entry.append('div')
        .attr('class', styles.entryHeader);

      entryTitle.append('a')
        .attr('class', styles.entryTitle)
        .style('color', shouldShowAllPoints ? 'royalblue' : colorsPoints[data.index])
        .attr('href', `/production/analysis/${data.point._id}`)
        .attr('target', '_blank')
        .html(data.point.code);

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      let pigmentationLabel = i18next.t('shadedplot.type.pigmentation');
      pigmentationLabel = pigmentationLabel.toLowerCase();
      pigmentationLabel = pigmentationLabel.charAt(0).toUpperCase() + pigmentationLabel.slice(1);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${getLabelAxisX(phaseType)}: <strong>${formatter(phaseType, data.point.inputData.stage)}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.averageWeight')}: <strong>${roundWeight({ value: data.point.resultData.averageWeight })}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.averageLength')}: <strong>${roundLength({ value: data.point.resultData.averageLength })}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${capitalize(i18next.t('stockings.pdf.typeParameter.uniformity').toLowerCase())}: <strong>${data.point.resultData.uniformity} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('analysis.resultData.variationCoefficientLength')}: <strong>${data.point.resultData.variationCoefficientLength} %</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${pigmentationLabel}: <strong>${data.point.resultData.pigmentation}</strong>`);

      if (data.point.resultData.animalsAboveConditionFactor) {
        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('shadedplot.type.factorK')}: <strong>${data.point.resultData.animalsAboveConditionFactor} %</strong>`);
      }

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${i18next.t('stockings.pdf.density')}: <strong>${applyThousandsSeparator(data.density)}</strong>`);

      if (index !== dataByStage.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  getCurveType () {
    return d3.curveCatmullRom;
  }

  getLineData (avgPoint: Point[]) {
    const lineData: { [key: number]: Point[] } = {};

    for (let i = 0; i < avgPoint.length; ++i) {
      const point = avgPoint[i];
      const index = point.x;

      if (!lineData[index]) {
        lineData[index] = [];
      }

      lineData[index].push(point);
    }

    return lineData;
  }

  generateNormalLines () {
    const { scaleLinearBottomAxisX, scaleLinearLeftAxisY, dataSource } = this;

    const linePoints: Point[][] = [];
    const indexes: number[] = [];

    const line = d3.line<Point>()
      .x((d) => { return scaleLinearBottomAxisX(d.x); })
      .y((d) => { return scaleLinearLeftAxisY(d.y); })
      .curve(this.getCurveType());

    if (this.firstStage === this.lastStage) {
      return {
        indexes,
        linePoints,
        line,
      };
    }

    for (let index = 0; index < dataSource.length; index++) {
      const avgPoint = dataSource[index].avgPoint;
      const isEnabled = dataSource[index].enabled;

      if (!isEnabled) {
        continue;
      }

      const lineData = this.getLineData(avgPoint);
      const linePoint: Point[] = getLineData(lineData);
      linePoints.push(linePoint);
      indexes.push(index);
    }

    return {
      indexes,
      linePoints,
      line,
    };
  }

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width, showDerivative } = this;

    d3.select(container).selectAll('.lineLeftAxisY').remove();
    d3.select(container).selectAll('.lineRightAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', 'lineLeftAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', -8)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);


    if (showDerivative) {
      groupMain.append('line')
        .attr('class', 'lineRightAxisY')
        .attr('stroke', '#959595')
        .attr('fill', 'transparent')
        .attr('stroke-width', 1)
        .attr('y1', -8)
        .attr('y2', height)
        .attr('x1', width)
        .attr('x2', width);
    }
  }

  renderTriangleLeftAxisY = () => {
    const { container, width, margin, showDerivative } = this;

    const triangleMargins = {
      left: margin.left - 5,
      right: width + margin.left - 5,
    };

    d3.select(container).selectAll('.triangleComparisionChart').remove();
    d3.select(container).selectAll('.triangleGrowthDelta').remove();
    d3.select(container).selectAll('.triangleLeftAxisY').remove();
    d3.select(container).selectAll('.triangleRightAxisY').remove();

    d3.select(container)
      .attr('id', 'triangleLeftAxisY')
      .append('div')
      .attr('class', cx('triangleLeftAxisY', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);

    if (!showDerivative) {
      return;
    }

    d3.select(container)
      .attr('id', 'triangleRightAxisY')
      .append('div')
      .attr('class', cx('triangleRightAxisY', styles.triangle))
      .style('transform', `translate(${triangleMargins.right}px, 0px)`);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 60) + 'px')
      .style('top', '-8px');
  };

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  renderResetBrush = () => {
    const { margin, width, svg, colorLine } = this;
    const marginLeft = width + margin.left - margin.right;

    svg.select('#resetBrushButton').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft)
      .attr('y', 0)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('fill', 'red')
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        zoomDone = false;
        const { firstStage, lastStage } = this;

        const { maxY, minY } = this.getYDomainData();
        const domainDerivative = this.getYDomainDerivative();
        this.resetBrush({ x0: firstStage, x1: lastStage, leftAxisY0: minY, leftAxisY1: maxY, rightAxisY0: domainDerivative.minY, rightAxisY1: domainDerivative.maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', colorLine)
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  updateAxis () {
    const { height, firstStage, lastStage, typeMetric, dataMetric, showDerivative } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.renderTriangleLeftAxisY();
    this.renderResetBrush();
    this.renderLinesAxis();

    this.buildAxisX();
    this.buildLeftAxisY();
    this.buildRightAxisY();

    numbersTicks = getNumberTicks({ phaseType: stockingPhaseTypes.ADULT, firstStage, lastStage, typeMetric, dataMetric });
    const axisBottom = d3.axisBottom(this.scaleLinearBottomAxisX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    const leftAxis = d3.axisLeft(this.scaleLinearLeftAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.leftAxisY
      .attr('class', cx(styles.leftAxisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .transition()
      .duration(TIME_TRANSITION)
      .call(leftAxis);

    const rightAxis = d3.axisRight(this.scaleLinearRightAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.rightAxisY
      .attr('class', cx(styles.rightAxisY, isLightTheme ? styles.axisLight : styles.axisDark, showDerivative ? styles.showRightAxisY : styles.hideRightAxisY))
      .transition()
      .duration(TIME_TRANSITION)
      .call(rightAxis);
  }

  showOrHideFirstLineOfAxisXTick () {
    const tickG = d3.selectAll<HTMLElement, undefined>('#axisX>.tick').nodes()[0];
    const transformProperty: string = tickG?.getAttribute('transform') || '';
    const match = transformProperty.match(/translate\(([\d.]+),[\d.]+\)/);

    if (match) {
      const xPosition = parseFloat(match[1]);
      if (xPosition === 0) {
        d3.select(tickG).select('line').style('display', 'none');
      } else {
        d3.select(tickG).select('line').style('display', 'flex');
      }
    } else {
      d3.select(tickG).select('line').style('display', 'flex');
    }
  }

  updateData () {
    this.updateAxis();

    this.createGClipPathElement();
    this.createMoonPhases();
    this.renderNormalLines();
    this.renderMovingAverageLine();
    this.renderDerivativeLines();
    this.renderPoints();
    this.updateBorders();
  }

  refreshChart (props: { dataMetric: ItemReferenceCurves[]; dataSource?: DataSource[]; firstStage: number; lastStage: number; width: number; height: number; colorsPoints?: string[]; colorsStroke?: string[]; typeMetric?: string; scale?: string; movingAverage?: number; colorFillRect: string; colorLine: string; lunarPhaseStages?: LunarPhaseStages; showMovingAverage: boolean; showDerivative: boolean; selectedTickStoke: string; }) {
    const { dataMetric, dataSource = [], firstStage, lastStage, width, height, colorsPoints = [], colorsStroke = [], typeMetric, scale = typeScale.LINEAR, movingAverage = 2, colorFillRect, colorLine, lunarPhaseStages, showMovingAverage, showDerivative, selectedTickStoke } = props;
    const { container } = this;

    this.typeMetric = typeMetric;
    this.scale = scale;
    this.colorsPoints = colorsPoints;
    this.colorsStroke = colorsStroke;
    this.dataSource = dataSource;
    this.dataMetric = dataMetric;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.movingAverage = movingAverage;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.lunarPhaseStages = lunarPhaseStages;
    this.showMovingAverage = showMovingAverage;
    this.showDerivative = showDerivative;
    this.selectedTickStoke = selectedTickStoke;
    this.shouldShowAllPoints = dataSource.length === 1;

    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).select('#tooltipExtraPadding').remove();

    d3.select(container).selectAll('.movingAverageLine').remove();
    d3.select(container).selectAll('.normalLine').remove();
    d3.select(container).selectAll('.derivativeDataLine').remove();

    d3.select(container).selectAll('.labelsPoints').remove();
    d3.select(container).selectAll('.points').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    this.updateSize(width, height);
    this.sortData(dataMetric);
    this.renderLineSelected();
    this.updateData();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    let minX = firstStage;
    let maxX = lastStage;

    if (zoomDone) {
      minX = extentX0;
      maxX = extentX1;
    }

    this.scaleLinearBottomAxisX = d3.scaleLinear()
      .domain([minX, maxX])
      .range([0, width]);
  }

  buildLeftAxisY () {
    const { scale, height } = this;
    let { maxY, minY } = this.getYDomainData();

    if (zoomDone) {
      maxY = extentLeftAxisY1;
      minY = extentLeftAxisY0;
    }

    if (scale === typeScale.LOGARITHMIC) {
      this.scaleLinearLeftAxisY = d3.scaleSymlog()
        .domain([minY, maxY])
        .range([height, 0]);
    } else {
      this.scaleLinearLeftAxisY = d3.scaleLinear()
        .domain([minY, maxY])
        .range([height, 0]);
    }
  }

  buildRightAxisY () {
    const { scale, height } = this;
    let { maxY, minY } = this.getYDomainDerivative();

    if (zoomDone) {
      maxY = extentRightAxisY1;
      minY = extentRightAxisY0;
    }

    if (scale === typeScale.LOGARITHMIC) {
      this.scaleLinearRightAxisY = d3.scaleSymlog()
        .domain([minY, maxY])
        .range([height, 0]);
    } else {
      this.scaleLinearRightAxisY = d3.scaleLinear()
        .domain([minY, maxY])
        .range([height, 0]);
    }
  }

  getYDomainData = () => {
    const { dataSource, firstStage, lastStage } = this;
    const margin = getMarginArea(typeParam.FACTOR_K);

    let minY = getMinY(dataSource, [], firstStage, lastStage, typesChart.STOCKINGS);
    let maxY = getMaxY(dataSource, [], firstStage, lastStage, typesChart.STOCKINGS);

    minY = minY - (minY * margin) < 0 ? 0 : minY - (minY * margin);
    maxY = maxY + (maxY * margin);

    return {
      maxY,
      minY,
    };
  }

  getYDomainDerivative = () => {
    const { dataSource } = this;
    const dataRightAxisY: number[] = [];
    const dataX: number[] = [];

    for (let index = 0; index < dataSource.length; index++) {
      const { enabled, points } = dataSource[index];

      if (!enabled || !points) {
        continue;
      }

      for (let j = 0; j < points.length; j++) {
        const point = points[j];
        dataX.push(point.x);
        dataRightAxisY.push(point.y);
      }
    }

    const derivativeData = calcDerivativeData({ dataX: dataX, dataY: dataRightAxisY });
    const y = derivativeData.map((item) => item.y);

    const minY = Math.min(...y);
    const maxY = Math.max(...y);

    return {
      minY,
      maxY,
      derivativeData,
    };
  };

  drawXAxis () {
    const { height, groupMain, firstStage, lastStage, typeMetric, dataMetric } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    numbersTicks = getNumberTicks({ phaseType: stockingPhaseTypes.ADULT, firstStage, lastStage, typeMetric, dataMetric });

    const axis = d3.axisBottom(this.scaleLinearBottomAxisX)
      .tickFormat((x) => renderTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(10);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();
  }

  drawAxisLeftY () {
    const { groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisLeft(this.scaleLinearLeftAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.leftAxisY = groupMain.append('g')
      .attr('id', 'leftAxisY')
      .attr('class', cx(styles.leftAxisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  drawAxisRightY () {
    const { groupMain, width, showDerivative } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisRight(this.scaleLinearRightAxisY)
      .tickFormat((d) => renderTickLeftFormat({ format: d, phaseType: stockingPhaseTypes.ADULT, parameter: typeParam.FACTOR_K, forceCast: true }) as string)
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.rightAxisY = groupMain.append('g')
      .attr('id', 'rightAxisY')
      .attr('class', cx(styles.rightAxisY, isLightTheme ? styles.axisLight : styles.axisDark, showDerivative ? styles.showRightAxisY : styles.hideRightAxisY))
      .attr('transform', `translate(${width},0)`)
      .call(axis);
  }

  sortData (dataMetric: ItemReferenceCurves[]) {
    const { typeMetric } = this;

    this.sortDataSource();
    this.sortAllPoints();
    this.dataY1 = sortY1({ data: dataMetric, parameter: typeParam.FACTOR_K, typeMetric });
    this.dataY2 = sortY2({ data: dataMetric, parameter: typeParam.FACTOR_K, typeMetric });
  }

  sortDataSource () {
    const { dataSource } = this;

    for (let i = 0; i < dataSource.length; i++) {
      const points = getPoints(dataSource[i], typesChart.STOCKINGS);
      const avgPoints: Point[] = dataSource[i].avgPoint;

      const pointsList: Point[] = [];
      const avgPointsList: Point[] = [];

      if (points) {
        // eslint-disable-next-line
        for (let index = 0; index < points.length; index++) {
          const item: Point = {
            ...points[index],
            x: getPointX(points[index], typesChart.STOCKINGS),
            y: getPointY(points[index], typesChart.STOCKINGS, typeParam.FACTOR_K),
          };
          pointsList.push(item);
        }

        dataSource[i].points = pointsList;
        dataSource[i].points?.sort((a, b) => a.x - b.x);
      }

      for (let index = 0; index < avgPoints.length; index++) {
        const item = {
          ...avgPoints[index],
          x: getPointX(avgPoints[index], typesChart.STOCKINGS),
          y: getPointY(avgPoints[index], typesChart.STOCKINGS, typeParam.FACTOR_K),
        };
        avgPointsList.push(item);
      }

      dataSource[i].avgPoint = avgPointsList;
      dataSource[i].avgPoint?.sort((a, b) => a.x - b.x);
    }
  }

  sortAllPoints () {
    const { dataSource } = this;
    const pointsList: Point[] = [];

    for (let i = 0; i < dataSource.length; i++) {
      const enabled = dataSource[i].enabled;

      if (!enabled) {
        continue;
      }

      const points = getPoints(dataSource[i], typesChart.STOCKINGS);
      if (!points) {
        continue;
      }

      for (let index = 0; index < points.length; index++) {
        const item: Point = {
          ...points[index],
          x: getPointX(points[index], typesChart.STOCKINGS),
          y: getPointY(points[index], typesChart.STOCKINGS, typeParam.FACTOR_K),
        };
        pointsList.push(item);
      }
    }

    this.allPoints = pointsList;
    this.allPoints.sort((a, b) => a.x - b.x);
  }

  resize = (width: number, height: number) => {
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltipFactorK').remove();

    this.updateSize(width, height);
    this.sortData(this.dataMetric);
    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };

  createMoonPhases () {
    const { height, lunarPhaseStages, gClipPath } = this;

    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    for (const lunarPhase in lunarPhaseStages) {
      for (let index = 0; index < lunarPhaseStages[lunarPhase].length; index++) {
        const range = lunarPhaseStages[lunarPhase][index];

        gClipPath.append('rect')
          .attr('class', isLightTheme ? `${lunarPhase}_LIGHT` : `${lunarPhase}_DARK`)
          .attr('fill', 'pink')
          .attr('x', this.scaleLinearBottomAxisX(range[0]))
          .attr('y', 0)
          .attr('width', this.scaleLinearBottomAxisX(range[range.length - 1]) - this.scaleLinearBottomAxisX(range[0]))
          .attr('height', height);
      }
    }
  }

  renderNormalLines () {
    const { container, colorsPoints, gClipPath, brush, shouldShowAllPoints } = this;

    const { linePoints, line, indexes } = this.generateNormalLines();
    d3.select(container).selectAll('.brush').remove();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    for (let index = 0; index < linePoints.length; index++) {
      const linePoint = linePoints[index];
      gClipPath
        .append('path')
        .datum(linePoint)
        .attr('id', 'normalLine')
        .attr('class', cx('normalLine', styles.normalLine))
        .attr('stroke', shouldShowAllPoints ? this.colorLine : colorsPoints[indexes[index]])
        .attr('d', line);
    }
  }

  renderMovingAverageLine = () => {
    const { gClipPath, dataSource, showMovingAverage, dispatch } = this;

    if (!showMovingAverage) {
      return;
    }

    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const movingAverageLine = this.getMovingAverageLine();
    const itemDataSource = dataSource[0];

    const movingAverageData = this.calculateMovingAverage(itemDataSource);
    const className = dispatch ? isLightTheme ? styles.movingAverageLineLight : styles.movingAverageLineDark : styles.movingAverageLineLight;

    gClipPath.append('path')
      .datum(movingAverageData)
      .attr('class', cx('movingAverageLine', className))
      .attr('d', movingAverageLine);
  };

  calculateMovingAverage = (itemDataSource: DataSource) => {
    const { movingAverage: window = 2 } = this;

    const movingAverage: { stage: number; mean: number }[] = [];
    const data: { x: number; y: number }[] = [];

    itemDataSource.avgPoint.forEach((point) => {
      data.push({ x: point.x, y: point.y });
    });

    for (let i = window - 1; i < data.length; i++) {
      let sum = 0;
      for (let j = i - window + 1; j <= i; j++) {
        sum += data[j].y;
      }
      const mean = sum / window;
      movingAverage.push({ stage: data[i].x, mean });
    }

    return movingAverage;
  };

  getMovingAverageLine = () => {
    const { scaleLinearBottomAxisX, scaleLinearLeftAxisY } = this;

    const movingAverageLine = d3.line<{ stage: number; mean: number; }>()
      .x((d) => scaleLinearBottomAxisX(d.stage))
      .y((d) => scaleLinearLeftAxisY(d.mean))
      .curve(this.getCurveType());

    return movingAverageLine;
  };

  renderDerivativeLines () {
    const { gClipPath, showDerivative, dispatch } = this;

    if (!showDerivative) {
      return;
    }

    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const { derivativeData } = this.getYDomainDerivative();
    const { line } = this.generateDerivativeLines();

    const className = dispatch ? isLightTheme ? styles.derivativeDataLineLight : styles.derivativeDataLineDark : styles.derivativeDataLineLight;

    gClipPath
      .append('path')
      .datum(derivativeData)
      .attr('class', cx('derivativeDataLine', className))
      .attr('d', line);
  }

  generateDerivativeLines () {
    const { scaleLinearBottomAxisX, scaleLinearRightAxisY } = this;

    const line = d3.line<{ x: number; y: number; }>()
      .x((d) => { return scaleLinearBottomAxisX(d.x); })
      .y((d) => { return scaleLinearRightAxisY(d.y); })
      .curve(this.getCurveType());

    return { line };
  }

  renderPoints () {
    const { scaleLinearBottomAxisX, scaleLinearLeftAxisY, gClipPath, shouldShowAllPoints, colorsPoints } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    const { avgPoints, indexes } = this.generatePoints();

    for (let index = 0; index < avgPoints.length; index++) {
      const avgPoint = avgPoints[index];

      gClipPath.append('g')
        .attr('class', 'points')
        .attr('fill', 'transparent')
        .selectAll('circle')
        .data(avgPoint)
        .enter()
        .append('circle')
        .attr('id', (point: Point) => `point_${point.code}`)
        .attr('class', (point: Point) => {
          if (this.firstStage <= point.x && point.x <= this.lastStage) {
            return cx(styles.circle, `stage${point.x}`);
          }
          return styles.hideCircle;
        })
        .attr('r', DEFAULT_CIRCLE)
        .attr('cx', (point: Point) => scaleLinearBottomAxisX(point.x))
        .attr('cy', (point: Point) => scaleLinearLeftAxisY(point.y))
        .attr('fill', shouldShowAllPoints ? isLightTheme ? '#a8b7ec' : '#d9d9d9' : colorsPoints[indexes[index]])
        .attr('stroke', isLightTheme ? '#ffffff' : '#131B55')
        .attr('stroke-width', 2);
    }
  }

  generatePoints () {
    const { dataSource } = this;

    const avgPoints: Point[][] = [];
    const points: Point[][] = [];
    const indexes: number[] = [];

    for (let index = 0; index < dataSource.length; index++) {
      const point = dataSource[index].points;
      const avgPoint = dataSource[index].avgPoint;
      const isEnabled = dataSource[index].enabled;

      if (!isEnabled || !point) {
        continue;
      }

      points.push(point);
      avgPoints.push(avgPoint);
      indexes.push(index);
    }

    return {
      avgPoints,
      points,
      indexes,
    };
  }
}

export default FactorKChartD3;