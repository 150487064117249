import { useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import { LrvForm } from '../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvInputNumber } from '../../../common/components/LrvInputNumber/LrvInputNumber';
import { aggregationMethods, frequencyTypes, stockingParameterTypes } from '../../../config/commons';

import { disabledForm, getAggregationMethod, getFrequencyType } from './helpers';
import * as companyStockingParameterSlice from './companyStockingParameterSlice';
import { CompanyStockingParameterBody, GlobalStockingParameter } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const NewStockingParameterForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { company } = useSelector((state: Store) => state.header);
  const {
    globalStockingParameters,
    companyStockingParameters,
    showCreateParameterModal,
    isCreatingCompanyStockingParameterModal,
  } = useSelector((state: Store) => state.companyStockingParameter);

  const [parameterType, setParameterType] = useState<string | undefined>();
  const [stockingParameter, setStockingParameter] = useState<string>();
  const [options, setOptions] = useState<string[]>([]);
  const [unit, setUnit] = useState<string>();
  const [minimum, setMinimum] = useState<number | undefined>();
  const [maximum, setMaximum] = useState<number | undefined>();
  const [frequencyType, setFrequencyType] = useState<string | undefined>();
  const [aggregationMethod, setAggregationMethod] = useState<string | undefined>();
  const [globalParameterId, setGlobalParameterId] = useState<string | undefined>();

  const companyId = company._id;

  const closeModal = () => {
    dispatch(companyStockingParameterSlice.setShowCreateParameterModal(false));

    form.resetFields();

    setParameterType(undefined);
    setGlobalParameterId(undefined);
    setStockingParameter('');
    setOptions([]);
    setUnit('');
    setFrequencyType(frequencyTypes.INDIVIDUAL);
    setAggregationMethod(undefined);
    setMinimum(undefined);
    setMaximum(undefined);
  };

  const onChangeStockingParameters = (ids: string[]) => {
    const id = ids.length === 0 ? undefined : ids[ids.length - 1];
    const globalStockingParameter = globalStockingParameters.find((item: GlobalStockingParameter) => item.key === id);

    if (!globalStockingParameter?._id) {
      setStockingParameter(id);

      form.setFieldsValue({
        parameter: id,
        parameterType: undefined,
        options: undefined,
        frequency: undefined,
        unit: undefined,
        minimum: undefined,
        maximum: undefined,
      });

      return;
    }

    setStockingParameter(globalStockingParameter.key);
    setParameterType(globalStockingParameter.type);
    setOptions(globalStockingParameter.options);
    setUnit(globalStockingParameter.unit);
    setMinimum(globalStockingParameter.min);
    setMaximum(globalStockingParameter.max);
    setGlobalParameterId(globalStockingParameter._id);

    form.setFieldsValue({
      parameter: globalStockingParameter.key,
      parameterType: globalStockingParameter.type,
      options: globalStockingParameter.options,
      unit: globalStockingParameter.unit,
      minimum: globalStockingParameter.min,
      maximum: globalStockingParameter.max,
    });
  };

  const saveStockingParameter = () => {
    if (!stockingParameter || !parameterType) {
      return;
    }

    const body: CompanyStockingParameterBody = {
      companyId,
      key: stockingParameter,
      options,
      type: parameterType,
      unit: unit || undefined,
      min: minimum,
      max: maximum,
      frequencyType: getFrequencyType({ options, frequencyType }),
      aggregationMethod: getAggregationMethod({ options, frequencyType, aggregationMethod }),
      globalParameterId,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(companyStockingParameterSlice.createCompanyStockingParameter(props));
  };

  const renderStockingParameter = () => {
    const stockingParameters = globalStockingParameters.filter(globalParameter =>
      !companyStockingParameters.some(companyParameter => companyParameter.key === globalParameter.key)
    );

    return (
      <Form.Item
        name='parameter'
        label={t('stockingParameter.parameter')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          mode='tags'
          autoFocus
          placeholder={t('stockingParameter.parameter')}
          onChange={onChangeStockingParameters}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={stockingParameter}
        >
          {stockingParameters.map((stockingParameter: GlobalStockingParameter) =>
            <Option key={stockingParameter.key} value={stockingParameter.key}>{stockingParameter.key}</Option>)
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderParameterType = () => {
    return (
      <Form.Item
        name='parameterType'
        label={t('stockingParameter.form.type')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.type')}
          onChange={(value) => setParameterType(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={parameterType || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={stockingParameterTypes.CATEGORICAL} value={stockingParameterTypes.CATEGORICAL}>{t('stockingParameter.form.categorical')}</Option>)
          <Option key={stockingParameterTypes.NUMERIC} value={stockingParameterTypes.NUMERIC}>{t('stockingParameter.form.numeric')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderOptionsDropdown = () => {
    return (
      <Form.Item
        name='options'
        label={parameterType === stockingParameterTypes.CATEGORICAL ? t('stockingParameter.options') : t('stockingParameter.form.frequency')}
        required={parameterType === stockingParameterTypes.CATEGORICAL}
        rules={[{ required: parameterType === stockingParameterTypes.CATEGORICAL, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='options-dropdown'
          theme='light'
          showSearch
          mode='tags'
          autoFocus
          disabled={!stockingParameter}
          placeholder={parameterType === stockingParameterTypes.CATEGORICAL ? t('stockingParameter.options') : t('stockingParameter.form.frequency')}
          onChange={(values: string[]) => {
            setOptions(values);
          }}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={options}
        >
          {options.map((option) =>
            <Option key={option} value={option}>{option}</Option>)
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderFrequencyTypeDropdown = () => {
    if (parameterType === stockingParameterTypes.CATEGORICAL || options.length <= 1) {
      return;
    }

    return (
      <Form.Item
        name='frequencyType'
        label={t('stockingParameter.form.frequencyType')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.frequencyType')}
          onChange={(value) => setFrequencyType(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={frequencyType || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={frequencyTypes.INDIVIDUAL} value={frequencyTypes.INDIVIDUAL}>{t('stockingParameter.form.individual')}</Option>)
          <Option key={frequencyTypes.AGGREGATED} value={frequencyTypes.AGGREGATED}>{t('stockingParameter.form.aggregate')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderAggregationMethodDropdown = () => {
    if (parameterType === stockingParameterTypes.CATEGORICAL || options.length <= 1 || !frequencyType || frequencyType === frequencyTypes.INDIVIDUAL) {
      return;
    }

    return (
      <Form.Item
        name='aggregationMethod'
        label={t('stockingParameter.form.aggregationMethod')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          id='stocking-parameters-dropdown'
          theme='light'
          showSearch
          disabled={!stockingParameter}
          autoFocus
          placeholder={t('stockingParameter.form.aggregationMethod')}
          onChange={(value) => setAggregationMethod(value)}
          removeIcon={<Icon name='close' />}
          dropdownMatchSelectWidth={false}
          filterOption={filterOptionSelect}
          value={aggregationMethod || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option key={aggregationMethods.SUM} value={aggregationMethods.SUM}>{t('stockingParameter.form.sum')}</Option>)
          <Option key={aggregationMethods.AVERAGE} value={aggregationMethods.AVERAGE}>{t('stockingParameter.form.average')}</Option>)
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderUnitInput = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='unit'
        label={t('stockingParameter.unit')}
      >
        <LrvInput
          id='unit-input'
          theme='light'
          value={unit}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.unit')}
          onChange={(e) => setUnit(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderMinValue = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='minimum'
        label={t('stockingParameter.form.min')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInputNumber
          theme='light'
          min={0}
          max={maximum}
          value={minimum}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.form.min')}
          onChange={(value) => {
            if (value === undefined || value === null) {
              return;
            }

            setMinimum(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  const renderMaxValue = () => {
    if (parameterType !== stockingParameterTypes.NUMERIC) {
      return;
    }

    return (
      <Form.Item
        name='maximum'
        label={t('stockingParameter.form.max')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInputNumber
          theme='light'
          min={minimum}
          value={maximum}
          disabled={!stockingParameter}
          placeholder={t('stockingParameter.form.max')}
          onChange={(value) => {
            if (!value) {
              return;
            }

            setMaximum(parseInt(value.toString()));
          }}
        />
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme='light'
      title={t('stockingParameter.form.title')}
      open={showCreateParameterModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-stocking-parameter',
        htmlType: 'submit',
        form: 'form',
        loading: isCreatingCompanyStockingParameterModal,
        disabled: disabledForm({ aggregationMethod, frequencyType, options, parameterType, stockingParameter, maximum, minimum }),
      }}
      onOk={saveStockingParameter}
      okText={t('maturations.accept')}
      cancelText={t('maturations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-stocking-parameter'
        layout='vertical'
      >
        {renderStockingParameter()}
        {renderParameterType()}
        {renderOptionsDropdown()}

        <Row gutter={16}>
          <Col span={12}>
            {renderFrequencyTypeDropdown()}
          </Col>
          <Col span={12}>
            {renderAggregationMethodDropdown()}
          </Col>
        </Row>

        {renderUnitInput()}
        {renderMinValue()}
        {renderMaxValue()}
      </LrvForm>
    </LrvModal>
  );
};