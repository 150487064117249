import { Modal } from 'antd';
import cx from 'classnames';

import { LrvConfirmProps } from '../../interfaces/lrvConfirm';

import styles from './LrvConfirm.module.scss';

const { confirm } = Modal;

export const lrvConfirm = (props: LrvConfirmProps) => {
  const { wrapClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const newProps: LrvConfirmProps = {
    ...props,
    wrapClassName: cx(wrapClassName, isDarkTheme ? styles.darkConfirm : styles.lightConfirm),
  };

  return confirm({
    ...newProps,
  });
};
