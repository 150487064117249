import { Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import ActionButton from '../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../common/components/Breadcrumb/Breadcrumbs';
import * as onboardingSlice from '../../common/components/Onboarding/OnboardingSlice';

import styles from './UnitSubHeader.module.scss';
import { setShowCreateCampusModal } from './campusSlice';

export const UnitSubHeader = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { breadcrumb } = useSelector((state: Store) => state.header);
  const { run: isRunningOnboarding } = useSelector((state: Store) => state.onboarding);

  const theme = getCurrentTheme();

  return (
    <Row className={styles.rowHeader} align='middle' justify='space-between'>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />

      <Row className={styles.rightRow} justify='end' align='middle'>
        <Space size={18}>
          <ActionButton
            id='btn_new_campus'
            type='primary'
            icon={<Icon name='add' />}
            className={styles.btnAddUnit}
            onClick={() => {
              dispatch(setShowCreateCampusModal(true));

              if (isRunningOnboarding) {
                dispatch(onboardingSlice.goToOnboardingNextStep(500));
              }
            }}
          >
            {t('campus.campus')}
          </ActionButton>
        </Space>
      </Row>
    </Row>
  );
};
