import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { stockingPhaseTypes } from '../../../../config/commons';
import { getParameter } from '../../../../helpers/stocking.helpers';

import { typeParam } from './helpers';
import styles from './Legend.module.scss';

interface Props {
  color?: string;
  parameter: string;
  phaseTypeSelected?: string;
}

export const Legend = (props: Props) => {
  const { color, parameter, phaseTypeSelected } = props;

  const [t] = useTranslation();
  const parameterLabel = getParameter({ parameter, stockingPhaseType: '', addUnit: false });

  const renderLegends = () => {
    switch (parameter) {
      case typeParam.FACTOR_K:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.fullMoon)} />
              <span style={{ color }}>
                {t('detail.moonPhase.fullMoon')}
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.lastQuarterMoon)} />
              <span style={{ color }}>
                {t('detail.moonPhase.lastQuarterMoon')}
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.newMoonLight)} />
              <span style={{ color }}>
                {t('detail.moonPhase.newMoon')}
              </span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.moonPhaseBox, styles.firstQuarterMoon)} />
              <span style={{ color }}>
                {t('detail.moonPhase.firstQuarterMoon')}
              </span>
            </div>
          </div>
        );

      case typeParam.PIGMENTATION:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.pigmentation)} />
              <span style={{ color }}>{parameterLabel}</span>
            </div>
          </div>
        );

      case typeParam.GROWTH_DELTA:
        return (
          <div className={styles.legend}>
            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.pigmentation)} />
              <span style={{ color }}>{parameterLabel}</span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.colorBox, styles.movingAverage)} />
              <span style={{ color }}>{t('production.movingAverage')}</span>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.legend}>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.below)} />
              <span style={{ color }}>{t('shadedplot.legends.below')}</span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.normal)} />
              <span style={{ color }}>{t('shadedplot.legends.normal')}</span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.over)} />
              <span style={{ color }}>{t('shadedplot.legends.over')}</span>
            </div>

            <div className={styles.item}>
              <div className={cx(styles.circle, styles.consolidated)} />
              <span style={{ color }}>{t('shadedplot.legends.consolidated')}</span>
            </div>

            {
              parameter === typeParam.AVG_WEIGHT && phaseTypeSelected === stockingPhaseTypes.ADULT &&
              <div className={styles.item}>
                <div className={cx(styles.colorBox, styles.movingAverage)} />
                <span style={{ color }}>{t('production.movingAverage')}</span>
              </div>
            }
          </div>
        );
    }
  };

  return renderLegends();
};
