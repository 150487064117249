import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserSession } from '../../../utils/userSession';
import { UserSession } from '../../../common/interfaces/auth';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { STOCKING_PARAMETER_CUSTOMIZABLE_URL } from '../../../config/config.api';

import { StockingParameterCustomizablePayload, StockingParameterCustomizableState } from './interfaces';

const initialState: StockingParameterCustomizableState = {
  _id: '',
  companyId: '',
  fields: [],
  showCustomizableModal: false,
  isLoading: false,
};

export const stockingParameterCustomizableSlice = createSlice({
  name: 'stockingParameterCustomizable',
  initialState,
  reducers: {
    setStockingParameterCustomizable: (state: StockingParameterCustomizableState, action: PayloadAction<StockingParameterCustomizablePayload>) => {
      state._id = action.payload._id;
      state.companyId = action.payload.companyId;
      state.fields = action.payload.fields;
    },

    resetDetailCustomizable: (state: StockingParameterCustomizableState) => {
      state._id = '';
      state.companyId = '';
      state.fields = [];
    },

    setFields: (state: StockingParameterCustomizableState, action: PayloadAction<string[]>) => {
      state.fields = action.payload;
    },

    setIsLoading: (state: StockingParameterCustomizableState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setShowCustomizableModal: (state: StockingParameterCustomizableState, action: PayloadAction<boolean>) => {
      state.showCustomizableModal = action.payload;
    },
  },
});

export const {
  setStockingParameterCustomizable,
  resetDetailCustomizable,
  setFields,
  setIsLoading,
  setShowCustomizableModal,
} = stockingParameterCustomizableSlice.actions;

export const fetchStockingParameterCustomizable = () => async (dispatch: Function) => {
  const userSession: UserSession = getUserSession();

  dispatch(setIsLoading(true));

  const params = {
    $limit: -1,
    companyId: userSession.companyId,
  };

  try {
    const response = await axios.get<StockingParameterCustomizablePayload[]>(STOCKING_PARAMETER_CUSTOMIZABLE_URL, { params });
    if (response.data.length && response.data.length > 0) {
      dispatch(setStockingParameterCustomizable(response.data[0]));
    }
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const saveStockingParameterCustomizable = (props: { companyId: string; fields: string[] }) => async (dispatch: Function) => {
  const { companyId, fields } = props;

  const body = {
    companyId,
    fields,
  };

  try {
    const response = await axios.post(STOCKING_PARAMETER_CUSTOMIZABLE_URL, body);
    dispatch(setStockingParameterCustomizable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateStockingParameterCustomizable = (props: { _id: string; fields: string[] }) => async (dispatch: Function) => {
  const { _id, fields } = props;

  const url = `${STOCKING_PARAMETER_CUSTOMIZABLE_URL}/${_id}`;
  const body = { fields };

  try {
    const response = await axios.patch(url, body);
    dispatch(setStockingParameterCustomizable(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default stockingParameterCustomizableSlice.reducer;
