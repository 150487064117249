import cx from 'classnames';

import styles from './LrvMoonPhase.module.scss';

export const LrvFullMoon = () => {
  return (
    <div className={cx(styles.moon, styles.fullMoon)}></div>
  );
};

export const LrvNewMoon = () => {
  return (
    <div className={cx(styles.moon, styles.newMoon)}></div>
  );
};

export const LrvFirstQuarterMoon = () => {
  return (
    <div className={cx(styles.moon, styles.firstQuarterMoon)}></div>
  );
};

export const LrvLastQuarterMoon = () => {
  return (
    <div className={cx(styles.moon, styles.lastQuarterMoon)}></div>
  );
};
