import cx from 'classnames';
import { Input, InputRef } from 'antd';
import React, { forwardRef } from 'react';

import { LrvInputProps } from '../../interfaces/lrvInput';

import styles from './LrvPassword.module.scss';

const { Password } = Input;

export const LrvPassword = forwardRef<InputRef, LrvInputProps>((props, ref) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvInputProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkInput : styles.lightInput, className),
  };

  return (
    <Password {...lrvInputProps} ref={ref}>
    </Password>
  );
});

