import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../state/store';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';

import { SimilarMaturation } from './interfaces';
import * as baseMaturationsSlice from './baseMaturationsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const MergeMaturation = (props: Props) => {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { currentPage, showMergeMaturationModal, baseMaturation } = useSelector((state: RootState) => state.baseMaturations);

  const columns = [
    {
      key: 1,
      title: t('maturations.name'),
      dataIndex: 'maturationName',
      width: '40%',
    },
    {
      key: 2,
      title: t('maturations.merge.rating'),
      dataIndex: 'rating',
      width: '30%',
    },
    {
      key: 3,
      width: '30%',
      render: (record: SimilarMaturation) => {
        return (
          <Button
            type='primary'
            onClick={async (e) => {
              e.stopPropagation(); // This avoids click confusion when cell row is clicked
              const data = { mainMaturationId: baseMaturation._id, secondaryMaturationId: record.maturationId };

              await baseMaturationsSlice.mergeMaturation(data);
              dispatch(baseMaturationsSlice.setShowMergeMaturationModal(false));
              dispatch(baseMaturationsSlice.fetchAllBaseMaturations({ page: currentPage }));
            }}
          >
            {t('maturations.merge.title')}
          </Button>
        );
      }
    },
  ];

  const closeModal = () => {
    dispatch(baseMaturationsSlice.setShowMergeMaturationModal(false));
  };

  return (
    <LrvModal
      theme='light'
      title={t('maturations.merge.title')}
      open={showMergeMaturationModal}
      destroyOnClose={true}
      footer={null}
      onCancel={closeModal}
    >
      <LrvText
        text={t('maturations.merge.description', { name: baseMaturation.name })}
        theme={theme}
      />

      <LrvTable
        id='marge-maturation-table'
        columns={columns}
        dataSource={baseMaturation.similarMaturations}
        size='small'
        theme={theme}
      />
    </LrvModal>
  );
};

export default MergeMaturation;