import i18next from 'i18next';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { STOCKINGS_URL } from '../../../config/config.api';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';
import PopulationTypes, { EditPopulationStockingState, PopulationData, Stocking, StockingPopulation } from '../interfaces';

const initialState: EditPopulationStockingState = {
  isLoadingStocking: false,
  isUpdating: false,
  selectedStocking: {
    _id: '',
    code: '',
    type: '',
    bindingStockings: [],
    populations: [],
    name: '',
    status: '',
    startDate: '',
    startDateJuvenile: '',
    startDateGrowOut: '',
    endDate: '',
    naupliusNumber: 0,
    juvenilesNumber: 0,
    growOutNumber: 0,
    litersNumber: 0,
    cubicMeters: 0,
    hectares: 0,
    createdAt: '',
    comment: '',
    stage: 0,
    shouldFinish: false,
    poundsHarvested: 0,
    harvestQuantity: 0,
    phaseType: '',
    companyId: '',
    larvaePerGram: 0,
    campusId: {
      _id: '',
      name: '',
      code: '',
      status: '',
      phaseType: '',
    },
    tankId: {
      _id: '',
      name: '',
      status: '',
      type: ''
    },
    maturationId: {
      _id: '',
      name: '',
      codes: [],
    },
    maturationCode: '',
    moduleId: {
      _id: '',
      name: '',
    },
    analysisCount: 0,
    isPublic: false,
    isArchived: false,
    transfers: [],
    harvests: [],
    customStage: {
      key: '',
      value: 0,
    },
    daysToInitialStage: 0,
  },
  stockingTransfers: [],
};

export const editPopulationStockingSlice = createSlice({
  name: 'editPopulationStocking',
  initialState,
  reducers: {
    setIsLoadingStocking: (state: EditPopulationStockingState, action: PayloadAction<boolean>) => {
      state.isLoadingStocking = action.payload;
    },
    setIsUpdatingStocking: (state: EditPopulationStockingState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setSelectedStocking: (state: EditPopulationStockingState, action: PayloadAction<Stocking>) => {
      state.selectedStocking = action.payload;
    },
    setStockingTransfers: (state: EditPopulationStockingState, action: PayloadAction<Stocking[]>) => {
      state.stockingTransfers = action.payload;
    },
  },
});

export const {
  setIsLoadingStocking, setIsUpdatingStocking, setSelectedStocking, setStockingTransfers,
} = editPopulationStockingSlice.actions;

export const fetchStocking = (stockingId: string) => async (dispatch: Function) => {
  dispatch(setIsLoadingStocking(true));

  const params = {
    '$sort[startDate]': '-1',
    $select: [
      'name',
      'code',
      'phaseType',
      'startDate',
      'startDateJuvenile',
      'startDateGrowOut',
      'naupliusNumber',
      'juvenilesNumber',
      'growOutNumber',
      'harvestWeight',
      'larvaePerGram',
      'harvestQuantity',
      'averageHarvestedWeight',
      'status',
      'populations',
      'harvests',
      'transfers',
      'litersNumber',
      'hectares',
      'cubicMeters',
      'endDate',
      'survivalRate',
    ],
    $populate: ['campusId', 'moduleId', 'tankId'],
  };

  try {
    const response = await axios.get<Stocking>(`${STOCKINGS_URL}/${stockingId}`, { params });
    const stocking = response.data;

    if (!stocking.populations?.length) {
      dispatch(setSelectedStocking(stocking));
      dispatch(setIsLoadingStocking(false));
      return;
    }

    dispatch(setSelectedStocking(stocking));
  } catch (e) {
    dispatch(setSelectedStocking(initialState.selectedStocking));
    console.log(e?.response);
  }
  dispatch(setIsLoadingStocking(false));
};

export const patchStockingPopulations = (params: { stockingId: string; data: PopulationData[] }) => async (dispatch: Function) => {
  const { stockingId, data } = params;
  dispatch(setIsUpdatingStocking(true));
  const populationsFiltered = data.filter(item => item.type === PopulationTypes.MANUAL_POPULATION);
  const populations: StockingPopulation[] = populationsFiltered.map(item => ({
    animalsNumber: item.animalsNumber,
    density: item.density,
    populationDate: item.populationDate,
    survivalRate: item.survivalRate,
  }));
  const body = { populations };

  try {
    await axios.patch(`${STOCKINGS_URL}/${stockingId}`, body);
    openSuccessNotification(i18next.t('stockings.populations.populationSuccess'));
  } catch (error) {
    console.log(error?.response);
  }
  dispatch(setIsUpdatingStocking(false));
};

export const fetchStockingTransfersData = (params: { companyId: string; stockingIds: string[] }) => async (dispatch: Function) => {
  const { companyId, stockingIds } = params;
  dispatch(setStockingTransfers([]));

  try {
    const fetchParams = {
      $limit: -1,
      companyId,
      '_id[$in]': stockingIds,
      '$select': ['name'],
    };

    const response = await axios.get(STOCKINGS_URL, { params: fetchParams });
    if (response?.data) {
      dispatch(setStockingTransfers(response.data));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export default editPopulationStockingSlice.reducer;
