import cx from 'classnames';
import { Button } from 'antd';
import { clone } from 'lodash';
import { useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import { Banner } from '../Settings/interfaces';
import { getLanguage } from '../../utils/language';
import Icon from '../../common/components/Icon/Icon';
import { getMainRole, roles } from '../../config/commons';
import { GenericParam } from '../../common/interfaces/commons';

import styles from './ImmediateAttentionTopBar.module.scss';
import { showAlertByCompany, showAlertByDate } from './helpers';
import { setAlertMessage, setShowTopBarAlert } from './headerSlice';

export const ImmediateAttentionTopBar = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { showTopBarAlert, alertMessage, showPreviousAlert } = useSelector((state: RootState) => state.header);
  const [showAlertAnimation, setShowAlertAnimation] = useState(false);
  const higherRole = getMainRole();

  const language = getLanguage().toLowerCase();

  const getAlertMessage = useCallback(() => {
    const alertMessage: Banner = JSON.parse(localStorage.getItem('alertMessage') || '{}');
    dispatch(setAlertMessage(alertMessage));
  }, [dispatch, pathname]);

  useEffect(() => {
    getAlertMessage();
  }, [getAlertMessage]);

  useEffect(() => {
    if (!alertMessage) {
      return;
    }

    if (pathname === '/settings' && (higherRole === roles.SUPER_ADMIN || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER)) {
      dispatch(setShowTopBarAlert(showPreviousAlert));
      return;
    }

    if (!alertMessage.persistentMode && !alertMessage.showAlert) {
      return;
    }

    const showAlert = showAlertByCompany(alertMessage) && showAlertByDate(alertMessage) && alertMessage.showAlert && !alertMessage.alertClosed;
    dispatch(setShowTopBarAlert(showAlert));
  }, [dispatch, alertMessage, showPreviousAlert]);

  useEffect(() => {
    setTimeout(() => {
      setShowAlertAnimation(showTopBarAlert);
    }, 1000);
  }, [dispatch, showTopBarAlert]);

  if (!showTopBarAlert) {
    return null;
  }

  const onCloseBanner = () => {
    if (!alertMessage) {
      return;
    }

    if (alertMessage.persistentMode) {
      dispatch(setShowTopBarAlert(false));
      setShowAlertAnimation(false);
      return;
    }

    const messages: Banner = clone(alertMessage);
    messages.showAlert = false;
    messages.alertClosed = true;
    localStorage.setItem('alertMessage', JSON.stringify(messages));
    dispatch(setShowTopBarAlert(false));
    setShowAlertAnimation(false);
    getAlertMessage();
  };

  return (
    <div
      className={cx(styles.topBar, showAlertAnimation ? styles.showWithAnimation : '')}
      style={{
        backgroundColor: alertMessage?.backgroundColor,
      }}
    >
      <div className={styles.left}>
        <Icon name={alertMessage?.icon || 'alarm-warning'} className={styles.icon} />

        <div className={styles.message}>
          {alertMessage ? (alertMessage as GenericParam)[language] || '' : ''}
        </div>
      </div>
      <div className={styles.right}>
        <Button
          className={styles.closeButton}
          type='text'
          icon={<Icon name='close' className={styles.icon} />}
          onClick={onCloseBanner}
        />
      </div>
    </div>
  );
};
