import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Form, Select } from 'antd';
import { useEffect, useState } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';

import { RootState } from '../../state/store';
import { isNumber } from '../../utils/validations';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { ReferenceCurvesDto } from '../Units/interfaces';
import { getParameter } from '../../helpers/stocking.helpers';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvPopover } from '../../common/components/LrvPopover/LrvPopover';
import { roundTwoDecimals, stockingPhaseTypes } from '../../config/commons';
import { typeParam } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';

import styles from './ReferenceCurvesModal.module.scss';
import ReferenceCurveChart from './ReferenceCurveChart';
import { createUnitReferenceCurve, updateUnitReferenceCurve } from './UnitsReferenceCurves/UnitsReferenceCurvesSlice';
import { createCompanyReferenceCurve, updateCompanyReferenceCurve } from './CompanyReferenceCurves/CompanyReferenceCurvesSlice';
import { areInOrderAndUnique, getDayTitle, getMultipliedReferences, getXaxisLabel, isValidDay, isValidMean, maxMeanValue } from './helpers';

interface Props {
  showModal: boolean;
  onClose: () => void;
  isLoading: boolean;
  currentTab: 'GLOBAL_REFERENCE_CURVES' | 'COMPANY_REFERENCE_CURVES' | 'UNIT_REFERENCE_CURVES';
  campusId?: string;
  campusName?: string;
  fetchAllUnits?: boolean;
  readOnly?: boolean;
}

interface ReferenceCurve {
  key: number;
  stage: number | undefined;
  mean: number | undefined;
  range: number | undefined;
}

const { Option } = Select;
const initialReferenceCurveData: ReferenceCurve = { key: 1, stage: undefined, mean: undefined, range: undefined };

export const ReferenceCurvesModal = (props: Props) => {
  const { showModal, isLoading, onClose, campusId, campusName, currentTab, fetchAllUnits, readOnly = false } = props;
  const [t] = useTranslation();
  const theme = getCurrentTheme();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { company } = useSelector((state: Store) => state.header);
  const { unitsReferenceCurves, companyReferenceCurves, globalReferenceCurves } = useSelector((state: RootState) => state);

  const [newName, setNewName] = useState('');
  const [newPhaseType, setNewPhaseType] = useState('');
  const [newParameter, setNewParameter] = useState('');
  const [referenceCurves, setReferenceCurves] = useState<ReferenceCurve[]>([initialReferenceCurveData]);
  const [disableSaveButton, setDisabledSaveButton] = useState(true);

  const isCompanyReferenceCurve = currentTab === 'COMPANY_REFERENCE_CURVES';
  const referenceCurveSelected = currentTab === 'UNIT_REFERENCE_CURVES' ?
    unitsReferenceCurves.referenceCurveSelected :
    currentTab === 'COMPANY_REFERENCE_CURVES' ?
      companyReferenceCurves.referenceCurveSelected :
      globalReferenceCurves.referenceCurveSelected;

  useEffect(() => {
    if (!showModal) {
      onCloseModal();
    }

    return (() => {
      form.resetFields();
    });
  }, [form, showModal]);

  useEffect(() => {
    if (referenceCurves) {
      const disabled = !isValidReferenceCurves();
      setDisabledSaveButton(disabled);
    }
  }, [referenceCurves]);

  useEffect(() => {
    if (!referenceCurveSelected?._id) {
      onCloseModal();
      return;
    }

    setNewName(referenceCurveSelected.name);
    setNewPhaseType(referenceCurveSelected.phaseType);
    setNewParameter(referenceCurveSelected.type);
    setReferenceCurves(referenceCurveSelected.values);

    form.setFieldsValue({
      name: referenceCurveSelected.name,
      phaseType: referenceCurveSelected.phaseType,
      campusId: referenceCurveSelected.campusId,
      metricType: referenceCurveSelected.type,
    });
  }, [form, referenceCurveSelected]);

  const onCloseModal = () => {
    form.resetFields();
    setNewName('');
    setNewParameter('');
    setNewPhaseType('');
    setReferenceCurves([initialReferenceCurveData]);
    setDisabledSaveButton(true);
  };

  const onSaveForm = () => {
    if (currentTab === 'GLOBAL_REFERENCE_CURVES') {
      onCloseModal();
      onClose();
      return;
    }

    const data: ReferenceCurvesDto = {
      _id: referenceCurveSelected._id,
      companyId: company._id,
      name: newName,
      phaseType: newPhaseType,
      type: newParameter,
      values: getMultipliedReferences({ parameter: newParameter, phaseType: newPhaseType, referenceCurves }),
      active: referenceCurveSelected.active,
    };

    if (isCompanyReferenceCurve) {
      if (referenceCurveSelected._id) {
        dispatch(updateCompanyReferenceCurve({ referenceCurve: data, phaseType: companyReferenceCurves.phaseType }));
        return;
      }
      dispatch(createCompanyReferenceCurve({ referenceCurve: data, phaseType: companyReferenceCurves.phaseType }));
      return;
    }

    data.campusId = campusId;

    if (referenceCurveSelected._id) {
      dispatch(updateUnitReferenceCurve({ referenceCurve: data, fetchAllUnits, phaseType: unitsReferenceCurves.phaseType }));
      return;
    }
    dispatch(createUnitReferenceCurve({ referenceCurve: data, phaseType: unitsReferenceCurves.phaseType }));
  };

  const updateReferenceCurves = (record: ReferenceCurve, value: string | number) => {
    const referenceCurvesCopy: ReferenceCurve[] = cloneDeep(referenceCurves);
    record.mean = parseFloat(value.toString());
    referenceCurvesCopy[record.key] = record;
    setReferenceCurves(referenceCurvesCopy);
  };

  const handleInputChange = (record: ReferenceCurve, value: string | number | null) => {
    if (value == null || value == undefined || !isNumber(value)) {
      return;
    }

    updateReferenceCurves(record, value);
  };

  const renderDayErrorLabel = (props: { stage?: number; mean?: number; index: number }) => {
    const { index, mean, stage } = props;

    if (!isValidDay({ referenceCurves, day: stage, index })) {
      return (
        <div className={styles.labelError}>{t('references.invalid')}</div>
      );
    }

    if (!isValidMean({ mean, newParameter, newPhaseType })) {
      return (
        <div className={styles.labelError}>&nbsp;</div>
      );
    }

    return null;
  };

  const renderMeanErrorLabel = (props: { stage?: number; mean?: number; index: number }) => {
    const { index, mean, stage } = props;

    if (!isValidMean({ mean, newParameter, newPhaseType })) {
      return (
        <div className={styles.labelError}>{t('references.maxValue', { value: maxMeanValue({ mean, newParameter, newPhaseType }) })}</div>
      );
    }

    if (!isValidDay({ referenceCurves, day: stage, index })) {
      return (
        <div className={styles.labelError}>&nbsp;</div>
      );
    }

    return null;
  };

  const renderErrorLabel = (props: { stage?: number; mean?: number; index: number }) => {
    const { index, mean, stage } = props;

    if (!isValidMean({ mean, newParameter, newPhaseType })) {
      return (
        <div className={styles.labelError}>&nbsp;</div>
      );
    }

    if (!isValidDay({ referenceCurves, day: stage, index })) {
      return (
        <div className={styles.labelError}>&nbsp;</div>
      );
    }

    return null;
  };

  const renderContent = () => {
    return (
      <div>
        <div>{getDayTitle(newPhaseType)}</div>
        <div>{t('references.dayDescription.2')}</div>
      </div>
    );
  };

  const renderDayTitle = () => {
    return (
      <>
        {getXaxisLabel(newPhaseType)}
        &nbsp;&nbsp;
        <LrvPopover
          content={renderContent()}
          placement='right'
        >
          <InfoCircleOutlined />
        </LrvPopover>
      </>
    );
  };

  const renderRowsReferenceCurve = () => {
    const columns: ColumnProps<ReferenceCurve>[] = [
      {
        title: renderDayTitle(),
        dataIndex: 'stage',
        width: '15%',
        className: `${styles.column}`,
        render: (_, record: ReferenceCurve, index: number) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.stage}
                className={cx(styles.input, isValidDay({ referenceCurves, day: record.stage, index }) ? '' : styles.inputError)}
                min={0}
                disabled={readOnly}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  const referenceCurvesCopy: ReferenceCurve[] = cloneDeep(referenceCurves);
                  record.stage = parseInt(value.toString());
                  referenceCurvesCopy[record.key] = record;
                  setReferenceCurves(referenceCurvesCopy);
                }}
              />
              {renderDayErrorLabel({ stage: record.stage, mean: record.mean, index })}
            </>
          );
        },
      },
      {
        title: newParameter ? getParameter({ parameter: newParameter, stockingPhaseType: newPhaseType, addUnit: true }) : t('referenceCurve.mean'),
        dataIndex: 'mean',
        width: '20%',
        className: `${styles.column}`,
        render: (_, record: ReferenceCurve, index: number) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.mean}
                className={cx(styles.input, isValidMean({ mean: record.mean, newParameter, newPhaseType }) ? '' : styles.inputError)}
                onChange={(value) => handleInputChange(record, value)}
                parser={(input) => `${input}`.replace(',', '.')}
                disabled={readOnly}
              />
              {renderMeanErrorLabel({ stage: record.stage, mean: record.mean, index })}
            </>
          );
        },
      },
      {
        title: t('references.percent'),
        dataIndex: 'range',
        width: '15%',
        className: `${styles.column}`,
        render: (_, record: ReferenceCurve, index: number) => {
          return (
            <>
              <LrvInputNumber
                theme='light'
                value={record.range}
                className={styles.input}
                min={0}
                disabled={readOnly}
                onChange={(value) => {
                  if (value == null || value == undefined || !isNumber(value)) {
                    return;
                  }

                  const referenceCurvesCopy: ReferenceCurve[] = cloneDeep(referenceCurves);
                  record.range = parseInt(value.toString());
                  referenceCurvesCopy[record.key] = record;
                  setReferenceCurves(referenceCurvesCopy);
                }}
              />
              {renderErrorLabel({ stage: record.stage, mean: record.mean, index })}
            </>
          );
        },
      },
      {
        title: t('references.rangeSelect'),
        width: '18%',
        className: cx(styles.column, styles.range),
        render: (_, record: ReferenceCurve, index: number) => {
          if (!record.mean || !record.range) {
            return (
              <>
                <LrvText
                  theme='light'
                  text='-'
                />
                {renderErrorLabel({ stage: record.stage, mean: record.mean, index })}
              </>
            );
          }

          const minValue = record.mean - (record.mean * (record.range / 100));
          const maxValue = record.mean + (record.mean * (record.range / 100));
          const value = `${roundTwoDecimals(minValue)} - ${roundTwoDecimals(maxValue)}`;

          return (
            <>
              <LrvText
                theme='light'
                text={value}
              />
              {renderErrorLabel({ stage: record.stage, mean: record.mean, index })}
            </>
          );
        },
      }
    ];

    if (!readOnly) {
      const item: ColumnProps<ReferenceCurve> = {
        title: '',
        dataIndex: 'delete',
        width: '6%',
        render: (_, record: ReferenceCurve, index: number) => {
          if (readOnly) {
            return null;
          }

          return (
            <>
              <IconButton
                theme='light'
                className={styles.buttonDelete}
                icon={<Icon name='delete-bin' type='line' theme={theme} className={styles.icon} />}
                onClick={() => {
                  const referenceCurvesCopy: ReferenceCurve[] = cloneDeep(referenceCurves);
                  const newReferenceCurves = referenceCurvesCopy.filter((item, index) => index !== record.key);

                  if (newReferenceCurves.length === 0) {
                    newReferenceCurves.push(initialReferenceCurveData);
                  }
                  setReferenceCurves(newReferenceCurves);
                }}
              />
              {renderErrorLabel({ stage: record.stage, mean: record.mean, index })}
            </>
          );
        },
      };

      columns.push(item);
    }

    const data: ReferenceCurve[] = [];
    for (let i = 0; i < referenceCurves.length; ++i) {
      data.push({
        key: i,
        stage: referenceCurves[i].stage,
        mean: referenceCurves[i].mean,
        range: referenceCurves[i].range,
      });
    }

    return (
      <Form.Item>
        <LrvTable
          theme='light'
          className={styles.table}
          columns={columns}
          rowClassName={styles.row}
          dataSource={data}
          size='small'
          pagination={false}
          scroll={{ y: 272 }}
        />
      </Form.Item>
    );
  };

  const isValidAllMeans = () => {
    if (!referenceCurves.length) {
      return false;
    }

    for (let index = 0; index < referenceCurves.length; index++) {
      const referenceCurve = referenceCurves[index];
      if (referenceCurve.mean === undefined || referenceCurve.stage === undefined || referenceCurve.range === undefined) {
        return false;
      }

      if ((!isValidMean({ mean: referenceCurve.mean, newParameter, newPhaseType }))) {
        return false;
      }
    }

    return true;
  };

  const isNotEmptyReferenceData = () => {
    if (!referenceCurves.length || !newParameter || !newPhaseType) {
      return false;
    }

    for (let index = 0; index < referenceCurves.length; index++) {
      const referenceCurve = referenceCurves[index];
      if (referenceCurve.mean === undefined || referenceCurve.stage === undefined || referenceCurve.range === undefined) {
        return false;
      }
    }

    return true;
  };

  const isValidReferenceCurves = () => {
    if (!isNotEmptyReferenceData()) {
      return false;
    }

    if (!isValidAllMeans()) {
      return false;
    }

    const days = referenceCurves.map((item) => item.stage || 0);
    return areInOrderAndUnique(days);
  };

  const addRowReferenceCurve = () => {
    const data: ReferenceCurve[] = [...referenceCurves];
    data.push(initialReferenceCurveData);
    setReferenceCurves(data);
    setDisabledSaveButton(true);
  };

  const renderNewReferenceCurveButton = () => {
    if (readOnly) {
      return null;
    }

    return (
      <Form.Item className={styles.formItem}>
        <ActionButton
          id='btn_new_reference_curve'
          className={styles.button}
          icon={<Icon name='add' />}
          disabled={!isValidReferenceCurves()}
          onClick={() => {
            if (isValidReferenceCurves()) {
              addRowReferenceCurve();
            }
          }}
          theme='light'
          type='ghost'
        >
          {t('referenceCurve.new')}
        </ActionButton>
      </Form.Item>
    );
  };

  const renderCurveReferenceForm = () => {
    return (
      <LrvForm
        theme='light'
        form={form}
        className={styles.referenceCurves}
        layout='vertical'
        name='formCurveReference'
        id='formCurveReference'
        onFieldsChange={() => {
          const disabled = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !isValidReferenceCurves();
          setDisabledSaveButton(disabled);
        }}
      >
        <Form.Item
          required
          label={t('references.name')}
          name='name'
          rules={[{ required: true, message: t('references.nameRequired') }]}
        >
          <LrvInput
            theme='light'
            autoFocus
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            disabled={readOnly}
          />
        </Form.Item>

        <Form.Item
          required
          label={t('references.phaseSelect')}
          name='phaseType'
          rules={[{ required: true, message: t('references.phaseRequired') }]}
          initialValue={newPhaseType}
        >
          <LrvSelect
            theme='light'
            value={newPhaseType}
            onChange={e => setNewPhaseType(e)}
            suffixIcon={<Icon name='arrow-down-s' />}
            dropdownMatchSelectWidth={false}
            disabled={readOnly}
          >
            <Option value={stockingPhaseTypes.LARVAE}>{t('header.phaseTypes.larvae')}</Option>
            <Option value={stockingPhaseTypes.JUVENILE}>{t('header.phaseTypes.juveniles')}</Option>
            <Option value={stockingPhaseTypes.ADULT}>{t('header.phaseTypes.growOut')}</Option>
          </LrvSelect>
        </Form.Item>

        <Form.Item
          required
          label={t('references.metricTypeSelect')}
          name='metricType'
          rules={[{ required: true, message: t('references.metricTypeRequired') }]}
          initialValue={newParameter}
        >
          <LrvSelect
            theme='light'
            value={newParameter}
            onChange={e => setNewParameter(e)}
            suffixIcon={<Icon name='arrow-down-s' />}
            dropdownMatchSelectWidth={false}
            disabled={readOnly}
          >
            {newPhaseType === stockingPhaseTypes.LARVAE &&
              <Option value={typeParam.PLG}>{t('analysis.resultData.larvaePerGram')}</Option>
            }

            <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
            <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
            <Option value={typeParam.UNIFORMITY}>{t('analysis.resultData.uniformity')}</Option>
          </LrvSelect>
        </Form.Item>

        {renderRowsReferenceCurve()}
        {renderNewReferenceCurveButton()}
      </LrvForm>
    );
  };

  return (
    <LrvModal
      theme='light'
      className={styles.referenceCurvesModal}
      title={campusName ? `${t('campus.referenceCurves')} - ${campusName}` : t('campus.referenceCurves')}
      isLoading={isLoading}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{
        disabled: disableSaveButton,
        form: 'formCurveReference',
        id: 'formCurveReference_btnSave',
      }}
      cancelButtonProps={{
        hidden: currentTab === 'GLOBAL_REFERENCE_CURVES',
      }}
      onCancel={() => {
        onCloseModal();
        onClose();
      }}
      okText={currentTab === 'GLOBAL_REFERENCE_CURVES' ? t('references.accept') : t('references.save')}
      onOk={onSaveForm}
      cancelText={t('references.cancel')}
    >
      <div className={styles.contentModal}>
        {currentTab !== 'GLOBAL_REFERENCE_CURVES' && renderCurveReferenceForm()}

        <div className={styles.chart}>
          <ReferenceCurveChart
            company={company}
            phaseType={newPhaseType}
            referenceCurves={cloneDeep(referenceCurves)}
            parameter={newParameter}
            widthChartMax={currentTab === 'GLOBAL_REFERENCE_CURVES' ? 962 : 504}
            currentTab={currentTab}
            referenceName={referenceCurveSelected.name}
          />
        </div>
      </div>
    </LrvModal>
  );
};
