import React from 'react';

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

export default function DnaIcon (props: Props) {
  const { fill = 'currentColor', width = 36, height = 36 } = props;

  return (
    <svg width={width} height={height} viewBox='0 0 512 512' transform='rotate(-45)'>
      <g>
        <path
          fill={fill}
          d="M197.676,421.001L78.031,301.362c5.924-4.073,11.86-7.621,17.784-10.716
		c6.591-3.443,13.189-6.376,19.856-8.801l100.32,100.317c3.35-11.007,5.569-22.71,6.716-35.624l-73.348-73.357
		c6.898-1.099,13.904-1.842,21.036-2.286c15.981-0.986,32.557-0.429,49.75,0.97c-0.424-3.936-0.848-7.743-1.294-11.411
    c-1.082-8.898-2.133-18.265-3.03-27.963c-5.883-0.372-11.763-0.67-17.65-0.84c-30.079-0.873-60.442,1.624-90.546,11.75
		c-15.028,5.076-29.971,12.025-44.622,21.247c-14.628,9.205-29.005,20.713-43.034,34.742L0.004,319.351l27.518,27.526l19.966-19.97
		c2.352-2.352,4.687-4.59,7.031-6.731L179.12,444.778C186.252,437.011,192.394,429.132,197.676,421.001z"
        />
        <path
          fill={fill}
          d="M270.547,197.045c-0.771-27.227,1.556-52.603,9.763-77.009c4.118-12.228,9.706-24.237,17.291-36.287
		c7.58-12.042,17.157-24.099,29.32-36.262l19.97-19.962L319.371,0l-19.977,19.97c-18.709,18.709-32.876,38.024-43.212,57.775
		c-15.545,29.644-22.294,60.12-24.116,90.271c-1.846,30.201,1.019,60.208,4.619,90.304c2.356,19.622,4.259,38.501,4.781,56.636
		c0.771,27.227-1.548,52.603-9.763,77.01c-4.114,12.219-9.71,24.252-17.287,36.286c-7.58,12.034-17.165,24.084-29.324,36.246
		l-19.97,19.978L192.641,512l19.973-19.977c18.718-18.709,32.88-38.024,43.225-57.767c15.536-29.643,22.288-60.111,24.108-90.28
		c1.838-30.192-1.023-60.208-4.619-90.304C272.967,234.05,271.064,215.18,270.547,197.045z"
        />
        <path
          fill={fill}
          d="M484.479,165.114l-19.962,19.962c-2.352,2.351-4.672,4.598-7.023,6.74L332.884,67.215
		c-7.128,7.766-13.245,15.622-18.555,23.767l119.664,119.673c-5.948,4.032-11.864,7.588-17.804,10.684
		c-6.586,3.459-13.18,6.368-19.856,8.808l-100.32-100.324c-3.35,11.007-5.564,22.726-6.716,35.632l73.377,73.373
		c-6.901,1.099-13.924,1.818-21.06,2.271c-15.985,0.978-32.553,0.461-49.778-0.954c0.449,3.919,0.873,7.718,1.322,11.386
		c1.078,8.906,2.134,18.257,3.035,27.962c5.903,0.404,11.778,0.695,17.67,0.873c30.063,0.841,60.41-1.665,90.514-11.783
		c15.064-5.051,29.983-12.009,44.626-21.238c14.628-9.213,29.012-20.721,43.034-34.743l19.962-19.97L484.479,165.114z"
        />
      </g>
    </svg>
  );
}

