import cx from 'classnames';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Dropdown, Form, Menu, Row, Select } from 'antd';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { filterOptionSelect } from '../../utils/select';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { fetchCampus, setSelectedCampus } from '../Units/campusSlice';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import Breadcrumbs from '../../common/components/Breadcrumb/Breadcrumbs';
import { changeHeader, changeBreadcrumb } from '../AppHeader/headerSlice';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { ExtraActionsButton } from '../../common/components/buttons/ExtraActionsButton';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { getKeyValue, stockingPhaseTypes, unitPhaseTypes, unitStatuses, commonOptions, THEME } from '../../config/commons';

import './Modules.scss';
import { Module } from './interfaces';
import styles from './Modules.module.scss';
import { createModule, deleteModule, fetchModule, fetchModules, setModules, setSelectedModule, updateModule } from './modulesSlice';

type TParams = { unitId: string };
const { Option } = Select;

const Modules = ({ match }: RouteComponentProps<TParams>) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const unitId = match.params.unitId;

  const [form] = Form.useForm();

  const [showCreateModuleModal, setShowCreateModuleModal] = useState(false);
  const [showUpdateModuleModal, setShowUpdateModuleModal] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');
  const [newModuleCode, setNewModuleCode] = useState('');
  const [newModulePhase, setNewModulePhase] = useState(stockingPhaseTypes.JUVENILE);

  const { breadcrumb } = useSelector((state: Store) => state.header);
  const { selectedCampus } = useSelector((state: Store) => state.campus);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);
  const { selectedModule, modules, isModuleLoading, isModulesLoading } = useSelector((state: Store) => state.modules);

  const isCampusInactive = selectedCampus.status === unitStatuses.INACTIVE;
  const unitNameRoute = t('campus.title');

  const MAX_CODES_LENGTH = 4;

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (selectedCampus.name) {
      const breadcrumb = [
        { id: 'units', name: unitNameRoute, url: '/units' },
        { id: 'modules', name: selectedCampus.name, url: `/units/${unitId}` },
      ];
      dispatch(changeBreadcrumb(breadcrumb));
    }
  }, [dispatch, selectedCampus.name, unitNameRoute]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'campus.title' }));
    dispatch(fetchCampus(unitId));
    dispatch(fetchModules(unitId));
  }, [dispatch, unitId]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedCampus({ _id: '', name: '', code: '', timezone: '', companyId: '', phaseType: '', country: '', province: '', status: '' }));
      dispatch(setModules([]));
    };
  }, [dispatch]);

  useEffect(() => {
    setNewModuleName(selectedModule.name);
    setNewModuleCode(selectedModule.code);
  }, [selectedModule._id, selectedModule.code, selectedModule.name]);

  const columns: ColumnsType<Module> = [
    {
      key: 1,
      title: t('campus.code'),
      dataIndex: 'code',
      ellipsis: { showTitle: false },
      width: '20%',
      responsive: isDesktop ? undefined : ['md'] as Breakpoint[],
    },
    {
      key: 2,
      title: t('campus.name'),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
      width: isDesktop ? '20%' : '25%',
    },
    {
      key: 3,
      title: t('campus.phase'),
      dataIndex: 'phaseType',
      ellipsis: { showTitle: false },
      width: '15%',
      render: (value: string) => t(`stockings.phaseTypes.${value}`),
    },
    {
      key: 5,
      className: styles.optionsCell,
      width: '10%',
      render: (_, record: Module) => (
        renderDropdownModuleOptions(record)
      ),
    }
  ];

  const renderDropdownModuleOptions = (record: Module) => {
    return (
      <Dropdown
        overlay={() => moduleMenuItems(record)}
        trigger={['click']}
        placement='bottomRight'
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${record._id}`}
        />
      </Dropdown>
    );
  };

  const moduleMenuItems = (record: Module) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          if (key === commonOptions.EDIT) {
            dispatch(fetchModule(record._id));
            openEditModuleModal();
            return;
          }

          if (key === commonOptions.REMOVE) {
            showDeleteModuleConfirm(record._id, record.name);
            return;
          }
        }}
      >
        <Menu.Item id='unit_menu_option_config' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.edit')}</span>
        </Menu.Item>

        <Menu.Item id='unit_menu_option_remove' key={commonOptions.REMOVE} className={styles.menuItemOptions}>
          <Icon name='delete-bin' type='line' theme={theme} className={styles.icon} />
          <span>{t('campus.delete')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  function closeModuleModal () {
    setShowCreateModuleModal(false);
    setNewModuleName('');
    setNewModuleCode('');
    form.resetFields();
  }

  function openEditModuleModal () {
    setNewModuleName(selectedModule.name);
    setNewModuleCode(selectedModule.code);
    setShowUpdateModuleModal(true);
  }

  function showDeleteModuleConfirm (moduleId: string, moduleName: string) {
    let content: JSX.Element | string = moduleName;

    if (isCampusInactive) {
      content = (
        <div className={styles.confirmModal}>
          <div className={styles.description}>
            {content}
          </div>
          <br />
          <div >
            <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
          </div>
        </div>
      );
    }

    lrvConfirm({
      theme: 'light',
      title: t('campus.confirmDelete'),
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: t('campus.delete'),
      okButtonProps: { id: 'btnOkDeleteModule', disabled: isCampusInactive },
      okType: 'primary',
      cancelText: t('campus.cancel'),
      onOk () {
        dispatch(deleteModule(moduleId));

        if (moduleId === selectedModule._id) {
          dispatch(setSelectedModule({ _id: '', name: '', code: '', campusId: '', phaseType: '' }));
        }
      },
    });
  }

  return (
    <div className={styles.modules}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />
      <Row align='middle' justify='space-between' className={styles.rowHeader}>
        <div className={styles.title}>
          <LrvText text={t('campus.modulesIn', { campus: selectedCampus.name })} theme={theme} />
        </div>
        <ActionButton
          id='btn_new_module'
          type='primary'
          icon={<Icon name='add' />}
          className={styles.btnAddModule}
          onClick={() => {
            setNewModuleName('');
            setNewModuleCode('');
            setShowCreateModuleModal(true);
            dispatch(goToOnboardingNextStep(500));
          }}
        >
          {t('campus.module')}
        </ActionButton>
      </Row>
      <Row className={styles.rowFlex}>
        <LrvTable
          id='tableModules'
          rowClassName={(record) => cx(styles.moduleRow, selectedModule._id === record._id ? isLightTheme ? styles.selectedRowLight : styles.selectedRowDark : '')}
          size='small'
          loading={isModulesLoading}
          columns={columns}
          dataSource={modules}
          pagination={false}
          scroll={{ y: '' }}
          theme={theme}
          onRow={(record: Module) => {
            return {
              onClick: (e) => {
                e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
                if (selectedCampus._id === record._id) {
                  return;
                }
                history.push('/units/' + selectedCampus._id + '/modules/' + record._id);

                if (isRunningOnboarding) {
                  dispatch(goToOnboardingNextStep(1200));
                }
              },
            };
          }}
        />

        <LrvModal
          theme='light'
          className='createModuleModal'
          title={t('campus.newModule')}
          open={showCreateModuleModal}
          destroyOnClose={true}
          okButtonProps={{ htmlType: 'submit', form: 'formNewModule', id: 'formNewModule_btnSave', disabled: isCampusInactive }}
          onOk={() => {
            form.submit();
          }}
          okText={t('campus.create')}
          cancelText={t('campus.cancel')}
          onCancel={() => {
            closeModuleModal();
          }}
        >
          <LrvForm
            theme='light'
            form={form}
            layout='vertical'
            name='formNewModule'
            id='formNewModule'
            onFinish={() => {
              const campusPhaseTypeSelected = selectedCampus.phaseType;
              const phaseType = campusPhaseTypeSelected === unitPhaseTypes.LARVAE ? campusPhaseTypeSelected : newModulePhase;
              const moduleData = { name: newModuleName, code: newModuleCode, campusId: selectedCampus._id, phaseType };

              dispatch(createModule(moduleData));
              closeModuleModal();
              if (isRunningOnboarding) {
                dispatch(goToOnboardingNextStep(500));
              }
            }}
          >
            <Form.Item
              label={t('campus.name')}
              required
              name='newModuleName'
              rules={[{ required: true, message: t('campus.nameRequired') }]}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
                disabled={isCampusInactive}
              />
            </Form.Item>

            <Form.Item
              label={t('campus.code')}
              required
              name='newModuleCode'
              rules={[{ required: true, message: t('campus.codeRequired') }]}
            >
              <LrvInput
                theme='light'
                value={newModuleCode}
                className={styles.inputUpperCase}
                maxLength={MAX_CODES_LENGTH}
                onChange={(e) => setNewModuleCode(e.target.value)}
                disabled={isCampusInactive}
              />
            </Form.Item>

            {
              selectedCampus.phaseType === unitPhaseTypes.PRODUCTION &&
              <Form.Item
                required
                name='newModulePhase'
                label={t('campus.phase')}
                rules={[{ required: true }]}
                valuePropName='defaultValue'
                initialValue={stockingPhaseTypes.JUVENILE}
              >
                <LrvSelect
                  theme='light'
                  showSearch
                  className={styles.select}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  placeholder={t('campus.phase')}
                  optionFilterProp='children'
                  value={newModulePhase}
                  onChange={value => setNewModulePhase(value)}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {
                    Object.keys(stockingPhaseTypes).map((key, index) => {
                      const typedKey = key as 'LARVAE' | 'JUVENILE' | 'ADULT';

                      if (typedKey === stockingPhaseTypes.LARVAE) {
                        return null;
                      }

                      return (
                        <Option key={index} value={getKeyValue(stockingPhaseTypes, typedKey)}>{t(`stockings.phaseTypes.${key}`)}</Option>
                      );
                    })
                  }
                </LrvSelect >
              </Form.Item>
            }
          </LrvForm>
        </LrvModal>

        <LrvModal
          theme='light'
          className='editModuleModal'
          title={t('campus.editModule')}
          open={showUpdateModuleModal}
          isLoading={isModuleLoading}
          destroyOnClose={true}
          okButtonProps={{ disabled: isModuleLoading || isCampusInactive, form: 'formEditModule', id: 'formEditModule_btnSave' }}
          onOk={() => {
            form.setFieldsValue({
              moduleName: newModuleName,
              moduleCode: newModuleCode
            });

            form.validateFields()
              .then(() => {
                form.submit();
              });
          }}
          okText={t('campus.save')}
          cancelText={t('campus.cancel')}
          onCancel={() => {
            setShowUpdateModuleModal(false);
            dispatch(setSelectedModule({ _id: '', name: '', code: '', campusId: '', phaseType: '' }));
            setNewModuleName('');
            setNewModuleCode('');
          }}
        >
          <LrvForm
            theme='light'
            form={form}
            layout='vertical'
            name='formEditModule'
            id='formEditModule'
            onFinish={() => {
              const moduleData = {
                _id: selectedModule._id, name: newModuleName, code: newModuleCode, active: true, campusId: selectedCampus._id
              };
              dispatch(updateModule(moduleData));
              setShowUpdateModuleModal(false);
              setNewModuleName('');
              setNewModuleCode('');
              dispatch(setSelectedModule({ _id: '', name: '', code: '', campusId: '', phaseType: '' }));
              form.resetFields();
            }}
          >
            <Form.Item
              label={t('campus.name')}
              name='moduleName'
              rules={[{ required: true, message: t('campus.nameRequired') }]}
              valuePropName='defaultValue'
              initialValue={newModuleName}
            >
              <LrvInput
                theme='light'
                autoFocus
                value={newModuleName}
                onChange={(e) => setNewModuleName(e.target.value)}
                disabled={isCampusInactive}
              />
            </Form.Item>

            <Form.Item
              label={t('campus.code')}
              name='moduleCode'
              rules={[{ required: true, message: t('campus.codeRequired') }]}
              valuePropName='defaultValue'
              initialValue={newModuleCode}
            >
              <LrvInput
                theme='light'
                value={newModuleCode}
                className={styles.inputUpperCase}
                maxLength={MAX_CODES_LENGTH}
                onChange={(e) => setNewModuleCode(e.target.value)}
                disabled={isCampusInactive}
              />
            </Form.Item>
          </LrvForm>
        </LrvModal>
      </Row>
    </div>
  );
};

export default Modules;
