import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvText } from '../../../common/components/LrvText/LrvText';

import styles from './Legends.module.scss';
import { ParameterTypes, StockingParameterGraph } from './interfaces';
import { existsMinimumDataParameters, getDataParameter } from './helpers';

interface Props {
  stockingParameterGraphs: StockingParameterGraph[];
  selectedParameterName: ParameterTypes;
}

export const Legends = (props: Props) => {
  const { selectedParameterName, stockingParameterGraphs } = props;
  const [t] = useTranslation();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  if (!stockingParameterGraphs.length) {
    return null;
  }

  const existsMinimumData = existsMinimumDataParameters({ stockingParameterGraphs, selectedParameterName });

  if (!existsMinimumData) {
    return null;
  }

  const data = getDataParameter({ item: stockingParameterGraphs[0], selectedParameterName });
  const parameter = t(`parameterStateGraph.parameters.${selectedParameterName}`);

  return (
    <div className={styles.legend}>
      <div className={styles.item}>
        <div className={cx(styles.line, styles.parameter)} />
        <span>
          <LrvText
            className={isLightTheme ? styles.lightText : styles.darkText}
            text={t('parameterStateGraph.currentParameter', { parameter, unit: data.unit })}
          />
        </span>
      </div>

      {
        selectedParameterName === 'averageWeight' &&
        <div className={styles.item}>
          <div className={cx(styles.line, styles.referenceLine)} />
          <span>
            <LrvText
              className={isLightTheme ? styles.lightText : styles.darkText}
              text={t('parameterStateGraph.currentReference', { parameter, unit: data.unit })}
            />
          </span>
        </div>
      }
    </div>
  );
};
