import cx from 'classnames';
import { capitalize } from 'lodash';
import { Row, Space, Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { generateColorHex } from '../../../utils/colors';
import { filterOptionSelect } from '../../../utils/select';
import { changeHeader } from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { getLabelAxisX } from '../../../helpers/stocking.helpers';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { typeParam } from '../../../common/components/charts/ShadedPlot/helpers';
import { formatLongDateWithOffset, formatLongDateWithZone } from '../../../utils/date';
import ActiveTankChartD3 from '../../../common/components/charts/ActiveTankChart/ActiveTankChartD3';
import { roundWeight, stockingPhaseTypes, unitStatuses, roundLength, THEME } from '../../../config/commons';

import './ActiveTanks.scss';
import { Tank } from './interfaces';
import { LegendNormal } from './LegendNormal';
import styles from './ActiveTanks.module.scss';
import * as activeTanksSlice from './ActiveTanksSlice';
import { getFactorK, getWidthOfTheOtherElements } from './helpers';

const { Option } = Select;
let chart: ActiveTankChartD3 | null;

export default function ActiveTanks () {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const {
    activeTanks,
    isLoading,
    campuses,
    campus,
    modules,
    module,
    parameter,
    referenceCurve,
    globalReferenceCurves,
    companyReferenceCurves,
    unitReferenceCurves,
    firstStage,
    lastStage,
  } = useSelector((state: Store) => state.activeTanks);
  const { company, phaseType } = useSelector((state: Store) => state.header);

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements());
  const [colors, setColors] = useState<string[]>([]);

  const refChartMain = useRef<HTMLDivElement>(null);
  const [showTable, setShowTable] = useState(false);

  const resetChart = useCallback(() => {
    if (!company._id) {
      return;
    }

    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(activeTanksSlice.setCampus(undefined));
    dispatch(activeTanksSlice.setModule(undefined));
    dispatch(activeTanksSlice.setParameterSelected(undefined));
    dispatch(activeTanksSlice.setActiveTanks([]));
    dispatch(activeTanksSlice.setReferenceCurve(undefined));
    dispatch(activeTanksSlice.fetchCampuses(company._id, unitPhaseType));
    dispatch(activeTanksSlice.setReferenceCurve(undefined));
  }, [dispatch, company._id, phaseType]);

  useEffect(() => {
    if (!chart || !parameter) {
      setShowTable(false);
      return;
    }

    const timer = setTimeout(() => {
      setShowTable(true);
    }, 10);

    return () => {
      clearTimeout(timer);
    };
  }, [chart, parameter]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'activeTanks.header' }));

    return () => {
      chart = null;
      resetChart();
    };
  }, [dispatch, resetChart]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
    });
  }, []);

  useEffect(() => {
    if (!activeTanks.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
  }, [activeTanks]);

  useEffect(() => {
    if (!activeTanks.length) {
      return;
    }

    const days = activeTanks.map((tank) => tank.analysis.stage);

    const firstStage = Math.min(...days) - 1;
    const lastStage = Math.max(...days) + 1;

    dispatch(activeTanksSlice.setFirstStage(firstStage));
    dispatch(activeTanksSlice.setLastStage(lastStage));
  }, [activeTanks]);

  useEffect(() => {
    resetChart();
  }, [resetChart]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    switch (phaseType) {
      case stockingPhaseTypes.LARVAE:
        dispatch(activeTanksSlice.setMaxStage(company.maxStage));
        break;

      case stockingPhaseTypes.JUVENILE:
        dispatch(activeTanksSlice.setMaxStage(company.maxDayJuvenile));
        break;

      case stockingPhaseTypes.ADULT:
        dispatch(activeTanksSlice.setMaxStage(company.maxDayGrowOut));
        break;
    }
  }, [company, phaseType]);

  useEffect(() => {
    if (!activeTanks.length || !parameter) {
      return;
    }

    const colors: string[] = [];
    for (let index = 0; index < activeTanks.length; index++) {
      const color = generateColorHex();
      colors.push(color);
    }

    setColors(colors);
  }, [activeTanks.length, parameter]);

  useEffect(() => {
    if (!activeTanks.length || !colors.length || !parameter) {
      return;
    }

    const renderD3Chart = () => {
      if (chart) {
        chart.refreshChart({ parameter, width, firstStage, lastStage, activeTanks, referenceCurve, colors });
        return;
      }

      const props = {
        height: 560,
        parameter,
        phaseType,
        width,
        activeTanks,
        container: refChartMain.current,
        firstStage,
        lastStage,
        referenceCurve,
        colors,
      };
      chart = new ActiveTankChartD3(props);
    };

    renderD3Chart();
  }, [parameter, phaseType, activeTanks, width, firstStage, lastStage, referenceCurve, colors, theme]);

  const getColumnsType = () => {
    const columnsType: ColumnsType<Tank> = [
      {
        key: 1,
        width: '8%',
        title: t('stockings.container'),
        dataIndex: 'name',
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.name.localeCompare(b.name),
        className: styles.cell,
      },
      {
        key: 2,
        width: '12%',
        title: t('activeTanks.stocking'),
        ellipsis: { showTitle: false },
        dataIndex: ['analysis', 'stockingName'],
      },
      {
        key: 3,
        width: '5%',
        title: t('activeTanks.stage'),
        dataIndex: ['analysis', 'stage'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.analysis.stage - b.analysis.stage,
        className: styles.cell,
      },
      {
        key: 4,
        width: '10%',
        title: t('activeTanks.date'),
        dataIndex: ['analysis', 'createdAt'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        render: (_, record: Tank) => formatLongDateWithOffset(record.analysis.createdAt),
        sorter: (a, b) => a.analysis.createdAt.localeCompare(b.analysis.createdAt),
        className: styles.cell,
      },
      {
        key: 5,
        width: '10%',
        title: t('activeTanks.stockingStartDate'),
        dataIndex: ['analysis', 'stockingStartDate'],
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        render: (_, record: Tank) => formatLongDateWithZone(record.analysis.stockingStartDate),
        sorter: (a, b) => a.analysis.stockingStartDate.localeCompare(b.analysis.stockingStartDate),
        className: styles.cell,
      },
      {
        key: 6,
        width: '9%',
        title: `${t('detail.weight')} ${t('analysis.resultData.average')}`,
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        render: (_, record: Tank) => roundWeight({ value: record.analysis.averageWeight }),
        sorter: (a, b) => a.analysis.averageWeight - b.analysis.averageWeight,
        className: styles.cell,
      },
      {
        key: 7,
        width: '10%',
        title: `${t('detail.length')} ${t('analysis.resultData.average')}`,
        ellipsis: { showTitle: false },
        responsive: ['lg'] as Breakpoint[],
        render: (_, record: Tank) => roundLength({ value: record.analysis.averageLength }),
        sorter: (a, b) => a.analysis.averageLength - b.analysis.averageLength,
        className: styles.cell,
      },
      {
        key: 8,
        width: '9%',
        title: t('analysis.resultData.unif') + ' ' + t('detail.weight') + ' %',
        dataIndex: ['analysis', 'uniformityWeight'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.analysis.uniformityWeight - b.analysis.uniformityWeight,
        className: styles.cell,
      },
      {
        key: 9,
        width: '10%',
        title: t('analysis.resultData.unif') + ' ' + t('detail.length') + ' %',
        dataIndex: ['analysis', 'uniformityLength'],
        ellipsis: { showTitle: false },
        sorter: (a, b) => a.analysis.uniformityLength - b.analysis.uniformityLength,
        className: styles.cell,
      },
    ];

    if (phaseType === stockingPhaseTypes.LARVAE) {
      columnsType.push(
        {
          key: 10,
          width: '5%',
          title: t('activeTanks.resultData.larvaePerGram'),
          dataIndex: ['analysis', 'larvaePerGram'],
          ellipsis: { showTitle: false },
          responsive: ['lg'] as Breakpoint[],
          sorter: (a, b) => a.analysis.larvaePerGram - b.analysis.larvaePerGram,
          className: styles.cell,
        }
      );
    }

    if (phaseType === stockingPhaseTypes.ADULT) {
      columnsType.push(
        {
          key: 11,
          width: '10%',
          title: t('activeTanks.factorK') + '%',
          ellipsis: { showTitle: false },
          render: (_, record: Tank) => getFactorK(record),
          sorter: (a, b) => getFactorK(a) - getFactorK(b),
          className: styles.cell,
        }
      );
    }

    return columnsType;
  };

  const renderEmptyComponent = (description: string) => {
    return (
      <div className={styles.height}>
        <LrvEmpty className={styles.empty} description={description} theme={theme} />
      </div>
    );
  };

  const renderChart = () => {
    return (
      <div id='activeTanks' className={styles.activeTanks}>
        <LegendNormal />

        <>
          <div id='chart' ref={refChartMain} className={styles.chart} />
          {
            showTable && <LrvText className={styles.labelAxisX} theme={theme} text={getLabelAxisX(phaseType)} />
          }
        </>

        {
          showTable &&
          <LrvTable
            id='active-tanks-table'
            className={cx(styles.table, isLightTheme ? styles.tableLight : styles.tableDark)}
            columns={getColumnsType()}
            loading={isLoading || !chart}
            dataSource={activeTanks}
            size='small'
            theme={theme}
            pagination={false}
            onRow={(record: Tank) => {
              return {
                onClick: (e) => {
                  e.stopPropagation();
                  const url = `/production/analysis/${record.analysis.analysisId}`;
                  window.open(url, '_blank');
                }
              };
            }}
          />
        }

      </div>
    );
  };

  const renderBody = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (campuses.length === 0) {
      return renderEmptyComponent(t('activeTanks.emptyCampuses'));
    }

    if (!campus) {
      return renderEmptyComponent(t('activeTanks.selectCampus'));
    }

    if (modules.length === 0) {
      return renderEmptyComponent(t('activeTanks.emptyModules'));
    }

    if (!module) {
      return renderEmptyComponent(t('activeTanks.selectModule'));
    }

    if (!parameter) {
      return renderEmptyComponent(t('activeTanks.selectParameter'));
    }

    if (activeTanks.length === 0) {
      return renderEmptyComponent(t('activeTanks.empty'));
    }

    return renderChart();
  };

  const onChangeCampus = (value: string) => {
    const campus = campuses.find((campus) => campus._id === value);

    if (!campus) {
      return;
    }

    chart = null;
    dispatch(activeTanksSlice.setCampus(campus));
    dispatch(activeTanksSlice.setActiveTanks([]));
    dispatch(activeTanksSlice.setModule(undefined));
    dispatch(activeTanksSlice.setParameterSelected(undefined));
    dispatch(activeTanksSlice.setReferenceCurve(undefined));
    dispatch(activeTanksSlice.fetchModules(value, phaseType));
  };

  const onChangeModule = (value: string) => {
    const module = modules.find((module) => module._id === value);

    if (!module) {
      return;
    }

    chart = null;
    dispatch(activeTanksSlice.setModule(module));
    dispatch(activeTanksSlice.setParameterSelected(undefined));
    dispatch(activeTanksSlice.setReferenceCurve(undefined));
    dispatch(activeTanksSlice.fetchActiveTanks({ companyId: company._id, phaseType, campusId: module.campusId, moduleId: module._id }));
  };

  const onChangeParameter = (value: string) => {
    if (!campus?._id) {
      return;
    }

    dispatch(activeTanksSlice.setParameterSelected(value));
    dispatch(activeTanksSlice.setReferenceCurve(undefined));
    dispatch(activeTanksSlice.fetchGlobalReferenceCurves({ type: value, phaseType }));
    dispatch(activeTanksSlice.fetchCompanyReferenceCurves({ companyId: company._id, type: value, phaseType }));
    dispatch(activeTanksSlice.fetchUnitsReferenceCurves({ companyId: company._id, phaseType, type: value, campusId: campus?._id }));
  };

  const onChangeReference = (value: string) => {
    const referenceCurves = [...globalReferenceCurves, ...unitReferenceCurves, ...companyReferenceCurves];
    const refCurve = referenceCurves.find(item => item._id === value);

    if (!refCurve) {
      return;
    }

    dispatch(activeTanksSlice.setReferenceCurve(refCurve));
  };

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campuses'
        showSearch
        theme={theme}
        className={className}
        value={campus?.name ? campus?.name : undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        onSelect={onChangeCampus}
        dropdownMatchSelectWidth={false}
      >
        {campuses.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModuleDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_module'
        showSearch
        theme={theme}
        className={className}
        value={module?.name ? module?.name : undefined}
        disabled={!campus?._id}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        onSelect={onChangeModule}
        dropdownMatchSelectWidth={false}
      >
        {modules.map((module) =>
          <Option key={module._id} value={module._id} label={module.name}> {module.name}</Option>
        )}
      </LrvSelect>
    );
  };

  const renderParameterDropdown = (props: DropdownProps) => {
    const { className, theme } = props;
    return (
      <LrvSelect
        id='phase_type_dropdown'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={parameter}
        disabled={!module?._id}
        showSearch
        onChange={onChangeParameter}
        title={t('production.filters.parameter')}
        placeholder={t('production.filters.parameter')}
      >
        {phaseType === stockingPhaseTypes.LARVAE &&
          <Option value={typeParam.PLG}>{t('analysis.resultData.larvaePerGram')}</Option>
        }

        <Option value={typeParam.AVG_WEIGHT}>{t('analysis.resultData.averageWeight')}</Option>
        <Option value={typeParam.AVG_LENGTH}>{t('analysis.resultData.averageLength')}</Option>
        <Option value={typeParam.CV_LENGTH}>{t('analysis.resultData.variationCoefficientLength')}</Option>
      </LrvSelect>
    );
  };

  const renderGlobalRefOptions = () => {
    return globalReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
          label={item.name}
        >
          {capitalize(item.name)}&nbsp;
          <Icon name='global' />
        </Select.Option>
      );
    });
  };

  const renderCompanyRefOptions = () => {
    return companyReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderUnitRefOptions = () => {
    return unitReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderReferencesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;
    return (
      <LrvSelect
        id='reference_dropdown'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={referenceCurve?._id}
        disabled={!parameter}
        showSearch
        onChange={onChangeReference}
        filterOption={filterOptionSelect}
        title={t('production.filters.reference')}
        placeholder={t('production.filters.reference')}
      >
        {renderGlobalRefOptions()}
        {renderCompanyRefOptions()}
        {renderUnitRefOptions()}
      </LrvSelect>
    );
  };

  const renderSubHeader = () => {
    return (
      <Row>
        <Space align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderModuleDropdown({ className: styles.select, theme })}
          {renderParameterDropdown({ className: styles.select, theme })}
          {renderReferencesDropdown({ className: styles.select, theme })}
        </Space>
      </Row>
    );
  };

  return (
    <Space className={styles.content} direction='vertical' size='middle'>
      {renderSubHeader()}
      {renderBody()}
    </Space>
  );
}
