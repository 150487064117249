import moment from 'moment';
import i18next from 'i18next';

import { DATE_FORMATS, stockingPhaseTypes } from '../../../config/commons';

export const SURVIVAL_AVG = 'SURVIVAL_AVG';

export const chartType = {
  WEEKLY: 'WEEKLY',
  HISTOGRAM: 'HISTOGRAM',
};

export const getFromDate = () => {
  return moment().startOf('year').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDate = () => {
  return moment().format(DATE_FORMATS.YYYY_MM_DD);
};

export const disabledFromDateChart = (current: moment.Moment, toDate: string) => {
  const years = 1;
  const start = moment().subtract(years, 'year');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledToDateChart = (current: moment.Moment, fromDate: string) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledFromDateHistogram = (current: moment.Moment, toDate: string) => {
  const years = 1;
  const start = moment().subtract(years, 'year');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledToDateHistogram = (current: moment.Moment, fromDate: string) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const getHeightChart = () => {
  const subtractHeight = 284;
  return window.innerHeight - subtractHeight;
};

export const getWidthChart = () => {
  if (window.innerWidth <= 950) {
    return window.innerWidth - 76;
  }

  if (window.innerWidth <= 1420) {
    return window.innerWidth - 170;
  }

  return window.innerWidth - 320;
};

export const getHeightHistogram = () => {
  const subtractHeight = 220;
  return window.innerHeight - subtractHeight;
};

export const initialFiltersState = {
  campus: {
    _id: '',
    name: '',
    code: '',
    status: '',
    phaseType: '',
  },
  moduleId: undefined,
  tankId: undefined,
  fromDate: getFromDate(),
  toDate: getToDate(),
};

export const getTitle = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('survivalRate.larvaeSubtitle');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('survivalRate.juvenileSubtitle');

    case stockingPhaseTypes.ADULT:
      return i18next.t('survivalRate.growOutSubtitle');
  }
};

export const areAllNullsOrZeros = (array: (null | number)[]): boolean => {
  return array.every(element => element === null || element === 0);
};
