import * as d3 from 'd3';
import cx from 'classnames';

import { roundOneDecimal } from '../../../config/commons';
import { Dataset } from '../../Analysis/Detail/interfaces';
import { applyThousandsSeparator } from '../../../utils/strings';

import styles from './HistogramD3.module.scss';

interface Props {
  container: HTMLDivElement | null;
  primaryDataset: Dataset[];
  histogram: string;
  primaryLimits: number[];
  width: number;
  height: number;
  theme: 'dark' | 'light';
  renderPdf?: boolean;
}

const positionYLimits = 15;
let positionYFrequencies = positionYLimits;
const xAxisPadding = 0.02;
const primaryFillBars = '#1b62e6';
const secondayFillBars = '#45f441';

const opacityBars = 0.6;

export default class HistogramD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  gRect: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  x: d3.ScaleBand<string> = d3.scaleBand();
  y: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  xScaleLinear: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  yAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  bars: d3.Selection<SVGRectElement, Dataset, null, undefined> = d3.select<SVGRectElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  textLimits: d3.Selection<SVGTextElement, number, null, unknown> = d3.select<SVGTextElement, number>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;
  textFrequencies: d3.Selection<SVGTextElement, Dataset, null, unknown> = d3.select<SVGTextElement, Dataset>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));;

  timeTransition = 300;

  width = 0;
  height = 0;
  margin = { top: 12, right: 20, bottom: 16, left: 6 };

  primaryDataset: Dataset[];
  secondaryDataset?: Dataset[] = [];
  histogram: string;
  primaryLimits: number[];
  secondaryLimits: number[] = [];
  renderPdf = false;

  //styles
  theme?: 'dark' | 'light' = 'dark';

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, primaryDataset, height, histogram, primaryLimits, theme, width, renderPdf = false } = props;
    this.container = container;
    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.primaryDataset = primaryDataset;
    this.histogram = histogram;
    this.primaryLimits = primaryLimits;

    this.theme = theme;
    this.renderPdf = renderPdf;
    positionYFrequencies = renderPdf ? positionYFrequencies + 6 : positionYFrequencies;

    this.renderTriangle();
    this.createSvgElement(this.width, this.height);
    this.createHistogram();
  }

  createSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('position', 'relative')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);
  }

  getFrecuencyPositionX (i: number) {
    return (i * this.x.bandwidth()) + (this.x.bandwidth() / 2) + (i * this.x.bandwidth() * xAxisPadding);
  }

  getFrecuencyLabel = (d: Dataset, i: number) => {
    if (d.percent === undefined) {
      return applyThousandsSeparator(d.frequency);
    }

    return (
      `
      <tspan x='${this.getFrecuencyPositionX(i) + 2.2}' dy='0em'> ${d.percent > 0 ? `${roundOneDecimal(d.percent)}%` : ''}</tspan>
      <tspan x='${this.getFrecuencyPositionX(i)}' dy='1.12em'>${d.frequency > 0 ? applyThousandsSeparator(d.frequency) : ''}</tspan>
      `
    );
  }

  renderBars = (props: { dataset: Dataset[]; limits: number[]; histogramName: 'primary' | 'secondary' }) => {
    const { dataset, histogramName, limits } = props;
    const { gRect, height } = this;

    // eslint-disable-next-line
    // @ts-ignore
    this.bars = gRect.selectAll(`.histogram-${histogramName}`)
      .data(dataset)
      .enter()
      .append('rect')
      .attr('class', `histogram histogram-${histogramName}`)
      .attr('x', (d: Dataset, index) => this.xScaleLinear(limits[index]))
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', primaryFillBars)
      .attr('opacity', opacityBars);
  };

  createHistogram () {
    const { primaryDataset, secondaryDataset = [], primaryLimits, groupMain, height, renderPdf } = this;

    this.buildAxisX();
    this.buildAxisY();
    this.drawAxisY();
    this.drawLineAxisX();

    this.gRect = groupMain.append('g')
      .attr('id', 'rect');

    this.renderBars({ dataset: primaryDataset, histogramName: 'primary', limits: primaryLimits });
    this.renderBars({ dataset: secondaryDataset, histogramName: 'secondary', limits: primaryLimits });

    // eslint-disable-next-line
    // @ts-ignore
    this.textLimits = groupMain.selectAll('.limits')
      .data(primaryLimits)
      .enter()
      .append('text')
      .attr('class', cx('limits', styles.limitsDefaultLight, renderPdf ? styles.limitsDefaultLightPDF : ''))
      .attr('x', (_, i: number) => (i * this.x.bandwidth()) + (i * this.x.bandwidth() * xAxisPadding))
      .attr('y', height + positionYLimits)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = groupMain.selectAll('.frequencies')
      .data(primaryDataset)
      .enter()
      .append('text')
      .attr('class', cx('frequencies', styles.frequenciesDefaultLight, renderPdf ? styles.frequenciesDefaultLightPDF : ''))
      .attr('x', (d: Dataset, i: number) => this.getFrecuencyPositionX(i))
      .attr('y', (d: Dataset) => this.y(d.frequency) - positionYFrequencies)
      .style('text-anchor', 'middle')
      .html((d: Dataset, i: number) => this.getFrecuencyLabel(d, i));
  }

  updateBars = (props: { dataset: Dataset[]; limits: number[]; fillBars: string; histogramName: 'primary' | 'secondary'; }) => {
    const { dataset, fillBars, histogramName, limits } = props;
    const { gRect, height, timeTransition } = this;

    // eslint-disable-next-line
    // @ts-ignore
    this.bars = gRect.selectAll(`.histogram-${histogramName}`)
      .data(dataset);

    this.bars.enter()
      .append('rect')
      .merge(this.bars)
      .transition()
      .duration(timeTransition)
      .attr('class', `histogram histogram-${histogramName}`)
      .attr('x', (d: Dataset, index) => this.xScaleLinear(limits[index]))
      .attr('width', this.x.bandwidth())
      .attr('y', (d: Dataset) => this.y(d.frequency))
      .attr('height', (d: Dataset) => height - this.y(d.frequency))
      .style('fill', fillBars)
      .attr('opacity', opacityBars);

    this.bars.exit().remove();

    if (histogramName === 'secondary') {
      gRect.selectAll('.histogram-secondary').raise();
    }
  };

  refreshHistogram (props: { primaryDataset: Dataset[]; secondaryDataset?: Dataset[]; histogram: string; primaryLimits: number[]; secondaryLimits: number[]; theme: 'dark' | 'light'; }) {
    const { primaryDataset, secondaryDataset = [], histogram, primaryLimits, secondaryLimits, theme } = props;
    const { groupMain, height, timeTransition, renderPdf } = this;

    this.primaryDataset = primaryDataset;
    this.secondaryDataset = secondaryDataset;
    this.histogram = histogram;
    this.primaryLimits = primaryLimits;
    this.secondaryLimits = secondaryLimits;
    this.theme = theme;

    this.buildAxisX();
    this.buildAxisY();
    this.drawAxisY();
    this.drawLineAxisX();

    this.updateBars({ dataset: primaryDataset, fillBars: primaryFillBars, histogramName: 'primary', limits: primaryLimits });
    this.updateBars({ dataset: secondaryDataset, fillBars: secondayFillBars, histogramName: 'secondary', limits: secondaryLimits });

    //update limits
    // eslint-disable-next-line
    // @ts-ignore
    this.textLimits = groupMain.selectAll('.limits')
      .data(primaryLimits);

    this.textLimits.enter()
      .append('text')
      .merge(this.textLimits)
      .transition()
      .duration(timeTransition)
      .attr('class', cx('limits', styles.limitsDefaultLight, renderPdf ? styles.limitsDefaultLightPDF : ''))
      .attr('x', (d: number, i: number) => (i * this.x.bandwidth()) + (i * this.x.bandwidth() * xAxisPadding))
      .attr('y', height + positionYLimits)
      .style('text-anchor', 'middle')
      .text((d: number) => d);

    this.textLimits.exit()
      .remove();

    //update frequency
    // eslint-disable-next-line
    // @ts-ignore
    this.textFrequencies = groupMain.selectAll('.frequencies')
      .data(primaryDataset);

    this.textFrequencies.enter()
      .append('text')
      .merge(this.textFrequencies)
      .attr('class', cx('frequencies', styles.frequenciesDefaultLight, renderPdf ? styles.frequenciesDefaultLightPDF : ''))
      .attr('x', (d: Dataset, i: number) => this.getFrecuencyPositionX(i))
      .attr('y', (d: Dataset) => this.y(d.frequency) - positionYFrequencies)
      .style('text-anchor', 'middle')
      .html((d: Dataset, i: number) => this.getFrecuencyLabel(d, i));

    this.textFrequencies.exit()
      .remove();
  }

  buildAxisX () {
    const { primaryDataset, primaryLimits, width } = this;
    const domain = primaryDataset.map((data, index) => index.toString());

    this.x = d3.scaleBand()
      .domain(domain)
      .range([0, width])
      .padding(xAxisPadding);

    this.xScaleLinear = d3.scaleLinear()
      .domain([primaryLimits[0], primaryLimits[primaryLimits.length - 1]])
      .range([0, width]);
  }

  buildAxisY () {
    const { primaryDataset, secondaryDataset = [], height } = this;
    const maxDomain: number = d3.max([...primaryDataset, ...secondaryDataset], (d) => d.frequency) || 0;
    const yMax = maxDomain + (maxDomain * 0.1);

    this.y = d3.scaleLinear()
      .domain([0, yMax])
      .range([height, 0]);
  }

  drawAxisY () {
    const { container, groupMain } = this;

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat(() => '')
      .ticks(10)
      .tickSize(0)
      .tickPadding(3);

    d3.select(container).select('#axisY').remove();

    this.yAxis = groupMain.append('g')
      .attr('id', 'axisY')
      .attr('class', styles.axisY)
      .call(axisLeft);
  }

  drawLineAxisX () {
    const { container, groupMain, height, width } = this;

    d3.select(container).select('#lineAxisX').remove();

    groupMain.append('line')
      .attr('id', 'lineAxisX')
      .attr('class', styles.lineAxisX)
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);
  }

  renderTriangle = () => {
    const { container, margin } = this;

    const triangleMargins = {
      left: margin.left - 5,
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);
  };
}
