import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';

import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { useLocation } from '../../../hooks/useLocation';
import { GOOGLE_MAP_API } from '../../../config/google-maps';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';

import './ContainerMap.scss';
import { Container } from './interfaces';
import { Containers } from './Containers';
import styles from './ContainerMap.module.scss';
import { LocationMarker } from './LocationMarker';
import { getLocationContainers } from './helpers';
import * as containerMapSlice from './containerMapSlice';
import { ContainerMapFilters } from './ContainerMapFilters';

export const ContainerMap = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refFilters = useRef<HTMLDivElement>(null);
  const { location: currentLocation } = useLocation({});

  const { filters, isLoading } = useSelector((state: Store) => state.containerMap);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    containers,
    units,
    unitId,
    moduleId,
    selectedContainer,
  } = filters;

  const theme = getCurrentTheme();

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(containerMapSlice.resetFilters());
    const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
    dispatch(containerMapSlice.fetchUnits({ companyId: selectedCompany._id, unitPhaseType }));
  }, [dispatch, phaseType, selectedCompany._id]);

  const showDescription = (props: { text: string }) => {
    const { text } = props;

    return (
      <div className={styles.center} >
        <LrvEmpty description={text} theme={theme} />
      </div>
    );
  };

  const handleNewMarker = (props: { container: Container }) => {
    const { container } = props;

    const newContainers = [...containers.located, container];
    dispatch(containerMapSlice.setLocatedContainers(newContainers));

    const unlocatedContainers = containers.unlocated.filter((item) => item._id !== container._id);
    dispatch(containerMapSlice.setUnlocatedContainers(unlocatedContainers));

    dispatch(containerMapSlice.setSelectedUnLocatedContainer(undefined));
  };

  const handleMoveMarker = (props: { container: Container }) => {
    const { container } = props;
    const updatedContainers = containers.located.map((item) => item._id === container._id ? container : item);

    dispatch(containerMapSlice.setLocatedContainers(updatedContainers));
  };

  const renderLocationMarker = (props: { container: Container }) => {
    const { container } = props;

    return (
      <LocationMarker
        container={cloneDeep(container)}
        key={container._id}
        unitId={unitId}
        units={units}
        handleNewMarker={handleNewMarker}
        handleMoveMarker={handleMoveMarker}
        unlocatedContainer={cloneDeep(selectedContainer.unlocated)}
      />
    );
  };

  const renderLocationMarkers = () => {
    if (containers.located.length === 0 && selectedContainer.unlocated?._id) {
      return renderLocationMarker({ container: selectedContainer.unlocated });
    }

    return containers.located.map((container) => renderLocationMarker({ container }));
  };

  const renderChart = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (!unitId) {
      return showDescription({ text: t('containerMap.selectUnit') });
    }

    if (!moduleId) {
      return showDescription({ text: t('containerMap.selectModule') });
    }

    const { latitude, longitude } = getLocationContainers({ currentLocation, locatedContainers: containers.located });

    return (
      <div className={cx(styles.body, selectedContainer.unlocated?._id ? styles.cursorPin : '')}>
        <MapContainer
          id='containerMap'
          className={cx('containerMap', styles.containerMap, containers.unlocated.length > 0 ? styles.widthContainerMap : '')}
          center={[latitude, longitude]}
          zoom={16}
        >
          <TileLayer
            url={`https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&key=${GOOGLE_MAP_API}`}
            attribution='&copy; <a href="https://www.google.com/maps">Google Maps</a>'
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />

          {renderLocationMarkers()}
        </MapContainer>

        {containers.unlocated.length > 0 && <Containers theme={theme} />}
      </div>
    );
  };

  return (
    <div className={styles.container} >
      <ContainerMapFilters
        refFilters={refFilters}
        theme={theme}
      />

      {renderChart()}
    </div>
  );
};
