import i18next from 'i18next';
import { FormInstance } from 'antd/lib/form';

import { PATTERN } from '../config/commons';

export function isEmpty (value: any) {
  if (value === undefined || value === '') {
    return true;
  }
  return false;
}

export function isNumber (value: any) {
  if (isNaN(value)) {
    return false;
  }
  return true;
}

export function isIntegerNumber (value: any) {
  if (!Number.isInteger(parseFloat(value))) {
    return false;
  }
  return true;
}

export function checkValueMin (value: any, min: number) {
  const number = parseFloat(value);
  return number >= min;
}

export function isBetweenRange (value: any, min: number, max: number) {
  const number = parseFloat(value);
  if (number < min || number > max) {
    return false;
  }
  return true;
}

export function checkEmail (value: any) {
  return PATTERN.EMAIL.test(String(value).toLowerCase());
}

export function checkNewPassword (value: any) {
  return PATTERN.PASSWORD.test(String(value)) && value.length === value.trim().length;
}

export function validateString (value: any) {
  const status = isEmpty(value);
  if (status) {
    return Promise.reject(i18next.t('validations.fieldRequired'));
  }
  return Promise.resolve();
}

export function validateNumber (value: any, checkInteger: boolean, min?: number, max?: number, message?: string) {
  let status = isEmpty(value);

  if (status) {
    return Promise.reject(i18next.t('validations.fieldRequired'));
  }

  status = isNumber(value);
  if (!status) {
    return Promise.reject(i18next.t('validations.inputNumber'));
  }

  status = isIntegerNumber(value);
  if (!status && checkInteger) {
    return Promise.reject(i18next.t('validations.inputNumberInteger'));
  }

  if (min !== undefined) {
    status = checkValueMin(value, min);
    if (!status) {
      return Promise.reject(i18next.t('validations.minValue') + min);
    }
  }

  if (min !== undefined && max !== undefined) {
    status = isBetweenRange(value, min, max);
    if (!status) {
      return Promise.reject(message);
    }
  }

  return Promise.resolve();
}

export function validateEmail (value: any) {
  const isValidField = PATTERN.EMAIL.test(String(value).toLowerCase());
  if (!isValidField) {
    return Promise.reject(i18next.t('users.profile.emailIncorrect'));
  }
  return Promise.resolve();
}

export function validatePasswordPolicy (value: any) {
  const status = isEmpty(value);

  if (status) {
    return Promise.reject(i18next.t('validations.fieldRequired'));
  }

  const isValidField = PATTERN.PASSWORD.test(String(value));
  if (!isValidField) {
    return Promise.reject(i18next.t('password.reset.passwordError'));
  }

  if (value.length !== value.trim().length) {
    return Promise.reject(i18next.t('users.profile.whiteSpaces'));
  }

  return Promise.resolve();
}

export function validateConfirmPassword (getFieldValue: any, value: any) {
  if (value !== getFieldValue('newPassword')) {
    return Promise.reject(i18next.t('users.errorConfirmPassword'));
  }
  return Promise.resolve();
}

export function validatePIN (value: any, required: boolean) {
  if (value === '') {
    return Promise.resolve();
  }
  let status: boolean;

  if (!required) {
    return Promise.resolve();
  }

  status = isNumber(value);
  if (!status) {
    return Promise.reject(i18next.t('validations.inputNumber'));
  }

  status = isIntegerNumber(value);
  if (!status) {
    return Promise.reject(i18next.t('validations.inputNumberInteger'));
  }

  if (value.length !== 4) {
    return Promise.reject(i18next.t('validations.pin'));
  }

  return Promise.resolve();
}

export const isFieldsTouchedCustom = (form: FormInstance, fields: string[]) => {
  let isFieldsTouched = true;

  const values: {} = form.getFieldsValue();
  for (const [key, value] of Object.entries(values)) {
    if (fields.includes(key) && !value) {
      isFieldsTouched = false;
      break;
    }
  }

  return isFieldsTouched;
};

export const validatePhoneInputGroup = (value: any, phoneInput: any, countryCodeSelect: any) => {
  if (value && phoneInput && countryCodeSelect) {
    return Promise.resolve();
  }

  if (!countryCodeSelect) {
    return Promise.reject(new Error(i18next.t('clients.countryCodeRequired')));
  }

  return Promise.reject(new Error(i18next.t('clients.phoneRequired')));
};
