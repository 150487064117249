import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Row, Space, Select } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { RootState } from '../../../state/store';
import { getDividedReferences } from '../helpers';
import Icon from '../../../common/components/Icon/Icon';
import { ReferenceCurves } from '../../Units/interfaces';
import { formatLongDateWithOffset } from '../../../utils/date';
import { ReferenceCurvesModal } from '../ReferenceCurvesModal';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { stockingPhaseTypes, commonOptions, referenceOptions } from '../../../config/commons';
import { ExtraActionsButton } from '../../../common/components/buttons/ExtraActionsButton';

import styles from './CompanyReferenceCurves.module.scss';
import * as companyReferenceCurvesSlice from './CompanyReferenceCurvesSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

const CompanyReferenceCurves = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { _id: companyId } = useSelector((state: RootState) => state.header.company);
  const { phaseType, isLoading, isCreating, isUpdating, referenceCurves, showModal: showReferenceCurvesModal } = useSelector((state: RootState) => state.companyReferenceCurves);

  useEffect(() => {
    dispatch(companyReferenceCurvesSlice.fetchCompanyReferenceCurves({ companyId, phaseType }));
    return () => {
      dispatch(companyReferenceCurvesSlice.setReferenceCurves([]));
    };
  }, [dispatch, companyId, phaseType]);

  const dataSource = referenceCurves.map((referenceCurve: ReferenceCurves) => {
    return {
      ...referenceCurve,
      creationDate: formatLongDateWithOffset(referenceCurve.createdAt),
      lastUpdate: formatLongDateWithOffset(referenceCurve.updatedAt),
    };
  });

  const columns: ColumnProps<ReferenceCurves>[] = [
    {
      key: 1,
      title: t('references.table.name'),
      dataIndex: 'name',
      width: '22%',
    },
    {
      key: 2,
      title: t('references.table.phase'),
      dataIndex: 'phaseType',
      width: '14%',
      responsive: ['md'] as Breakpoint[],
      render: (_, record: ReferenceCurves) => t(`stockings.phaseTypes.${record.phaseType}`),
    },
    {
      key: 3,
      title: t('references.metricTypeSelect'),
      dataIndex: 'type',
      width: '18%',
      render: (_, record: ReferenceCurves) => getParameter({ parameter: record.type, stockingPhaseType: '', addUnit: false }),
    },
    {
      key: 4,
      title: t('references.table.creationDate'),
      width: '18%',
      render: (_, record: ReferenceCurves) => formatLongDateWithOffset(record.createdAt),
    },
    {
      key: 5,
      title: t('references.table.lastUpdate'),
      width: '18%',
      render: (_, record: ReferenceCurves) => formatLongDateWithOffset(record.updatedAt),
    },
    {
      key: 6,
      width: '12%',
      render: (_, record: ReferenceCurves) => {
        return (
          <Space size='small' className={styles.space}>
            {!record.active && <LrvTag type='active' theme={theme}>{t('references.inactive').toLowerCase()}</LrvTag>}

            <Dropdown
              overlay={() => menuItemOptions(record)}
              trigger={['click']}
              placement='bottomRight'
            >
              <ExtraActionsButton
                id={`button_${record._id}`}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          </Space>
        );
      },
    }
  ];

  const showConfirmEnableReference = (record: ReferenceCurves) => {
    const title = record.active ? t('references.disableConfirm.title') : t('references.enableConfirm.title');
    const content = record.active ? t('references.disableConfirm.description') : t('references.enableConfirm.description');
    const idOkButton = record.active ? 'submit_disable_reference' : 'submit_enable_reference';
    const idCancelButton = record.active ? 'cancel_disable_reference' : 'cancel_enaable_reference';

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined className={styles.exclamationIcon} />,
      content: content,
      okText: t('analysis.accept').toUpperCase(),
      cancelText: t('analysis.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const referenceCurveCopy = cloneDeep(record);
        referenceCurveCopy.active = !referenceCurveCopy.active;
        dispatch(companyReferenceCurvesSlice.enableReferenceCurve({ referenceCurve: referenceCurveCopy, phaseType }));
      },
    });
  };

  const menuItemOptions = (record: ReferenceCurves) => {
    return (
      // eslint-disable-next-line
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT: {
              const reference = getDividedReferences({ referenceCurves: cloneDeep(record) });
              dispatch(companyReferenceCurvesSlice.setSelectedReferenceCurve(reference));
              dispatch(companyReferenceCurvesSlice.setShowModal(true));
              break;
            }

            case referenceOptions.ENABLE:
            case referenceOptions.DISABLE: {
              showConfirmEnableReference(record);
              break;
            }
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' type='line' theme={theme} className={styles.icon} />
          <span>{t('references.edit')}</span>
        </Menu.Item>

        {
          record.active ?
            <Menu.Item id='menu_option_disable' key={referenceOptions.DISABLE} className={styles.menuItemOptions}>
              <Icon name='close' type='line' theme={theme} className={styles.icon} />
              <span>{t('references.disable')}</span>
            </Menu.Item>
            :
            <Menu.Item id='menu_option_enable' key={referenceOptions.ENABLE} className={styles.menuItemOptions}>
              <Icon name='checkbox' type='line' theme={theme} className={styles.icon} />
              <span>{t('references.enable')}</span>
            </Menu.Item>
        }
      </Menu>
    );
  };

  const renderPhaseTypeSelect = () => {
    return (
      <LrvSelect
        id='dropdown_phaseType'
        theme={theme}
        className={styles.select}
        value={phaseType || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        dropdownMatchSelectWidth={false}
        title={t('references.phaseSelect')}
        placeholder={t('references.phaseSelect')}
        onChange={(value) => dispatch(companyReferenceCurvesSlice.setPhaseType(value))}
      >
        <Option key={undefined} value={undefined}>
          {t('references.all')}
        </Option>

        <Option key={stockingPhaseTypes.LARVAE} value={stockingPhaseTypes.LARVAE}>
          {t('header.phaseTypes.larvae')}
        </Option>

        <Option key={stockingPhaseTypes.JUVENILE} value={stockingPhaseTypes.JUVENILE}>
          {t('header.phaseTypes.juveniles')}
        </Option>

        <Option key={stockingPhaseTypes.ADULT} value={stockingPhaseTypes.ADULT}>
          {t('header.phaseTypes.growOut')}
        </Option>
      </LrvSelect>
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.rowHeader}>
        {renderPhaseTypeSelect()}

        <ActionButton
          id='btn_new_reference_curve'
          type='primary'
          icon={<Icon name='add' />}
          className={styles.btnAddReferenceCurve}
          onClick={() => {
            dispatch(companyReferenceCurvesSlice.setShowModal(true));
            dispatch(companyReferenceCurvesSlice.setSelectedReferenceCurve({} as ReferenceCurves));
          }}
          disabled={isCreating}
        >
          {t('references.button')}
        </ActionButton>
      </Row>
    );
  };

  return (
    <div className={styles.container} >
      {renderSubHeader()}

      <Row className={styles.rowFlex} >
        <LrvTable
          columns={columns}
          className={styles.table}
          rowClassName={styles.tableRow}
          dataSource={dataSource}
          loading={isLoading}
          scroll={{ y: '' }}
          size='small'
          theme={theme}
          pagination={false}
        />
      </Row>

      <ReferenceCurvesModal
        isLoading={isCreating || isUpdating}
        onClose={() => {
          dispatch(companyReferenceCurvesSlice.setShowModal(false));
          dispatch(companyReferenceCurvesSlice.setSelectedReferenceCurve({} as ReferenceCurves));
        }}
        showModal={showReferenceCurvesModal}
        currentTab='COMPANY_REFERENCE_CURVES'
      />
    </div>
  );
};

export default CompanyReferenceCurves;