import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { validateEmail, validateNumber } from '../../utils/validations';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';

import styles from './TabFormEdit.module.scss';
import { createCoupon } from './settingsSlice';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabCoupon (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();
  const [formCoupon] = Form.useForm();

  const [email, setEmail] = useState<string>();
  const [quota, setQuota] = useState<string>();
  const [disabledButton, setDisabledButton] = useState(true);

  const quotas = [10, 20, 30, 40, 50];

  return (
    <LrvForm
      id='formCoupon'
      form={formCoupon}
      name='formCoupon'
      layout='vertical'
      theme={theme}
      onFinish={data => {
        createCoupon(data);
      }}
      onFieldsChange={() => {
        const errors = !formCoupon.isFieldsTouched(true) || formCoupon.getFieldsError().filter(({ errors }) => errors.length).length > 0;
        setDisabledButton(errors);
      }}
    >
      <Form.Item
        name='email'
        required
        label={t('coupon.email')}
        rules={[() => ({ validator (rule, value) { return validateEmail(value); } })]}
      >
        <LrvInput
          theme={theme}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>

      <Form.Item
        name='quota'
        required
        label={t('coupon.quota')}
        rules={[() => ({ validator (rule, value) { return validateNumber(value, true, 1); } })]}
      >
        <LrvSelect
          value={quota}
          onChange={e => setQuota(e)}
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('coupon.placeholder')}
          theme={theme}
        >
          {quotas.map((quota) => <Option key={quota} value={quota}>{quota}</Option>)}
        </LrvSelect>
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight}>
          <ActionButton id='submit_coupon_button' type='primary' htmlType='submit' disabled={disabledButton}>
            {t('coupon.save')}
          </ActionButton>
        </div>
      </Form.Item>
    </LrvForm>
  );
}
