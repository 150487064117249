import React from 'react';

import { getUnitCountAnimalChart } from '../helpers';
import { THEME } from '../../../config/commons';

import BarsChart from './BarsChart';
import styles from './Chart.module.scss';
import { Analysis } from './interfaces';

interface Props {
  analysisData: Analysis;
  typeChart: 'GROW_OUT_WHOLE' | 'GROW_OUT_TAIL';
  textClassName?: string;
  textInsideBarClassName?: string;
  textOutsideBarClassName?: string;
  containerClassName?: string;
  typeStyles?: 'DEFAULT' | 'PDF';
  theme?: 'light' | 'dark';
  width?: number;
  height?: number;
}

function GrowOutSizesChart (props: Props) {
  const {
    textClassName = styles.ranges,
    textInsideBarClassName = styles.textInsideBarClassName,
    textOutsideBarClassName = styles.textOutsideBarClassName,
    containerClassName = styles.container,
    analysisData,
    typeChart,
    typeStyles = 'DEFAULT',
    theme = 'dark',
    width,
    height,
  } = props;

  const larvaeNumber = analysisData.resultData.larvaeNumber;
  const analysisPhaseType = analysisData.phaseType;
  const { tail, whole } = analysisData.growOutSizes;
  const isLightTheme = theme === THEME.LIGHT;

  let data: number[] = [];
  let labels: string[] = [];
  let percents: string[] = [];

  const measureUnit = getUnitCountAnimalChart(analysisPhaseType);
  const primaryColor = isLightTheme ? '#1b62e6' : '#0000ff';
  const colorBars = typeChart === 'GROW_OUT_WHOLE' || typeChart === 'GROW_OUT_TAIL' ? primaryColor : undefined;

  const updateGrowOutSizesData = (growOutSizes: {
    values: number[];
    ranges: number[];
    labels: string[];
  }) => {
    data = growOutSizes.values.slice().reverse();
    percents = data.map(value => ((value * 100) / larvaeNumber).toFixed(1));
    labels = growOutSizes.labels.slice().reverse();
  };

  if (typeChart === 'GROW_OUT_WHOLE') {
    updateGrowOutSizesData(whole);
  } else if (typeChart === 'GROW_OUT_TAIL') {
    updateGrowOutSizesData(tail);
  }

  return (
    <BarsChart
      theme={theme}
      colorBars={colorBars}
      data={data}
      weightLabels={labels}
      measureUnit={measureUnit}
      chartType='GROW_OUT_SIZES'
      percents={percents}
      averageWeight={0}
      textClassName={textClassName}
      textInsideBarClassName={textInsideBarClassName}
      textOutsideBarClassName={textOutsideBarClassName}
      containerClassName={containerClassName}
      typeStyles={typeStyles}
      defaultWidth={width}
      defaultHeight={height}
    />
  );
}

export default GrowOutSizesChart;
