import { Form } from 'antd';
import i18next from 'i18next';
import { ColumnsType } from 'antd/lib/table';
import { MouseEventHandler } from 'react';

import Icon from '../../common/components/Icon/Icon';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import ActionButton from '../../common/components/buttons/ActionButton';

import { BindingStocking, BindingStockingTable as IBindingStockingTable } from './interfaces';
import styles from './BindingStocking.module.scss';
import { stockingBindingRowIsFull } from './sowings.helpers';

export const ErrorLabelRow = (props: { hasError?: boolean }) => {
  const { hasError = false } = props;

  if (hasError) {
    return (
      <div className={styles.errorRow}> {i18next.t('stockings.invalidBindingCode')} </div>
    );
  }

  return (
    <div className={styles.hiddenRow}> - </div>
  );
};

interface PropsBindingStockingTable {
  columns?: ColumnsType<BindingStocking> | undefined;
  dataSource?: readonly IBindingStockingTable[] | undefined;
}

export const BindingStockingTable = (props: PropsBindingStockingTable) => {
  const { columns, dataSource } = props;

  return (
    <Form.Item
      name='bindingCodes'
      label={i18next.t('stockings.stockingBindingCode')}
      rules={[{ required: false }]}
    >
      <LrvTable
        theme='light'
        className={styles.tableBindingCode}
        columns={columns}
        dataSource={dataSource}
        size='small'
        pagination={false}
        scroll={{ y: 240 }}
      />
    </Form.Item>
  );
};

interface PropsNewBindingStockingButton {
  theme?: 'dark' | 'light';
  bindingStockings: BindingStocking[];
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}

export const NewBindingStockingButton = (props: PropsNewBindingStockingButton) => {
  const { bindingStockings, onClick, theme } = props;

  return (
    <Form.Item className={styles.newItem}>
      <ActionButton
        id='btn_new_binding_stocking'
        className={styles.button}
        icon={<Icon name='add' theme={theme} />}
        disabled={!stockingBindingRowIsFull(bindingStockings)}
        onClick={onClick}
        type='ghost'
        theme={theme}
      >
        {i18next.t('stockings.new')}
      </ActionButton>
    </Form.Item>
  );
};
