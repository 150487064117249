import moment from 'moment';
import { ChartData } from 'chart.js';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import '../ActivityDashboard.scss';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { Campus, CategoryActivity } from '../interfaces';
import { getTextColor } from '../../../helpers/chartjs.helpers';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import FullScreen from '../../../common/components/FullScreen/FullScreen';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { fetchAnalysisCategory, fetchCategoriesActivity } from '../activityDashboardSlice';
import { getMainRole, isAdminUser, roles, analysisCategories as categories, THEME } from '../../../config/commons';

import styles from './AnalysisResults.module.scss';

const { Option } = Select;

interface Analysis {
  _id: string;
  code: string;
}

interface Props {
  theme?: 'dark' | 'light';
}

function AnalysisResults (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const isLightTheme = theme === THEME.LIGHT;

  const fromDate = useSelector((state: Store) => state.activityDashboard.filters.fromDate);
  const toDate = useSelector((state: Store) => state.activityDashboard.filters.toDate);
  const selectedCampus = useSelector((state: Store) => state.activityDashboard.filters.campus as Campus);
  const categoriesActivity = useSelector((state: Store) => state.activityDashboard.categoriesActivity as CategoryActivity[]);
  const analysisCategory = useSelector((state: Store) => state.activityDashboard.analysisCategory as Analysis[]);
  const isAnalysisCategoryLoading = useSelector((state: Store) => state.activityDashboard.isAnalysisCategoryLoading);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [selectCategory, setSelectCategory] = useState('');
  const lastDays = moment(toDate).diff(moment(fromDate), 'days');

  useEffect(() => {
    if (!selectedCompany._id || selectedCompany.maxStage === 1) {
      return;
    }

    dispatch(fetchCategoriesActivity({ fromDate, toDate, phaseType, companyId: selectedCompany._id, campusId: selectedCampus._id }));
  }, [dispatch, fromDate, phaseType, selectedCampus, selectedCompany, toDate]);

  const analysisResultsData = {
    labels: categoriesActivity.map(activity => t(`clientDashboard.categories.${activity.category}`)),
    datasets: [
      {
        backgroundColor: [
          '#2ecc71',
          '#e67e22',
          '#c0392b',
          '#2c3e50'
        ],
        borderWidth: 0,
        data: categoriesActivity.map(activity => activity.count)
      }
    ]
  };

  const analysisCategories = categoriesActivity.map(activity => activity.category);

  const analysisColumns: ColumnProps<Analysis>[] = [{
    key: 1,
    title: t('clientDashboard.analysis'),
    dataIndex: 'code',
    render: (name, record) => {
      const route = `/production/analysis/${record._id}`;
      const mainRole = getMainRole();
      return mainRole !== roles.SALES && mainRole !== roles.SALES_MANAGER ?
        <Link to={route} target='_blank' className={isLightTheme ? 'linkLight' : 'linkDark'}>{name}</Link> : name;
    }
  }];

  const analysisData = analysisCategory.map((analysis: Analysis, index) => {
    return {
      key: index,
      _id: analysis._id,
      code: analysis.code,
    };

  });

  const parentRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);

  let doResize: string | number | NodeJS.Timeout | undefined;
  function handleResize () {
    clearTimeout(doResize);
    doResize = setTimeout(resizeTable, 100);
  }

  function resizeTable () {
    let parentHeight = 0;
    if (parentRef.current) {
      parentHeight = parentRef.current.offsetHeight;
      if (parentHeight !== tableHeight) {
        setTableHeight(parentHeight);
      }
    }
  }

  window.addEventListener('resize', handleResize);
  handleResize();

  function renderWidget () {
    return <div className={styles.container}>
      <div className={styles.actions}>
        <IconButton
          id='open_analysis_results_button'
          onClick={() => {
            if (isRunningOnboarding) {
              return;
            }
            setSelectCategory(categories.OUTLIER);
            dispatch(fetchAnalysisCategory({
              fromDate,
              toDate,
              phaseType: phaseType,
              companyId: selectedCompany._id,
              campusId: selectedCampus._id,
              category: categories.OUTLIER
            }));
            setShowFullScreenModal(true);
          }}
          iconName='fullscreen'
          size='small'
        />
      </div>
      {renderGraph(analysisResultsData)}
    </div>;
  }

  function renderFullWidget () {
    return <div className={styles.containerFull} ref={parentRef}>
      <Row gutter={16} className={styles.row}>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} className={styles.col}>
          {renderGraph(analysisResultsData)}
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className={styles.col}>
          {renderAnalysis()}
        </Col>
      </Row>

    </div>;
  }

  function renderGraph (data: ChartData<'doughnut', number[], unknown>) {
    return <Doughnut
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              color: getTextColor(theme),
              font: {
                size: 11
              },
            },
          },
          title: {
            display: true,
            font: {
              size: 15
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const tooltipItem = context.dataset.data[context.dataIndex];
                return ` ${tooltipItem}`;
              },
            },
          },
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            bottom: -5,
          }
        },
      }}
    />;
  }

  function renderModalTitle () {
    const campus = selectedCampus._id === '' ? t('clientDashboard.allCampus') : selectedCampus.name;
    const amountAnalysis = t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis() });

    if (isAdminUser()) {
      return `${t('clientDashboard.analysisResults')} - ${selectedCompany.name} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
    }

    return `${t('clientDashboard.analysisResults')} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
  }

  function getAmountAnalysis () {
    let value = 0;
    for (let index = 0; index < categoriesActivity.length; index++) {
      value += categoriesActivity[index].count;
    }

    return value;
  }

  const renderTitleTable = () => {
    return (
      <Space>
        <div id='dropdown_categories'>
          <LrvSelect
            title={t('clientDashboard.analysisCategory')}
            showSearch
            className={styles.dashCompanySelect}
            value={selectCategory === '' ? undefined : selectCategory}
            style={{ width: 200 }}
            suffixIcon={<Icon name='arrow-down-s' theme={theme} />}
            placeholder={t('clientDashboard.selectCategory')}
            optionFilterProp='children'
            theme={theme}
            onSelect={(key, option) => {
              setSelectCategory(`${option?.value || ''}`);
              dispatch(fetchAnalysisCategory({
                fromDate,
                toDate,
                phaseType: phaseType,
                companyId: selectedCompany._id,
                campusId: selectedCampus._id,
                category: `${option?.value || ''}`
              }));
            }}
            filterOption={(input, option) => {
              const children = `${option?.children || ''}`;
              return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          >
            {analysisResultsData ? analysisCategories.map((category: string, index) =>
              <Option key={index} value={category}>
                <div id={`${category}`}>{t(`clientDashboard.categories.${category}`)}</div>
              </Option>
            ) : null}
          </LrvSelect>
        </div>
      </Space>
    );
  };

  function renderAnalysis () {
    return <LrvTable
      className={styles.table}
      title={renderTitleTable}
      columns={analysisColumns}
      dataSource={analysisData}
      loading={isAnalysisCategoryLoading}
      scroll={{ y: tableHeight - 170 }}
      theme={theme}
    />;
  }

  return <div>
    {renderWidget()}
    <FullScreen
      title={renderModalTitle()}
      show={showFullScreenModal}
      onClose={() => setShowFullScreenModal(false)}
      theme={theme}
    >
      {renderFullWidget()}
    </FullScreen>
  </div>;
}

export default AnalysisResults;
