import cx from 'classnames';
import ReactDOM from 'react-dom';
import { List, Select, Space } from 'antd';
import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../utils/select';
import * as multiphaseSlice from '../Multiphase/multiphaseSlice';
import { LrvList } from '../../../common/components/LrvList/LrvList';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import TinyCounter from '../../../common/components/TinyCounter/TinyCounter';

import { Container } from './interfaces';
import styles from './Tanks.module.scss';
import * as stockingAnalysisSlice from './stockingAnalysisSlice';
import { getHeightOfTheOtherElements, getScrollHeight } from './tanks-helpers';

interface Props {
  refFilters: RefObject<HTMLDivElement>;
  refLegends: RefObject<HTMLDivElement>;
  refOriginsMultiphase: RefObject<HTMLDivElement>;
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const Tanks = (props: Props) => {
  const { refFilters, refLegends, refOriginsMultiphase, theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const {
    unitSelected,
    moduleSelected,
    containerSelected,
    units,
    modules,
    containers,
    showStockingDetail,
  } = useSelector((state: Store) => state.stockingAnalysis);
  const { company, phaseType } = useSelector((state: Store) => state.header);

  const listContainerRef = useRef<HTMLDivElement>(null);
  const isLightTheme = theme === THEME.LIGHT;

  const renderDropdownUnit = () => {
    return (
      <LrvSelect
        id='dropdown_unit'
        showSearch
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('production.sidePanel.unit')}
        placeholder={t('production.sidePanel.unit')}
        value={unitSelected}
        theme={theme}
        onChange={(value) => {
          dispatch(stockingAnalysisSlice.setUnitSelected(value));
          dispatch(stockingAnalysisSlice.setModuleSelected(undefined));
          dispatch(stockingAnalysisSlice.setContainerSelected(undefined));
          dispatch(stockingAnalysisSlice.setStocking(undefined));

          dispatch(stockingAnalysisSlice.setContainers([]));
          dispatch(stockingAnalysisSlice.setModules([]));

          dispatch(stockingAnalysisSlice.fetchModules({ unitId: value, phaseType, selectFirstItem: true }));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderDropdownModule = () => {
    return (
      <LrvSelect
        id='dropdown_module'
        showSearch
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('production.sidePanel.module')}
        placeholder={t('production.sidePanel.module')}
        value={moduleSelected}
        disabled={!unitSelected}
        theme={theme}
        onChange={(value) => {
          dispatch(stockingAnalysisSlice.setModuleSelected(value));
          dispatch(stockingAnalysisSlice.setContainerSelected(undefined));
          dispatch(stockingAnalysisSlice.setStocking(undefined));

          dispatch(stockingAnalysisSlice.setContainers([]));
          dispatch(stockingAnalysisSlice.fetchContainers({ unitId: unitSelected, moduleId: value }));
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelect>
    );
  };

  const onChangeContainer = (container: Container) => {
    dispatch(stockingAnalysisSlice.setShowMultiphaseChart(false));
    dispatch(multiphaseSlice.setPhaseTypesSelected([]));
    dispatch(multiphaseSlice.setPhaseTypesLegend([]));
    dispatch(stockingAnalysisSlice.setIsLoadingFetchActiveStockings(true));
    dispatch(stockingAnalysisSlice.setListStockingsIds([]));
    dispatch(stockingAnalysisSlice.setListStockingsName([]));
    dispatch(stockingAnalysisSlice.setEnabledStockings([]));
    dispatch(stockingAnalysisSlice.setContainerSelected(container._id));
    dispatch(stockingAnalysisSlice.fetchActiveStocking({ companyId: company._id, unitId: unitSelected, moduleId: moduleSelected, containerId: container._id }));
  };

  const disablePreviousIcon = () => {
    const index = containers.findIndex((container) => container._id === containerSelected);
    return !containerSelected || index <= 0;
  };

  const disableNextIcon = () => {
    const index = containers.findIndex((container) => container._id === containerSelected);
    return !containerSelected || index + 1 >= containers.length;
  };

  const handleScrollToPosition = (position: number) => {
    if (!listContainerRef.current) {
      return;
    }

    const listElement = ReactDOM.findDOMNode(listContainerRef.current);

    if (!listElement) {
      return;
    }

    if (!(listElement instanceof Element)) {
      return;
    }

    const listChildElement = listElement.querySelector('.ant-list');
    if (!listChildElement) {
      return;
    }

    listChildElement.scrollTop = position;
  };

  const onPreviousContainer = () => {
    dispatch(stockingAnalysisSlice.setShowMultiphaseChart(false));
    const containerIndex = containers.findIndex((container) => container._id === containerSelected);

    let prevContainerIndex = containerIndex;

    for (let index = (containerIndex - 1); index >= 0; index--) {
      const container = containers[index];

      if (container.analysisCount > 0) {
        prevContainerIndex = index;
        break;
      }
    }

    const container = prevContainerIndex === -1 ? { _id: containerSelected } as Container : containers[prevContainerIndex];

    if (container._id !== containerSelected) {
      onChangeContainer(container);
      const scrollHeight = getScrollHeight({ listContainerRef });
      const itemHeight = scrollHeight / containers.length * (containerIndex - 1);
      handleScrollToPosition(itemHeight);
    }
  };

  const onNextContainer = () => {
    dispatch(stockingAnalysisSlice.setShowMultiphaseChart(false));
    const containerIndex = containers.findIndex((container) => container._id === containerSelected);

    let nextContainerIndex = containerIndex;

    for (let index = (containerIndex + 1); index < containers.length; index++) {
      const container = containers[index];

      if (container.analysisCount > 0) {
        nextContainerIndex = index;
        break;
      }
    }

    const container = nextContainerIndex === -1 ? { _id: containerSelected } as Container : containers[nextContainerIndex];

    if (container._id !== containerSelected) {
      onChangeContainer(container);
      const scrollHeight = getScrollHeight({ listContainerRef });
      const itemHeight = (scrollHeight / containers.length * (nextContainerIndex + 1));
      handleScrollToPosition(itemHeight);
    }
  };

  const renderTitleDropdownContainer = () => {
    return (
      <div className={isLightTheme ? styles.dropdownContainerLight : styles.dropdownContainerDark}>
        <div className={styles.tanksTitle}>
          <div className={styles.title}>
            {t('production.sidePanel.tanks')}
          </div>
          <div className={styles.icons}>
            <div
              className={cx(styles.icon, disablePreviousIcon() ? styles.disabled : '')}
              onClick={() => {
                if (!disablePreviousIcon()) {
                  onPreviousContainer();
                }
              }}
            >
              <Icon name='arrow-left' theme={theme} />
            </div>

            <div
              className={cx(styles.icon, disableNextIcon() ? styles.disabled : '')}
              onClick={() => {
                if (!disableNextIcon()) {
                  onNextContainer();
                }
              }}
            >
              <Icon name='arrow-right' theme={theme} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderItemDropdownContainer = (container: Container) => {
    return (
      <List.Item
        className={container._id === containerSelected ? styles.tankSelected : styles.tank}
        onClick={() => {
          onChangeContainer(container);
        }}
        title=''
      >
        <span>{container.name}</span>
        <TinyCounter theme={theme} title={t('stockings.analysisCount')} text={container.analysisCount?.toString()} className={isLightTheme ? styles.analysisCountLight : styles.analysisCountDark} />
      </List.Item>
    );
  };

  const renderDropdownContainer = () => {
    return (
      <LrvList
        id='list'
        ref={listContainerRef}
        title={renderTitleDropdownContainer()}
        size='small'
        theme={theme}
        className={cx(styles.tanks, isLightTheme ? styles.tanksLight : styles.tanksDark)}
        style={{
          maxHeight: window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends, origins: refOriginsMultiphase, showStockingDetail }),
        }}
        bordered
        dataSource={containers}
        rowKey={(container: Container) => container._id}
        renderItem={renderItemDropdownContainer}
      />
    );
  };

  return (
    <Space direction='vertical' className={styles.container}>
      {renderDropdownUnit()}
      {renderDropdownModule()}
      {renderDropdownContainer()}
    </Space>
  );
};
