import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../state/store.interfaces';
import { formatLongDateWithOffset } from '../../../utils/date';

import styles from './Footer.module.scss';

interface Props {
  stockingBindingCode?: string;
}

export default function Footer ({ stockingBindingCode }: Props) {
  const [t] = useTranslation();

  const date = moment();
  const initialDate = formatLongDateWithOffset(date.toString());
  const maxDate = formatLongDateWithOffset(date.add(4, 'M').toString());
  const footer = t('detail.pdf.footer', { initialDate: initialDate, maxDate: maxDate });

  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detailAnalysis);

  const getAnalysisCode = () => {
    if (!stockingBindingCode) {
      return `${t('detail.pdf.sample')} ${analysisOriginal.code}`;
    }

    return `${t('detail.pdf.sample')} ${analysisOriginal.code} - ${t('stockings.pdf.stockingBindingCode')} `;
  };

  const getGeneticCode = () => {
    if (!stockingBindingCode) {
      return null;
    }
    return stockingBindingCode;
  };

  return (
    <div className={styles.footer}>
      {analysisOriginal.sowingId?._id &&
        <>
          {getAnalysisCode()} &nbsp; {getGeneticCode()}
          <br />
        </>
      }
      {footer}
    </div>
  );
}
