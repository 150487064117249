import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';

import { Company } from '../../AppHeader/interfaces';
import resetIconSvg from '../../../assets/reset.svg';
import { getCurrentTheme } from '../../../helpers/theme';
import { applyThousandsSeparator } from '../../../utils/strings';
import { GenericParam } from '../../../common/interfaces/commons';
import '../../../common/components/charts/ShadedPlot/ShadedPlot.scss';
import { DEFAULT_STAGE_MAX, THEME, roundOneDecimal, roundWeight } from '../../../config/commons';

import styles from './PrevStatsD3.module.scss';
import { calcPrevStatsStages, getPrevStatChartLeftPosition, renderXTickFormat } from './helpers';
import { PrevStatsByTanks, PrevStatsData, PrevStatsDataByStage, PrevTankSelected } from './interfaces';

let zoomDone = false;
let numbersTicks: number;
let currentStageActive: string;
let idleTimeout: NodeJS.Timeout | null;
let prevTanksSelected: PrevTankSelected[] = [];

const TIME_TRANSITION = 300;
const TICK_PADDING = 8;

interface Props {
  colorFillRect?: string;
  colorLine: string;
  companyData: Company;
  container: HTMLDivElement | null;
  dataMetric: PrevStatsByTanks[];
  firstStage: number;
  lastStage: number;
  showLabels?: boolean;
  width: number;
  height: number;
  selectedTickStoke?: string;
  prevTanksSelected: PrevTankSelected[];
}

export default class PrevStatsD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, unknown, null, undefined> = d3.select<SVGSVGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  groupMain: d3.Selection<SVGGElement, unknown, null, undefined>;

  x: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  y: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  dataMetric: PrevStatsByTanks[] = [];
  dataY1: number[] = [];
  dataY2: number[] = [];
  allPoints: PrevStatsData[] = [];

  width: number;
  height: number;
  margin = { top: 20, right: 24, bottom: 20, left: 45 };

  firstStage = 0;
  lastStage = 0;
  maxStage = 0;

  containerLeft: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerRight: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerTop: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  containerBottom: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  colorFillRect: string;
  colorLine = '';

  selectedTickStoke: string;
  showLabels: boolean;

  clip: d3.Selection<d3.BaseType, unknown, null, undefined> = d3.select<d3.BaseType, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<unknown> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  // eslint-disable-next-line
  constructor(props: Props) {
    const { colorFillRect = '', showLabels = false, colorLine, companyData, container, dataMetric, firstStage, lastStage, width, height, selectedTickStoke = 'white' } = props;

    this.container = container;
    this.dataMetric = dataMetric;
    this.maxStage = companyData.maxStage;
    this.colorLine = colorLine;
    this.colorFillRect = colorFillRect;
    this.selectedTickStoke = selectedTickStoke;
    prevTanksSelected = props.prevTanksSelected;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.showLabels = showLabels;

    d3.select(container).select('#tooltip').remove();
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.sortAllPoints();
    if (firstStage === lastStage && firstStage === 0) {
      const stages = calcPrevStatsStages(dataMetric);
      this.firstStage = stages[0];
      this.lastStage = stages[1];
    } else {
      this.firstStage = firstStage;
      this.lastStage = lastStage;
    }

    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width + 16)
      .attr('height', height + 14)
      .attr('x', -8)
      .attr('y', -8);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { firstStage, lastStage } = this;
      const { maxY, minY } = this.getYDomainData();

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }
      this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;
    this.x.domain([x0, x1].map(this.x.invert));
    this.y.domain([y1, y0].map(this.y.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);

    this.refreshBrush();
  }

  resetBrush = (props: { x0: number; x1: number; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = false;
    this.x.domain([x0, x1]);
    this.y.domain([y0, y1]);

    this.refreshBrush();
    this.hideTooltipOption();
  }

  refreshBrush = () => {
    const { height } = this;

    const axisBottom = d3.axisBottom(this.x)
      .tickFormat(renderXTickFormat)
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    const axisLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    const { line } = this.generateLines();

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.gClipPath
      .selectAll('.pathLine')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', line as any);

    this.gClipPath
      .selectAll('circle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.x(point.x))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.y(roundWeight({ value: point.y, showUnit: false, forceCast: true }) as number));

    this.renderResetBrush();
  }

  renderLineSelected () {
    const { container, groupMain, margin, height, selectedTickStoke } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke)
      .attr('stroke-width', 1)
      .attr('y1', -(margin.top / 2))
      .attr('y2', height)
      .style('display', 'none');
  }

  updateDataPoints = () => {
    this.buildAxisX();
    this.buildAxisY();
    this.renderResetBrush();

    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentStageActive).attr('r', 8);
      });

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    this.renderLineSelected();
    this.createGClipPathElement();
    this.renderLines();

    this.drawXAxis();
    this.drawYAxis();
    this.renderPoints();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  };

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  /* eslint-disable max-depth*/
  renderTooltips () {
    const { dataMetric, groupMain, x, y, tooltip,
      firstStage, lastStage, allPoints,
      width, height, renderTooltipsForPrevStats
    } = this;

    const bisect = d3.bisector(function (point: PrevStatsData) {
      return point.rangeId;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const tooltipExtraPadding = tooltip.append('div')
      .attr('id', 'tooltipExtraPadding')
      .attr('class', styles.extraPadding);

    groupMain
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentStageActive).attr('r', () => 6);
      })
      .on('mousemove', function (event) {
        let x0 = x.invert((d3).pointer(event)[0]);
        x0 = Math.round(x0);

        const isXVisible = (x0 >= x.domain()[0]) && (x0 <= x.domain()[1]);
        if (!isXVisible) {
          return;
        }

        const index = bisect(allPoints, x0, 1);

        const previousPoint = allPoints[index - 1];
        const currentPoint = allPoints[index];
        let selectedPoint: PrevStatsData;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint.rangeId > currentPoint.rangeId - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint) {
          return;
        }

        if (!(selectedPoint.rangeId < firstStage || selectedPoint.rangeId > lastStage)) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        const dataByStage: PrevStatsDataByStage[] = [];
        const pointsList: { x: number; y: number; }[] = [];

        for (let index = 0; index < dataMetric.length; index++) {
          const data = dataMetric[index];
          const points = data.prevStats.map(metric => { return { x: metric.rangeId, y: metric.avg }; });
          if (points && points.length > 0) {
            points.forEach((point) => {
              if (point.x === selectedPoint.rangeId) {
                const pointData = data.prevStats.find(item => item.rangeId === point.x) as PrevStatsData;
                const { stageRange, stockingId, stockingName, phaseType, destination } = pointData;
                const _dataByStage: PrevStatsDataByStage = { point, stageRange, stockingId, stockingName, tankId: data.tankId, tankName: data.tankName || '', index: index, phaseType, destination };
                dataByStage.push(_dataByStage);
                pointsList.push(point);
              }
            });
          }
        }

        d3.selectAll('.points circle').attr('r', () => 6);
        if (pointsList.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: { x: number; y: number; } = pointsList.reduce(function (prev, current) {
          return (prev.y > current.y) ? prev : current;
        });
        const lowestValue: { x: number; y: number; } = pointsList.reduce(function (prev, current) {
          return (prev.y < current.y) ? prev : current;
        });

        const marginLeft = x(higherValue.x);
        const marginBottom = y(roundWeight({ value: lowestValue.y, showUnit: false, forceCast: true }) as number);
        currentStageActive = `.stage${higherValue.x}`;
        d3.selectAll(currentStageActive).attr('r', 8);

        const tooltipDialogWidth = 160;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }

        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltipExtraPadding
          .style('width', '16px') // has to be the same that value of left
          .style('left', () => {
            let value = 0;
            const tooltipTotalWidth = tooltip.node()?.offsetWidth || 0;

            if ((marginLeft + tooltipTotalWidth) < width) {
              value = -16; // has to be the same that width, but negative
            } else {
              value = tooltipTotalWidth;
            }

            return `${value}px`;
          });

        tooltip
          .style('left', getPrevStatChartLeftPosition(leftPositionProps))
          .style('bottom', () => {
            let value = 0;
            const tooltipTotalHeight = tooltip.node()?.offsetHeight || 0;

            if (marginBottom + tooltipTotalHeight > height) {
              value = (height - marginBottom);
            } else {
              value = (height - marginBottom) - (tooltipTotalHeight / 4);
            }
            return `${value}px`;
          });

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipsForPrevStats(dataByStage, tooltipContent);
      });
  }

  renderTooltipsForPrevStats (dataByStage: PrevStatsDataByStage[], tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>) {
    const forceCast = true;
    for (let index = 0; index < dataByStage.length; index++) {
      const data = dataByStage[index];
      const prevTankItem = prevTanksSelected.find(item => item.tankId === data.tankId) as PrevTankSelected;
      const { lineColor } = prevTankItem;

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      const entryOriginTitle = entry.append('div')
        .attr('class', styles.entryHeader);

      const phaseLabel = i18next.t(`stockings.phaseTypes.${data.phaseType}`);
      const originLabel = i18next.t('stockings.origin');
      entryOriginTitle.append('div')
        .attr('class', styles.entryTitle)
        .style('color', lineColor)
        .html(`${originLabel} (${phaseLabel}):`);

      const entryOriginContent = entry.append('div')
        .attr('class', styles.entryContent);

      entryOriginContent.append('div')
        .attr('class', styles.stat)
        .html(`<a target="_blank" href="/production/stockings/${data.stockingId}"><strong>${data.tankName}:</strong> ${data.stockingName}</a>`);

      const entryDestinationTitle = entry.append('div')
        .attr('class', styles.entryHeader);
      const destinationLabel = i18next.t('stockings.destination');
      entryDestinationTitle.append('div')
        .attr('class', styles.entryTitle)
        .style('color', lineColor)
        .html(`${destinationLabel} (${i18next.t('stockings.phaseTypes.ADULT')}):`);

      const entryDestinationContent = entry.append('div')
        .attr('class', styles.entryContent);

      for (let i = 0; i < data.destination.length; i++) {
        const destinationData = data.destination[i];
        const { tankName, densityUnit } = destinationData;
        const density = applyThousandsSeparator(Math.floor(destinationData.density));
        entryDestinationContent.append('div')
          .attr('class', styles.stat)
          .html(`<a target="_blank" href="/production/stockings/${destinationData.stockingId}"><strong>${tankName}:</strong> ${density} ${densityUnit}</a>`);
      }

      const stageLabel = i18next.t('performance.tooltip.days');
      entryDestinationContent.append('div')
        .attr('class', styles.stat)
        .html(`<strong>${stageLabel}: </strong>${data.stageRange}`);

      const avgWeightLabel = i18next.t('performance.tooltip.averageWeight');
      entryDestinationContent.append('div')
        .attr('class', cx(styles.stat, 'growthDelta'))
        .html(`<strong>${avgWeightLabel}:</strong> ${roundWeight({ value: data.point.y, forceCast })}`);

      if (index !== dataByStage.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }

  generateLines () {
    const { x, y, dataMetric } = this;

    const linePoints: { tankId: string; x: number; y: number }[][] = [];

    const line = d3.line<{ x: number; y: number }>()
      .x((d) => x(d.x))
      .y((d) => y(roundWeight({ value: d.y, showUnit: false, forceCast: true }) as number))
      .curve(d3.curveBasis);

    if (this.firstStage === this.lastStage) {
      return {
        linePoints,
        line,
      };
    }

    for (let index = 0; index < dataMetric.length; index++) {
      const prevStatsData = dataMetric[index].prevStats;
      const points = prevStatsData.map(data => { return { tankId: data.tankId, x: data.rangeId, y: data.avg }; });

      linePoints.push(points);
    }

    return {
      linePoints,
      line,
    };
  }

  renderLines () {
    const { brush, container, gClipPath } = this;

    const { linePoints, line } = this.generateLines();

    d3.select(container).selectAll('.brush').remove();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    for (let index = 0; index < linePoints.length; index++) {
      const linePoint = linePoints[index];
      const prevTankId = linePoint?.length > 0 ? linePoint[0].tankId : '';
      const prevTankItem = prevTanksSelected.find(item => item.tankId === prevTankId) as PrevTankSelected;
      const { lineColor } = prevTankItem;

      gClipPath
        .append('path')
        .datum(linePoint)
        .attr('class', cx('pathLine', 'lines'))
        .attr('stroke', lineColor)
        .attr('d', line);
    }
  }

  renderLabelOfPoint (props: { avgPoint: { x: number; y: number; } }) {
    const { groupMain } = this;
    const { avgPoint } = props;

    groupMain.append('text')
      .attr('class', 'labelsPoints')
      .attr('x', this.x(avgPoint.x))
      .attr('y', this.y(avgPoint.y) - 12)
      .style('text-anchor', 'middle')
      .text(roundOneDecimal(avgPoint.y));
  }

  renderLabelsOfPoints () {
    const { dataMetric } = this;

    for (let i = 0; i < dataMetric.length; i++) {
      const prevStatsData = dataMetric[i].prevStats;
      const points = prevStatsData.map(data => { return { x: data.rangeId, y: data.avg }; });
      for (let j = 0; j < points.length; j++) {
        const avgPoint = points[j];
        this.renderLabelOfPoint({ avgPoint });
      }
    }
  }

  renderPoints () {
    const { gClipPath, x, y, dataMetric } = this;

    for (let index = 0; index < dataMetric.length; index++) {
      const prevStatsData = dataMetric[index].prevStats;
      const prevTankId = dataMetric[index].tankId;
      const points = prevStatsData.map(data => { return { x: data.rangeId, y: data.avg }; });
      const prevTankItem = prevTanksSelected.find(item => item.tankId === prevTankId) as PrevTankSelected;
      const { lineColor, strokeColor } = prevTankItem;

      gClipPath.append('g')
        .attr('class', 'points')
        .attr('fill', 'transparent')
        .style('pointer-events', 'none')
        .selectAll('circle')
        .data(points)
        .enter()
        .append('circle')
        .attr('id', (point) => `point_${point.x}`)
        .attr('class', (point) => {
          if (this.firstStage <= point.x && point.x <= this.lastStage) {
            return `stage${point.x}`;
          }
          return styles.hideCircle;
        })
        .attr('r', () => {
          return 6;
        })
        .attr('cx', (point) => x(point.x))
        .attr('cy', (point) => y(roundWeight({ value: point.y, showUnit: false, forceCast: true }) as number))
        .attr('fill', () => {
          return lineColor;
        })
        .attr('stroke', () => {
          return strokeColor;
        })
        .attr('stroke-width', 2);
    }
  }

  deleteDomainAxisX () {
    const { container } = this;
    d3.select(container).select('#content').select('#axisX').selectAll('.domain').remove();
  }

  renderLinesAxis = () => {
    const { container, groupMain, height, width } = this;

    d3.select(container).selectAll('.lineAxisY').remove();
    d3.select(container).selectAll('.lineAxisX').remove();

    groupMain.append('line')
      .attr('class', 'lineAxisY')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', -8)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', 0);

    groupMain.append('line')
      .attr('class', 'lineAxisX')
      .attr('stroke', '#959595')
      .attr('fill', 'transparent')
      .attr('stroke-width', 1)
      .attr('y1', height)
      .attr('y2', height)
      .attr('x1', 0)
      .attr('x2', width);
  }

  renderTriangle = () => {
    const { container, margin, height } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 11),
    };

    d3.select(container).selectAll('.triangle').remove();

    d3.select(container)
      .attr('id', 'triangle')
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, 0px)`);
  };

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 28) + 'px')
      .style('top', '-8px');
  };

  renderResetBrush = () => {
    const { margin, width, svg, colorLine } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#resetBrushButton').remove();
    svg.select('#defsBackgroundIcon').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft)
      .attr('y', 0)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        const { firstStage, lastStage } = this;
        const { maxY, minY } = this.getYDomainData();
        this.resetBrush({ x0: firstStage, y0: minY, x1: lastStage, y1: maxY });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', colorLine)
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  updateAxis () {
    const { height, firstStage, lastStage } = this;

    this.renderTriangle();
    this.renderLinesAxis();
    this.renderResetBrush();

    this.buildAxisX();
    this.buildAxisY();

    numbersTicks = (lastStage - firstStage) + 1 < DEFAULT_STAGE_MAX ? (lastStage - firstStage) + 1 : 16; // Check method getNumberTicks in GrowthDeltaChart

    const axis = d3.axisBottom(this.x)
      .tickFormat((x) => renderXTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .transition()
      .duration(TIME_TRANSITION)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();

    const axiLeft = d3.axisLeft(this.y)
      .tickFormat((d) => d3.format('')(d as number))
      .tickSize(0)
      .tickPadding(TICK_PADDING);
    this.yAxis.transition()
      .duration(TIME_TRANSITION)
      .call(axiLeft);
  }

  showOrHideFirstLineOfAxisXTick () {
    const tickG = d3.selectAll<HTMLElement, undefined>('#axisX>.tick').nodes()[0];
    const transformProperty: string = tickG?.getAttribute('transform') || '';
    const match = transformProperty.match(/translate\(([\d.]+),[\d.]+\)/);

    if (match) {
      const xPosition = parseFloat(match[1]);
      if (xPosition === 0) {
        d3.select(tickG).select('line').style('display', 'none');
      } else {
        d3.select(tickG).select('line').style('display', 'flex');
      }
    } else {
      d3.select(tickG).select('line').style('display', 'flex');
    }
  }

  updateData () {
    this.updateAxis();
    this.createGClipPathElement();
    this.renderLines();
    this.renderPoints();

    if (this.showLabels) {
      this.renderLabelsOfPoints();
    }
  }

  refreshChart (props: { dataMetric: PrevStatsByTanks[]; prevTanksSelected: PrevTankSelected[]; firstStage: number; lastStage: number; width: number; height: number; colorFillRect: string; colorLine: string; selectedTickStoke: string; }) {
    const { dataMetric, selectedTickStoke, colorFillRect, colorLine, firstStage, lastStage, width, height } = props;
    const { container, tooltip } = this;

    zoomDone = false;
    this.dataMetric = dataMetric;
    this.firstStage = firstStage;
    this.lastStage = lastStage;
    this.colorFillRect = colorFillRect;
    this.colorLine = colorLine;
    this.selectedTickStoke = selectedTickStoke;
    prevTanksSelected = props.prevTanksSelected;

    d3.select(container).select('#tooltipContent').remove();

    d3.select(container).selectAll('.labelsPoints').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);
    this.sortAllPoints();
    this.renderLineSelected();
    this.updateData();
    this.renderTooltipOption();
    this.renderTooltips();
  }

  buildAxisX () {
    const { width, firstStage, lastStage } = this;

    this.x = d3.scaleLinear()
      .domain([firstStage, lastStage])
      .range([0, width]);
  }

  buildAxisY () {
    const { height } = this;
    const { maxY, minY } = this.getYDomainData();

    this.y = d3.scaleLinear()
      .domain([minY, maxY])
      .range([height, 0]);
  }

  getYDomainData = () => {
    const { dataMetric } = this;

    const margin = 0.1;
    const dataY: number[] = [0.5];
    dataMetric.forEach(metric => {
      metric.prevStats.forEach(data => {
        dataY.push(data.avg);
      });
    });
    let minY = 0;
    let maxY = Math.max(...dataY);

    minY = minY - margin < 0 ? 0 : minY - margin;
    maxY = maxY + margin;

    minY = roundWeight({ value: minY, showUnit: false, forceCast: true }) as number;
    maxY = roundWeight({ value: maxY, showUnit: false, forceCast: true }) as number;

    return {
      maxY,
      minY,
    };
  }

  drawXAxis () {
    const { height, groupMain, firstStage, lastStage } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    numbersTicks = (lastStage - firstStage) + 1 < DEFAULT_STAGE_MAX ? (lastStage - firstStage) + 1 : 16; // Check method getNumberTicks in GrowthDeltaChart

    const axis = d3.axisBottom(this.x)
      .tickFormat((x) => renderXTickFormat(x))
      .tickSize(-height)
      .ticks(numbersTicks)
      .tickPadding(TICK_PADDING);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.deleteDomainAxisX();
    this.showOrHideFirstLineOfAxisXTick();
  }

  drawYAxis () {
    const { groupMain } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;


    const axis = d3.axisLeft(this.y)
      .tickFormat(function (d) {
        return d3.format('')(d as number);
      })
      .ticks(10)
      .tickSize(0)
      .tickPadding(TICK_PADDING);

    this.yAxis = groupMain.append('g')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  sortAllPoints () {
    const { dataMetric } = this;

    const points: PrevStatsData[] = [];
    dataMetric.forEach(metric => {
      metric.prevStats.forEach(data => {
        points.push(data);
      });
    });

    this.allPoints = points;
    this.allPoints.sort((a: PrevStatsData, b: PrevStatsData) => a.rangeId - b.rangeId);
  }

  resize = (width: number, height: number) => {
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltip').remove();

    this.updateSize(width, height);
    this.updateAxis();
    this.sortAllPoints();
    this.createBrushElement();
    this.updateDataPoints();
    this.renderTooltipOption();
    this.renderTooltips();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };
}
