import { Volume } from '../pages/Units/interfaces';
import { stockingPhaseTypes, containerTypes } from '../config/commons';

export const getContainerVolumeLimits = (props: { volumeRanges: Volume; phaseType?: string }) => {
  const { phaseType, volumeRanges } = props;

  const racewayLimit = phaseType === stockingPhaseTypes.ADULT ?
    { min: volumeRanges.growOut.cubicMeters.min, max: volumeRanges.growOut.cubicMeters.max } :
    { min: volumeRanges.juvenile.cubicMeters.min, max: volumeRanges.juvenile.cubicMeters.max };


  const containerVolumeLimits = {
    [containerTypes.TANK]: { min: volumeRanges.larvae.liters.min, max: volumeRanges.larvae.liters.max },
    [containerTypes.RACEWAY]: racewayLimit,
    [containerTypes.PRE_HATCHERY]: { min: volumeRanges.juvenile.hectares.min, max: volumeRanges.juvenile.hectares.max },
    [containerTypes.POOL]: { min: volumeRanges.growOut.hectares.min, max: volumeRanges.growOut.hectares.max },
  };

  return containerVolumeLimits;
};

