import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem, move, swap } from 'react-grid-dnd';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { getTitlePhaseType } from '../../../helpers/header.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { openErrorNotification } from '../../../common/notification/Notification';

import styles from './AnalysisCard.module.scss';
import { AnalysisCardCustomMobile } from './interfaces';
import { getKeysAnalysisDetailCustomMobile, getLabelForKey } from './helpers';
import * as analysisCardCustomizableSlice from './analysisCardCustomizableSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const AnalysisCard = (props: Props) => {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { analysisCardCustomizable } = useSelector((state: Store) => state.analysisCardCustomizable);

  const { fields } = analysisCardCustomizable;

  const isLightTheme = theme === THEME.LIGHT;

  const maxItemsToShow = 4;
  const [allData, setAllData] = useState<{ keysSelected: AnalysisCardCustomMobile[], otherKeys: AnalysisCardCustomMobile[] }>({ keysSelected: [], otherKeys: [] });

  useEffect(() => {
    /* if (company._id && isOwnerRole()) {
    } */
    dispatch(analysisCardCustomizableSlice.fetchAnalysisCardCustomizable({ companyId: company._id, phaseType }));
  }, [dispatch, company._id, phaseType]);

  useEffect(() => {
    const { keysSelected, otherKeys } = getKeysAnalysisDetailCustomMobile({ phaseType, fields });
    setAllData({ keysSelected, otherKeys });
  }, [phaseType, fields]);

  const onChange = (sourceId: 'keysSelected' | 'otherKeys', sourceIndex: number, targetIndex: number, targetId?: 'keysSelected' | 'otherKeys') => {
    if (targetId === 'keysSelected' && allData.keysSelected.length >= maxItemsToShow) {
      return;
    }

    if (!targetId) {
      const result = swap(allData[sourceId], sourceIndex, targetIndex);
      return setAllData({
        ...allData,
        [sourceId]: result
      });
    }

    if (sourceId === 'keysSelected' && allData.keysSelected[sourceIndex] === 'stage') {
      openErrorNotification(t('analysisCardCustomMobile.notAllowed'));
      return;
    }

    const result = move(
      allData[sourceId],
      allData[targetId],
      sourceIndex,
      targetIndex
    );

    return setAllData({
      ...allData,
      [sourceId]: result[0],
      [targetId]: result[1]
    });
  };

  const getBorderRadiusClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.borderRadiusLeft;

      case (maxItemsToShow - 1):
        return styles.borderRadiusRight;

      default:
        return '';
    }
  };

  const getHoverGridItemClass = (index: number) => {
    switch (index) {
      case 0:
        return styles.hoverItem0;

      case (maxItemsToShow - 1):
        return styles.hoverItem3;
    }
  };

  const getWidthClass = (index: number) => {
    switch (index) {
      case (maxItemsToShow - 1):
        return styles.width;

      default:
        return '';
    }
  };

  const isValidAnalysisDetailCustomizable = () => {
    return allData.keysSelected.length === maxItemsToShow;
  };

  return (
    <div className={styles.container}>
      <LrvText
        className={styles.settings}
        theme={theme}
        text={'* ' + t('analysisCardCustomMobile.settings', { phaseType: getTitlePhaseType(phaseType) })}
      />

      <GridContextProvider
        onChange={(sourceId: string, sourceIndex: number, targetIndex: number, targetId?: string) => {
          onChange(sourceId as 'keysSelected' | 'otherKeys', sourceIndex, targetIndex, targetId as 'keysSelected' | 'otherKeys');
        }}
      >
        <Space className={styles.row} direction='vertical'>
          <div>
            <div className={styles.label}>
              <LrvText
                className={styles.description}
                theme={theme}
                text={t('analysisCardCustomMobile.description')}
              />
            </div>

            <GridDropZone
              className={cx(styles.dropzone, styles.otherKeys, isLightTheme ? styles.otherKeysLight : styles.otherKeysDark)}
              id='otherKeys'
              boxesPerRow={4}
              rowHeight={40}
            >
              {allData.otherKeys.map(item => (
                <GridItem key={item}>
                  <div className={cx(styles.gridItem, styles.gridItemOtherKeys)}>
                    <div className={cx(styles.gridItemContent, styles.keys)}>
                      <LrvText
                        text={getLabelForKey({ phaseType, field: item })}
                        theme={theme}
                      />
                    </div>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>

          <div>
            <GridDropZone
              className={cx(styles.dropzone, styles.keysSelected)}
              id='keysSelected'
              boxesPerRow={maxItemsToShow}
              rowHeight={78}
            >
              {allData.keysSelected.map((item, index) => (
                <GridItem
                  key={item}
                  className={cx(getBorderRadiusClass(index), getWidthClass(index))}
                >
                  <div className={cx(styles.gridItem, getHoverGridItemClass(index))}>
                    <div className={styles.gridItemContent}>
                      <LrvText
                        text={getLabelForKey({ phaseType, field: item })}
                        theme={theme}
                      />
                    </div>
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </div>
        </Space>
      </GridContextProvider>

      <div className={styles.alignButtonRight} >
        <ActionButton
          type='primary'
          htmlType='button'
          disabled={!isValidAnalysisDetailCustomizable()}
          onClick={() => {
            if (analysisCardCustomizable._id) {
              dispatch(analysisCardCustomizableSlice.updateAnalysisCardCustomizable({ _id: analysisCardCustomizable._id, fields: allData.keysSelected }));
              return;
            }

            dispatch(analysisCardCustomizableSlice.saveAnalysisCardCustomizable({ companyId: company._id, phaseType, fields: allData.keysSelected }));
          }}
        >
          {t('analysisCardCustomMobile.save')}
        </ActionButton>
      </div>
    </div>
  );
};
