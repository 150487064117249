import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { THEME } from '../../../../config/commons';
import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../../helpers/theme';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../../common/components/LrvTooltip/LrvTooltip';

import * as sampleSlice from './sampleSlice';
import styles from './IconOptions.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  editSample: boolean;
  larvaeModifiedList?: [];
  analysisId: string;
}

export default function IconOptions (props: Props) {
  const {
    editSample,
    larvaeModifiedList,
    theme = getCurrentTheme(),
    analysisId,
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isLightTheme = theme === THEME.LIGHT;
  const { sampleAnimalList, changesSaved } = useSelector((state: Store) => state.sample);

  if (!larvaeModifiedList?.length && !changesSaved) {
    return (
      <>
        <div className={styles.editSample}>
          <LrvTooltip
            title={editSample ? t('detail.manageAnimals.cancel') : t('detail.manageAnimals.edit')}
            placement='right'
          >
            <ActionButton
              type='text'
              icon={<Icon name={editSample ? 'close' : 'edit'} type='line' theme={theme} />}
              onClick={() => {
                dispatch(sampleSlice.setEditSample(!editSample));
                dispatch(sampleSlice.resetAnimalSelected());
                dispatch(sampleSlice.resetOldPercentile());
                dispatch(sampleSlice.resetNewPercentile());
                dispatch(sampleSlice.resetNewAnimal());
              }}
            />
          </LrvTooltip>
        </div>

        {
          sampleAnimalList.length && editSample &&
          <div className={styles.containerButton}>
            <ActionButton
              id='btn_save_sample'
              className={styles.saveButton}
              type='primary'
              onClick={() => {
                dispatch(sampleSlice.editSampleAnimals(analysisId, sampleAnimalList));
              }}
              icon={<Icon name='save-3' />}
            >
              {t('detail.manageAnimals.save')}
            </ActionButton>
          </div>
        }
      </>
    );
  }

  return (
    <div className={cx(styles.editSample, isLightTheme ? styles.editSampleLight : styles.editSampleDark)}>
      <LrvTooltip
        title={t('detail.manageAnimals.disabledEdit')}
        placement='right'
      >
        <ActionButton
          type='text'
          icon={<Icon name='information' type='line' theme={theme} />}
        />
      </LrvTooltip>
    </div>
  );
}
