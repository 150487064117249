import React from 'react';
import cx from 'classnames';
import { Empty } from 'antd';

import { LrvEmptyProps } from '../../interfaces/lrvEmpty';

import styles from './LrvEmpty.module.scss';

export const LrvEmpty = (props: LrvEmptyProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkEmpty : styles.lightEmpty, className),
  };

  return (
    <Empty {...lrvProps} />
  );
};
