import React from 'react';
import cx from 'classnames';
import { Empty, SpinProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { LrvTableProps } from '../../interfaces/lrvTable';
import DotSpinner from '../DotSpinner/DotSpinner';
import { LrvEmpty } from '../LrvEmpty/LrvEmpty';

import styles from './LrvTable.module.scss';

// build a custom spinner by defect
const buildLoadingProps = (loading: SpinProps | boolean | undefined, isDarkTheme: boolean) => {
  const renderLrvTableSpinner = () => {
    return (
      <div className={styles.spinnerContainer}>
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      </div>
    );
  };

  let loadingProps: SpinProps | boolean | undefined = loading;

  if (loading && typeof loading === 'boolean') {
    loadingProps = {
      className: cx(styles.lrvTableSpinner, isDarkTheme ? styles.spinnerBackgroundDark : styles.spinnerBackgroundLight),
      indicator: renderLrvTableSpinner(),
      spinning: loading, // if loading is boolean, spiining prop is equal to loading
    };
  }

  if (typeof loading !== 'boolean') {
    loadingProps = {
      ...loading,
      className: loading?.className ?? cx(styles.lrvTableSpinner, isDarkTheme ? styles.spinnerBackgroundDark : styles.spinnerBackgroundLight),
      indicator: loading?.indicator ?? renderLrvTableSpinner(),
      spinning: loading?.spinning ?? false, // should pass spinning prop as true to show spinner
    };
  }

  return loadingProps;
};

export const LrvTable = (props: LrvTableProps) => {
  const { className, loading, theme = 'dark', pagination, ref } = props;
  const isDarkTheme = theme === 'dark';
  const [t] = useTranslation();

  const loadingProps = buildLoadingProps(loading, isDarkTheme);

  const lrvTableProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkTable : styles.lightTable, className),
    loading: loadingProps,
    pagination: typeof pagination === 'boolean' ? pagination : {
      ...pagination,
      locale: {
        items_per_page: t('page'),
      }
    }
  };

  //10, 20, 50 100
  return (
    <Table
      {...lrvTableProps}
      ref={ref}
      locale={{
        triggerAsc: t('stockings.sorts.asc'),
        cancelSort: t('stockings.sorts.cancel'),
        triggerDesc: t('stockings.sorts.desc'),
        emptyText: (
          <LrvEmpty
            theme={theme}
            description={t('empty')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      }}
    />
  );
};
