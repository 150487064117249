import React from 'react';
import cx from 'classnames';

import { getUserSession } from '../../../utils/userSession';

import styles from './Avatar.module.scss';

interface Props {
  imageUrl?: string;
  className?: string;
  labelClassName?: string;
  label?: string;
  firstName: string;
  lastName: string;
}

export default function Avatar (props: Props) {
  const { imageUrl, labelClassName, className, firstName = '', lastName = '', label } = props;

  const getContent = () => {
    if (imageUrl) {
      return (
        <img src={imageUrl} alt="" />
      );
    }

    if (label) {
      return (
        <span className={cx(styles.textNameAvatar, labelClassName ?? '')}>{`${label}`}</span>
      );
    }

    return (
      <span id='avatar_letters' className={labelClassName}>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</span>
    );
  };

  return (
    <div className={cx(styles.avatar, className ?? '')}>
      {getContent()}
    </div>
  );
}

const userSession = getUserSession();

Avatar.defaultProps = {
  firstName: userSession.firstName,
  lastName: userSession.lastName,
};
