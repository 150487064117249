import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { typeHistogram } from '../../../config/commons';
import { Dataset } from '../../Analysis/Detail/interfaces';
import { HISTOGRAM_HEIGHT, HISTOGRAM_WIDTH } from '../helpers';

import HistogramD3 from './HistogramD3';
import styles from './HarvestHistogram.module.scss';

let chart: HistogramD3 | null;

interface Props {
  theme?: 'dark' | 'light';
  histogram: string;
  primaryDataset: Dataset[];
  secondaryDataset: Dataset[];
  primaryLimits: number[];
  secondaryLimits: number[];
  width?: number;
  height?: number;
  renderPdf?: boolean;
}

export const HarvestHistogram = (props: Props) => {
  const {
    theme = 'light',
    histogram,
    primaryDataset,
    secondaryDataset,
    primaryLimits,
    secondaryLimits,
    width = window.innerWidth > HISTOGRAM_WIDTH ? HISTOGRAM_WIDTH : window.innerWidth - 40,
    height = HISTOGRAM_HEIGHT,
    renderPdf,
  } = props;

  const [t] = useTranslation();
  const refChart = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, []);

  useEffect(() => {
    if (!refChart.current || primaryDataset.length === 0 || primaryLimits.length === 0) {
      return;
    }

    if (chart) {
      chart.refreshHistogram({ primaryDataset, secondaryDataset, histogram, primaryLimits, secondaryLimits, theme });
      return;
    }

    chart = new HistogramD3({
      container: refChart.current,
      primaryDataset,
      height,
      histogram,
      primaryLimits,
      theme,
      width,
      renderPdf,
    });
  }, [histogram, refChart.current, primaryDataset, secondaryDataset, primaryLimits, secondaryLimits, theme, renderPdf]);

  const getLabelAxisX = () => {
    switch (histogram) {
      case typeHistogram.WEIGHT:
      default:
        return t('harvests.weights');

      case typeHistogram.LENGTH:
        return t('harvests.lengths');
    }
  };

  return (
    <div>
      <div className={cx(styles.labelAxisY, renderPdf ? styles.labelAxisYPDF : '')}>{t('harvests.weightedFrequency')}</div>
      <div ref={refChart} />
      <div className={cx(styles.labelAxisX, renderPdf ? styles.labelAxisXPDF : '')}>{getLabelAxisX()}</div>
    </div>
  );
};
