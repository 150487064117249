import cx from 'classnames';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Data from '../Data';
import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import { DataSource, Point } from '../../home/interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { charactersToSeparate } from '../../../utils/strings';
import Content from '../../../common/components/Content/Content';
import { getLabelAxisX } from '../../../helpers/stocking.helpers';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import * as laboratoryChartSlice from '../../Reports/LaboratoryChart/laboratoryChartSlice';
import { typesChart, metricsStatuses, colorsPoints, getDefaultDataByStage } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './LaboratoryPdf.module.scss';

type TParams = {
  companyId: string; scale: string;
  language: string; accessToken: string; laboratories: string;
  parameter: string; phaseType: string;
  minimumDate: string; maximumDate: string;
  firstStage: string; lastStage: string; unitId: string; unitName?: string;
};

const LaboratoryPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    companyId, language, scale,
    accessToken, laboratories, parameter,
    minimumDate, maximumDate, phaseType,
    firstStage, lastStage, unitId, unitName,
  } = match.params;

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const selectedLaboratories = laboratories ? laboratories.split(charactersToSeparate) : [];

  const {
    isLoading,
    dataSource,
    laboratoryMetrics,
    laboratoryMetricsByUnits,
  } = useSelector((state: Store) => state.laboratoryChart);

  const { company: selectedCompany } = useSelector((state: Store) => state.header);

  const refChartMain = useRef<HTMLDivElement>(null);
  const laboratoryNames = dataSource.map((data) => data.name);

  const width = 1680 - 96;
  // const height = laboratorys.length === 0 ? 950 - 22 : 920 - 22;
  const height = 910;
  const backgroundColor = 'white';

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    const params = {
      companyId,
      parameter,
      minimumDate,
      maximumDate,
      phaseType,
      accessToken,
      unitId,
    };

    dispatch(laboratoryChartSlice.fetchLaboratoryMetrics(params));
  }, [dispatch, phaseType, companyId, minimumDate, maximumDate, parameter, accessToken, unitId]);

  useEffect(() => {
    const laboratoryDataSource: DataSource[] = [];

    let index = 0;
    for (const [laboratoryName, laboratoryStageMetrics] of Object.entries(laboratoryMetrics)) {
      const laboratoryByStageList: Point[] = [];
      const color = colorsPoints[index];
      const enabled = true;
      const show = true;

      if (!selectedLaboratories.includes(laboratoryName)) {
        continue;
      }

      for (const [stage, laboratoryStage] of Object.entries(laboratoryStageMetrics)) {
        const x: number = parseInt(stage);
        const y: number = laboratoryStage.mean;
        const analysesNumber = laboratoryStage.number;
        const laboratoryByStage: Point = getDefaultDataByStage({ x, y, analysesNumber });
        laboratoryByStageList.push(laboratoryByStage);
      }

      const laboratoryData: DataSource = { id: laboratoryName, name: laboratoryName, color, enabled, show, points: [], avgPoint: laboratoryByStageList };
      laboratoryDataSource.push(laboratoryData);
      index++;
    }

    for (const [laboratoryName, laboratoryMetricByUnit] of Object.entries(laboratoryMetricsByUnits)) {
      for (const [unitName, laboratoryStage] of Object.entries(laboratoryMetricByUnit)) {
        const key = `${laboratoryName}_${unitName}`;

        // eslint-disable-next-line
        if (!selectedLaboratories.includes(key)) {
          continue;
        }

        const laboratoryByStageList: Point[] = [];
        const dataSourceSelected = dataSource.find((data) => data.id === key);
        const color = dataSourceSelected?.color || colorsPoints[index];

        const enabled = dataSourceSelected?.enabled || true;
        const show = dataSourceSelected?.show || true;

        // eslint-disable-next-line
        for (const [stage, value] of Object.entries(laboratoryStage)) {
          const x: number = parseInt(stage);
          const y: number = value.mean;
          const analysesNumber = value.number;
          const laboratoryByStage: Point = getDefaultDataByStage({ x, y, analysesNumber });
          laboratoryByStageList.push(laboratoryByStage);
        }
        const laboratoryData: DataSource = { id: key, name: `${laboratoryName}: ${unitName}`, color, enabled, show, points: [], avgPoint: laboratoryByStageList };
        laboratoryDataSource.push(laboratoryData);
        index++;
      }
    }

    dispatch(laboratoryChartSlice.setDataSource(laboratoryDataSource));
  }, [laboratoryMetrics]);

  useEffect(() => {
    const showChart = () => {
      const dataSourceFiltered = dataSource.filter((data) => data.enabled && data.show && data.color);
      const colorsPoints = dataSourceFiltered.map((dataSource) => `${dataSource.color}`);
      const colorsStroke = colorsPoints;

      const props = {
        colorFillRect: backgroundColor,
        colorLine: 'white',
        companyData: selectedCompany,
        container: refChartMain.current,
        dataMetric: [],
        dataSource: cloneDeep(dataSource),
        firstStage: Number(firstStage),
        height,
        lastStage: Number(lastStage),
        parameter,
        phaseType,
        scale,
        showLabels: false,
        typeChart: typesChart.LABORATORY,
        typeMetric: metricsStatuses.COMPANY,
        width,
        colorsPoints,
        colorsStroke,
      };
      new D3ShadedPlot(props);
    };

    showChart();
  }, [laboratoryMetrics, scale, parameter, firstStage, lastStage, width, height, selectedCompany, phaseType, dataSource]);

  if (isLoading && dataSource.length === 0) {
    return (
      <div className={styles.spinner} >
        <DotSpinner />
      </div>
    );
  }

  return (
    <div id='maturationChartPdf' className={cx(styles.containerMain, 'maturationComparisionPdf')}>
      <Header
        title={selectedCompany.name}
        subtitle={`${t('laboratory.comparison')} -`}
        rightSubtitle={`${moment(minimumDate).format('YYYY-MMM-DD')} - ${moment(maximumDate).format('YYYY-MMM-DD')}`}
      />

      <div className={styles.rowData}>
        <Data
          stockingPhaseType={phaseType}
          parameter={parameter}
          legendList={laboratoryNames}
          scale={scale}
          language={language}
          unitName={unitName}
        />
      </div>

      <div className={styles.rowData}>
        <Content
          headerClassName={styles.headerPadding}
          titleClassName={styles.titleContent}
          noPadding
          style={{ backgroundColor: backgroundColor }}
        >
          <div className='maturationChart'>
            <div ref={refChartMain} className={styles.chart}> </div>
            <div className={styles.labelAxisX}>{getLabelAxisX(phaseType)}</div>
          </div>
        </Content>
      </div>

      <Footer />
    </div>
  );
};

export default LaboratoryPdf;