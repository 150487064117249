import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import { Company } from '../AppHeader/interfaces';
import Icon from '../../common/components/Icon/Icon';
import { ItemReferenceCurves } from '../home/interfaces';
import { handleResizeEvent } from '../../utils/dimensions';
import { getParameter } from '../../helpers/stocking.helpers';
import { LrvText } from '../../common/components/LrvText/LrvText';
import D3ShadedPlot from '../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { calcReferenceCurveStages, lineColor, metricsStatuses, rectColor, typesChart, selectedTickStoke } from '../../common/components/charts/ShadedPlot/helpers';

import { getXaxisLabel } from './helpers';
import styles from './ReferenceCurveChart.module.scss';

interface Props {
  referenceCurves: ItemReferenceCurves[];
  phaseType: string;
  company: Company;
  parameter: string;
  referenceName: string;
  widthChartMax: number;
  currentTab: 'GLOBAL_REFERENCE_CURVES' | 'COMPANY_REFERENCE_CURVES' | 'UNIT_REFERENCE_CURVES';
}

let chart: D3ShadedPlot | null;
const heightChart = 528;
const screenMax = 1030;
const widthChartMin = 432;

const ReferenceCurveChart = (props: Props) => {
  const { company, referenceCurves, phaseType, parameter = '', referenceName, widthChartMax = 504, currentTab } = props;

  const [t] = useTranslation();
  const refChartMain = useRef<HTMLDivElement>(null);

  const colorFillRect = rectColor.light;
  const colorLine = lineColor.light;

  const validReferenceCurves: { key: number; mean: number; stage: number; range: number }[] = [];
  referenceCurves.forEach((item, index) => {
    if (item.mean !== undefined && item.stage !== undefined && item.range !== undefined) {
      const reference = {
        key: item.key || index,
        mean: item.mean,
        stage: item.stage,
        range: item.range,
      };
      validReferenceCurves.push(reference);
    }
  });

  const [width, setWidth] = useState(window.innerWidth > screenMax ? widthChartMax : widthChartMin);

  useEffect(() => {
    return (() => {
      chart = null;
    });
  }, []);

  useEffect(() => {
    chart && chart.resize(width, heightChart);
  }, [window.innerWidth]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth > screenMax ? widthChartMax : widthChartMin);
    });
  }, []);

  useEffect(() => {
    if (!validReferenceCurves.length) {
      return;
    }

    const { firstStage, lastStage } = calcReferenceCurveStages({ itemReferenceCurves: validReferenceCurves });

    if (chart) {
      const props = {
        dataMetric: validReferenceCurves,
        firstStage,
        lastStage,
        width: width,
        height: heightChart,
        colorFillRect,
        colorLine,
        typeChart: typesChart.STOCKINGS,
        parameter,
        phaseType,
        selectedTickStoke: selectedTickStoke.light,
        typeMetric: metricsStatuses.REFERENCE_CURVE,
      };
      chart.refreshChart(props);
      return;
    }

    const props = {
      colorFillRect,
      colorLine,
      companyData: company,
      container: refChartMain.current,
      dataMetric: validReferenceCurves,
      firstStage,
      height: heightChart,
      lastStage,
      parameter,
      phaseType,
      showLabels: false,
      typeChart: typesChart.STOCKINGS,
      typeMetric: metricsStatuses.REFERENCE_CURVE,
      width: width,
    };

    chart = new D3ShadedPlot(props);
  }, [company, validReferenceCurves.length, validReferenceCurves, phaseType, parameter, width]);

  const showChart = validReferenceCurves.length > 0;

  const renderParameter = () => {
    const value = getParameter({ parameter: parameter, stockingPhaseType: phaseType });

    return (
      <div className={styles.labelAxisY}>
        <LrvText theme='light' className={styles.lightText} text={value} />
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: showChart ? '' : 'none',
        }}
      >

        <div className={styles.title}>
          {currentTab === 'GLOBAL_REFERENCE_CURVES' ? `${t('references.preview')} - ${t('stockings.reference')}  ${capitalize(referenceName)}` : t('references.preview')}
        </div>

        {renderParameter()}
        <div ref={refChartMain} className={styles.chart} />
        <div className={styles.labelAxisX}>
          <LrvText className={styles.lightText} theme='light' text={getXaxisLabel(phaseType)} />
        </div>
      </div>

      <div
        className={styles.preview}
        style={{
          display: showChart ? 'none' : '',
        }}
      >
        <Icon name='line-chart' className={styles.icon} />

        <div className={styles.text}>
          <LrvText
            theme='light'
            text={t('references.descriptionPreview')}
          />
        </div>
      </div>
    </>
  );
};

export default ReferenceCurveChart;