/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';
import i18next from 'i18next';
import { isDesktop } from 'react-device-detect';

import sightIconSvg from '../../../../assets/sight-icon.svg';
import closeIconSvg from '../../../../assets/close_icon.svg';
import { stockingPhaseTypes } from '../../../../config/commons';
import { Animal, AnimalsByGroup, Percentile } from '../interfaces';
import nounHeartMonitorSvg from '../../../../assets/noun-heart-monitor.svg';

import styles from './SampleD3.module.scss';

export interface SampleD3Props {
  container: HTMLDivElement | null;
  width: number;
  height: number;
  urlImage: string;
  animalsByGroup: Percentile[] | AnimalsByGroup[];
  phaseType: string;
  showAnimalsRemoved?: boolean;
  translateX?: number;
  translateY?: number;
  enableScroll?: boolean;
  sightIconColor?: string;
  allowEditSample?: boolean;
  newAnimal?: number[] | undefined;
  animalSelected?: Animal | undefined;
  currentIndex?: number;
  addNewAnimal?: (point: number[]) => void;
  onSelectAnimal?: (animal: Animal, percentile: Percentile | AnimalsByGroup) => void;
  removeAnimal?: () => void;
  undoChangeGroupAnimal?: () => void;
  undoRemovedAnimal?: () => void;
  removeDisease?: () => void;
  showChangeGroupPanel?: (show: boolean) => void;
  showDiseasesPanel?: (show: boolean) => void;
}

export interface EditSampleD3Props {
  animalsByGroup: Percentile[] | AnimalsByGroup[];
  showAnimalsRemoved: boolean;
  allowEditSample?: boolean;
  newAnimal?: number[];
  animalSelected?: Animal;
  currentIndex?: number;
  urlImage: string;
  removeAnimal?: () => void;
  undoChangeGroupAnimal?: () => void;
  undoRemovedAnimal?: () => void;
  removeDisease?: () => void;
}

interface PointProps {
  fill: string;
  r: number;
  x: number;
  y: number;
  stroke: string;
  strokeWidth: number;
  dash: number[];
  animal: Animal;
  group: Percentile | AnimalsByGroup;
  hasDisease?: boolean;
}

export default class SampleD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, PointProps, null, undefined> = d3.select<SVGSVGElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));
  svgGroup: d3.Selection<SVGGElement, PointProps, null, undefined> = d3.select<SVGGElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  svgImage: d3.Selection<SVGImageElement, PointProps, null, undefined> = d3.select<SVGImageElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'image'));
  svgCircle: d3.Selection<SVGCircleElement, PointProps, null, undefined> = d3.select<SVGCircleElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'circle'));

  svgCloseIcon: d3.Selection<SVGImageElement, PointProps, null, undefined> = d3.select<SVGImageElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'image'));
  svgVirusIcon: d3.Selection<SVGImageElement, PointProps, null, undefined> = d3.select<SVGImageElement, PointProps>(document.createElementNS('http://www.w3.org/2000/svg', 'image'));

  width = 0;
  height = 0;
  urlImage = '';
  animalsByGroup;
  phaseType = '';
  showAnimalsRemoved;
  translateX;
  translateY;
  enableScroll = true;
  allowEditSample = false;
  newAnimal;
  animalSelected;
  addNewAnimal;
  sightIconColor;
  onSelectAnimal;
  removeAnimal?: () => void;
  undoChangeGroupAnimal?: () => void;
  undoRemovedAnimal?: () => void;
  removeDisease?: () => void;
  showChangeGroupPanel;
  showDiseasesPanel;
  currentIndex = 0;
  previousIndex = 0;

  dragging = false;
  startCoords = [0, 0];
  offset = {
    x: 0,
    y: 0,
  };
  lastTouchEnd = 0;
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  diseaseTooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));

  // eslint-disable-next-line
  constructor(props: SampleD3Props) {
    const {
      container,
      width,
      height,
      urlImage,
      animalsByGroup,
      phaseType,
      showAnimalsRemoved,
      translateX = 0,
      translateY = 0,
      enableScroll = true,
      allowEditSample = false,
      newAnimal,
      animalSelected,
      addNewAnimal,
      showChangeGroupPanel,
      showDiseasesPanel,
      sightIconColor,
      onSelectAnimal,
    } = props;

    this.container = container;
    this.width = width;
    this.height = height;
    this.urlImage = urlImage;
    this.animalsByGroup = animalsByGroup;
    this.phaseType = phaseType;
    this.showAnimalsRemoved = showAnimalsRemoved;
    this.translateX = translateX;
    this.translateY = translateY;
    this.enableScroll = enableScroll;
    this.allowEditSample = allowEditSample;
    this.newAnimal = newAnimal;
    this.animalSelected = animalSelected;
    this.addNewAnimal = addNewAnimal;
    this.sightIconColor = sightIconColor;
    this.onSelectAnimal = onSelectAnimal;
    this.showChangeGroupPanel = showChangeGroupPanel;
    this.showDiseasesPanel = showDiseasesPanel;

    this.createSvgElement();
    this.renderImage();

    if (enableScroll) {
      this.applyScrollToSvg();
    }

    this.renderCircles();

    if (allowEditSample) {
      this.activateEvents();
    }

    this.renderTooltip();
  }

  refreshChart (props: EditSampleD3Props) {
    const { animalsByGroup, showAnimalsRemoved, allowEditSample = false, newAnimal, animalSelected, currentIndex = 0, urlImage, removeAnimal, undoChangeGroupAnimal, undoRemovedAnimal, removeDisease } = props;

    this.animalsByGroup = animalsByGroup;
    this.showAnimalsRemoved = showAnimalsRemoved;
    this.allowEditSample = allowEditSample;
    this.newAnimal = newAnimal;
    this.animalSelected = animalSelected;
    this.urlImage = urlImage;
    this.removeAnimal = removeAnimal;
    this.undoChangeGroupAnimal = undoChangeGroupAnimal;
    this.undoRemovedAnimal = undoRemovedAnimal;
    this.removeDisease = removeDisease;

    if (this.currentIndex !== currentIndex) {
      this.previousIndex = this.currentIndex;
    }

    this.currentIndex = currentIndex;

    if (this.previousIndex !== this.currentIndex) {
      this.renderImage();
    }

    if (this.enableScroll) {
      this.applyScrollToSvg();
    }

    this.renderCircles();

    if (allowEditSample) {
      this.activateEvents();
    }

    this.renderNewAnimal();
    this.updateBackgroundColor('black');
  }

  createSvgElement () {
    const { container, height, width, translateX, translateY } = this;

    d3.select(container).select('svg').remove();

    this.svg = d3.select<HTMLDivElement | null, PointProps>(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', width)
      .attr('height', height);

    this.svgGroup = this.svg
      .append('g')
      .attr('id', 'svg-group')
      .attr('transform', `translate(${translateX}, ${translateY})`);
  }

  renderImage () {
    const { container, urlImage } = this;

    d3.select(container).select('#svgImage').remove();

    this.svgImage = this.svgGroup.append('image')
      .attr('id', 'svgImage')
      .attr('xlink:href', urlImage)
      .attr('width', '100%')
      .attr('height', '100%');
  }

  getCoords (event: TouchEvent) {
    if (isDesktop) {
      return d3.pointer(event);
    }

    if (!event.touches) {
      return [this.startCoords[0], this.startCoords[1]];
    }

    const touch = event.touches[0];
    return [touch.clientX, touch.clientY];
  }

  mouseDown (event: TouchEvent) {
    this.dragging = true;
    this.startCoords = this.getCoords(event);
    this.svgGroup.style('cursor', 'grabbing');
  }

  mouseUp () {
    if (!this.dragging) return;

    this.dragging = false;
    this.svgGroup.style('cursor', 'grab');
  }

  mouseMove (event: TouchEvent) {
    if (!this.dragging) return;
    this.tooltip.style('display', 'none');

    const { height, width } = this;
    const currentCoords = this.getCoords(event);

    const extraHeight = 70;
    const deltaX = (currentCoords[0] - this.startCoords[0]) * 0.8;
    const deltaY = (currentCoords[1] - this.startCoords[1]) * 0.8;

    this.offset.x += deltaX;
    this.offset.y += deltaY;

    this.offset.x = Math.min(0, Math.max(window.innerWidth - width, this.offset.x));
    this.offset.y = Math.min(0, Math.max(window.innerHeight - height - extraHeight, this.offset.y));

    this.svgGroup.attr('transform', `translate(${this.offset.x}, ${this.offset.y})`);
    this.startCoords = currentCoords;
  }

  getPointOnDoubleTap (event: TouchEvent) {
    const headerContainer = document.getElementById('header_fullscreen');
    if (!headerContainer) {
      return [0, 0];
    }

    const headerHeight = headerContainer.offsetHeight;

    const transformMatrix = this.svgGroup?.node()?.transform.baseVal[0].matrix;
    if (!transformMatrix) {
      return [0, 0];
    }

    const translateX = transformMatrix.e;
    const translateY = transformMatrix.f;

    const touch = event.touches[0];
    const x = touch.clientX - translateX;
    const y = touch.clientY - translateY - headerHeight;
    return [x, y];
  }

  touchStart (event: TouchEvent) {
    this.dragging = true;
    this.startCoords = this.getCoords(event);
    this.svgGroup.style('cursor', 'grabbing');

    if (!this.allowEditSample) {
      return;
    }

    this.tooltip.style('display', 'none');
    const currentTime = new Date().getTime();
    const timeSinceLastTouchEnd = currentTime - this.lastTouchEnd;

    if (timeSinceLastTouchEnd < 300) {
      const point = this.getPointOnDoubleTap(event);
      this.addNewAnimal && this.addNewAnimal(point);
    }

    this.lastTouchEnd = currentTime;
  }

  applyScrollToSvg () {
    this.svgGroup.on('mousedown', (event: TouchEvent) => this.mouseDown(event));
    this.svgGroup.on('mouseup', () => this.mouseUp());
    this.svgGroup.on('mousemove', (event: TouchEvent) => this.mouseMove(event));

    this.svgGroup.on('touchstart', (event: TouchEvent) => this.touchStart(event));
    this.svgGroup.on('touchend', () => this.mouseUp());
    this.svgGroup.on('touchmove', (event: TouchEvent) => this.mouseMove(event));
  }

  isEditedAnimal (animal: Animal) {
    return animal.previousGroup !== undefined || animal.added || animal.disease !== undefined;
  }

  isAdultPhaseType () {
    const { phaseType } = this;
    return phaseType === stockingPhaseTypes.ADULT;
  }

  getStrokeColor (animal: Animal) {
    if (this.isEditedAnimal(animal) || this.isAdultPhaseType()) {
      return 'white';
    }

    return '';
  }

  getStrokeWidth (animal: Animal) {
    if (this.isEditedAnimal(animal) || this.isAdultPhaseType()) {
      return 1.5;
    }

    return 0;
  }

  getDash (animal: Animal) {
    if (this.isEditedAnimal(animal)) {
      return [3, 2];
    }

    return [];
  }

  getAnimalData (props: { group: Percentile | AnimalsByGroup; animal: Animal }) {
    const { animal, group } = props;

    const hasDisease = !!animal.disease;
    const data: PointProps = {
      fill: group.color,
      r: group.radius,
      x: animal.x,
      y: animal.y,
      stroke: this.getStrokeColor(animal),
      strokeWidth: this.getStrokeWidth(animal),
      dash: this.getDash(animal),
      animal,
      group,
      hasDisease,
    };
    return data;
  }

  getAnimalsRemoved (props: { group: Percentile | AnimalsByGroup; animal: Animal }) {
    const { animal, group } = props;

    const data: PointProps = {
      fill: group.color,
      r: group.radius,
      x: animal.x,
      y: animal.y,
      stroke: '',
      strokeWidth: 0,
      dash: [],
      animal,
      group,
    };

    return data;
  }

  renderSelectedAnimals (props: { group: Percentile | AnimalsByGroup; animal: Animal }) {
    const { animal, group } = props;

    // eslint-disable-next-line
    const svgCirclePulse: any = this.svgGroup
      .append('circle')
      .classed('outline pulse', true)
      .attr('id', 'circleSelected')
      .attr('fill', 'transparent')
      .attr('r', group.radius)
      .attr('cx', animal.x)
      .attr('cy', animal.y);

    const distance = 8;
    const color = group.color.split('80')[0];

    const repeat = () => {
      svgCirclePulse
        .transition()
        .duration(750)
        .attr('r', group.radius)
        .style('opacity', 1)
        .attrTween('r', () => d3.interpolateNumber(group.radius, group.radius + distance))
        .styleTween('stroke', () => d3.interpolateString(color, 'transparent'))
        .styleTween('stroke-width', () => d3.interpolateNumber(1, distance * 2))
        .transition()
        .duration(500)
        .style('opacity', 0)
        .on('end', () => repeat());
    };

    repeat();
  }

  renderAnimalsRemoved (props: { data: PointProps[] }) {
    const { data } = props;

    // eslint-disable-next-line
    // @ts-ignore
    this.svgCloseIcon = this.svgGroup
      .append('g')
      .attr('id', 'removeAnimalGroup')
      .selectAll('image')
      .data(data)
      .enter()
      .append('image')
      .attr('xlink:href', closeIconSvg)
      .attr('class', styles.closeIconSvg)
      .attr('x', (point: PointProps) => point.x - (point.r * 1))
      .attr('y', (point: PointProps) => point.y - (point.r * 1))
      .attr('width', (point: PointProps) => point.r * 2)
      .attr('height', (point: PointProps) => point.r * 2)
      .style('cursor', this.allowEditSample ? 'pointer' : '');
  }

  renderSickAnimals (props: { data: PointProps[] }) {
    const { data } = props;

    // eslint-disable-next-line
    // @ts-ignore
    this.svgVirusIcon = this.svgGroup
      .append('g')
      .attr('id', 'sickAnimals')
      .selectAll('image')
      .data(data)
      .enter()
      .append('image')
      .attr('xlink:href', nounHeartMonitorSvg)
      .attr('x', (point: PointProps) => point.x - (point.r * 1))
      .attr('y', (point: PointProps) => point.y - (point.r * 1))
      .attr('width', (point: PointProps) => point.r * 2)
      .attr('height', (point: PointProps) => point.r * 2)
      .style('cursor', 'pointer')
      .on('mouseover', (event: TouchEvent, point: PointProps) => {
        this.renderDiseaseTooltip(point?.animal?.disease);
        this.showDiseaseTooltip(point);
      })
      .on('mouseout', () => {
        this.diseaseTooltip.style('display', 'none');
      });
  }

  renderAnimals (props: { data: PointProps[] }) {
    const { data } = props;

    // eslint-disable-next-line
    // @ts-ignore
    this.svgCircle = this.svgGroup
      .append('g')
      .attr('id', 'animals')
      .selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', (point: PointProps) => point.r)
      .attr('cx', (point: PointProps) => point.x)
      .attr('cy', (point: PointProps) => point.y)
      .attr('fill', (point: PointProps) => point.fill)
      .attr('stroke', (point: PointProps) => point.stroke)
      .attr('stroke-dasharray', (point: PointProps) => point.dash)
      .attr('stroke-width', (point: PointProps) => point.strokeWidth)
      .style('cursor', this.allowEditSample ? 'pointer' : '');
  }

  /* eslint-disable max-depth*/
  renderCircles () {
    const { animalsByGroup, container, showAnimalsRemoved, animalSelected } = this;

    const circleData: PointProps[] = [];
    const sickAnimals: PointProps[] = [];
    const removeAnimals: PointProps[] = [];
    let groupSelected: Percentile | AnimalsByGroup | undefined = undefined;

    d3.select(container).select('#removeAnimalGroup').remove();
    d3.select(container).select('#animals').remove();
    d3.select(container).select('#sickAnimals').remove();
    d3.select(container).select('#circleSelected').remove();

    for (let i = 0; i < animalsByGroup.length; i++) {
      const group = animalsByGroup[i];

      for (let index = 0; index < group.animals.length; index++) {
        const animal = group.animals[index];

        if (!group.isActive && !animal.removed) {
          continue;
        }

        if (animal.disease) {
          const data: PointProps = this.getAnimalsRemoved({ animal, group });
          sickAnimals.push(data);
        }

        if (animalSelected && animalSelected.x === animal.x && animalSelected.y === animal.y) {
          const data: PointProps = this.getAnimalData({ animal, group });
          circleData.push(data);
          groupSelected = group;

          continue;
        }

        if (animal.removed) {
          if (showAnimalsRemoved) {
            const data: PointProps = this.getAnimalsRemoved({ animal, group });
            removeAnimals.push(data);
          }
          continue;
        }

        const data: PointProps = this.getAnimalData({ animal, group });
        circleData.push(data);
      }
    }

    this.renderAnimalsRemoved({ data: removeAnimals });
    this.renderAnimals({ data: circleData });
    this.renderSickAnimals({ data: sickAnimals });
    animalSelected && groupSelected && this.renderSelectedAnimals({ animal: animalSelected, group: groupSelected });
  }

  updateBackgroundColor (color: string) {
    this.sightIconColor = color;

    d3.select('#background-color-filter feFlood')
      .attr('flood-color', color);
  }

  renderNewAnimal () {
    const { animalsByGroup, container, newAnimal, sightIconColor = 'black' } = this;

    d3.select(container).select('#sightIcon').remove();
    d3.select(container).select('#background-color-filter').remove();
    d3.select(container).select('#feCompositeBackgroundIcon').remove();

    if (!newAnimal) {
      return;
    }

    const radius = animalsByGroup[2].radius;
    const sizeCloseIcon = radius * 2;

    this.svgGroup
      .append('image')
      .attr('id', 'sightIcon')
      .attr('x', newAnimal[0] - radius)
      .attr('y', newAnimal[1] - radius)
      .attr('xlink:href', sightIconSvg)
      .attr('width', sizeCloseIcon)
      .attr('height', sizeCloseIcon)
      .attr('filter', 'url(#background-color-filter)');

    this.svgGroup
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', sightIconColor)
      .attr('result', 'backgroundColor');

    this.svgGroup.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  }

  onClick (point: PointProps) {
    this.renderTooltip(point);
    this.showGeneralTooltip(point);

    this.diseaseTooltip.style('display', 'none');
    this.showChangeGroupPanel && this.showChangeGroupPanel(false);
    this.showDiseasesPanel && this.showDiseasesPanel(false);
    this.onSelectAnimal && this.onSelectAnimal(point.animal, point.group);
  }

  activateEvents () {
    this.svgCloseIcon && this.svgCloseIcon.on('click', (event: TouchEvent, point: PointProps) => {
      this.onClick(point);
    });

    this.svgVirusIcon && this.svgVirusIcon.on('click', (event: TouchEvent, point: PointProps) => {
      this.onClick(point);
    });

    this.svgCircle.on('click', (event: TouchEvent, point: PointProps) => {
      this.renderTooltip(point);
      this.showGeneralTooltip(point);
      this.showChangeGroupPanel && this.showChangeGroupPanel(false);
      this.showDiseasesPanel && this.showDiseasesPanel(false);
      this.onSelectAnimal && this.onSelectAnimal(point.animal, point.group);
    });

    this.svgGroup.on('dblclick', (event: TouchEvent) => {
      const point = d3.pointer(event);
      this.tooltip.style('display', 'none');
      this.addNewAnimal && this.addNewAnimal(point);
    });
  }

  renderDiseaseTooltip (disease?: string) {
    const { container } = this;
    d3.select(container).select('#diseaseTooltip').remove();

    this.diseaseTooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'diseaseTooltip')
      .attr('class', styles.tooltip)
      .style('display', 'block');

    if (!disease) {
      return;
    }

    const tooltipContent = this.diseaseTooltip.append('div')
      .attr('id', 'diseaseTooltipContent')
      .attr('class', styles.content);

    const entry = tooltipContent
      .append('div')
      .attr('class', styles.entry);

    const entryContent = entry.append('div')
      .attr('class', styles.entryContent);

    entryContent.append('div')
      .attr('class', styles.row)
      .append('div')
      .attr('class', styles.title)
      .html(`<div>
        <div>${i18next.t('detail.manageAnimals.disease')}:</div>
        <strong>${disease}</strong>
      </div>`);
  }

  renderTooltip (point?: PointProps) {
    const { container } = this;
    d3.select(container).select('#tooltip').remove();

    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('display', 'none');

    if (!point) {
      return;
    }

    const tooltipContent = this.tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    const entry = tooltipContent
      .append('div')
      .attr('class', styles.entry);

    const entryContent = entry.append('div')
      .attr('class', styles.entryContent);

    if (!point.animal.removed) {
      entryContent.append('div')
        .attr('class', styles.row)
        .append('div')
        .attr('class', styles.option)
        .html(point.animal.previousGroup ? i18next.t('detail.manageAnimals.undoChangeGroup') : i18next.t('detail.manageAnimals.changeGroup'))
        .on('click', () => {
          if (point.animal.previousGroup) {
            this.undoChangeGroupAnimal && this.undoChangeGroupAnimal();
          } else {
            this.showChangeGroupPanel && this.showChangeGroupPanel(true);
          }

          this.tooltip.style('display', 'none');
        });

      entryContent.append('div')
        .attr('class', styles.row)
        .append('div')
        .attr('class', styles.option)
        .html(point.animal.disease ? i18next.t('detail.manageAnimals.removeDisease') : i18next.t('detail.manageAnimals.addDisease'))
        .on('click', () => {
          if (point.animal.disease) {
            this.removeDisease && this.removeDisease();
          } else {
            this.showDiseasesPanel && this.showDiseasesPanel(true);
          }

          this.tooltip.style('display', 'none');
        });
    }

    if (point.animal.added || (!point.animal.previousGroup && !point.animal.disease)) {
      entryContent.append('div')
        .attr('class', styles.row)
        .append('div')
        .attr('class', styles.option)
        .html(point.animal.removed ? i18next.t('detail.manageAnimals.undoChangeGroup') : i18next.t('detail.manageAnimals.delete'))
        .on('click', () => {
          if (point.animal.removed) {
            this.undoRemovedAnimal && this.undoRemovedAnimal();
          } else {
            this.removeAnimal && this.removeAnimal();
          }

          this.tooltip.style('display', 'none');
        });
    }
  }

  showGeneralTooltip (point: PointProps) {
    this.showTooltip({ point, tooltip: this.tooltip });
  }

  showDiseaseTooltip (point: PointProps) {
    this.showTooltip({ point, tooltip: this.diseaseTooltip });
  }

  showTooltip (props: { point: PointProps; tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> }) {
    const { point, tooltip } = props;

    const transformMatrix = this.svgGroup.node()?.transform.baseVal[0].matrix;
    if (!transformMatrix) {
      return;
    }

    const translateX = transformMatrix.e;
    const translateY = transformMatrix.f;

    const widthDifference = window.innerWidth > this.width ? (window.innerWidth - this.width) / 2 : 0;
    const tooltipWidth = 160;

    if (window.innerWidth > (point.x + translateX + tooltipWidth)) {
      tooltip.style('display', 'block')
        .style('left', `${point.x + translateX + widthDifference + (point.r * 1.5)}px`)
        .style('right', 'inherit')
        .style('top', `${point.y + translateY - point.r}px`);
      return;
    }

    tooltip.style('display', 'block')
      .style('right', `${window.innerWidth - (point.x + translateX - (tooltipWidth / 6))}px`)
      .style('left', 'inherit')
      .style('top', `${point.y + translateY - point.r}px`);
  }
}
