import { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { changeHeader } from '../../AppHeader/headerSlice';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { stockingParameterOptions, stockingParameterTypes } from '../../../config/commons';

import styles from './StockingParameters.module.scss';
import { CompanyStockingParameter } from './interfaces';
import { NewStockingParameterForm } from './NewStockingParameterForm';
import { EditStockingParameterForm } from './EditStockingParameterForm';
import { StockingParameterCustomizable } from './StockingParameterCustomizable';
import * as companyStockingParameterSlice from './companyStockingParameterSlice';
import * as stockingParameterCustomizableSlice from './stockingParameterCustomizableSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const StockingParameters = (props: Props) => {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { companyStockingParameters, isLoadingCompanyStockingParameter } = useSelector((state: Store) => state.companyStockingParameter);

  useEffect(() => {
    dispatch(changeHeader({ title: 'stockingParameter.description' }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(companyStockingParameterSlice.fetchCompanyStockingParameter());
    dispatch(stockingParameterCustomizableSlice.fetchStockingParameterCustomizable());
  }, []);

  const menuItemsStockingOptions = (record: CompanyStockingParameter) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case stockingParameterOptions.EDIT:
              dispatch(companyStockingParameterSlice.setShowEditParameterModal(true));
              dispatch(companyStockingParameterSlice.setCompanyStockingParameter(record));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={stockingParameterOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const columns: ColumnsType<CompanyStockingParameter> = [
    {
      key: 1,
      title: t('stockingParameter.parameter'),
      dataIndex: 'key',
      width: '25%',
    },
    {
      key: 2,
      title: t('stockingParameter.form.type'),
      dataIndex: 'unit',
      width: '20%',
      render: (_, record: CompanyStockingParameter) => {
        switch (record.type) {
          case stockingParameterTypes.CATEGORICAL:
            return t('stockingParameter.form.categorical');

          case stockingParameterTypes.NUMERIC:
          default:
            return t('stockingParameter.form.numeric');
        }
      },
    },
    {
      key: 3,
      title: t('stockingParameter.unit'),
      dataIndex: 'unit',
      width: '20%',
    },
    {
      key: 4,
      title: t('stockingParameter.options'),
      width: '25%',
      render: (_, record: CompanyStockingParameter) => {
        if (!record.options?.length) {
          return '';
        }

        return (
          <div>
            {record.options.map((option, index) => {
              if (record.options && (record.options.length - 1) === index) {
                return option;
              }
              return `${option}, `;
            })}
          </div>
        );
      },
    },
    {
      key: 5,
      width: '5%',
      render: (_, record: CompanyStockingParameter) => {
        return (
          <Dropdown
            overlay={() => menuItemsStockingOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.alignButtonRight}>
        <ActionButton
          id='add_button'
          type='primary'
          icon={<Icon name='add' />}
          onClick={() => {
            dispatch(companyStockingParameterSlice.setShowCreateParameterModal(true));
            dispatch(companyStockingParameterSlice.fetchGlobalStockingParameter());
          }}
        >
          {t('stockingParameter.create')}
        </ActionButton>
      </div>

      <LrvTable
        columns={columns}
        dataSource={companyStockingParameters}
        loading={isLoadingCompanyStockingParameter}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={{
          size: 'default',
        }}
      />

      <StockingParameterCustomizable theme={theme} />
      <NewStockingParameterForm theme='light' />
      <EditStockingParameterForm theme='light' />
    </div>
  );
};
