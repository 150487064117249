import i18next from 'i18next';

import { Company } from '../AppHeader/interfaces';
import { analysisTypes, stockingPhaseTypes } from '../../config/commons';

import { SampleWeight } from './interfaces';

export const getTitleCountAnimalChart = (analysisPhaseType: string) => {
  switch (analysisPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('detail.chart.countPostlarvae');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('detail.chart.countJuveniles');

    case stockingPhaseTypes.ADULT:
      return i18next.t('detail.chart.countShrimps');
  }
};

export const getUnitCountAnimalChart = (stockingPhaseType: string) => {
  switch (stockingPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return 'pl';

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return 'ind';
  }
};

export const getStageColumnTitle = (phaseTypeSelected: string) => {
  switch (phaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('analysis.inputData.stage');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('analysis.inputData.days');
  }
};

export const getPostLarvaeColumnTitle = (phaseTypeSelected: string) => {
  switch (phaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('analysis.resultData.larvaeNumber');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('analysis.resultData.shrimpsNumber');
  }
};

export const getPlgColumnTitle = (phaseTypeSelected: string) => {
  switch (phaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('analysis.resultData.larvaePerGram');

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return i18next.t('analysis.resultData.averageWeight');
  }
};

export const getPlgDataIndex = (phaseTypeSelected: string) => {
  switch (phaseTypeSelected) {
    case stockingPhaseTypes.LARVAE:
    default:
      return 'larvaePerGram';

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return 'averageWeight';
  }
};

export const isConsolidatedAnalysis = (analysisType: string) => {
  return analysisType === analysisTypes.CONSOLIDATED_JUVENILE_ANALYSIS || analysisType === analysisTypes.CONSOLIDATED_ADULT_ANALYSIS || analysisType === analysisTypes.CONSOLIDATED_LARVAE_ANALYSIS;
};

export const getAreaPercentIncrease = (props: { analysisType: string }) => {
  const { analysisType } = props;

  switch (analysisType) {
    case analysisTypes.GENERAL_LARVAE_ANALYSIS:
    case analysisTypes.CONSOLIDATED_LARVAE_ANALYSIS:
    default:
      return 0;

    case analysisTypes.GENERAL_JUVENILE_ANALYSIS:
    case analysisTypes.GENERAL_ADULT_ANALYSIS:
    case analysisTypes.CONSOLIDATED_JUVENILE_ANALYSIS:
    case analysisTypes.CONSOLIDATED_ADULT_ANALYSIS:
      return 10000;
  }
};

export const isJuvenileOrGrowOutTypeAnalysis = (analysisPhaseType: string) => {
  return (
    analysisPhaseType === stockingPhaseTypes.JUVENILE ||
    analysisPhaseType === stockingPhaseTypes.ADULT
  );
};

export const getMinSampleWeight = (sampleWeightLimits: SampleWeight, phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return sampleWeightLimits.larvae.min;
    case stockingPhaseTypes.JUVENILE:
      return sampleWeightLimits.juvenile.min;
    case stockingPhaseTypes.ADULT:
      return sampleWeightLimits.growOut.min;
  }
};

export const getMaxSampleWeight = (sampleWeightLimits: SampleWeight, phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return sampleWeightLimits.larvae.max;
    case stockingPhaseTypes.JUVENILE:
      return sampleWeightLimits.juvenile.max;
    case stockingPhaseTypes.ADULT:
      return sampleWeightLimits.growOut.max;
  }
};

export const getMinStage = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return 1;
    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      return 0;
  }
};

export const getMaxStage = (selectCompany: Company, phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return selectCompany.maxStage;
    case stockingPhaseTypes.JUVENILE:
      return selectCompany.maxDayJuvenile;
    case stockingPhaseTypes.ADULT:
      return selectCompany.maxDayGrowOut;
  }
};
