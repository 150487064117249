import i18next from 'i18next';
import { CONFLICT } from 'http-status-codes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { unitStatuses } from '../../../config/commons';
import { GenericParam } from '../../../common/interfaces/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { ReferenceCurves, ReferenceCurvesDto } from '../../Units/interfaces';
import { getReferenceCurveDataPayload } from '../../../helpers/reference-curve';
import { openErrorNotification, openSuccessNotification } from '../../../common/notification/Notification';
import { CAMPUS_URL, REFERENCE_CURVES_BY_FILTERS_URL, REFERENCE_CURVES_OF_THE_UNITS_URL, REFERENCE_CURVES_URL } from '../../../config/config.api';

import { Campus, UnitsReferenceCurvesState } from './interfaces';

const initialState: UnitsReferenceCurvesState = {
  phaseType: '',
  units: [],
  unitSelected: {} as Campus,
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  showModal: false,
  referenceCurves: [],
  referenceCurveSelected: {
    _id: '',
    campusId: '',
    companyId: '',
    name: '',
    phaseType: '',
    values: [],
    createdAt: '',
    updatedAt: '',
    type: '',
    active: false,
  },
};

export const unitsReferenceCurvesSlice = createSlice({
  initialState,
  name: 'unitsReferenceCurves',
  reducers: {
    setIsLoading: (state: UnitsReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsCreating: (state: UnitsReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setIsUpdating: (state: UnitsReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setReferenceCurves: (state: UnitsReferenceCurvesState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves = action.payload;
    },
    setSelectedReferenceCurve: (state: UnitsReferenceCurvesState, action: PayloadAction<ReferenceCurves>) => {
      state.referenceCurveSelected = action.payload;
    },
    setPhaseType: (state: UnitsReferenceCurvesState, action: PayloadAction<string>) => {
      state.phaseType = action.payload;
    },
    setSelectedUnit: (state: UnitsReferenceCurvesState, action: PayloadAction<Campus>) => {
      state.unitSelected = action.payload;
    },
    setUnits: (state: UnitsReferenceCurvesState, action: PayloadAction<Campus[]>) => {
      state.units = action.payload;
    },
    setShowModal: (state: UnitsReferenceCurvesState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

export const { setIsLoading, setIsCreating, setIsUpdating, setReferenceCurves, setSelectedReferenceCurve, setPhaseType, setSelectedUnit, setUnits, setShowModal } = unitsReferenceCurvesSlice.actions;

export default unitsReferenceCurvesSlice.reducer;

export const fetchAllUnitsReferenceCurves = (params: { companyId: string }) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_OF_THE_UNITS_URL, { params });
    dispatch(setReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
    const code = error?.response?.data?.code;

    if (code === CONFLICT) {
      openErrorNotification(i18next.t('references.error.unitMaxDocs'));
      dispatch(setIsCreating(false));
      return;
    }

    openErrorNotification(i18next.t('references.error.unknownError'));
  }

  dispatch(setIsLoading(false));
};

export const fetchUnitsReferenceCurves = (props: { companyId: string; campusId: string; phaseType: string }) => async (dispatch: Function) => {
  const { campusId, companyId, phaseType } = props;
  dispatch(setIsLoading(true));

  const params: GenericParam = {
    campusId,
    companyId,
    phaseType: phaseType || undefined,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
    const code = error?.response?.data?.code;
    if (code === CONFLICT) {
      openErrorNotification(i18next.t('references.error.unitMaxDocs'));
      dispatch(setIsCreating(false));
      return;
    }
    openErrorNotification(i18next.t('references.error.unknownError'));
  }

  dispatch(setIsLoading(false));
};

export const fetchUnits = (companyId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    companyId: companyId,
    active: true,
    'status[$in]': [unitStatuses.ACTIVE],
    '$sort[name]': 1,
    $select: ['name', 'status']
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const createUnitReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; phaseType: string }) => async (dispatch: Function) => {
  const { referenceCurve, phaseType } = props;

  dispatch(setIsCreating(true));
  const data = getReferenceCurveDataPayload(referenceCurve);

  try {
    await axios.post(REFERENCE_CURVES_URL, data);
    dispatch(setShowModal(false));
    openSuccessNotification(i18next.t('referenceCurve.created'));
    dispatch(fetchUnitsReferenceCurves({ companyId: data.companyId, campusId: data.campusId ?? '', phaseType }));
  } catch (error) {
    console.log(error?.response);
  }
  dispatch(setIsCreating(false));
};

export const updateUnitReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; fetchAllUnits?: boolean; phaseType: string; }) => async (dispatch: Function) => {
  const { referenceCurve, fetchAllUnits, phaseType } = props;

  dispatch(setIsUpdating(true));
  const url = `${REFERENCE_CURVES_URL}/${referenceCurve._id}`;
  const data = getReferenceCurveDataPayload(referenceCurve);

  try {
    await axios.patch(url, data);
    openSuccessNotification(i18next.t('referenceCurve.updated'));

    if (fetchAllUnits) {
      dispatch(fetchAllUnitsReferenceCurves({ companyId: data.companyId }));
    } else {
      dispatch(fetchUnitsReferenceCurves({ companyId: data.companyId, campusId: data?.campusId || '', phaseType }));
    }

    dispatch(setSelectedReferenceCurve({} as ReferenceCurves));
    dispatch(setShowModal(false));
  } catch (e) {
    console.log(e?.response);
  }
  dispatch(setIsUpdating(false));
};

export const enableReferenceCurve = (props: { referenceCurve: ReferenceCurvesDto; fetchAllUnits: boolean; phaseType: string; }) => async (dispatch: Function) => {
  const { referenceCurve, fetchAllUnits, phaseType } = props;

  const url = `${REFERENCE_CURVES_URL}/${referenceCurve._id}`;
  const data = {
    active: referenceCurve.active,
  };

  try {
    await axios.patch(url, data);
    openSuccessNotification(i18next.t('referenceCurve.updated'));

    if (fetchAllUnits) {
      dispatch(fetchAllUnitsReferenceCurves({ companyId: referenceCurve.companyId }));
    } else {
      dispatch(fetchUnitsReferenceCurves({ companyId: referenceCurve.companyId, campusId: referenceCurve?.campusId || '', phaseType }));
    }
    dispatch(setSelectedReferenceCurve({} as ReferenceCurves));
  } catch (e) {
    console.log(e?.response);
  }
};
