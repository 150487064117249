import moment from 'moment';

import { analysisStatuses, DEFAULT_DAYS_TO_INITIAL_STAGE } from '../config/commons';
import { Analysis } from '../pages/Analysis/interfaces';
import { Company } from '../pages/AppHeader/interfaces';

export const canBePublishedStocking = (analysis: Analysis[]) => {
  const completeAnalysis = analysis.filter((item) => item.status === analysisStatuses.COMPLETED && !item.hidden);
  const amountAnalysis = completeAnalysis.length;

  if (amountAnalysis < 3) {
    return false;
  }

  let sameDay = 0;
  for (let index = 0; index < completeAnalysis.length - 1; index++) {
    const currentAnalysis = completeAnalysis[index];
    const nextAnalysis = completeAnalysis[index + 1];

    const currentDate = moment(currentAnalysis.createdAt).startOf('day');
    const nextDate = moment(nextAnalysis.createdAt).startOf('day');

    const daysDifference = nextDate.diff(currentDate, 'day');
    if (daysDifference === 0) {
      sameDay++;
    }
  }

  if (completeAnalysis.length - sameDay < 3) {
    return false;
  }

  return true;
};

export const hasSameAnalysis = (array1: string[], array2: string[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  return true;
};

export const getDaysToInitialStage = (props: { daysToInitialStage?: number; company: Company }) => {
  const { daysToInitialStage, company } = props;
  return daysToInitialStage || (company.daysToInitialStage || DEFAULT_DAYS_TO_INITIAL_STAGE);
};
