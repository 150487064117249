import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';

import './i18n';
import './index.scss';
import App from './App';
import { store } from './state/store';
import Icon from './common/components/Icon/Icon';
import * as serviceWorker from './serviceWorker';
import MouseTooltip from './common/components/TooltipFollow/MouseTooltip';
import { rollbarConfig } from './config/rollbar';

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <MouseTooltip offsetX={20} offsetY={10} children={<Icon name='spy' className='spyIcon' />} />
        <App />
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
