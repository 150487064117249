import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../state/store.interfaces';
import larviaLogo from '../../../assets/powered-by.png';

import styles from './Header.module.scss';

interface Props {
  companyName: string;
  stockingBindingCode?: string;
}

export default function Header ({ companyName, stockingBindingCode }: Props) {
  const [t] = useTranslation();

  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detailAnalysis);

  const getUnitName = () => {
    const campusName = analysisOriginal.campusName?.toLowerCase();
    if (!campusName) {
      return null;
    }

    return ' - ' + t('analysis.campus') + ' ' + campusName.charAt(0).toUpperCase() + campusName.slice(1);
  };

  const getModuleAndTankName = () => {
    const stocking = analysisOriginal.sowingId;
    if (!stocking?.moduleName || !stocking?.tankName) {
      if (!stockingBindingCode) {
        return `${t('detail.pdf.sample')} ${analysisOriginal.code}`;
      }

      return `${t('detail.pdf.sample')} ${analysisOriginal.code} - ${t('stockings.pdf.stockingBindingCode')} `;
    }

    const moduleName = stocking.moduleName.charAt(0).toUpperCase() + stocking.moduleName.slice(1);
    const tankName = stocking.tankName.charAt(0).toUpperCase() + stocking.tankName.slice(1);

    return t('campus.module') + ' ' + moduleName + ' - ' + t('campus.tank') + ' ' + tankName + ' - ' + t('stockings.productionCycle') + ' ' + stocking.code;
  };

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <div className={styles.company}>
          {companyName} {getUnitName()}
        </div>

        <div className={styles.row}>
          <div className={styles.code}>
            {getModuleAndTankName()}
          </div>
          {
            stockingBindingCode &&
            <div className={cx(styles.code, styles.stockingBindingCode)}>
              &nbsp;{stockingBindingCode}
            </div>
          }
        </div>
      </div>

      <div className={styles.right}>
        <img src={larviaLogo} alt='larvia' />
      </div>
    </div>
  );
}
