import React from 'react';
import cx from 'classnames';

import styles from './TinyLabel.module.scss';

interface Props {
  text: string;
  className?: string;
}

export default function TinyLabel (props: Props) {
  const { text, className } = props;

  return (
    <div className={cx(styles.tinyLabel, className)}>
      {text}
    </div>
  );
}
