import React from 'react';
import { Tag } from 'antd';
import cx from 'classnames';

import { LrvTagProps } from '../../interfaces/lrvTag';

import styles from './LrvTag.module.scss';

export const LrvTag = (props: LrvTagProps) => {
  const { className, type = 'default', children, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const getStyles = () => {
    switch (type) {
      case 'active':
        return styles.active;

      case 'error':
        return styles.error;

      case 'info':
        return styles.info;

      case 'transfer':
        return styles.transfer;

      default:
        return styles.default;
    }
  };

  const lrvTagProps = {
    ...props,
    className: cx(styles.tag, isDarkTheme ? styles.tagDark : styles.tagLight, className, getStyles()),
  };

  return (
    <Tag {...lrvTagProps}>
      {children}
    </Tag>
  );
};
