import { useSelector } from 'react-redux';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { getCurrentTheme } from '../../../helpers/theme';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';

import styles from './LegendNormal.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export const LegendNormal = (props: Props) => {
  const { theme = getCurrentTheme() } = props;

  const {
    parameter,
  } = useSelector((state: Store) => state.activeTanks);
  const { phaseType } = useSelector((state: Store) => state.header);

  const isLightTheme = theme === THEME.LIGHT;

  const renderParameter = () => {
    if (!parameter) {
      return;
    }

    const value = getParameter({ parameter, stockingPhaseType: phaseType });

    return (
      <div className={styles.labelAxisY}>
        <LrvText theme={theme} className={isLightTheme ? styles.lightText : styles.darkText} text={value} />
      </div>
    );
  };

  return (
    <div className={styles.containerNormalLegend}>
      <div className={styles.normalLegend}>
        {renderParameter()}
      </div>
    </div>
  );
};