import { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { changeHeader } from '../../AppHeader/headerSlice';
import { filterOptionSelect } from '../../../utils/select';
import { formatLongDateWithOffset } from '../../../utils/date';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { analysisPatch, unitStatuses } from '../../../config/commons';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { ExtraActionsButton } from '../../../common/components/buttons/ExtraActionsButton';

import { Analysis, Campus } from './interfaces';
import styles from './ArchivedAnalysis.module.scss';
import * as archivedAnalysisSlice from './archivedAnalysisSlice';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function ArchivedAnalysis (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    analysis,
    campuses,
    currentPage,
    isLoading,
    limit,
    total,
  } = useSelector((state: Store) => state.archivedAnalysis);
  const { company: companySelected, phaseType } = useSelector((state: Store) => state.header);

  const [campus, setCampus] = useState<Campus | undefined>(undefined);

  useEffect(() => {
    dispatch(changeHeader({ title: 'archive.header' }));
  }, [dispatch]);

  useEffect(() => {
    if (!companySelected._id) {
      return;
    }

    dispatch(archivedAnalysisSlice.fetchCampuses(companySelected._id));
  }, [dispatch, companySelected._id]);

  useEffect(() => {
    if (!companySelected._id) {
      return;
    }

    setCampus(undefined);
    dispatch(archivedAnalysisSlice.fetchArchivedAnalysis({ page: 1, companyId: companySelected._id, phaseType }));
  }, [dispatch, companySelected._id, phaseType]);

  const onChangePage = (page: number) => {
    dispatch(archivedAnalysisSlice.setCurrentPage(page));
    dispatch(archivedAnalysisSlice.fetchArchivedAnalysis({ page, companyId: companySelected._id, phaseType }));
  };

  const menuItemAnalysisOption = (record: Analysis) => {
    return (
      // eslint-disable-next-line
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          if (key === analysisPatch.UNARCHIVE) {
            showConfirmArchiveAnalysis(record);
          }
        }}
      >
        <Menu.Item id='menu_option_unarchive' key={analysisPatch.UNARCHIVE} className={styles.menuItemOptions}>
          <Icon name='inbox-unarchive' type='line' theme={theme} className={styles.icon} />
          <span>{t('archive.unarchive')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const showConfirmArchiveAnalysis = (record: Analysis) => {
    const title = t('archive.unarchiveConfirm.title');
    const content = t('archive.unarchiveConfirm.description');
    const idOkButton = 'submit_unarchive';
    const idCancelButton = 'cancel_unarchive';

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: content,
      okText: t('archive.accept').toUpperCase(),
      cancelText: t('archive.cancel').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        dispatch(archivedAnalysisSlice.unarchiveAnalysis({ analysisId: record._id, currentPage, companyId: companySelected._id, phaseType }));
      },
    });
  };

  const columns: ColumnsType<Analysis> = [
    {
      key: 1,
      title: t('archive.table.code'),
      dataIndex: 'code',
      width: '30%',
    },
    {
      key: 2,
      title: t('archive.table.sampleWeight'),
      dataIndex: ['inputData', 'sampleWeight'],
      width: '18%',
      responsive: ['md'] as Breakpoint[],
      render: (value: string) => `${value} g`,
    },
    {
      key: 3,
      title: t('archive.table.stage'),
      dataIndex: ['inputData', 'stage'],
      width: '13%',
      responsive: ['sm'] as Breakpoint[],
    },
    {
      key: 4,
      title: t('archive.table.animals'),
      dataIndex: ['resultData', 'larvaeNumber'],
      width: '15%',
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 5,
      title: t('archive.table.date'),
      dataIndex: 'createdAt',
      width: '18%',
      responsive: ['sm'] as Breakpoint[],
      render: (value: string) => formatLongDateWithOffset(value),
    },
    {
      key: 6,
      width: '5%',
      render: (_, record: Analysis) => {
        return (
          <Dropdown
            overlay={() => menuItemAnalysisOption(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <ExtraActionsButton
              id={`button_${record.code}`}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        );
      },
    }
  ];

  const onChangeCampus = (value: string) => {
    const campus = campuses.find((item) => item._id === value);

    if (!campus?._id) {
      return;
    }

    setCampus(campus);
    dispatch(archivedAnalysisSlice.fetchArchivedAnalysis({ page: currentPage, companyId: companySelected._id, phaseType, campusId: campus._id }));
  };

  const renderCampusesDropdown = () => {
    return (
      <LrvSelect
        id='dropdown_campuses'
        showSearch
        theme={theme}
        className={styles.select}
        value={campus?.name || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        disabled={!phaseType}
        dropdownMatchSelectWidth={false}
        title={t('campus.campus')}
        placeholder={t('archive.selectUnit')}
        onChange={onChangeCampus}
        filterOption={filterOptionSelect}
      >
        {campuses.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.rowHeader}>
        {renderCampusesDropdown()}
      </Row>
    );
  };

  return (
    <div className={styles.archivedAnalysis} >
      {renderSubHeader()}

      <Row className={styles.rowFlex} >
        <LrvTable
          columns={columns}
          className={styles.table}
          rowClassName={styles.analysisRow}
          dataSource={analysis}
          loading={isLoading}
          scroll={{ y: '' }}
          size='small'
          theme={theme}
          pagination={{
            size: 'default',
            defaultPageSize: limit,
            pageSize: limit,
            total: total,
            onChange: onChangePage,
            showSizeChanger: false,
            current: currentPage,
          }}
          onRow={(record: Analysis) => {
            return {
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/archive/analysis/${record._id}`);
              }
            };
          }}
        />
      </Row>
    </div>
  );
}
