import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Payment } from '../interfaces';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { typeErrorRuleAws, typeErrorPayment, bucketName, methodPresignedUrl, plansTypes } from '../../../config/commons';
import { GENERATE_PRESIGNED_URL, PAYMENTS_URL } from '../../../config/config.api';
import { openSuccessNotification, openErrorNotification } from '../../../common/notification/Notification';

import { DetailPaymentState } from './interfaces';

const initialState: DetailPaymentState = {
  isLoading: false,
  isLoadingReject: false,
  isLoadingApprove: false,
  showRejectModal: false,
  payment: {
    _id: '',
    amount: 0,
    orderId: '',
    transactionId: '',
    companyPayerId: '',
    companyName: '',
    userPayerId: '',
    userPayer: {
      email: '',
      firstName: '',
      lastName: '',
    },
    type: '',
    paymentDate: '',
    comment: '',
    image: {
      key: '',
      url: '',
    },
    quota: 0,
    status: '',
    isTransfer: false,
    createdAt: '',
    updatedAt: '',
  },
};

export const detailPaymenttSlice = createSlice({
  name: 'detailPayment',
  initialState,
  reducers: {
    setPayment: (state: DetailPaymentState, action: PayloadAction<Payment>) => {
      state.payment._id = action.payload._id;
      state.payment.image = action.payload.image;
      state.payment.amount = action.payload.amount;
      state.payment.quota = action.payload.quota;
      state.payment.userPayerId = action.payload.userPayerId;
      state.payment.companyPayerId = action.payload.companyPayerId;
      state.payment.companyName = action.payload.companyName;
      state.payment.userPayer = action.payload.userPayer;
      state.payment.paymentDate = action.payload.paymentDate;
      state.payment.comment = action.payload.comment;
      state.payment.type = action.payload.type;
      state.payment.status = action.payload.status;
      state.payment.isTransfer = action.payload.isTransfer;
      state.payment.image = action.payload.image;
      state.payment.createdAt = action.payload.createdAt;
      state.payment.updatedAt = action.payload.updatedAt;
    },

    setIsLoading: (state: DetailPaymentState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    setShowRejectModal: (state: DetailPaymentState, action: PayloadAction<boolean>) => {
      state.showRejectModal = action.payload;
    },

    setIsLoadingReject: (state: DetailPaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingReject = action.payload;
    },

    setIsLoadingApprove: (state: DetailPaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingApprove = action.payload;
    },

  },
});

export const { setIsLoading, setPayment, setIsLoadingReject, setShowRejectModal, setIsLoadingApprove } = detailPaymenttSlice.actions;

export const fetchPayment = (id: string) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<Payment>(`${PAYMENTS_URL}/${id}`);

    if (!response.data.isTransfer || !response.data.image?.key) {
      dispatch(setIsLoading(false));
      dispatch(setPayment(response.data));
      return;
    }

    const body = {
      'objectKey': response.data.image?.key,
      'bucketName': bucketName.PREPAID_VOUCHERS,
      'methodPresignedUrl': methodPresignedUrl.GET,
    };

    const presignedURL = await axios.post(GENERATE_PRESIGNED_URL, body);

    if (response.data.image) {
      response.data.image.url = presignedURL.data;
    }

    dispatch(setPayment(response.data));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};

export const rejectPayment = (props: { paymentId: string; paymentType: string; comment: string; }) => async (dispatch: Function) => {
  const { paymentId, paymentType, comment } = props;
  dispatch(setIsLoadingReject(true));

  const body = {
    paymentId,
    comment,
  };

  let url = '';

  switch (paymentType) {
    case plansTypes.PREPAID:
      url = `${PAYMENTS_URL}/reject/transfer`;
      break;

    case plansTypes.POSTPAID:
      url = `${PAYMENTS_URL}/postpaid/transfer/reject`;
      break;
  }

  try {
    await axios.post(url, body);
    dispatch(fetchPayment(paymentId));
    dispatch(setShowRejectModal(false));
    openSuccessNotification(i18next.t('payments.detail.rejected'));
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.data?.data) {
      showErrorPayment(error?.response?.data?.data?.error);
    }
  }

  dispatch(setIsLoadingReject(false));
};

export const approvePayment = (props: { paymentId: string; paymentType: string; }) => async (dispatch: Function) => {
  const { paymentId, paymentType } = props;
  const body = { paymentId };
  dispatch(setIsLoadingApprove(true));

  let url = '';

  switch (paymentType) {
    case plansTypes.PREPAID:
      url = `${PAYMENTS_URL}/approve/transfer`;
      break;

    case plansTypes.POSTPAID:
      url = `${PAYMENTS_URL}/postpaid/transfer/approve`;
      break;
  }

  try {
    await axios.post(url, body);
    dispatch(fetchPayment(paymentId));
    openSuccessNotification(i18next.t('payments.detail.approved'));
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.data?.data) {
      showErrorPayment(error?.response?.data?.data?.error);
    }
  }
  dispatch(setIsLoadingApprove(false));
};

const showErrorPayment = (error: string) => {
  switch (error) {
    case typeErrorRuleAws.CREATE_RULE_IN_AWS:
    case typeErrorRuleAws.CREATE_TARGET_IN_AWS:
    case typeErrorRuleAws.ADD_PERMISSION_IN_AWS:
    case typeErrorRuleAws.REMOVE_RULE_IN_AWS:
      openErrorNotification(i18next.t('payments.error'));
      break;

    case typeErrorPayment.PREPAID_BALANCE_BEING_USED:
      openErrorNotification(i18next.t('payments.prepaidBalanceBeingUsed'));
      break;

    case typeErrorPayment.POSTPAID_BALANCE_AVAILABLE:
      openErrorNotification(i18next.t('payments.postpaidBalanceAvailable'));
      break;

    case typeErrorPayment.BONUS_BALANCE_AVAILABLE:
      openErrorNotification(i18next.t('payments.bonusQuotaAvailable'));
      break;

    case typeErrorPayment.PREPAID_BALANCE_AVAILABLE:
      openErrorNotification(i18next.t('payments.prepaidBalanceAvailable'));
      break;

    case typeErrorPayment.PREPAID_BALANCE_NOT_AVAILABLE:
      openErrorNotification(i18next.t('payments.prepaidBalanceNotAvailable'));
      break;

    case typeErrorPayment.TRANSFER_ALREADY_REGISTERED:
      openErrorNotification(i18next.t('payments.transferAlreadyRegistered'));
      break;
  }
};

export default detailPaymenttSlice.reducer;
