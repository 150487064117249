import React from 'react';
import { capitalize } from 'lodash';
import { Row, Space, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { filterOptionSelect } from '../../../utils/select';
import { DropdownProps } from '../../../common/interfaces/commons';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './Subheader.module.scss';
import * as parameterStateSlice from './parameterStateSlice';
import { existsMinimumDataParameters } from './helpers';

const { Option } = Select;

export const Subheader = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const {
    units,
    modules,
    unitId,
    moduleId,
    selectedReferenceCurve,
    globalReferenceCurves,
    companyReferenceCurves,
    unitReferenceCurves,
    selectedParameterName,
    selectedDateOption,
    isDownloadingFile,
    stockingParameterGraphs,
  } = useSelector((state: Store) => state.parameterState);

  const onChangeUnit = (value: string) => {
    dispatch(parameterStateSlice.setSelectedUnitId(value));
    dispatch(parameterStateSlice.setSelectedModuleId(undefined));
    dispatch(parameterStateSlice.setStockingParameterGraphs([]));

    dispatch(parameterStateSlice.fetchModules({ campusId: value, phaseType }));
    dispatch(parameterStateSlice.fetchUnitsReferenceCurves({ campusId: value, companyId: selectedCompany._id, phaseType }));
  };

  const onChangeModule = (value: string) => {
    if (!unitId) {
      return;
    }

    dispatch(parameterStateSlice.setSelectedModuleId(value));

    const params = {
      campusId: unitId,
      companyId: selectedCompany._id,
      moduleId: value,
      phaseType,
    };
    dispatch(parameterStateSlice.fetchStockingParameterGraphs(params));
  };

  const onChangeReference = (value: string) => {
    const referenceCurves = [...globalReferenceCurves, ...unitReferenceCurves, ...companyReferenceCurves];
    const refCurve = referenceCurves.find(item => item._id === value);

    if (refCurve) {
      dispatch(parameterStateSlice.setSelectedReferenceCurve(refCurve));
    }
  };

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unitId || undefined}
        onChange={onChangeUnit}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        onChange={onChangeModule}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        dropdownMatchSelectWidth={false}
        showSearch
        filterOption={filterOptionSelect}
        disabled={!unitId}
      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderGlobalRefOptions = () => {
    return globalReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
          label={item.name}
        >
          {capitalize(item.name)}&nbsp;
          <Icon name='global' />
        </Select.Option>
      );
    });
  };

  const renderCompanyRefOptions = () => {
    return companyReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderUnitRefOptions = () => {
    return unitReferenceCurves.map((item) => {
      return (
        <Select.Option
          key={item._id}
          value={item._id}
        >
          {item.name}
        </Select.Option>
      );
    });
  };

  const renderReferenceDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='reference_radio_group'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={selectedReferenceCurve?._id}
        showSearch
        title={t('production.filters.reference')}
        placeholder={t('production.filters.reference')}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
        disabled={!moduleId || selectedParameterName !== 'averageWeight'}
        onChange={onChangeReference}
      >
        {renderGlobalRefOptions()}
        {renderCompanyRefOptions()}
        {renderUnitRefOptions()}
      </LrvSelect>
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('balances.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(parameterStateSlice.resetFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderUnitsDropdown({ theme: 'light' })}
            {renderModulesDropdown({ theme: 'light' })}
            {renderReferenceDropdown({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => {
          dispatch(parameterStateSlice.resetFilters());
        }}
      />
    );
  };

  const disabledButtonGeneratePdf = () => {
    const existsMinimumData = existsMinimumDataParameters({ stockingParameterGraphs, selectedParameterName });
    return !existsMinimumData;
  };

  const renderButtonGeneratePdf = () => {
    return (
      <IconButton
        className={styles.generatePdf}
        loading={isDownloadingFile}
        onClick={generatePdf}
        iconName='download'
        disabled={disabledButtonGeneratePdf()}
        placement='left'
        tooltipText={t('shadedplot.generate')}
      />
    );
  };

  const generatePdf = () => {
    if (!unitId || !moduleId || !selectedReferenceCurve?._id) {
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      parameter: selectedParameterName,
      phaseType,
      companyName: selectedCompany.name,
      dateOption: selectedDateOption,
      unitId,
      moduleId,
      referenceId: selectedReferenceCurve?._id,
    };

    dispatch(parameterStateSlice.fetchUrlParameterStatePdf(params));
  };

  return (
    <>
      <Row className={styles.container}>
        <Space className={styles.filters} align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderModulesDropdown({ className: styles.select, theme })}
          {renderReferenceDropdown({ className: styles.select, theme })}
          {renderCleanButton()}
        </Space>
        {renderSidePanel()}

        <Row className={styles.options}>
          {renderButtonGeneratePdf()}
        </Row>
      </Row>
    </>
  );
};
