import * as d3 from 'd3';
import i18next from 'i18next';

import { bot } from '../../config/commons';
import { formatMonth, formatLocaleHour } from '../../utils/date';

import styles from './ChartBalance.module.scss';
import { Balance, LogUserBalance } from './interfaces';

class Data {
  index: number;
  quotaType: string;
  quotaAmount: number;
  quotaPrevious: number;
  bonusQuotaType: string;
  bonusQuotaAmount: number;
  bonusQuotaPrevious: number;
  date: string;
  hour: string;
  type: string;
  creatorUserFirstName: string;
  creatorUserLastName: string;
  availableQuota: number | undefined;

  // eslint-disable-next-line
  constructor(params: {
    index: number; date: string; hour: string; type: string;
    quotaAmount: number; quotaPrevious: number; quotaType: string;
    bonusQuotaPrevious: number; bonusQuotaAmount: number; bonusQuotaType: string;
    creatorUserFirstName: string; creatorUserLastName: string; availableQuota?: number;
  }) {
    const { index, date, hour, type, quotaAmount, quotaPrevious, quotaType, bonusQuotaPrevious, bonusQuotaAmount, bonusQuotaType, creatorUserFirstName, creatorUserLastName, availableQuota } = params;
    this.index = index;
    this.quotaType = quotaType;
    this.quotaAmount = quotaAmount;
    this.quotaPrevious = quotaPrevious;
    this.bonusQuotaType = bonusQuotaType;
    this.bonusQuotaAmount = bonusQuotaAmount;
    this.bonusQuotaPrevious = bonusQuotaPrevious;
    this.date = date;
    this.hour = hour;
    this.type = type;
    this.creatorUserFirstName = creatorUserFirstName;
    this.creatorUserLastName = creatorUserLastName;
    this.availableQuota = availableQuota;
  }
}

class CrossedLines {
  x1: number;
  y1: number;
  x2: number;
  y2: number;

  // eslint-disable-next-line
  constructor(x1: number, y1: number, x2: number, y2: number) {
    this.x1 = x1;
    this.x2 = x2;
    this.y1 = y1;
    this.y2 = y2;
  }
}

let currentBalance = '';
const leftIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18"><path fill="none"/><path d="M14 12l-4 4V8z"/></svg>';
const fundsIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="royalblue" d="M4.406 14.523l3.402-3.402 2.828 2.829 3.157-3.157L12 9h5v5l-1.793-1.793-4.571 4.571-2.828-2.828-2.475 2.474a8 8 0 1 0-.927-1.9zm-1.538 1.558l-.01-.01.004-.004A9.965 9.965 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10c-4.07 0-7.57-2.43-9.132-5.919z"/></svg>';

const balanceType = {
  ACCREDITATION: 'ACCREDITATION',
  DEBIT: 'DEBIT'
};

const typeBalanceManagement = {
  PREPAID: 'PREPAID',
  POSTPAID: 'POSTPAID',
  BONUS_QUOTA: 'BONUS_QUOTA',
};

export default class ChartBalance {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined>;
  logsBalances: LogUserBalance[];
  lastBalance: Balance;

  margin = { top: 30, right: 50, bottom: 32, left: 50 };
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));

  width: number;
  height: number;

  xScale: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  yScale: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  data: Data[] = [];
  crossedLines: CrossedLines[] = [];

  timeTransition = 300;
  barWidth = 100;
  axisX: d3.Axis<d3.NumberValue> = d3.axisBottom(this.xScale);

  // eslint-disable-next-line
  constructor(props: { container: HTMLDivElement | null; width: number; height: number; logsBalances: LogUserBalance[]; lastBalance: Balance }) {
    const { container, lastBalance, logsBalances, height, width } = props;

    this.container = container;
    this.logsBalances = logsBalances;
    this.lastBalance = lastBalance;

    const total = logsBalances.length + (lastBalance ? 1 : 0);
    const newWidth = total > 5 ? ((total * this.barWidth) - this.margin.left - this.margin.right) : (width - this.margin.left - this.margin.right);
    this.width = newWidth;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#tooltip').remove();
    d3.select(container).select('svg').remove();
    d3.select(container).selectAll('.textQuota').remove();
    d3.select(container).selectAll('.crossedLines').remove();
    d3.select(container).selectAll('.lines').remove();
    d3.select(container).selectAll('.points').remove();

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    this.svg = d3.select(container)
      .append('svg')
      .attr('width', _width)
      .attr('height', _height)
      .append('g')
      .attr('id', 'contentTooltip')
      .attr('transform', `translate( ${this.margin.left} , ${this.margin.top} )`);

    this.updateDataPoints();
    this.renderTooltips();
  }

  getY1 (logBalance: LogUserBalance) {
    if (logBalance.quotaType) {
      return logBalance.quotaPrevious + (logBalance.quotaType === balanceType.DEBIT ? - logBalance.quotaAmount : logBalance.quotaAmount);
    }

    if (logBalance.bonusQuotaType) {
      return logBalance.bonusQuotaPrevious + (logBalance.bonusQuotaType === balanceType.DEBIT ? - logBalance.bonusQuotaAmount : logBalance.bonusQuotaAmount);
    }

    return 0;
  }

  getY2 (logBalance: LogUserBalance) {
    if (logBalance.quotaType) {
      return logBalance.quotaPrevious;
    }

    if (logBalance.bonusQuotaType) {
      return logBalance.bonusQuotaPrevious;
    }

    return 0;
  }

  getBalance (data: Data) {
    if (data.quotaType) {
      return data.quotaPrevious + (data.quotaType === balanceType.DEBIT ? - data.quotaAmount : data.quotaAmount);
    }

    if (data.bonusQuotaType) {
      return data.bonusQuotaPrevious + (data.bonusQuotaType === balanceType.DEBIT ? - data.bonusQuotaAmount : data.bonusQuotaAmount);
    }

    return 0;
  }

  getQuotaAmount (data: Data) {
    if (data.quotaType) {
      return data.quotaAmount;
    }

    if (data.bonusQuotaType) {
      return data.bonusQuotaAmount;
    }

    return 0;
  }

  getQuotaPrevious (data: Data) {
    if (data.quotaType) {
      return data.quotaPrevious;
    }

    if (data.bonusQuotaType) {
      return data.bonusQuotaPrevious;
    }

    return 0;
  }

  getQuotaType (data: Data) {
    if (data.quotaType) {
      return data.quotaType;
    }

    if (data.bonusQuotaType) {
      return data.bonusQuotaType;
    }

    return '';
  }

  getAnalysisType (data: Data) {
    switch (data.type) {
      case typeBalanceManagement.PREPAID:
        return i18next.t('users.balance.prepaidAnalysis');

      case typeBalanceManagement.POSTPAID:
        return i18next.t('users.balance.postpaidAnalysis');

      case typeBalanceManagement.BONUS_QUOTA:
        return i18next.t('users.balance.bonusAnalysis');

      default:
        return i18next.t('users.balance.postpaidAnalysis');
    }
  }

  fillData () {
    const { lastBalance } = this;

    const dataList: Data[] = [];
    const lines: CrossedLines[] = [];
    for (let i = 0; i < this.logsBalances.length; ++i) {
      const logBalance = this.logsBalances[i];
      const params = {
        index: i,
        date: formatMonth(logBalance.createdAt),
        hour: formatLocaleHour(logBalance.createdAt),
        quotaAmount: logBalance.quotaAmount,
        quotaPrevious: logBalance.quotaPrevious,
        quotaType: logBalance.quotaType,
        bonusQuotaAmount: logBalance.bonusQuotaAmount,
        bonusQuotaPrevious: logBalance.bonusQuotaPrevious,
        bonusQuotaType: logBalance.bonusQuotaType,
        type: logBalance.type,
        creatorUserFirstName: logBalance.creatorUserId?._id ? logBalance.creatorUserId.firstName : bot.name,
        creatorUserLastName: logBalance.creatorUserId?._id ? logBalance.creatorUserId.lastName : bot.lastName,
      };
      const data: Data = new Data(params);
      dataList.push(data);

      if (i + 1 !== this.logsBalances.length) {
        const logBalanceNext = this.logsBalances[i + 1];
        const x1 = i;
        const x2 = i + 1;
        const y1 = this.getY1(logBalance);
        const y2 = this.getY2(logBalanceNext);
        const line = new CrossedLines(x1, y1, x2, y2);
        lines.push(line);
      }
    }

    if (lastBalance) {
      const lastLogBalance = this.logsBalances[dataList.length - 1];
      const x1 = dataList.length - 1;
      const x2 = dataList.length;
      const y1 = this.getY1(lastLogBalance);
      const y2 = lastBalance.availableQuota;

      const line = new CrossedLines(x1, y1, x2, y2);
      lines.push(line);

      const date = new Date(lastBalance.updatedAt);
      const currentDate = new Date();
      const showLabelNow = date.getMonth() + 1 === currentDate.getMonth() + 1 && date.getFullYear() === currentDate.getFullYear();

      const params = {
        index: dataList.length,
        date: showLabelNow ? i18next.t('users.balance.now') : formatMonth(lastBalance.updatedAt),
        hour: showLabelNow ? '' : formatLocaleHour(lastBalance.updatedAt),
        availableQuota: lastBalance.availableQuota,
        quotaAmount: 0,
        quotaPrevious: 0,
        quotaType: '',
        bonusQuotaAmount: 0,
        bonusQuotaPrevious: 0,
        bonusQuotaType: '',
        type: '',
        creatorUserFirstName: '',
        creatorUserLastName: '',
      };

      const data: Data = new Data(params);
      dataList.push(data);
    }

    this.crossedLines = lines;
    this.data = dataList;
  }

  updateDataPoints = () => {
    this.fillData();
    this.buildAxisX();
    this.buildHours();
    this.buildAxisY();

    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
      });

    this.renderCrossedLines();
    this.renderLines();
    this.renderPoints();
    this.renderQuota();
  };

  buildAxisX () {
    const { svg, width, height, data, timeTransition } = this;
    const lengthData = data.length - 1;

    this.xScale = d3.scaleLinear()
      .domain([0, lengthData])
      .range([0, width]);

    this.axisX = d3.axisBottom(this.xScale)
      .tickFormat((x) => {
        const index = Number(x.toString());
        return data[index]?.date.toString();
      })
      .ticks(lengthData === 0 ? 1 : lengthData)
      .tickSize(-height)
      .tickPadding(8);

    svg.append('g')
      .transition()
      .duration(timeTransition)
      .attr('class', styles.axisX)
      .attr('transform', `translate(0, ${height})`)
      .call(this.axisX);
  }

  buildHours () {
    const { svg, height, data, timeTransition, xScale } = this;

    const hours = data.map((d: Data) => d.hour);
    const labels = svg.selectAll<SVGTextElement, string>('.hours').data(hours);

    labels.enter()
      .append('text')
      .merge(labels)
      .transition()
      .duration(timeTransition)
      .attr('class', styles.hours)
      .attr('x', (d: string, i: number) => xScale(i))
      .attr('y', height + 32)
      .style('text-anchor', 'middle')
      .text((d: string) => d);

    labels.exit().remove();
  }

  buildAxisY () {
    const { height, svg, timeTransition } = this;

    const maxDomain = this.getValueMaxY();
    this.yScale = d3.scaleLinear()
      .domain([0, maxDomain])
      .range([height, 0]);

    const axis = d3.axisLeft(this.yScale)
      .tickFormat(function (d) {
        return d3.format('')(d as number);
      })
      .tickSize(0)
      .tickPadding(15);

    svg.append('g')
      .transition()
      .duration(timeTransition)
      .attr('class', styles.axisY)
      .attr('transform', 'translate(0, 0)')
      .call(axis);
  }

  getValueMaxY () {
    const { data } = this;

    const value = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].availableQuota === undefined) {
        const element = this.getBalance(data[index]);
        value.push(element);
      }
    }

    const max = Math.max(...value);
    return max;
  }

  renderPoints () {
    const { svg, xScale, yScale, data, timeTransition } = this;

    svg.append('g')
      .attr('class', 'points')
      .style('pointer-events', 'none')
      .selectAll('points')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', (d: Data) => `balance${d.index}`)
      .transition()
      .duration(timeTransition)
      .attr('r', 7)
      .attr('cx', (d: Data) => {
        return xScale(d.index);
      })
      .attr('cy', (d: Data) => {
        const y = this.getBalance(d);
        const valueY = yScale(y);
        return valueY;
      })
      .style('fill', (d: Data) => {
        const quotaType = this.getQuotaType(d);
        return this.getColor(quotaType);
      });
  }

  renderLines () {
    const { svg, xScale, yScale, data, timeTransition } = this;

    svg.append('g')
      .attr('class', 'lines')
      .style('pointer-events', 'none')
      .selectAll('lines')
      .data(data)
      .enter()
      .append('line')
      .transition()
      .duration(timeTransition)
      .attr('class', styles.lines)
      .attr('stroke', (d: Data) => {
        const quotaType = this.getQuotaType(d);
        return this.getColor(quotaType);
      })
      .attr('x1', (d: Data) => {
        return xScale(d.index);
      })
      .attr('y1', (d: Data) => {
        const quotaPrevious = this.getQuotaPrevious(d);
        const valueY = yScale(quotaPrevious);
        return valueY;
      })
      .attr('x2', (d: Data) => {
        return xScale(d.index);
      })
      .attr('y2', (d: Data) => {
        const y = this.getBalance(d);
        const valueY = yScale(y);
        return valueY;
      });
  }

  renderCrossedLines () {
    const { svg, xScale, yScale, crossedLines, timeTransition } = this;

    svg.append('g')
      .attr('class', 'crossedLines')
      .style('pointer-events', 'none')
      .selectAll('crossedLines')
      .data(crossedLines)
      .enter()
      .append('line')
      .transition()
      .duration(timeTransition)
      .attr('class', styles.crossedLines)
      .attr('x1', (d: CrossedLines) => {
        const valueX = xScale(d.x1);
        return valueX;
      })
      .attr('y1', (d: CrossedLines) => {
        const valueY = yScale(d.y1);
        return valueY;
      })
      .attr('x2', (d: CrossedLines) => {
        const valueX = xScale(d.x2);
        return valueX;
      })
      .attr('y2', (d: CrossedLines) => {
        const valueY = yScale(d.y2);
        return valueY;
      });
  }

  renderQuota () {
    const { svg, data, timeTransition } = this;

    svg.append('g')
      .attr('class', 'textQuota')
      .style('pointer-events', 'none')
      .selectAll('textQuota')
      .data(data)
      .enter()
      .append('text')
      .transition()
      .duration(timeTransition)
      .attr('class', (d: Data) => this.getClaseStyle(d))
      .attr('x', (d: Data) => this.getTextPositionX(d))
      .attr('y', (d: Data) => this.getTextPositionY(d))
      .style('text-anchor', 'middle')
      .text((d: Data) => this.getTextQuota(d));
  }

  renderTooltips () {
    const { svg, xScale, data, width, height, tooltip } = this;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.contentTooltip);

    svg.append('rect')
      .attr('id', 'rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.selectAll('.points circle').attr('r', 7);
      });

    svg.append('g')
      .attr('class', 'tooltips')
      .selectAll('tooltips')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d: Data) => xScale(d.index) - 10)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', height)
      .style('fill', 'transparent')
      .style('pointer-events', 'all')
      // eslint-disable-next-line
      .on('mouseover', (event: any, d: Data) => this.mouseover(d, tooltipContent));
  }

  mouseover (data: Data, tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>) {
    const { xScale, tooltip, height, yScale, margin } = this;

    tooltip.style('display', 'block');

    currentBalance = `.balance${data.index}`;
    d3.selectAll('.points circle').attr('r', 7);
    d3.selectAll(currentBalance).attr('r', 9);

    const container = document.getElementById('chartBalance');
    const width = container?.clientWidth || 0;
    const scrollLeft = container?.scrollLeft || 0;
    const pointX = xScale(data.index);
    const marginLeft = pointX - scrollLeft;

    const tooltipDialogWidth = window.innerWidth < 540 ? 270 : 300;
    const bubbleWidth = 17; // this needs to be the same as defined in the css
    const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

    if (marginLeft + tooltipTotalWidth < width) {
      tooltip.classed(styles.rightAlignedTooltip, false);
      tooltip.classed(styles.bottomAlignedTooltip, false);
      tooltip.classed(styles.leftAlignedTooltip, true);
    } else if (marginLeft + margin.left + margin.right > tooltipTotalWidth) {
      tooltip.classed(styles.bottomAlignedTooltip, false);
      tooltip.classed(styles.rightAlignedTooltip, true);
      tooltip.classed(styles.leftAlignedTooltip, false);
    } else {
      tooltip.classed(styles.rightAlignedTooltip, false);
      tooltip.classed(styles.bottomAlignedTooltip, true);
      tooltip.classed(styles.leftAlignedTooltip, false);
    }

    let marginBottom = 0;
    const marginBottomAdditional = (this.data.length > 5) ? 17 : 0;
    if (data.availableQuota) {
      marginBottom = height - yScale(data.availableQuota) + 122 + marginBottomAdditional;
    } else {
      const y = this.getBalance(data);
      marginBottom = height - yScale(y) + 110 + marginBottomAdditional;
    }

    const distanceBetweenLineAndTooltip = 10;
    const spaceSubtract = 60 - (window.innerWidth < 540 ? 20 : 0);

    tooltip.style('left', () => {
      if (marginLeft + tooltipTotalWidth < width) {
        const tooltipXCoordinate = marginLeft + (tooltipDialogWidth / 2) + bubbleWidth + distanceBetweenLineAndTooltip - spaceSubtract;
        return `${tooltipXCoordinate}px`;
      } if (marginLeft + margin.left + margin.right > tooltipTotalWidth) {
        const tooltipXCoordinate = marginLeft - (tooltipDialogWidth / 2) - bubbleWidth - distanceBetweenLineAndTooltip - spaceSubtract;
        return `${tooltipXCoordinate}px`;
      }
      const tooltipXCoordinate = marginLeft + margin.left + margin.right - (tooltipTotalWidth / 2);
      return `${tooltipXCoordinate}px`;

    }).style('bottom', () => {
      if (marginLeft + tooltipTotalWidth < width) {
        return `${marginBottom}px`;
      } if (marginLeft + margin.left + margin.right > tooltipTotalWidth) {
        return `${marginBottom}px`;
      }
      return `${marginBottom + 75}px`;

    });

    tooltipContent.selectAll('*').remove();
    const entry = tooltipContent
      .append('div')
      .attr('class', styles.entry);

    const entryAvatar = entry.append('div')
      .attr('class', styles.entryAvatar);

    const entryContent = entry.append('div')
      .attr('class', styles.entryContent);

    if (data.availableQuota) {
      entryAvatar.append('div')
        .html(`${fundsIcon}`);

      entryContent.append('div')
        .attr('class', styles.statCurrentBalance)
        .html(`${i18next.t('users.balance.currentBalance')} <strong>${data.availableQuota}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${data.date} ${data.hour}`);

    } else {
      const quotaType = this.getQuotaType(data);
      const typeAction = quotaType === balanceType.ACCREDITATION ? i18next.t('users.balance.added') : i18next.t('users.balance.debit');
      const typeAnalysis = this.getAnalysisType(data);
      let creatorUser = `${data.creatorUserFirstName} ${data.creatorUserLastName}`;
      if (creatorUser.length > 12) {
        creatorUser = `${data.creatorUserFirstName} ${data.creatorUserLastName?.charAt(0)}.`;
      }

      entryAvatar.append('div')
        .attr('class', styles.avatar)
        .html(`<span>${data.creatorUserFirstName.charAt(0)}${data.creatorUserLastName.charAt(0)}</span>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`<strong>${creatorUser}</strong> ${typeAction} <strong>${this.getQuotaAmount(data)}</strong> ${typeAnalysis}`);

      const total = this.getBalance(data);
      entryContent.append('div')
        .attr('class', styles.statIcon)
        .html(`${i18next.t('users.balance.newBalance')} <span class=${styles.quotaPrevious}>${this.getQuotaPrevious(data)}</span> ${leftIcon} <strong>${total}</strong>`);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`${data.date} ${data.hour}`);
    }
  }

  getTextPositionX (d: Data) {
    const { xScale } = this;

    const y = this.getBalance(d);
    const valueX = xScale(d.index);

    let value = 0;
    if (y === 0 && (this.getQuotaPrevious(d) !== 0 || this.getQuotaAmount(d) !== 0)) {
      value = 18;
    }

    return valueX + value;
  }

  getTextPositionY (d: Data) {
    const { yScale } = this;

    const y = this.getBalance(d);
    let valueY = 0;

    if (d.availableQuota) {
      valueY = yScale(d.availableQuota);
    } else {
      const y = this.getBalance(d);
      valueY = yScale(y);
    }

    let value = 0;
    const quotaType = this.getQuotaType(d);
    switch (quotaType) {
      case balanceType.ACCREDITATION:
        value = -12;
        break;

      case balanceType.DEBIT:
        value = 23;
        break;
    }

    if (y === 0) {
      value = -12;
    }

    if (d.availableQuota !== undefined) {
      value = -8;
    }

    return valueY + value;
  }

  getTextQuota (d: Data) {
    if (d.availableQuota !== undefined) {
      return `${d.availableQuota}`;
    }

    let value = '';
    const quotaType = this.getQuotaType(d);
    switch (quotaType) {
      case balanceType.ACCREDITATION:
        value = `+ ${this.getQuotaAmount(d)}`;
        break;

      case balanceType.DEBIT:
        value = `- ${this.getQuotaAmount(d)}`;
        break;
    }

    return value;
  }

  getClaseStyle (d: Data) {
    if (d.availableQuota !== undefined) {
      return styles.availableQuota;
    }

    let value = '';
    const quotaType = this.getQuotaType(d);
    switch (quotaType) {
      case balanceType.ACCREDITATION:
        value = styles.accreditation;
        break;

      case balanceType.DEBIT:
        value = styles.debit;
        break;
    }

    return value;
  }

  getColor (quotaType: string) {
    let value = 'transparent';
    switch (quotaType) {
      case balanceType.ACCREDITATION:
        value = '#17c776';
        break;

      case balanceType.DEBIT:
        value = '#ff7979';
        break;
    }

    return value;
  }

  resize = (width: number, height: number) => {
    const { logsBalances, lastBalance, barWidth, margin, container } = this;

    const total = logsBalances.length + (lastBalance ? 1 : 0);
    const newWidth = total > 5 ? ((total * barWidth) - margin.left - margin.right) : (width - this.margin.left - this.margin.right);

    this.width = newWidth;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#contentTooltip').selectAll('*').remove();
    d3.select(container).select('#tooltip').remove();

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);

    this.updateDataPoints();
    this.renderTooltips();
  };
}
