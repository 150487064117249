import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { stockingPhaseTypes, isSuperAdmin } from '../../config/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../common/components/LrvEmpty/LrvEmpty';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';

import Sample from './Sample';
import styles from './SamplesByUser.module.scss';
import { fetchSamplesByUsers, setFiltersSampleByUser, setSamplesByUsers } from './samplesByUserSlice';

const { Option } = Select;

const getImageSizeDefault = (props: { innerWidth: number }) => {
  const { innerWidth } = props;

  if (innerWidth > 1500) {
    return 420;
  }

  if (innerWidth > 1200) {
    return 340;
  }

  if (innerWidth > 1000) {
    return 300;
  }

  if (innerWidth > 680) {
    return 240;
  }

  return 180;
};

interface Props {
  theme?: 'dark' | 'light';
}

export default function SamplesByUser (props: Props) {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters, samplesByUsers } = useSelector((state: Store) => state.samplesByUser);
  const { fromDate, toDate, company, analysisPhaseType } = filters;
  const { _id: companyId } = company;

  const { innerWidth } = window;
  const imageSizeDefault = getImageSizeDefault({ innerWidth });

  useEffect(() => {
    if (companyId) {
      dispatch(fetchSamplesByUsers({ companyId, fromDate, toDate, analysisPhaseType }));
    }

    return () => {
      dispatch(setSamplesByUsers({}));
    };
  }, [dispatch, companyId, toDate, fromDate, analysisPhaseType]);

  const renderSampleByUser = (props: { header: string; key: string; content: JSX.Element[] }) => {
    const { content, header, key } = props;
    return (
      <div className={styles.containerPage} key={key}>
        <LrvText theme={theme} className={styles.header} text={header} />
        {content}
      </div>
    );
  };

  const renderRow = (props: { subtitle: string; analysisId: string; content: JSX.Element }) => {
    const { content, analysisId, subtitle } = props;
    return (
      <div className={styles.containerColumn} key={analysisId}>
        <div className={styles.containerSubtitle} style={{ width: imageSizeDefault * 2 }}>
          <LrvText theme={theme} className={styles.subtitle} text={subtitle} />

          {isSuperAdmin() && <a href={`/production/analysis/${analysisId}`} target='_blank' rel='noopener noreferrer'>
            <LrvText theme={theme} className={styles.open} text={t('clientDashboard.samplesByUser.open')} />
          </a>}
        </div>
        {content}
      </div>
    );
  };

  const renderSample = (content: JSX.Element) => {
    return (
      <div className={styles.containerSample}>
        {content}
      </div>
    );
  };

  const renderSampleEmpty = () => {
    return (
      <div className={styles.center} >
        <LrvEmpty
          theme={theme}
          description={t('clientDashboard.samplesByUser.empty')}
        />
      </div>
    );
  };

  const renderSamples = () => {
    const samples: JSX.Element[] = [];

    for (const userName in samplesByUsers) {
      const rows: JSX.Element[] = [];

      if (!Object.prototype.hasOwnProperty.call(samplesByUsers, userName)) {
        continue;
      }

      const analysis = samplesByUsers[userName];

      for (let index = 0; index < analysis.length; index++) {
        const element = analysis[index];

        const sample = <Sample analysis={element} imageSizeDefault={imageSizeDefault} />;
        const content = renderSample(sample);
        const row = renderRow({ analysisId: element._id, content, subtitle: element.code });
        rows.push(row);
      }

      const sampleByUser = renderSampleByUser({ header: userName, key: userName, content: rows });
      samples.push(sampleByUser);
    }

    return samples;
  };

  const renderBody = () => {
    const samplesByUsersLength = Object.keys(samplesByUsers).length;

    if (samplesByUsersLength === 0) {
      return renderSampleEmpty();
    }

    return renderSamples();
  };

  function onChangeAnalysisType (value: string) {
    dispatch(setFiltersSampleByUser({ company, toDate, fromDate, analysisPhaseType: value }));
  }

  function buildPhaseTypeDropdown () {
    return (
      <LrvSelect
        theme={theme}
        id='dropdown_type'
        className={styles.select}
        value={analysisPhaseType}
        onChange={onChangeAnalysisType}
        suffixIcon={<Icon name='arrow-down-s' />}
      >
        <Option value={stockingPhaseTypes.LARVAE} >
          <div> {t('stockings.phaseTypes.LARVAE').toUpperCase()} </div>
        </Option>
        <Option value={stockingPhaseTypes.JUVENILE} >
          <div> {t('stockings.phaseTypes.JUVENILE').toUpperCase()} </div>
        </Option>
        <Option value={stockingPhaseTypes.ADULT} >
          <div> {t('stockings.phaseTypes.ADULT').toUpperCase()} </div>
        </Option>
      </LrvSelect>
    );
  }

  return (
    <div className={styles.containerMain}>
      {buildPhaseTypeDropdown()}
      {renderBody()}
    </div>
  );
}