import cx from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Select, Button, Spin, Space } from 'antd';

import { dollarUS } from '../../utils/strings';
import { useDragger } from '../../hooks/useDragger';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';
import { plansTypes, typeErrorBalanceAvailable, applyIVA, roundTwoDecimals } from '../../config/commons';

import './NewPrepaidPayment.scss';
import { Plan } from './interfaces';
import { editLocalStorage } from './helpers';
import * as paymentSlice from './paymentSlice';
import styles from './NewPrepaidPayment.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

export default function NewPrepaidPayment (props: Props) {
  const { theme = 'dark' } = props;
  const { Option } = Select;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [formPrepaidPayments] = Form.useForm();

  const [planPrice, setPlanPrice] = useState(0);
  const [quota, setQuota] = useState(0);
  const [transferPayment, setTransferPayment] = useState(false);

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageName,
    imageUrl,
    loadingImage,
    reducedImage,
    voucherHeight,
    voucherWidth,
    cleanImage,
  } = useDragger({});

  const {
    showPrepaidPaymentModal,
    typeErrorBalance,
    checkingBalanceAvailable,
    payphone,
    isLoadingCreatePayment,
    plans
  } = useSelector((state: Store) => state.payments);
  const { company: selectedCompany } = useSelector((state: Store) => state.header);

  const onChangePlan = (value: string) => {
    plans.forEach((plan: Plan) => {
      if (value === plan._id) {
        const price = selectedCompany.isInternational ? plan.value : applyIVA(plan.value);

        setPlanPrice(price);
        setQuota(plan.amountAnalysis);

        const amount = roundTwoDecimals(price * 100);
        dispatch(paymentSlice.fetchButtonPrepare({ amount }));
      }
    });

    formPrepaidPayments.setFieldsValue({ plan: value });
  };

  const updateErrorBalance = (error: string) => {
    switch (error) {
      case typeErrorBalanceAvailable.POSTPAID_BALANCE_AVAILABLE:
        return t('payments.postpaidBalanceAvailable');

      case typeErrorBalanceAvailable.PREPAID_BALANCE_AVAILABLE:
        return t('payments.prepaidBalanceAvailable');

      case typeErrorBalanceAvailable.BONUS_QUOTA_AVAILABLE:
        return t('payments.bonusQuotaAvailable');
    }
  };

  const renderItemPlan = (plan: Plan, index: number) => {
    const price = selectedCompany.isInternational ? plan.value : applyIVA(plan.value);
    return (
      <Option key={index} value={plan._id}>
        {t('payments.descriptionPlan', { amountAnalysis: plan.amountAnalysis, value: dollarUS.format(price) })}
      </Option>
    );
  };

  const renderPlanSelect = () => {
    return (
      <Form.Item
        label={t('payments.plan')}
        name='plan'
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvSelect
          theme={theme}
          id='dropdown_plans'
          showSearch
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('payments.selectPlan')}
          optionFilterProp='children'
          onChange={onChangePlan}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
          disabled={updateErrorBalance(typeErrorBalance) !== undefined}
        >
          {
            plans.map(renderItemPlan)
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderButtonsPay = () => {
    const paymentDate = moment().toISOString();

    if (checkingBalanceAvailable) {
      return (
        <div className={styles.rect}>
          <Spin />
        </div>
      );
    }

    if (typeErrorBalance) {
      return (
        <div className={styles.rect}>
          {updateErrorBalance(typeErrorBalance)}
        </div>
      );
    }

    if (planPrice === 0) {
      return (
        <div className={styles.rect}>
          {t('payments.selectPlan')}
        </div>
      );
    }

    return (
      <Form.Item
        className='itemPayphone'
        label={t('payments.payWith')}
      >
        <div className={styles.payphone}>
          <Space direction='horizontal'>
            <Button
              type='primary'
              disabled={planPrice === 0}
              icon={<Icon name='file-transfer' />}
              onClick={() => {
                setTransferPayment(true);
              }}
            >
              &nbsp;{t('payments.transfer')}
            </Button>

            <Button
              type='primary'
              disabled={!payphone.payWithCard}
              icon={<Icon name='bank-card' />}
              onClick={() => {
                setTransferPayment(false);
                editLocalStorage({ paymentType: plansTypes.PREPAID, months: JSON.stringify([paymentDate]), prices: JSON.stringify([planPrice]), quota, url: payphone.payWithCard });
              }}
            >
              &nbsp;{t('payments.card')}
            </Button>

            <Button
              type='primary'
              disabled={!payphone.payWithPayPhone}
              icon={<Icon name='tablet' />}
              onClick={() => {
                setTransferPayment(false);
                editLocalStorage({ paymentType: plansTypes.PREPAID, months: JSON.stringify([paymentDate]), prices: JSON.stringify([planPrice]), quota, url: payphone.payWithPayPhone });
              }}
            >
              &nbsp;{t('payments.payphone')}
            </Button>
          </Space>
        </div>
      </Form.Item>
    );
  };

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p >{t('payments.drag')}</p>
      </div>
    );
  };

  const renderUploadImage = () => {
    if (!transferPayment) {
      return null;
    }

    return (
      <Form.Item
        name='paymentImage'
        label={t('payments.voucher')}
        rules={[{ required: false, message: t('common.requiredField') }]}
      >
        <div>
          <LrvDragger
            theme={theme}
            name='file'
            accept='image/png, image/jpeg'
            maxCount={1}
            listType='picture-card'
            showUploadList={false}
            beforeUpload={() => true}
            onChange={handleOnChange}
            customRequest={handleCustomRequest}
          >
            {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
          </LrvDragger>

          <canvas
            className={styles.canvas}
            ref={canvasRef}
            width={voucherWidth}
            height={voucherHeight}
          />
        </div>
      </Form.Item>
    );
  };

  const renderSubmitButton = () => {
    if (!transferPayment) {
      return null;
    }

    return (
      <div className={styles.containerSubmit}>
        <Button
          type='primary'
          disabled={!canvasRef.current}
          loading={isLoadingCreatePayment}
          onClick={() => {
            const currentDate = moment().toISOString();

            const params = {
              type: plansTypes.PREPAID,
              months: [currentDate],
              quota,
              prices: [planPrice],
              dataUrl: reducedImage,
              key: imageName,
            };

            setTransferPayment(false);
            cleanImage();

            dispatch(paymentSlice.createPrepaidTransfer(params));
            dispatch(paymentSlice.setShowPrepaidPaymentModal(false));
          }}
        >
          &nbsp;{t('payments.accept')}
        </Button>
      </div>
    );
  };

  return (
    <LrvModal
      theme={theme}
      className={cx(styles.prepaidPaymentModal, 'prepaidPaymentModal')}
      open={showPrepaidPaymentModal}
      title={t('payments.newPayment')}
      destroyOnClose={true}
      onCancel={() => {
        setPlanPrice(0);
        setQuota(0);
        setTransferPayment(false);
        cleanImage();
        dispatch(paymentSlice.setShowPrepaidPaymentModal(false));
        formPrepaidPayments.resetFields();
      }}
      footer={[
        renderSubmitButton()
      ]}
    >
      <LrvForm
        theme={theme}
        form={formPrepaidPayments}
        name='formPrepaidPayments'
        id='formPrepaidPayments'
        layout='vertical'
      >
        {renderPlanSelect()}
        {renderButtonsPay()}
        {renderUploadImage()}
      </LrvForm>
    </LrvModal>
  );
}