import { Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MouseEventHandler, useEffect, useState } from 'react';

import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { handleResizeEvent } from '../../utils/dimensions';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';
import { balanceCurrentTab, hasAdminRole, isBusinessManagerRole, isOwnerRole, isPromoterRole, isSalesManagerRole, isSalesRole } from '../../config/commons';

import * as usersSlice from './usersSlice';
import * as balanceSlice from './balanceSlice';
import styles from './UserSubHeader.module.scss';
import { getValueActiveUsers, USERS } from './helpers';

interface Props {
  onClickAddUser: MouseEventHandler<HTMLElement> | undefined;
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const UserSubHeader = (props: Props) => {
  const { onClickAddUser, theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company } = useSelector((state: RootState) => state.header);
  const { userStatus } = useSelector((state: RootState) => state.users.filters);
  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    handleResizeEvent(() => {
      setWindowWidth(window.innerWidth);
    });
  });

  const renderBalanceButton = () => {
    const showButton = (hasAdminRole() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole() || isOwnerRole());
    if (!showButton) {
      return null;
    }

    return (
      <ActionButton
        id='balances_button'
        type='text'
        className={styles.actionButton}
        icon={<Icon name='calculator' theme={theme} />}
        theme={theme}
        onClick={() => {
          dispatch(balanceSlice.fetchClientBalances(company._id));
          dispatch(balanceSlice.setShowBalanceModal(true));
          dispatch(balanceSlice.setCurrentBalanceTab(balanceCurrentTab.USERS));
          if (isRunningOnboarding) {
            dispatch(goToOnboardingNextStep(500));
          }
        }}
      >
        {t('users.balance.name')}
      </ActionButton>
    );
  };

  const renderAddUserButton = () => {
    const showButton = (hasAdminRole() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole() || isOwnerRole());
    if (!showButton) {
      return null;
    }

    return (
      <ActionButton
        id='new_user_button'
        type='primary'
        className={styles.actionButton}
        icon={<Icon name='add' />}
        onClick={onClickAddUser}
      >
        {windowWidth > 440 ? t('users.user') : ''}
      </ActionButton>
    );
  };

  const renderUserStatusDropdown = () => {
    return (
      <LrvSelect
        id='dropdown_filter_users'
        theme={theme}
        className={styles.select}
        value={userStatus}
        defaultValue={userStatus}
        title={t('payments.status')}
        onChange={(value) => {
          dispatch(usersSlice.setUserStatus(value));

          const active = getValueActiveUsers(value);
          dispatch(usersSlice.fetchUsers({ company, active }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
      >
        <Option value={USERS.ACTIVE} >
          <div id='filter_users_active'>{t('users.filter.active')}</div>
        </Option>
        <Option value={USERS.INACTIVE} >
          <div id='filter_users_inactive'>{t('users.filter.inactive')}</div>
        </Option>
        <Option value={USERS.ALL} >
          <div id='filter_users_all'>{t('users.filter.all')}</div>
        </Option>
      </LrvSelect>
    );
  };

  return (
    <div className={styles.container}>
      <Row>
        {renderUserStatusDropdown()}
      </Row>

      <Row justify='end' align='middle'>
        <Space>
          {renderBalanceButton()}
          {renderAddUserButton()}
        </Space>
      </Row>
    </div>
  );
};
