import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../../utils/axios_instance';
import { ANALYSES_URL, CAMPUS_URL, UNARCHIVE_ANALYSIS_URL } from '../../../config/config.api';
import { openErrorNotification, openSuccessNotification } from '../../../common/notification/Notification';
import { analysisStatuses, paginationAnalysis, typeErrorStocking, unitStatuses } from '../../../config/commons';

import { ArchivedAnalysisPayload, ArchivedAnalysisState, Campus } from './interfaces';

const initialState: ArchivedAnalysisState = {
  total: 0,
  skip: 0,
  limit: 0,
  currentPage: 1,
  analysis: [],
  isLoading: false,
  campuses: [],
  campusSelected: {
    _id: '',
    name: '',
    code: '',
    phaseType: '',
    status: '',
  },
};

export const archivedAnalysisSlice = createSlice({
  name: 'archivedAnalysis',
  initialState,
  reducers: {
    setArchivedAnalysis: (state: ArchivedAnalysisState, action: PayloadAction<ArchivedAnalysisPayload>) => {
      state.total = action.payload.total;
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.analysis = action.payload.data;
    },
    setIsLoading: (state: ArchivedAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCampusSelected: (state: ArchivedAnalysisState, action: PayloadAction<Campus | undefined>) => {
      state.campusSelected = action.payload;
    },
    setCurrentPage: (state: ArchivedAnalysisState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCampuses: (state: ArchivedAnalysisState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },
  },
});

export const {
  setArchivedAnalysis,
  setIsLoading,
  setCampusSelected,
  setCurrentPage,
  setCampuses,
} = archivedAnalysisSlice.actions;

export const fetchArchivedAnalysis = (props: { page: number; phaseType: string; companyId: string; campusId?: string }) => async (dispatch: Function) => {
  const { companyId, page, phaseType, campusId } = props;

  let skip = 0;
  dispatch(setIsLoading(true));

  if (page !== 0) {
    skip = paginationAnalysis * (page - 1);
  }

  const params = {
    $limit: paginationAnalysis.toString(),
    $skip: skip.toString(),
    isArchived: true,
    '$sort[createdAt]': '1',
    $select: ['code', 'inputData', 'resultData.uniformity', 'resultData.larvaeNumber', 'resultData.larvaePerGram', 'resultData.averageWeight', 'createdAt', 'status', 'sowingId', 'type'],
    '$or[0][status]': analysisStatuses.COMPLETED,
    '$or[1][status]': analysisStatuses.ERROR,
    phaseType,
    companyId,
    campusId,
  };

  try {
    const response = await axios.get<ArchivedAnalysisPayload>(ANALYSES_URL, { params: params });
    dispatch(setArchivedAnalysis(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const unarchiveAnalysis = (props: { analysisId: string; phaseType: string; companyId: string; currentPage: number }) => async (dispatch: Function) => {
  const { analysisId, companyId, phaseType, currentPage } = props;

  try {
    await axios.post(`${UNARCHIVE_ANALYSIS_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.unarchived'));
  } catch (e) {
    console.log(e?.response);

    if (!e.response?.data?.data?.error) {
      return;
    }

    const error = e.response.data.data.error;
    if (error === typeErrorStocking.ORIGIN_STOCKING_IS_PUBLISHED) {
      openErrorNotification(i18next.t('stockings.errors.originStockingIsPublished'));
    }
    return;
  }

  dispatch(fetchArchivedAnalysis({ page: currentPage, phaseType, companyId }));
};

export const fetchCampuses = (companyId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    companyId,
    'status[$in][0]': unitStatuses.ACTIVE,
    'status[$in][1]': unitStatuses.INACTIVE,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params: params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default archivedAnalysisSlice.reducer;
