import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

import { getCurrentTheme } from '../../../helpers/theme';

import styles from './ActionButton.module.scss';

interface ActionButtonProps extends ButtonProps {
  theme?: 'dark' | 'light';
  containerClassName?: string;
}

export default function ActionButton (props: ActionButtonProps) {
  const { children, className, containerClassName, theme = getCurrentTheme(), type = 'default' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    type,
    className: cx(styles.actionButton, isDarkTheme ? styles.darkActionButton : styles.lightActionButton, className),
  };

  return (
    <div className={cx(styles.container, containerClassName, isDarkTheme ? styles.darkContainer : styles.lightContainer)}>
      <Button
        {...lrvProps}
      >
        {children}
      </Button>
    </div>
  );
}
