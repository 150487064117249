import React, { useEffect } from 'react';
import { Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { companiesTypes } from '../../config/commons';
import { filterOptionSelect } from '../../utils/select';
import { DropdownProps } from '../../common/interfaces/commons';
import CleanButton from '../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvFilterPanel } from '../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import styles from './BalanceFilterPanel.module.scss';
import * as balanceConsumptionSlice from './BalanceConsumptionSlice';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

export default function BalanceFilterPanel (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const { filters, sellers, promoters } = useSelector((state: Store) => state.balanceConsumption);

  const {
    sellerId,
    promoterId,
    companyType,
    companyStatus: companyStatus,
  } = filters;

  useEffect(() => {
    dispatch(balanceConsumptionSlice.fetchSellers());
    dispatch(balanceConsumptionSlice.fetchPromoters());
  }, [dispatch]);

  const changeSeller = (value: string) => {
    dispatch(balanceConsumptionSlice.setPromoterId(undefined));
    dispatch(balanceConsumptionSlice.setSellerId(value));
  };

  const changePromoter = (value: string) => {
    dispatch(balanceConsumptionSlice.setPromoterId(value));
    dispatch(balanceConsumptionSlice.setSellerId(undefined));
  };

  const renderDropdownSellers = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='sellers'
        showSearch
        theme={theme}
        className={className}
        title={t('balances.seller')}
        placeholder={t('balances.selectSeller')}
        value={sellerId}
        suffixIcon={<Icon name='arrow-down-s' />}
        onChange={changeSeller}
        dropdownMatchSelectWidth={false}
        optionFilterProp='children'
        filterOption={filterOptionSelect}
      >
        {sellers.map((seller) => {
          const userFullName = `${seller.firstName} ${seller.lastName}`;
          return (
            <Option key={seller._id} value={seller._id}>{userFullName}</Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderDropdownPromoters = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='promoters'
        showSearch
        theme={theme}
        className={className}
        title={t('balances.promoter')}
        placeholder={t('balances.selectPromoter')}
        optionFilterProp='children'
        value={promoterId}
        suffixIcon={<Icon name='arrow-down-s' />}
        onChange={changePromoter}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        {promoters.map((promoter) => {
          const userFullName = `${promoter.firstName} ${promoter.lastName}`;
          return (
            <Option key={promoter._id} value={promoter._id}>{userFullName}</Option>
          );
        })}
      </LrvSelect>
    );
  };

  const changeShowInternationalCompanies = (value: string) => {
    dispatch(balanceConsumptionSlice.setCompanyType(value));

    switch (value) {
      case companiesTypes.NATIONAL:
        dispatch(balanceConsumptionSlice.setShowInternationalCompanies(false));
        return;


      case companiesTypes.INTERNATIONAL:
        dispatch(balanceConsumptionSlice.setShowInternationalCompanies(true));
        return;

      case companiesTypes.ALL:
        dispatch(balanceConsumptionSlice.setShowInternationalCompanies(undefined));
        return;
    }
  };

  const renderDropdownTypeCompanies = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='type_companies'
        showSearch
        theme={theme}
        className={className}
        title={t('balances.typeCompanies')}
        placeholder={t('balances.select')}
        optionFilterProp='children'
        value={companyType}
        defaultValue={companyType}
        suffixIcon={<Icon name='arrow-down-s' />}
        onChange={changeShowInternationalCompanies}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        <Option key={companiesTypes.ALL} value={companiesTypes.ALL}>{t('balances.all')}</Option>
        <Option key={companiesTypes.NATIONAL} value={companiesTypes.NATIONAL}>{t('balances.nationals')}</Option>
        <Option key={companiesTypes.INTERNATIONAL} value={companiesTypes.INTERNATIONAL}>{t('balances.internationals')}</Option>
      </LrvSelect>
    );
  };

  const changeStatusCompanies = (value: string) => {
    dispatch(balanceConsumptionSlice.setCompanyStatus(value));

    switch (value) {
      case companiesTypes.ACTIVE:
        dispatch(balanceConsumptionSlice.setShowTrialPhaseCompanies(false));
        dispatch(balanceConsumptionSlice.setShowActiveCompanies(true));
        return;


      case companiesTypes.INACTIVE:
        dispatch(balanceConsumptionSlice.setShowTrialPhaseCompanies(false));
        dispatch(balanceConsumptionSlice.setShowActiveCompanies(false));
        return;

      case companiesTypes.TRIAL_PHASE:
        dispatch(balanceConsumptionSlice.setShowTrialPhaseCompanies(true));
        dispatch(balanceConsumptionSlice.setShowActiveCompanies(true));
        return;
    }
  };

  const renderDropdownStatusCompanies = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='status_companies'
        showSearch
        theme={theme}
        className={className}
        title={t('balances.statusCompanies')}
        placeholder={t('balances.select')}
        optionFilterProp='children'
        value={companyStatus}
        suffixIcon={<Icon name='arrow-down-s' />}
        onChange={changeStatusCompanies}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        <Option key={companiesTypes.ACTIVE} value={companiesTypes.ACTIVE}>{t('balances.activeCompanies')}</Option>
        <Option key={companiesTypes.INACTIVE} value={companiesTypes.INACTIVE}>{t('balances.inactiveCompanies')}</Option>
        <Option key={companiesTypes.TRIAL_PHASE} value={companiesTypes.TRIAL_PHASE}>{t('balances.trialPhase')}</Option>
      </LrvSelect>
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('balances.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(balanceConsumptionSlice.resetBalanceConsumptionFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderDropdownSellers({ theme: 'light' })}
            {renderDropdownPromoters({ theme: 'light' })}
            {renderDropdownTypeCompanies({ theme: 'light' })}
            {renderDropdownStatusCompanies({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(balanceConsumptionSlice.resetBalanceConsumptionFilters())}
      />
    );
  };

  return (
    <Row className={styles.container}>
      <Space className={styles.filters} align='end'>
        {renderDropdownSellers({ className: styles.select, theme })}
        {renderDropdownPromoters({ className: styles.select, theme })}
        {renderDropdownTypeCompanies({ className: styles.select, theme })}
        {renderDropdownStatusCompanies({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>
      {renderSidePanel()}
    </Row>
  );
}
