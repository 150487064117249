import React from 'react';
import { useTranslation } from 'react-i18next';

import { getParameter } from '../../helpers/stocking.helpers';
import { isReferenceCurveMetric, metricsStatuses, typeParam, typeScale } from '../../common/components/charts/ShadedPlot/helpers';

import styles from './Data.module.scss';
import LegendStocking from './LegendStocking';

interface Props {
  unitName?: string;
  parameter: string;
  scale?: string;
  dataSource?: string;
  legendList?: string[];
  language: string;
  stockingPhaseType: string;
  referenceName?: string;
}

export default function Data (props: Props) {
  const { unitName, parameter, scale, dataSource, legendList = [], language, stockingPhaseType, referenceName } = props;
  const [t] = useTranslation();

  function getLabelSource (source: string) {
    if (isReferenceCurveMetric(source)) {
      return referenceName || t('stockings.pdf.typeSource.referenceCurve');
    }

    switch (source) {
      default:
      case metricsStatuses.GLOBAL:
        return t('stockings.pdf.typeSource.global');

      case metricsStatuses.COMPANY:
        return t('stockings.pdf.typeSource.company');
    }
  }

  function getLabelScale (scale: string) {
    switch (scale) {
      case typeScale.LINEAR:
        return t('stockings.pdf.typeScale.linear');

      case typeScale.LOGARITHMIC:
        return t('stockings.pdf.typeScale.logarithmic');
    }
  }

  function renderUnit () {
    if (unitName) {
      return (
        <div className={styles.item}>
          <span> &nbsp;{unitName} </span>
        </div>
      );
    }
  }

  function renderParameterSelected () {
    if (language.toLowerCase() === 'es') {
      return (
        <div className={styles.item}>
          <span> &nbsp;{getParameter({ parameter, stockingPhaseType, addUnit: false })}  </span>
        </div>
      );
    }

    return (
      <div className={styles.item}>
        <span> &nbsp;{getParameter({ parameter, stockingPhaseType, addUnit: false })}  </span>
      </div>
    );
  }

  function renderReferenceSelected () {
    if (!dataSource) {
      return null;
    }

    if (language.toLowerCase() === 'es') {
      return (
        <div className={styles.item}>
          <div> {t('stockings.pdf.reference')} </div>
          <span> &nbsp;{getLabelSource(dataSource)} </span>
        </div>
      );
    }

    return (
      <div className={styles.item}>
        <span> {getLabelSource(dataSource)} </span>
        <div> &nbsp;{t('stockings.pdf.reference')} </div>
      </div>
    );
  }

  function renderScaleSelected () {
    if (!scale) {
      return null;
    }

    if (language.toLowerCase() === 'es') {
      return (
        <div className={styles.item}>
          <div> {t('stockings.pdf.scale')} </div>
          <span> &nbsp;{getLabelScale(scale)} </span>
        </div>
      );
    }

    return (
      <div className={styles.item}>
        <span> {getLabelScale(scale)} </span>
        <div> &nbsp;{t('stockings.pdf.scale')} </div>
      </div>
    );
  }

  function renderParameters () {
    return (
      <div className={styles.title}>
        {renderUnit()}

        {
          unitName &&
          <div className={styles.item}>
            <div>&nbsp;-&nbsp;</div>
          </div>
        }

        {renderParameterSelected()}

        {
          parameter !== typeParam.PIGMENTATION &&
          parameter !== typeParam.FACTOR_K &&
          parameter !== typeParam.GROWTH_DELTA &&
          dataSource &&
          <div className={styles.item}>
            <div>&nbsp;-&nbsp;</div>
          </div>
        }

        {
          parameter !== typeParam.PIGMENTATION &&
          parameter !== typeParam.FACTOR_K &&
          parameter !== typeParam.GROWTH_DELTA &&
          renderReferenceSelected()
        }

        {
          scale &&
          <div className={styles.item}>
            <div>&nbsp;-&nbsp;</div>
          </div>
        }

        {
          scale &&
          renderScaleSelected()
        }

      </div>
    );
  }

  return <div className={styles.gridContainer}>
    {renderParameters()}
    <LegendStocking legendList={legendList} />
  </div>;
}
