import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import JoyRide, { CallBackProps, ACTIONS, EVENTS } from 'react-joyride';

import { Store } from '../../../state/store.interfaces';
import { getUserSession } from '../../../utils/userSession';
import { onboardingTypes, getMainRole, roles } from '../../../config/commons';
import FunctionalitiesModal from '../FunctionalitiesModal/FunctionalitiesModal';

import { startOnboarding, setSteps, saveOnboardingUserData, setIsContinuous, goToOnboardingStep, fetchOnboardingUserData } from './OnboardingSlice';

const Onboarding = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const higherRole = getMainRole();

  const run = useSelector((state: Store) => state.onboarding.run);
  const steps = useSelector((state: Store) => state.onboarding.steps);
  const stepIndex = useSelector((state: Store) => state.onboarding.stepIndex);
  const shouldRun = useSelector((state: Store) => state.onboarding.shouldRun);
  const userData = useSelector((state: Store) => state.onboarding.user);
  const onboardingType = useSelector((state: Store) => state.onboarding.onboardingType);
  const isContinuous = useSelector((state: Store) => state.onboarding.isContinuous);

  const isImpersonating = localStorage.getItem('impersonate');
  const userSession = getUserSession();

  //#region Onboarding steps
  const INIT_CONFIG_STEPS = [
    {
      target: '#stockings-table',
      content: t('onboarding.steps.initConfig.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideNextButton: false,
    },
    {
      target: '.ant-table-thead',
      content: t('onboarding.steps.initConfig.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
    },
    {
      target: '#phase-type-global',
      content: t('onboarding.steps.initConfig.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
      placement: 'left',
    },
    {
      target: '#sidebar-menu',
      content: t('onboarding.steps.initConfig.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
      placement: 'right',
    },
    {
      target: '#menu-avatar',
      content: t('onboarding.steps.initConfig.4.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
      placement: 'top',
    },
    {
      target: '.ant-popover-inner',
      content: t('onboarding.steps.initConfig.5.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
      placement: 'right',
    }
  ];

  const MATURATION_SECTION_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.maturationSection.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
    },
    {
      target: '#btn_new_maturation',
      content: t('onboarding.steps.maturationSection.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '.newMaturationModal',
      content: t('onboarding.steps.maturationSection.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '#btn_add_maturation_code',
      content: t('onboarding.steps.maturationSection.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '#txt_new_maturation_code',
      content: t('onboarding.steps.maturationSection.4.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
    },
    {
      target: '#submit_maturation',
      content: t('onboarding.steps.maturationSection.5.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '.maturations',
      content: t('onboarding.steps.maturationSection.6.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      placement: 'center',
    },
  ];

  const UNIT_SECTION_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.unitSection.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
    },
    {
      target: '#btn_new_campus',
      content: t('onboarding.steps.unitSection.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '.ant-modal-content',
      content: t('onboarding.steps.unitSection.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
      placement: 'right',
    },
    {
      target: '#tableCampus',
      content: t('onboarding.steps.unitSection.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '#btn_new_module',
      content: t('onboarding.steps.unitSection.4.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '.ant-modal-content',
      content: t('onboarding.steps.unitSection.5.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '#tableModules',
      content: t('onboarding.steps.unitSection.6.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '#btn_new_tank',
      content: t('onboarding.steps.unitSection.7.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '.ant-modal-content',
      content: t('onboarding.steps.unitSection.8.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: 'body',
      content: t('onboarding.steps.unitSection.9.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      placement: 'center',
    },
  ];

  const STOCKING_SECTION_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.stockingSection.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
    },
    {
      target: '#btn_create_stocking',
      content: t('onboarding.steps.stockingSection.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '.ant-modal-content',
      content: t('onboarding.steps.stockingSection.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
      placement: 'right',
    },
    {
      target: 'body',
      content: t('onboarding.steps.stockingSection.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideNextButton: false,
      placement: 'center'
    },
  ];

  const CLIENT_DASHBOARD_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.clientDashboard.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
    },
    {
      target: '#userActivity',
      content: t('onboarding.steps.clientDashboard.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
    },
    {
      target: '#open_users_activity_button',
      content: t('onboarding.steps.clientDashboard.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
    },
    {
      target: '#campusActivity',
      content: t('onboarding.steps.clientDashboard.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
    },
    {
      target: '#stageActivity',
      content: t('onboarding.steps.clientDashboard.5.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
    }
  ];

  const USERS_SECTION_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.usersSection.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
      hideNextButton: false,
    },
    {
      target: '.ant-table-body',
      content: t('onboarding.steps.usersSection.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '.ant-modal-content',
      content: t('onboarding.steps.usersSection.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: true,
      hideNextButton: true,
    },
    {
      target: '#balances_button',
      content: t('onboarding.steps.usersSection.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '.editBalanceModal',
      content: t('onboarding.steps.usersSection.4.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
    },
  ];

  const PREPAID_BALANCE_SECTION_STEPS = {
    OWNER: [
      {
        target: 'body',
        content: t('onboarding.steps.prepaidBalanceSection.owner.0.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        placement: 'center',
        hideNextButton: false,
      },
      {
        target: '.ant-table-body',
        content: t('onboarding.steps.prepaidBalanceSection.owner.1.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
      {
        target: '#new_balance_request_button',
        content: t('onboarding.steps.prepaidBalanceSection.owner.2.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: true,
      },
      {
        target: '.balanceRequestModal',
        content: t('onboarding.steps.prepaidBalanceSection.owner.3.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
    ],
    SALESADMIN: [
      {
        target: 'body',
        content: t('onboarding.steps.prepaidBalanceSection.salesAdmin.0.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        placement: 'center',
        hideNextButton: false,
      },
      {
        target: '.ant-table-body',
        content: t('onboarding.steps.prepaidBalanceSection.salesAdmin.1.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
    ],
  };

  const CLIENTS_SECTION_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.clientsSection.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
      hideNextButton: false,
    },
    {
      target: '.ant-table-body',
      content: t('onboarding.steps.clientsSection.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
    },
    {
      target: '#formEditClient',
      content: t('onboarding.steps.clientsSection.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: true,
      placement: 'left',
    },
    {
      target: '.select_status_client',
      content: t('onboarding.steps.clientsSection.3.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
      placement: 'right',
    }
  ];

  const PAYPAL_SECTION_STEPS = {
    OWNER: [
      {
        target: 'body',
        content: t('onboarding.steps.paypalSection.owner.0.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        placement: 'center',
        hideNextButton: false,
      },
      {
        target: '.ant-table-body',
        content: t('onboarding.steps.paypalSection.owner.1.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
      {
        target: '#new_postpaid_transfer_button',
        content: t('onboarding.steps.paypalSection.owner.2.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
    ],
    SALESADMIN: [
      {
        target: 'body',
        content: t('onboarding.steps.paypalSection.salesAdmin.0.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        placement: 'center',
        hideNextButton: false,
      },
      {
        target: '.ant-table-body',
        content: t('onboarding.steps.paypalSection.salesAdmin.1.content'),
        disableBeacon: true,
        disableOverlayClose: true,
        hideCloseButton: true,
        hideFooter: false,
        spotlightClicks: true,
        hideBackButton: false,
        hideNextButton: false,
      },
    ],
  };

  const ADMINISTRATION_HISTORY_STEPS = [
    {
      target: 'body',
      content: t('onboarding.steps.administrationHistory.0.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      placement: 'center',
      hideNextButton: false,
    },
    {
      target: '.ant-table-body',
      content: t('onboarding.steps.administrationHistory.1.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
    },
    {
      target: '#container_filters',
      content: t('onboarding.steps.administrationHistory.2.content'),
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      hideFooter: false,
      spotlightClicks: true,
      hideBackButton: false,
      hideNextButton: false,
    }
  ];
  //#endregion

  useEffect(() => {
    if (isImpersonating === 'true' || !userSession?._id) {
      return;
    }

    dispatch(fetchOnboardingUserData());
  }, [dispatch, isImpersonating]);

  useEffect(() => {
    switch (onboardingType) {
      case onboardingTypes.INIT_CONFIG:
        dispatch(setSteps(INIT_CONFIG_STEPS));
        dispatch(setIsContinuous(true));
        break;

      case onboardingTypes.MATURATION_SECTION:
        dispatch(setSteps(MATURATION_SECTION_STEPS));
        dispatch(setIsContinuous(true));
        break;

      case onboardingTypes.UNIT_SECTION:
        dispatch(setSteps(UNIT_SECTION_STEPS));
        dispatch(setIsContinuous(true));
        break;

      case onboardingTypes.STOCKING_SECTION:
        dispatch(setSteps(STOCKING_SECTION_STEPS));
        dispatch(setIsContinuous(true));
        break;

      case onboardingTypes.CLIENT_DASHBOARD:
        dispatch(setSteps(CLIENT_DASHBOARD_STEPS));
        dispatch(setIsContinuous(true));
        break;
      case onboardingTypes.USERS_SECTION:
        dispatch(setSteps(USERS_SECTION_STEPS));
        dispatch(setIsContinuous(true));
        break;
      case onboardingTypes.PREPAID_BALANCE_SECTION:
        dispatch(setIsContinuous(true));
        switch (higherRole) {
          case roles.CLIENT_OWNER:
            dispatch(setSteps(PREPAID_BALANCE_SECTION_STEPS.OWNER));
            break;
          case roles.SUPER_ADMIN:
          case roles.SALES_MANAGER:
          case roles.SALES:
          case roles.FINANCE:
            dispatch(setSteps(PREPAID_BALANCE_SECTION_STEPS.SALESADMIN));
            break;
        }
        break;
      case onboardingTypes.CLIENTS_SECTION:
        dispatch(setSteps(CLIENTS_SECTION_STEPS));
        dispatch(setIsContinuous(true));
        break;

      case onboardingTypes.PAYPAL_SECTION:
        dispatch(setIsContinuous(true));
        switch (higherRole) {
          case roles.CLIENT_OWNER:
            dispatch(setSteps(PAYPAL_SECTION_STEPS.OWNER));
            break;

          case roles.SUPER_ADMIN:
          case roles.SALES_MANAGER:
          case roles.SALES:
          case roles.FINANCE:
            dispatch(setSteps(PAYPAL_SECTION_STEPS.SALESADMIN));
            break;
        }
        break;
      case onboardingTypes.ADMINISTRATION_HISTORY:
        dispatch(setSteps(ADMINISTRATION_HISTORY_STEPS));
        dispatch(setIsContinuous(true));
        break;
    }
    // eslint-disable-next-line
  }, [onboardingType]);

  const labels = {
    back: t('onboarding.labels.back'),
    close: t('onboarding.labels.close'),
    last: t('onboarding.labels.last'),
    next: t('onboarding.labels.next'),
    skip: t('onboarding.labels.skip')
  };

  const handleJoyrideCallback = (props: CallBackProps) => {
    if (!(props.type === EVENTS.STEP_AFTER || props.type === EVENTS.TOUR_END)) {
      return;
    }

    switch (props.action) {
      case ACTIONS.CLOSE:
      case ACTIONS.SKIP: {
        dispatch(saveOnboardingUserData());
        break;
      }
      case ACTIONS.NEXT:
      case ACTIONS.PREV: {
        if (!isContinuous) {
          break;
        }

        const action = props.action === ACTIONS.PREV ? -1 : 1;
        const nextIndex = props.index + action;

        if (nextIndex < steps.length) {
          dispatch(goToOnboardingStep(nextIndex));
          break;
        }

        dispatch(saveOnboardingUserData());
        break;
      }
    }
  };

  return <>
    <FunctionalitiesModal
      show={shouldRun && !run}
      titleModal={t('onboarding.welcomeTitle')}
      titleButton={t('onboarding.startOnboarding')}
      onClickSecondary={() => {
        dispatch(saveOnboardingUserData());
      }}
      titleSecondaryButton={t('onboarding.exitOnboarding')}
      onClose={() => {
        dispatch(startOnboarding());
      }}
    >
      {t('onboarding.welcomeMessage', { name: capitalize(userData.firstName) })}
    </FunctionalitiesModal>

    <JoyRide
      steps={steps}
      continuous={isContinuous}
      showSkipButton={true}
      disableCloseOnEsc={true}
      hideBackButton={true}
      stepIndex={stepIndex}
      locale={labels}
      spotlightPadding={4}
      scrollToFirstStep={true}
      run={run}
      styles={{
        options: {
          backgroundColor: '#ffffff',
          overlayColor: 'rgba(255, 255, 255, 0.25)',
          primaryColor: 'rgb(105, 111, 251)',
          textColor: '#000000',
          zIndex: 11000,
        },
        buttonBack: {
          color: '#0000ff',
        },
        buttonNext: {
          backgroundColor: '#0000ff',
          color: '#ffff',
          display: (steps.length > 0 && !steps[stepIndex]?.hideNextButton) ? undefined : 'none',
        },
        buttonSkip: {
          backgroundColor: '#ffff',
          color: '#000000',
        },
        tooltipContainer: {
          textAlign: 'justify'
        },
      }}
      callback={handleJoyrideCallback}
    />
  </>;
};

export default Onboarding;
