import React from 'react';
import cx from 'classnames';
import { Collapse } from 'antd';

import { LrvCollapseProps } from '../../interfaces/lrvCollapseProps';

import styles from './LrvCollapse.module.scss';

export const LrvCollapse = (props: LrvCollapseProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkCollapse : styles.lightCollapse, className),
  };

  return (
    <Collapse {...lrvProps}>
    </Collapse>
  );
};
