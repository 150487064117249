import { Form, InputRef } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import ActionButton from '../../common/components/buttons/ActionButton';

import './Impersonate.scss';
import styles from './Sidebar.module.scss';
import { UserImpersonate } from './interfaces';
import { fetchImpersonate, fetchUsers, setShowModalImpersonate } from './impersonateSlice';

export default function Impersonate () {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [users, setUsers] = useState<UserImpersonate[]>();

  const showModal = useSelector((state: Store) => state.impersonate.showModal);
  const isLoading = useSelector((state: Store) => state.impersonate.isUsersLoading);
  const usersImpersonate: UserImpersonate[] = useSelector((state: Store) => state.impersonate.users);
  const searchInput = useRef<InputRef | null>(null);

  useEffect(() => {
    if (!showModal) {
      return;
    }

    dispatch(fetchUsers());
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, [dispatch, showModal]);

  useEffect(() => {
    setUsers(usersImpersonate);
  }, [usersImpersonate]);

  const columns: ColumnsType<UserImpersonate> = [
    {
      key: 1,
      title: t('users.user'),
      width: '20%',
      render: (_, record: UserImpersonate) => {
        return <>{record.firstName} {record.lastName}</>;
      }
    },
    {
      key: 2,
      title: t('users.email'),
      dataIndex: 'email',
      width: '40%',
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 3,
      title: t('impersonate.company'),
      dataIndex: ['companyId', 'name'],
      width: '20%',
      responsive: ['lg'] as Breakpoint[],
    },
    {
      key: 4,
      dataIndex: 'impersonate',
      width: '20%',
      render: (_, record: UserImpersonate) => {
        const id = `impersonate_${record._id}_button`;
        return (
          <ActionButton
            type='primary'
            theme='light'
            id={id}
            onClick={() => {
              const params = { userId: record._id, lastName: record.lastName, firstName: record.firstName, companyId: record.companyId._id, email: record.email, active: record.active, isAssignedCompany: record.isAssignedCompany, allowXlsxAnalysisReport: record.allowXlsxAnalysisReport, haveAccessPaymentReport: record.haveAccessPaymentReport, language: record.language };
              dispatch(fetchImpersonate(params));
            }}
          >
            {t('impersonate.impersonate')}
          </ActionButton>
        );
      },
    },
  ];

  return <LrvModal
    theme='light'
    title={t('impersonate.impersonate')}
    open={showModal}
    destroyOnClose={true}
    onCancel={() => dispatch(setShowModalImpersonate(false))}
    className='impersonateModal'
    closeIcon={<Icon id='close_impersonate_modal' name='close' />}
    isLoading={isLoading}
  >
    <LrvForm theme='light' >
      <Form.Item>
        <LrvInput
          theme='light'
          id='impersonate_input'
          placeholder={t('impersonate.search')}
          ref={searchInput}
          onChange={(e) => {
            const value = e.target.value;
            const usersFilter: UserImpersonate[] = usersImpersonate.filter((user: UserImpersonate) => {
              return user.firstName.toLowerCase().includes(value.toLowerCase())
                || user.lastName.toLowerCase().includes(value.toLowerCase())
                || user.email.toLowerCase().includes(value.toLowerCase());
            });
            setUsers(usersFilter);
          }}
        />
      </Form.Item>

      <Form.Item className={styles.formItem}>
        <LrvTable
          theme='light'
          columns={columns}
          dataSource={users}
          pagination={{
            showSizeChanger: false,
            size: 'default',
          }}
          scroll={{ y: '' }}
          size='small'
        />
      </Form.Item>
    </LrvForm>

  </LrvModal>;
}
