import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiClient } from '../../utils/axios_instance';
import { CLIENTS_PUBLIC_URL } from '../../config/config.api';

import { ActivateAccountState } from './interfaces';

const initialState: ActivateAccountState = {
  errorCode: 0,
  okCode: 0,
  isLoading: false
};

const apiClient: ApiClient = new ApiClient(false);
const axios = apiClient.axios;

export const activateAccountSlice = createSlice({
  name: 'activateAccount',
  initialState,
  reducers: {
    setIsLoading: (state: ActivateAccountState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setErrorCode: (state: ActivateAccountState, action: PayloadAction<number>) => {
      state.errorCode = action.payload;
    },
    setOkCode: (state: ActivateAccountState, action: PayloadAction<number>) => {
      state.okCode = action.payload;
    },
  },
});

export const { setIsLoading, setErrorCode, setOkCode } = activateAccountSlice.actions;

export const activateAccount = (token: string) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  try {
    const response = await axios.post(`${CLIENTS_PUBLIC_URL}/${token}`);
    dispatch(setOkCode(response.status));
  } catch (error) {
    if (error.response?.status) {
      dispatch(setErrorCode(error.response.status));
    }
  }

  dispatch(setIsLoading(false));
};

export default activateAccountSlice.reducer;
