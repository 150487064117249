import { stockingPhaseTypes } from '../config/commons';
import {
  COMPANY_METRICS_URL_ADULT, COMPANY_METRICS_URL_JUVENILE, COMPANY_METRICS_URL_LARVAE,
  METRICS_URL_ADULT, METRICS_URL_JUVENILE, METRICS_URL_LARVAE
} from '../config/config.api';

export const getMetricUrl = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return METRICS_URL_LARVAE;

    case stockingPhaseTypes.JUVENILE:
      return METRICS_URL_JUVENILE;

    case stockingPhaseTypes.ADULT:
      return METRICS_URL_ADULT;
  }
};

export const getCompanyMetricUrl = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return COMPANY_METRICS_URL_LARVAE;

    case stockingPhaseTypes.JUVENILE:
      return COMPANY_METRICS_URL_JUVENILE;

    case stockingPhaseTypes.ADULT:
      return COMPANY_METRICS_URL_ADULT;
  }
};
