import React from 'react';
import cx from 'classnames';
import { Checkbox } from 'antd';

import { LrvCheckboxProps } from '../../interfaces/lrvCheckboxProps';

import styles from './LrvCheckbox.module.scss';

export const LrvCheckbox = (props: LrvCheckboxProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkCheckbox : styles.lightCheckbox, className),
  };

  return (
    <Checkbox {...lrvProps}>
    </Checkbox>
  );
};
