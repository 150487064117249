import i18next from 'i18next';
import { isDesktop } from 'react-device-detect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { editSampleError } from '../../../../config/commons';
import { ANALYSES_URL, DISEASES_URL, GENERATE_JUVENILE_MASK_URL } from '../../../../config/config.api';
import { axiosClient as axios } from '../../../../utils/axios_instance';
import { Animal, AnimalsByGroup, Disease, Percentile, SampleAnimal, SampleStatus } from '../interfaces';
import { openSuccessNotification, openErrorNotification } from '../../../../common/notification/Notification';
import { downloadData, downloadFile } from '../../../../utils/download';
import { GenericParam } from '../../../../common/interfaces/commons';

const initialState: SampleStatus = {
  editSample: false,
  changesSaved: false,
  showAnimalsRemoved: true,
  sampleAnimalList: [],
  percentileList: [],
  showQuantityAnimalsPanel: isDesktop,
  disease: undefined,
  diseases: [],
  showPanelToChangeGroup: false,
  showPanelToAddDisease: false,
  isDownloadingSample: false,
  isDownloadingMask: false,
  isDownloadingJuvenileMask: false,
};

export const sampleSlice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    setEditSample: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.editSample = action.payload;
    },
    setNewAnimal: (state: SampleStatus, action: PayloadAction<number[]>) => {
      state.newAnimal = action.payload;
    },
    resetNewAnimal: (state: SampleStatus) => {
      state.newAnimal = undefined;
    },
    setAnimalSelected: (state: SampleStatus, action: PayloadAction<Animal>) => {
      state.animalSelected = action.payload;
    },
    resetAnimalSelected: (state: SampleStatus) => {
      state.animalSelected = undefined;
    },
    setNewPercentile: (state: SampleStatus, action: PayloadAction<Percentile | AnimalsByGroup>) => {
      state.newPercentile = action.payload;
    },
    setOldPercentile: (state: SampleStatus, action: PayloadAction<Percentile | AnimalsByGroup>) => {
      state.oldPercentile = action.payload;
    },
    resetNewPercentile: (state: SampleStatus) => {
      state.newPercentile = undefined;
    },
    resetOldPercentile: (state: SampleStatus) => {
      state.oldPercentile = undefined;
    },
    setShowAnimalsRemoved: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.showAnimalsRemoved = action.payload;
    },
    setSampleAnimalList: (state: SampleStatus, action: PayloadAction<SampleAnimal[]>) => {
      state.sampleAnimalList = action.payload;
    },
    resetSampleAnimalList: (state: SampleStatus) => {
      state.sampleAnimalList = [];
    },
    setChangesSaved: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.changesSaved = action.payload;
    },
    setPercentileList: (state: SampleStatus, action: PayloadAction<Percentile[] | AnimalsByGroup[]>) => {
      state.percentileList = action.payload;
    },
    setShowQuantityAnimalsPanel: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.showQuantityAnimalsPanel = action.payload;
    },
    setDisease: (state: SampleStatus, action: PayloadAction<string>) => {
      state.disease = action.payload;
    },
    resetDisease: (state: SampleStatus) => {
      state.disease = undefined;
    },
    setDiseases: (state: SampleStatus, action: PayloadAction<string[]>) => {
      state.diseases = action.payload;
    },
    setShowPanelToChangeGroup: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.showPanelToChangeGroup = action.payload;
    },
    setShowPanelToAddDisease: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.showPanelToAddDisease = action.payload;
    },
    setIsDownloadingSample: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.isDownloadingSample = action.payload;
    },
    setIsDownloadingMask: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.isDownloadingMask = action.payload;
    },
    setIsDownloadingJuvenileMask: (state: SampleStatus, action: PayloadAction<boolean>) => {
      state.isDownloadingJuvenileMask = action.payload;
    },
  },
});

export const {
  setEditSample,
  setNewAnimal,
  resetNewAnimal,
  setAnimalSelected,
  resetAnimalSelected,
  setNewPercentile,
  setOldPercentile,
  resetNewPercentile,
  resetOldPercentile,
  setShowAnimalsRemoved,
  setSampleAnimalList,
  resetSampleAnimalList,
  setChangesSaved,
  setPercentileList,
  setShowQuantityAnimalsPanel,
  setDisease,
  resetDisease,
  setDiseases,
  setShowPanelToChangeGroup,
  setShowPanelToAddDisease,
  setIsDownloadingSample,
  setIsDownloadingMask,
  setIsDownloadingJuvenileMask,
} = sampleSlice.actions;

export const editSampleAnimals = (analysisId: string, sampleAnimalList: SampleAnimal[]) => async (dispatch: Function) => {
  const data = {
    animals: sampleAnimalList,
  };

  try {
    await axios.post(`${ANALYSES_URL}/${analysisId}/edit-sample-animals`, data);
    dispatch(resetSampleAnimalList());
    dispatch(setChangesSaved(true));
    openSuccessNotification(i18next.t('detail.manageAnimals.success'));
  } catch (error) {
    catchErrorsEditSample(error);
  }
};

export const fetchDiseases = () => async (dispatch: Function) => {
  const params = {
    $limit: -1,
  };

  try {
    const response = await axios.get<Disease[]>(DISEASES_URL, { params });
    const diseases = response.data.map((disease) => disease.disease);
    dispatch(setDiseases(diseases));
  } catch (error) {
    console.log(error?.response);
  }
};

const catchErrorsEditSample = (error: GenericParam) => {
  if (!error.response?.data) {
    openErrorNotification(i18next.t('detail.manageAnimals.openSampleAgain'));
  }

  const typeError = error.response?.data?.data?.error;
  switch (typeError) {
    case editSampleError.SAMPLE_WAS_EDITED:
      openErrorNotification(i18next.t('detail.manageAnimals.disabledEdit'));
      break;

    case editSampleError.COORDINATES_REMOVED:
      openErrorNotification(i18next.t('detail.manageAnimals.withoutCoordinates'));
      break;

    case editSampleError.IS_EDITING_SAMPLE:
      openErrorNotification(i18next.t('detail.manageAnimals.isEditingSample'));
      break;
  }
};

export const downloadImage = (url: string, nameFile: string) => async (dispatch: Function) => {
  dispatch(setIsDownloadingSample(true));
  try {
    await downloadFile(url, nameFile, 'jpg');
  } catch (error) {
    console.log('ERROR', error);
  }
  dispatch(setIsDownloadingSample(false));
};

export const downloadMask = (url: string, nameFile: string, format: string) => async (dispatch: Function) => {
  dispatch(setIsDownloadingMask(true));
  try {
    await downloadFile(url, nameFile, format);
  } catch (error) {
    console.log('ERROR', error);
  }
  dispatch(setIsDownloadingMask(false));
};

export const pingGenerateJuvenileMask = async () => {

  try {
    await axios.get(GENERATE_JUVENILE_MASK_URL, { params: { ping: 1 } });
  } catch (error) {
    console.log(error?.response);
  }
};

export const generateJuvenileMask = (params: { analysisCode: string; bucketName: string; imageName: string; format: string }) => async (dispatch: Function) => {
  const { analysisCode, bucketName, imageName, format } = params;
  dispatch(setIsDownloadingJuvenileMask(true));

  await pingGenerateJuvenileMask();

  try {
    const response = await axios.get<BlobPart>(GENERATE_JUVENILE_MASK_URL, {
      params: {
        imageName, bucketName
      },
      responseType: 'blob',
    });

    downloadData(response.data, analysisCode, format);

  } catch (error) {
    openErrorNotification(i18next.t('download.error'));
    console.log(error?.response);
  }
  dispatch(setIsDownloadingJuvenileMask(false));
};

export default sampleSlice.reducer;
