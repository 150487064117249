export const textColor = {
  dark: '#dedede',
  light: '#222222',
};

export const getTextColor = (theme?: 'dark' | 'light') => {
  switch (theme) {
    case 'dark':
    default:
      return textColor.dark;


    case 'light':
      return textColor.light;
  }
};
