import moment from 'moment';
import { clone } from 'lodash';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Select, Space } from 'antd';

import '../ActivityDashboard.scss';
import { getAnalysisUsageLabel } from '../helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getTextColor } from '../../../helpers/chartjs.helpers';
import { Campus, Maturation, StageActivity } from '../interfaces';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import FullScreen from '../../../common/components/FullScreen/FullScreen';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { animalDaysUnit, isAdminUser, stockingPhaseTypes, THEME } from '../../../config/commons';
import { fetchMaturations, fetchStagesActivity, fetchStagesFullActivity, setStagesFullActivity } from '../activityDashboardSlice';

import styles from './StagesActivity.module.scss';

const { Option } = Select;

interface Props {
  theme?: 'dark' | 'light';
}

function StagesActivity (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLightTheme = theme === THEME.LIGHT;
  const barBgColor = isLightTheme ? 'rgb(27, 98, 230)' : 'rgb(0, 0, 255)';
  const barBorderColor = isLightTheme ? 'rgb(27, 98, 230)' : 'rgb(0, 0, 255)';

  const fromDate = useSelector((state: Store) => state.activityDashboard.filters.fromDate);
  const toDate = useSelector((state: Store) => state.activityDashboard.filters.toDate);
  const selectedCampus = useSelector((state: Store) => state.activityDashboard.filters.campus as Campus);
  const stagesActivity = useSelector((state: Store) => state.activityDashboard.stagesActivity as StageActivity[]);
  const stagesFullActivity = useSelector((state: Store) => state.activityDashboard.stagesFullActivity as StageActivity[]);
  const companyMaturations = useSelector((state: Store) => state.activityDashboard.companyMaturations as Maturation[]);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [selectMaturation, setSelectMaturation] = useState({ _id: '', name: '' });
  const lastDays = moment(toDate).diff(moment(fromDate), 'days');

  useEffect(() => {
    if (!selectedCompany._id || selectedCompany.maxStage === 1) {
      return;
    }

    dispatch(fetchStagesActivity({ fromDate, toDate, phaseType, companyId: selectedCompany._id, campusId: selectedCampus._id }));
  }, [dispatch, fromDate, phaseType, selectedCampus, selectedCompany, toDate]);

  let labels;

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
      labels = stagesActivity.map(activity => `${animalDaysUnit.PL}${activity.stage}`);
      break;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      labels = stagesActivity.map(activity => `${activity.stage}${animalDaysUnit.D}`);
      break;
  }

  const stagesActivityData = {
    labels,
    datasets: [
      {
        backgroundColor: barBgColor,
        borderColor: barBorderColor,
        borderWidth: 0,
        data: stagesActivity.map(activity => activity.count)
      }
    ]
  };

  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
      labels = stagesFullActivity.map(activity => `${animalDaysUnit.PL}${activity.stage}`);
      break;

    case stockingPhaseTypes.JUVENILE:
    case stockingPhaseTypes.ADULT:
      labels = stagesFullActivity.map(activity => `${activity.stage}${animalDaysUnit.D}`);
      break;
  }

  const stagesActivityFullData = {
    labels: labels,
    datasets: [
      {
        backgroundColor: barBgColor,
        borderColor: barBorderColor,
        borderWidth: 0,
        data: stagesFullActivity.map(activity => activity.count)
      }
    ]
  };

  function renderWidget () {
    return <div className={styles.container}>
      <div className={styles.actions}>
        <IconButton
          id='open_analysis_by_stage_button'
          onClick={() => {
            if (isRunningOnboarding) {
              return;
            }
            setShowFullScreenModal(true);
            dispatch(fetchMaturations(selectedCompany._id));
            dispatch(setStagesFullActivity(clone(stagesActivity) as []));
            setSelectMaturation({ _id: '', name: '' });
          }}
          iconName='fullscreen'
          size='small'
        />
      </div>
      {renderGraph(stagesActivityData)}
    </div>;
  }

  function renderFullWidget () {
    return <div className={styles.containerFull}>
      <Row gutter={16} className='row'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className={styles.filters} style={{ marginLeft: '45px', marginTop: '10px' }}>
            <Space align='end'>
              <LrvSelect
                id='dropdown_maturations'
                showSearch
                value={selectMaturation._id === '' ? undefined : selectMaturation._id}
                style={{ width: 220 }}
                suffixIcon={<Icon name='arrow-down-s' theme={theme} />}
                title={t('clientDashboard.selectMaturation')}
                placeholder={t('clientDashboard.selectMaturation')}
                optionFilterProp='children'
                theme={theme}
                onSelect={(key, option) => {
                  setSelectMaturation({ _id: `${option?.value || ''}`, name: `${option?.children || ''}` });
                  dispatch(fetchStagesFullActivity({
                    fromDate,
                    toDate,
                    phaseType: phaseType,
                    companyId: selectedCompany._id,
                    campusId: selectedCampus._id,
                    maturationId: `${option?.value || ''}`
                  }, selectedCompany.maxStage));
                }}
                filterOption={(input, option) => {
                  const children = `${option?.children || ''}`;
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {companyMaturations ? companyMaturations.map((maturation: Maturation, index) =>
                  <Option key={index} value={maturation._id}>{maturation.name}</Option>
                ) : null}
              </LrvSelect>

              <CleanButton
                placement='left'
                theme={theme}
                onClick={() => {
                  setSelectMaturation({ _id: '', name: '' });
                  dispatch(fetchStagesFullActivity({
                    fromDate,
                    toDate,
                    phaseType: phaseType,
                    companyId: selectedCompany._id,
                    campusId: selectedCampus._id
                  }, selectedCompany.maxStage));
                }}
                className={styles.cleanBtn}
              />
            </Space>
          </div>
        </Col >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className='customCol'>
          {renderGraph(stagesActivityFullData)}
        </Col>
      </Row >
    </div >;
  }

  function renderGraph (data: ChartData<'bar', (number | [number, number] | null)[], unknown>) {
    return <Bar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 0
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const tooltipItem = context.dataset.data[context.dataIndex];
                return ` ${tooltipItem}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              color: getTextColor(theme),
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: getTextColor(theme),
            }
          }
        },
      }}
    />;
  }

  function renderModalTitle () {
    const campus = selectedCampus._id === '' ? t('clientDashboard.allCampus') : selectedCampus.name;
    const amountAnalysis = t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis() });

    if (isAdminUser()) {
      return `${getAnalysisUsageLabel(phaseType)} - ${selectedCompany.name} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
    }

    return `${getAnalysisUsageLabel(phaseType)} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
  }

  function getAmountAnalysis () {
    let value = 0;
    for (let index = 0; index < stagesFullActivity.length; index++) {
      value += stagesFullActivity[index].count;
    }

    return value;
  }

  return <div>
    {renderWidget()}
    <FullScreen
      title={renderModalTitle()}
      show={showFullScreenModal}
      onClose={() => setShowFullScreenModal(false)}
      theme={theme}
    >
      {renderFullWidget()}
    </FullScreen>
  </div>;
}

export default StagesActivity;
