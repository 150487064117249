import i18next from 'i18next';

import { finishStockingTabs } from '../config/commons';

export const getTitleModal = (currentTab: string) => {
  switch (currentTab) {
    case finishStockingTabs.HARVEST:
    default:
      return i18next.t('stockings.finishStockingLabels.title.harvest');

    case finishStockingTabs.DISCARDED:
      return i18next.t('stockings.finishStockingLabels.title.discard');

    case finishStockingTabs.TRANSFER:
      return i18next.t('stockings.finishStockingLabels.title.transfer');
  }
};
