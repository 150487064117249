import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { unitStatuses } from '../../../config/commons';
import { formatYearMonthDay } from '../../../utils/date';
import { downloadXlsxFile } from '../../../utils/download';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openErrorNotification } from '../../../common/notification/Notification';
import { ACTIVE_TANKS_URL, CAMPUS_URL, MODULES_URL, REFERENCE_CURVES_BY_FILTERS_URL, REFERENCE_CURVES_URL, XLSX_ACTIVE_TANKS_REPORT_URL } from '../../../config/config.api';

import { ActiveTankState, Campus, Module, ReferenceCurves, Tank } from './interfaces';

const initialState: ActiveTankState = {
  isLoading: false,
  isDownloadingActiveTanks: false,
  activeTanks: [],
  campuses: [],
  modules: [],
  referenceCurve: {
    _id: '',
    campusId: '',
    companyId: '',
    name: '',
    phaseType: '',
    values: [],
  },
  globalReferenceCurves: [],
  companyReferenceCurves: [],
  unitReferenceCurves: [],
  firstStage: 0,
  lastStage: 0,
  maxStage: 0,
};

export const activeTanks = createSlice({
  name: 'activeTanks',
  initialState,
  reducers: {
    setIsLoading: (state: ActiveTankState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActiveTanks: (state: ActiveTankState, action: PayloadAction<Tank[]>) => {
      state.activeTanks = action.payload;
    },
    setCampuses: (state: ActiveTankState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },
    setCampus: (state: ActiveTankState, action: PayloadAction<Campus | undefined>) => {
      state.campus = action.payload;
    },
    setModules: (state: ActiveTankState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setModule: (state: ActiveTankState, action: PayloadAction<Module | undefined>) => {
      state.module = action.payload;
    },
    setParameterSelected: (state: ActiveTankState, action: PayloadAction<string | undefined>) => {
      state.parameter = action.payload;
    },
    setIsDownloadingActiveTanks: (state: ActiveTankState, action: PayloadAction<boolean>) => {
      state.isDownloadingActiveTanks = action.payload;
    },
    setReferenceCurve: (state: ActiveTankState, action: PayloadAction<ReferenceCurves | undefined>) => {
      state.referenceCurve = action.payload;
    },
    setGlobalReferenceCurves: (state: ActiveTankState, action: PayloadAction<ReferenceCurves[]>) => {
      state.globalReferenceCurves = action.payload;
    },
    setCompanyReferenceCurves: (state: ActiveTankState, action: PayloadAction<ReferenceCurves[]>) => {
      state.companyReferenceCurves = action.payload;
    },
    setUnitReferenceCurves: (state: ActiveTankState, action: PayloadAction<ReferenceCurves[]>) => {
      state.unitReferenceCurves = action.payload;
    },
    setFirstStage: (state: ActiveTankState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: ActiveTankState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },
    setMaxStage: (state: ActiveTankState, action: PayloadAction<number>) => {
      state.maxStage = action.payload;
    },
  },
});

export const {
  setIsLoading,
  setActiveTanks,
  setCampuses,
  setCampus,
  setModules,
  setModule,
  setParameterSelected,
  setReferenceCurve,
  setIsDownloadingActiveTanks,
  setGlobalReferenceCurves,
  setCompanyReferenceCurves,
  setUnitReferenceCurves,
  setFirstStage,
  setLastStage,
  setMaxStage,
} = activeTanks.actions;

export const fetchActiveTanks = (params: { companyId: string; phaseType: string; campusId: string; moduleId: string; }) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  try {
    const response = await axios.get<Tank[]>(ACTIVE_TANKS_URL, { params });
    dispatch(setActiveTanks(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchUrlActiveTanks = (props: { companyId: string, phaseType: string; userLang: string; campusId: string; campusName: string; timezone: string; moduleId: string; moduleName: string; }) => async (dispatch: Function) => {
  const { companyId, phaseType, userLang, campusId, campusName, timezone, moduleId, moduleName } = props;

  dispatch(setIsDownloadingActiveTanks(true));

  const config = {
    params: {
      companyId,
      phaseType,
      userLang,
      campusId,
      campusName,
      timezone,
      moduleId,
      moduleName,
    }
  };

  const currentDate = new Date().toString();
  const fileName = `${i18next.t('activeTanks.header').toUpperCase()}_${formatYearMonthDay(currentDate)}`;

  try {
    const response = await axios.get(XLSX_ACTIVE_TANKS_REPORT_URL, config);
    const xlsxData = response.data;

    downloadXlsxFile({ xlsxData, fileName: fileName.replace(' ', '_') });
  } catch (error) {
    console.log(error);
    openErrorNotification(i18next.t('download.error'));
  }

  dispatch(setIsDownloadingActiveTanks(false));
};

export const fetchCampuses = (companyId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    companyId,
    active: true,
    status: unitStatuses.ACTIVE,
    phaseType,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (campusId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    campusId,
    active: true,
    phaseType,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchGlobalReferenceCurves = (props: { phaseType: string; type: string }) => async (dispatch: Function) => {
  const { phaseType, type } = props;

  const params = {
    $limit: -1,
    phaseType,
    type,
    'companyId[$exists]': false,
  };

  try {
    const response = await axios.get(REFERENCE_CURVES_URL, { params });
    dispatch(setGlobalReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyReferenceCurves = (params: { companyId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  dispatch(setCompanyReferenceCurves([]));

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setCompanyReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnitsReferenceCurves = (params: { companyId: string; campusId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  dispatch(setUnitReferenceCurves([]));

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setUnitReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export default activeTanks.reducer;
