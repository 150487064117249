import moment from 'moment';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { DATE_FORMATS } from '../../../config/commons';
import { LrvCalendar } from '../../../common/components/LrvCalendar/LrvCalendar';

import './MonthlyUserActivity.scss';
import { getBackgroundColorCell } from './helpers';
import styles from './MonthlyUserActivity.module.scss';

export default function MonthlyUserActivity () {
  const { filters, userActivity } = useSelector((state: Store) => state.userActivityDashboard);
  const { dateSelected } = filters;

  const renderDateCell = (date: moment.Moment) => {
    const activity = userActivity.find((activity) => activity.day === date.format(DATE_FORMATS.YYYY_MM_DD).toString());

    if (!activity || !activity.count) {
      return null;
    }

    const parentRef = useRef<HTMLDivElement>(null);

    if (parentRef.current?.parentElement?.parentElement?.style) {
      parentRef.current.parentElement.parentElement.style.backgroundColor = getBackgroundColorCell(activity.count);
    }

    return (
      <div
        key={activity.day}
        className={styles.cell}
        ref={parentRef}
      >
        {activity.count}
      </div>
    );
  };

  const disabledCalendarDate = (current: moment.MomentInput) => {
    const start = moment().startOf('month');
    const end = moment().endOf('month');
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  };

  return (
    <div className={cx(styles.content, 'monthlyUserActivity')}>
      <LrvCalendar
        theme='light'
        className={styles.calendar}
        dateCellRender={renderDateCell}
        fullscreen={true}
        value={moment(dateSelected)}
        headerRender={() => null}
        disabledDate={disabledCalendarDate}
      />
    </div>
  );
}