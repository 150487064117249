import { Row, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../../common/components/buttons/ActionButton';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';

import { getUpdatedContainers } from './helpers';
import styles from './ContainerMapFilters.module.scss';
import * as containerMapSlice from './containerMapSlice';

const { Option } = Select;

interface Props {
  refFilters: React.RefObject<HTMLDivElement>;
  theme?: 'dark' | 'light';
}

export const ContainerMapFilters = (props: Props) => {
  const { refFilters, theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters, isSaving } = useSelector((state: Store) => state.containerMap);
  const { phaseType } = useSelector((state: Store) => state.header);

  const {
    unitId,
    units,
    moduleId,
    modules,
    containers,
  } = filters;

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_units'
        theme={theme}
        className={className}
        value={unitId || undefined}
        onChange={(value) => {
          dispatch(containerMapSlice.setUnitId(value));
          dispatch(containerMapSlice.setModuleId(''));
          dispatch(containerMapSlice.setLocatedContainers([]));
          dispatch(containerMapSlice.setUnlocatedContainers([]));
          dispatch(containerMapSlice.fetchModules({ phaseType, unitId: value }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('containerMap.filters.unit')}
        placeholder={t('containerMap.filters.unit')}
        dropdownMatchSelectWidth={false}
      >
        {units.map((unit) => <Option key={unit._id} value={unit._id}>{unit.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules'
        theme={theme}
        className={className}
        value={moduleId || undefined}
        onChange={(value) => {
          dispatch(containerMapSlice.setModuleId(value));
          dispatch(containerMapSlice.fetchContainers({ unitId, moduleId: value }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('containerMap.filters.module')}
        placeholder={t('containerMap.filters.module')}
        dropdownMatchSelectWidth={false}
      >
        {modules.map((item) => <Option key={item._id} value={item._id}>{item.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon
          title={<LrvText className={styles.title} theme='light' text={t('containerMap.title')} />}
          cleanButtonProps={{
            onClick: () => {
              dispatch(containerMapSlice.resetFilters());
            },
          }}
        >
          <Space direction='vertical' className={styles.bodyPanel}>
            {renderUnitsDropdown({ theme: 'light' })}
            {renderModulesDropdown({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(containerMapSlice.resetFilters())}
      />
    );
  };

  const saveUpdatedLocations = () => {
    return (
      <ActionButton
        id='btn_save_locations'
        className={styles.addButton}
        type='primary'
        disabled={getUpdatedContainers({ locatedContainers: containers.located }).length === 0}
        loading={isSaving}
        onClick={() => {
          const updatedContainers = getUpdatedContainers({ locatedContainers: containers.located });
          dispatch(containerMapSlice.updateLocationContainers({ containers: updatedContainers, moduleId, unitId }));
        }}
      >
        {t('campus.save')}
      </ActionButton>
    );
  };

  return (
    <Row className={styles.container} ref={refFilters}>
      <Space className={styles.filters} align='end'>
        {renderUnitsDropdown({ className: styles.select, theme })}
        {renderModulesDropdown({ className: styles.select, theme })}
        {renderCleanButton()}
      </Space>

      {renderSidePanel()}

      <Row className={styles.rowRight}>
        <Space align='center' size='middle'>
          {saveUpdatedLocations()}
        </Space>
      </Row>
    </Row>
  );
};
