import { useEffect, useState } from 'react';

import { Stocking } from '../pages/Sowings/interfaces';
import * as sowingsSlice from '../pages/Sowings/sowingsSlice';

export const useGetPrevStocking = (tankId: string) => {
  const [previousStocking, setPreviousStocking] = useState<Stocking | undefined>();

  useEffect(() => {
    const fetchPreviousStocking = async () => {
      const stocking = await sowingsSlice.fetchPreviousStockingByTank(tankId);
      setPreviousStocking(stocking);
    };

    if (tankId) {
      fetchPreviousStocking();
    }
  }, [tankId]);
  
  return {
    previousStocking,
  };
};