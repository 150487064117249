import React from 'react';
import cx from 'classnames';
import { Tooltip } from 'antd';

import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';
import { LrvTooltipProps } from '../../interfaces/lrvTooltip';

import styles from './LrvTooltip.module.scss';

export const LrvTooltip = (props: LrvTooltipProps) => {
  const { themeStyle = getCurrentTheme(), children, overlayClassName, showArrow = true } = props;
  const isLightTheme = themeStyle === THEME.LIGHT;

  return (
    <Tooltip
      {...props}
      showArrow={showArrow}
      overlayClassName={cx(overlayClassName, styles.lrvTooltip, isLightTheme ? styles.lightStyle : styles.darkStyle)}
    >
      {children}
    </Tooltip>
  );
};
