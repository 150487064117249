import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { sortByName } from '../../../utils/sort';
import { StockingPayload } from '../../Sowings/interfaces';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { Maturation, MaturationCode } from '../../Clients/interfaces';
import { stockingPhaseTypes, stockingSearchTypes, unitStatuses } from '../../../config/commons';
import { STOCKINGS_URL, CAMPUS_URL, MODULES_URL, TANKS_URL, MATURATIONS_URL } from '../../../config/config.api';

import { Container, Module, OtherStockingState, Unit } from './interfaces';

const pagination = 10;

const initialState: OtherStockingState = {
  campuses: [],
  modules: [],
  tanks: [],
  isFetchStockings: false,
  total: 0,
  skip: 0,
  limit: 0,
  stockings: [],
  maturationCodes: [],
  maturations: [],
  searchType: stockingSearchTypes.NAME,
};

export const otherStockingsSlice = createSlice({
  name: 'otherStockings',
  initialState,
  reducers: {
    setStockings: (state: OtherStockingState, action: PayloadAction<StockingPayload>) => {
      state.stockings = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
    },
    setCampuses: (state: OtherStockingState, action: PayloadAction<Unit[]>) => {
      state.campuses = action.payload;
    },
    setModules: (state: OtherStockingState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setTanks: (state: OtherStockingState, action: PayloadAction<Container[]>) => {
      state.tanks = action.payload;
    },
    setIsFetchStockings: (state: OtherStockingState, action: PayloadAction<boolean>) => {
      state.isFetchStockings = action.payload;
    },
    setMaturationCodes: (state: OtherStockingState, action: PayloadAction<MaturationCode[]>) => {
      state.maturationCodes = action.payload;
    },
    setMaturations: (state: OtherStockingState, action: PayloadAction<Maturation[]>) => {
      state.maturations = action.payload;
    },
    setSearchType: (state: OtherStockingState, action: PayloadAction<string>) => {
      state.searchType = action.payload;
    },
  },
});

export const {
  setStockings,
  setCampuses,
  setModules,
  setTanks,
  setIsFetchStockings,
  setMaturationCodes,
  setMaturations,
  setSearchType,
} = otherStockingsSlice.actions;

export const fetchStockings = (
  props: { page: number; companyId?: string; campusId?: string; moduleId?: string; tankId?: string; maturationId?: string; maturationCode?: string; name?: string; code?: string; phase: string }
) => async (dispatch: Function) => {
  const { page, companyId, campusId, moduleId, tankId, maturationId, maturationCode, name, code, phase } = props;
  let skip = 0;
  dispatch(setIsFetchStockings(true));

  if (page !== 0) {
    skip = pagination * (page - 1);
  }

  const params = {
    $limit: pagination,
    $skip: skip,
    active: true,
    phaseType: phase,
    companyId: companyId,
    '$sort[startDate]': -1,
    $select: ['name', 'status', 'startDate', 'analysisCount', 'startDateJuvenile', 'startDateGrowOut', 'phaseType', 'naupliusNumber', 'juvenilesNumber', 'growOutNumber', 'litersNumber', 'hectares', 'cubicMeters'],
    maturationId: maturationId || undefined,
    maturationCode: maturationCode || undefined,
    tankId: tankId || undefined,
    moduleId: moduleId || undefined,
    campusId: campusId || undefined,
    name: name || undefined,
    code: code || undefined,
    '$sort[startDateJuvenile]': phase === stockingPhaseTypes.JUVENILE ? -1 : undefined,
    '$sort[startDateGrowOut]': phase === stockingPhaseTypes.ADULT ? -1 : undefined,
  };

  try {
    const response = await axios.get(STOCKINGS_URL, { params: params });
    dispatch(setStockings(response.data));
    dispatch(setIsFetchStockings(false));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchCampuses = (props: { companyId: string; phaseType: string }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  const userSession = getUserSession();
  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    phaseType: phaseType,
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setModules(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchTanks = (campusId?: string, moduleId?: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    moduleId: moduleId || undefined,
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params: params });
    response.data.sort(sortByName);
    dispatch(setTanks(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchMaturations = (companyId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    companyId,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Maturation[]>(MATURATIONS_URL, { params: params });
    dispatch(setMaturations(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchMaturationCodes = (maturationId: string) => async (dispatch: Function) => {
  const params = { $select: ['codes'] };
  const url = `${MATURATIONS_URL}/${maturationId}`;

  try {
    const response = await axios.get<Maturation>(url, { params });
    const maturationCodes = response.data.codes.filter((code) => code.active);
    dispatch(setMaturationCodes(maturationCodes));
  } catch (e) {
    console.log(e?.response);
  }
};

export default otherStockingsSlice.reducer;
