import { stockingPhaseTypes } from '../../../config/commons';

import { ParameterTypes, StockingParameterGraph, StockingParameterValue, ReferenceCurve } from './interfaces';

export const minimumItems = 2;
export const stockingParameterTypes: ParameterTypes[] = ['averageWeight', 'biomass', 'totalFeed'];

export const getAxisXDate = (props: { stockingParameters: StockingParameterValue[]; }) => {
  const { stockingParameters } = props;

  const dates: Date[] = [];

  for (let index = 0; index < stockingParameters.length; index++) {
    const stockingParameter = stockingParameters[index];
    dates.push(new Date(stockingParameter.date));
  }

  const minDate = new Date(Math.min(...dates.map(date => date.getTime())));
  const maxDate = new Date(Math.max(...dates.map(date => date.getTime())));

  return {
    minDate,
    maxDate,
  };
};

export const getAxisY = (props: { stockingParameters: StockingParameterValue[]; referenceCurves: StockingParameterValue[]; }) => {
  const { stockingParameters, referenceCurves } = props;

  const values: number[] = [];

  for (let index = 0; index < stockingParameters.length; index++) {
    const stockingParameter = stockingParameters[index];
    values.push(stockingParameter.value);
  }

  for (let index = 0; index < referenceCurves.length; index++) {
    const referenceCurve = referenceCurves[index];
    values.push(referenceCurve.value);
  }

  const minY = Math.min(...values);
  const maxY = Math.max(...values);

  return {
    minY,
    maxY,
  };
};

export const getDataParameter = (props: { item: StockingParameterGraph; selectedParameterName: ParameterTypes }) => {
  const { item, selectedParameterName } = props;

  switch (selectedParameterName) {
    case 'averageWeight':
      return item.averageWeights;

    case 'biomass':
      return item.biomasses;

    case 'totalFeed':
      return item.totalFeeds;
  }
};

export const getAllDataParameters = (props: { stockingParameterGraphs: StockingParameterGraph[]; selectedParameterName: ParameterTypes }) => {
  const { stockingParameterGraphs, selectedParameterName } = props;

  switch (selectedParameterName) {
    case 'averageWeight':
      return stockingParameterGraphs.flatMap((item) => item.averageWeights.values);

    case 'biomass':
      return stockingParameterGraphs.flatMap((item) => item.biomasses.values);

    case 'totalFeed':
      return stockingParameterGraphs.flatMap((item) => item.totalFeeds.values);
  }
};

export const existsMinimumDataParameters = (props: { stockingParameterGraphs: StockingParameterGraph[]; selectedParameterName: ParameterTypes }) => {
  const { stockingParameterGraphs, selectedParameterName } = props;

  switch (selectedParameterName) {
    case 'averageWeight':
      return stockingParameterGraphs.filter((item) => item.averageWeights.values.length > 0).length >= minimumItems;

    case 'biomass':
      return stockingParameterGraphs.filter((item) => item.biomasses.values.length > 0).length >= minimumItems;

    case 'totalFeed':
      return stockingParameterGraphs.filter((item) => item.totalFeeds.values.length > 0).length >= minimumItems;
  }
};

export const generateStockingParameterValues = (props: { stockingParameters: StockingParameterValue[]; referenceCurve?: ReferenceCurve; phaseType: string }): StockingParameterValue[] => {
  const { referenceCurve, stockingParameters, phaseType } = props;

  if (!referenceCurve?._id) {
    return [];
  }

  const divider = phaseType === stockingPhaseTypes.LARVAE ? 1 : 1000;
  const result: StockingParameterValue[] = [];

  stockingParameters.forEach((stockingParameter) => {
    const itemReferenceCurves = referenceCurve.values.find((curve) => curve.stage === stockingParameter.stage);

    if (itemReferenceCurves) {
      result.push({
        date: stockingParameter.date,
        value: itemReferenceCurves.mean / divider,
        stage: stockingParameter.stage,
      });
    }
  });

  return result;
};
