import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';

import styles from './GeneticsSetup.module.scss';
import Maturations from './Maturations/Maturations';

const GeneticsSetup = () => {
  const dispatch = useDispatch();
  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'maturations.title' }));
  }, [dispatch]);

  return (
    <div className={styles.genetics}>
      <Maturations theme={theme} />
    </div>
  );
};

export default GeneticsSetup;