import { ReactNode } from 'react';

import Icon from '../Icon/Icon';

import styles from './ItemRenderPagination.module.scss';

export const ItemRenderPagination = (page: number, type: string, originalElement: ReactNode) => {
  if (type === 'prev') {
    return <Icon id='page_prev' name='arrow-left-s' className={styles.arrow} />;
  }

  if (type === 'next') {
    return <Icon id='page_next' name='arrow-right-s' className={styles.arrow} />;
  }

  return originalElement;
};