import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { getMainRole, roles } from '../../config/commons';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';

import styles from './ReferenceCurves.module.scss';
import UnitsReferenceCurves from './UnitsReferenceCurves/UnitsReferenceCurves';
import GlobalReferenceCurves from './GlobalReferenceCurves/GlobalReferenceCurves';
import CompanyReferenceCurves from './CompanyReferenceCurves/CompanyReferenceCurves';

const ReferenceCurves = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const higherRole = getMainRole();
  const hasPermissions = higherRole === roles.SUPER_ADMIN || higherRole === roles.BUSINESS_MANAGER || higherRole === roles.SALES || higherRole === roles.SALES_MANAGER || higherRole === roles.CLIENT_OWNER || higherRole === roles.PARTIAL_ADMIN;

  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'references.title' }));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <LrvTabs defaultActiveKey='1' theme={theme}>
        <TabPane tab={<div id='tab_global_reference_curves'>{t('sidebar.global')}</div>} key='GLOBAL_REFERENCE_CURVES'>
          <GlobalReferenceCurves theme={theme} />
        </TabPane>

        {
          hasPermissions &&
          <TabPane tab={<div id='tab_company_reference_curves'>{t('header.company')}</div>} key='COMPANY_REFERENCE_CURVES'>
            <CompanyReferenceCurves theme={theme} />
          </TabPane>
        }

        <TabPane tab={<div id='tab_units_reference_curves'>{t('campus.title')}</div>} key='UNIT_REFERENCE_CURVES'>
          <UnitsReferenceCurves theme={theme} />
        </TabPane>
      </LrvTabs>
    </div>
  );
};

export default ReferenceCurves;