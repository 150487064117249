import cx from 'classnames';
import React, { ReactNode } from 'react';

import Icon from '../../components/Icon/Icon';
import IconButton from '../buttons/IconButton';

import styles from './FullScreen.module.scss';

interface Props {
  children: ReactNode;
  title: string | ReactNode;
  show: boolean;
  bodyClassName?: string;
  className?: string;
  onClose: React.MouseEventHandler<HTMLElement>;
  theme?: 'dark' | 'light';
}

export default function FullScreen (props: Props) {
  const { children, title, show, onClose, className, bodyClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  return (
    <div id='modal_fullscreen' className={cx(styles.fullScreenFrame, isDarkTheme ? styles.fullScreenFrameDark : styles.fullScreenFrameLight, className, show ? styles.showFullScreen : styles.hideFullScreen)}>
      <div id='header_fullscreen' className={styles.header}>
        <div className={cx(styles.title, isDarkTheme ? styles.titleDark : styles.titleLight)}>
          {title}
        </div>
        <div className={styles.close}>
          <IconButton
            onClick={onClose}
            icon={<Icon id='close_fullscreen' name='close' theme={theme} />}
          />
        </div>
      </div>
      <div className={cx(styles.body, bodyClassName)}>
        {children}
      </div>
    </div>
  );
}
