import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Space, Form, Button, Descriptions } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UpdateMaturationProps } from '../interfaces';
import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { MaturationCode } from '../../../Clients/interfaces';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvTooltip } from '../../../../common/components/LrvTooltip/LrvTooltip';
import { LrvDescriptions } from '../../../../common/components/LrvDescriptions/LrvDescriptions';

import styles from './EditMaturation.module.scss';
import * as companyMaturationsSlice from './companyMaturationsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function EditInactiveMaturationCodes (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [maturationCodes, setMaturationCodes] = useState<MaturationCode[]>([]);

  const { isCreating, showEditInactiveMaturationCodesModal, selectedMaturation } = useSelector((state: Store) => state.companyMaturations);

  useEffect(() => {
    if (!selectedMaturation?._id) {
      return;
    }

    const inactiveCodes = selectedMaturation.codes ? selectedMaturation.codes.filter((code) => !code.active) : [];
    setMaturationCodes(inactiveCodes);
  }, [selectedMaturation]);

  const closeModal = () => {
    dispatch(companyMaturationsSlice.setShowEditInactiveMaturationCodesModal(false));
    dispatch(companyMaturationsSlice.setSelectedMaturation(undefined));

    setMaturationCodes([]);
  };

  const isValidMaturationCode = () => {
    if (!maturationCodes) {
      return true;
    }

    for (let index = 0; index < maturationCodes.length; index++) {
      const code = maturationCodes[index];
      if (!code.code) {
        return false;
      }
    }

    return true;
  };

  const renderCodeInputs = () => {
    return maturationCodes.map((maturationCode: MaturationCode, index: number) => {
      return (
        <Space className={cx(styles.codeRow, !maturationCode.isCodeBase && styles.codeRowWithButton)}>
          <LrvInput
            id='txt_new_maturation_code'
            theme='light'
            value={maturationCode.code}
            placeholder={t('maturations.code')}
            autoFocus
            readOnly={maturationCode.isCodeBase}
            onChange={(e) => {
              const newData: MaturationCode[] = cloneDeep(maturationCodes);
              const value = e.target.value;
              newData[index].code = value;
              setMaturationCodes(newData);
            }}
          />
          {
            maturationCode.isCodeBase ?
              <LrvTooltip
                themeStyle='light'
                title={t('maturations.catalogCode')}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <Icon name='information' theme={theme} />
                </div>
              </LrvTooltip>
              :
              <Button
                className={maturationCode.active ? styles.deactiveMaturationButton : styles.activeMaturationButton}
                onClick={() => {
                  const newData: MaturationCode[] = cloneDeep(maturationCodes);
                  newData[index].active = !newData[index].active;
                  setMaturationCodes(newData);
                }}
              >
                {maturationCode.active ? t('maturations.deactivate') : t('maturations.activate')}
              </Button>
          }
        </Space>
      );
    });
  };

  const disabledForm = () => {
    return !isValidMaturationCode();
  };

  const onUpdateMaturation = () => {
    if (!selectedMaturation?._id) {
      return;
    }

    const activeCodes = selectedMaturation.codes ? selectedMaturation.codes.filter((code) => code.active) : [];
    const codes = [...maturationCodes, ...activeCodes];

    const maturation: UpdateMaturationProps = {
      _id: selectedMaturation._id,
      active: selectedMaturation.active,
      codes,
      companyId: selectedMaturation.companyId,
      maturationBaseId: selectedMaturation.maturationBaseId,
      name: selectedMaturation.name,
    };

    dispatch(companyMaturationsSlice.updateMaturation({ maturation, onSuccess: closeModal }));
  };

  return (
    <LrvModal
      theme='light'
      className={styles.editMaturation}
      title={t('maturations.inactiveCodes')}
      open={showEditInactiveMaturationCodesModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_maturation', htmlType: 'submit', form: 'form', loading: isCreating, disabled: disabledForm() }}
      onOk={onUpdateMaturation}
      okText={t('maturations.accept')}
      cancelText={t('maturations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form'
        layout='vertical'
      >
        <Form.Item>
          <LrvDescriptions theme={theme}>
            <Descriptions.Item label={t('maturations.name')}>
              {selectedMaturation?.name}
            </Descriptions.Item>
          </LrvDescriptions>
        </Form.Item>

        <Form.Item
          name='codes'
          label={t('maturations.codes')}
        >
          <div className={styles.codes}>
            {renderCodeInputs()}
          </div>
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
}