import React from 'react';
import cx from 'classnames';

import { getUnitCountAnimalChart } from '../helpers';
import { roundWeight, getWeightUnit, weightUnits, THEME, stockingPhaseTypes } from '../../../config/commons';

import BarsChart from './BarsChart';
import styles from './Chart.module.scss';
import { Analysis } from './interfaces';
import { groupLabels } from './math_percentile';

interface Props {
  analysisData: Analysis;
  typeChart: 'SHRIPMS_PER_GROUP' | 'CUMMULATIVE_WEIGHT_PER_GROUP';
  textClassName?: string;
  textInsideBarClassName?: string;
  textOutsideBarClassName?: string;
  containerClassName?: string;
  typeStyles?: 'DEFAULT' | 'PDF';
  theme?: 'dark' | 'light';
  width?: number;
  height?: number;
}

function HorizontalBarsChart (props: Props) {
  const {
    textClassName = styles.chartText,
    textInsideBarClassName = styles.textInsideBarClassName,
    textOutsideBarClassName = styles.textOutsideBarClassName,
    containerClassName = styles.container,
    analysisData,
    typeChart,
    typeStyles = 'DEFAULT',
    theme = 'dark',
    width,
    height,
  } = props;

  const larvaeNumber = analysisData.resultData.larvaeNumber;
  const analysisPhaseType = analysisData.phaseType;
  const isLightTheme = theme === THEME.LIGHT;
  const isConsolidatedGrowOut = analysisData.phaseType === stockingPhaseTypes.ADULT && analysisData?.analysisIds?.length > 0;

  let data: number[] = [];
  let ranges: number[] = [];
  let percents: string[] = [];
  let averageWeight = 0;
  let weightLabels: string[] = [];

  let measureUnit = getUnitCountAnimalChart(analysisPhaseType);

  if (typeChart === 'SHRIPMS_PER_GROUP') {
    percents = analysisData.resultData.countGroup.map(value => ((value * 100) / larvaeNumber).toFixed(1));
    data = analysisData.resultData.countGroup;
  } else if (typeChart === 'CUMMULATIVE_WEIGHT_PER_GROUP') {
    data = analysisData.resultData.weightGroup.map(group => Math.round(group));
    const totalWeight = data.reduce((total, value) => total + value, 0);

    percents = data.map(group => ((group * 100) / totalWeight).toFixed(1));
    measureUnit = weightUnits.MG;
  }

  ranges = analysisData.resultData.labels;
  averageWeight = analysisData.resultData.averageWeight;

  ranges = roundWeight({ value: ranges, showUnit: false, average: averageWeight }) as number[];
  if (measureUnit !== 'pl' && measureUnit !== 'ind') {
    measureUnit = getWeightUnit(averageWeight);
    data = roundWeight({ value: data, showUnit: false, average: averageWeight }) as number[];
  }

  weightLabels = groupLabels(ranges);

  return (
    <BarsChart
      data={data}
      weightLabels={weightLabels}
      measureUnit={measureUnit}
      chartType='WEIGHT_GROUPS'
      isConsolidatedGrowOut={isConsolidatedGrowOut}
      percents={percents}
      averageWeight={averageWeight}
      textClassName={textClassName}
      textInsideBarClassName={textInsideBarClassName}
      textOutsideBarClassName={textOutsideBarClassName}
      containerClassName={cx(containerClassName, isLightTheme ? styles.containerLight : styles.containerDark)}
      typeStyles={typeStyles}
      defaultWidth={width}
      defaultHeight={height}
      theme={theme}
    />
  );
}

export default HorizontalBarsChart;
