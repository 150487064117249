import { Row, Select, Space } from 'antd';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import ActionButton from '../../common/components/buttons/ActionButton';
import { isBusinessManagerRole, isPromoterRole, isSalesManagerRole, isSalesRole, isSuperAdmin } from '../../config/commons';

import * as clientsSlice from './clientsSlice';
import styles from './ClientSubHeader.module.scss';
import { CLIENTS, getValueActiveUsers } from './clientsHelper';

const { Option } = Select;

interface Props {
  onClickAddClient: MouseEventHandler<HTMLElement> | undefined;
  theme?: 'dark' | 'light';
}

export const ClientSubHeader = (props: Props) => {
  const { onClickAddClient, theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { filters } = useSelector((state: RootState) => state.clients);

  const renderAddClientButton = () => {
    const showButton = (isSuperAdmin() || isSalesRole() || isSalesManagerRole() || isPromoterRole() || isBusinessManagerRole());
    if (!showButton) {
      return;
    }

    return (
      <ActionButton
        id='btn-add-client'
        type='primary'
        className={styles.addBtn}
        icon={<Icon name='add' />}
        onClick={onClickAddClient}
      >
        {t('clients.client')}
      </ActionButton>
    );
  };

  const renderClientStatusDropdown = () => {
    return (
      <LrvSelect
        id='select_status_client'
        containerClassName='select_status_client'
        title={t('payments.status')}
        value={filters.clientStatus}
        className={styles.select}
        theme={theme}
        onChange={(value) => {
          dispatch(clientsSlice.setClientStatus(value));
          const active = getValueActiveUsers(value);
          dispatch(clientsSlice.fetchClients({ active }));
        }}
        suffixIcon={<Icon name='arrow-down-s' />}
      >
        <Option value={CLIENTS.ACTIVE} > {t('users.filter.active')} </Option>
        <Option value={CLIENTS.INACTIVE} >  {t('users.filter.inactive')}  </Option>
        <Option value={CLIENTS.ALL} >  {t('users.filter.all')}  </Option>
      </LrvSelect>
    );
  };

  return (
    <div className={styles.container}>
      <Row>
        {renderClientStatusDropdown()}
      </Row>

      <Row justify='end' align='middle'>
        <Space>
          {renderAddClientButton()}
        </Space>
      </Row>
    </div>
  );
};
