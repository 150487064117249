
export const calcDerivativeData = (props: { dataY: number[]; dataX: number[] }) => {
  const { dataY, dataX } = props;

  if (dataY.length < 2) {
    return [];
  }

  const result = [];

  for (let i = 1; i < dataY.length; i++) {
    const item = {
      x: dataX[i],
      y: dataY[i] - dataY[i - 1],
    };
    result.push(item);
  }

  return result;
};
