import React from 'react';
import cx from 'classnames';
import { Divider } from 'antd';

import { LrvDividerProps } from '../../interfaces/lrvDivider';

import styles from './LrvDivider.module.scss';

export const LrvDivider = (props: LrvDividerProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvInputProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkDivider : styles.lightDivider, className),
  };

  return (
    <Divider {...lrvInputProps}>
    </Divider>
  );
};
