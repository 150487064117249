import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getHoursOffset } from '../../../utils/date';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { USERS_URL, USER_ACTIVITY_DASHBOARD_URL } from '../../../config/config.api';
import { DATE_FORMATS } from '../../../config/commons';
import { getUserSession } from '../../../utils/userSession';

import { FiltersUserActivity, User, UserActivity, UserActivityDashboardState } from './interfaces';

const initialState: UserActivityDashboardState = {
  userActivity: [],
  isLoading: false,
  showModal: false,
  filters: {
    users: [],
    fromDate: '',
    toDate: '',
    dateSelected: '',
    user: {
      _id: '',
      firstName: '',
      lastName: '',
    },
    frequency: 'WEEKLY',
  },
};

export const userActivityDashboardSlice = createSlice({
  name: 'userActivityDashboard',
  initialState,
  reducers: {
    setUserActivity: (state: UserActivityDashboardState, action: PayloadAction<UserActivity[]>) => {
      state.userActivity = action.payload;
    },
    setIsUserActivityLoading: (state: UserActivityDashboardState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowUserActivityDashboardModal: (state: UserActivityDashboardState, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setFiltersUserActivityDashboard: (state: UserActivityDashboardState, action: PayloadAction<FiltersUserActivity>) => {
      state.filters = action.payload;
    },
    setDateSelected: (state: UserActivityDashboardState, action: PayloadAction<string>) => {
      state.filters.dateSelected = action.payload;
    },
    setFromDate: (state: UserActivityDashboardState, action: PayloadAction<string>) => {
      state.filters.fromDate = action.payload;
    },
    setToDate: (state: UserActivityDashboardState, action: PayloadAction<string>) => {
      state.filters.toDate = action.payload;
    },
    setUsers: (state: UserActivityDashboardState, action: PayloadAction<User[]>) => {
      state.filters.users = action.payload;
    },
    setSelectedUser: (state: UserActivityDashboardState, action: PayloadAction<User>) => {
      state.filters.user._id = action.payload?._id;
      state.filters.user.lastName = action.payload?.lastName;
      state.filters.user.firstName = action.payload?.firstName;
    },
    setFrequency: (state: UserActivityDashboardState, action: PayloadAction<'WEEKLY' | 'MONTHLY'>) => {
      state.filters.frequency = action.payload;
    },
  },
});

export const {
  setUserActivity,
  setIsUserActivityLoading,
  setShowUserActivityDashboardModal,
  setFiltersUserActivityDashboard,
  setSelectedUser,
  setUsers,
  setFromDate,
  setToDate,
  setDateSelected,
  setFrequency,
} = userActivityDashboardSlice.actions;

export const fetchUserActivityDashboard = (props: { fromDate: string; toDate: string; userId: string; companyId: string }) => async (dispatch: Function) => {
  const { fromDate, toDate, userId, companyId } = props;
  dispatch(setIsUserActivityLoading(true));

  const params = {
    fromDate: moment(fromDate).format(DATE_FORMATS.YYYY_MM_DD),
    toDate: moment(toDate).format(DATE_FORMATS.YYYY_MM_DD),
    hoursOffset: getHoursOffset()
  };

  const url = `${USER_ACTIVITY_DASHBOARD_URL}/${companyId}/${userId}`;

  try {
    const response = await axios.get<UserActivity[]>(url, { params });
    dispatch(setUserActivity(response.data));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsUserActivityLoading(false));
};

export const fetchUsers = (companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    active: true,
    '$sort[firstName]': 1,
    $select: ['_id', 'firstName', 'lastName'],
  };

  try {
    const response = await axios.get<User[]>(USERS_URL, { params });
    dispatch(setUsers(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export default userActivityDashboardSlice.reducer;
