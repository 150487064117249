import cx from 'classnames';
import { Button } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import { ButtonProps } from 'antd/es/button';

import Icon from '../Icon/Icon';
import { LrvTooltip } from '../LrvTooltip/LrvTooltip';
import { getCurrentTheme } from '../../../helpers/theme';
import { THEME } from '../../../config/commons';
import { IconType } from '../../interfaces/icon';

import styles from './IconButton.module.scss';

interface IconButtonProps extends ButtonProps {
  theme?: 'dark' | 'light';
  placement?: TooltipPlacement;
  tooltipText?: string;
  iconName?: string;
  iconType?: IconType;
}

export default function IconButton (props: IconButtonProps) {
  const { onClick, className, tooltipText, theme = getCurrentTheme(), placement = 'top', disabled, icon, iconName, iconType = 'line', type = 'text' } = props;
  const isLightTheme = theme === THEME.LIGHT;

  const lrvProps = {
    ...props,
    type,
    className: cx(styles.iconButton, isLightTheme ? styles.iconButtonLight : styles.iconButtonDark, className),
    icon: icon ?? <Icon name={iconName ?? ''} type={iconType} theme={theme} />,
    onClick,
    disabled,
  };

  if (!tooltipText) {
    return (
      <Button
        {...lrvProps}
      />
    );
  }

  return (
    <LrvTooltip
      themeStyle={theme}
      placement={placement}
      title={tooltipText}
    >
      <Button
        {...lrvProps}
      />
    </LrvTooltip>
  );
}
