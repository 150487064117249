import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { stockingPhaseTypes, THEME } from '../../../../config/commons';
import { LrvTooltip } from '../../../../common/components/LrvTooltip/LrvTooltip';

import styles from './Legend.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  analysisPhaseType: string;
}

export default function Legend (props: Props) {
  const { analysisPhaseType, theme = 'dark' } = props;

  const [t] = useTranslation();
  const isLightTheme = theme === THEME.LIGHT;

  const renderBorderTransparent = () => {
    if (analysisPhaseType === stockingPhaseTypes.ADULT) {
      return null;
    }

    const stroke = isLightTheme ? '#222222' : 'white';
    return (
      <LrvTooltip placement='top' title={t('detail.legends.withoutBorders.description')} className={styles.legend}>
        <div className={styles.icon}>
          <svg
            width={18}
            height={18}
          >
            <circle
              r={8}
              cx={9}
              cy={9}
              stroke={stroke}
              fill='#ffffff4d'
              strokeWidth={1}
            />
          </svg>
        </div>

        <div className={styles.item}>
          {t('detail.legends.withoutBorders.title')}
        </div>
      </LrvTooltip>
    );
  };

  const renderBorderSolid = () => {
    if (analysisPhaseType === stockingPhaseTypes.LARVAE || analysisPhaseType === stockingPhaseTypes.JUVENILE) {
      return null;
    }

    const stroke = isLightTheme ? '#222222' : 'white';
    const fill = isLightTheme ? 'white' : '#030829';
    return (
      <LrvTooltip placement='top' title={t('detail.legends.solidBorder.description')} className={styles.legend}>
        <div className={styles.icon}>
          <svg
            width={18}
            height={18}
          >
            <circle
              r={8}
              cx={9}
              cy={9}
              stroke={stroke}
              fill={fill}
              strokeWidth={1}
            />
          </svg>
        </div>

        <div className={styles.item}>
          {t('detail.legends.solidBorder.title')}
        </div>
      </LrvTooltip>
    );
  };

  const renderBorderDotted = () => {
    const stroke = isLightTheme ? '#222222' : 'white';
    return (
      <LrvTooltip className={styles.legend} title={t('detail.legends.dottedBorder.description')}>
        <div className={styles.icon}>
          <svg
            width={18}
            height={18}
          >
            <circle
              r={8}
              cx={9}
              cy={9}
              stroke={stroke}
              strokeWidth={1}
              fill='transparent'
              strokeDasharray={2}
            />
          </svg>

        </div>
        <div className={styles.item}>
          {t('detail.legends.dottedBorder.title')}
        </div>
      </LrvTooltip>
    );
  };

  return (
    <div className={cx(styles.legends, isLightTheme ? styles.legendsLight : styles.legendsDark)}>
      {renderBorderTransparent()}
      {renderBorderSolid()}
      {renderBorderDotted()}
    </div>
  );
}
