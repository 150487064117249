import moment from 'moment';

import { Company } from '../../AppHeader/interfaces';
import { getCurrentElementHeight } from '../../../utils/dimensions';
import { DATE_FORMATS, DEFAULT_STAGE_MAX, stockingPhaseTypes } from '../../../config/commons';

export const lastDaysGrowthDelta = 60;

export const getFromDate = () => {
  return moment(new Date()).subtract(lastDaysGrowthDelta, 'days').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDate = () => {
  return moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD);
};

export const disabledFromDate = (toDate: string | undefined, current: moment.Moment) => {
  const start = moment().subtract(lastDaysGrowthDelta, 'days');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledToDate = (fromDate: string | undefined, current: moment.Moment) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const getMaxDay = (selectedCompany: Company, phaseType: string | undefined) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return selectedCompany.maxStage;

    case stockingPhaseTypes.JUVENILE:
      return selectedCompany.maxDayJuvenile;

    case stockingPhaseTypes.ADULT:
      return selectedCompany.maxDayGrowOut;
  }
};

export const initialFiltersState = {
  campus: {
    _id: '',
    name: '',
    code: '',
    status: '',
    province: '',
    phaseType: '',
  },
  module: {
    _id: '',
    name: '',
    phaseType: '',
  },
  containerId: undefined,
  stockingId: undefined,
  fromDate: getFromDate(),
  toDate: getToDate(),
  firstStage: 1,
  lastStage: DEFAULT_STAGE_MAX,
  firstStageZoom: 1,
  lastStageZoom: DEFAULT_STAGE_MAX,
  movingAverage: 2,
  showMovingAverage: true,
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
  legends: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, legends } = props;

  const headerHeight = 64;
  const extraHeight = 68;

  const value = getCurrentElementHeight(filters) + getCurrentElementHeight(legends) + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = () => {
  const extraWidth = 40;
  let sidebarWidth = 0;

  if (window.innerWidth > 1420) {
    sidebarWidth = 240;
    return sidebarWidth + extraWidth;
  }

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
    return sidebarWidth + extraWidth;
  }

  return sidebarWidth + extraWidth;
};
