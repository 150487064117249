import { Dropdown, Menu } from 'antd';
import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../../state/store';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { maturationOptions } from '../../../config/commons';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../../AppHeader/headerSlice';
import { ItemRenderPagination } from '../../../common/components/LrvPagination/ItemRenderPagination';

import { BaseMaturation } from './interfaces';
import MergeMaturation from './MergeMaturation';
import styles from './GeneticsGlobal.module.scss';
import * as baseMaturationsSlice from './baseMaturationsSlice';

const GeneticsGlobal = () => {
  const history = useHistory();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const theme = getCurrentTheme();

  const { breadcrumb } = useSelector((state: RootState) => state.header);
  const { allBaseMaturations, isLoadingAllMaturations, limit, total, currentPage } = useSelector((state: RootState) => state.baseMaturations);

  useEffect(() => {
    dispatch(changeHeader({ title: 'maturations.title' }));
    dispatch(baseMaturationsSlice.fetchAllBaseMaturations({ page: 0 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeBreadcrumb([{ id: 'maturations', name: t('maturations.title'), url: '/genetics/global' }]));
  }, [dispatch, t]);

  const columns: ColumnsType<BaseMaturation> = [
    {
      key: 1,
      title: t('maturations.name'),
      dataIndex: 'name',
      width: '35%',
    },
    {
      key: 2,
      title: t('clients.company'),
      dataIndex: 'companyName',
      width: '35%',
    },
    {
      key: 3,
      width: '20%',
      render: (_, record: BaseMaturation) => {
        if (record.active === undefined) {
          return (
            <LrvTag type='default' theme={theme}>{t('maturations.status.pending').toLowerCase()}</LrvTag>
          );
        }

        if (record.active) {
          return (
            <LrvTag type='active' theme={theme}>{t('maturations.status.active').toLowerCase()}</LrvTag>
          );
        }

        return null;
      }
    },
    {
      key: 4,
      width: '10%',
      render: (_, record: BaseMaturation) => (
        renderDropdownOptions(record)
      ),
    }
  ];

  const menuItems = (record: BaseMaturation) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case maturationOptions.MERGE:
              dispatch(baseMaturationsSlice.setBaseMaturation(record));
              dispatch(baseMaturationsSlice.setShowMergeMaturationModal(true));
              break;
          }
        }}
      >
        <Menu.Item
          id={`merge_maturation_${record._id}`}
          key={maturationOptions.MERGE}
          className={styles.menuItemOptions}
        >
          <Icon name='git-merge' theme={theme} className={styles.icon} />
          <span>{t('maturations.merge.title')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderDropdownOptions = (record: BaseMaturation) => {
    if (record.merged || !record.similarMaturations?.length || record.active === false) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    return (
      <Dropdown
        overlay={() => menuItems(record)}
        trigger={['click']}
        placement='bottomLeft'
      >
        <ActionButton
          className={isDesktop ? styles.dropdownActions : styles.mobileDropdownActions}
          type='text'
          icon={<Icon name='more-2' type='line' />}
          onClick={(e) => e.stopPropagation()}
          id={`button_${record._id}`}
        />
      </Dropdown>
    );
  };

  const onChangePage = (page: number) => {
    dispatch(baseMaturationsSlice.fetchAllBaseMaturations({ page }));
    dispatch(baseMaturationsSlice.setCurrentPage(page));
  };

  return (
    <div className={styles.geneticsGlobal}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />
      <LrvTable
        id='genetics-global-table'
        className={styles.table}
        rowClassName={styles.row}
        columns={columns}
        loading={isLoadingAllMaturations}
        dataSource={allBaseMaturations}
        size='small'
        theme={theme}
        scroll={{ y: '' }}
        pagination={{
          size: 'default',
          showSizeChanger: false,
          defaultPageSize: limit,
          pageSize: limit,
          onChange: onChangePage,
          total: total,
          current: currentPage,
          itemRender: ItemRenderPagination,
        }}
        onRow={(record: BaseMaturation) => {
          return {
            onClick: (e) => {
              e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
              dispatch(baseMaturationsSlice.setBaseMaturation(record));
              history.push(`/genetics/global/${record._id}`);
            }
          };
        }}
      />

      <MergeMaturation theme='light' />
    </div>
  );
};

export default GeneticsGlobal;