import { StockingParameter } from '../pages/StockingParameter/interfaces';

export const existsRepeatedParameters = (props: { index: number; selectedStockingParameter: StockingParameter; stockingParameters: StockingParameter[] }) => {
  const {
    index,
    selectedStockingParameter,
    stockingParameters,
  } = props;

  const stockingParameter = stockingParameters.find((item, i) => i !== index && item.key === selectedStockingParameter.key && item.frequency === selectedStockingParameter.frequency);
  return !!stockingParameter;
};