import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Collapse, Dropdown, Menu } from 'antd';

import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../../helpers/theme';
import { LrvTag } from '../../../../common/components/LrvTag/LrvTag';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { Maturation, MaturationCode } from '../../../Clients/interfaces';
import * as baseMaturationsSlice from '../../Global/baseMaturationsSlice';
import { LrvEmpty } from '../../../../common/components/LrvEmpty/LrvEmpty';
import { getMaturationStatus } from '../../../../helpers/maturation.helpers';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import { LrvTooltip } from '../../../../common/components/LrvTooltip/LrvTooltip';
import { LrvCollapse } from '../../../../common/components/LrvCollapse/LrvCollapse';
import * as onboardingSlice from '../../../../common/components/Onboarding/OnboardingSlice';
import { ExtraActionsButton } from '../../../../common/components/buttons/ExtraActionsButton';
import { isAdminUser, maturationOptions, onboardingTypes, THEME } from '../../../../config/commons';

import './Maturations.scss';
import NewMaturation from './NewMaturation';
import EditMaturation from './EditMaturation';
import styles from './Maturations.module.scss';
import * as companyMaturationsSlice from './companyMaturationsSlice';
import { isClientsSection, isSettingsGeneticsSection } from './helpers';
import EditInactiveMaturationCodes from './EditInactiveMaturationCodes';

const { Panel } = Collapse;

interface Props {
  theme?: 'dark' | 'light';
}

export default function Maturations (props: Props) {
  const { theme = getCurrentTheme() } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const { company } = useSelector((state: Store) => state.header);
  const { isLoading } = useSelector((state: Store) => state.companyMaturations);
  const { isClientLoading, selectedClient } = useSelector((state: Store) => state.clients);
  const maturations: Maturation[] = useSelector((state: Store) => state.companyMaturations.maturations);
  const companyMaturations: Maturation[] | undefined = useSelector((state: Store) => state.companyMaturations.companyMaturations);

  const {
    existsUserData,
    user: onboardingUserData,
    run: isRunningOnboarding,
  } = useSelector((state: Store) => state.onboarding);

  const companyId = isAdminUser() && selectedClient._id ? selectedClient._id : company._id;
  const isLightTheme = theme === THEME.LIGHT;

  useEffect(() => {
    if (existsUserData && !onboardingUserData.onboarding?.maturationSection) {
      setTimeout(() => {
        dispatch(onboardingSlice.setStepIndex(0));
        dispatch(onboardingSlice.setOnboardingType(onboardingTypes.MATURATION_SECTION));
        dispatch(onboardingSlice.setShouldRun(true));
        dispatch(onboardingSlice.setRun(true));
      }, 500);
    }
  }, [dispatch, onboardingUserData, existsUserData]);

  useEffect(() => {
    return () => {
      dispatch(companyMaturationsSlice.setCompanyMaturations([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    if (isSettingsGeneticsSection()) {
      dispatch(companyMaturationsSlice.fetchCompanyMaturations(companyId));
      return;
    }

    if (!isClientLoading && isClientsSection()) {
      dispatch(companyMaturationsSlice.fetchCompanyMaturations(companyId));
    }
  }, [dispatch, companyId, isClientLoading]);

  useEffect(() => {
    const maturationsData: Maturation[] = (companyMaturations && companyMaturations.length) ? companyMaturations.map((maturation: Maturation, index) => {
      return {
        key: index,
        _id: maturation._id,
        name: maturation.name,
        newName: maturation.newName,
        codes: maturation.codes,
        active: maturation.active,
        public: maturation.public,
        status: maturation.status,
        companyId: maturation.companyId,
        companyBaseId: maturation.companyBaseId,
        maturationBaseId: maturation.maturationBaseId,
      };
    }) : [];

    dispatch(companyMaturationsSlice.setMaturations(maturationsData));
  }, [companyMaturations]);

  const renderAddMatutations = () => {
    return (
      <ActionButton
        id='btn_new_maturation'
        icon={<Icon name='add' />}
        className={styles.button}
        onClick={() => {
          dispatch(companyMaturationsSlice.setShowNewMaturationModal(true));
          dispatch(baseMaturationsSlice.fetchActiveBaseMaturations());

          if (isRunningOnboarding) {
            dispatch(onboardingSlice.goToOnboardingNextStep(800));
          }
        }}
        type='primary'
      >
        {t('maturations.add')}
      </ActionButton>
    );
  };

  const renderMaturationName = (maturation: Maturation) => {
    if (maturation.newName) {
      return (
        <div id={`maturation_name_${maturation._id}`}>
          {maturation.name}
          &nbsp;
          <Icon name='arrow-right' theme={theme} className={styles.icon} />
          &nbsp;
          {maturation.newName}
        </div>
      );
    }

    return (
      <div id={`maturation_name_${maturation._id}`}>
        {maturation.name}
      </div>
    );
  };

  const menuItemsMaturationOptions = (maturation: Maturation) => {
    const inactiveCodes = maturation.codes ? maturation.codes.filter((code) => !code.active) : [];

    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case maturationOptions.EDIT:
              dispatch(companyMaturationsSlice.setSelectedMaturation(maturation));
              dispatch(companyMaturationsSlice.setShowEditMaturationModal(true));
              break;

            case maturationOptions.EDIT_INACTIVE_CODES:
              dispatch(companyMaturationsSlice.setSelectedMaturation(maturation));
              dispatch(companyMaturationsSlice.setShowEditInactiveMaturationCodesModal(true));
              break;
          }
        }}
      >
        <Menu.Item
          id={`edit_maturation_${maturation._id}`}
          key={maturationOptions.EDIT}
          className={styles.menuItemOptions}
        >
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('maturations.edit')}</span>
        </Menu.Item>

        {
          inactiveCodes.length > 0 &&
          <Menu.Item
            id={`edit_inactive_maturation_codes_${maturation._id}`}
            key={maturationOptions.EDIT_INACTIVE_CODES}
            className={styles.menuItemOptions}
          >
            <Icon name='edit-box' theme={theme} className={styles.icon} />
            <span>{t('maturations.inactiveCodes')}</span>
          </Menu.Item>
        }
      </Menu>
    );
  };

  const renderDropdownMaturationOptions = (maturation: Maturation) => {
    return (
      <Dropdown
        overlay={() => menuItemsMaturationOptions(maturation)}
        trigger={['click']}
        placement='bottomRight'
        className={styles.dropdown}
      >
        <ExtraActionsButton
          onClick={(e) => e.stopPropagation()}
          id={`button_${maturation._id}`}
        />
      </Dropdown>
    );
  };

  const renderPanelOptions = (maturation: Maturation) => {
    return (
      <Space className={styles.panelOptions}>
        {renderGlobalMaturationStatus(maturation)}
        {renderCompanyMaturationStatus(maturation)}
        {renderDropdownMaturationOptions(maturation)}
      </Space>
    );
  };

  const renderGlobalMaturationStatus = (maturation: Maturation) => {
    const text = getMaturationStatus(maturation.status);

    if (!text) {
      return null;
    }

    return (
      <LrvTooltip
        themeStyle='light'
        placement='left'
        title={text}
      >
        <ActionButton
          type='text'
          icon={<Icon name='information' theme={theme} />}
          onClick={(e) => e.stopPropagation()}
        />
      </LrvTooltip>
    );
  };

  const renderPanelHeader = (maturation: Maturation) => {
    return (
      <Space id={maturation._id} className={styles.header}>
        {renderMaturationName(maturation)}
        {renderPanelOptions(maturation)}
      </Space>
    );
  };

  const renderCompanyMaturationStatus = (maturation: Maturation) => {
    if (maturation.active) {
      return null;
    }

    return (
      <LrvTag type='error'>{t('maturations.status.inactive').toLowerCase()}</LrvTag>
    );
  };

  const renderCodeInput = (props: { maturation: Maturation; code?: string; maturationIndex: number; codeIndex?: number }) => {
    const { maturation, code, maturationIndex, codeIndex } = props;

    if (codeIndex && maturation.codes && !maturation.codes[codeIndex].active) {
      return null;
    }

    return (
      <Space className={styles.codeRow}>
        <div id={`maturation_code_${maturationIndex}_${codeIndex}`}>
          {code}
        </div>
      </Space>
    );
  };

  const renderMaturationCodes = (maturation: Maturation, maturationIndex: number) => {
    if (!maturation?.codes) {
      return renderCodeInput({ maturation, maturationIndex });
    }

    if (!maturation?.codes.length) {
      return (
        <LrvText
          text={t('maturations.addCode')}
          className={styles.emptyCode}
          theme={theme}
        />
      );
    }

    return maturation.codes.map((maturationCode: MaturationCode, codeIndex: number) => {
      return renderCodeInput({ maturation, code: maturationCode.code, maturationIndex, codeIndex });
    });
  };

  const renderPanels = () => {
    if (!maturations || isLoading) {
      return null;
    }

    return maturations.map((maturation: Maturation, index: number) => {
      const isEvenIndex = index % 2 === 0;
      const key = `${maturation._id}-${index}`;
      const isActiveKey = activeKey === key;

      return (
        <Panel
          key={key}
          id={`maturation-panel-${index}`}
          header={renderPanelHeader(maturation)}
          className={cx(styles.maturationPanel,
            isActiveKey ? styles.activeMaturationPanel : '',
            isEvenIndex ? styles.evenMaturationPanel : styles.oddMaturationPanel
          )}
        >
          {renderMaturationCodes(maturation, index)}
        </Panel>
      );
    });
  };

  const onChangePanel = (key: string | string[]) => {
    setActiveKey(key);
  };

  const renderMaturationEmpty = () => {
    return (
      <LrvEmpty
        theme={theme}
        description={t('maturations.empty')}
        className={styles.empty}
      />
    );
  };

  return (
    <div className={cx('maturations', styles.maturations, isLightTheme ? styles.maturationsLight : styles.maturationsDark)}>
      <Space align='end' className={styles.buttonsContainer}>
        {renderAddMatutations()}
      </Space>

      {
        maturations.length > 0 ?
          <LrvCollapse
            theme={theme}
            activeKey={activeKey}
            className={styles.collapse}
            onChange={onChangePanel}
            accordion
          >
            {renderPanels()}
          </LrvCollapse> : renderMaturationEmpty()
      }

      <NewMaturation theme='light' />
      <EditMaturation theme='light' />
      <EditInactiveMaturationCodes theme='light' />
    </div>
  );
}