import moment from 'moment';
import i18next from 'i18next';

import { DATE_FORMATS, stockingPhaseTypes } from '../../config/commons';

import { UserActivity } from './interfaces';

const lastDays = 7;

export const getAnalysisUsageLabel = (analysisPhaseType: string) => {
  switch (analysisPhaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('clientDashboard.analysisUsage.larvae');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('clientDashboard.analysisUsage.juvenile');

    case stockingPhaseTypes.ADULT:
      return i18next.t('clientDashboard.analysisUsage.growOut');
  }
};

export const disabledDateFrom = (current: moment.MomentInput, toDate: string) => {
  const minMonths = 2;
  const start = moment().subtract(minMonths, 'month');
  const end = moment(toDate);
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const disabledDateTo = (current: moment.MomentInput, fromDate: string) => {
  const start = moment(fromDate).add(1, 'days');
  const end = moment();
  return !(start.isSameOrBefore(current) && end.isAfter(current));
};

export const getFromDateDefault = () => {
  return moment(new Date()).subtract(lastDays, 'days').format(DATE_FORMATS.YYYY_MM_DD);
};

export const getToDateDefault = () => {
  return moment(new Date()).format(DATE_FORMATS.YYYY_MM_DD);
};

export const getTitle = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('clientDashboard.larvaeSubtitle');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('clientDashboard.juvenileSubtitle');

    case stockingPhaseTypes.ADULT:
      return i18next.t('clientDashboard.growOutSubtitle');
  }
};

export const initialActivityFiltersState = {
  fromDate: getFromDateDefault(),
  toDate: getToDateDefault(),
  campus: {
    _id: '',
    name: ''
  },
  campuses: [],
};

export const getAmountAnalysis = (userActivities: UserActivity[]) => {
  let value = 0;
  for (let index = 0; index < userActivities.length; index++) {
    value += userActivities[index].count;
  }

  return value;
};
