import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataSource } from '../../home/interfaces';
import { Stocking } from '../../Sowings/interfaces';
import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { ReferenceCurves } from '../../Units/interfaces';
import { getUserSession } from '../../../utils/userSession';
import { getMetricUrl } from '../../../helpers/metrics.helpers';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { typeParam, typeScale } from '../../../common/components/charts/ShadedPlot/helpers';
import { analysisStatuses, stockingPhaseTypes, unitStatuses } from '../../../config/commons';
import { CAMPUS_URL, COMPANY_USAGE_URL, MATURATIONS_URL, MATURATION_COMPARISON_PDF_URL, METRICS_URL, REFERENCE_CURVES_BY_FILTERS_URL, REFERENCE_CURVES_GROUPED_BY_DAYS_URL, REFERENCE_CURVES_URL } from '../../../config/config.api';

import { getMaximumDate, getMinimumDate, initialGeneticsFiltersState } from './helpers';
import { GeneticsAnalysisState, GeneticsFilter, MaturationMetricsPayload, StockingsInfoParams } from './interfaces';

const minimumAnalysisCount = 50;

const initialState: GeneticsAnalysisState = {
  hasMinimumAnalysis: false,
  isDownloadingFile: false,
  isLoadingMaturationMetrics: false,
  isLoadingCompanyMaturations: false,
  isLoadingStockingsInfo: false,
  showStockingsModal: false,
  maturations: [],
  units: [],
  referenceCurves: {
    companies: [],
    globals: [],
    units: [],
  },
  metrics: {
    maturationMetrics: {},
    maturationCodeMetrics: {},
  },
  filters: {
    unit: '',
    maximumDate: getMaximumDate(),
    minimumDate: getMinimumDate(),
    parameter: typeParam.AVG_WEIGHT,
    scale: typeScale.LINEAR,
  },
  firstStage: 0,
  lastStage: 0,
  firstStageZoom: 0,
  lastStageZoom: 0,
  dataSource: [],
  stockingsInfo: [],
  maturationName: '',
  stage: 0,
};

export const geneticsAnalysisSlice = createSlice({
  name: 'geneticsAnalysis',
  initialState,
  reducers: {
    setCompanyReferenceCurves: (state: GeneticsAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves.companies = action.payload;
    },
    setGlobalReferenceCurves: (state: GeneticsAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves.globals = action.payload;
    },
    setUnitReferenceCurves: (state: GeneticsAnalysisState, action: PayloadAction<ReferenceCurves[]>) => {
      state.referenceCurves.units = action.payload;
    },
    setMaturationMetrics: (state: GeneticsAnalysisState, action: PayloadAction<MaturationMetricsPayload>) => {
      state.metrics.maturationMetrics = action.payload.maturationMetrics;
      state.metrics.maturationCodeMetrics = action.payload.maturationCodeMetrics;
    },
    setHasMinimumAnalysis: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.hasMinimumAnalysis = action.payload;
    },
    setIsLoadingCompanyMaturations: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingCompanyMaturations = action.payload;
    },
    setIsLoadingMaturationMetrics: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingMaturationMetrics = action.payload;
    },
    setCompanyMaturations: (state: GeneticsAnalysisState, action: PayloadAction<[]>) => {
      state.maturations = action.payload;
    },
    setIsDownloadingFile: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
    setUnits: (state: GeneticsAnalysisState, action: PayloadAction<[]>) => {
      state.units = action.payload;
    },

    setMaximumDate: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.filters.maximumDate = action.payload;
    },
    setMinimumDate: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.filters.minimumDate = action.payload;
    },
    setParameter: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.filters.parameter = action.payload;
    },
    setScale: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.filters.scale = action.payload;
    },
    setGeneticsFilters: (state: GeneticsAnalysisState, action: PayloadAction<GeneticsFilter>) => {
      state.filters = action.payload;
    },
    setUnit: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.filters.unit = action.payload;
    },
    setReferenceCurve: (state: GeneticsAnalysisState, action: PayloadAction<ReferenceCurves | undefined>) => {
      state.filters.referenceCurve = action.payload;
    },

    resetGeneticsAnalysis: (state: GeneticsAnalysisState) => {
      state.maturations = [];
      state.units = [];
      state.dataSource = [];
      state.metrics.maturationMetrics = {};
      state.metrics.maturationCodeMetrics = {};
      state.hasMinimumAnalysis = false;
    },

    setFirstStage: (state: GeneticsAnalysisState, action: PayloadAction<number>) => {
      state.firstStage = action.payload;
    },
    setLastStage: (state: GeneticsAnalysisState, action: PayloadAction<number>) => {
      state.lastStage = action.payload;
    },
    setFirstStageZoom: (state: GeneticsAnalysisState, action: PayloadAction<number>) => {
      state.firstStageZoom = action.payload;
    },
    setLastStageZoom: (state: GeneticsAnalysisState, action: PayloadAction<number>) => {
      state.lastStageZoom = action.payload;
    },
    setDataSource: (state: GeneticsAnalysisState, action: PayloadAction<DataSource[]>) => {
      state.dataSource = action.payload;
    },
    setStockingsInfo: (state: GeneticsAnalysisState, action: PayloadAction<Stocking[]>) => {
      state.stockingsInfo = action.payload;
    },
    setIsLoadingStockingsInfo: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.isLoadingStockingsInfo = action.payload;
    },
    setShowStockingsModal: (state: GeneticsAnalysisState, action: PayloadAction<boolean>) => {
      state.showStockingsModal = action.payload;
    },
    setMaturationName: (state: GeneticsAnalysisState, action: PayloadAction<string>) => {
      state.maturationName = action.payload;
    },
    setStage: (state: GeneticsAnalysisState, action: PayloadAction<number>) => {
      state.stage = action.payload;
    },
  },
});

export const {
  setHasMinimumAnalysis,
  setIsLoadingCompanyMaturations,
  setIsLoadingMaturationMetrics,
  setMaturationMetrics,
  setCompanyMaturations,
  setIsDownloadingFile,
  setUnits,

  setCompanyReferenceCurves,
  setGlobalReferenceCurves,
  setUnitReferenceCurves,
  setReferenceCurve,

  setMaximumDate,
  setMinimumDate,
  setParameter,
  setScale,
  setGeneticsFilters,
  setUnit,
  resetGeneticsAnalysis,
  setFirstStage,
  setLastStage,
  setFirstStageZoom,
  setLastStageZoom,
  setDataSource,
  setStockingsInfo,
  setIsLoadingStockingsInfo,
  setShowStockingsModal,
  setMaturationName,
  setStage,
} = geneticsAnalysisSlice.actions;

export const fetchCompanyActivity = (companyId?: string) => async (dispatch: Function) => {
  const userSession = getUserSession();
  const params = {
    $limit: 0,
    companyId: companyId || userSession.companyId,
    status: analysisStatuses.COMPLETED,
    active: true,
  };

  try {
    const response = await axios.get(COMPANY_USAGE_URL, { params });
    const hasMinimumAnalysis = response.data.usage >= minimumAnalysisCount;

    dispatch(setHasMinimumAnalysis(hasMinimumAnalysis));

    if (hasMinimumAnalysis) {
      dispatch(fetchCompanyMaturations(companyId || userSession.companyId));
    }

  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchMaturationMetrics = (props: { unitId: string; companyId: string; type: string; phaseType: string; minimumDate: string; maximumDate: string; accessToken?: string }) => async (dispatch: Function) => {
  const { unitId, companyId, phaseType, type, minimumDate, maximumDate, accessToken } = props;
  dispatch(setIsLoadingMaturationMetrics(true));

  const params = {
    type: type,
    minimumDate,
    maximumDate,
    campusId: unitId,
  };

  const metricsUrl = getMetricUrl(phaseType);
  let response = undefined;

  try {
    if (accessToken) {
      response = await axios.get(`${metricsUrl}/${companyId}/maturations`, { params, headers: { Authorization: accessToken } });
    } else {
      response = await axios.get(`${metricsUrl}/${companyId}/maturations`, { params });
    }

    dispatch(setMaturationMetrics(response?.data));
    dispatch(setIsLoadingMaturationMetrics(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchReferenceCurve = (props: { referenceId: string; accessToken: string }) => async (dispatch: Function) => {
  const { accessToken, referenceId } = props;

  const url = `${REFERENCE_CURVES_URL}/${referenceId}`;

  try {
    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    dispatch(setReferenceCurve(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchReferenceCurveGroupedByDays = (props: { referenceId: string; accessToken: string }) => async (dispatch: Function) => {
  const { accessToken, referenceId } = props;

  const url = `${REFERENCE_CURVES_GROUPED_BY_DAYS_URL}/${referenceId}`;

  try {
    const response = await axios.get(url, {
      headers: { Authorization: accessToken },
    });

    dispatch(setReferenceCurve(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchGlobalReferenceCurves = (props: { phaseType: string; type: string }) => async (dispatch: Function) => {
  const { phaseType, type } = props;

  dispatch(setGlobalReferenceCurves([]));
  dispatch(setReferenceCurve(undefined));

  const params = {
    $limit: -1,
    'companyId[$exists]': false,
    phaseType,
    type,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_URL, { params });
    dispatch(setGlobalReferenceCurves(response.data));

    if (!response.data.length) {
      return;
    }

    if (phaseType !== stockingPhaseTypes.LARVAE) {
      const accessToken = localStorage.getItem('accessToken') || '';
      dispatch(fetchReferenceCurveGroupedByDays({ referenceId: response.data[0]._id, accessToken }));
      return;
    }

    dispatch(setReferenceCurve(response.data[0]));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyReferenceCurves = (props: { companyId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setCompanyReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchUnitsReferenceCurves = (props: { companyId: string; campusId: string; phaseType: string; type: string }) => async (dispatch: Function) => {
  const params = {
    ...props,
    active: true,
  };

  try {
    const response = await axios.get<ReferenceCurves[]>(REFERENCE_CURVES_BY_FILTERS_URL, { params });
    dispatch(setUnitReferenceCurves(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyMaturations = (companyId: string, accessToken?: string) => async (dispatch: Function) => {
  dispatch(setIsLoadingCompanyMaturations(true));
  dispatch(setCompanyMaturations([]));

  const params = {
    $limit: -1,
    companyId: companyId,
    active: true,
    $select: ['name', 'codes']
  };

  try {
    if (accessToken) {
      const response = await axios.get(MATURATIONS_URL, {
        params,
        headers: { Authorization: accessToken },
      });

      dispatch(setCompanyMaturations(response.data));
      dispatch(setIsLoadingCompanyMaturations(false));
      return;
    }

    const response = await axios.get(MATURATIONS_URL, { params });
    dispatch(setCompanyMaturations(response.data));
    dispatch(setIsLoadingCompanyMaturations(false));
  } catch (e) {
    console.log(e?.response);
  }
};

interface UrlMaturationProps {
  companyId: string;
  parameter: string;
  scale: string;
  phaseType: string;
  firstStage: number;
  lastStage: number;
  minimumDate: string;
  maximumDate: string;
  maturationsName: string;
  companyName: string;
  unitId: string;
  unitName: string;
  referenceId?: string;
}

export const fetchUrlMaturationComparisonPdf = (params: UrlMaturationProps) => async (dispatch: Function) => {
  const { companyName, companyId, phaseType, parameter, scale, firstStage, lastStage, minimumDate, maximumDate, maturationsName, unitId, unitName, referenceId } = params;
  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(MATURATION_COMPARISON_PDF_URL);
    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    let url = `${response.data.url}?companyId=${companyId}&maturationsName=${maturationsName}&language=${language}&parameter=${parameter}&scale=${scale}&phaseType=${phaseType}&minimumDate=${minimumDate}&maximumDate=${maximumDate}&firstStage=${firstStage}&lastStage=${lastStage}&accessToken=${accessToken}&referenceId=${referenceId}`;
    if (unitId !== '') {
      url += `&unitId=${unitId}&unitName=${unitName}`;
    }
    await downloadFile(url, companyName, 'pdf');
    dispatch(setIsDownloadingFile(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const resetGeneticsFilters = (props: { phaseType: string; parameter: string }) => (dispatch: Function) => {
  const { parameter, phaseType } = props;
  dispatch(setGeneticsFilters(initialGeneticsFiltersState));

  if (parameter === typeParam.AVG_WEIGHT) {
    dispatch(fetchGlobalReferenceCurves({ type: typeParam.AVG_WEIGHT, phaseType }));
  }
};

export const fetchUnits = (params: { companyId: string, unitPhaseType: string }) => async (dispatch: Function) => {
  const { companyId, unitPhaseType } = params;
  const userSession = getUserSession();

  const unitParams = {
    $limit: -1,
    companyId: companyId ? companyId : userSession.companyId,
    phaseType: unitPhaseType,
    active: true,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    '$sort[name]': 1
  };

  try {
    const response = await axios.get(CAMPUS_URL, { params: unitParams });
    dispatch(setUnits(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchStockingsInfo = (params: StockingsInfoParams) => async (dispatch: Function) => {
  const { campusId, companyId, maturationName, maximumDate, minimumDate, phaseType, stage } = params;
  dispatch(setIsLoadingStockingsInfo(true));

  try {
    const query = { maturationName, stage, phaseType, minimumDate, maximumDate, campusId };
    const url = `${METRICS_URL}/stockings-info/${companyId}`;
    const response = await axios.get(url, { params: query });
    const stockings = response?.data || [];
    dispatch(setStockingsInfo(stockings));
  } catch (error) {
    console.log(error.response);
    dispatch(setStockingsInfo([]));
  }

  dispatch(setIsLoadingStockingsInfo(false));
};

export default geneticsAnalysisSlice.reducer;
