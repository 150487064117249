import i18next from 'i18next';

import { transferStatus } from '../config/commons';

export const getPaymentStatus = (status?: string) => {
  switch (status) {
    case transferStatus.PENDING_REVIEW:
      return { color: '#e0b927', text: i18next.t('payments.detail.statuses.approvalPending') };

    case transferStatus.APPROVED:
      return { color: '#4f9b73', text: i18next.t('payments.detail.statuses.approved') };

    case transferStatus.REJECTED:
      return { color: '#a85f66', text: i18next.t('payments.detail.statuses.rejected') };

    default:
      return { color: '', text: '' };
  }
};