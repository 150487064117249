import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { changeHeader } from '../AppHeader/headerSlice';
import { LrvTabs } from '../../common/components/LrvTabs/LrvTabs';
import { getCurrentTheme } from '../../helpers/theme';

import styles from './Archive.module.scss';
import ArchivedAnalysis from './Analysis/ArchivedAnalysis';
import ArchivedStocking from './Stocking/ArchivedStocking';

export default function Archive () {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { TabPane } = Tabs;

  const theme = getCurrentTheme();

  useEffect(() => {
    dispatch(changeHeader({ title: 'archive.header' }));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <LrvTabs defaultActiveKey='1' theme={theme}>
        <TabPane tab={<div id='tab_archived_analysis'>{t('analysis.analysis')}</div>} key='1'>
          <ArchivedAnalysis theme={theme} />
        </TabPane>

        <TabPane tab={<div id='tab_archived_stocking'>{t('stockings.title')}</div>} key='2'>
          <ArchivedStocking theme={theme} />
        </TabPane>
      </LrvTabs>
    </div>
  );
}