import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { getUserSession } from '../../../utils/userSession';
import { calcAvgGrowth } from '../../../helpers/stocking.helpers';
import IconButton from '../../../common/components/buttons/IconButton';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { getDaysToInitialStage } from '../../../helpers/analysis.helpers';
import { formatLocaleHour, formatYearMonthDay } from '../../../utils/date';
import { roundWeight, roundLength, animalDaysUnit, stockingPhaseTypes, THEME } from '../../../config/commons';

import styles from './DataDetail.module.scss';
import DataItemDetail from './DataItemDetail';
import DataDetailCustom from './DataDetailCustom';
import DetailCustomizable from './DetailCustomizable';
import { setShowDetailCustomizableModal } from './detailCustomizableSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function DataDetail (props: Props) {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company } = useSelector((state: Store) => state.header);
  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detailAnalysis);
  const { _id: detailCustomizableId, isLoading: isLoadingDetailCustom } = useSelector((state: Store) => state.detailCustomizable);

  const daysToInitialStage = getDaysToInitialStage({ company, daysToInitialStage: analysisOriginal.sowingId?.daysToInitialStage });

  const days = analysisOriginal.inputData?.stage + (daysToInitialStage - 1);
  const analysisPhaseType = analysisOriginal.phaseType;
  const dictType = analysisPhaseType === stockingPhaseTypes.LARVAE ? 'larvae' : 'juvenile';
  const stage = analysisPhaseType === stockingPhaseTypes.LARVAE ? `${animalDaysUnit.PL} ${analysisOriginal.inputData?.stage} - ${days} ${t('analysis.days')}` : `${analysisOriginal.inputData?.stage} ${t('analysis.days')}`;
  const uniformityWeight = analysisOriginal.resultData.uniformity;
  const uniformityLength = 100 - analysisOriginal.resultData.variationCoefficientLength;

  const isLightTheme = theme === THEME.LIGHT;
  const accessToken = localStorage.getItem('accessToken');
  const userSession = getUserSession();

  const renderItem = (props: { value: string; label: string }) => {
    const { value, label } = props;

    return (
      <DataItemDetail value={value} label={label} theme={theme} />
    );
  };

  const renderCustomDataDetailButton = () => {
    return (
      <IconButton
        id='settings_button'
        onClick={() => {
          dispatch(setShowDetailCustomizableModal(true));
        }}
        iconName='settings-4'
        size='small'
        tooltipText={t('analysisDetailCustom.title')}
      />
    );
  };

  const renderDataDetail = () => {
    if (isLoadingDetailCustom) {
      return (
        <div className={styles.spinner}>
          <DotSpinner />
        </div>
      );
    }

    if (!detailCustomizableId) {
      const gramPerWeek = calcAvgGrowth({ averageWeight: analysisOriginal.resultData.averageWeight, stage: analysisOriginal.inputData.stage, phaseType: analysisOriginal.phaseType });

      return (
        <div className={cx(styles.gridContainer, isLightTheme ? styles.gridContainerLight : styles.gridContainerDark)}>
          <div className={cx(isLightTheme ? styles.oddLight : styles.oddDark, styles.firstRow)}>
            {renderItem({ label: t('analysis.resultData.sample'), value: `${analysisOriginal.resultData?.larvaeNumber} ${t(`analysis.resultData.${dictType}Sample`)} / ${analysisOriginal.inputData?.sampleWeight}` })}
            {analysisPhaseType === stockingPhaseTypes.LARVAE && renderItem({ label: t('analysis.resultData.larvaePerGram'), value: `${analysisOriginal.resultData.larvaePerGram}` })}
            {analysisPhaseType === stockingPhaseTypes.JUVENILE && renderItem({ label: t('analysis.inputData.maturation'), value: analysisOriginal.inputData?.maturationId?.name || '' })}
            {analysisPhaseType === stockingPhaseTypes.ADULT && renderItem({ label: t('analysis.resultData.avgGrowth'), value: gramPerWeek })}

            {renderItem({ label: t(`analysis.inputData.${dictType}Stage`), value: stage })}
          </div>

          <div>
            {renderItem({ label: t('analysis.resultData.averageWeight'), value: `${roundWeight({ value: analysisOriginal.resultData?.averageWeight })}` })}
            {renderItem({ label: t('analysis.resultData.variationCoefficient'), value: `${analysisOriginal.resultData?.variationCoefficient.toFixed(2)} %` })}
            {renderItem({ label: `${t('detail.weight')} ${t('analysis.resultData.uniformity')}`, value: `${uniformityWeight.toFixed(2)} %` })}
          </div>

          <div className={isLightTheme ? styles.oddLight : styles.oddDark}>
            {renderItem({ label: t('analysis.resultData.averageLength'), value: `${roundLength({ value: analysisOriginal.resultData?.averageLength })}` })}
            {renderItem({ label: t('analysis.resultData.variationCoefficientLength'), value: `${analysisOriginal.resultData?.variationCoefficientLength.toFixed(2)} %` })}
            {renderItem({ label: `${t('detail.length')} ${t('analysis.resultData.uniformity')}`, value: `${uniformityLength.toFixed(2)} %` })}
          </div>

          <div>
            {renderItem({ label: t('analysis.campus'), value: analysisOriginal.campusName?.toUpperCase() || '-' })}
            {renderItem({ label: t('analysis.createdAt'), value: formatYearMonthDay(analysisOriginal.createdAt) })}
            {renderItem({ label: t('analysis.hour'), value: formatLocaleHour(analysisOriginal.createdAt) })}
          </div>
        </div>
      );
    }

    return (
      <DataDetailCustom theme={theme} />
    );
  };

  return (
    <>
      <div className={styles.container}>
        {
          (accessToken && userSession.companyId === company._id) &&
          <div className={styles.actions}>
            {renderCustomDataDetailButton()}
          </div>
        }

        {renderDataDetail()}
      </div>
      <DetailCustomizable theme='light' />
    </>
  );
}
