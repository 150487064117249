import { typeParam } from '../ShadedPlot/helpers';
import { DEFAULT_STAGE_MAX, stockingPhaseTypes } from '../../../../config/commons';
import { ItemReferenceCurves } from '../../../../pages/Reports/ActiveTanksDashboard/interfaces';

import { Tank, Point } from './interfaces';

const zList = [80, 100];
const ticksMinimum = 3;
const ticksMaximumAdult = 16;

export const getNumberTicks = (props: { phaseType: string; firstStage: number; lastStage: number; }) => {
  const { phaseType, firstStage, lastStage } = props;
  if (lastStage === firstStage) {
    return ticksMinimum;
  }

  if (phaseType === stockingPhaseTypes.ADULT) {
    return (lastStage - firstStage) + 1 < DEFAULT_STAGE_MAX ? (lastStage - firstStage) + 1 : ticksMaximumAdult;
  }

  return (lastStage - firstStage) + 1;
};

export const getDataLow = (itemReferenceCurves: ItemReferenceCurves[]) => {
  const points = [];

  for (let index = 0; index < itemReferenceCurves.length; index++) {
    const itemReferenceCurve = itemReferenceCurves[index];

    for (let k = 0; k < zList.length; k++) {
      const element = zList[k];
      const percent = itemReferenceCurve.range / 100;
      const mean = itemReferenceCurve.mean;
      let value = mean - (mean * percent);
      value = Math.round(value * 10) / 10;

      const pointLow = [itemReferenceCurve.stage, value, element];
      points.push(pointLow);
    }
  }

  const x: number[] = points.map(point => point[0]);
  const y: number[] = points.map(point => point[1]);

  return {
    x, y
  };
};

export const getDataHigh = (itemReferenceCurves: ItemReferenceCurves[]) => {
  const points = [];

  for (let index = 0; index < itemReferenceCurves.length; index++) {
    const itemReferenceCurve = itemReferenceCurves[index];

    for (let k = 0; k < zList.length; k++) {
      const element = zList[k];
      const percent = itemReferenceCurve.range / 100;
      const mean = itemReferenceCurve.mean;
      let value = mean + (mean * percent);
      value = Math.round(value * 10) / 10;

      const pointHigh = [itemReferenceCurve.stage, value, element];
      points.push(pointHigh);
    }
  }

  const x: number[] = points.map(point => point[0]);
  const y: number[] = points.map(point => point[1]);

  return {
    x, y
  };
};

export const getScaleYLimits = (props: { parameter: string; phaseType: string; yValues: number[] }) => {
  const { parameter, phaseType, yValues } = props;

  switch (parameter) {
    case typeParam.AVG_WEIGHT: {
      if (phaseType === stockingPhaseTypes.LARVAE) {
        return yValues;
      }

      return yValues.map((value) => value / 1000);
    }

    case typeParam.AVG_LENGTH: {
      if (phaseType === stockingPhaseTypes.LARVAE) {
        return yValues;
      }

      return yValues.map((value) => value / 10);
    }

    case typeParam.PLG:
    default:
      return yValues;
  }
};

const getAverageWeightValues = (props: { phaseType: string; activeTanks: Tank[] }) => {
  const { phaseType, activeTanks } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return activeTanks.map((tank) => tank.analysis.averageWeight);
  }

  return activeTanks.map((tank) => tank.analysis.averageWeight / 1000);
};

const getAverageLengthValues = (props: { phaseType: string; activeTanks: Tank[] }) => {
  const { phaseType, activeTanks } = props;

  if (phaseType === stockingPhaseTypes.LARVAE) {
    return activeTanks.map((tank) => tank.analysis.averageLength);
  }

  return activeTanks.map((tank) => tank.analysis.averageLength / 10);
};

export const getScaleYValues = (props: { parameter: string; phaseType: string; activeTanks: Tank[] }) => {
  const { parameter, phaseType, activeTanks } = props;

  switch (parameter) {
    case typeParam.AVG_WEIGHT:
    default:
      return getAverageWeightValues({ phaseType, activeTanks });

    case typeParam.AVG_LENGTH:
      return getAverageLengthValues({ phaseType, activeTanks });

    case typeParam.PLG:
      return activeTanks.map((tank) => tank.analysis.larvaePerGram);
  }
};

export const getMinY = (props: { parameter: string; phaseType: string; activeTanks: Tank[]; points: Point[]; firstStage: number; lastStage: number }) => {
  const { parameter, phaseType, activeTanks, points, firstStage, lastStage } = props;

  const yValues = getScaleYValues({ parameter, phaseType, activeTanks });
  let minY = Math.min(...yValues);

  for (let index = 0; index < points.length; index++) {
    const point = points[index];
    if ((firstStage <= point.x && point.x <= lastStage) && (minY > point.y)) {
      minY = point.y;
    }
  }

  return minY;
};

export const getMaxY = (props: { parameter: string; phaseType: string; activeTanks: Tank[]; points: Point[]; firstStage: number; lastStage: number }) => {
  const { parameter, phaseType, activeTanks, points, firstStage, lastStage } = props;

  const yValues = getScaleYValues({ parameter, phaseType, activeTanks });
  let maxY = Math.max(...yValues);

  for (let index = 0; index < points.length; index++) {
    const point = points[index];
    if ((firstStage <= point.x && point.x <= lastStage) && maxY < point.y) {
      maxY = point.y;
    }
  }

  return maxY;
};

export const getMarginArea = (parameter: string) => {
  const marginPlg = 0.1;
  const marginUniformity = 0.05;
  const marginDefault = 0.25;

  switch (parameter) {
    case typeParam.PLG:
      return marginPlg;

    case typeParam.UNIFORMITY:
      return marginUniformity;

    default:
      return marginDefault;
  }
};
