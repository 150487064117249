import cx from 'classnames';
import { Select } from 'antd';

import { LrvSelectProps } from '../../interfaces/lrvSelect';

import styles from './LrvSelect.module.scss';

export const LrvSelect = (props: LrvSelectProps) => {
  const { options, title, disabled, containerClassName, popupClassName, titleClassName, className, children, theme = 'dark' } = props;
  const { Option } = Select;
  const isDarkTheme = theme === 'dark';

  const LrvSelectProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkSelect : styles.lightSelect, disabled ? styles.disabled : '', className),
    popupClassName: cx(styles.lightPopup, popupClassName),
  };

  const lrvSelectOptions = options?.map((option) => {
    const { value, id, label, key } = option;

    return (
      <Option key={key} value={value}>
        <div id={id}>{label}</div>
      </Option>
    );
  });

  return (
    <div className={cx(styles.container, containerClassName, isDarkTheme ? styles.darkContainer : styles.lightContainer)}>
      {
        title &&
        <div className={cx(isDarkTheme ? styles.darkTitle : styles.lightTitle, titleClassName)}>{title}</div>
      }
      <Select
        {...LrvSelectProps}
      >
        {lrvSelectOptions ?? children}
      </Select>
    </div>
  );
};
