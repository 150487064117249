import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Radio, RadioChangeEvent, Row } from 'antd';

import { Store } from '../../state/store.interfaces';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { isNumber, validateNumber } from '../../utils/validations';
import { LrvRadio } from '../../common/components/LrvRadio/LrvRadio';
import { LrvButton } from '../../common/components/LrvButton/LrvButton';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvCheckbox } from '../../common/components/LrvCheckbox/LrvCheckbox';
import { LrvTextArea } from '../../common/components/LrvTextArea/LrvTextArea';
import { getStartDate } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';
import { convertKilogramsToPounds, convertPoundsToKilograms, disabledEndDate, getHarvestedAndTransferredAnimalCount } from '../../helpers/stocking.helpers';
import { DATE_FORMATS, LBS_TO_GR_RATIO, stockingPhaseTypes, stockingStatuses, transferTypes, unitStatuses, weightUnits, weightUnitsByCompany } from '../../config/commons';

import styles from './FinishStocking.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import * as multiphaseSlice from './Multiphase/multiphaseSlice';
import { FinishStockingData, FinishStockingProps } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  pathname: string;
}

export const HarvestForm = (props: Props) => {
  const { theme = 'dark', pathname } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [formHarvestStocking] = Form.useForm();

  const {
    averageWeight,
    isLoadingFinish,
    stockingFinishedData,
    showModalFinish: showModal,
    plgHarvest: plgHarvestDefault,
  } = useSelector((state: Store) => state.finishStocking);

  const { company } = useSelector((state: Store) => state.header);
  const { stockingsToShow } = useSelector((state: Store) => state.stockings.filters);
  const { isLoadingTransfer } = useSelector((state: Store) => state.transferStocking);
  const { selectedStocking, selectedCampus, selectedModuleId, selectedTankId, currentPage } = useSelector((state: Store) => state.stockings);

  const [comment, setComment] = useState('');
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [plgHarvest, setPlgHarvest] = useState<number | string>('');
  const [harvestQuantity, setHarvestQuantity] = useState<number | string>('');
  const [poundsHarvested, setPoundsHarvested] = useState<number | string>('');
  const [kilogramsHarvested, setKilogramsHarvested] = useState<number | string>('');
  const [averageHarvestedWeight, setAverageHarvestedWeight] = useState<number | string>('');

  const [radio, setRadio] = useState(stockingStatuses.HARVESTED);
  const [createBindingCode, setCreateBindingCode] = useState<boolean>(false);
  const [isInvalidHarvestedNauplii, setIsInvalidHarvestedNauplii] = useState(false);
  const [messageWarningHarvestedNauplii, setMessageWarningHarvestedNauplii] = useState('');

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  /* useEffect section */
  useEffect(() => {
    const fillFormHarvestStocking = () => {
      const endDateStocking = moment();
      setHarvestQuantity('');
      setPoundsHarvested('');
      setComment('');
      setEndDate(endDateStocking);

      if (selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER) {
        setPlgHarvest(`${plgHarvestDefault}`);

        calculateAverageHarvestedWeight(plgHarvestDefault || 0);
      }

      setIsInvalidHarvestedNauplii(false);
      setCreateBindingCode(false);

      formHarvestStocking.setFieldsValue({
        naupliiHarvested: '',
        poundsHarvested: '',
        kilogramsHarvested: '',
        plgHarvest: selectedStocking.status === stockingStatuses.ACTIVE || selectedStocking.status === transferTypes.PARTIAL_TRANSFER ? `${plgHarvestDefault}` : '',
        endDate: endDateStocking,
        radio: radio,
        comment: '',
        kilogramsTransferred: '',
        poundsTransferred: '',
        animalsTransferred: '',
        phaseType: '',
      });
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormHarvestStocking();
    }
  }, [dispatch, formHarvestStocking, averageWeight, plgHarvestDefault, selectedStocking, showModal]);

  useEffect(() => {
    if (!showModal) {
      formHarvestStocking.resetFields();
    }
  }, [showModal]);
  /* End od useEffect section*/

  /* Functions section */
  function onChangeRadio (e: RadioChangeEvent) {
    const radio = e.target.value;
    setRadio(radio);
    dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
  }

  const showWarningHarvestedQuantity = (value: string | number) => {
    if (!value || !isNumber(value) || parseInt(value.toString()) <= 0) {
      setIsInvalidHarvestedNauplii(false);
      return;
    }

    let animals = 0;
    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
      default:
        animals = parseInt(selectedStocking.naupliusNumber.toString());
        break;

      case stockingPhaseTypes.JUVENILE:
        animals = parseInt(selectedStocking.juvenilesNumber.toString());
        break;

      case stockingPhaseTypes.ADULT:
        animals = parseInt(selectedStocking.growOutNumber.toString());
        break;
    }

    const animalCount = getHarvestedAndTransferredAnimalCount({ stocking: selectedStocking }) + parseInt(value.toString());
    const percent = Math.round((animalCount * 100 / animals) * 100) / 100;
    const showWarning = percent < 60 || percent > 100;
    setIsInvalidHarvestedNauplii(showWarning);

    if (percent < 60) {
      setMessageWarningHarvestedNauplii(t('stockings.warning.numberNaupliiMin'));
      return;
    }

    if (percent > 100) {
      setMessageWarningHarvestedNauplii(t('stockings.warning.numberNaupliiMax', { percent: percent }));
    }
  };

  const calculateShrimpsHarvested = (poundsHarvested: string | number, averageHarvestedWeight: string | number) => {
    let harvestedShrimp = 0;

    if (!!poundsHarvested && isNumber(poundsHarvested) && !!averageHarvestedWeight && isNumber(averageHarvestedWeight) && Number(averageHarvestedWeight) > 0) {
      harvestedShrimp = parseFloat(poundsHarvested.toString()) * LBS_TO_GR_RATIO / parseFloat(averageHarvestedWeight.toString());
      harvestedShrimp = Math.round(harvestedShrimp);
    }

    setHarvestQuantity(harvestedShrimp);
    showWarningHarvestedQuantity(harvestedShrimp);
    formHarvestStocking.setFieldsValue({
      naupliiHarvested: harvestedShrimp,
    });
  };

  const calculatePlgHarvest = (value: string | number) => {
    const plg = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;

    setPlgHarvest(plg);
    formHarvestStocking.setFieldsValue({
      plgHarvest: plg,
    });
  };

  const calculateAverageHarvestedWeight = (value: string | number) => {
    if (Number(value) == 0) {
      setAverageHarvestedWeight('');
      formHarvestStocking.setFieldsValue({
        averageHarvestedWeight: '',
      });
      return;
    }

    const average = Math.round(1000 / parseFloat(value.toString()) * 100) / 100;

    setAverageHarvestedWeight(average);
    formHarvestStocking.setFieldsValue({
      averageHarvestedWeight: average
    });
  };

  const calculateKilogramsHarvested = (pounds: string | number) => {
    const kilograms = convertPoundsToKilograms(pounds);

    setKilogramsHarvested(kilograms);
    formHarvestStocking.setFieldsValue({
      kilogramsHarvested: kilograms
    });
  };

  const calculatePoundsHarvested = (kilograms: string | number) => {
    const pounds = convertKilogramsToPounds(kilograms);

    setPoundsHarvested(pounds);
    formHarvestStocking.setFieldsValue({
      poundsHarvested: pounds
    });
  };

  const isValidLarvaeFinishForm = () => {
    const endDate = formHarvestStocking.getFieldValue('endDate');
    const harvestQuantity = formHarvestStocking.getFieldValue('naupliiHarvested');
    const plgHarvest = formHarvestStocking.getFieldValue('plgHarvest');

    return !!(harvestQuantity && plgHarvest && endDate);
  };

  const isValidJuvenileFinishForm = () => {
    const endDate = formHarvestStocking.getFieldValue('endDate');
    const harvestQuantity = formHarvestStocking.getFieldValue('naupliiHarvested');
    const averageHarvestedWeight = formHarvestStocking.getFieldValue('averageHarvestedWeight');
    const { min } = stockingFinishedData.juvenile.averageHarvestedWeight;

    return !!(harvestQuantity && averageHarvestedWeight && parseFloat(averageHarvestedWeight) >= min && endDate);
  };

  const isValidGrowOutFinishForm = () => {
    const endDate = formHarvestStocking.getFieldValue('endDate');
    const harvestQuantity = formHarvestStocking.getFieldValue('naupliiHarvested');
    const averageHarvestedWeight = formHarvestStocking.getFieldValue('averageHarvestedWeight');
    const poundsHarvested = formHarvestStocking.getFieldValue('poundsHarvested');

    const { min } = stockingFinishedData.growOut.averageHarvestedWeight;

    return !!(harvestQuantity && averageHarvestedWeight && parseInt(averageHarvestedWeight) >= min && poundsHarvested && endDate);
  };

  const disabledFinishForm = () => {
    if (isCampusInactive) {
      return true;
    }

    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        return !isValidLarvaeFinishForm();

      case stockingPhaseTypes.JUVENILE:
        return !isValidJuvenileFinishForm();

      case stockingPhaseTypes.ADULT:
        return !isValidGrowOutFinishForm();
    }
  };

  const onCreateBindingCode = async () => {
    if (!createBindingCode) {
      return;
    }

    const params = {
      stockingId: selectedStocking._id,
      companyId: selectedStocking.companyId,
    };

    await multiphaseSlice.createStockingBindingCode(params);
  };

  const onSubmit = () => {
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
    const makeFetchStockings = pathname === '/production/stockings';

    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();
    const stockingData: FinishStockingData = {
      status: radio,
      harvestQuantity: harvestQuantity.toString(),
      phaseType: selectedStocking.phaseType,
      comment: comment || undefined,
    };

    if (radio === stockingStatuses.HARVESTED) {
      stockingData.endDate = date;
    }

    if (radio === stockingStatuses.PARTIAL_HARVESTED) {
      stockingData.harvestDate = date;
    }

    switch (selectedStocking.phaseType) {
      case stockingPhaseTypes.LARVAE:
        stockingData.plg = plgHarvest.toString();
        break;

      case stockingPhaseTypes.JUVENILE:
        stockingData.averageHarvestedWeight = averageHarvestedWeight.toString();
        break;

      case stockingPhaseTypes.ADULT:
        stockingData.poundsHarvested = poundsHarvested.toString();
        stockingData.averageHarvestedWeight = averageHarvestedWeight.toString();
        break;
    }

    const params: FinishStockingProps = { stockingId: selectedStocking?._id, stockingData, makeFetchStockings, paramsToFetchStocking, onCreateBindingCode };
    dispatch(finishStockingSlice.finishStocking(params));
  };
  /* End of functions section */

  /* Rendered functions */
  const renderHarvestedRadioButton = () => {
    return (
      <LrvRadio
        theme={theme}
        id='radio_button_harvested'
        className={styles.radio}
        value={stockingStatuses.HARVESTED}
      >
        {t('stockings.radio.harvested')}
      </LrvRadio>
    );
  };

  const renderPartialHarvestRadioButton = () => {
    return (
      <LrvRadio
        theme={theme}
        id='radio_button_harvested'
        className={styles.radio}
        value={stockingStatuses.PARTIAL_HARVESTED}
      >
        {t('stockings.radio.partialHarvest')}
      </LrvRadio>
    );
  };

  const renderEndDateInput = (props: { span: number }) => {
    const { span } = props;
    const label = radio === stockingStatuses.PARTIAL_HARVESTED ? t('production.stockingInfoModal.harvestDate') : t('stockings.endDate');

    return (
      <Col
        span={span}
      >
        <Form.Item
          name='endDate'
          label={label}
          required
          rules={[{ required: true, message: t('stockings.form.endDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            placeholder={t('stockings.selectEndDate')}
            disabledDate={(currentDate) => disabledEndDate({ currentDate, stockingStartDate: getStartDate(selectedStocking) })}
            value={moment(endDate)}
            onChange={(date) => setEndDate(date ?? moment())}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderCommentInput = () => {
    return (
      <Form.Item
        name='comment'
        label={`${t('stockings.comment')} (${t('common.optional')})`}
      >
        <LrvTextArea
          theme={theme}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderCreateBindingCode = () => {
    return (
      <Form.Item
        name='assignBindingCode'
      >
        <LrvCheckbox
          theme={theme}
          checked={createBindingCode}
          onChange={event => setCreateBindingCode(event.target.checked)}
        >
          <LrvText text={t('stockings.assignBindingCode')} theme={theme} />
        </LrvCheckbox>
      </Form.Item>
    );
  };

  const renderAnimalsHarvested = (props: { span: number }) => {
    const { span } = props;
    const showWarningMessage = radio === stockingStatuses.HARVESTED && isInvalidHarvestedNauplii;

    return (
      <Col
        span={span}
      >
        <Form.Item
          name='naupliiHarvested'
          label={t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.harvested`)}
          required
          validateStatus={showWarningMessage ? 'warning' : undefined}
          help={showWarningMessage ? messageWarningHarvestedNauplii : undefined}
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, true, 1);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={harvestQuantity}
            min={1}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            readOnly={selectedStocking.phaseType === stockingPhaseTypes.ADULT}
            onChange={(value) => {
              const harvestQuantity = value || '';
              setHarvestQuantity(harvestQuantity);
              showWarningHarvestedQuantity(harvestQuantity);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPlgHarvest = (props: { span: number }) => {
    const { span } = props;
    const { min, max } = stockingFinishedData.larvae.larvaePerGram;

    return (
      <Col
        span={span}
        hidden={radio === stockingStatuses.DISCARDED}
      >
        <Form.Item
          name='plgHarvest'
          label={t('stockings.finishStockingLabels.LARVAE.plg')}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, min, max, t('stockings.plgHarvestError', { min, max }));
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={plgHarvest}
            min={min}
            max={max}
            onChange={(value) => {
              const plgHarvest: string | number = value || '';
              setPlgHarvest(plgHarvest);
              calculateAverageHarvestedWeight(plgHarvest);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAverageHarvestedWeight = (props: { min?: number, max?: number, unit: string; span: number }) => {
    const { max, min, unit, span } = props;
    const label = selectedStocking.phaseType === stockingPhaseTypes.LARVAE ? t('stockings.finishStockingLabels.LARVAE.harvestWeight') : t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.averageWeight`);

    return (
      <Col
        span={span}
      >
        <Form.Item
          name='averageHarvestedWeight'
          label={`${t(label)} ${unit}`}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, min);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={averageHarvestedWeight}
            min={min}
            max={max}
            onChange={(value) => {
              const averageWeight: string | number = value || '';
              setAverageHarvestedWeight(averageWeight);

              if (selectedStocking.phaseType === stockingPhaseTypes.ADULT) {
                calculateShrimpsHarvested(poundsHarvested, averageWeight);
              }
              calculatePlgHarvest(averageWeight);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderKilogramsHarvested = (props: { span: number }) => {
    const { span } = props;

    return (
      <Col
        span={span}
      >
        <Form.Item
          name='kilogramsHarvested'
          label={t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.kilograms`)}
          hidden={company.weightUnit === weightUnitsByCompany.POUND}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, 1);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={kilogramsHarvested}
            min={1}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setKilogramsHarvested(value);
              const pounds = convertKilogramsToPounds(value);
              calculateShrimpsHarvested(pounds, averageHarvestedWeight);
              calculatePoundsHarvested(value);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPoundsHarvested = (props: { span: number }) => {
    const { span } = props;

    return (
      <Col
        span={span}
      >
        <Form.Item
          name='poundsHarvested'
          label={t(`stockings.finishStockingLabels.${selectedStocking.phaseType}.pounds`)}
          hidden={company.weightUnit === weightUnitsByCompany.KILOGRAM}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, false, 1);
            }
          })]}
        >
          <LrvInputNumber
            theme={theme}
            value={poundsHarvested}
            min={1}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setPoundsHarvested(value);
              calculateShrimpsHarvested(value, averageHarvestedWeight);
              calculateKilogramsHarvested(value);
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderBodyLarvaeFinishForm = () => {
    return (
      <Row gutter={16}>
        {renderPlgHarvest({ span: 12 })}
        {renderAverageHarvestedWeight({ min: 0, unit: weightUnits.MG, span: 12 })}
        {renderAnimalsHarvested({ span: 12 })}
        {renderEndDateInput({ span: 12 })}
      </Row>
    );
  };

  const renderBodyJuvenileFinishForm = () => {
    const { min } = stockingFinishedData.juvenile.averageHarvestedWeight;

    return (
      <>
        <Row gutter={16}>
          {renderAnimalsHarvested({ span: 12 })}
          {renderAverageHarvestedWeight({ min, unit: weightUnits.G, span: 12 })}
          {renderEndDateInput({ span: 24 })}
        </Row>
      </>
    );
  };

  const renderBodyGrowOutFinishForm = () => {
    const { min } = stockingFinishedData.growOut.averageHarvestedWeight;

    return (
      <>
        <Row gutter={16}>
          {renderKilogramsHarvested({ span: 12 })}
          {renderPoundsHarvested({ span: 12 })}
          {renderAverageHarvestedWeight({ min, unit: weightUnits.G, span: 12 })}
          {renderAnimalsHarvested({ span: 12 })}
          {renderEndDateInput({ span: 24 })}
        </Row>
      </>
    );
  };

  const renderCancelButton = () => {
    return (
      <LrvButton
        className={styles.cancelButton}
        theme={theme}
        type='text'
        onClick={() => {
          formHarvestStocking.resetFields();
          dispatch(finishStockingSlice.setShowModalFinish(false));
          dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
        }}
      >
        <LrvText theme={theme} text={t('stockings.cancel')} />
      </LrvButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ActionButton
        className={styles.submitButton}
        theme='light'
        type='primary'
        htmlType='submit'
        onClick={onSubmit}
        disabled={disabledFinishForm()}
        loading={isLoadingFinish || isLoadingTransfer}
      >
        <LrvText theme='dark' text={t('stockings.finishStockingLabels.harvest')} />
      </ActionButton>
    );
  };
  /* End of rendered functions */

  return (
    <div className={styles.formContainer}>
      <LrvForm
        className={styles.formHarvestStocking}
        theme={theme}
        form={formHarvestStocking}
        name='formHarvestStocking'
        id='formHarvestStocking'
        layout='vertical'
      >
        <Form.Item
          name='radio'
          required
          initialValue={radio}
        >
          <Radio.Group
            onChange={onChangeRadio}
            value={radio}
          >
            {renderHarvestedRadioButton()}
            {renderPartialHarvestRadioButton()}
          </Radio.Group>
        </Form.Item>

        {selectedStocking.phaseType === stockingPhaseTypes.LARVAE && renderBodyLarvaeFinishForm()}
        {selectedStocking.phaseType === stockingPhaseTypes.JUVENILE && renderBodyJuvenileFinishForm()}
        {selectedStocking.phaseType === stockingPhaseTypes.ADULT && renderBodyGrowOutFinishForm()}

        {renderCommentInput()}
        {renderCreateBindingCode()}
      </LrvForm>

      <div className={styles.buttonsContainer}>
        {renderCancelButton()}
        {renderSubmitButton()}
      </div>
    </div>
  );
};
