import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';

import { DataCustomizable, LayoutCustomizable } from './interfaces';
import * as parameterChartSlice from './parameterChartSlice';
import styles from './StockingDataCustomizableModal.module.scss';
import { defaultStockingDataChart, getKeysStockingDataCustom, parameterCardWidth } from './helpers';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const StockingDataCustomizableModal = (props: Props) => {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [otherKeys, setOtherKeys] = useState<string[]>([]);
  const [key, setKey] = useState<string>('');

  const { showDataCustomizableModal, parameterChart, selectedDataIndex, shouldAddNewData } = useSelector((state: Store) => state.parameterChart);
  const { dataCustomizable, defaultData: parameterChartData } = parameterChart;

  useEffect(() => {
    if (!showDataCustomizableModal) {
      return;
    }

    const fields = [];
    for (const key in parameterChartData) {
      fields.push(key);
    }

    const { otherKeys } = getKeysStockingDataCustom({ layoutCustomizable: dataCustomizable.data, fields });
    setOtherKeys(otherKeys);

    return (() => {
      setOtherKeys([]);
      setKey('');
    });
  }, [showDataCustomizableModal]);

  const isValidDetailCustomizable = () => {
    return !!key;
  };

  const getXPosition = (props: { lastLayout: LayoutCustomizable }) => {
    const { lastLayout } = props;

    if (lastLayout.x + parameterCardWidth.DEFAULT <= parameterCardWidth.MAX - parameterCardWidth.DEFAULT) {
      return lastLayout.x + parameterCardWidth.DEFAULT;
    }

    return 0;
  };

  const addNewData = () => {
    const layoutCopy = cloneDeep(dataCustomizable);
    const layouts = layoutCopy.data.filter((item) => item.i !== defaultStockingDataChart.CHART).sort((a, b) => a.x - b.x).sort((a, b) => a.y - b.y);

    if (!layouts.length) {
      return;
    }

    const lastLayout = layouts[layouts.length - 1];

    const newData: DataCustomizable = {
      data: layoutCopy.data.concat({ ...lastLayout, i: key, x: getXPosition({ lastLayout }), y: lastLayout.y }),
    };

    dispatch(parameterChartSlice.setDataCustomizable(newData));
    dispatch(parameterChartSlice.setShouldAddNewData(false));
    dispatch(parameterChartSlice.setShowDataCustomizableModal(false));
  };

  const onSaveDetailCustomizable = () => {
    if (shouldAddNewData) {
      addNewData();
      return;
    }

    const fields = cloneDeep(dataCustomizable);
    fields.data[selectedDataIndex].i = key;

    dispatch(parameterChartSlice.setDataCustomizable(fields));
    dispatch(parameterChartSlice.setShowDataCustomizableModal(false));
  };

  const renderDataCustomDescription = () => {
    if (shouldAddNewData) {
      return;
    }

    const text = dataCustomizable?.data ? '* ' + t('production.parameter.dataCustom.description', { oldParameter: t(`production.parameter.${dataCustomizable?.data[selectedDataIndex]?.i}`) }) : '';

    return (
      <LrvText
        className={styles.settings}
        theme={theme}
        text={text}
      />
    );
  };

  return (
    <LrvModal
      open={showDataCustomizableModal}
      title={t('production.parameter.dataCustom.title')}
      theme={theme}
      cancelText={undefined}
      className={styles.customizableModal}
      onCancel={() => dispatch(parameterChartSlice.setShowDataCustomizableModal(false))}
      footer={[
        <Button
          type='primary'
          className={styles.button}
          disabled={!isValidDetailCustomizable()}
          onClick={onSaveDetailCustomizable}
        >
          {t('stockingParameterCustom.save')}
        </Button>
      ]}
    >
      <Space className={styles.container} direction='vertical' size='middle'>
        {renderDataCustomDescription()}

        <LrvSelect
          theme='light'
          id='dropdown_keys'
          showSearch
          className={styles.select}
          value={key || undefined}
          suffixIcon={<Icon name='arrow-down-s' />}
          title={t('production.parameter.dataCustom.parameters')}
          placeholder={t('production.parameter.dataCustom.parameters')}
          optionFilterProp='children'
          filterOption={(input, option) => {
            const children = `${option?.children || ''}`;
            return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          dropdownMatchSelectWidth={false}
          onChange={(value) => setKey(value)}
        >
          {otherKeys.map((otherKey: string) =>
            <Option key={otherKey} value={otherKey}>{t(`production.parameter.${otherKey}`)}</Option>
          )}
        </LrvSelect>
      </Space>
    </LrvModal>
  );
};
