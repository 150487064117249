import moment from 'moment';
import { Col, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { disabledEndDate } from '../../helpers/stocking.helpers';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvButton } from '../../common/components/LrvButton/LrvButton';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvTextArea } from '../../common/components/LrvTextArea/LrvTextArea';
import { getStartDate } from '../../common/components/charts/ShadedPlot/helpers';
import { LrvDatePicker } from '../../common/components/LrvDatePicker/LrvDatePicker';
import { DATE_FORMATS, stockingStatuses, unitStatuses } from '../../config/commons';

import styles from './FinishStocking.module.scss';
import * as finishStockingSlice from './finishStockingSlice';
import { defaultDestinationStocking } from './sowings.helpers';
import { FinishStockingData, FinishStockingProps } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
  pathname: string;
}

export const DiscardedForm = (props: Props) => {
  const { theme = 'dark', pathname } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company } = useSelector((state: Store) => state.header);
  const { stockingsToShow } = useSelector((state: Store) => state.stockings.filters);
  const { isLoadingTransfer } = useSelector((state: Store) => state.transferStocking);
  const { isLoadingFinish, showModalFinish: showModal } = useSelector((state: Store) => state.finishStocking);
  const { selectedStocking, selectedCampus, selectedModuleId, selectedTankId, currentPage } = useSelector((state: Store) => state.stockings);

  const [formDiscardedStocking] = Form.useForm();

  const [comment, setComment] = useState('');
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  useEffect(() => {
    const fillFormHarvestStocking = () => {
      const endDateStocking = moment();
      setEndDate(endDateStocking);

      formDiscardedStocking.setFieldsValue({
        endDate: endDateStocking,
        comment: '',
      });
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormHarvestStocking();
    }
  }, [dispatch, formDiscardedStocking, selectedStocking, showModal]);

  useEffect(() => {
    if (!showModal) {
      formDiscardedStocking.resetFields();
    }
  }, [showModal]);

  const disabledFinishForm = () => {
    if (isCampusInactive) {
      return true;
    }

    return !(endDate);
  };

  const onSubmit = () => {
    const paramsToFetchStocking = { companyId: company._id, campusId: selectedCampus?._id, moduleId: selectedModuleId, tankId: selectedTankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
    const makeFetchStockings = pathname === '/production/stockings';

    const date = endDate.format(DATE_FORMATS.YYYY_MM_DD).toString();
    const stockingData: FinishStockingData = {
      status: stockingStatuses.DISCARDED,
      endDate: date,
      phaseType: selectedStocking.phaseType,
      comment: comment || undefined,
    };

    const params: FinishStockingProps = { stockingId: selectedStocking?._id, stockingData, makeFetchStockings, paramsToFetchStocking };
    dispatch(finishStockingSlice.finishStocking(params));
  };

  const renderEndDateInput = () => {
    return (
      <Col
        span={24}
      >
        <Form.Item
          name='endDate'
          label={t('stockings.endDate')}
          required
          rules={[{ required: true, message: t('stockings.form.endDate') }]}
        >
          <LrvDatePicker
            theme={theme}
            placeholder={t('stockings.selectEndDate')}
            disabledDate={(currentDate) => disabledEndDate({ currentDate, stockingStartDate: getStartDate(selectedStocking) })}
            value={moment(endDate)}
            onChange={(date) => setEndDate(date ?? moment())}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderCommentInput = () => {
    return (
      <Form.Item
        name='comment'
        label={`${t('stockings.comment')} (${t('common.optional')})`}
      >
        <LrvTextArea
          theme={theme}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderCancelButton = () => {
    return (
      <LrvButton
        className={styles.cancelButton}
        theme={theme}
        type='text'
        onClick={() => {
          formDiscardedStocking.resetFields();
          dispatch(finishStockingSlice.setShowModalFinish(false));
          dispatch(finishStockingSlice.setDataDestinationStocking([defaultDestinationStocking]));
        }}
      >
        <LrvText theme={theme} text={t('stockings.cancel')} />
      </LrvButton>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ActionButton
        className={styles.submitButton}
        theme='light'
        type='primary'
        htmlType='submit'
        onClick={onSubmit}
        disabled={disabledFinishForm()}
        loading={isLoadingFinish || isLoadingTransfer}
      >
        <LrvText theme='dark' text={t('stockings.finishStockingLabels.discard')} />
      </ActionButton>
    );
  };

  return (
    <div className={styles.formContainer}>
      <LrvForm
        className={styles.formDiscardedStocking}
        theme={theme}
        form={formDiscardedStocking}
        name='formDiscardedStocking'
        id='formDiscardedStocking'
        layout='vertical'
      >
        {renderEndDateInput()}
        {renderCommentInput()}
      </LrvForm>

      <div className={styles.buttonsContainer}>
        {renderCancelButton()}
        {renderSubmitButton()}
      </div>
    </div>
  );
};
