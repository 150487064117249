import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { getUserSession } from './utils/userSession';

const userSession = getUserSession();
const language = userSession.language || navigator.language;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    lng: language,
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
