import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '../../Users/interfaces';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { paginationHistoryManagement } from '../../../config/commons';
import { LOG_BALANCES_URL, USERS_URL } from '../../../config/config.api';

import { initialHistoryManagementFilters } from './helper';
import { HistoryManagementFilter, HistoryManagementPayload, HistoryManagementState } from './interfaces';

const initialState: HistoryManagementState = {
  historyManagement: {
    isLoading: false,
    total: 0,
    currentPage: 1,
    data: [],
    users: [],
    filters: initialHistoryManagementFilters,
  },
};

export const historyManagementSlice = createSlice({
  name: 'historyManagement',
  initialState,
  reducers: {
    setHistoryManagement: (state: HistoryManagementState, action: PayloadAction<HistoryManagementPayload>) => {
      state.historyManagement.total = action.payload.total;
      state.historyManagement.data = action.payload.data;
    },
    setIsLoadingHistory: (state: HistoryManagementState, action: PayloadAction<boolean>) => {
      state.historyManagement.isLoading = action.payload;
    },
    setCurrentPageHistory: (state: HistoryManagementState, action: PayloadAction<number>) => {
      state.historyManagement.currentPage = action.payload;
    },
    setUsers: (state: HistoryManagementState, action: PayloadAction<User[]>) => {
      state.historyManagement.users = action.payload;
    },
    setHistoryManagementFilters: (state: HistoryManagementState, action: PayloadAction<HistoryManagementFilter>) => {
      state.historyManagement.filters = action.payload;
    },
    resetHistoryManagementFilters: (state: HistoryManagementState) => {
      state.historyManagement.currentPage = 1;
      state.historyManagement.filters = initialHistoryManagementFilters;
    },
  },
});

export const {
  setHistoryManagement,
  setIsLoadingHistory,
  setCurrentPageHistory,
  setUsers,
  setHistoryManagementFilters,
  resetHistoryManagementFilters,
} = historyManagementSlice.actions;

export const fetchHistoryManagement = (params: { page: number; origin?: string; userId?: string }) => async (dispatch: Function) => {
  const { page, origin, userId } = params;
  let skip = 0;

  if (page !== 0) {
    skip = paginationHistoryManagement * (page - 1);
  }

  dispatch(setIsLoadingHistory(true));

  const userSession = getUserSession();
  const paramsLogBalance = {
    $limit: paginationHistoryManagement.toString(),
    $skip: skip.toString(),
    companyId: userSession.companyId,
    origin: origin || undefined,
    userId: userId || undefined,
  };

  try {
    const response = await axios.get<HistoryManagementPayload>(LOG_BALANCES_URL, { params: paramsLogBalance });
    dispatch(setHistoryManagement(response.data));
    dispatch(setIsLoadingHistory(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchUsers = () => async (dispatch: Function) => {
  const userSession = getUserSession();

  const params = {
    $limit: '-1',
    companyId: userSession.companyId,
    $select: ['_id', 'firstName', 'lastName'],
    '$sort[firstName]': 1,
  };

  try {
    const response = await axios.get<User[]>(USERS_URL, { params });
    dispatch(setUsers(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export default historyManagementSlice.reducer;
