import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { calculateDateRange } from '../../Sowings/Parameters/helpers';
import ParameterStateD3 from '../../Reports/ParameterState/ParameterStateD3';
import { ParameterTypes, StockingParameterData } from '../../Reports/ParameterState/interfaces';
import { generateStockingParameterValues, getAllDataParameters, getAxisXDate, getAxisY, minimumItems } from '../../Reports/ParameterState/helpers';

import styles from './ParameterGraph.module.scss';

interface Props {
  data: StockingParameterData;
  stockingName: string;

  parameter: ParameterTypes;
  dateOption: string;
  phaseType: string;
  theme?: 'dark' | 'light';
}

export const StockingParameterGraph = (props: Props) => {
  const { data, stockingName, parameter, dateOption, phaseType, theme = 'light' } = props;

  const dispatch = useDispatch();
  const refChartMain = useRef<HTMLDivElement>(null);

  const {
    stockingParameterGraphs,
    selectedReferenceCurve,
  } = useSelector((state: Store) => state.parameterState);

  const height = 200;
  const width = 220;

  useEffect(() => {
    if (!parameter || data.values.length < minimumItems) {
      return;
    }

    const dates = getAxisXDate({ stockingParameters: data.values });
    const { maxDate, minDate } = calculateDateRange({ defaultMaxDate: dates.maxDate.toString(), defaultMinDate: dates.minDate.toString(), option: dateOption });
    const referenceCurves = generateStockingParameterValues({ referenceCurve: selectedReferenceCurve, stockingParameters: data.values, phaseType });

    const allData = getAllDataParameters({ selectedParameterName: parameter, stockingParameterGraphs });
    const { maxY, minY } = getAxisY({ referenceCurves, stockingParameters: allData });

    new ParameterStateD3({
      container: refChartMain.current,
      data,
      height,
      maxDate,
      minDate,
      parameter,
      theme,
      width,
      maxY,
      minY,
      referenceCurves,
    });
  }, [dispatch, width, height, theme, data, dateOption, parameter, refChartMain.current, selectedReferenceCurve?._id, phaseType]);

  if (data.values.length < minimumItems) {
    return null;
  }

  return (
    <div className={styles.square}>
      <LrvText className={styles.stockingName} text={stockingName} theme={theme} />
      <div ref={refChartMain} />
    </div>
  );
};
