import axios from 'axios';
import jwt from 'jsonwebtoken';
import { isNaN } from 'lodash';
import { MiddlewareAPI, Action, Dispatch } from 'redux';

import { setUtcDate } from '../../utils/date';
import { environments, LARVIA_ID } from '../../config/commons';
import { getDateToChangePassword } from '../../pages/Users/usersSlice';
import { REFRESH_TOKEN_URL, ENVIRONMENT } from '../../config/config.api';
import { setRefreshingToken, signOut } from '../../pages/auth/authSlice';
import { RefreshToken, PayloadToken } from '../../common/interfaces/auth';

const millisecondsInSecond = 1000;

function waitForRefreshTokenFinished (store: MiddlewareAPI<Dispatch>) {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!store.getState().auth.isRefreshingToken) {
        clearInterval(interval);
        resolve(undefined);
      }
    }, 50);
  });
}

function setRefreshingTokenStatus (store: MiddlewareAPI<Dispatch>, isRefreshing: boolean) {
  store.dispatch({
    type: setRefreshingToken,
    payload: {
      isRefreshingToken: isRefreshing,
    },
  });
}

export default (store: MiddlewareAPI<Dispatch>) => {
  return (next: Function) => async (action: Action) => {
    // only actions that are async (functions) are taken into account since all of them are api requests
    const refreshToken: string = localStorage.getItem('refreshToken') as string;
    if (typeof action !== 'function' || !refreshToken) {
      next(action);
      return;
    }
    if (store.getState().auth.isRefreshingToken) {
      await waitForRefreshTokenFinished(store);
      next(action);
      return;
    }
    const accessToken: string = localStorage.getItem('accessToken') as string;
    const currentTime: number = new Date().getTime() / millisecondsInSecond;
    const decodedAccessToken = jwt.decode(accessToken) as PayloadToken;

    const accessTokenHasExpired: boolean = currentTime >= decodedAccessToken.exp;
    if (accessTokenHasExpired) {
      const decodedRefreshToken = jwt.decode(refreshToken) as RefreshToken;
      const refreshTokenHasExpired: boolean = currentTime >= decodedRefreshToken.exp;
      if (refreshTokenHasExpired) {
        next({ type: signOut, payload: {} });
        return;
      }
      setRefreshingTokenStatus(store, true);
      let refreshTokenResponse;
      try {
        refreshTokenResponse = await axios.post(REFRESH_TOKEN_URL, { refreshToken });
      } catch (e) {
        next({ type: signOut, payload: {} });
        return;
      }
      localStorage.setItem('accessToken', refreshTokenResponse.data.accessToken);
      localStorage.setItem('refreshToken', refreshTokenResponse.data.refreshToken);
      setRefreshingTokenStatus(store, false);
    }

    if (ENVIRONMENT !== environments.STG && decodedAccessToken.companyId === LARVIA_ID) {
      // Verify dateToChangePassword in accessToken
      // if dateToChangePassword is undefined when decoded accessToken, it returns NaN
      const dateToChangePassword = setUtcDate(decodedAccessToken.dateToChangePassword).getTime() / millisecondsInSecond;
      let isTimeToChangePassword = isNaN(dateToChangePassword) || currentTime >= dateToChangePassword;

      if (!isTimeToChangePassword) {
        next(action);
        return;
      }

      // Verify if dateToChangePassword was updated before
      let newDateToChangePassword = await getDateToChangePassword(decodedAccessToken.sub);
      newDateToChangePassword = setUtcDate(newDateToChangePassword).getTime() / millisecondsInSecond;
      isTimeToChangePassword = isNaN(newDateToChangePassword) || currentTime >= newDateToChangePassword;

      if (isTimeToChangePassword && accessToken) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('isAssignedCompany');
        localStorage.removeItem('isDistributor');
        window.location.href = `/password/renew/${accessToken}`;
        return;
      }

      next({ type: signOut, payload: {} });
      return;
    }

    next(action);
  };
};
