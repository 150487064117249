import moment from 'moment';
import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import '../ActivityDashboard.scss';
import { Campus, UserActivity } from '../interfaces';
import { Store } from '../../../state/store.interfaces';
import { isAdminUser, THEME } from '../../../config/commons';
import { fetchUsersActivity } from '../activityDashboardSlice';
import { getTextColor } from '../../../helpers/chartjs.helpers';
import IconButton from '../../../common/components/buttons/IconButton';
import FullScreen from '../../../common/components/FullScreen/FullScreen';

import styles from './UsersActivity.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

function UsersActivity (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLightTheme = theme === THEME.LIGHT;
  const barBgColor = isLightTheme ? 'rgb(27, 98, 230)' : 'rgb(0, 0, 255)';
  const barBorderColor = isLightTheme ? 'rgb(27, 98, 230)' : 'rgb(0, 0, 255)';

  const fromDate = useSelector((state: Store) => state.activityDashboard.filters.fromDate);
  const toDate = useSelector((state: Store) => state.activityDashboard.filters.toDate);
  const selectedCampus = useSelector((state: Store) => state.activityDashboard.filters.campus as Campus);
  const usersActivity = useSelector((state: Store) => state.activityDashboard.usersActivity as UserActivity[]);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const lastDays = moment(toDate).diff(moment(fromDate), 'days');
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);

  useEffect(() => {
    if (!selectedCompany._id || selectedCompany.maxStage === 1) {
      return;
    }

    dispatch(fetchUsersActivity({ fromDate, toDate, phaseType: phaseType, companyId: selectedCompany._id, campusId: selectedCampus._id }));
  }, [dispatch, fromDate, phaseType, selectedCampus, selectedCompany, toDate]);

  const start = usersActivity.length >= 5 ? usersActivity.length - 5 : 0;
  const end = usersActivity.length;
  const data = sortBy(usersActivity, 'count').slice(start, end);
  const fullData = sortBy(usersActivity, 'count');

  const usersActivityData = {
    labels: data.map(activity => `${activity.firstName.split(' ')[0]} ${activity.lastName.charAt(0)}.`),
    datasets: [
      {
        backgroundColor: barBgColor,
        borderColor: barBorderColor,
        borderWidth: 0,
        data: data.map(activity => activity.count)
      }
    ]
  };

  const usersActivityFullData = {
    labels: fullData.map(activity => `${activity.firstName.split(' ')[0]} ${activity.lastName.charAt(0)}.`),
    datasets: [
      {
        backgroundColor: barBgColor,
        borderColor: barBorderColor,
        borderWidth: 0,
        data: fullData.map(activity => activity.count)
      }
    ]
  };

  function renderWidget () {
    return <div className={styles.container}>
      <div className={styles.actions}>
        <IconButton
          id='open_users_activity_button'
          onClick={() => {
            if (isRunningOnboarding) {
              return;
            }
            setShowFullScreenModal(true);
          }}
          iconName='fullscreen'
          size='small'
        />
      </div>
      {renderGraph(usersActivityData)}
    </div>;
  }

  function renderFullWidget () {
    return <div className={styles.containerFull}>
      {renderGraph(usersActivityFullData)}
    </div>;
  }

  function renderGraph (data: ChartData<'bar', (number | [number, number] | null)[], unknown>) {
    return <Bar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 0
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const tooltipItem = context.dataset.data[context.dataIndex];
                return ` ${tooltipItem}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              color: getTextColor(theme),
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: getTextColor(theme),
            }
          }
        }
      }}
    />;
  }

  function renderModalTitle () {
    const campus = selectedCampus._id === '' ? t('clientDashboard.allCampus') : selectedCampus.name;
    const amountAnalysis = t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis() });

    if (isAdminUser()) {
      return `${t('clientDashboard.usersActivityFull')} - ${selectedCompany.name} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
    }

    return `${t('clientDashboard.usersActivityFull')} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
  }

  function getAmountAnalysis () {
    let value = 0;
    for (let index = 0; index < usersActivity.length; index++) {
      value += usersActivity[index].count;
    }

    return value;
  }

  return <div>
    {renderWidget()}
    <FullScreen
      title={renderModalTitle()}
      show={showFullScreenModal}
      onClose={() => setShowFullScreenModal(false)}
      theme={theme}
    >
      {renderFullWidget()}
    </FullScreen>
  </div>;
}

export default UsersActivity;
