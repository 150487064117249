import { useState, useEffect } from 'react';
import { LabeledValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Row, Col, Form, Select, Tooltip, Collapse, Input } from 'antd';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input';

import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { applyThousandsSeparator } from '../../utils/strings';
import { isAdminUser, isDistributorCompany, LARVIA_ID, plansTypes } from '../../config/commons';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { getAvailableBalance } from '../../helpers/users.helpers';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { formatLongDateWithOffset, formatYearMonthDay } from '../../utils/date';
import { lrvConfirm } from '../../common/components/LrvConfirm/LrvConfirm';
import { LrvCollapse } from '../../common/components/LrvCollapse/LrvCollapse';
import { validateString, validatePhoneInputGroup } from '../../utils/validations';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { goToOnboardingNextStep } from '../../common/components/Onboarding/OnboardingSlice';

import styles from './EditClient.module.scss';
import { EditClientBody, Maturation } from './interfaces';
import { getValueActiveUsers, MAX_LENGTH_COMPANY_CODE, PHONE_PREFIX, COUNTRY_CODE, clientDefault } from './clientsHelper';
import { setSelectedClient, updateClient, fetchSellers, setSellers, setPromoters, setShowEditClientModal, fetchPromotersBySeller } from './clientsSlice';

const { Option } = Select;

export const EditClient = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  const [companyName, setCompanyName] = useState<string>('');
  const [businessName, setBusinessName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [ownerId, setOwnerId] = useState<string>('');
  const [responsibleId, setResponsibleId] = useState<string>('');
  const [promoterId, setPromoterId] = useState<string>();
  const [activeUser, setActiveUser] = useState(true);
  const [hasTrialPhase, setHasTrialPhase] = useState<boolean>(false);
  const [selectedMaturations, setSelectedMaturations] = useState<LabeledValue[]>();
  const [ruc, setRuc] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>(COUNTRY_CODE);
  const [phonePrefix, setPhonePrefix] = useState<string>(PHONE_PREFIX);
  const [address, setAddress] = useState<string>('');
  const [planType, setPlanType] = useState<string>('');
  const [planPriceOverride, setPlanPriceOverride] = useState<number>(0);
  const [disabledButtonForm, setDisabledButtonForm] = useState(false);

  const {
    showEditClientModal,
    selectedClient,
    sellers,
    promoters,
    isClientLoading,
    isFormUpdateLoading,
    filters,
  } = useSelector((state: RootState) => state.clients);

  const { clientStatus } = filters;

  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);

  useEffect(() => {
    return () => {
      dispatch(setSelectedClient(clientDefault));
      dispatch(setSellers([]));
      dispatch(setPromoters([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!selectedClient._id) {
      return;
    }

    const trialPhase = !!selectedClient.trialEndDate;
    setActiveUser(selectedClient.active);
    setCompanyName(selectedClient.name);
    setHasTrialPhase(trialPhase);
    setBusinessName(selectedClient.businessName);
    setOwnerId(selectedClient.owner._id);
    setUserEmail(selectedClient.owner.email);
    setCode(selectedClient.code);
    setRuc(selectedClient.ruc);
    setPhone(selectedClient.phone);
    setCountryCode(selectedClient.countryCode);
    setPhonePrefix(selectedClient.phonePrefix);
    setAddress(selectedClient.address);
    setResponsibleId(selectedClient.sellerId);
    setPromoterId(selectedClient.promoterId);
    dispatch(fetchSellers());

    setPlanType(selectedClient.planType);

    const planPriceOverride = selectedClient.balance?.planPriceOverride;
    if (planPriceOverride !== undefined) {
      setPlanPriceOverride(planPriceOverride);
    }

    const userIds = selectedClient.users?.map((user) => user._id);
    let owner = selectedClient.owner._id;

    if (userIds && userIds.includes(selectedClient.owner._id.toString())) {
      const user = selectedClient.users?.find((user) => user._id === selectedClient.owner._id.toString());
      owner = user?.firstName + ' ' + user?.lastName;
    }

    form.setFieldsValue({
      companyName: selectedClient.name,
      businessName: selectedClient.businessName,
      ownerName: owner,
      ewnerEmail: selectedClient.owner.email,
      companyBalance: selectedClient.balance?.quota,
      availableBalance: getAvailableBalance(selectedClient),
      planPrice: selectedClient.balance?.planPrice,
      hasTrialPhase: trialPhase,
      code: selectedClient.code,
      ruc: selectedClient.ruc,
      phone: selectedClient.phone,
      countryCode: selectedClient.countryCode,
      address: selectedClient.address,
      responsibleId: selectedClient.sellerId,
      promoterId: selectedClient.promoterId,
      createdAt: formatLongDateWithOffset(selectedClient.createdAt),
      planType: selectedClient.planType,
      planPriceOverride: planPriceOverride || '',
    });
  }, [dispatch, form, selectedClient, selectedClient.name, selectedClient.businessName, selectedClient.owner._id, selectedClient._id, selectedClient.owner.email, selectedClient.active, selectedClient.assignedCompanies, selectedClient.balance, selectedClient.isDistributor, selectedClient.trialEndDate, selectedClient.code, selectedClient.isInternational, selectedClient.daysToInitialStage, selectedClient.maxStage, selectedClient.maxDayJuvenile, selectedClient.maxDayGrowOut, selectedClient.sellerId, selectedClient.promoterId, selectedClient.planType, selectedClient.createdAt]);

  useEffect(() => {
    if (responsibleId) {
      dispatch(fetchPromotersBySeller(responsibleId));
    }
  }, [dispatch, responsibleId]);

  useEffect(() => {
    const selectedMaturations: LabeledValue[] = selectedClient.maturations ? selectedClient.maturations.map((maturation: Maturation) => {
      return { key: maturation._id, label: maturation.name, value: maturation._id };
    }) : [];
    setSelectedMaturations(selectedMaturations);
  }, [selectedClient.maturations]);

  const renderCompanyName = () => {
    return (
      <Col span={12}>
        <Form.Item
          label={t('clients.company')}
          name='companyName'
          rules={[{ required: true, message: t('clients.companyNameRequired') }]}
        >
          <LrvInput
            theme='light'
            id='txt_edit_companyName'
            placeholder={t('clients.companyName')}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderBusinessName = () => {
    return (
      <Col span={12}>
        <Form.Item
          required={!hasTrialPhase}
          name='businessName'
          label={hasTrialPhase ? `${t('clients.businessName')} (${t('common.optional')})` : t('clients.businessName')}
          rules={[{ required: !hasTrialPhase, message: t('clients.businessNameRequired') }]}
        >
          <LrvInput
            theme='light'
            id='txt_edit_businessName'
            placeholder={t('clients.businessName')}
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderRUC = () => {
    return (
      <Col span={12}>
        <Form.Item
          required={!hasTrialPhase}
          name='ruc'
          label={hasTrialPhase ? `${t('clients.ruc')} (${t('common.optional')})` : t('clients.ruc')}
          rules={[{ required: !hasTrialPhase, message: t('clients.rucRequired') }]}
        >
          <LrvInput
            theme='light'
            id='txt_edit_ruc'
            placeholder={t('clients.ruc')}
            value={ruc}
            onChange={(e) => setRuc(e.target.value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderPhone = () => {
    return (
      <Col span={12}>
        <Form.Item
          label={t('clients.phone')}
          name='phone'
          required
          rules={[
            { validator: (_, value) => validatePhoneInputGroup(value, form.getFieldValue('phone'), form.getFieldValue('countryCode')) },
          ]}
          validateTrigger={['onBlur', 'onChange']}
        >
          <Input.Group className={styles.inputGroup} compact>
            <Form.Item
              name='countryCode'
              noStyle
            >
              <LrvSelect
                theme='light'
                showSearch
                className={styles.countryCodeSelect}
                containerClassName={styles.countryCodeContainer}
                style={{ width: '100%' }}
                suffixIcon={<Icon name='arrow-down-s' />}
                removeIcon={<Icon name='close' />}
                placeholder={t('clients.select')}
                optionFilterProp='children'
                filterOption={filterOptionSelect}
                onChange={value => {
                  setPhonePrefix(getCountryCallingCode(value));
                  setCountryCode(value);
                }}
                value={countryCode}
              >
                {
                  getCountries().map((country) => {
                    return (
                      <Option
                        key={country}
                        value={country}
                      >
                        {`${country} +${getCountryCallingCode(country)}`}
                      </Option>
                    );
                  })
                }
              </LrvSelect>
            </Form.Item>
            <Form.Item
              name='phone'
              noStyle
            >
              <LrvInput
                theme='light'
                id='txt_edit_phone'
                placeholder={t('clients.phone')}
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              />
            </Form.Item>
          </Input.Group>

        </Form.Item>
      </Col>
    );
  };

  const renderAddress = () => {
    return (
      <Col span={12}>
        <Form.Item
          required={!hasTrialPhase}
          name='address'
          label={hasTrialPhase ? `${t('clients.address')} (${t('common.optional')})` : t('clients.address')}
          rules={[{ required: !hasTrialPhase, message: t('clients.addressRequired') }]}
        >
          <LrvInput
            theme='light'
            id='txt_edit_address'
            placeholder={t('clients.address')}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderUserInput = () => {
    const userIds = selectedClient.users?.map((user) => user._id);

    if (userIds && userIds.includes(selectedClient.owner._id.toString())) {
      const user = selectedClient.users?.find((user) => user._id === selectedClient.owner._id.toString());
      const userName = user?.firstName + ' ' + user?.lastName;

      return (
        <LrvInput theme='light' placeholder={t('clients.select')} readOnly value={userName} />
      );
    }

    return (
      <LrvSelect
        theme='light'
        showSearch
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('clients.select')}
        optionFilterProp='children'
        filterOption={filterOptionSelect}
        onChange={(e: string) => {
          setOwnerId(e);
          setUserEmail((selectedClient.users?.find(user => user._id === e))?.email || '');
        }}
        value={ownerId}
      >
        {selectedClient.users?.map((user, index) => {
          return <Option
            key={index}
            value={user._id}
            disabled={selectedClient.owner._id === user._id}
          >
            {`${user.firstName} ${user.lastName}`}
          </Option>;
        })}
      </LrvSelect>
    );
  };

  const renderCode = () => {
    return (
      <Col span={12}>
        <Form.Item
          required={!hasTrialPhase}
          name='code'
          label={hasTrialPhase ? `${t('clients.code')} (${t('common.optional')})` : t('clients.code')}
          rules={[{ required: !hasTrialPhase, message: t('clients.codeRequired') }]}
        >
          <LrvInput
            theme='light'
            maxLength={MAX_LENGTH_COMPANY_CODE}
            onChange={(e) => setCode(e.target.value)}
            placeholder={t('clients.code')}
            value={code}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderOwner = () => {
    return (
      <Col span={12}>
        <Form.Item
          label={t('clients.owner')}
          name='ownerName'
          rules={[{ required: true, message: t('clients.ownerRequired') }]}
        >
          {renderUserInput()}
        </Form.Item>
      </Col>
    );
  };

  const renderPlanPrice = () => {
    if (planType !== plansTypes.POSTPAID || isDistributorCompany()) {
      return null;
    }

    return (
      <Form.Item
        label={t('clients.planPriceOverride')}
        name='planPriceOverride'
      >
        <LrvInputNumber
          theme='light'
          value={planPriceOverride}
          onChange={(value) => {
            if (value) {
              setPlanPriceOverride(parseInt(value.toString()));
              return;
            }

            const planPrice = selectedClient.balance?.planPrice;

            if (planPrice !== undefined) {
              setPlanPriceOverride(planPrice);
            }
          }}
        />
      </Form.Item>
    );
  };

  const renderPlanType = () => {
    return (
      <Form.Item
        required
        label={t('clients.planType')}
        name='planType'
        rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
      >
        <LrvSelect
          theme='light'
          id='select_plan_type'
          placeholder={t('clients.select')}
          value={planType}
          onChange={(value) => setPlanType(value)}
          suffixIcon={<Icon name='arrow-down-s' />}
        >
          <Option
            key={plansTypes.POSTPAID}
            value={plansTypes.POSTPAID}
          >
            {t('clients.postpaid').toUpperCase()}
          </Option>

          <Option
            key={plansTypes.PREPAID}
            value={plansTypes.PREPAID}
          >
            {t('clients.prepaid').toUpperCase()}
          </Option>
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderPlanAndPrice = () => {
    if (selectedClient.isDistributor) {
      return null;
    }

    return (
      <>
        <Col span={12}>
          {renderPlanType()}
        </Col>

        <Col span={12}>
          {renderPlanPrice()}
        </Col>
      </>
    );
  };

  const renderResponsibleSelect = () => {
    return (
      <Col span={12}>
        <Form.Item
          required={selectedClient._id !== LARVIA_ID}
          label={t('clients.responsible')}
          name='responsibleId'
          rules={selectedClient._id === LARVIA_ID ? undefined : [() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvSelect
            id='select_responsable_client'
            showSearch
            theme='light'
            placeholder={t('clients.select')}
            value={responsibleId}
            onChange={(value) => {
              setResponsibleId(value);
              form.setFieldsValue({
                promoterId: undefined,
              });
              setPromoterId(undefined);
            }}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
          >
            {sellers.map((seller, index) => {
              return (
                <Option
                  key={index}
                  value={seller._id}
                >
                  {`${seller.firstName} ${seller.lastName}`}
                </Option>
              );
            })}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const renderPromoterSelect = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='promoterId'
          label={`${t('clients.promoter')} (${t('common.optional')})`}
        >
          <LrvSelect
            id='select_promoter_client'
            theme='light'
            showSearch
            placeholder={t('clients.select')}
            value={promoterId}
            onChange={(value) => {
              setPromoterId(value);
            }}
            disabled={!responsibleId || promoters.length === 0}
            suffixIcon={<Icon name='arrow-down-s' />}
            filterOption={filterOptionSelect}
          >
            {promoters.map((promoter, index) => {
              return (
                <Option
                  key={index}
                  value={promoter._id}
                >
                  {`${promoter.firstName} ${promoter.lastName}`}
                </Option>
              );
            })}
          </LrvSelect>
        </Form.Item>
      </Col>
    );
  };

  const removeTrialPhaseConfirm = (value: boolean) => {
    const title = value ? t('clients.confirmActiveTrialPhase') : t('clients.confirmEndTrialPhase');

    lrvConfirm({
      theme: 'light',
      title: title,
      icon: <ExclamationCircleOutlined />,
      okButtonProps: { id: 'btnOkTrialPhase' },
      okText: t('clients.accept'),
      okType: 'primary',
      cancelText: t('clients.cancel'),
      onOk () {
        setHasTrialPhase(value);
      },
    });
  };

  const renderTrialPhase = () => {
    if (!selectedClient.trialEndDate) {
      return;
    }

    return (
      <Col span={12}>
        <Form.Item
          className={styles.itemTrialPhase}
          name='hasTrialPhase'
          label={
            <div className={styles.trialPhase}>
              <div>{t('clients.trialPhase')} &nbsp;</div>
              {
                isAdminUser() &&
                <Tooltip
                  title={t('clients.trialPhaseDateExpired', { date: formatYearMonthDay(selectedClient.trialEndDate) })}
                >
                  <Button type='text' icon={<Icon name='information' className={styles.icon} />} />
                </Tooltip>
              }
            </div>}
        >
          <LrvSwitch
            theme='light'
            id='edit_trial_phase_switch'
            checked={hasTrialPhase}
            onChange={removeTrialPhaseConfirm}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderMaturationsAssigned = () => {
    if (!selectedMaturations || selectedMaturations.length === 0) {
      return <LrvInput theme='light' placeholder={t('maturations.unassigned')} readOnly />;
    }

    return (
      <LrvSelect
        theme='light'
        labelInValue
        className={styles.maturationsSelect}
        mode='multiple'
        removeIcon={<Icon name='close' />}
        value={selectedMaturations}
        disabled
      >{null}
      </LrvSelect>
    );
  };

  const renderMaturations = () => {
    return (
      <Col span={24}>
        <Form.Item
          name='editMaturations'
          label={t('users.maturations')}
        >
          <div id='select_client_maturations'>
            {renderMaturationsAssigned()}
          </div>
        </Form.Item>
      </Col>
    );
  };

  const renderAdditionalData = () => {
    return (
      <Col span={24}>
        <Form.Item>
          <LrvCollapse theme='light'>
            <Panel key='1' header={t('clients.additionalData')}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={t('users.email')}
                    name='ewnerEmail'
                  >
                    <LrvInput theme='light' placeholder={t('clients.ownerEmail')} readOnly value={userEmail} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('clients.planPrice')}
                    name='planPrice'
                  >
                    <LrvInput theme='light' readOnly value={applyThousandsSeparator(selectedClient.balance?.planPrice)} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={t('clients.companyBalance')}
                    name='companyBalance'
                  >
                    <Tooltip title={t('clients.balanceManage')}>
                      <LrvInput theme='light' placeholder={t('clients.companyBalance')} readOnly value={applyThousandsSeparator(selectedClient.balance?.quota)} />
                    </Tooltip>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={t('clients.availableBalance')}
                    name='availableBalance'
                  >
                    <Tooltip title={t('clients.balanceManage')}>
                      <LrvInput theme='light' placeholder={t('clients.availableBalance')} readOnly value={getAvailableBalance(selectedClient)} />
                    </Tooltip>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label={t('clients.createdAt')}
                    name='createdAt'
                  >
                    <LrvInput theme='light' placeholder={t('clients.createdAt')} readOnly value={formatLongDateWithOffset(selectedClient.createdAt)} />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </LrvCollapse>
        </Form.Item>
      </Col>
    );
  };

  const resetEditForm = () => {
    dispatch(setShowEditClientModal(false));
    setDisabledButtonForm(false);
    form.resetFields();
  };

  const editClient = () => {
    if (companyName === '' || ownerId === '') {
      return;
    }

    const currentPlanPriceOverride = selectedClient.balance?.planPriceOverride ? selectedClient.balance?.planPriceOverride : 0;

    const data: EditClientBody = {
      _id: selectedClient._id,
      name: companyName,
      businessName,
      code,
      ownerId,
      active: activeUser,
      hasTrialPhase,
      ruc,
      phone,
      phonePrefix,
      countryCode,
      address,
      promoterId: promoterId || null,
      sellerId: responsibleId,
      planType,
      currentPlanPriceOverride: currentPlanPriceOverride,
      planPriceOverride: planPriceOverride,
    };

    const active = getValueActiveUsers(clientStatus);
    dispatch(updateClient({ data, active, resetEditForm }));
  };

  return (
    <LrvModal
      theme='light'
      className={styles.editClientModal}
      title={t('clients.editClient')}
      isLoading={isClientLoading || isFormUpdateLoading}
      open={showEditClientModal}
      closeIcon={<Icon id='close_edit_client_modal' name='close' />}
      destroyOnClose
      cancelButtonProps={{ id: 'cancel_new_user' }}
      onCancel={() => {
        dispatch(setShowEditClientModal(false));
        form.resetFields();

        if (isRunningOnboarding) {
          dispatch(goToOnboardingNextStep(800));
        }
      }}
      cancelText={t('users.cancel')}
      style={{ top: 20 }}
      okText={t('users.save')}
      okButtonProps={{ htmlType: 'submit', className: styles.button, form: 'formEditClient', type: 'primary', disabled: disabledButtonForm, loading: isFormUpdateLoading, id: 'btn_save_edit' }}
    >
      <div className={styles.contentHeader}>
        <LrvText className={styles.title} theme='light' text={selectedClient.name === '' ? t('clients.client') : selectedClient.name} />
        <div className={styles.switch}>
          <LrvText theme='light' text={t('users.userActive')} />
          <LrvSwitch
            theme='light'
            id='active_user_switch'
            checked={activeUser}
            onChange={(value) => setActiveUser(value)}
          />
        </div>
      </div>

      <LrvForm
        theme='light'
        form={form}
        id='formEditClient'
        name='formEditClient'
        layout='vertical'
        onFieldsChange={() => {
          setDisabledButtonForm(form.getFieldsError().filter(({ errors }) => errors.length).length > 0);
        }}
        onFinish={editClient}
      >
        <Row gutter={16}>
          {renderCompanyName()}
          {renderBusinessName()}
          {renderRUC()}
          {renderCode()}
          {renderAddress()}
          {renderPhone()}
          {renderPlanAndPrice()}
          {renderOwner()}

          {
            isAdminUser() && selectedClient._id !== LARVIA_ID &&
            <>
              {renderResponsibleSelect()}
              {renderPromoterSelect()}
            </>
          }

          {renderTrialPhase()}

          {renderMaturations()}
          {renderAdditionalData()}
        </Row>
      </LrvForm>
    </LrvModal>
  );
};
