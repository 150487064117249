import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../state/store';
import { getMonthName } from '../../utils/date';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { validateNumber } from '../../utils/validations';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvSwitch } from '../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { isAdminUser, DEFAULT_DAYS_TO_INITIAL_STAGE, DEFAULT_STAGE_MAX, DEFAULT_DAY_MAX_JUVENILE, DEFAULT_DAY_MAX_GROW_OUT, plansTypes, isDistributorCompany, MIN_DAYS_TO_INITIAL_STAGE, MAX_DAYS_TO_INITIAL_STAGE } from '../../config/commons';

import { SettingClientBody } from './interfaces';
import styles from './ClientSettings.module.scss';
import { updateRenuevalMonths, setShowSettingClientModal, setShowCreateClientModal, updateClientSetting, fetchCompanies } from './clientsSlice';
import { discountPorcentage, getValueActiveUsers, MAX_DAYS_STAGE, MIN_DAYS_JUVENILE, MAX_DAYS_JUVENILE, MAX_DAYS_GROW_OUT, MIN_DAYS_GROW_OUT, months, hasPermissionToManageCompanies } from './clientsHelper';

const { Option } = Select;

export const ClientSettings = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [form] = Form.useForm();

  const [daysToInitialStage, setDaysToInitialStage] = useState<number>(DEFAULT_DAYS_TO_INITIAL_STAGE);
  const [maxStage, setMaxStage] = useState<number>(DEFAULT_STAGE_MAX);
  const [maxDayJuvenile, setMaxDayJuvenile] = useState<number>(DEFAULT_DAY_MAX_JUVENILE);
  const [maxDayGrowOut, setMaxDayGrowOut] = useState<number>(DEFAULT_DAY_MAX_GROW_OUT);

  const [assignedCompaniesActive, setAssignedCompaniesActive] = useState<string[]>([]);
  const [assignedNameCompaniesInactive, setAssignedNameCompaniesInactive] = useState<string[]>([]);
  const [discountPercentage, newDiscountPercentage] = useState<number | undefined>(0);
  const [isDistributor, setIsDistributor] = useState<boolean>(false);
  const [isInternational, setIsInternational] = useState<boolean>(false);
  const [allowXlsxAnalysisReport, setAllowXlsxAnalysisReport] = useState<boolean>(false);
  const [renuevalMonths, setRenuevalMonths] = useState<number[]>([]);
  const [allowAutomaticConsolidation, setAllowAutomaticConsolidation] = useState<boolean>(false);
  const [showStockingParameterSection, setShowStockingParameterSection] = useState<boolean>(false);
  const [disabledButtonForm, setDisabledButtonForm] = useState(false);

  const {
    activeAssignedCompanies,
    companies,
    filters,
    inactiveAssignedCompanies,
    isClientLoading,
    isFormCreateLoading,
    isFormUpdateLoading,
    hasEmailError,
    selectedClient,
    showSettingClientModal,
  } = useSelector((state: RootState) => state.clients);

  const { clientStatus } = filters;

  useEffect(() => {
    if (!selectedClient._id) {
      return;
    }

    setIsDistributor(selectedClient.isDistributor);
    setIsInternational(selectedClient.isInternational);
    setAllowAutomaticConsolidation(selectedClient.allowAutomaticConsolidation || false);
    setDaysToInitialStage(selectedClient.daysToInitialStage);
    setMaxStage(selectedClient.maxStage);
    setMaxDayJuvenile(selectedClient.maxDayJuvenile);
    setMaxDayGrowOut(selectedClient.maxDayGrowOut);

    newDiscountPercentage(selectedClient.balance?.discountPercentage);
    setShowStockingParameterSection(selectedClient.showStockingParameterSection);

    const renuevalMonths = selectedClient.balance?.renuevalMonths;
    setRenuevalMonths(renuevalMonths || []);
    setAllowXlsxAnalysisReport(selectedClient.allowXlsxAnalysisReport || false);

    form.setFieldsValue({
      daysToInitialStage: selectedClient.daysToInitialStage,
      maxStage: selectedClient.maxStage,
      maxDayJuvenile: selectedClient.maxDayJuvenile,
      maxDayGrowOut: selectedClient.maxDayGrowOut,
      allowAutomaticConsolidation: selectedClient.allowAutomaticConsolidation,
      isDistributor: selectedClient.isDistributor,
      isInternational: selectedClient.isInternational,
      allowXlsxAnalysisReport: selectedClient.allowXlsxAnalysisReport,
      showStockingParameterSection: selectedClient.showStockingParameterSection,
      discountPercentage: selectedClient.balance?.discountPercentage,
      renuevalMonths: renuevalMonths,
    });
  }, [dispatch, form, selectedClient, selectedClient.name, selectedClient.businessName, selectedClient.owner._id, selectedClient._id, selectedClient.owner.email, selectedClient.active, selectedClient.assignedCompanies, selectedClient.balance, selectedClient.isDistributor, selectedClient.trialEndDate, selectedClient.code, selectedClient.isInternational, selectedClient.daysToInitialStage, selectedClient.maxStage, selectedClient.maxDayJuvenile, selectedClient.maxDayGrowOut, selectedClient.sellerId, selectedClient.promoterId, selectedClient.planType, selectedClient.createdAt, selectedClient.allowAutomaticConsolidation]);

  useEffect(() => {
    if (!isDistributor) {
      setAssignedCompaniesActive([]);
      setAssignedNameCompaniesInactive([]);
      return;
    }

    const ids = activeAssignedCompanies.map((company) => company._id);
    const names = inactiveAssignedCompanies.map((company) => company.name);

    setAssignedCompaniesActive(ids);
    setAssignedNameCompaniesInactive(names);

    form.setFieldsValue({
      activeAssignedCompanies: ids,
      inactiveAssignedCompanies: names,
    });
  }, [form, isDistributor, activeAssignedCompanies, inactiveAssignedCompanies]);

  useEffect(() => {
    if (!hasEmailError && !isFormCreateLoading) {
      dispatch(setShowCreateClientModal(false));
      form.resetFields();
    }
  }, [dispatch, form, hasEmailError, isFormCreateLoading]);

  const renderDaysToInitialStage = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='daysToInitialStage'
          label={t('clients.daysToInitialStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, MIN_DAYS_TO_INITIAL_STAGE); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_TO_INITIAL_STAGE}
            max={MAX_DAYS_TO_INITIAL_STAGE}
            value={daysToInitialStage}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setDaysToInitialStage(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderMaxStage = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='maxStage'
          label={t('clients.maxStage')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, Number(daysToInitialStage)); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={daysToInitialStage}
            max={MAX_DAYS_STAGE}
            value={maxStage}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setMaxStage(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderMaxDayJuvenile = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='maxDayJuvenile'
          label={t('clients.maxDayJuvenile')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_JUVENILE}
            max={MAX_DAYS_JUVENILE}
            value={maxDayJuvenile}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setMaxDayJuvenile(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderMaxDayGrowOut = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='maxDayGrowOut'
          label={t('clients.maxDayGrowOut')}
          required
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true); } })]}
        >
          <LrvInputNumber
            theme='light'
            min={MIN_DAYS_GROW_OUT}
            max={MAX_DAYS_GROW_OUT}
            value={maxDayGrowOut}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setMaxDayGrowOut(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAssignedCompanies = () => {
    if (!isDistributor) {
      return null;
    }

    return (
      <Form.Item>
        <Card
          className={styles.assignedCompanies}
          title={t('clients.assignedCompanies.title')}
          size='small'
        >
          {renderAssignedCompaniesActive()}
          {renderAssignedCompaniesInactive()}
        </Card>
      </Form.Item>
    );
  };

  const renderDiscountPercentage = () => {
    if (!isDistributor) {
      return null;
    }

    return (
      <Col span={12}>
        <Form.Item
          required={true}
          label={t('clients.discountPercentage')}
          name='discountPercentage'
          rules={[() => ({ validator (rule, value) { return validateNumber(value, true, discountPorcentage.min, discountPorcentage.max, t('users.balance.discountError', { min: discountPorcentage.min, max: discountPorcentage.max })); } })]}
        >
          <LrvInputNumber
            theme='light'
            id='txt_edit_discount_percentage'
            value={discountPercentage}
            onChange={(value) => {
              if (value) {
                const discount = parseInt(value.toString());
                newDiscountPercentage(discount);
              }
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderRenuevalMonths = () => {
    if (!isAdminUser() || selectedClient.planType !== plansTypes.POSTPAID) {
      return null;
    }

    return (
      <Form.Item
        name='renuevalMonths'
        label={t('clients.renuevalMonths')}
      >
        <LrvSelect
          theme='light'
          showSearch
          mode='multiple'
          suffixIcon={<Icon name='arrow-down-s' />}
          removeIcon={<Icon name='close' />}
          placeholder={t('clients.select')}
          optionFilterProp='children'
          filterOption={filterOptionSelect}
          onChange={(e: number[]) => {
            setRenuevalMonths(e);
          }}
          value={renuevalMonths}
        >
          {
            months.map((month) => {
              return (
                <Option
                  key={month}
                  value={month}
                >
                  {getMonthName(month.toString())}
                </Option>
              );
            })
          }
        </LrvSelect>
      </Form.Item>
    );
  };

  const renderAllowAutomaticConsolidation = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='allowAutomaticConsolidation'
          label={t('clients.automaticConsolidation')}
        >
          <LrvSwitch
            theme='light'
            id='edit_allow_automatic_consolidation_switch'
            checked={allowAutomaticConsolidation}
            onChange={(value) => setAllowAutomaticConsolidation(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderSwitchIsDistributor = () => {
    if (isDistributorCompany()) {
      return;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='isDistributor'
          label={t('clients.distributionCompany')}
        >
          <LrvSwitch
            theme='light'
            id='edit_distribution_company_switch'
            checked={isDistributor}
            onChange={(value) => {
              setIsDistributor(value);

              if (value && companies.length === 0) {
                dispatch(fetchCompanies());
              }
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderSwitchXlsxReport = () => {
    if (!hasPermissionToManageCompanies) {
      return;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='allowXlsxAnalysisReport'
          label={t('clients.allowXlsxAnalysisReport')}
        >
          <LrvSwitch
            theme='light'
            id='allow_xlsx_switch'
            checked={allowXlsxAnalysisReport}
            onChange={(value) => setAllowXlsxAnalysisReport(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderSwitchStockingParameter = () => {
    if (!hasPermissionToManageCompanies) {
      return;
    }

    return (
      <Col span={12}>
        <Form.Item
          name='showStockingParameterSection'
          label={t('clients.showStockingParameterSection')}
        >
          <LrvSwitch
            theme='light'
            id='allow_stocking_parameter_switch'
            checked={showStockingParameterSection}
            onChange={(value) => setShowStockingParameterSection(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderSwitchIsInternational = () => {
    return (
      <Col span={12}>
        <Form.Item
          name='isInternational'
          label={t('clients.international')}
        >
          <LrvSwitch
            theme='light'
            id='edit_is_international_switch'
            checked={isInternational}
            onChange={(value) => setIsInternational(value)}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderAssignedCompaniesActive = () => {
    return (
      <Form.Item
        name='activeAssignedCompanies'
        label={t('clients.assignedCompanies.active')}
      >
        <div id='assigned_companies_dropdown'>
          <LrvSelect
            theme='light'
            showSearch
            mode='multiple'
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('clients.select')}
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            onChange={(e: string[]) => {
              setAssignedCompaniesActive(e);
            }}
            value={assignedCompaniesActive}
          >
            {companies?.map((company, index) => {
              if (company._id !== selectedClient._id) {
                return (
                  <Option
                    key={index}
                    value={company._id}
                  >
                    {`${company.name}`}
                  </Option>
                );
              }
            })}
          </LrvSelect>
        </div>
      </Form.Item>
    );
  };

  const renderAssignedCompaniesInactive = () => {
    if (assignedNameCompaniesInactive.length === 0) {
      return null;
    }

    return (
      <Form.Item
        name='inactiveAssignedCompanies'
        label={t('clients.assignedCompanies.inactive')}
      >
        <div id='assigned_companies_inactive_dropdown'>
          <LrvSelect
            theme='light'
            mode='multiple'
            suffixIcon={<Icon name='arrow-down-s' />}
            placeholder={t('clients.select')}
            optionFilterProp='children'
            filterOption={filterOptionSelect}
            onChange={(e: string[]) => {
              setAssignedNameCompaniesInactive(e);
            }}
            value={assignedNameCompaniesInactive}
          >
            {null}
          </LrvSelect>
        </div>
      </Form.Item>
    );
  };

  const resetEditForm = () => {
    dispatch(setShowSettingClientModal(false));
    setDisabledButtonForm(false);
    form.resetFields();
  };

  const editClient = () => {
    const active = getValueActiveUsers(clientStatus);

    const inactiveCompanyIds: string[] = [];
    inactiveAssignedCompanies.forEach((company) => {
      if (assignedNameCompaniesInactive.includes(company.name)) {
        inactiveCompanyIds.push(company._id);
      }
    });
    const newAssignedCompanies = assignedCompaniesActive.concat(inactiveCompanyIds);

    const data: SettingClientBody = {
      _id: selectedClient._id,
      daysToInitialStage,
      maxStage,
      maxDayJuvenile,
      maxDayGrowOut,
      allowAutomaticConsolidation,
      isDistributor,
      isInternational,
      allowXlsxAnalysisReport,
      showStockingParameterSection,
      assignedCompanies: isDistributor !== selectedClient.isDistributor && !isDistributor ? [] : newAssignedCompanies,
      activeAssignedCompanies: selectedClient.assignedCompanies,
    };

    const shouldUpdateCompanyBalance = renuevalMonths !== selectedClient.balance?.renuevalMonths || discountPercentage !== selectedClient.balance?.discountPercentage;
    if (isAdminUser() && selectedClient.balance?._id && shouldUpdateCompanyBalance) {
      updateRenuevalMonths({ companyBalanceId: selectedClient.balance._id, renuevalMonths, discountPercentage: discountPercentage });
    }

    dispatch(updateClientSetting({ data, active, resetEditForm }));
  };

  return (
    <LrvModal
      theme='light'
      className={styles.settingClientModal}
      title={t('clients.settings')}
      isLoading={isClientLoading || isFormUpdateLoading}
      open={showSettingClientModal}
      closeIcon={<Icon id='close_edit_client_modal' name='close' />}
      destroyOnClose
      cancelButtonProps={{ id: 'cancel_new_user' }}
      onCancel={() => {
        dispatch(setShowSettingClientModal(false));
        form.resetFields();
      }}
      cancelText={t('users.cancel')}
      style={{ top: 20 }}
      okText={t('users.save')}
      okButtonProps={{ htmlType: 'submit', form: 'formEditClient', type: 'primary', disabled: disabledButtonForm, loading: isFormUpdateLoading, id: 'btn_save_edit' }}
    >
      <LrvForm
        theme='light'
        form={form}
        id='formEditClient'
        name='formEditClient'
        layout='vertical'
        onFieldsChange={() => {
          setDisabledButtonForm(
            form.getFieldsError().filter(({ errors }) => errors.length).length > 0
          );
        }}
        onFinish={editClient}
      >
        <Row gutter={16}>
          {renderDaysToInitialStage()}
          {renderMaxStage()}

          {renderMaxDayJuvenile()}
          {renderMaxDayGrowOut()}

          {renderAllowAutomaticConsolidation()}

          {renderSwitchIsDistributor()}
          {renderSwitchIsInternational()}

          {renderSwitchXlsxReport()}
          {renderSwitchStockingParameter()}

          {renderDiscountPercentage()}
        </Row>

        {renderAssignedCompanies()}
        {renderRenuevalMonths()}
      </LrvForm>
    </LrvModal>
  );
};
