import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Form, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { sortTankStatus } from '../../utils/sort';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../utils/select';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { getUnitPhaseTypeFromStocking } from '../../helpers/stocking.helpers';
import { tankStatuses, typeFetchModule, typeFetchTank, typeFetchCampus, unitStatuses } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import { Stocking as ISowing } from './interfaces';
import { fetchModules, fetchTanks, moveStocking, fetchCampuses, setShowModalMove } from './sowingsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

function MoveStockingModal (props: Props) {
  const { theme = 'dark' } = props;

  const { Option } = Select;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;

  const [moveStockingTank, setMoveStockingTank] = useState({ id: '', name: '' });
  const [moveStockingModule, setMoveStockingModule] = useState('');
  const [moveStockingCampus, setMoveStockingCampus] = useState('');
  const [currentStockingTank, setCurrentStockingTank] = useState('');
  const [currentStockingModule, setCurrentStockingModule] = useState('');
  const [currentStockingCampus, setCurrentStockingCampus] = useState('');
  const [currentStockingCampusId, setCurrentStockingCampusId] = useState('');

  const [disabledButtonFormEditStocking, setDisabledButtonFormEditStocking] = useState(false);

  const showModal = useSelector((state: Store) => state.stockings.showModalMove);
  const isLoadingStocking = useSelector((state: Store) => state.stockings.isLoadingStocking);
  const { stockingsToShow } = useSelector((state: Store) => state.stockings.filters);
  const currentPage = useSelector((state: Store) => state.stockings.currentPage);
  const isLoadingToMove = useSelector((state: Store) => state.stockings.isLoadingToMove);
  const existErrorToMove = useSelector((state: Store) => state.stockings.existErrorToMove);

  const company = useSelector((state: Store) => state.header.company);
  const selectedCampus = useSelector((state: Store) => state.stockings.selectedCampus);
  const moduleId = useSelector((state: Store) => state.stockings.selectedModuleId);
  const tankId = useSelector((state: Store) => state.stockings.selectedTankId);

  const modulesMove = useSelector((state: Store) => state.stockings.modulesMove);
  const tanksMove = useSelector((state: Store) => state.stockings.tanksMove);
  const campusesMove = useSelector((state: Store) => state.stockings.campusesMove);
  const selectedStocking = useSelector((state: Store) => state.stockings.selectedStocking);

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;
  const tanks = cloneDeep(tanksMove);
  tanks.sort(sortTankStatus);

  useEffect(() => {
    const fillFormMoveStocking = (stocking: ISowing) => {
      const unitPhaseType = getUnitPhaseTypeFromStocking(stocking.phaseType);
      dispatch(fetchCampuses({ companyId: stocking.companyId, phaseType: unitPhaseType, type: typeFetchCampus.MOVE }));
      if (stocking.campusId._id) {
        dispatch(fetchModules(stocking.campusId._id, typeFetchModule.MOVE));
      }

      if (stocking.moduleId._id) {
        dispatch(fetchTanks(stocking.campusId._id, stocking.moduleId._id, typeFetchModule.MOVE));
      }
    };

    if (selectedStocking._id !== '' && showModal === true) {
      fillFormMoveStocking(selectedStocking);

      setMoveStockingCampus(selectedStocking.campusId._id);
      setMoveStockingModule(selectedStocking.moduleId._id);

      setCurrentStockingTank(selectedStocking.tankId.name ?? t('stockings.undefined'));
      setCurrentStockingModule(selectedStocking.moduleId.name ?? t('stockings.undefined'));
      setCurrentStockingCampus(selectedStocking.campusId.name ?? t('stockings.undefined'));
      setCurrentStockingCampusId(selectedStocking.campusId._id);
    }
  }, [dispatch, selectedStocking, showModal]);

  useEffect(() => {
    if (!showModal) {
      resetStockingMovement();
    }
  }, [dispatch, existErrorToMove, showModal]);

  useEffect(() => {
    if (isCampusInactive) {
      setDisabledButtonFormEditStocking(true);
    }
  }, [isCampusInactive]);

  function compareCampus () {
    return currentStockingCampusId === moveStockingCampus;
  }

  function validateStockingMovement () {
    if (moveStockingCampus && moveStockingModule && moveStockingTank.id) {
      return true;
    }

    return false;
  }

  function resetStockingMovement () {
    setMoveStockingCampus('');
    setMoveStockingModule('');
    setMoveStockingTank({ 'id': '', 'name': '' });
  }

  function renderLabelCampusInactive () {
    if (isCampusInactive) {
      return (
        <Form.Item>
          <TinyLabel className={styles.campusInactive} text={t('stockings.inactiveCampus')} />
        </Form.Item>
      );
    }

    return null;
  }

  return <LrvModal
    theme={theme}
    isLoading={isLoadingStocking}
    className={cx(styles.settingStockingModal, 'settingStockingModal')}
    title={t('stockings.moveStocking')}
    open={showModal}
    destroyOnClose={true}
    okButtonProps={{
      id: 'submit_edit_stocking',
      htmlType: 'submit',
      form: 'formMoveStocking',
      loading: isLoadingToMove,
      disabled: disabledButtonFormEditStocking,
    }}
    onOk={() => {
      const paramsToFetchStockings = { companyId: company._id, campusId: selectedCampus?._id, moduleId, tankId, page: currentPage, phaseType: selectedStocking.phaseType, stockingsToShow };
      const makeFetchStockings = pathname === '/production/stockings';
      if (validateStockingMovement()) {
        const body = { campusId: moveStockingCampus, moduleId: moveStockingModule, tankId: moveStockingTank.id, tankName: moveStockingTank.name };
        dispatch(moveStocking(selectedStocking._id, body, makeFetchStockings, paramsToFetchStockings));
      }
    }}
    okText={t('stockings.move')}
    cancelText={t('stockings.cancel')}
    onCancel={() => {
      dispatch(setShowModalMove(false));
    }}
    width={580}
  >
    <div>
      <LrvForm
        theme={theme}
        layout='vertical'
      >
        <div className={styles.parent}>
          <Form.Item className={styles.item}>
            <div className={styles.row}>
              <LrvInput theme={theme} value={currentStockingCampus} readOnly />

              <div className={styles.icon} >
                <Icon name='arrow-right-s' />
              </div>

              <LrvInput theme={theme} value={currentStockingModule} readOnly />

              <div className={styles.icon} >
                <Icon name='arrow-right-s' />
              </div>

              <LrvInput theme={theme} value={currentStockingTank} readOnly />
            </div>
          </Form.Item>

          <Form.Item className={styles.item}>
            <div className={styles.row}>
              <div id='dropdown_campus_move' className={styles.select}>
                <LrvSelect
                  theme={theme}
                  showSearch
                  value={moveStockingCampus}
                  onChange={value => {
                    dispatch(fetchModules(value, typeFetchModule.MOVE));
                    setMoveStockingModule('');
                    setMoveStockingTank({ 'id': '', 'name': '' });
                    setMoveStockingCampus(value);
                    setDisabledButtonFormEditStocking(true);
                  }}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                  disabled={isCampusInactive}
                >
                  {campusesMove.map((campus) => <Option key={campus._id} value={campus._id}>{campus.name}</Option>)}
                </LrvSelect>
              </div>

              <div className={styles.icon} >
                <Icon name='arrow-right-s' />
              </div>

              <div id='dropdown_modules_move' className={styles.select}>
                <LrvSelect
                  theme={theme}
                  showSearch
                  value={moveStockingModule}
                  onChange={value => {
                    dispatch(fetchTanks(moveStockingCampus, value, typeFetchTank.MOVE));
                    setMoveStockingTank({ 'id': '', 'name': '' });
                    setMoveStockingModule(value);
                    setDisabledButtonFormEditStocking(true);
                  }}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  disabled={!moveStockingCampus || isCampusInactive}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {modulesMove.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
                </LrvSelect>
              </div>

              <div className={styles.icon} >
                <Icon name='arrow-right-s' />
              </div>

              <div id='dropdown_tanks_move' className={styles.select}>
                <LrvSelect
                  theme={theme}
                  showSearch
                  value={moveStockingTank.id}
                  onChange={value => {
                    const tank = tanks.find((tank) => tank._id === value);
                    setMoveStockingTank({ id: value, name: tank?.name || '' });
                    const disabled = !(moveStockingCampus && moveStockingModule && value) || isCampusInactive;
                    setDisabledButtonFormEditStocking(disabled);
                  }}
                  suffixIcon={<Icon name='arrow-down-s' />}
                  disabled={!moveStockingModule || isCampusInactive}
                  filterOption={filterOptionSelect}
                  dropdownMatchSelectWidth={false}
                >
                  {tanks.map((tank) => <Option disabled={tank.status !== tankStatuses.AVAILABLE} key={tank._id} value={tank._id}>{tank.name}</Option>)}
                </LrvSelect>
              </div>

            </div>
          </Form.Item>

          {renderLabelCampusInactive()}

          <Form.Item className={styles.item}>
            <div className={styles.alertError} >
              {!isLoadingStocking && <div id={compareCampus() ? 'hide_alert' : 'show_alert'} role='alert' className={compareCampus() ? styles.hasError : ''}>{t('stockings.moveOtherCampus')}</div>}
            </div>
          </Form.Item>

          <div className={styles.child}>
            <ul className={styles.tree}>
              <li> {t('stockings.origin')} </li>
              <li className={styles.last}> {t('stockings.destination')} </li>
            </ul>
          </div>
        </div>
      </LrvForm>
    </div>
  </LrvModal>;
}

export default MoveStockingModal;