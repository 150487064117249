import React from 'react';
import cx from 'classnames';
import { Radio } from 'antd';

import { LrvRadioGroupProps } from '../../interfaces/lrvRadioGroup';

import styles from './LrvRadioGroup.module.scss';

const { Group } = Radio;

export const LrvRadioGroup = (props: LrvRadioGroupProps) => {
  const { title, containerClassName, titleClassName, className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(styles.radioGroup, isDarkTheme ? styles.darkRadioGroup : styles.lightRadioGroup, className),
  };

  return (
    <div className={cx(isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}>
      <div className={cx(isDarkTheme ? styles.darkTitle : styles.lightTitle, titleClassName)}>{title}</div>
      <Group {...lrvProps}>
      </Group>
    </div>
  );
};
