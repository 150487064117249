import i18next from 'i18next';

import { stockingPhaseTypes } from '../config/commons';

export const getTitlePhaseType = (phaseType: string) => {
  switch (phaseType) {
    case stockingPhaseTypes.LARVAE:
    default:
      return i18next.t('header.phaseTypes.larvae');

    case stockingPhaseTypes.JUVENILE:
      return i18next.t('header.phaseTypes.juveniles');

    case stockingPhaseTypes.ADULT:
      return i18next.t('header.phaseTypes.growOut');
  }
};
