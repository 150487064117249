import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { getMaxStage } from '../../Analysis/helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { changeHeader } from '../../AppHeader/headerSlice';
import { filterOptionSelect } from '../../../utils/select';
import { handleResizeEvent } from '../../../utils/dimensions';
import { GrowthDeltaStocking } from '../../Sowings/interfaces';
import { getParameter } from '../../../helpers/stocking.helpers';
import { LrvTag } from '../../../common/components/LrvTag/LrvTag';
import { DropdownProps } from '../../../common/interfaces/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import CleanButton from '../../../common/components/buttons/CleanButton';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import GrowthDeltaChart from '../../../common/components/charts/ShadedPlot/GrowthDeltaChart';
import { LrvFilterPanel } from '../../../common/components/LrvSideFloatingPanel/LrvFilterPanel';
import { CONTAINER_LABEL, stockingPhaseTypes, THEME, unitPhaseTypes, unitStatuses } from '../../../config/commons';
import { typeParam, sortDeltaData, calcDeltaStages, rectColor, typeScale, typesChart, lineColor, selectedTickStoke } from '../../../common/components/charts/ShadedPlot/helpers';

import './GrowthDelta.scss';
import styles from './GrowthDelta.module.scss';
import * as growthDeltaSlice from './growthDeltaSlice';
import { disabledFromDate, disabledToDate, getHeightOfTheOtherElements, getMaxDay, getWidthOfTheOtherElements } from './helpers';

let chart: GrowthDeltaChart | null;

const { Option } = Select;

const GrowthDelta = () => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [maxStage, setMaxStage] = useState<number>(0);

  const {
    data: growDeltaData,
    filters: growDeltaFilters,
    campus: campuses,
    modules,
    containers,
    stockings,
    isDownloadingFile,
  } = useSelector((state: Store) => state.growthDelta);

  const {
    firstStage,
    lastStage,
    firstStageZoom,
    lastStageZoom,
    campus,
    module,
    containerId,
    stockingId,
    fromDate,
    toDate,
    movingAverage,
    showMovingAverage,
  } = growDeltaFilters;

  const {
    company: selectedCompany,
    phaseType
  } = useSelector((state: Store) => state.header);

  const isSelectedAnyFilter = growDeltaFilters.module?._id || growDeltaFilters.containerId || growDeltaFilters.stockingId;
  const modulePhaseTypeSelected = growDeltaFilters.module?.phaseType || stockingPhaseTypes.LARVAE;

  const refChart = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refLegends = useRef<HTMLDivElement>(null);

  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements());
  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));

  const parameterLabel = getParameter({ parameter: typeParam.GROWTH_DELTA, stockingPhaseType: modulePhaseTypeSelected });

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  useEffect(() => {
    dispatch(changeHeader({ title: 'stockings.growthDelta.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    dispatch(growthDeltaSlice.setGrowthDeltaData([]));
    dispatch(growthDeltaSlice.resetGrowthDeltaFilters({ company: selectedCompany, phaseType }));
  }, [dispatch, selectedCompany._id, phaseType]);

  useEffect(() => {
    return () => {
      chart = null;
      dispatch(growthDeltaSlice.setGrowthDeltaData([]));
    };
  }, [dispatch]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements());
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !isSelectedAnyFilter) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements());
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, legends: refLegends }));
  }, [selectedCompany._id, isSelectedAnyFilter]);

  useEffect(() => {
    if (!growDeltaData.length) {
      return;
    }

    function updateRange () {
      let firstStage = 0;
      let lastStage = 0;

      const data: GrowthDeltaStocking[] = [{ data: growDeltaData, stockingId: '' }];
      const deltaStockings = sortDeltaData(data, '', [], []);
      const stages = calcDeltaStages(deltaStockings, maxStage);
      firstStage = stages[0];
      lastStage = stages[1];

      dispatch(growthDeltaSlice.setFirstStage(firstStage));
      dispatch(growthDeltaSlice.setLastStage(lastStage));

      dispatch(growthDeltaSlice.setFirstStageZoom(firstStage));
      dispatch(growthDeltaSlice.setLastStageZoom(lastStage));
    }

    updateRange();

  }, [maxStage, growDeltaData]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const maxDay = getMaxDay(selectedCompany, modulePhaseTypeSelected);
    dispatch(growthDeltaSlice.setLastStage(maxDay));

    const maxStage = getMaxStage(selectedCompany, modulePhaseTypeSelected);
    setMaxStage(maxStage);
  }, [selectedCompany, modulePhaseTypeSelected]);

  useEffect(updateScreenOnResize, [width, height]);

  useEffect(() => {
    if (!growDeltaData.length && !isSelectedAnyFilter) {
      return;
    }

    const data: GrowthDeltaStocking[] = [{ data: growDeltaData, stockingId: '' }];
    const deltaStockings = sortDeltaData(data, '', [], []);

    if (chart) {
      chart.refreshChart({ dataMetric: deltaStockings, scale: typeScale.LINEAR, firstStage, lastStage, width, height, movingAverage, showMovingAverage, colorFillRect, colorLine });
      return;
    }

    const props = {
      colorFillRect,
      colorLine,
      companyData: selectedCompany,
      container: refChart.current,
      dataMetric: deltaStockings,
      firstStage,
      height,
      lastStage,
      parameter: typeParam.GROWTH_DELTA,
      phaseType: modulePhaseTypeSelected,
      scale: typeScale.LINEAR,
      showLabels: false,
      typeChart: typesChart.STOCKINGS,
      width,
      movingAverage,
      showMovingAverage,
      selectedTickStoke: tickStroke,
      dispatch,
    };
    chart = new GrowthDeltaChart(props);
  }, [dispatch, selectedCompany, growDeltaData, firstStage, lastStage, width, height, isSelectedAnyFilter, modulePhaseTypeSelected, movingAverage, showMovingAverage, colorFillRect, colorLine, tickStroke]);

  function updateScreenOnResize () {
    chart && chart.resize(width, height);
  }

  const getAxisXLabel = () => {
    return growDeltaFilters.campus?.phaseType === unitPhaseTypes.LARVAE ? t('survivalRate.stages') : t('survivalRate.days');
  };

  const triggerGetGrowthDeltaData = (params: { stockingId?: string; containerId?: string; moduleId?: string; fromDate?: string; toDate?: string }) => {
    const { stockingId, containerId, moduleId, fromDate, toDate } = params;

    if (stockingId) {
      dispatch(growthDeltaSlice.fetchGrowthDeltaData({ sowingId: stockingId, phaseType }));
      return;
    }

    if (containerId) {
      dispatch(growthDeltaSlice.fetchGrowthDeltaData({ containerId, fromDate, toDate, phaseType }));
      return;
    }

    if (moduleId) {
      dispatch(growthDeltaSlice.fetchGrowthDeltaData({ moduleId, fromDate, toDate, phaseType }));
    }
  };

  const onChangeCampus = (value: string) => {
    const campus = campuses.find((campus) => campus._id === value);

    if (!campus) {
      return;
    }

    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.campus = campus;
    filters.module = undefined;
    filters.containerId = undefined;
    filters.stockingId = undefined;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    dispatch(growthDeltaSlice.fetchGrowthDeltaModules(campus._id));
  };

  const onChangeModule = (value: string) => {
    const module = modules.find((module) => module._id === value);

    if (!module) {
      return;
    }

    const maxDay = getMaxDay(selectedCompany, module.phaseType);

    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.module = module;
    filters.containerId = undefined;
    filters.stockingId = undefined;
    filters.firstStage = 1;
    filters.lastStage = maxDay;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    dispatch(growthDeltaSlice.fetchGrowthDeltaContainers(value));
    triggerGetGrowthDeltaData({ moduleId: module?._id, fromDate: fromDate, toDate: toDate });
  };

  const onChangeTank = (value: string) => {
    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.containerId = value;
    filters.stockingId = undefined;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    dispatch(growthDeltaSlice.fetchGrowthDeltaStockings(value));
    triggerGetGrowthDeltaData({ containerId: value, fromDate: fromDate, toDate: toDate });
  };

  const onChangeStocking = (value: string) => {
    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.stockingId = value;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    triggerGetGrowthDeltaData({ stockingId: value });
  };

  const onChangeFromDate = (date: moment.Moment | null, dateString: string) => {
    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.fromDate = dateString;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    triggerGetGrowthDeltaData({ moduleId: module?._id, containerId, stockingId, fromDate: dateString, toDate });
  };

  const onChangeToDate = (date: moment.Moment | null, dateString: string) => {
    chart = null;
    dispatch(growthDeltaSlice.setGrowthDeltaData([]));

    const filters = { ...growDeltaFilters };
    filters.toDate = dateString;
    dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
    triggerGetGrowthDeltaData({ moduleId: module?._id, containerId, stockingId, fromDate, toDate: dateString });
  };

  const renderUnitsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_campuses_growth_delta'
        showSearch
        theme={theme}
        className={className}
        value={campus?.name ? campus?.name : undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectCampus')}
        placeholder={t('stockings.selectCampus')}
        onSelect={onChangeCampus}
        dropdownMatchSelectWidth={false}
      >
        {campuses.map((campus) => {
          return (
            <Option key={campus._id} value={campus._id} label={campus.name}>
              {campus.name}
              {
                campus.status === unitStatuses.INACTIVE
                &&
                <>
                  &nbsp; &nbsp;
                  <LrvTag color='#e04d00'>{t('campus.inactive')}</LrvTag>
                </>
              }
            </Option>
          );
        })}
      </LrvSelect>
    );
  };

  const renderModulesDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_modules_growth_delta'
        showSearch
        theme={theme}
        className={className}
        value={module?._id ? module?._id : undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectModule')}
        placeholder={t('stockings.selectModule')}
        onSelect={onChangeModule}
        disabled={!campus?._id}
        optionFilterProp='children'
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}

      >
        {modules.map((module) => <Option key={module._id} value={module._id}>{module.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderTankDropdown = (props: DropdownProps) => {
    const { className, theme } = props;
    const module = modules.find((module) => module._id === growDeltaFilters.module?._id);

    return (
      <LrvSelect
        id='dropdown_tanks_growth_delta'
        showSearch
        theme={theme}
        className={className}
        value={containerId}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t(`survivalRate.selectTank.${CONTAINER_LABEL[module?.phaseType || stockingPhaseTypes.LARVAE]}`)}
        placeholder={t(`survivalRate.selectTank.${CONTAINER_LABEL[module?.phaseType || stockingPhaseTypes.LARVAE]}`)}
        optionFilterProp='children'
        onSelect={onChangeTank}
        disabled={!module?._id}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
      >
        {containers.map((container) => <Option key={container._id} value={container._id}>{container.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderStockingsDropdown = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='dropdown_stockings_growth_delta'
        showSearch
        theme={theme}
        className={className}
        value={stockingId}
        suffixIcon={<Icon name='arrow-down-s' />}
        title={t('stockings.selectStocking')}
        placeholder={t('stockings.selectStocking')}
        optionFilterProp='children'
        onSelect={onChangeStocking}
        disabled={!containerId}
        dropdownMatchSelectWidth={false}
        filterOption={filterOptionSelect}
      >
        {stockings.map((stocking) => <Option key={stocking._id} value={stocking._id}>{stocking.name}</Option>)}
      </LrvSelect>
    );
  };

  const renderFromDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvTooltip placement='top' title={growDeltaFilters.stockingId ? t('stockings.growthDelta.dateInfo') : undefined}>
        <LrvDatePicker
          id='from_date_picker'
          theme={theme}
          className={className}
          value={moment(fromDate)}
          defaultValue={moment(fromDate)}
          allowClear={false}
          disabledDate={(value) => disabledFromDate(toDate, value)}
          disabled={!!stockingId}
          title={t('stockings.growthDelta.dateFrom')}
          placeholder={t('stockings.growthDelta.dateFrom')}
          onChange={onChangeFromDate}
        />
      </LrvTooltip>
    );
  };

  const renderToDatePicker = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvTooltip placement='top' title={growDeltaFilters.stockingId ? t('stockings.growthDelta.dateInfo') : undefined}>
        <LrvDatePicker
          id='to_date_picker'
          theme={theme}
          className={className}
          value={moment(toDate)}
          defaultValue={moment(toDate)}
          allowClear={false}
          disabledDate={(value) => disabledToDate(fromDate, value)}
          disabled={!!growDeltaFilters.stockingId}
          title={t('stockings.growthDelta.dateTo')}
          placeholder={t('stockings.growthDelta.dateTo')}
          onChange={onChangeToDate}
        />
      </LrvTooltip>
    );
  };

  const renderMovingAverage = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='moving_average'
        theme={theme}
        className={className}
        suffixIcon={<Icon name='arrow-down-s' />}
        value={showMovingAverage ? movingAverage : t('production.none')}
        showSearch
        onChange={(value) => {
          if (!value) {
            const filters = { ...growDeltaFilters };
            filters.showMovingAverage = false;
            dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
            return;
          }

          const filters = { ...growDeltaFilters };
          if (!showMovingAverage) {
            filters.showMovingAverage = true;
          }

          filters.movingAverage = value;
          dispatch(growthDeltaSlice.setGrowthDeltaFilters(filters));
        }}
        title={t('production.movingAverage')}
        dropdownMatchSelectWidth={false}
      >
        <Select.Option key={undefined} value={undefined}> {t('production.none')} </Select.Option>
        <Select.Option key={2} value={2}> {t('production.window.2')} </Select.Option>
        <Select.Option key={3} value={3}> {t('production.window.3')} </Select.Option>
        <Select.Option key={4} value={4}> {t('production.window.4')} </Select.Option>
      </LrvSelect>
    );
  };

  const renderSidePanel = () => {
    return (
      <div className={styles.sidePanel}>
        <LrvFilterPanel
          showFilterIcon={true}
          title={<div className={styles.title}>{t('stockings.growthDelta.title')}</div>}
          cleanButtonProps={{
            onClick: () => {
              dispatch(growthDeltaSlice.resetGrowthDeltaFilters({ company: selectedCompany, phaseType }));
            },
          }}
        >
          <Space
            direction='vertical'
            className={styles.bodyPanel}
          >
            {renderUnitsDropdown({ theme: 'light' })}
            {renderModulesDropdown({ theme: 'light' })}
            {renderTankDropdown({ theme: 'light' })}
            {renderStockingsDropdown({ theme: 'light' })}
            {renderFromDatePicker({ theme: 'light' })}
            {renderToDatePicker({ theme: 'light' })}
            {renderMovingAverage({ theme: 'light' })}
          </Space>
        </LrvFilterPanel>
      </div>
    );
  };

  const generatePdf = () => {
    if (growDeltaData.length === 0 || !growDeltaFilters.campus?._id) {
      return;
    }

    const params = {
      moduleId: growDeltaFilters.module?._id,
      campusId: growDeltaFilters.campus?._id,
      tankId: growDeltaFilters.containerId,
      stockingId: growDeltaFilters.stockingId,
      fromDate: growDeltaFilters.fromDate,
      toDate: growDeltaFilters.toDate,
      movingAverage: growDeltaFilters.showMovingAverage ? growDeltaFilters.movingAverage : -1,
      firstStage: firstStageZoom,
      lastStage: lastStageZoom,
    };

    dispatch(growthDeltaSlice.fetchUrlGrowthDeltaPdf(params));
  };

  const renderButtonGeneratePdf = () => {
    return (
      <IconButton
        id='btn_generate_pdf'
        iconName='download'
        loading={isDownloadingFile}
        onClick={generatePdf}
        disabled={growDeltaData.length === 0}
        tooltipText={t('stockings.growthDelta.download')}
        placement='left'
      />
    );
  };

  const renderCleanButton = () => {
    return (
      <CleanButton
        theme={theme}
        onClick={() => dispatch(growthDeltaSlice.resetGrowthDeltaFilters({ company: selectedCompany, phaseType }))}
      />
    );
  };

  const renderSubHeader = () => {
    return (
      <Row className={styles.header} ref={refFilters}>
        <Space className={styles.filters} align='end'>
          {renderUnitsDropdown({ className: styles.select, theme })}
          {renderModulesDropdown({ className: styles.select, theme })}
          {renderTankDropdown({ className: styles.select, theme })}
          {renderStockingsDropdown({ className: styles.select, theme })}
          {renderFromDatePicker({ className: styles.select, theme })}
          {renderToDatePicker({ className: styles.select, theme })}
          {renderMovingAverage({ className: styles.select, theme })}
          {renderCleanButton()}
        </Space>
        {renderSidePanel()}

        <Row className={styles.rowRight}>
          {renderButtonGeneratePdf()}
        </Row>
      </Row>
    );
  };

  const renderLegends = () => {
    if (!growDeltaData.length) {
      return null;
    }

    return (
      <div className={styles.legend}>
        <div className={styles.item}>
          <div className={cx(styles.colorBox, styles.pigmentation)} />
          <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('stockings.growthDelta.title')} />
        </div>

        <div className={styles.item}>
          <div className={cx(styles.colorBox, styles.movingAverage)} />
          <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('production.movingAverage')} />
        </div>
      </div>
    );
  };

  const renderYParameter = (props: { opacity?: number }) => {
    const { opacity = 1 } = props;
    return (
      <div className={styles.labelAxisY} style={{ opacity }}>
        <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={parameterLabel} />
      </div>
    );
  };

  return (
    <div className={cx(styles.containerFull, 'growthDelta')}>
      {renderSubHeader()}

      <Row className={isSelectedAnyFilter ? styles.row : cx(styles.row, styles.height)}>
        {
          isSelectedAnyFilter ?
            <Col className={cx(styles.col, styles.containerChart)}>
              <div className={styles.containerLegends} ref={refLegends}>
                {renderYParameter({})}
                {renderLegends()}
                {renderYParameter({ opacity: 0 })}
              </div>

              <div ref={refChart} className={styles.chart} />
              <div className={cx(styles.labelAxisX, isLightTheme ? styles.axisLight : styles.axisDark)}>
                <LrvText text={getAxisXLabel()} />
              </div>
            </Col> :
            <Col className={styles.col}>
              <div className={styles.graphInfo}>
                <p id='growth_delta_info'>
                  <LrvText text={t('stockings.growthDelta.graphInfo')} theme={theme} />
                </p>
              </div>
            </Col>
        }
      </Row>
    </div>
  );
};

export default GrowthDelta;
