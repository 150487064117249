import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { filterOptionSelect } from '../../utils/select';
import { getUserSession } from '../../utils/userSession';
import { DropdownProps } from '../../common/interfaces/commons';
import IconButton from '../../common/components/buttons/IconButton';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { getMainRole, harvestOptions, roles, stockingPhaseTypes } from '../../config/commons';

import styles from './Subheader.module.scss';
import * as harvestsSlice from './harvestsSlice';

const { Option } = Select;

export const Subheader = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { consolidateHarvests, currentHarvests, harvestsToShow } = useSelector((state: Store) => state.harvests);
  const { selectedHarvests } = consolidateHarvests;

  const userSession = getUserSession();
  const theme = getCurrentTheme();
  const higherRole = getMainRole();

  const renderConsolidateButton = () => {
    if (company._id !== userSession.companyId || phaseType !== stockingPhaseTypes.LARVAE) {
      return null;
    }

    return (
      <ActionButton
        id='consolidate_button'
        type='primary'
        className={styles.actionButton}
        disabled={!currentHarvests.length || selectedHarvests.length <= 1}
        onClick={() => {
          dispatch(harvestsSlice.setShowConsolidateHarvestsModal(true));
        }}
      >
        {t('harvests.consolidate')}
      </ActionButton>
    );
  };

  const renderHarvestsXAxisOption = () => {
    if (higherRole === roles.CLIENT_MANAGER || higherRole === roles.CLIENT_OPERATOR || phaseType !== stockingPhaseTypes.LARVAE) {
      return null;
    }

    return (
      <IconButton
        id='harvest_x_axis_button'
        className={styles.reportButton}
        icon={<Icon name='settings-4' theme={theme} />}
        onClick={() => {
          dispatch(harvestsSlice.setShowHarvestsXAxisConfigModal(true));
          dispatch(harvestsSlice.fetchHarvestsXAxisValues({ companyId: company._id }));
        }}
        placement='bottomLeft'
        tooltipText={t('harvests.harvestsXAxisConfig.tooltip')}
      />
    );
  };

  const renderHarvestOptions = (props: DropdownProps) => {
    const { className, theme } = props;

    return (
      <LrvSelect
        id='harvests_to_show'
        value={harvestsToShow}
        suffixIcon={<Icon name='arrow-down-s' />}
        showSearch
        theme={theme}
        className={className}
        title={t('harvests.title')}
        placeholder={t('harvests.title')}
        optionFilterProp='children'
        onChange={(value) => {
          dispatch(harvestsSlice.setHarvestsToShow(value));

          switch (value) {
            case harvestOptions.CURRENT:
              dispatch(harvestsSlice.fetchCurrentHarvests({ companyId: company._id, currentPage: 1 }));
              break;

            case harvestOptions.PREVIOUS:
              dispatch(harvestsSlice.fetchPreviousHarvests({ companyId: company._id, currentPage: 1 }));
              break;
          }
        }}
        filterOption={filterOptionSelect}
        dropdownMatchSelectWidth={false}
      >
        <Option key={harvestOptions.CURRENT} value={harvestOptions.CURRENT}>{t('harvests.filters.actives')}</Option>
        <Option key={harvestOptions.PREVIOUS} value={harvestOptions.PREVIOUS}>{t('harvests.filters.previous')}</Option>
      </LrvSelect>
    );
  };

  return (
    <div className={styles.container}>
      <Space>
        {renderHarvestOptions({ className: styles.select, theme })}
      </Space>
      <Space>
        {renderConsolidateButton()}
        {renderHarvestsXAxisOption()}
      </Space>
    </div>
  );
};
