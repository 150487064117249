export const USERS = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const getValueActiveUsers = (value: string) => {
  switch (value) {
    case USERS.ACTIVE:
    default:
      return true;

    case USERS.INACTIVE:
      return false;

    case USERS.ALL:
      return undefined;
  }
};
