import React from 'react';
import cx from 'classnames';
import { Tabs } from 'antd';

import { LrvTabsProps } from '../../interfaces/lrvTabs';

import styles from './LrvTabs.module.scss';

export const LrvTabs = (props: LrvTabsProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkTabs : styles.lightTabs, className),
  };

  return (
    <Tabs {...lrvProps}>
    </Tabs>
  );
};
