import { companiesTypes } from '../../config/commons';
import { getEndDate, getStartDate } from '../../utils/date';

export const dayOne = 1;
export const dayFifteen = 15;

export const CONSUMPTION_TYPE = {
  LOW: 'LOW',
  MODERATE: 'MODERATE',
  NORMAL: 'NORMAL',
};

const COLOR_LOW_CONSUMER = '#d46761';
const COLOR_MODERATE_CONSUMER = '#eac946';
const COLOR_NORMAL_CONSUMER = '#4d6c20';
export const COLOR_CURRENT_MONTH = '#dfe0e4';
export const colorsPoints = [COLOR_LOW_CONSUMER, COLOR_MODERATE_CONSUMER, COLOR_NORMAL_CONSUMER];

export const getBackgroundColor = (percentage: number) => {
  if (percentage < 50) {
    return COLOR_LOW_CONSUMER;
  }

  if (percentage < 90) {
    return COLOR_MODERATE_CONSUMER;
  }

  return COLOR_NORMAL_CONSUMER;
};

export const getDaysInTheLastThreeMonths = () => {
  const startDate = getStartDate({ monthsToSubtract: 2 });
  const endDate = getEndDate({ monthsToSubtract: 0 });

  const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

export const getDaysBeetwenTwoDates = (props: { startDate: Date; endDate: Date }) => {
  const { startDate, endDate } = props;

  const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const initialBalanceFiltersState = {
  sellerId: undefined,
  promoterId: undefined,
  companyStatus: companiesTypes.ACTIVE,
  companyType: companiesTypes.ALL,
  showInternationalCompanies: undefined,
  showActiveCompanies: true,
  showTrialPhaseCompanies: false,
};
