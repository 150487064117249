import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { groupBy } from 'lodash';

import { SurvivalRate } from '../../Sowings/interfaces';
import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './SurvivalD3.module.scss';

let weekActive: string;
const DEFAULT_RADIO_CIRCLE = 9;
const ACTIVE_RADIO_CIRCLE = 11;

const LEGENDS_HEIGHT = 31;
const MARGIN_BOTTOM = 20;
const PADDING_BOTTOM_MODAL = 20;

const EXCELLENT_PERCENTAGE = 75;
const VERY_GOOD_PERCENTAGE = 65;
const WELL_PERCENTAGE = 50;
const EXTRA_AXIS_Y = 5;
const MAX_WEEK = 53;

const MAX_ITEMS_AXIS_X = 40;
const MARGIN_SVG_PARENT = 60; //value of margin class (Survival.module.scss file)
const SPACE_BETWEEN_ITEM = 40;

export default class SurvivalD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'svg'));

  xScaleLinear: d3.ScaleLinear<number, number, never> = d3.scaleLinear();
  yScaleLinear: d3.ScaleLinear<number, number, never> = d3.scaleLinear();

  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  timeTransition = 300;
  xAxisBg: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  xAxis: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  axisY: d3.Selection<SVGGElement, unknown, null, undefined> = d3.select<SVGGElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  rectExcellent: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  rectVeryGood: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  rectWell: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));
  rectBad: d3.Selection<SVGRectElement, unknown, null, undefined> = d3.select<SVGRectElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'rect'));

  labelExcellent: d3.Selection<SVGTextElement, unknown, null, undefined> = d3.select<SVGTextElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));
  labelVeryGood: d3.Selection<SVGTextElement, unknown, null, undefined> = d3.select<SVGTextElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));
  labelWell: d3.Selection<SVGTextElement, unknown, null, undefined> = d3.select<SVGTextElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));
  labelBad: d3.Selection<SVGTextElement, unknown, null, undefined> = d3.select<SVGTextElement, unknown>(document.createElementNS('http://www.w3.org/2000/svg', 'text'));

  width = 0;
  height = 0;
  margin = { top: 20, right: 10, bottom: MARGIN_BOTTOM, left: 40 };

  maxWeek = 0;
  minWeek = 0;
  minY = 100;
  maxY = 0;

  survivalRateDataset: SurvivalRate[] = [];
  copySurvivalRateDataset: SurvivalRate[] = [];
  globalAvg = 0;
  companyAvg = 0;
  selectedTickStoke?: string;

  // eslint-disable-next-line
  constructor(
    container: HTMLDivElement | null,
    dataset: SurvivalRate[],
    width: number,
    height: number,
    globalAvg: number,
    companyAvg: number,
    selectedTickStoke: string,
  ) {
    this.container = container;
    this.survivalRateDataset = dataset;

    this.globalAvg = globalAvg;
    this.companyAvg = companyAvg;
    this.selectedTickStoke = selectedTickStoke;

    this.fillCopySurvivalRate(dataset);

    const maxY = dataset.reduce((a, b) => a.avgSurvivalRate > b.avgSurvivalRate ? a : b).avgSurvivalRate;
    const minY = dataset.reduce((a, b) => a.avgSurvivalRate < b.avgSurvivalRate ? a : b).avgSurvivalRate;
    this.maxY = this.getMaxY([maxY, globalAvg, companyAvg]);
    this.minY = this.getMinY([minY, globalAvg, companyAvg]);

    this.maxWeek = this.copySurvivalRateDataset.reduce((a, b) => a.virtualWeek > b.virtualWeek ? a : b).virtualWeek;
    this.minWeek = this.copySurvivalRateDataset.reduce((a, b) => a.virtualWeek < b.virtualWeek ? a : b).virtualWeek;

    const totalItems = this.maxWeek - this.minWeek + 1;
    const newWidth = totalItems > MAX_ITEMS_AXIS_X ? ((totalItems * SPACE_BETWEEN_ITEM) - this.margin.left - this.margin.right) : (width - this.margin.left - this.margin.right);

    this.width = newWidth - MARGIN_SVG_PARENT;
    this.height = height - this.margin.top - this.margin.bottom;

    this.buildChart();
  }

  getMinY (values: number[]) {
    const minValue = Math.min(...values);
    return minValue;
  }

  getMaxY (values: number[]) {
    const maxValue = Math.max(...values);
    return maxValue;
  }

  fillCopySurvivalRate (survivalRateDataset: SurvivalRate[]) {
    const datasetGroupByYear = groupBy(survivalRateDataset, 'year');

    const copySurvivalRate: SurvivalRate[] = [];
    let virtualWeek = -1;

    for (const key in datasetGroupByYear) {
      if (Object.prototype.hasOwnProperty.call(datasetGroupByYear, key)) {
        const dataset: SurvivalRate[] = datasetGroupByYear[key];

        const maxLength = dataset.length - 1;
        let initialWeek = dataset[0].week;
        const year = dataset[0].year;
        const minVirtualWeek = dataset[0].virtualWeek;
        const maxVirtualWeek = dataset[maxLength].virtualWeek;

        // eslint-disable-next-line
        if (virtualWeek === -1) {
          virtualWeek = minVirtualWeek;
        }

        // eslint-disable-next-line
        for (let initialVirtualWeek = minVirtualWeek; initialVirtualWeek <= maxVirtualWeek; initialVirtualWeek++) {
          initialWeek = initialWeek <= MAX_WEEK ? initialWeek : 1;

          const data: SurvivalRate = { week: initialWeek, year: year, avgSurvivalRate: 0.0, virtualWeek: virtualWeek, showYearLine: initialVirtualWeek === maxVirtualWeek };
          copySurvivalRate.push(data);
          initialWeek++;
          virtualWeek++;
        }
      }
    }

    for (let i = 0; i < survivalRateDataset.length; i++) {
      const originalDataset = survivalRateDataset[i];
      for (let j = 0; j < copySurvivalRate.length; j++) {
        const copyDataset = copySurvivalRate[j];

        // eslint-disable-next-line
        if (copyDataset.week === originalDataset.week) {
          survivalRateDataset[i].virtualWeek = copyDataset.virtualWeek;

          copySurvivalRate[j].avgSurvivalRate = originalDataset.avgSurvivalRate;
        }
      }
    }

    this.copySurvivalRateDataset = copySurvivalRate;
  }

  buildChart () {
    this.createMainSvgElement(this.width, this.height);
    this.buildAxisX();
    this.buildAxisY();
    this.createTooltip();
    this.renderLineSelected();
    this.createRects();
    this.createXAxisBg();
    this.drawXAxis();
    this.drawYAxis();
    this.createLines();
    this.renderTooltips();
  }

  createMainSvgElement (width: number, height: number) {
    const { container, margin } = this;
    d3.select(container).select('svg').remove();

    this.svg = d3.select(container)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('id', 'survivalRate')
      .append('g')
      .attr('transform', `translate( ${margin.left} , ${margin.top} )`);
  }

  buildAxisX () {
    const { maxWeek, minWeek, width } = this;

    this.xScaleLinear = d3.scaleLinear()
      .domain([minWeek, maxWeek])
      .range([0, width]);
  }

  buildAxisY () {
    const { height, minY, maxY } = this;
    const minDomain = minY - EXTRA_AXIS_Y < 0 ? minY : minY - EXTRA_AXIS_Y;
    const maxDomain = maxY + EXTRA_AXIS_Y > 100 ? maxY : maxY + EXTRA_AXIS_Y;

    this.yScaleLinear = d3
      .scaleLinear()
      .domain([minDomain, maxDomain])
      .range([height, 0]);
  }

  createTooltip () {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltip')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.select(weekActive).attr('r', ACTIVE_RADIO_CIRCLE);
      });
  }

  renderLineSelected () {
    const { container, svg, height, selectedTickStoke } = this;
    d3.select(container).select('#selectedTick').remove();

    svg.append('line')
      .attr('id', 'selectedTick')
      .attr('stroke', selectedTickStoke || '')
      .attr('stroke-width', 1)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  drawXAxis () {
    const { svg, height, maxWeek, minWeek, copySurvivalRateDataset } = this;
    const ticks = maxWeek - minWeek + 1;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisBottom(this.xScaleLinear)
      .tickFormat((x, i) => {
        const survivalRate = copySurvivalRateDataset[i];
        const week = survivalRate !== undefined && ticks !== 3 ? survivalRate.week ?? x : x;
        return `${week}`;
      })
      .tickSize(-height)
      .ticks(ticks)
      .tickPadding(10);

    this.xAxis = svg.append('g')
      .attr('class', cx(styles.axis, styles.x, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axis);

    this.hideItemsAxisX(ticks);
  }

  updateXAxis () {
    const { height, maxWeek, minWeek, timeTransition, copySurvivalRateDataset } = this;
    const ticks = maxWeek - minWeek + 1;

    const axis = d3.axisBottom(this.xScaleLinear)
      .tickFormat((x, i) => {
        const survivalRate = copySurvivalRateDataset[i];
        const week = survivalRate !== undefined && ticks !== 3 ? survivalRate.week ?? x : x;
        return `${week}`;
      })
      .tickSize(-height)
      .ticks(ticks)
      .tickPadding(10);

    this.xAxis
      .attr('fill', 'transparent')
      .attr('transform', `translate(0, ${this.height})`)
      .transition().duration(timeTransition).call(axis);

    this.hideItemsAxisX(ticks);
  }

  drawYAxis () {
    const { svg } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    const axis = d3.axisLeft(this.yScaleLinear)
      .tickSize(0)
      .tickPadding(10);

    this.axisY = svg.append('g')
      .attr('class', cx(styles.axis, styles.x, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);
  }

  hideItemsAxisX (ticks: number) {
    const { svg, width, height } = this;
    if (ticks === 2) {
      svg.append('rect')
        .attr('class', cx('rect', styles.hide))
        .attr('x', (width / 2) - 15)
        .attr('y', height)
        .attr('width', 30)
        .attr('height', 20);
    } else if (ticks === 3) {
      const halfWidth = width / 2;

      svg.append('rect')
        .attr('class', cx('rect', styles.hide))
        .attr('x', halfWidth - (halfWidth / 2) - 15)
        .attr('y', height)
        .attr('width', 30)
        .attr('height', 20);

      svg.append('rect')
        .attr('class', cx('rect', styles.hide))
        .attr('x', halfWidth + (halfWidth / 2) - 15)
        .attr('y', height)
        .attr('width', 30)
        .attr('height', 20);
    }
  }

  updateYAxis () {
    const { timeTransition } = this;

    const axis = d3.axisLeft(this.yScaleLinear)
      .tickSize(0)
      .tickPadding(10);

    this.axisY.transition().duration(timeTransition).call(axis);
  }

  createRects () {
    const { svg, width, height, minY, yScaleLinear } = this;
    const minDomain = minY - EXTRA_AXIS_Y < 0 ? minY : minY - EXTRA_AXIS_Y;
    const labelPaddingTop = 20;
    const labelPaddingLeft = 5;

    let heightExcellent = yScaleLinear(EXCELLENT_PERCENTAGE);
    let heightVeryGood = yScaleLinear(VERY_GOOD_PERCENTAGE);
    let heightWell = yScaleLinear(WELL_PERCENTAGE);
    const heightBad = yScaleLinear(minDomain);
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    heightExcellent = height < heightExcellent ? heightBad : heightExcellent < 0 ? 0 : heightExcellent;
    heightVeryGood = height < heightVeryGood ? heightBad : heightVeryGood < 0 ? 0 : heightVeryGood;
    heightWell = height < heightWell ? heightBad : heightWell < 0 ? 0 : heightWell;

    this.rectExcellent = svg.append('rect');
    this.rectVeryGood = svg.append('rect');
    this.rectWell = svg.append('rect');
    this.rectBad = svg.append('rect');

    this.labelExcellent = svg.append('text');
    this.labelVeryGood = svg.append('text');
    this.labelWell = svg.append('text');
    this.labelBad = svg.append('text');

    this.rectExcellent
      .attr('id', 'excellent')
      .attr('class', isLightTheme ? styles.excellentLight : styles.excellentDark)
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', heightExcellent);

    this.labelExcellent
      .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
      .attr('x', labelPaddingLeft)
      .attr('y', labelPaddingTop)
      .text(`${i18next.t('survivalRate.legends.excellent')}`);

    if (heightVeryGood - heightExcellent > 0) {
      this.rectVeryGood
        .attr('id', 'veryGood')
        .attr('class', isLightTheme ? styles.veryGoodLight : styles.veryGoodDark)
        .attr('x', 0)
        .attr('y', heightExcellent)
        .attr('width', width)
        .attr('height', heightVeryGood - heightExcellent);

      this.labelVeryGood
        .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
        .attr('x', labelPaddingLeft)
        .attr('y', heightExcellent + labelPaddingTop)
        .text(`${i18next.t('survivalRate.legends.veryGood')}`);
    }

    if (heightWell - heightVeryGood > 0) {
      this.rectWell
        .attr('id', 'good')
        .attr('class', isLightTheme ? styles.goodLight : styles.goodDark)
        .attr('x', 0)
        .attr('y', heightVeryGood)
        .attr('width', width)
        .attr('height', heightWell - heightVeryGood);

      this.labelWell
        .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
        .attr('x', labelPaddingLeft)
        .attr('y', heightVeryGood + labelPaddingTop)
        .text(`${i18next.t('survivalRate.legends.good')}`);
    }

    // Rect Bad
    if (heightBad - heightWell > 0) {
      this.rectBad
        .attr('id', 'bad')
        .attr('class', isLightTheme ? styles.badLight : styles.badDark)
        .attr('x', 0)
        .attr('y', heightWell)
        .attr('width', width)
        .attr('height', heightBad - heightWell);

      this.labelBad
        .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
        .attr('x', labelPaddingLeft)
        .attr('y', heightWell + labelPaddingTop)
        .text(`${i18next.t('survivalRate.legends.bad')}`);
    }
  }

  updateRects () {
    const { width, height, minY, yScaleLinear } = this;
    const minDomain = minY - EXTRA_AXIS_Y < 0 ? minY : minY - EXTRA_AXIS_Y;
    const labelPaddingTop = 20;

    let heightExcellent = yScaleLinear(EXCELLENT_PERCENTAGE);
    let heightVeryGood = yScaleLinear(VERY_GOOD_PERCENTAGE);
    let heightWell = yScaleLinear(WELL_PERCENTAGE);
    const heightBad = yScaleLinear(minDomain);
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    heightExcellent = height < heightExcellent ? heightBad : heightExcellent < 0 ? 0 : heightExcellent;
    heightVeryGood = height < heightVeryGood ? heightBad : heightVeryGood < 0 ? 0 : heightVeryGood;
    heightWell = height < heightWell ? heightBad : heightWell < 0 ? 0 : heightWell;

    this.rectExcellent
      .attr('class', isLightTheme ? styles.excellentLight : styles.excellentDark)
      .attr('width', width)
      .attr('height', heightExcellent);

    this.labelExcellent
      .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
      .attr('y', labelPaddingTop);

    this.rectVeryGood
      .attr('class', isLightTheme ? styles.veryGoodLight : styles.veryGoodDark)
      .attr('y', heightExcellent)
      .attr('width', width)
      .attr('height', heightVeryGood - heightExcellent);

    this.labelVeryGood
      .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
      .attr('y', heightExcellent + labelPaddingTop);

    this.rectWell
      .attr('class', isLightTheme ? styles.goodLight : styles.goodDark)
      .attr('y', heightVeryGood)
      .attr('width', width)
      .attr('height', heightWell - heightVeryGood);

    this.labelWell
      .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
      .attr('y', heightVeryGood + labelPaddingTop);

    // Rect Bad
    this.rectBad
      .attr('class', isLightTheme ? styles.badLight : styles.badDark)
      .attr('y', heightWell)
      .attr('width', width)
      .attr('height', heightBad - heightWell);

    this.labelBad
      .attr('class', cx('rectLabel', isLightTheme ? styles.labelLight : styles.labelDark))
      .attr('y', heightWell + labelPaddingTop);
  }

  createXAxisBg () {
    const { svg, width, height } = this;
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.xAxisBg = svg.append('rect')
      .attr('id', 'rectAxisX')
      .attr('class', isLightTheme ? styles.lightRectAxisX : styles.darkRectAxisX)
      .attr('x', 0)
      .attr('y', height)
      .attr('width', width)
      .attr('height', height + EXTRA_AXIS_Y);
  }

  updateXAxisBg () {
    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;

    this.xAxisBg
      .attr('class', isLightTheme ? styles.lightRectAxisX : styles.darkRectAxisX);
  }

  createLines () {
    const { svg, xScaleLinear, yScaleLinear, height, width, globalAvg, companyAvg, survivalRateDataset, copySurvivalRateDataset } = this;

    const years: number[] = [];
    const virtualWeeks: number[] = [];
    for (let index = 0; index < copySurvivalRateDataset.length; index++) {
      const element = copySurvivalRateDataset[index];
      const showYearLine = element.showYearLine;
      const year = element.year;
      const virtualWeek = element.virtualWeek;

      if (showYearLine && (years.length === 0 || !years.includes(year))) {
        years.push(year);
        virtualWeeks.push(virtualWeek);
      }
    }

    if (virtualWeeks.length > 1) {
      for (let index = 0; index < virtualWeeks.length; index++) {
        const year = years[index];
        const virtualWeek = virtualWeeks[index];

        svg.append('line')
          .attr('class', cx(styles.yearsLine, 'lines'))
          .attr('x1', xScaleLinear(virtualWeek))
          .attr('y1', 0)
          .attr('x2', xScaleLinear(virtualWeek))
          .attr('y2', height);

        svg.append('text')
          .attr('class', cx(styles.yearsText, 'yearsText'))
          .attr('x', -20)
          .attr('y', xScaleLinear(virtualWeek))
          .style('text-anchor', 'middle')
          .attr('transform', 'translate(-4,0) rotate(270)')
          .text(`${year}`);
      }
    }

    const line = d3.line<SurvivalRate>()
      .x(function (d: SurvivalRate) { return xScaleLinear(d.virtualWeek); })
      .y(function (d: SurvivalRate) { return yScaleLinear(d.avgSurvivalRate); })
      .curve(d3.curveLinear);

    const theme = getCurrentTheme();
    const isLightTheme = theme === THEME.LIGHT;
    svg.append('path')
      .datum(survivalRateDataset)
      .attr('class', cx(isLightTheme ? styles.survivalRateLineLight : styles.survivalRateLineDark, styles.survivalRateLine, 'lines'))
      .attr('d', line);

    svg.append('line')
      .attr('class', cx(styles.globalAvgLine, 'lines'))
      .attr('x1', 0)
      .attr('y1', yScaleLinear(globalAvg))
      .attr('x2', width)
      .attr('y2', yScaleLinear(globalAvg));

    svg.append('line')
      .attr('class', cx(styles.companyAvgLine, 'lines'))
      .attr('x1', 0)
      .attr('y1', yScaleLinear(companyAvg))
      .attr('x2', width)
      .attr('y2', yScaleLinear(companyAvg));

    svg.selectAll('.circle')
      .data(survivalRateDataset)
      .enter()
      .append('circle')
      .attr('id', (d: SurvivalRate) => {
        return `week${d.virtualWeek}`;
      })
      .attr('class', cx(isLightTheme ? styles.circleLight : styles.circleDark, 'circle'))
      .attr('stroke', isLightTheme ? '#ffffff' : '#131B55')
      .attr('stroke-width', 2)
      .attr('r', DEFAULT_RADIO_CIRCLE)
      .attr('cx', function (d: SurvivalRate) { return xScaleLinear(d.virtualWeek); })
      .attr('cy', function (d: SurvivalRate) { return yScaleLinear(d.avgSurvivalRate); });
  }

  renderTooltips () {
    const { svg, xScaleLinear, yScaleLinear, width, height, tooltip, survivalRateDataset } = this;

    const bisect = d3.bisector(function (dataset: SurvivalRate) {
      return dataset.virtualWeek;
    }).left;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    let previousVirtualWeek = -1;
    let previousWeek = -1;

    svg.append('rect')
      .attr('class', 'rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll('.circle').attr('r', DEFAULT_RADIO_CIRCLE);
      })
      .on('mousemove', function (event) {
        const x0 = xScaleLinear.invert((d3).pointer(event)[0]);
        const index = bisect(survivalRateDataset, x0, 1);

        const previousObject = survivalRateDataset[index - 1];
        const currentObject = survivalRateDataset[index];
        let selectedObject: SurvivalRate;

        if (currentObject) {
          selectedObject = x0 - previousObject.virtualWeek > currentObject.virtualWeek - x0 ? currentObject : previousObject;
        } else {
          selectedObject = previousObject;
        }

        if (!selectedObject) {
          return;
        }

        tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');

        d3.selectAll('.circle').attr('r', DEFAULT_RADIO_CIRCLE);
        d3.select(weekActive).attr('r', ACTIVE_RADIO_CIRCLE);

        if (previousVirtualWeek === selectedObject.virtualWeek) {
          return;
        }

        previousVirtualWeek = selectedObject.virtualWeek;
        previousWeek = selectedObject.week;
        let survivalRate = selectedObject.avgSurvivalRate;

        const container = document.getElementById('survival_rate_chart');
        const width = container?.clientWidth || 0;
        const scrollLeft = container?.scrollLeft || 0;
        const pointX = xScaleLinear(previousVirtualWeek);
        const marginLeft = pointX - scrollLeft + 20;

        const scaleY = yScaleLinear(survivalRate);
        const marginBottom = height - scaleY + (LEGENDS_HEIGHT + MARGIN_BOTTOM + PADDING_BOTTOM_MODAL + ACTIVE_RADIO_CIRCLE);
        weekActive = `#week${previousVirtualWeek}`;

        const tooltipDialogWidth = 140;
        const bubbleWidth = 16;
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }

        tooltip
          .style('left', () => {
            if (marginLeft + tooltipTotalWidth < width) {
              const tooltipXCoordinate = marginLeft + (tooltipDialogWidth / 2) + bubbleWidth;
              return `${tooltipXCoordinate}px`;
            }
            const tooltipXCoordinate = marginLeft - (tooltipDialogWidth / 2) - bubbleWidth;
            return `${tooltipXCoordinate}px`;

          })
          .style('bottom', `${marginBottom}px`);

        d3.select('#selectedTick')
          .attr('x1', pointX)
          .attr('x2', pointX);

        tooltipContent.selectAll('*').remove();

        const entry = tooltipContent
          .append('div')
          .attr('class', styles.entry);

        const entryContent = entry.append('div')
          .attr('class', styles.entryContent);

        entryContent.append('div')
          .attr('class', styles.stat)
          .html(`${i18next.t('survivalRate.week')}: <strong>${previousWeek}</strong>`);

        survivalRate = Math.round(survivalRate * 100) / 100;

        entryContent.append('div')
          .attr('class', cx(styles.stat, 'survivalRate'))
          .html(`${i18next.t('survivalRate.percent')}: <strong>${survivalRate} %</strong>`);
      });
  }

  resize (width: number, height: number) {
    const { container, maxWeek, minWeek } = this;

    const totalItems = maxWeek - minWeek + 1;
    const newWidth = totalItems > MAX_ITEMS_AXIS_X ? ((totalItems * SPACE_BETWEEN_ITEM) - this.margin.left - this.margin.right) : (width - this.margin.left - this.margin.right);

    this.width = newWidth - MARGIN_SVG_PARENT;
    this.height = height - this.margin.top - this.margin.bottom;

    d3.select(container).select('#survivalRate').selectAll('*').remove();
    d3.select(container).select('#tooltip').remove();
    d3.select(container).select('#tooltipContent').remove();

    this.buildChart();
  }

  refreshChart (dataset: SurvivalRate[], width: number, height: number, globalAvg: number, companyAvg: number, selectedTickStoke: string) {
    const { tooltip, container } = this;

    this.survivalRateDataset = dataset;
    this.globalAvg = globalAvg;
    this.companyAvg = companyAvg;
    this.selectedTickStoke = selectedTickStoke;

    this.fillCopySurvivalRate(dataset);

    const maxY = this.survivalRateDataset.reduce((a, b) => a.avgSurvivalRate > b.avgSurvivalRate ? a : b).avgSurvivalRate;
    const minY = this.survivalRateDataset.reduce((a, b) => a.avgSurvivalRate < b.avgSurvivalRate ? a : b).avgSurvivalRate;
    this.maxY = this.getMaxY([maxY, globalAvg, companyAvg]);
    this.minY = this.getMinY([minY, globalAvg, companyAvg]);

    this.maxWeek = this.copySurvivalRateDataset.reduce((a, b) => a.virtualWeek > b.virtualWeek ? a : b).virtualWeek;
    this.minWeek = this.copySurvivalRateDataset.reduce((a, b) => a.virtualWeek < b.virtualWeek ? a : b).virtualWeek;

    d3.select(container).selectAll('.rect').remove();
    d3.select(container).select('#tooltipContent').remove();
    d3.select(container).selectAll('.circle').remove();
    d3.select(container).selectAll('.lines').remove();
    d3.select(container).selectAll('.yearsText').remove();

    tooltip.style('display', 'none');

    this.updateSize(width, height);

    this.buildAxisX();
    this.buildAxisY();
    this.renderLineSelected();

    this.updateRects();
    this.updateXAxisBg();

    this.updateXAxis();
    this.updateYAxis();

    this.createLines();
    this.renderTooltips();
  }

  updateSize = (width: number, height: number) => {
    const { maxWeek, minWeek } = this;

    const totalItems = maxWeek - minWeek + 1;
    const newWidth = totalItems > MAX_ITEMS_AXIS_X ? ((totalItems * SPACE_BETWEEN_ITEM) - this.margin.left - this.margin.right) : (width - this.margin.left - this.margin.right);

    this.width = newWidth - MARGIN_SVG_PARENT;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select('#survivalRate').attr('width', _width)
      .attr('height', _height);
  };
}
