import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LrvModal } from '../LrvModal/LrvModal';
import { LrvSelect } from '../LrvSelect/LrvSelect';
import { Store } from '../../../state/store.interfaces';
import { getUserSession } from '../../../utils/userSession';
import { stockingPhaseTypes, stockingsShow } from '../../../config/commons';
import { saveDefaultPhase } from '../../../pages/auth/authSlice';
import { setPhaseType } from '../../../pages/AppHeader/headerSlice';
import { resetStockingFilters } from '../../../pages/Sowings/sowingsSlice';

import styles from './LrvDefaultPhaseModal.module.scss';

export const LrvDefaultPhaseModal = () => {
  const theme = 'light';
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state: Store) => state.auth);
  const { user: onboardingUserData } = useSelector((state: Store) => state.onboarding);

  const userSession = getUserSession();

  const existPhase = !!userSession?.defaultPhase;
  const accessToken = localStorage.getItem('accessToken');

  const [isOpen, setIsOpen] = useState(false);

  const [phaseSelected, setPhaseSelected] = useState(stockingPhaseTypes.LARVAE);

  useEffect(() => {
    setIsOpen(!!onboardingUserData.onboarding?.initConfig && !existPhase && !!accessToken);
  }, [onboardingUserData.onboarding?.initConfig, existPhase, accessToken]);

  const onClose = () => setIsOpen(false);

  const onSave = () => {
    const params = {
      userId: userSession._id,
      companyId: userSession.companyId,
      defaultPhase: phaseSelected,
      onSuccess: onClose,
    };

    dispatch(setPhaseType(phaseSelected));
    dispatch(resetStockingFilters({ page: 1, phaseType: phaseSelected, stockingsToShow: stockingsShow.ACTIVE, companyId: userSession.companyId }));
    dispatch(saveDefaultPhase(params));
  };

  return (
    <LrvModal
      className={styles.modal}
      theme={theme}
      open={isOpen}
      title={t('defaultPhaseModal.title')}
      destroyOnClose
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okText={t('defaultPhaseModal.save')}
      width={450}
      okButtonProps={{ loading: isLoading }}
      onOk={onSave}
    >
      <div className={styles.description}>
        <span>{t('defaultPhaseModal.description')}</span>
      </div>
      <LrvSelect
        theme={theme}
        className={styles.select}
        value={phaseSelected}
        options={[
          { value: stockingPhaseTypes.LARVAE, label: t('header.phaseTypes.larvae') },
          { value: stockingPhaseTypes.JUVENILE, label: t('header.phaseTypes.juveniles') },
          { value: stockingPhaseTypes.ADULT, label: t('header.phaseTypes.growOut') },
        ]}
        onChange={(phase) => setPhaseSelected(phase)}
      />
    </LrvModal>
  );
};
