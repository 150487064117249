import { useTranslation } from 'react-i18next';
import { TooltipPlacement } from 'antd/es/tooltip';

import { getCurrentTheme } from '../../../helpers/theme';

import IconButton from './IconButton';

interface Props {
  className?: string;
  theme?: 'dark' | 'light';
  onClick: React.MouseEventHandler<HTMLElement>;
  placement?: TooltipPlacement;
  disabled?: boolean;
}

export default function CleanButton (props: Props) {
  const { onClick, theme = getCurrentTheme(), placement = 'top' } = props;
  const [t] = useTranslation();

  const lrvProps = {
    ...props,
    theme,
    placement,
    onClick,
  };

  return (
    <IconButton
      {...lrvProps}
      iconName='eraser'
      tooltipText={t('cleanFilters.title')}
    />
  );
}
