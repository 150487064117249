import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MYSQL_CREDENTIALS_URL } from '../../config/config.api';
import { getUserSession } from '../../utils/userSession';
import { UserSession } from '../../common/interfaces/auth';
import { axiosClient as axios } from '../../utils/axios_instance';
import { openSuccessNotification } from '../../common/notification/Notification';

import { ScheduleRule, ScheduleRuleState } from './interfaces';

const initialState: ScheduleRuleState = {
  _id: '',
  companyId: '',
  hours: [],
  isLoading: false,
};

export const scheduleRuleSlice = createSlice({
  name: 'scheduleRule',
  initialState,
  reducers: {
    setCredential: (state: ScheduleRuleState, action: PayloadAction<ScheduleRule>) => {
      state._id = action.payload._id;
      state.companyId = action.payload.companyId;
      state.hours = action.payload.hours;
    },
    setIsLoading: (state: ScheduleRuleState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setCredential,
  setIsLoading,
} = scheduleRuleSlice.actions;

export const fetchCredential = () => async (dispatch: Function) => {
  dispatch(setIsLoading(true));

  const userSession: UserSession = getUserSession();
  const params = {
    $limit: -1,
    companyId: userSession.companyId,
    active: true,
    $select: ['companyId', 'hours'],
  };

  try {
    const response = await axios.get<ScheduleRule[]>(MYSQL_CREDENTIALS_URL, { params });
    if (response.data && response.data[0]) {
      dispatch(setCredential(response.data[0]));
    }
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const updateCredential = (props: { hours: string[]; id: string }) => async (dispatch: Function) => {
  const { hours, id } = props;

  const url = `${MYSQL_CREDENTIALS_URL}/${id}`;
  const body = { hours };

  try {
    const response = await axios.patch<ScheduleRule>(url, body);
    dispatch(setCredential(response.data));
    openSuccessNotification(i18next.t('schedule.success'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default scheduleRuleSlice.reducer;
