import i18next from 'i18next';
import { AxiosResponse, AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MATURATIONS_URL } from '../../../../config/config.api';
import { typeErrorMaturation } from '../../../../config/commons';
import { axiosClient as axios } from '../../../../utils/axios_instance';
import { CompanyMaturationsState, Maturation } from '../../../Clients/interfaces';
import { openSuccessNotification, openErrorNotification } from '../../../../common/notification/Notification';
import { CreateMaturationFromCatalogProps, CreateMaturationProps, UpdateMaturationBatchProps, UpdateMaturationProps } from '../interfaces';

const initialState: CompanyMaturationsState = {
  maturations: [],
  companyMaturations: [],
  isCreating: false,
  isLoading: false,
  showNewMaturationModal: false,
  showEditMaturationModal: false,
  showEditInactiveMaturationCodesModal: false,
};

export const companyMaturationsSlice = createSlice({
  name: 'companyMaturations',
  initialState,
  reducers: {
    setCompanyMaturations: (state: CompanyMaturationsState, action: PayloadAction<Maturation[]>) => {
      state.companyMaturations = action.payload;
    },
    setIsCreating: (state: CompanyMaturationsState, action: PayloadAction<boolean>) => {
      state.isCreating = action.payload;
    },
    setIsLoading: (state: CompanyMaturationsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowNewMaturationModal: (state: CompanyMaturationsState, action: PayloadAction<boolean>) => {
      state.showNewMaturationModal = action.payload;
    },
    setShowEditMaturationModal: (state: CompanyMaturationsState, action: PayloadAction<boolean>) => {
      state.showEditMaturationModal = action.payload;
    },
    setShowEditInactiveMaturationCodesModal: (state: CompanyMaturationsState, action: PayloadAction<boolean>) => {
      state.showEditInactiveMaturationCodesModal = action.payload;
    },
    setMaturations: (state: CompanyMaturationsState, action: PayloadAction<Maturation[]>) => {
      state.maturations = action.payload;
    },
    setSelectedMaturation: (state: CompanyMaturationsState, action: PayloadAction<Maturation | undefined>) => {
      state.selectedMaturation = action.payload;
    },
  },
});

export const {
  setCompanyMaturations,
  setIsCreating,
  setIsLoading,
  setShowNewMaturationModal,
  setShowEditMaturationModal,
  setShowEditInactiveMaturationCodesModal,
  setMaturations,
  setSelectedMaturation,
} = companyMaturationsSlice.actions;

const sortMaturationCodesByStatus = (companyMaturations: Maturation[]) => {
  const maturations: Maturation[] = [];

  companyMaturations.forEach((maturation: Maturation) => {
    if (!maturation.codes) {
      maturations.push(maturation);
      return;
    }
    maturation.codes.sort((a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1));
    maturations.push(maturation);
  });
  return maturations;
};

export const fetchCompanyMaturations = (companyId: string, accessToken?: string) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  dispatch(setCompanyMaturations([]));

  const params = {
    $limit: -1,
    companyId: companyId,
    '$sort[active]': -1,
  };

  let response: AxiosResponse<Maturation[]>;

  try {
    if (accessToken) {
      response = await axios.get<Maturation[]>(MATURATIONS_URL,
        {
          params,
          headers: { Authorization: accessToken },
        });
    } else {
      response = await axios.get<Maturation[]>(MATURATIONS_URL, { params });
    }

    const maturations = sortMaturationCodesByStatus(response.data);
    dispatch(setCompanyMaturations(maturations));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createMaturations = (params: {
  maturations: CreateMaturationProps[];
  maturationsFromCatalog: CreateMaturationFromCatalogProps[];
  companyId: string;
  onSuccess: () => void;
}) => async (dispatch: Function) => {
  const { maturations, maturationsFromCatalog, companyId, onSuccess } = params;

  dispatch(setIsCreating(true));

  try {
    await createCompanyMaturations({ maturations: maturationsFromCatalog, url: `${MATURATIONS_URL}/from-base-maturation/create-many` });
    await createCompanyMaturations({ maturations, url: `${MATURATIONS_URL}/default/create-many` });

    openSuccessNotification(i18next.t('maturations.created'));
    dispatch(fetchCompanyMaturations(companyId));
    onSuccess();
  } catch (e) {
    console.log(e?.response);
    showMessageError(e);
  }

  dispatch(setIsCreating(false));
};

const createCompanyMaturations = async (params: { maturations: CreateMaturationProps[] | CreateMaturationFromCatalogProps[]; url: string }) => {
  const { maturations, url } = params;

  if (maturations.length === 0) {
    return;
  }

  const data = { maturations };
  await axios.post(url, data);
};

export const updateBatchMaturations = (params: {
  maturations: UpdateMaturationBatchProps[];
  companyId: string;
}) => async (dispatch: Function) => {
  const { maturations, companyId } = params;

  try {
    await axios.patch(`${MATURATIONS_URL}/default/update-many`, { maturations });

    openSuccessNotification(i18next.t('maturations.updated'));
    dispatch(fetchCompanyMaturations(companyId));
  } catch (e) {
    showMessageError(e);
  }
};

export const updateMaturation = (params: { maturation: UpdateMaturationProps; onSuccess: () => void }) => async (dispatch: Function) => {
  const { maturation, onSuccess } = params;

  const url = `${MATURATIONS_URL}/${maturation._id}`;

  try {
    await axios.patch(url, maturation);
    openSuccessNotification(i18next.t('maturations.updated'));
    dispatch(fetchCompanyMaturations(maturation.companyId));

    onSuccess();
  } catch (e) {
    showMessageError(e);
  }
};

const showMessageError = (e: AxiosError) => {
  const error = e?.response?.data?.data?.error;
  const errorCode = e?.response?.data?.code;

  switch (error) {
    case typeErrorMaturation.MATURATION_NAME:
      openErrorNotification(i18next.t('maturations.error.maturationName'));
      break;

    case typeErrorMaturation.VALIDATION_ERROR:
      openErrorNotification(i18next.t('maturations.error.requiredFields'));
      break;

    case typeErrorMaturation.DUPLICATE_MATURATION_CODES:
      openErrorNotification(i18next.t('maturations.error.duplicateMaturationCodes'));
      break;

    default:
      if ((!error && errorCode === 400)) {
        openErrorNotification(i18next.t('maturations.error.requiredFields'));
      }
      break;
  }
};

export default companyMaturationsSlice.reducer;