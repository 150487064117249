import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getMonthName } from '../../utils/date';
import { downloadXlsxFile } from '../../utils/download';
import { axiosClient as axios } from '../../utils/axios_instance';
import { paginationBalancesRequest, plansTypes } from '../../config/commons';
import { openErrorNotification, openSuccessNotification } from '../../common/notification/Notification';
import { BALANCE_USAGE_URL, PREPAID_BALANCE_USAGE_URL, SEARCH_PROMOTERS_URL, SEARCH_SELLER_URL, XLSX_PAYMENTS_REPORT_URL } from '../../config/config.api';

import { initialBalanceFiltersState } from './helpers';
import { BalanceConsumptionPayload, BalanceConsumptionState, User } from './interfaces';

type MonthToDownload = 'beforeLast' | 'last';
type Payment = 'prepaid' | 'postpaid';

const initialState: BalanceConsumptionState = {
  sellers: [],
  promoters: [],
  filters: initialBalanceFiltersState,
  postpaid: {
    currentPage: 1,
    total: 0,
    skip: 0,
    limit: 0,
    data: [],
    isLoading: false,
    months: {
      currentMonth: {
        month: 0,
        year: 0,
      },
      lastMonth: {
        month: 0,
        year: 0,
      },
      monthBeforeLast: {
        month: 0,
        year: 0,
      },
    },
    isDownloadingLastMonth: false,
    isDownloadingBeforeLastMonth: false,
  },
  prepaid: {
    currentPage: 1,
    total: 0,
    skip: 0,
    limit: 0,
    data: [],
    isLoading: false,
    months: {
      currentMonth: {
        month: 0,
        year: 0,
      },
      lastMonth: {
        month: 0,
        year: 0,
      },
      monthBeforeLast: {
        month: 0,
        year: 0,
      },
    },
    isDownloadingLastMonth: false,
    isDownloadingBeforeLastMonth: false,
  },
  tabSelected: plansTypes.POSTPAID,
};

export const balanceConsumptionSlice = createSlice({
  name: 'balanceConsumption',
  initialState,
  reducers: {
    setPostpaidBalanceUsage: (state: BalanceConsumptionState, action: PayloadAction<BalanceConsumptionPayload>) => {
      state.postpaid.total = action.payload.total;
      state.postpaid.skip = action.payload.skip;
      state.postpaid.limit = action.payload.limit;
      state.postpaid.data = action.payload.data;
      state.postpaid.months = action.payload.months;
    },
    setPrepaidBalanceUsage: (state: BalanceConsumptionState, action: PayloadAction<BalanceConsumptionPayload>) => {
      state.prepaid.total = action.payload.total;
      state.prepaid.skip = action.payload.skip;
      state.prepaid.limit = action.payload.limit;
      state.prepaid.data = action.payload.data;
      state.prepaid.months = action.payload.months;
    },
    resetBalanceUsage: (state: BalanceConsumptionState, action: PayloadAction<BalanceConsumptionState>) => {
      state.filters.companyType = action.payload.filters.companyType;
      state.filters.companyStatus = action.payload.filters.companyStatus;
      state.filters.showActiveCompanies = action.payload.filters.showActiveCompanies;
      state.filters.showTrialPhaseCompanies = action.payload.filters.showTrialPhaseCompanies;
      state.filters.showInternationalCompanies = action.payload.filters.showInternationalCompanies;
      state.filters.sellerId = action.payload.filters.sellerId;
      state.filters.promoterId = action.payload.filters.promoterId;

      state.postpaid.total = action.payload.postpaid.total;
      state.postpaid.skip = action.payload.postpaid.skip;
      state.postpaid.limit = action.payload.postpaid.limit;
      state.postpaid.data = action.payload.postpaid.data;
      state.postpaid.months = action.payload.postpaid.months;
      state.postpaid.isLoading = action.payload.postpaid.isLoading;

      state.prepaid.total = action.payload.prepaid.total;
      state.prepaid.skip = action.payload.prepaid.skip;
      state.prepaid.limit = action.payload.prepaid.limit;
      state.prepaid.data = action.payload.prepaid.data;
      state.prepaid.months = action.payload.prepaid.months;
      state.prepaid.isLoading = action.payload.prepaid.isLoading;
    },
    setIsPostpaidLoading: (state: BalanceConsumptionState, action: PayloadAction<boolean>) => {
      state.postpaid.isLoading = action.payload;
    },
    setIsPrepaidLoading: (state: BalanceConsumptionState, action: PayloadAction<boolean>) => {
      state.prepaid.isLoading = action.payload;
    },

    setIsDownloadingPayments: (state: BalanceConsumptionState, action: PayloadAction<{ isDownloading: boolean, monthToDownload: MonthToDownload, payment: Payment }>) => {
      const { payment, monthToDownload, isDownloading } = action.payload;
      if (payment === 'postpaid') {
        if (monthToDownload === 'last') {
          state.postpaid.isDownloadingLastMonth = isDownloading;
        } else {
          state.postpaid.isDownloadingBeforeLastMonth = isDownloading;
        }
        return ;
      }

      if (monthToDownload === 'last') {
        state.prepaid.isDownloadingLastMonth = isDownloading;
      } else {
        state.prepaid.isDownloadingBeforeLastMonth = isDownloading;
      }

    },

    setPostpaidCurrentPage: (state: BalanceConsumptionState, action: PayloadAction<number>) => {
      state.postpaid.currentPage = action.payload;
    },
    setPrepaidCurrentPage: (state: BalanceConsumptionState, action: PayloadAction<number>) => {
      state.prepaid.currentPage = action.payload;
    },

    setSellers: (state: BalanceConsumptionState, action: PayloadAction<User[]>) => {
      state.sellers = action.payload;
    },
    setPromoters: (state: BalanceConsumptionState, action: PayloadAction<User[]>) => {
      state.promoters = action.payload;
    },
    setSellerId: (state: BalanceConsumptionState, action: PayloadAction<string | undefined>) => {
      state.filters.sellerId = action.payload;
    },
    setPromoterId: (state: BalanceConsumptionState, action: PayloadAction<string | undefined>) => {
      state.filters.promoterId = action.payload;
    },
    setCompanyType: (state: BalanceConsumptionState, action: PayloadAction<string>) => {
      state.filters.companyType = action.payload;
    },
    setCompanyStatus: (state: BalanceConsumptionState, action: PayloadAction<string>) => {
      state.filters.companyStatus = action.payload;
    },
    setShowInternationalCompanies: (state: BalanceConsumptionState, action: PayloadAction<boolean | undefined>) => {
      state.filters.showInternationalCompanies = action.payload;
    },
    setShowActiveCompanies: (state: BalanceConsumptionState, action: PayloadAction<boolean>) => {
      state.filters.showActiveCompanies = action.payload;
    },
    setShowTrialPhaseCompanies: (state: BalanceConsumptionState, action: PayloadAction<boolean>) => {
      state.filters.showTrialPhaseCompanies = action.payload;
    },
    resetBalanceConsumptionFilters: (state: BalanceConsumptionState) => {
      state.filters = initialBalanceFiltersState;
    },

    setTabSelected: (state: BalanceConsumptionState, action: PayloadAction<string>) => {
      state.tabSelected = action.payload;
    },
  },
});

export const {
  setPostpaidBalanceUsage, setPrepaidBalanceUsage, resetBalanceUsage,
  setIsPostpaidLoading, setIsPrepaidLoading,
  setPostpaidCurrentPage, setPrepaidCurrentPage,
  setTabSelected,

  setSellers, setPromoters,
  setSellerId, setPromoterId, setCompanyType, setCompanyStatus,
  setShowInternationalCompanies, setShowActiveCompanies, setShowTrialPhaseCompanies,
  resetBalanceConsumptionFilters, setIsDownloadingPayments,
} = balanceConsumptionSlice.actions;

export const fetchPostpaidBalanceUsage = (params: { page: number; sellerId?: string; promoterId?: string; active?: boolean; isInternational?: boolean; showTrialPhase?: boolean }) => async (dispatch: Function) => {
  const { page, sellerId, promoterId, active, isInternational, showTrialPhase } = params;

  let skip = 0;
  if (page !== 0) {
    skip = paginationBalancesRequest * (page - 1);
  }

  dispatch(setIsPostpaidLoading(true));

  try {
    const params = {
      $skip: skip,
      sellerId,
      promoterId,
      active,
      isInternational,
      showTrialPhase,
    };

    const response = await axios.get<BalanceConsumptionPayload>(BALANCE_USAGE_URL, { params });
    dispatch(setPostpaidBalanceUsage(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  dispatch(setIsPostpaidLoading(false));
};

export const resetBalanceConsumption = () => (dispatch: Function) => {
  dispatch(resetBalanceUsage(initialState));
};

export const fetchSellers = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<User[]>(SEARCH_SELLER_URL);
    dispatch(setSellers(response.data));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export const fetchPromoters = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<User[]>(SEARCH_PROMOTERS_URL);
    dispatch(setPromoters(response.data));
  } catch (e) {
    console.log('ERROR', e?.response);
  }
};

export const fetchXlsxPaymentsReport = (data: { month: number; year: number, monthToDownload: MonthToDownload, payment: Payment }) => async (dispatch: Function) => {
  const { month, year, monthToDownload, payment } = data;
  dispatch(setIsDownloadingPayments({ isDownloading: true, monthToDownload, payment }));
  openSuccessNotification(i18next.t('balances.downloading'));
  const monthName = getMonthName(month.toString());

  const config = { params: { month, year } };

  try {
    const response = await axios.get(XLSX_PAYMENTS_REPORT_URL, config);
    const xlsxData = response.data;

    const fileName = `${i18next.t('balances.sales').toUpperCase()}_${monthName.toUpperCase()}_${year}${xlsxData.extension}`;
    downloadXlsxFile({ xlsxData, fileName });
  } catch (error) {
    console.log(error);
    openErrorNotification(i18next.t('download.error'));
  }
  dispatch(setIsDownloadingPayments({ isDownloading: false, monthToDownload, payment }));
};

export const fetchPrepaidBalancePayments = (params: { page: number; sellerId?: string; promoterId?: string; active?: boolean; isInternational?: boolean; showTrialPhase?: boolean }) => async (dispatch: Function) => {
  const { page, sellerId, promoterId, active, isInternational, showTrialPhase } = params;

  let skip = 0;
  if (page !== 0) {
    skip = paginationBalancesRequest * (page - 1);
  }

  dispatch(setIsPrepaidLoading(true));

  try {
    const params = {
      $skip: skip,
      sellerId,
      promoterId,
      active,
      isInternational,
      showTrialPhase,
    };

    const response = await axios.get<BalanceConsumptionPayload>(PREPAID_BALANCE_USAGE_URL, { params });
    dispatch(setPrepaidBalanceUsage(response.data));
  } catch (e) {
    console.log('ERROR', e);
    return;
  }

  dispatch(setIsPrepaidLoading(false));
};

export default balanceConsumptionSlice.reducer;
