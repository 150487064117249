import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Data from '../../Pdfs/Data';
import Footer from '../../Pdfs/Footer';
import Header from '../../Pdfs/Header';
import { Store } from '../../../state/store.interfaces';
import { unitPhaseTypes } from '../../../config/commons';
import * as headerSlice from '../../AppHeader/headerSlice';
import Content from '../../../common/components/Content/Content';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { calcDensity, getParameter, getUnitDensity } from '../../../helpers/stocking.helpers';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import GrowthDeltaChart from '../../../common/components/charts/ShadedPlot/GrowthDeltaChart';
import { typesChart, typeParam, sortDeltaData, typeScale, lineColor } from '../../../common/components/charts/ShadedPlot/helpers';

import './GrowthDeltaPdf.scss';
import styles from './GrowthDeltaPdf.module.scss';
import { GrowthDeltaStocking } from './interfaces';
import { fetchGrowthDeltaData, fetchCampus, fetchStocking, fetchModule } from './growthDeltaSlice';

let chart: D3ShadedPlot | GrowthDeltaChart | null;

type TParams = {
  companyId: string; campusId: string; moduleId: string;
  tankId?: string; language: string;
  accessToken: string; stockingId?: string;
  fromDate: string; toDate: string;
  firstStage: string; lastStage: string;
  movingAverage: string;
};

export default function GrowthDeltaPdf ({ match }: RouteComponentProps<TParams>) {
  const {
    companyId, campusId, moduleId, tankId,
    language, accessToken, stockingId,
    fromDate, toDate, firstStage, lastStage,
    movingAverage,
  } = match.params;

  const showMovingAverage = movingAverage !== '-1';

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const refChartMain = useRef<HTMLDivElement>(null);

  const selectedCampus = useSelector((state: Store) => state.growthDeltaPdf.selectedCampus);
  const selectedModule = useSelector((state: Store) => state.growthDeltaPdf.selectedModule);
  const growDeltaData = useSelector((state: Store) => state.growthDeltaPdf.data);
  const selectedStocking = useSelector((state: Store) => state.growthDeltaPdf.selectedStocking);
  const isLoadingCampus = useSelector((state: Store) => state.growthDeltaPdf.isLoadingCampus);
  const isLoadingStocking = useSelector((state: Store) => state.growthDeltaPdf.isLoadingStocking);
  const { company: companyData } = useSelector((state: Store) => state.header);

  const selectedPhaseType = selectedModule.phaseType;
  const labelAxisXHeight = 56;
  const width = 1680 - 96;
  const height = 950 - labelAxisXHeight;
  const backgroundColor = 'white';

  const parameterLabel = getParameter({ parameter: typeParam.GROWTH_DELTA, stockingPhaseType: selectedPhaseType });

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    dispatch(fetchCampus(campusId, accessToken));
    dispatch(fetchModule(moduleId, accessToken));
  }, [dispatch, campusId, accessToken]);

  useEffect(() => {
    if (stockingId) {
      dispatch(fetchStocking(stockingId, accessToken));
    }
  }, [dispatch, stockingId, accessToken]);

  useEffect(() => {
    if (!selectedPhaseType) {
      return;
    }

    if (stockingId) {
      dispatch(fetchGrowthDeltaData({ sowingId: stockingId, phaseType: selectedPhaseType }, accessToken));
      return;
    }

    if (tankId) {
      dispatch(fetchGrowthDeltaData({ containerId: tankId, fromDate: fromDate, toDate: toDate, phaseType: selectedPhaseType }, accessToken));
      return;
    }

    if (moduleId) {
      dispatch(fetchGrowthDeltaData({ moduleId: moduleId, fromDate: fromDate, toDate: toDate, phaseType: selectedPhaseType }, accessToken));
    }
  }, [dispatch, stockingId, moduleId, tankId, accessToken, fromDate, toDate, selectedPhaseType]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    function showChartMain () {
      const data: GrowthDeltaStocking[] = [{ data: growDeltaData, stockingId: '' }];

      if (!chart) {
        const colorFillRect = backgroundColor;
        const deltaStockings = sortDeltaData(data, '', [], []);

        const props = {
          colorFillRect,
          colorLine: lineColor.light,
          companyData,
          container: refChartMain.current,
          dataMetric: deltaStockings,
          firstStage: parseInt(firstStage),
          height,
          lastStage: parseInt(lastStage),
          parameter: typeParam.GROWTH_DELTA,
          phaseType: selectedPhaseType,
          scale: typeScale.LINEAR,
          showLabels: deltaStockings.length === 1,
          typeChart: typesChart.STOCKINGS,
          width,
          movingAverage: parseInt(movingAverage),
          showMovingAverage,
        };
        chart = new GrowthDeltaChart(props);
      }
    }

    if (growDeltaData.length > 0 && companyData && !isLoadingCampus && !isLoadingStocking) {
      showChartMain();
    }
  }, [firstStage, lastStage, width, height, companyData, growDeltaData, isLoadingCampus, isLoadingStocking, selectedPhaseType, movingAverage, showMovingAverage]);

  if (isLoadingCampus || isLoadingStocking || growDeltaData.length === 0) {
    return <div className={styles.spinner}>
      <DotSpinner />
    </div>;
  }

  function getTitle () {
    if (selectedStocking._id) {
      let campusName = selectedCampus.name.toLowerCase();
      campusName = campusName.charAt(0).toUpperCase() + campusName.slice(1);
      return `${companyData?.name} - ${campusName}`;
    }

    return companyData?.name;
  }

  function getSubTitle () {
    if (selectedStocking._id) {
      let maturationName = selectedStocking.maturationId.name.toLowerCase();
      maturationName = maturationName.charAt(0).toUpperCase() + maturationName.slice(1);

      const density = calcDensity(selectedStocking);
      const densityUnit = getUnitDensity(selectedStocking);
      return `${t('stockings.pdf.stocking')} ${selectedStocking.code} - ${t('stockings.pdf.maturation')} ${maturationName} ${t('stockings.pdf.density')} ${density} ${densityUnit}`;
    }

    let campusName = selectedCampus.name.toLowerCase();
    campusName = campusName.charAt(0).toUpperCase() + campusName.slice(1);
    return campusName;
  }

  const getAxisXLabel = () => {
    return selectedCampus.phaseType === unitPhaseTypes.LARVAE ? t('survivalRate.stages') : t('survivalRate.days');
  };

  function renderReport () {
    const title = getTitle();
    const subtitle = getSubTitle();

    const renderLegends = () => {
      return (
        <div className={styles.legend}>
          <div className={styles.item}>
            <div className={cx(styles.colorBox, styles.pigmentation)} />
            <span>{parameterLabel}</span>
          </div>

          <div className={styles.item}>
            <div className={cx(styles.colorBox, styles.movingAverage)} />
            <span>{t('production.movingAverage')}</span>
          </div>
        </div>
      );
    };

    return <div className={cx(styles.containerMain, 'growthDeltaPdf')}>
      <Header
        title={title}
        subtitle={subtitle}
      />

      <div className={styles.rowData}>
        <Data
          stockingPhaseType={selectedPhaseType}
          parameter={typeParam.GROWTH_DELTA}
          legendList={[]}
          language={language}
        />
      </div>

      {renderLegends()}

      <div className={styles.rowData}>
        <div className={styles.containerChart}>
          <Content
            headerClassName={styles.headerPadding}
            titleClassName={styles.titleContent}
            noPadding
            style={{ backgroundColor: backgroundColor }}
          >
            <div ref={refChartMain} className={styles.chart}> </div>
            <div className={styles.labelAxisX}>{getAxisXLabel()}</div>
          </Content>
        </div>
      </div>
      <Footer />
    </div>;
  }

  return renderReport();
}