import React from 'react';
import { Form } from 'antd';
import cx from 'classnames';

import { LrvFormProps } from '../../interfaces/lrvForm';

import styles from './LrvForm.module.scss';

export const LrvForm = (props: LrvFormProps) => {
  const { className, children, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkForm : styles.lightForm, className),
  };

  return (
    <Form {...lrvProps}>
      {children}
    </Form>
  );
};
