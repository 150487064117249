import cx from 'classnames';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useRef } from 'react';

import Data from '../Data';
import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import Content from '../../../common/components/Content/Content';
import { getLabelAxisX } from '../../../helpers/stocking.helpers';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import GrowthDeltaChart from '../../../common/components/charts/ShadedPlot/GrowthDeltaChart';
import { calcDeltaStages, lineColor, metricsStatuses } from '../../../common/components/charts/ShadedPlot/helpers';
import { groupPointsByStage, typesChart, typeScale, typeParam, sortDeltaData, injectPigmentationToPoints } from '../../../common/components/charts/ShadedPlot/helpers';

import './ChartsPdf.scss';
import { DataSource } from './interfaces';
import styles from './ChartsPdf.module.scss';
import { getSubTitle, getTitle } from './helper';

let chart: D3ShadedPlot | GrowthDeltaChart | null;

type Props = {
  language: string;
  stockingId: string;
};

export default function GrowthDelta (props: Props) {
  const {
    language,
    stockingId,
  } = props;

  const refChartMain = useRef<HTMLDivElement>(null);

  const { company } = useSelector((state: Store) => state.header);
  const metrics = useSelector((state: Store) => state.stockingCharts);
  const stocking = useSelector((state: Store) => state.stockings.selectedStocking);

  const width = 1680 - 96;
  const height = 950 - 22;
  const backgroundColor = 'white';

  const sortStockings = useCallback(() => {
    const stockingGroupById = groupBy(metrics.analysis, 'sowingId');
    const dataSource: DataSource[] = [];
    const dataSourceSort: DataSource[] = [];

    for (const key in stockingGroupById) {
      if (Object.prototype.hasOwnProperty.call(stockingGroupById, key)) {
        const enabled = true;
        const points = stockingGroupById[key];
        const newPoints = injectPigmentationToPoints({ points, parameter: typeParam.GROWTH_DELTA });

        const stockingGroup: DataSource = { id: key, name: '', enabled: enabled, points: newPoints, avgPoint: [] };
        dataSource.push(stockingGroup);
      }
    }

    const data = dataSource.filter(value => value.id === stockingId)[0];
    data.enabled = true;
    data.avgPoint = groupPointsByStage({ points: data.points, parameter: typeParam.GROWTH_DELTA });
    dataSourceSort.push(data);

    return dataSourceSort;
  }, [metrics.analysis, stockingId]);

  useEffect(() => {
    function showChartMain () {
      const data = metrics.growthDelta;
      const maxStage = company.maxStage;
      const deltaStockings = sortDeltaData(data, stocking.name, [], []);
      const stages = calcDeltaStages(deltaStockings, maxStage);

      const firstStage = stages[0];
      const lastStage = stages[1];

      if (!chart) {
        const colorFillRect = backgroundColor;

        const deltaStockings = sortDeltaData(data, '', [], []);

        const props = {
          colorFillRect,
          colorLine: lineColor.light,
          companyData: company,
          container: refChartMain.current,
          dataMetric: deltaStockings,
          firstStage,
          height,
          lastStage,
          parameter: typeParam.GROWTH_DELTA,
          phaseType: stocking.phaseType,
          scale: typeScale.LINEAR,
          showLabels: deltaStockings.length === 1,
          typeChart: typesChart.STOCKINGS,
          width,
        };
        chart = new GrowthDeltaChart(props);
      }
    }

    if (metrics.analysis.length > 0 && metrics.growthDelta.length > 0 && company._id) {
      showChartMain();
    }
  }, [metrics.analysis, sortStockings, width, height, metrics.growthDelta, company._id, stocking.phaseType, stocking.name]);

  function renderReport () {
    const title = getTitle({ stocking, companyData: company });
    const subtitle = getSubTitle({ stocking });

    return <div className={cx(styles.containerMain, 'stockingChartsPdf')}>
      <Header
        title={title}
        subtitle={subtitle}
        stockingBindingCode={stocking.stockingBindingCode}
      />

      <div className={styles.rowData}>
        <Data
          stockingPhaseType={stocking.phaseType}
          dataSource={metricsStatuses.GROWTH_DELTA}
          parameter={typeParam.GROWTH_DELTA}
          scale={typeScale.LINEAR}
          language={language}
        />
      </div>

      <div className={styles.rowData}>
        <Content
          headerClassName={styles.headerPadding}
          titleClassName={styles.titleContent}
          noPadding
          style={{ backgroundColor: backgroundColor }}
        >
          <div ref={refChartMain} className={styles.chart}> </div>
          <div className={styles.labelAxisX}>{getLabelAxisX(stocking.phaseType)}</div>
        </Content>
      </div>

      <Footer />
    </div>;
  }

  return renderReport();
}