import moment from 'moment';
import cx from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent, Select, Space } from 'antd';

import { Store } from '../../../state/store.interfaces';
import { DATE_FORMATS } from '../../../config/commons';
import Icon from '../../../common/components/Icon/Icon';
import { LrvAlert } from '../../../common/components/LrvAlert/LrvAlert';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvSelect } from '../../../common/components/LrvSelect/LrvSelect';
import { LrvButton } from '../../../common/components/LrvButton/LrvButton';
import { LrvDatePicker } from '../../../common/components/LrvDatePicker/LrvDatePicker';
import { LrvRadioGroup } from '../../../common/components/LrvRadioGroup/LrvRadioGroup';
import { LrvRadioButton } from '../../../common/components/LrvRadioButton/LrvRadioButton';

import { User } from './interfaces';
import WeeklyUserActivity from './WeeklyUserActivity';
import MonthlyUserActivity from './MonthlyUserActivity';
import styles from './UserActivityDashboard.module.scss';
import { fetchUserActivityDashboard, setSelectedUser, setFromDate, setToDate, setDateSelected, setFrequency } from './userActivityDashboardSlice';

const { Option } = Select;

const dateOptions = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export default function UserActivityDashboard () {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { isLoading, filters, userActivity } = useSelector((state: Store) => state.userActivityDashboard);
  const { company } = useSelector((state: Store) => state.header);

  const { users, fromDate, toDate, user, dateSelected, frequency } = filters;

  useEffect(() => {
    if (!company._id || !user._id) {
      return;
    }

    dispatch(fetchUserActivityDashboard({ fromDate, toDate, userId: user._id, companyId: company._id }));
  }, [dispatch, company._id, user._id, fromDate, toDate]);

  const renderLoading = () => {
    return (
      <div className={styles.spinner}>
        <DotSpinner theme='light' />
      </div>
    );
  };

  const renderSampleEmpty = () => {
    return (
      <div className={styles.center} >
        <LrvEmpty
          theme='light'
          description={t('clientDashboard.userActivityDashboard.empty')}
        />
      </div>
    );
  };

  const renderBody = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (userActivity.length === 0) {
      return renderSampleEmpty();
    }

    switch (frequency) {
      case dateOptions.WEEKLY:
        return (
          <div className={styles.body}>
            {renderInfoLabel()}
            <WeeklyUserActivity />
          </div>
        );

      case dateOptions.MONTHLY:
        return (
          <Space className={cx(styles.body, styles.monthlyBody)}>
            {renderInfoLabel()}
            <MonthlyUserActivity />
          </Space>
        );
    }
  };

  const onUserChange = (value: string) => {
    const user = users.find((user) => user._id === value);
    if (!user) {
      return;
    }

    dispatch(setSelectedUser(user));
  };

  const renderUserDropdown = () => {
    return (
      <LrvSelect
        theme='light'
        id='dropdown_users'
        showSearch
        className={styles.select}
        value={user?._id || undefined}
        suffixIcon={<Icon name='arrow-down-s' />}
        placeholder={t('clientDashboard.userCompany')}
        optionFilterProp='children'
        filterOption={(input, option) => {
          const children = `${option?.children || ''}`;
          return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        dropdownMatchSelectWidth={false}
        disabled={!company._id}
        onChange={onUserChange}
      >
        {users.map((item: User, index: number) =>
          <Option key={index} value={item._id}>{item.firstName + ' ' + item.lastName}</Option>
        )}
      </LrvSelect>
    );
  };

  const onChangeDate = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }

    switch (frequency) {
      case dateOptions.WEEKLY:
        onWeekChange(date);
        break;

      case dateOptions.MONTHLY:
        onMonthChange(date);
        break;
    }
  };

  const onWeekChange = (date: moment.Moment) => {
    const year = date.year();
    const weekNumber = date.week();
    const weekStartDate = moment().isoWeekYear(year).isoWeek(weekNumber).startOf('week').format(DATE_FORMATS.YYYY_MM_DD);
    const weekEndDate = moment().isoWeekYear(year).isoWeek(weekNumber).endOf('week').format(DATE_FORMATS.YYYY_MM_DD);

    dispatch(setDateSelected(date.toString()));
    dispatch(setFromDate(weekStartDate));
    dispatch(setToDate(weekEndDate));
  };

  const onMonthChange = (date: moment.Moment) => {
    const weekStartDate = moment(date).startOf('month').format(DATE_FORMATS.YYYY_MM_DD);
    const weekEndDate = moment(date).endOf('month').format(DATE_FORMATS.YYYY_MM_DD);

    dispatch(setDateSelected(date.toString()));
    dispatch(setFromDate(weekStartDate));
    dispatch(setToDate(weekEndDate));
  };

  function disabledDatePicker (current: moment.Moment) {
    const start = moment().subtract(1, 'months');
    const end = moment();
    return !(start.isSameOrBefore(current) && end.isAfter(current));
  }

  const getPickerValue = () => {
    switch (frequency) {
      case dateOptions.WEEKLY:
      default:
        return 'week';

      case dateOptions.MONTHLY:
        return 'month';
    }
  };

  const onChangePreviousWeek = () => {
    const newDate = moment(dateSelected).subtract(1, 'week').startOf('week');
    onWeekChange(newDate);
  };

  const onChangePreviousMonth = () => {
    const newDate = moment(dateSelected).subtract(1, 'month').startOf('month');
    onMonthChange(newDate);
  };

  const canGoToPreviousWeek = () => {
    const newDate = moment(dateSelected).subtract(1, 'week').startOf('week');
    const startWeekDate = moment().subtract(1, 'months').startOf('week');
    return !startWeekDate.isSameOrBefore(newDate);
  };

  const canGoToPreviousMonth = () => {
    const newDate = moment(dateSelected).subtract(1, 'month').startOf('month');
    const startMonthDate = moment().subtract(1, 'months').startOf('months');
    return !startMonthDate.isSameOrBefore(newDate);
  };

  const onChangeNextWeek = () => {
    const newDate = moment(dateSelected).add(1, 'week').endOf('week');
    onWeekChange(newDate);
  };

  const onChangeNextMonth = () => {
    const newDate = moment(dateSelected).add(1, 'month').endOf('month');
    onMonthChange(newDate);
  };

  const canGoToNextWeek = () => {
    const newDate = moment(dateSelected).add(1, 'week').endOf('week');
    const endWeekDate = moment().endOf('week');
    return !endWeekDate.isSameOrAfter(newDate);
  };

  const canGoToNextMonth = () => {
    const newDate = moment(dateSelected).startOf('month').endOf('month');
    const endMonthDate = moment().endOf('month');
    return !endMonthDate.isAfter(newDate);
  };

  const canGoToPrevious = () => {
    switch (frequency) {
      case dateOptions.WEEKLY:
      default:
        return canGoToPreviousWeek();

      case dateOptions.MONTHLY:
        return canGoToPreviousMonth();
    }
  };

  const canGoToNext = () => {
    switch (frequency) {
      case dateOptions.WEEKLY:
      default:
        return canGoToNextWeek();

      case dateOptions.MONTHLY:
        return canGoToNextMonth();
    }
  };

  const onClickPreviousButton = () => {
    switch (frequency) {
      case dateOptions.WEEKLY:
        onChangePreviousWeek();
        break;

      case dateOptions.MONTHLY:
        onChangePreviousMonth();
        break;
    }
  };

  const onClickNextButton = () => {
    switch (frequency) {
      case dateOptions.WEEKLY:
        onChangeNextWeek();
        break;

      case dateOptions.MONTHLY:
        onChangeNextMonth();
        break;
    }
  };

  const renderPreviousButton = () => {
    return (
      <LrvButton
        theme='light'
        id='previous_button'
        type='default'
        className={styles.iconButton}
        disabled={canGoToPrevious()}
        icon={<Icon name='arrow-left' theme='light' />}
        onClick={onClickPreviousButton}
      />
    );
  };

  const renderNextButton = () => {
    return (
      <LrvButton
        theme='light'
        id='next_button'
        type='default'
        className={styles.iconButton}
        disabled={canGoToNext()}
        icon={<Icon name='arrow-right' theme='light' />}
        onClick={onClickNextButton}
      />
    );
  };

  const renderDatePicker = () => {
    return (
      <LrvDatePicker
        theme='light'
        id='date_picker'
        picker={getPickerValue()}
        className={styles.datePicker}
        value={moment(dateSelected)}
        allowClear={false}
        disabledDate={disabledDatePicker}
        onChange={onChangeDate}
        disabled
      />
    );
  };

  const onChangeFrequency = (event: RadioChangeEvent) => {
    const value = event.target.value;
    dispatch(setFrequency(value));

    switch (value) {
      case dateOptions.WEEKLY:
        onWeekChange(moment(dateSelected));
        break;

      case dateOptions.MONTHLY:
        onMonthChange(moment(dateSelected));
        break;
    }
  };

  const renderFrequencyOptions = () => {
    return (
      <LrvRadioGroup
        theme='light'
        id='frequency_radio_group'
        value={frequency}
        onChange={onChangeFrequency}
      >
        <LrvRadioButton
          theme='light'
          className={styles.radioButtonLeft}
          value={dateOptions.WEEKLY}
        >
          <div id='weekly_button'>{t('clientDashboard.userActivityDashboard.weekly')}</div>
        </LrvRadioButton>
        <LrvRadioButton
          theme='light'
          className={styles.radioButtonRight}
          value={dateOptions.MONTHLY}
        >
          <div id='monthly_button'>{t('clientDashboard.userActivityDashboard.monthly')}</div>
        </LrvRadioButton>
      </LrvRadioGroup>
    );
  };

  const renderInfoLabel = () => {
    return (
      <LrvAlert
        theme='light'
        className={styles.alert}
        message={t('clientDashboard.userActivityDashboard.infoLabel')}
        type='info'
        showIcon
      />
    );
  };

  return (
    <div className={cx(styles.userActivityDashboard, 'userActivityDashboard')}>
      <div className={styles.filters}>
        <Space className={styles.leftFilters}>
          {renderUserDropdown()}
        </Space>

        <Space className={styles.rightFilters}>
          {renderFrequencyOptions()}
          <Space className={styles.previousNextOptions}>
            {renderPreviousButton()}
            {renderNextButton()}
            {renderDatePicker()}
          </Space>
        </Space>
      </div>

      {renderBody()}
    </div>
  );
}