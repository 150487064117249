import { pathsPerms } from '../../config/config.perms';

export interface MenuItem {
  id?: string;
  path: string;
  subPaths?: string[];
  icon: string;
  title: string;
  rolesRequired?: string[];
  iconMoon?: boolean;
  subMenu?: SubMenuItem[];
}

export interface SubMenuItem {
  id?: string;
  path: string;
  subPaths?: string[];
  title: string;
  rolesRequired: string[];
  icon?: string;
}

export const popoverMenuItems: MenuItem[] = [
  {
    path: '/analysis-without-stockings',
    icon: 'file-search',
    title: 'sidebar.analysisWithoutStocking',
    rolesRequired: pathsPerms['/analysis-without-stockings'],
  },
  {
    path: '/archive',
    icon: 'archive',
    title: 'sidebar.archive',
    rolesRequired: pathsPerms['/archive'],
  },
  {
    path: '/balance-consumption',
    icon: 'file-chart',
    title: 'sidebar.balanceConsumption',
    rolesRequired: pathsPerms['/balance-consumption'],
  },
  {
    path: '/clients',
    icon: 'building',
    title: 'sidebar.clients',
    rolesRequired: pathsPerms['/clients']
  },
  {
    id: 'payments',
    path: '/payments',
    icon: 'money-dollar-circle',
    title: 'payments.payments',
    rolesRequired: pathsPerms['/payments']
  },
  {
    path: '/admin',
    icon: 'admin',
    title: 'sidebar.adminDashboard',
    rolesRequired: pathsPerms['/admin']
  }
];

export const menuItemsAbove: MenuItem[] = [
  {
    icon: 'production',
    path: '/production',
    title: 'sidebar.production',
    rolesRequired: pathsPerms['/production/stockings'],
    subMenu: [
      {
        path: '/stockings',
        subPaths: ['/production/analysis'],
        title: 'sidebar.stocking',
        rolesRequired: pathsPerms['/production/stockings'],
      },
      {
        path: '/insights',
        title: 'sidebar.curves',
        rolesRequired: pathsPerms['/production/insights'],
      },
      {
        path: '/parameters',
        title: 'sidebar.parameters',
        rolesRequired: pathsPerms['/production/parameters'],
      },
      {
        path: '/harvests',
        title: 'sidebar.harvests',
        rolesRequired: pathsPerms['/production/harvests'],
      },
    ],
  },
  {
    icon: 'line-chart',
    title: 'sidebar.reports',
    path: '/reports',
    rolesRequired: pathsPerms['/production/stockings'],
    subMenu: [
      {
        path: '/current-state',
        title: 'sidebar.currentState',
        rolesRequired: pathsPerms['/reports/current-state'],
      },
      {
        path: '/performance',
        title: 'sidebar.performance',
        rolesRequired: pathsPerms['/reports/performance'],
      },
      {
        path: '/quadrant',
        title: 'sidebar.quadrant',
        rolesRequired: pathsPerms['/reports/quadrant'],
      },
      {
        path: '/growth-delta',
        title: 'sidebar.growthDelta',
        rolesRequired: pathsPerms['/reports/growth-delta'],
      },
      {
        path: '/survival-rate',
        title: 'sidebar.survival',
        rolesRequired: pathsPerms['/reports/survival-rate'],
      },
      {
        path: '/laboratory',
        title: 'sidebar.laboratory',
        rolesRequired: pathsPerms['/reports/laboratory'],
      },
      {
        path: '/parameter-state',
        title: 'sidebar.parameters',
        rolesRequired: pathsPerms['/reports/parameter-state'],
      },
    ]
  },
  {
    icon: 'dna',
    title: 'sidebar.genetics',
    path: '/genetics',
    rolesRequired: pathsPerms['/genetics/setup'],
    subMenu: [
      {
        path: '/global',
        title: 'sidebar.global',
        rolesRequired: pathsPerms['/genetics/global'],
      },
      {
        path: '/setup',
        title: 'sidebar.settings',
        rolesRequired: pathsPerms['/genetics/setup'],
      },
      {
        path: '/insights',
        title: 'sidebar.curves',
        rolesRequired: pathsPerms['/genetics/insights'],
      },
    ]
  },
  {
    icon: 'building',
    title: 'sidebar.company',
    path: '/company',
    rolesRequired: pathsPerms['/company/administration-history'],
    subMenu: [
      {
        path: '/administration-history',
        title: 'sidebar.administrationHistory',
        rolesRequired: pathsPerms['/company/administration-history'],
      },
      {
        path: '/weight-units',
        title: 'sidebar.weightUnits',
        rolesRequired: pathsPerms['/company/weight-units'],
      },
      {
        path: '/analysis-card',
        title: 'sidebar.analysisCard',
        rolesRequired: pathsPerms['/company/analysis-card'],
      },
      {
        path: '/stocking-parameters',
        title: 'sidebar.parameters',
        rolesRequired: pathsPerms['/company/stocking-parameters'],
      },
      // {
      //   path: '/transports',
      //   title: 'sidebar.transports',
      //   rolesRequired: pathsPerms['/company/transports'],
      // },
    ]
  },
  {
    path: '/dashboard',
    icon: 'search-eye',
    title: 'sidebar.dashboard',
    rolesRequired: pathsPerms['/dashboard'],
  },
  {
    path: '/users',
    icon: 'user-3',
    title: 'sidebar.users',
    rolesRequired: pathsPerms['/users']
  },
  {
    path: '/units',
    subPaths: ['/units/:unitId', '/units/:id/modules', '/units/:unitId/modules/:moduleId'],
    icon: 'organization-chart',
    title: 'sidebar.campus',
    rolesRequired: pathsPerms['/units']
  },
  {
    path: '/reference-curves',
    icon: 'guide',
    title: 'sidebar.references',
    rolesRequired: pathsPerms['/reference-curves']
  },
];
