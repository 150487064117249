import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../../AppHeader/interfaces';
import { getHoursOffset } from '../../../utils/date';
import { getLanguage } from '../../../utils/language';
import { downloadFile } from '../../../utils/download';
import { unitStatuses } from '../../../config/commons';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { CAMPUS_URL, GROWTH_DELTA_PDF_URL, GROWTH_DELTA_URL, MODULES_URL, STOCKINGS_URL, TANKS_URL } from '../../../config/config.api';

import { initialFiltersState } from './helpers';
import { Campus, Container, GrowthDeltaFilters, GrowthDeltaState, Module } from './interfaces';

export const lastDaysGrowthDelta = 30;

const initialState: GrowthDeltaState = {
  data: [],
  filters: initialFiltersState,
  campus: [],
  modules: [],
  containers: [],
  stockings: [],
  isLoadingData: false,
  isDownloadingFile: false,
};

const growthDeltaSlice = createSlice({
  initialState,
  name: 'growthDelta',
  reducers: {
    setGrowthDeltaData: (state: GrowthDeltaState, action: PayloadAction<[]>) => {
      state.data = action.payload;
    },
    setGrowthDeltaCampus: (state: GrowthDeltaState, action: PayloadAction<Campus[]>) => {
      state.campus = action.payload;
    },
    setGrowthDeltaModules: (state: GrowthDeltaState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setGrowthDeltaContainers: (state: GrowthDeltaState, action: PayloadAction<Container[]>) => {
      state.containers = action.payload;
    },
    setGrowthDeltaStockings: (state: GrowthDeltaState, action: PayloadAction<[]>) => {
      state.stockings = action.payload;
    },
    setIsLoadingGrowthDeltaData: (state: GrowthDeltaState, action: PayloadAction<boolean>) => {
      state.isLoadingData = action.payload;
    },
    setIsDownloadingGrowthDeltaFile: (state: GrowthDeltaState, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },

    setFirstStage: (state: GrowthDeltaState, action: PayloadAction<number>) => {
      state.filters.firstStage = action.payload;
    },
    setLastStage: (state: GrowthDeltaState, action: PayloadAction<number>) => {
      state.filters.lastStage = action.payload;
    },
    setFirstStageZoom: (state: GrowthDeltaState, action: PayloadAction<number>) => {
      state.filters.firstStageZoom = action.payload;
    },
    setLastStageZoom: (state: GrowthDeltaState, action: PayloadAction<number>) => {
      state.filters.lastStageZoom = action.payload;
    },

    setGrowthDeltaFilters: (state: GrowthDeltaState, action: PayloadAction<GrowthDeltaFilters>) => {
      state.filters = action.payload;
    },
  },
});

export const {
  setGrowthDeltaData,
  setIsDownloadingGrowthDeltaFile,
  setGrowthDeltaCampus,
  setGrowthDeltaModules,
  setGrowthDeltaContainers,
  setGrowthDeltaStockings,
  setIsLoadingGrowthDeltaData,

  setFirstStage,
  setLastStage,
  setFirstStageZoom,
  setLastStageZoom,
  setGrowthDeltaFilters,
} = growthDeltaSlice.actions;

export const fetchGrowthDeltaData = (props: { sowingId?: string; containerId?: string; moduleId?: string; fromDate?: string; toDate?: string; phaseType: string }) => async (dispatch: Function) => {
  dispatch(setIsLoadingGrowthDeltaData(true));

  const params = {
    ...props,
    hoursOffset: getHoursOffset(),
  };

  try {
    const response = await axios.get(GROWTH_DELTA_URL, { params });
    dispatch(setGrowthDeltaData(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoadingGrowthDeltaData(false));
};

export const fetchGrowthDeltaCampuses = (companyId: string, phaseType: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    companyId: companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    phaseType,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    dispatch(setGrowthDeltaCampus(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchGrowthDeltaModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    campusId,
    $limit: -1,
    active: true,
    $select: ['_id', 'name', 'phaseType'],
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setGrowthDeltaModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchGrowthDeltaContainers = (moduleId: string) => async (dispatch: Function) => {
  const params = {
    moduleId,
    $limit: -1,
    active: true,
    $select: ['_id', 'name'],
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params });
    dispatch(setGrowthDeltaContainers(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchGrowthDeltaStockings = (containerId: string) => async (dispatch: Function) => {
  const params = {
    tankId: containerId,
    $limit: -1,
    active: true,
    $select: ['_id', 'name']
  };

  try {
    const response = await axios.get(STOCKINGS_URL, { params });
    dispatch(setGrowthDeltaStockings(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchUrlGrowthDeltaPdf =
  (params: {
    campusId?: string; moduleId?: string;
    firstStage: number; lastStage: number;
    fromDate?: string; toDate?: string;
    tankId?: string; stockingId?: string;
    movingAverage?: number;
  }) =>
    async (dispatch: Function) => {
      const { campusId, moduleId, firstStage, lastStage, fromDate, toDate, tankId, stockingId, movingAverage } = params;
      dispatch(setIsDownloadingGrowthDeltaFile(true));

      try {
        const userSession = getUserSession();
        const response = await axios.get(GROWTH_DELTA_PDF_URL);
        const language = getLanguage();
        const accessToken = localStorage.getItem('accessToken');

        let url = `${response.data.url}?companyId=${userSession.companyId}&campusId=${campusId}&moduleId=${moduleId}&firstStage=${firstStage}&lastStage=${lastStage}&accessToken=${accessToken}&language=${language}&fromDate=${fromDate}&toDate=${toDate}&movingAverage=${movingAverage}`;
        if (tankId) {
          url += `&tankId=${tankId}`;
        }

        if (stockingId) {
          url += `&sowingId=${stockingId}`;
        }

        await downloadFile(url, 'GROWTH_DELTA', 'pdf');
      } catch (e) {
        console.log(e.response);
      }
      dispatch(setIsDownloadingGrowthDeltaFile(false));
    };

export const resetGrowthDeltaFilters = (props: { company: Company; phaseType: string }) => (dispatch: Function) => {
  const { company, phaseType } = props;

  const unitPhaseType = getUnitPhaseTypeFromAnalysis(phaseType);
  dispatch(setGrowthDeltaFilters(initialFiltersState));
  dispatch(fetchGrowthDeltaCampuses(company._id, unitPhaseType));
};

const growthDeltaReducer = growthDeltaSlice.reducer;
export default growthDeltaReducer;
