import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { RootState } from '../../../state/store';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { stockingOptions } from '../../../config/commons';
import { lrvConfirm } from '../../../common/components/LrvConfirm/LrvConfirm';
import { ExtraActionsButton } from '../../../common/components/buttons/ExtraActionsButton';

import { Stocking } from './interfaces';
import * as archivedAnalysisSlice from './archivedStockingSlice';
import styles from './DropdownArchivedStockingOptions.module.scss';

interface Props {
  theme?: 'dark' | 'light';
  stocking: Stocking;
}

export const DropdownArchivedStockingOptions = (props: Props) => {
  const { stocking, theme = 'dark' } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isRunningOnboarding = useSelector((state: RootState) => state.onboarding.run);
  const { company: companySelected, phaseType } = useSelector((state: Store) => state.header);

  const {
    currentPage,
    sorts,
    campusSelected,
  } = useSelector((state: Store) => state.archivedStocking);

  const renderConfirmationUnarchiveStocking = (stocking: Stocking) => {
    const title = t('stockings.confirmUnarchive.title');
    const description = t('stockings.confirmUnarchive.description');
    const idOkButton = 'confirm_button';
    const idCancelButton = 'cancel_button';

    lrvConfirm({
      theme: 'light',
      title: title,
      content: description,
      icon: <ExclamationCircleOutlined />,
      okText: t('stockings.confirmUnarchive.yes').toUpperCase(),
      cancelText: t('stockings.confirmUnarchive.no').toUpperCase(),
      okButtonProps: { id: idOkButton },
      cancelButtonProps: { id: idCancelButton },
      onOk () {
        const params = {
          page: currentPage,
          companyId: companySelected._id,
          campusId: campusSelected?._id,
          phaseType,
          sortByCampusName: sorts.byCampusName,
          sortByModuleName: sorts.byModuleName,
          sortByTankName: sorts.byTankName,
          sortByStockingDate: sorts.byStockingDate,
        };
        dispatch(archivedAnalysisSlice.unarchiveStocking({ stockingId: stocking._id, fetchStockingParams: params }));
      },
    });
  };

  const menuItemsStockingOptions = (stocking: Stocking) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case stockingOptions.UNARCHIVE:
              renderConfirmationUnarchiveStocking(stocking);
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_unarchive' key={stockingOptions.UNARCHIVE} className={styles.menuItemOptions}>
          <Icon name='inbox-unarchive' theme={theme} className={styles.icon} />
          <span>{t('stockings.unarchive')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={() => menuItemsStockingOptions(stocking)}
      trigger={['click']}
      disabled={isRunningOnboarding}
      placement='bottomRight'
      className={styles.dropdown}
    >
      <ExtraActionsButton
        id={`button_${stocking._id}`}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};
