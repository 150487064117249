import { ReferenceCurvesDto } from '../pages/Units/interfaces';

export const getReferenceCurveDataPayload = (referenceCurves: ReferenceCurvesDto) => {
  const data = {
    ...referenceCurves,
    _id: referenceCurves._id || undefined,
    values: referenceCurves.values.map((value) => {
      return {
        mean: value.mean,
        range: value.range,
        stage: value.stage,
      };
    })
  };

  return data;
};