import React from 'react';
import cx from 'classnames';
import { InputNumber } from 'antd';

import { LrvText } from '../LrvText/LrvText';
import { LrvInputNumberProps } from '../../interfaces/lrvInputNumber';

import styles from './LrvInputNumber.module.scss';

export const LrvInputNumber = (props: LrvInputNumberProps) => {
  const { className, containerClassName, theme = 'dark', titleClassName, title } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkInputNumber : styles.lightInputNumber, className),
  };

  return (
    <div className={cx(styles.container, isDarkTheme ? styles.darkContainer : styles.lightContainer, containerClassName)}>
      <LrvText className={cx(isDarkTheme ? styles.titleDark : styles.titleLight, titleClassName)} text={title} theme={theme} />
      <InputNumber {...lrvProps}>
      </InputNumber>
    </div>
  );
};
