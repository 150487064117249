import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../Header';
import Footer from '../Footer';
import { Store } from '../../../state/store.interfaces';
import { typeHistogram } from '../../../config/commons';
import { Dataset } from '../../Analysis/Detail/interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import * as harvestsSlice from '../../Harvests/harvestsSlice';
import { getDataset } from '../../Analysis/Detail/histogram.helpers';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { HarvestHistogram } from '../../Harvests/Histogram/HarvestHistogram';

import DataDetail from './DataDetail';
import styles from './HarvestsPdf.module.scss';

type TParams = {
  harvestId: string;
  companyId: string;
  accessToken: string;
  language: string;
  histogram: string;
  harvestType: 'CONSOLIDATED' | 'HARVEST';
};

export const HarvestsPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    harvestId,
    companyId,
    accessToken,
    language,
    histogram,
    harvestType,
  } = match.params;

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const { histograms, isLoading } = useSelector((state: Store) => state.harvests);
  const { consolidated, harvest } = histograms;

  const [harvestDataset, setHarvestDataset] = useState<Dataset[]>([]);
  const [consolidatedDataset, setConsolidatedDataset] = useState<Dataset[]>([]);
  const [harvestLimits, setHarvestLimits] = useState<number[]>([]);
  const [consolidatedLimits, setConsolidatedLimits] = useState<number[]>([]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    switch (harvestType) {
      case 'HARVEST':
        dispatch(harvestsSlice.fetchHarvestHistogram({ harvestId, accessToken }));
        break;

      case 'CONSOLIDATED':
        dispatch(harvestsSlice.fetchConsolidationHistogram({ consolidatedId: harvestId, accessToken }));
        break;
    }
  }, [dispatch, harvestType, harvestId, accessToken]);

  useEffect(() => {
    if (!companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    if (histograms.isLoadingHarvest || !histograms.harvest?.stocking?._id) {
      return;
    }

    const frequency = histogram === typeHistogram.WEIGHT ? histograms.harvest.histogramWeight : histograms.harvest.histogramLength;
    const total = frequency.frequencies.reduce((accumulator, value) => accumulator + value, 0);
    const limits = histogram === typeHistogram.WEIGHT ? histograms.harvest.histogramWeight.limits : histograms.harvest.histogramLength.limits;
    const dataset: Dataset[] = getDataset(frequency, total);

    setHarvestLimits(limits);
    setHarvestDataset(dataset);
  }, [histogram, histograms.isLoadingHarvest, histograms.harvest]);

  useEffect(() => {
    if (histograms.isLoadingConsolidated || !histograms.consolidated?._id) {
      return;
    }

    const frequency = histogram === typeHistogram.WEIGHT ? histograms.consolidated.histogramWeight : histograms.consolidated.histogramLength;
    const total = frequency.frequencies.reduce((accumulator, value) => accumulator + value, 0);
    const limits = histogram === typeHistogram.WEIGHT ? histograms.consolidated.histogramWeight.limits : histograms.consolidated.histogramLength.limits;
    const dataset: Dataset[] = getDataset(frequency, total);

    setConsolidatedLimits(limits);
    setConsolidatedDataset(dataset);
  }, [histogram, histograms.isLoadingConsolidated, histograms.consolidated]);

  const getTitle = () => {
    if (harvest.stocking?._id) {
      return `${company.name} - ${harvest.stocking.campusId.name}`;
    }

    return company.name;
  };

  const getSubTitle = () => {
    if (harvest.stocking?._id) {
      return `${harvest.stocking.moduleId.name} - ${harvest.stocking.tankId.name} - ${harvest.stocking.name}`;
    }

    return consolidated?.name || '';
  };

  if (isLoading) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Header
        title={getTitle()}
        subtitle={getSubTitle()}
      />

      <div className={styles.body}>
        <DataDetail />

        <div className={cx(styles.harvestHistogram, styles.margin)}>
          <HarvestHistogram
            histogram={histogram}
            primaryLimits={harvestType === 'CONSOLIDATED' ? consolidatedLimits : harvestLimits}
            secondaryLimits={harvestType === 'CONSOLIDATED' ? harvestLimits : []}
            primaryDataset={harvestType === 'CONSOLIDATED' ? consolidatedDataset : harvestDataset}
            secondaryDataset={harvestType === 'CONSOLIDATED' ? harvestDataset : []}
            width={1110}
            height={686}
            renderPdf={true}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};
