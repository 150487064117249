import React from 'react';
import { StaticContext } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';

interface PublicRouteProps {
  component?: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | undefined;
  path?: string;
  exact?: boolean;
  render?: (props: RouteComponentProps<{
    [x: string]: string | undefined;
  }>) => React.ReactNode;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, path, exact, render } = props;

  return (
    <Route
      exact={exact}
      path={path}
      render={props => ((render && !Component) ? render(props) : undefined)}
      component={Component}
    />
  );
};
