import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { Store } from '../../../state/store.interfaces';
import { typeHistogram } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';
import { handleResizeEvent } from '../../../utils/dimensions';
import { Dataset } from '../../../pages/Analysis/Detail/interfaces';
import HistogramD3 from '../../../pages/Analysis/Detail/HistogramD3';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvResult } from '../../../common/components/LrvResult/LrvResult';
import { getDataset, typeStyles } from '../../../pages/Analysis/Detail/histogram.helpers';

import styles from './SurvivalRateHistogram.module.scss';
import { getHeightHistogram, getWidthChart, areAllNullsOrZeros } from './helpers';

let histogramChart: HistogramD3 | null;

interface Props {
  theme?: 'dark' | 'light';
}

export default function SurvivalRateHistogram (props: Props) {
  const { theme = getCurrentTheme() } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const refHistogram = useRef<HTMLDivElement>(null);

  const histogram = typeHistogram.SURVIVAL_RATE;

  const [height, setHeight] = useState(getHeightHistogram());
  const [width, setWidth] = useState(getWidthChart());

  const {
    survivalRateHistogram: frequencies,
    isLoading,
  } = useSelector((state: Store) => state.survivalRate);

  useEffect(() => {
    return () => {
      histogramChart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(getWidthChart());
      setHeight(getHeightHistogram());
    });
  }, []);

  useEffect(updateScreenOnResize, [width, height]);

  useEffect(() => {
    if (!frequencies.frequencies.length) {
      return;
    }
    const dataset: Dataset[] = getDataset(frequencies);
    const limits: number[] = frequencies.limits;

    if (histogramChart) {
      histogramChart.refreshHistogram({ dataset, histogram, limits, theme });
      return;
    }

    histogramChart = new HistogramD3({
      container: refHistogram.current,
      dataset,
      height,
      histogram,
      limits,
      theme,
      typeStyles: typeStyles.DEFAULT,
      width,
    });

  }, [t, histogram, histogramChart, frequencies, width, height, theme]);

  function updateScreenOnResize () {
    histogramChart && histogramChart.resize(width, height);
  }

  function renderSpinner () {
    if (!isLoading) {
      return null;
    }

    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  const hideChart = areAllNullsOrZeros(frequencies.frequencies) || areAllNullsOrZeros(frequencies.limits);

  const renderNotAVailableContainer = () => {
    if (hideChart && !isLoading) {
      return (
        <div className={styles.containerResult}>
          <LrvResult status='info' title={t('survivalRate.notAvailable')} className={styles.result} theme={theme} />
        </div>
      );
    }

    return null;
  };

  return <div id='survival_rate_histogram' className={styles.survivalRateHistogram}>
    {renderSpinner()}
    {renderNotAVailableContainer()}

    <div className={hideChart ? styles.hide : ''}>
      <div ref={refHistogram} />
      <div className={styles.labelAxisX}>
        <LrvText text={t('survivalRate.title')} theme={theme} />
      </div>
      <div className={styles.labelAxisY}>
        <LrvText text={t('survivalRate.stockingsHarvested')} theme={theme} />
      </div>
    </div>
  </div>;
}
