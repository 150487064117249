import { Form, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';

import './Settings.scss';
import styles from './TabApiService.module.scss';
import { createDataApiKey, getDataApi, updateDataApiKey } from './settingsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function TabIntegrationApiKey (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [formApiToken] = Form.useForm();

  const [apiKey, setApiKey] = useState('');
  const [copy, setCopy] = useState(t('share.copy'));
  const [showTooltip, setShowTooltip] = useState(false);

  const { dataAPI } = useSelector((state: Store) => state.profile);

  useEffect(() => {
    dispatch(getDataApi());
  }, [dispatch]);

  useEffect(() => {
    setApiKey(dataAPI.apiKey);

    formApiToken.setFieldsValue({
      apiKey: dataAPI.apiKey
    });
  }, [formApiToken, dataAPI]);

  return (
    <LrvForm
      id='formApiToken'
      className={styles.container}
      form={formApiToken}
      name='formApiToken'
      layout='vertical'
      theme={theme}
      onFinish={() => {
        if (!apiKey) {
          dispatch(createDataApiKey());
          return;
        }

        dispatch(updateDataApiKey());
      }}
    >
      <Form.Item>
        <LrvText theme={theme} text={t('apiService.description.service')} />
      </Form.Item>

      <div className={styles.line} />
      <Form.Item
        name='apiKey'
        label={t('apiService.apiKey')}
        required
      >
        <Tooltip
          open={showTooltip}
          placement='top'
          title={copy}
        >
          <LrvInput
            className={styles.apiToken}
            theme={theme}
            onMouseEnter={() => {
              setShowTooltip(true);
            }}
            onMouseLeave={() => {
              setCopy(t('share.copy'));
              setShowTooltip(false);
            }}
            onClick={() => {
              setCopy(t('share.copied'));
              navigator.clipboard.writeText(apiKey);
            }}
            value={apiKey}
            readOnly
          />
        </Tooltip>
      </Form.Item>

      <Form.Item>
        <div className={styles.alignButtonRight} >
          <Button id='submit_api_token_button' type='primary' htmlType='submit'>
            {t('apiService.generate')}
          </Button>
        </div>
      </Form.Item>
    </LrvForm>
  );
}
