import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';

import { LrvButtonProps } from '../../interfaces/lrvButton';

import styles from './LrvButton.module.scss';

export const LrvButton = (props: LrvButtonProps) => {
  const { className, theme = 'dark', children } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(styles.button, isDarkTheme ? styles.darkButton : styles.lightButton, className),
  };

  return (
    <Button {...lrvProps}>
      {children}
    </Button>
  );
};