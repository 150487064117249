import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { THEME } from '../../config/commons';
import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import IconButton from '../../common/components/buttons/IconButton';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';

import * as stockingSlice from './sowingsSlice';
import styles from './StockingSearchMobile.module.scss';
import RadioGroupSearchType from './RadioGroupSearchType';

interface Props {
  searchStockingsByName: (value: string) => void;
}

function StockingSearchMobile ({ searchStockingsByName }: Props) {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const { showSearchInput, searchValue } = useSelector((state: RootState) => state.stockings.filters);
  const [showRadioGroupSearch, setShowRadioGroupSearch] = useState(!!searchValue);

  const onClose = () => setShowRadioGroupSearch(false);

  return (
    <div className={styles.mobileSearchInputContainer}>
      <LrvInput
        id='search_stocking'
        theme={theme}
        className={cx(
          styles.mobileInputSearch,
          showSearchInput ? styles.activeSearch : '',
          isLightTheme ? styles.mobileInputSearchLight : styles.mobileInputSearchDark,
        )}
        placeholder={t('stockings.search')}
        onChange={(e) => {
          if (e.target.value) {
            setShowRadioGroupSearch(true);
          }
          dispatch(stockingSlice.setSearchValue(e.target.value));
        }}
        onFocus={() => {
          const existValue = !!searchValue;
          setShowRadioGroupSearch(existValue);
        }}
        value={searchValue}
        onPressEnter={() => {
          setShowRadioGroupSearch(false);
          dispatch(stockingSlice.setPage(1));
          dispatch(stockingSlice.setSearchValue(searchValue));
          searchStockingsByName(searchValue);
        }}
      />

      <IconButton
        id='btn_search_stocking'
        type={showSearchInput ? 'default' : 'text'}
        className={cx(
          styles.mobileBtnSearchStokings,
          showSearchInput ? styles.activeBtn : '',
          isLightTheme ? styles.mobileBtnLight : styles.mobileBtnDark,
        )}
        onClick={() => {
          dispatch(stockingSlice.setPage(1));
          dispatch(stockingSlice.setSearchValue(searchValue));
          searchStockingsByName(searchValue);
        }}
        icon={<Icon id='search_icon_button' name='search' />}
      />
      <RadioGroupSearchType onClose={onClose} show={showRadioGroupSearch} />
    </div>
  );
}

export default StockingSearchMobile;