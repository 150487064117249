import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon/Icon';
import { LrvTooltip } from '../LrvTooltip/LrvTooltip';
import { SORT_TYPE } from '../../../pages/Sowings/interfaces';
import { THEME } from '../../../config/commons';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './SortTitleCell.module.scss';

interface Props {
  sortType: SORT_TYPE;
  title: string;
  theme?: 'dark' | 'light';
}

export const SortTitleCell = (props: Props) => {
  const { sortType, title, theme = getCurrentTheme() } = props;

  const [t] = useTranslation();
  const isLightTheme = theme === THEME.LIGHT;

  const getIconClassName = () => {
    if (sortType === SORT_TYPE.ASC) {
      return styles.arrowUpIcon;
    }

    if (sortType === SORT_TYPE.DESC) {
      return styles.arrowDownIcon;
    }

    return styles.arrowDownIcon;
  };

  const getTooltipTitle = () => {
    if (sortType === SORT_TYPE.ASC) {
      return t('stockings.sorts.desc');
    }
    if (sortType === SORT_TYPE.DESC) {
      return t('stockings.sorts.cancel');
    }
    return t('stockings.sorts.asc');
  };

  const isFocused = sortType !== SORT_TYPE.DEFAULT;

  return (
    <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark)}>
      <LrvTooltip
        overlayStyle={{ pointerEvents: 'none' }}
        title={getTooltipTitle()}
      >
        <div className={styles.sorterCell}>
          <div className={styles.colTitle}>{title}</div>
          <div className={styles.sortIcons}>
            <Icon
              className={cx(isFocused ? styles.isFocusedIcon : '', getIconClassName())}
              name='arrow-up-s' type='fill'
            />
          </div>
        </div>
      </LrvTooltip>
    </div>
  );
};
