import cx from 'classnames';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';

import Header from '../Header';
import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { handleResizeEvent } from '../../../utils/dimensions';
import { THEME, roundTwoDecimals } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { calcDensity, getUnitDensity } from '../../../helpers/stocking.helpers';
import * as parameterChartSlice from '../../Sowings/Parameters/parameterChartSlice';
import ParameterChartD3 from '../../../common/components/charts/ParameterChart/ParameterChartD3';
import { getColorParameterWithFrequencies, getSelectedParametersChart } from '../../../common/components/charts/ParameterChart/helpers';
import { calculateDateRange, defaultStockingDataChart, updateParameterLayout, getExtraValue, parameterCardWidth } from '../../Sowings/Parameters/helpers';

import styles from './StockingParametersPdf.module.scss';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

let chart: ParameterChartD3 | null;

type TParams = {
  stockingId: string;
  accessToken: string;
  language: string;
  parameter: string;
  dateOption: string;
};

export const StockingParametersPdf = ({ match }: RouteComponentProps<TParams>) => {
  const {
    stockingId,
    accessToken,
    language,
    parameter,
    dateOption,
  } = match.params;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { company } = useSelector((state: Store) => state.header);
  const {
    parameterChart,

    parameterWithoutFrequencies,
    parameterWithFrequencies,
    isRequestInProgress,
    selectedStocking,
  } = useSelector((state: Store) => state.parameterChart);

  const { dataCustomizable } = parameterChart;
  const {
    growthRates,
    totalFeed,
    dates,
  } = parameterChart.data;

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const subtractWidth = 44;
  const extraHeight = 120;

  const refChartMain = useRef<HTMLDivElement>(null);
  const refLegends = useRef<HTMLDivElement>(null);

  const theme = 'light';
  const isLightTheme = theme === THEME.LIGHT;

  const onSelectParameter = (props: { parameter: string }) => {
    const { parameter } = props;

    if (!parameterChart.data.parameters?.withoutFrequencies) {
      return;
    }

    const parameterWithoutFrequencies = parameterChart.data.parameters.withoutFrequencies[parameter];
    dispatch(parameterChartSlice.setSelectedParameterName(parameter));

    if (parameterWithoutFrequencies && parameterWithoutFrequencies.values.length > 0) {
      dispatch(parameterChartSlice.setParameterWithoutFrequencies(parameterWithoutFrequencies));
      dispatch(parameterChartSlice.setParameterWithFrequencies({}));
      return;
    }

    const parameterWithFrequencies = parameterChart.data.parameters.withFrequencies[parameter];

    if (parameterWithFrequencies) {
      dispatch(parameterChartSlice.setParameterWithoutFrequencies({ unit: '', values: [] }));
      dispatch(parameterChartSlice.setParameterWithFrequencies(parameterWithFrequencies));
      return;
    }
  };

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    dispatch(parameterChartSlice.fetchStocking({ id: stockingId, accessToken }));
  }, [dispatch, stockingId, accessToken]);

  useEffect(() => {
    if (!selectedStocking?.companyId) {
      return;
    }

    dispatch(headerSlice.fetchCompany(selectedStocking.companyId, accessToken));
    dispatch(parameterChartSlice.fetchStockingParameterChart({ companyId: selectedStocking.companyId, stockingId, accessToken }));
  }, [dispatch, selectedStocking?.companyId, accessToken]);

  useEffect(() => {
    if (!company._id) {
      return;
    }

    dispatch(parameterChartSlice.fetchParameterChart({ companyId: company._id, accessToken }));
    dispatch(parameterChartSlice.fetchCompanyStockingParameter({ companyId: company._id, accessToken }));
  }, [dispatch, company._id, accessToken]);

  useEffect(() => {
    if (!stockingId || !dataCustomizable.data.length || !parameterChart.data.parameters?.withFrequencies) {
      return;
    }
    onSelectParameter({ parameter });
  }, [dataCustomizable.data, parameterChart.data.parameters, stockingId, parameter]);

  useEffect(() => {
    handleResizeEvent(() => {
      const legendsHeight = refLegends.current?.offsetHeight || 0;

      const chartWidth = refChartMain.current?.parentElement?.parentElement?.offsetWidth;
      const chartHeight = refChartMain.current?.parentElement?.parentElement?.offsetHeight;

      if (!chartWidth || !chartHeight) {
        return;
      }

      setWidth(chartWidth - subtractWidth);
      setHeight(chartHeight - legendsHeight + extraHeight);
    });
  }, []);

  useEffect(() => {
    if (!company._id || !refChartMain.current || isRequestInProgress) {
      return;
    }

    const legendsHeight = refLegends.current?.offsetHeight || 0;
    const chartWidth = refChartMain.current?.parentElement?.parentElement?.offsetWidth;
    const chartHeight = refChartMain.current?.parentElement?.parentElement?.offsetHeight;

    if (!chartWidth || !chartHeight) {
      return;
    }

    setWidth(chartWidth - subtractWidth);
    setHeight(chartHeight - legendsHeight + extraHeight);
  }, [parameterChart.data.dates, parameterChart.data.data, parameterChart.data.growthRates, parameterChart.data.parameters, parameterChart.data.totalFeed, isRequestInProgress, company._id, refChartMain.current, dataCustomizable.data]);

  useEffect(() => {
    if (!height || !width || !chart) {
      return;
    }

    chart.resize({ width, height });
  }, [width, height]);

  useEffect(() => {
    if (!height || !width || isRequestInProgress || chart || !refChartMain.current) {
      return;
    }

    const parameters = getSelectedParametersChart({ layoutCustomizable: dataCustomizable.data });

    if (!parameter && parameters.length > 0) {
      return;
    }

    const renderD3Chart = () => {
      const { maxDate, minDate } = calculateDateRange({ defaultMaxDate: dates.maxDate, defaultMinDate: dates.minDate, option: dateOption });

      chart = new ParameterChartD3({
        dispatch,
        height,
        width,
        parameterWithoutFrequencies,
        parameterWithFrequencies,
        container: refChartMain.current,
        minDate,
        maxDate,
        totalFeed,
        growthRates,
        selectedParameter: parameter,
        theme,
      });
    };

    renderD3Chart();
  }, [dispatch, width, height, theme, totalFeed, growthRates, dates, parameterWithoutFrequencies, parameterWithFrequencies, dateOption, parameter, refChartMain.current, isRequestInProgress, dataCustomizable.data]);

  const renderParameterCard = (props: { key: string; value?: number; unit?: string; }) => {
    const { key, value = 0, unit = '' } = props;

    const extraData = getExtraValue({ key, extraData: parameterChart.data.extraData });

    return (
      <div
        key={key}
        className={cx(styles.box)}
      >
        <div className={styles.containerText}>
          <LrvText text={t(`production.parameter.${key}`)} theme={theme} className={styles.label} />
          <LrvText text={`${roundTwoDecimals(value)} ${unit}`} theme={theme} className={styles.value} />
          <LrvText text={extraData.value ? `${roundTwoDecimals(extraData.value)} ${extraData.unit} ${extraData.label}` : ''} theme={theme} className={cx(styles.label, extraData.value && extraData.value > 0 ? styles.green : styles.red)} />
        </div>
      </div>
    );
  };

  const renderLegendWithFrequency = () => {
    const items: JSX.Element[] = [];
    let index = 0;

    Object.keys(parameterWithFrequencies).forEach((key) => {
      const label = `${parameter} ${key}`;

      const item = (
        <div
          className={styles.legend}
        >
          <div className={styles.circle} style={{ backgroundColor: getColorParameterWithFrequencies(index) }} />
          <LrvText theme={theme} text={label} />
        </div>
      );

      items.push(item);
      index++;
    });

    return items;
  };

  const renderLegendWithoutFrequency = () => {
    if (parameterWithoutFrequencies.values.length === 0) {
      return;
    }

    return (
      <div
        className={styles.legend}
      >
        <div className={cx(styles.circle, styles.parameterWithoutFrequencies)} />
        <LrvText theme={theme} text={parameter} />
      </div>
    );
  };

  const renderLegends = () => {
    return (
      <Space className={styles.legends} size='middle'>
        <div
          className={styles.legend}
        >
          <div className={cx(styles.square, isLightTheme ? styles.totalFeedLight : styles.totalFeedDark)} />
          <LrvText theme={theme} text={t('production.parameter.totalFeed')} />
        </div>

        <div
          className={styles.legend}
        >
          <div className={cx(styles.plusSign, isLightTheme ? styles.growthRateLight : styles.growthRateDark)} > + </div>
          <LrvText theme={theme} text={t('production.parameter.growthRate')} />
        </div>

        {renderLegendWithoutFrequency()}
        {renderLegendWithFrequency()}
      </Space>
    );
  };

  const renderLeftYAxisLabel = () => {
    return (
      <div className={styles.containerAxisYLabel}>
        <div
          className={styles.axisYLabel}
        >
          <LrvText theme={theme} text={`${t('production.parameter.totalFeed')} (${totalFeed.unit})`} />
        </div>
      </div>
    );
  };

  const getY3Label = () => {
    let unit = parameterWithoutFrequencies.unit;

    if (Object.keys(parameterWithFrequencies).length > 0) {
      const units = Object.values(parameterWithFrequencies).map(({ unit }) => unit);
      unit = units[0];
    }

    return unit ? `${parameter} (${unit})` : parameter;
  };

  const renderRightYAxisLabel = () => {
    const y3Label = getY3Label();

    return (
      <Space className={styles.containerAxisYLabel}>
        <div
          className={cx(styles.axisYLabel, styles.axisY2Label)}
        >
          <LrvText theme={theme} text={`${t('production.parameter.growthRate')} (${t('production.parameter.gramPerWeek', { unit: growthRates.unit })})`} />
        </div>

        <div
          className={cx(styles.axisYLabel, styles.axisY3Label)}
        >
          <LrvText theme={theme} text={y3Label} />
        </div>
      </Space>
    );
  };

  const renderRowLegends = () => {
    return (
      <div
        className={styles.containerLegends}
        ref={refLegends}
      >
        <div className={styles.rowLegends}>
          {renderLeftYAxisLabel()}
          {renderLegends()}
          {renderRightYAxisLabel()}
        </div>
      </div>
    );
  };

  const renderParameterChart = () => {
    return (
      <div className={styles.containerChart}>
        <div
          id='chart'
          ref={refChartMain}
          className={cx(styles.parameterChart, styles.showChart)}
        />
      </div>
    );
  };

  const renderChartCard = (props: { key: string; }) => {
    const { key } = props;

    return (
      <div key={key} className={styles.chart}>
        {renderRowLegends()}
        {renderParameterChart()}
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={styles.body}>
        <div
          className={styles.grid}
        >
          <ResponsiveReactGridLayout
            cols={{
              lg: parameterCardWidth.MAX,
              md: parameterCardWidth.MAX,
              sm: parameterCardWidth.MAX,
              xs: parameterCardWidth.MAX,
              xxs: parameterCardWidth.MAX,
            }}
            rowHeight={10}
            layouts={{ lg: dataCustomizable.data }}
            onLayoutChange={(currentLayout: Layout[]) => {
              const layoutCopy = updateParameterLayout({ layout: currentLayout, layoutCustomizable: dataCustomizable.data });
              dispatch(parameterChartSlice.setDataCustomizable({ data: layoutCopy }));
            }}
            useCSSTransforms={false}
            isDraggable={false}
            isDroppable={false}
            isResizable={false}
          >
            {dataCustomizable.data.map((item) => {
              if (item.i === defaultStockingDataChart.CHART) {
                return renderChartCard({ key: item.i });
              }

              return renderParameterCard({
                key: item.i,
                value: parameterChart.data.data[item.i]?.value,
                unit: parameterChart.data.data[item.i]?.unit,
              });
            })}
          </ResponsiveReactGridLayout>
        </div>
      </div>
    );
  };

  const getTitle = () => {
    let campusName = selectedStocking?.campusId?.name.toLowerCase();
    if (!campusName) {
      return company.name;
    }

    campusName = campusName.charAt(0).toUpperCase() + campusName.slice(1);
    return `${company.name} - ${campusName}`;
  };

  const getSubTitle = () => {
    if (!selectedStocking?._id) {
      return '';
    }

    const density = calcDensity(selectedStocking);
    const unitDensity = getUnitDensity(selectedStocking);

    let maturationName = selectedStocking.maturationId.name.toLowerCase();
    maturationName = maturationName.charAt(0).toUpperCase() + maturationName.slice(1);
    return t('stockings.pdf.stocking') + ' ' + selectedStocking.code + ' - ' + t('stockings.pdf.maturation') + ' ' + maturationName + ' - ' + t('stockings.pdf.density') + ' ' + density + ' ' + unitDensity;
  };

  return (
    <div className={styles.stockingParameters}>
      <Header
        title={getTitle()}
        subtitle={getSubTitle()}
      />

      <div className={styles.item}>
        <span>{t('stockingParameter.description')}</span>
      </div>

      {renderBody()}
    </div>
  );
};
