import DotSpinner from '../../common/components/DotSpinner/DotSpinner';

import styles from './Spinner.module.scss';

export default function Spinner () {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    </div>
  );
}
