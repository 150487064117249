import React, { ReactNode } from 'react';
import cx from 'classnames';

import { THEME } from '../../../config/commons';

import styles from './Content.module.scss';

interface Props {
  children: ReactNode;
  title?: ReactNode;
  headerClassName?: string;
  titleClassName?: string;
  subtitle?: ReactNode;
  actions?: ReactNode;
  className?: string;
  noPadding?: boolean;
  style?: React.CSSProperties | undefined;
  contentId?: string;
  theme?: 'dark' | 'light';
}

export default function Content (props: Props) {
  const { children, className, title, subtitle, actions, noPadding, style, headerClassName, titleClassName, contentId, theme = 'dark' } = props;

  const isLightTheme = theme === THEME.LIGHT;

  return <div className={cx(styles.content, className)} style={style} id={contentId}>
    <div className={cx(styles.header, headerClassName ?? '')}>
      <div className={cx(styles.title, isLightTheme ? styles.titleLight : styles.titleDark, titleClassName ?? '')}>{title}</div>
      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    <div className={cx(styles.body, noPadding ? styles.noPadding : '')}>
      {children}
    </div>
  </div>;
}
