import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LrvText } from '../LrvText/LrvText';
import { collection } from '../../../firebase';
import { Store } from '../../../state/store.interfaces';
import { Banner } from '../../../pages/Settings/interfaces';
import { checkIsLoggedIn } from '../../../utils/checkRoutes';
import * as headerSlice from '../../../pages/AppHeader/headerSlice';
import { FIRESTORE_KEY, FIRESTORE_VALUE } from '../../../config/firestore';

import { areBannersEqual } from './helper';
import NewFunctionalities from './NewFunctionalities';
import FunctionalitiesModal from './FunctionalitiesModal';

function FunctionalitiesListener () {
  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const modal = localStorage.getItem('showModal');
  const accessToken = localStorage.getItem('accessToken');

  const shouldRunOnboarding = useSelector((state: Store) => state.onboarding.shouldRun);
  const isLoggedIn = checkIsLoggedIn();
  const [showModal, setShowModal] = useState(modal === 'true' && accessToken !== null);
  const [shouldShowModal, setShouldShowModal] = useState(false || modal === null);
  const [shouldRelaunchPage, setShouldRelaunchPage] = useState(false);

  useEffect(() => {
    return history.listen((location) => {
      if (shouldShowModal) {
        localStorage.setItem('showModal', 'true');
      }

      if (shouldShowModal || shouldRelaunchPage) {
        const isLoggedIn = location.pathname !== '/login' && location.pathname !== '/password/forgot';

        if (isLoggedIn) {
          window.location.reload();
        }
      }
    });
  }, [history, shouldShowModal, shouldRelaunchPage]);

  useEffect(() => {
    const query = collection.where(FIRESTORE_KEY, '==', FIRESTORE_VALUE);
    const unsubscribe = query
      .onSnapshot(function (snapshot) {
        if (!snapshot.size) {
          return;
        }

        const version = localStorage.getItem('version');
        const commitRef = process.env.REACT_APP_COMMIT_REF;

        let versionFirestore = '';
        let messageFirestore: string[] = [];
        let newAlertMessage = {} as Banner;
        let commitRefFirestore = '';

        snapshot.forEach((element) => {
          const data = element.data();
          versionFirestore = data.version;
          messageFirestore = data.message;
          newAlertMessage = data.alertMessage;
          commitRefFirestore = data.commitRef;
        });

        const oldAlertMessage: Banner = JSON.parse(localStorage.getItem('alertMessage') || '{}');
        const areEqual = areBannersEqual(oldAlertMessage, newAlertMessage);

        if (!areEqual) {
          if (newAlertMessage.persistentMode && oldAlertMessage.alertClosed) {
            newAlertMessage.alertClosed = false;
          }

          localStorage.setItem('alertMessage', JSON.stringify(newAlertMessage));
          dispatch(headerSlice.setAlertMessage(newAlertMessage));
        }

        if (version !== versionFirestore) {
          localStorage.setItem('version', versionFirestore);
          localStorage.setItem('messages', JSON.stringify(messageFirestore));

          if (version !== null) {
            setShouldShowModal(true);
          }
        }

        if (commitRef && commitRef !== commitRefFirestore) {
          setShouldRelaunchPage(true);
        }
      });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <FunctionalitiesModal
      show={showModal && isLoggedIn && !shouldRunOnboarding}
      titleModal={t('functionalities.title')}
      titleButton={t('functionalities.gotIt')}
      onClose={() => {
        localStorage.setItem('showModal', 'false');
        setShowModal(false);
      }}
    >
      <NewFunctionalities theme='light' />
      <LrvText theme='light' text={t('functionalities.settings')} />
    </FunctionalitiesModal>
  );
}
export default FunctionalitiesListener;