import { getMainRole } from '../config/commons';

const higherRole = getMainRole();

export const checkIsLoggedIn = () => {
  const pathname = window.location.pathname;
  const isLoggedIn = pathname !== '/login' && pathname !== '/password/forgot';
  return isLoggedIn;
};

export function canRenderItem (rolesRequired: string[]) {
  return rolesRequired.includes(higherRole);
}