import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosClient as axios } from '../../utils/axios_instance';
import { STOCKINGS_TO_BE_FINISHED_URL } from '../../config/config.api';

import { StockingToBeFinishedPayload, StockingToBeFinishedState, Stocking } from './interfaces';

const limitStockingToBeFinished = 10;

const initialState: StockingToBeFinishedState = {
  total: 0,
  limit: 0,
  skip: 0,
  isLoading: false,
  stockings: [],
  showStockingNotification: false,
};

export const stockingToBeFinishedSlice = createSlice({
  name: 'stockingToBeFinished',
  initialState,
  reducers: {
    setShowStockingNotification: (state: StockingToBeFinishedState, action: PayloadAction<boolean>) => {
      state.showStockingNotification = action.payload;
    },

    setStockingsToBeFinished: (state: StockingToBeFinishedState, action: PayloadAction<StockingToBeFinishedPayload>) => {
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.stockings = action.payload.data;
    },

    resetStockingsToBeFinished: (state: StockingToBeFinishedState) => {
      state.total = 0;
      state.limit = 0;
      state.skip = 0;
      state.stockings = [];
    },

    setIsLoading: (state: StockingToBeFinishedState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setShowStockingNotification, setStockingsToBeFinished, resetStockingsToBeFinished, setIsLoading
} = stockingToBeFinishedSlice.actions;

export const fetchStockingsToBeFinished = (
  props: { page: number; companyId?: string; phaseType: string; stockings: Stocking[] }
) => async (dispatch: Function) => {
  const { page, companyId, phaseType, stockings } = props;
  let skip = 0;

  dispatch(setIsLoading(true));

  if (page !== 0) {
    skip = limitStockingToBeFinished * (page - 1);
  }

  const params = {
    phaseType,
    $limit: limitStockingToBeFinished,
    $skip: skip,
    companyId,
  };

  try {
    const response = await axios.get<StockingToBeFinishedPayload>(STOCKINGS_TO_BE_FINISHED_URL, { params: params });
    const data = {
      limit: response.data.limit,
      skip: response.data.skip,
      total: response.data.total,
      data: stockings.concat(response.data.data),
    };

    dispatch(setStockingsToBeFinished(data));
  } catch (e) {
    console.log('ERROR' + e?.response);
  }


  dispatch(setIsLoading(false));
};

export default stockingToBeFinishedSlice.reducer;
