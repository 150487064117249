import React from 'react';
import { Button, Col, Layout, Row, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import Logo from '../../../assets/larvia-dark.svg';
import { larviaDomain } from '../../../config/commons';

import styles from './PrivacyPolicyPage.module.scss';

const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

export default function UnauthorizedPage () {
  const [t] = useTranslation();

  return <Layout style={{ height: 'auto' }}>
    <Header className={styles.header}>
      <Row align="middle">
        <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <div className={styles.logo}>
            <img src={Logo} style={{ height: 100, width: 100 }} alt="LarvIA" />
          </div>
          <Space className={styles.options}>
            <Button
              type="link"
              size='large'
              className={styles.item}
              onClick={() => { window.location.href = larviaDomain; }}>
              {t('privacyPolicy.home')}
            </Button>
          </Space>
        </Col>
      </Row>
      <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
    </Header>
    <Content className={styles.main}>
      <div className={styles.content}>
        <Row>
          <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Title level={3}>{t('privacyPolicy.title')}</Title>
            <Paragraph className={styles.text}>
              {t('privacyPolicy.paragraph1')}
            </Paragraph>
            <Paragraph className={styles.text}>
              <ul>
                <li>{t('privacyPolicy.uses.1')}</li>
                <li>{t('privacyPolicy.uses.2')}</li>
                <li>{t('privacyPolicy.uses.3')}</li>
                <li>{t('privacyPolicy.uses.4')}</li>
                <li>{t('privacyPolicy.uses.5')}</li>
                <li>{t('privacyPolicy.uses.6')}</li>
              </ul>
            </Paragraph>
            <Paragraph className={styles.text}>
              {t('privacyPolicy.paragraph2')}
            </Paragraph>
          </Col>
          <Col xs={0} sm={0} md={5} lg={5} xl={5}></Col>
        </Row>
      </div>
    </Content>
    <Footer className={styles.footer}>
      LarvIA ©2020
    </Footer>
  </Layout>;

}



