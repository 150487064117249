import { Row, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Icon from '../../common/components/Icon/Icon';
import ActionButton from '../../common/components/buttons/ActionButton';
import { getMainRole, isDistributorCompany, roles } from '../../config/commons';

import * as paymentSlice from './paymentSlice';
import styles from './PaymentSubHeader.module.scss';

export default function PaymentSubHeader () {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const higherRole = getMainRole();

  const renderContent = () => {
    if (higherRole === roles.SUPER_ADMIN || higherRole === roles.FINANCE) {
      return (
        <Row>
          <ActionButton
            id='new_postpaid_transfer_button'
            type='primary'
            icon={<Icon name='file-upload' />}
            onClick={() => {
              dispatch(paymentSlice.setShowPostpaidCsvModal(true));
            }}
          >
            {t('payments.uploadPayments')}
          </ActionButton>
        </Row>
      );
    }

    if (higherRole === roles.CLIENT_OWNER || higherRole === roles.PARTIAL_ADMIN || higherRole === roles.BUSINESS_MANAGER) {
      return (
        <Row className={styles.rightRow} justify='end' align='middle'>
          <Space>
            {
              !isDistributorCompany() &&
              <ActionButton
                id='new_prepaid_pay_button'
                type='primary'
                icon={<Icon name='add' />}
                onClick={() => {
                  dispatch(paymentSlice.setShowPrepaidPaymentModal(true));
                  dispatch(paymentSlice.fetchPlans());
                  dispatch(paymentSlice.fetchCheckBalance());
                }}
              >
                {t('payments.prepaidBalance')}
              </ActionButton>
            }

            <ActionButton
              id='new_postpaid_transfer_button'
              type='primary'
              icon={<Icon name='add' />}
              onClick={() => {
                dispatch(paymentSlice.setShowPostpaidTransferModal(true));
              }}
            >
              {t('payments.pay')}
            </ActionButton>
          </Space>
        </Row>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      {renderContent()}
    </div>
  );
}