import React from 'react';
import cx from 'classnames';
import { Alert } from 'antd';

import { LrvAlertProps } from '../../interfaces/lrvAlert';

import styles from './LrvAlert.module.scss';

export const LrvAlert = (props: LrvAlertProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkAlert : styles.lightAlert, className),
  };

  return (
    <Alert {...lrvProps} >
    </Alert>
  );
};
