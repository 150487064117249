import ReactDOM from 'react-dom';

import { getCurrentElementHeight } from '../../../utils/dimensions';

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
  legends: React.RefObject<HTMLDivElement>;
  origins: React.RefObject<HTMLDivElement>;
  showStockingDetail: boolean;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters, legends, origins, showStockingDetail } = props;

  const headerHeight = 64;
  const axisXHeight = 24;
  const unitDropdownHeight = 54;

  const extraHeight = 50;
  const value = getCurrentElementHeight(filters) + getCurrentElementHeight(legends) + (showStockingDetail ? getCurrentElementHeight(origins) : 0) + axisXHeight + headerHeight + (unitDropdownHeight * 2) + extraHeight;
  return value;
};

interface ScrollHeightProps {
  listContainerRef: React.RefObject<HTMLDivElement>;
}

export const getScrollHeight = (props: ScrollHeightProps) => {
  const { listContainerRef } = props;
  if (!listContainerRef.current) {
    return 0;
  }

  const listElement = ReactDOM.findDOMNode(listContainerRef.current);

  if (!listElement) {
    return 0;
  }

  if (listElement instanceof Element) {
    const listChildElement = listElement.querySelector('.ant-list');
    return listChildElement?.scrollHeight || 0;
  }

  return 0;
};
