import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { ReactComponent as Logo } from '../../assets/larvia-dark.svg';

import styles from './PasswordForgot.module.scss';
import { passwordRequest } from './passwordSlice';
import { PasswordRequestData } from './interfaces';



export default function ForgotPassword () {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const isSuccessful: boolean | null = useSelector((state: Store) => state.password.isRequestSuccessful);

  return (
    <div className={styles.passwordForgotWrapper}>
      <div className={styles.passwordForgotFormWrapper}>
        <div className={styles.passwordForgotForm}>
          <div className={styles.passwordForgotLogo}><Logo /></div>
          <div className={styles.passwordForgotFormTitle}>
            {t('password.forgot.recover')}
          </div>
          {renderForm(isSuccessful, dispatch, t)}
        </div>
      </div>
    </div>

  );
}

function renderForm (isSuccessful: boolean | null, dispatch: Function, t: Function) {
  if (isSuccessful === false) {
    return <Form
      name="normal_login"
      className="login-form"
    >
      <Form.Item
        name="text"
        className={styles.formItem}
      >
        {t('password.forgot.errorText')}
      </Form.Item>

      <Form.Item>
        <div className={styles.formOptions}>
          <a className={styles.forgotPassword} href="/login">
            {t('password.forgot.signIn')}
          </a>
        </div>
      </Form.Item>
    </Form>;
  }

  if (isSuccessful === true) {
    return <Form
      name="normal_login"
      className="login-form"
    >
      <Form.Item
        name="text"
        className={styles.formItem}
      >
        {t('password.forgot.successText')}
      </Form.Item>

      <Form.Item>
        <div className={styles.formOptions}>
          <a className={styles.forgotPassword} href="/login">
            {t('password.forgot.signIn')}
          </a>
        </div>
      </Form.Item>
    </Form>;
  }

  return <Form
    name="normal_login"
    className="login-form"
    onFinish={(data) => dispatch(passwordRequest(data as PasswordRequestData))}
  >
    <Form.Item
      name="text"
      className={styles.formItem}
    >
      {t('password.forgot.descriptionText')}
    </Form.Item>

    <Form.Item
      name="email"
      rules={[{ required: true, message: t('signIn.emailError') }]}
      className={styles.formItem}
    >
      <Input size="large" prefix={<UserOutlined className={styles.loginIcon} />} autoFocus placeholder={t('signIn.email')} />
    </Form.Item>

    <Form.Item>
      <div className={styles.formOptions}>
        <Form.Item>
          <Button
            className={styles.submitButton}
            type="primary"
            htmlType="submit"
          >
            {t('password.forgot.send')}
          </Button>
        </Form.Item>

        <a className={styles.forgotPassword} href="/login">
          {t('password.forgot.signIn')}
        </a>
      </div>
    </Form.Item>
  </Form>;
}