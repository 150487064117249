import { useTranslation } from 'react-i18next';

import { LrvText } from '../../common/components/LrvText/LrvText';

import styles from './Legends.module.scss';
import { colorsPoints, CONSUMPTION_TYPE } from './helpers';

interface Props {
  theme?: 'dark' | 'light';
}

export default function Legends (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();

  return (
    <div className={styles.legend}>
      <div key={CONSUMPTION_TYPE.LOW} className={styles.item}>
        <div className={styles.colorBox} style={{ background: `${colorsPoints[0]}` }} />
        <span>
          <LrvText text={t('balances.consumption.low')} theme={theme} />
        </span>
      </div>

      <div key={CONSUMPTION_TYPE.MODERATE} className={styles.item}>
        <div className={styles.colorBox} style={{ background: `${colorsPoints[1]}` }} />
        <span>
          <LrvText text={t('balances.consumption.moderate')} theme={theme} />
        </span>
      </div>

      <div key={CONSUMPTION_TYPE.NORMAL} className={styles.item}>
        <div className={styles.colorBox} style={{ background: `${colorsPoints[2]}` }} />
        <span>
          <LrvText text={t('balances.consumption.normal')} theme={theme} />
        </span>
      </div>
    </div>
  );
}
