import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import IconButton from '../../common/components/buttons/IconButton';
import { fetchUrlActiveTanks } from '../Reports/ActiveTanksDashboard/ActiveTanksSlice';
import { getUserSession } from '../../utils/userSession';

import styles from './DownloadActiveTanks.module.scss';

export const DownloadActiveTanks = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { company, phaseType } = useSelector((state: Store) => state.header);
  const { activeTanks, isDownloadingActiveTanks, campus, module } = useSelector((state: Store) => state.activeTanks);

  const userSession = getUserSession();
  const disabled = activeTanks.length === 0;

  if (!(pathname.includes('reports/current-state'))) {
    return null;
  }

  return (
    <div className={styles.container}>
      <IconButton
        disabled={disabled}
        iconName='download'
        loading={isDownloadingActiveTanks}
        onClick={() => {
          if (!campus?._id || !module?._id) {
            return;
          }

          const params = { companyId: company._id, phaseType, userLang: userSession.language, campusId: campus._id, campusName: campus.name, timezone: campus.timezone, moduleId: module?._id, moduleName: module?.name };
          dispatch(fetchUrlActiveTanks(params));
        }}
        placement='right' tooltipText={t('activeTanks.download')}
      />
    </div>
  );
};
