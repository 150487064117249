import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';

import styles from './StockingParameterCustomizable.module.scss';
import { setShowCustomizableModal } from './stockingParameterCustomizableSlice';
import { StockingParameterCustomizableModal } from './StockingParameterCustomizableModal';

interface Props {
  theme?: 'dark' | 'light';
}

const maxItemsToShow = 4;

export const StockingParameterCustomizable = (props: Props) => {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const { fields } = useSelector((state: Store) => state.stockingParameterCustomizable);
  const { companyStockingParameters } = useSelector((state: Store) => state.companyStockingParameter);

  if (companyStockingParameters.length === 0) {
    return null;
  }

  const renderCustomDataButton = () => {
    return (
      <IconButton
        id='settings_button'
        onClick={() => {
          dispatch(setShowCustomizableModal(true));
        }}
        iconName='settings-4'
        size='middle'
        tooltipText={t('stockingParameterCustom.title')}
      />
    );
  };

  const renderItem = (key: string) => {
    return (
      <div>
        <div>
          <LrvText text='-' theme={theme} />
        </div>
        <div>
          <LrvText text={key} theme={theme} />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (fields.length === 0) {
      const items = [];

      for (let i = 0; i < maxItemsToShow && i < companyStockingParameters.length; i++) {
        items.push(renderItem(companyStockingParameters[i].key));
      }

      return items;
    }


    return (
      <>
        {fields.map((field) => renderItem(field))}
      </>
    );
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>
          <div>
            <LrvText text={t('stockingParameterCustom.title')} theme={theme} />
          </div>

          <LrvTooltip
            className={styles.containerInformation}
            title={t('stockingParameterCustom.tooltip')}
          >
            <div>
              <Icon name='information' theme={theme} />
            </div>
          </LrvTooltip>
        </div>

        <div className={styles.child}>
          <div className={styles.actions}>
            {renderCustomDataButton()}
          </div>
          {renderBody()}
        </div>
      </div>

      <StockingParameterCustomizableModal theme='light' />
    </>
  );
};