import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { Company } from '../AppHeader/interfaces';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { validateNumber } from '../../utils/validations';
import { PROVINCES_ECUADOR } from '../../config/commons';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';
import { applyParserThousandsSeparator, applyThousandsSeparator } from '../../utils/strings';

import './Sowings.scss';
import { Stocking } from './interfaces';
import { publishStocking, setShowPublishStockingModal } from './sowingsSlice';

function PublishStocking (props: { stocking: Stocking; company: Company }) {
  const { Option } = Select;
  const { stocking, company } = props;
  const [formPublishStocking] = Form.useForm();
  const million = 1000000;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const showModal = useSelector((state: Store) => state.stockings.publishLarviaTrade.showModal);
  const isLoadingToPublish = useSelector((state: Store) => state.stockings.publishLarviaTrade.isLoadingToPublish);

  const [animalsOffered, setAnimalsToHarvest] = useState<number | string>(0);
  const [province, setProvince] = useState('');
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  const resetForm = useCallback(() => {
    dispatch(setShowPublishStockingModal(false));
    setDisabledButton(true);

    formPublishStocking.resetFields();
    setAnimalsToHarvest(0);
  }, [dispatch, formPublishStocking]);

  useEffect(() => {
    if (!isLoadingToPublish) {
      resetForm();
    }
  }, [resetForm, isLoadingToPublish, formPublishStocking]);

  return (
    <LrvModal
      theme='light'
      title={t('stockings.trade.publishStocking')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_publish_stocking', htmlType: 'submit', form: 'formPublishStocking', loading: isLoadingToPublish, disabled: disabledButton }}
      onOk={() => {
        dispatch(publishStocking({ stockingId: stocking._id, isPublic: !stocking.isPublic, animalsOffered, province }));
      }}
      okText={t('stockings.trade.accept')}
      cancelText={t('stockings.trade.cancel').toUpperCase()}
      onCancel={resetForm}
    >
      <LrvForm
        id='formPublishStocking'
        theme='light'
        form={formPublishStocking}
        name='formPublishStocking'
        layout='vertical'
        onFieldsChange={() => {
          setDisabledButton(animalsOffered < million || animalsOffered > stocking.naupliusNumber);
        }}
      >
        <Form.Item>
          <LrvText
            theme='light'
            text={`* ${t('stockings.numberNauplii')} ${applyThousandsSeparator(stocking.naupliusNumber)}`}
          />
          <br />
          <LrvText
            theme='light'
            text={`* ${t('stockings.trade.infoRemove')}`}
          />

        </Form.Item>

        <Form.Item
          name='animalsOffered'
          label={t('stockings.trade.animalsOffered')}
          required
          rules={[() => ({
            validator (rule, value) {
              return validateNumber(value, true, million, stocking.naupliusNumber, t('stockings.trade.animalsOfferedError'));
            }
          })]}
        >
          <LrvInputNumber
            theme='light'
            value={animalsOffered}
            formatter={value => applyThousandsSeparator(value)}
            parser={value => applyParserThousandsSeparator(value)}
            onChange={(value) => {
              if (value) {
                setAnimalsToHarvest(value);
              }
            }}
          />
        </Form.Item>

        {!company.isInternational && !stocking.campusId.province &&
          <Form.Item
            required
            name='newCampusProvince'
            label={t('stockings.trade.province')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvSelect
              theme='light'
              showSearch
              value={province}
              onChange={e => setProvince(e)}
              suffixIcon={<Icon name='arrow-down-s' />}
              dropdownMatchSelectWidth={false}
            >
              {PROVINCES_ECUADOR.map((province) =>
                <Option key={province} value={province}>{province}</Option>
              )}
            </LrvSelect>
          </Form.Item>
        }
      </LrvForm>
    </LrvModal>
  );
}

export default PublishStocking;