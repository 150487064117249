import { Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../state/store.interfaces';
import { validateString } from '../../utils/validations';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../common/components/LrvText/LrvText';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInputNumber } from '../../common/components/LrvInputNumber/LrvInputNumber';

import * as harvestsSlice from './harvestsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export const HarvestHistogramXAxis = (props: Props) => {
  const {
    theme = 'light',
  } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [harvestsXAxisMinValue, setHarvestsXAxisMinValue] = useState(0);
  const [harvestsXAxisMaxValue, setHarvestsXAxisMaxValue] = useState(0);

  const { harvestsXAxisConfig } = useSelector((state: Store) => state.harvests);
  const { company } = useSelector((state: Store) => state.header);
  const { showModal, isLoading } = harvestsXAxisConfig;

  useEffect(() => {
    if (!showModal) {
      return;
    }

    setHarvestsXAxisMinValue(harvestsXAxisConfig.company.harvestsXAxisMinValue);
    setHarvestsXAxisMaxValue(harvestsXAxisConfig.company.harvestsXAxisMaxValue);

    form.setFieldsValue({
      harvestsXAxisMinValue: harvestsXAxisConfig.company.harvestsXAxisMinValue,
      harvestsXAxisMaxValue: harvestsXAxisConfig.company.harvestsXAxisMaxValue,
    });
  }, [form, showModal, harvestsXAxisConfig.company]);

  const onCloseModal = () => {
    setHarvestsXAxisMinValue(0);
    setHarvestsXAxisMaxValue(0);

    dispatch(harvestsSlice.setHarvestsXAxisValues({ harvestsXAxisMaxValue: 0, harvestsXAxisMinValue: 0 }));
    dispatch(harvestsSlice.setShowHarvestsXAxisConfigModal(false));
    form.resetFields();
  };

  const renderHarvestsXAxisMinValue = () => {
    return (
      <Col span={24}>
        <Form.Item
          name='harvestsXAxisMinValue'
          label={t('harvests.harvestsXAxisConfig.harvestsXAxisMinValue')}
          required
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={harvestsXAxisMinValue}
            min={0}
            onChange={(value) => {
              if (value === null) {
                return;
              }

              setHarvestsXAxisMinValue(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  const renderHarvestsXAxisMaxValue = () => {
    return (
      <Col span={24}>
        <Form.Item
          name='harvestsXAxisMaxValue'
          label={t('harvests.harvestsXAxisConfig.harvestsXAxisMaxValue')}
          required
          rules={[() => ({ validator (rule, value) { return validateString(value); } })]}
        >
          <LrvInputNumber
            theme={theme}
            value={harvestsXAxisMaxValue}
            min={0}
            onChange={(value) => {
              if (!value) {
                return;
              }

              setHarvestsXAxisMaxValue(parseInt(value.toString()));
            }}
          />
        </Form.Item>
      </Col>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('harvests.harvestsXAxisConfig.title')}
      open={showModal}
      destroyOnClose={true}
      okButtonProps={{ loading: isLoading }}
      onOk={() => {
        dispatch(harvestsSlice.updateHarvestsXAxisValues({
          companyId: company._id,
          body: { harvestsXAxisMaxValue, harvestsXAxisMinValue },
          onCloseModal,
        }));
      }}
      okText={t('harvests.accept')}
      onCancel={onCloseModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form'
        layout='vertical'
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item>
              <LrvText text={t('harvests.harvestsXAxisConfig.description')} theme={theme} />
            </Form.Item>
          </Col>
          {renderHarvestsXAxisMinValue()}
          {renderHarvestsXAxisMaxValue()}
        </Row>
      </LrvForm>
    </LrvModal>
  );
};
