import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Store } from '../../../../../state/store.interfaces';
import { LrvText } from '../../../../../common/components/LrvText/LrvText';

import styles from './Title.module.scss';
import { isRemovedOrHasPreviousGroup } from './helper';

interface Props {
  theme?: 'dark' | 'light';
}

export default function Title (props: Props) {
  const { theme } = props;
  const [t] = useTranslation();

  const {
    animalSelected,
  } = useSelector((state: Store) => state.sample);

  let label = t('detail.manageAnimals.title');

  if (isRemovedOrHasPreviousGroup(animalSelected)) {
    label = t('detail.manageAnimals.titleUndo');
  }

  return (
    <LrvText
      className={styles.title}
      theme={theme}
      text={label}
    />
  );
}
