import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Space, Form, Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UpdateMaturationProps } from '../interfaces';
import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { MaturationCode } from '../../../Clients/interfaces';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import { LrvSwitch } from '../../../../common/components/LrvSwitch/LrvSwitch';
import { LrvTooltip } from '../../../../common/components/LrvTooltip/LrvTooltip';

import styles from './EditMaturation.module.scss';
import * as companyMaturationsSlice from './companyMaturationsSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function EditMaturation (props: Props) {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [maturationName, setMaturationName] = useState('');
  const [maturationActive, setMaturationActive] = useState(true);
  const [maturationCodes, setMaturationCodes] = useState<MaturationCode[]>([]);

  const { isCreating, showEditMaturationModal, selectedMaturation } = useSelector((state: Store) => state.companyMaturations);

  useEffect(() => {
    if (!selectedMaturation?._id) {
      return;
    }

    const activeCodes = selectedMaturation.codes ? selectedMaturation.codes.filter((code) => code.active) : [];
    setMaturationName(selectedMaturation.name);
    setMaturationCodes(activeCodes);
    setMaturationActive(selectedMaturation.active);

    form.setFieldsValue({
      maturation: selectedMaturation.name,
    });
  }, [form, selectedMaturation]);

  const isValidNewMaturation = () => {
    const value = !!maturationName && isValidMaturationCode();
    return value;
  };

  const closeModal = () => {
    dispatch(companyMaturationsSlice.setShowEditMaturationModal(false));
    dispatch(companyMaturationsSlice.setSelectedMaturation(undefined));

    setMaturationName('');
    setMaturationCodes([]);
    form.resetFields();
  };

  const renderMaturationInput = () => {
    if (!selectedMaturation?.companyBaseId || selectedMaturation?.companyBaseId === selectedMaturation?.companyId) {
      return (
        <LrvInput
          id='txt_new_maturation'
          theme={theme}
          placeholder={t('maturations.name')}
          className={styles.maturationInput}
          value={maturationName}
          onChange={(e) => setMaturationName(e.target.value)}
        />
      );
    }

    return (
      <LrvTooltip
        themeStyle='dark'
        title={t('maturations.catalog')}
      >
        <LrvInput
          id='txt_new_maturation'
          theme={theme}
          placeholder={t('maturations.name')}
          value={maturationName}
          readOnly
          onChange={(e) => setMaturationName(e.target.value)}
        />
      </LrvTooltip>
    );
  };

  const addMaturationCode = () => {
    if (!isValidMaturationCode()) {
      return;
    }

    const newData: MaturationCode[] = cloneDeep(maturationCodes);
    newData.push({ _id: '', code: '', active: true });
    setMaturationCodes(newData);
  };

  const renderAddCodeButton = () => {
    return (
      <ActionButton
        id='btn_add_maturation_code'
        className={styles.addCodeButton}
        icon={<Icon name='add' />}
        onClick={() => addMaturationCode()}
        type='primary'
      >
        {t('maturations.newMaturationCode')}
      </ActionButton>
    );
  };

  const isValidMaturationCode = () => {
    if (!maturationCodes) {
      return true;
    }

    for (let index = 0; index < maturationCodes.length; index++) {
      const code = maturationCodes[index];
      if (!code.code) {
        return false;
      }
    }

    return true;
  };

  const renderCodeInputs = () => {
    return maturationCodes.map((maturationCode: MaturationCode, index: number) => {
      return (
        <Space className={cx(styles.codeRow, !maturationCode.isCodeBase && styles.codeRowWithButton)}>
          <LrvInput
            id='txt_new_maturation_code'
            theme='light'
            value={maturationCode.code}
            placeholder={t('maturations.code')}
            autoFocus
            readOnly={maturationCode.isCodeBase}
            onChange={(e) => {
              const newData: MaturationCode[] = cloneDeep(maturationCodes);
              const value = e.target.value;
              newData[index].code = value;
              setMaturationCodes(newData);
            }}
          />
          {
            maturationCode.isCodeBase ?
              <LrvTooltip
                themeStyle='light'
                title={t('maturations.catalogCode')}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  <Icon name='information' theme={theme} />
                </div>
              </LrvTooltip>
              :
              <Button
                className={maturationCode.active ? styles.deactiveMaturationButton : styles.activeMaturationButton}
                onClick={() => {
                  const newData: MaturationCode[] = cloneDeep(maturationCodes);
                  newData[index].active = !newData[index].active;
                  setMaturationCodes(newData);
                }}
              >
                {maturationCode.active ? t('maturations.deactivate') : t('maturations.activate')}
              </Button>
          }
        </Space>
      );
    });
  };

  const disabledForm = () => {
    return !isValidNewMaturation();
  };

  const onUpdateMaturation = () => {
    if (!selectedMaturation?._id) {
      return;
    }

    const inactiveCodes = selectedMaturation.codes ? selectedMaturation.codes.filter((code) => !code.active) : [];
    const codes = [...maturationCodes, ...inactiveCodes];

    const maturation: UpdateMaturationProps = {
      _id: selectedMaturation._id,
      active: maturationActive,
      codes,
      companyId: selectedMaturation.companyId,
      maturationBaseId: selectedMaturation.maturationBaseId,
      name: maturationName,
    };

    dispatch(companyMaturationsSlice.updateMaturation({ maturation, onSuccess: closeModal }));
  };

  return (
    <LrvModal
      theme='light'
      className={styles.editMaturation}
      title={t('maturations.editMaturation')}
      open={showEditMaturationModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'submit_maturation', htmlType: 'submit', form: 'form', loading: isCreating, disabled: disabledForm() }}
      onOk={onUpdateMaturation}
      okText={t('maturations.accept')}
      cancelText={t('maturations.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form'
        layout='vertical'
      >
        <Form.Item>
          <div className={styles.switch}>
            <div className={styles.status}>
              <LrvText text={t('maturations.status.active')} theme={theme} />
            </div>

            <LrvSwitch
              theme={theme}
              id='active_user_switch'
              className='switchActiveUser'
              checked={maturationActive}
              onChange={(value) => setMaturationActive(value)}
            />
          </div>
        </Form.Item>

        <Form.Item
          name='maturation'
          label={t('maturations.name')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          {renderMaturationInput()}
        </Form.Item>

        <Form.Item
          name='codes'
          label={maturationCodes.length > 0 ? t('maturations.codes') : ''}
        >
          <div className={styles.codes}>
            {renderCodeInputs()}
          </div>
          {renderAddCodeButton()}
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
}