import React from 'react';
import cx from 'classnames';
import { Result } from 'antd';

import { LrvResultProps } from '../../interfaces/lrvResult';

import styles from './LrvResult.module.scss';

export const LrvResult = (props: LrvResultProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(styles.result, isDarkTheme ? styles.darkResult : styles.lightResult, className),
  };

  return (
    <Result {...lrvProps}>
    </Result>
  );
};
