import { PresignedPostURL } from '../pages/Payments/interfaces';

import { ApiClient } from './axios_instance';

export const uploadFileToS3 = async (presignedPostData: PresignedPostURL, file: File) => {
  const apiClient: ApiClient = new ApiClient(false);
  const fields = presignedPostData.fields;
  const formData = new FormData();

  formData.append('AWSAccessKeyId', fields.AWSAccessKeyId);
  formData.append('key', fields.key);
  formData.append('policy', fields.policy);
  formData.append('signature', fields.signature);
  formData.append('file', file);

  try {
    await apiClient.axios.post(presignedPostData.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (e) {
    console.log(e?.response);
  }
};