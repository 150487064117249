import i18next from 'i18next';

import { Stocking } from '../Sowings/interfaces';
import { isNumber } from '../../utils/validations';
import { openErrorNotification } from '../../common/notification/Notification';
import { CompanyStockingParameter } from '../Company/StockingParameters/interfaces';
import { stockingParameterErrors, stockingParameterTypes } from '../../config/commons';

import { NewDataStockingParameter, StockingParameter } from './interfaces';

export const disabledStartButton = (companyStockingParameters: CompanyStockingParameter[]) => {
  const selectedItems = companyStockingParameters.filter((parameter) => !!parameter.selected);
  return selectedItems.length === 0;
};

export const subtractStockingParameters = (props: { companyStockingParameters: CompanyStockingParameter[]; stockingParameters: StockingParameter[] }) => {
  const {
    companyStockingParameters,
    stockingParameters,
  } = props;

  const results = companyStockingParameters.filter(companyStockingParameter => {
    const isNotInStockingParameters = !stockingParameters.some(stockingParameter => stockingParameter._id === companyStockingParameter._id);

    if (isNotInStockingParameters) {
      return true;
    }

    if (companyStockingParameter.type === stockingParameterTypes.NUMERIC && companyStockingParameter.options && companyStockingParameter.options.length > 0) {
      const usedOptionsCount = stockingParameters.filter(stockingParameter =>
        stockingParameter._id === companyStockingParameter._id &&
        companyStockingParameter.options.includes(stockingParameter.frequency)
      ).length;

      return usedOptionsCount < companyStockingParameter.options.length;
    }

    return false;
  });

  return results;
};

export const getBodyStockingParameter = (props: { _id?: string; stockingParameters: StockingParameter[]; selectedStocking: Stocking; currentDate: string; companyId: string }) => {
  const { _id, currentDate, selectedStocking, stockingParameters, companyId } = props;
  const newDataStockingParameters: NewDataStockingParameter[] = [];

  stockingParameters.forEach((stockingParameter) => {
    const item: NewDataStockingParameter = {
      key: stockingParameter._id,
      value: isNumber(stockingParameter.value) ? Number(stockingParameter.value) : stockingParameter.value,
      frequency: stockingParameter.frequency?.toString(),
    };
    newDataStockingParameters.push(item);
  });

  return {
    _id,
    stockingId: selectedStocking._id,
    companyId,
    data: newDataStockingParameters,
    date: currentDate,
  };
};

export const showStockingParameterError = (props: { error: string; key: string }) => {
  const { error, key } = props;

  switch (error) {
    case stockingParameterErrors.ONE_PARAMETER_BY_DATE:
      openErrorNotification(i18next.t('stockingParameter.form.errors.oneParameterByDate', { key }));
      break;
  }
};

export const isValidStockingParameter = (props: { auxiliarStockingParameters: StockingParameter[] }) => {
  const { auxiliarStockingParameters } = props;

  let isValid = true;

  for (let index = 0; index < auxiliarStockingParameters.length; index++) {
    const auxiliarStockingParameter = auxiliarStockingParameters[index];

    if (!isValid) {
      continue;
    }

    switch (auxiliarStockingParameter.type) {
      case stockingParameterTypes.NUMERIC:
      default: {
        const isValueValid = !!auxiliarStockingParameter.value && auxiliarStockingParameter.min <= auxiliarStockingParameter.value && auxiliarStockingParameter.value <= auxiliarStockingParameter.max;
        const isFrequencyValid = auxiliarStockingParameter.options.length > 0 ? !!auxiliarStockingParameter.frequency : true;
        isValid = isValueValid && isFrequencyValid;
        break;
      }

      case stockingParameterTypes.CATEGORICAL: {
        isValid = !!auxiliarStockingParameter.value;
        break;
      }
    }
  }

  return isValid;
};
