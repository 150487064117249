import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Company } from '../../AppHeader/interfaces';
import { getSortType } from '../../Sowings/sowings.helpers';
import { UserSession } from '../../../common/interfaces/auth';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';
import { FetchStockingParams, SORT_TYPE, STOCKINGS_SORT } from '../../Sowings/interfaces';
import { CAMPUS_URL, MODULES_URL, STOCKINGS_URL, TANKS_URL } from '../../../config/config.api';
import { getUnitPhaseTypeFromStocking, showErrorStocking } from '../../../helpers/stocking.helpers';
import { hasAdminRole, paginationStockings, stockingPhaseTypes, stockingsShow, stockingStatuses, transferTypes, unitStatuses } from '../../../config/commons';

import { ArchivedStockingPayload, ArchivedStockingState, Campus, Module, Container } from './interfaces';

const initialState: ArchivedStockingState = {
  total: 0,
  skip: 0,
  limit: 0,
  stockings: [],
  isLoading: false,
  currentPage: 1,
  campuses: [],
  modules: [],
  tanks: [],
  campusSelected: {
    _id: '',
    name: '',
    code: '',
    status: '',
    phaseType: '',
  },
  moduleIdSelected: '',
  tankIdSelected: '',
  sorts: {
    byCampusName: SORT_TYPE.DEFAULT,
    byModuleName: SORT_TYPE.DEFAULT,
    byTankName: SORT_TYPE.ASC,
    byStockingDate: SORT_TYPE.DEFAULT,
  },
};

export const archivedStockingSlice = createSlice({
  name: 'archivedStocking',
  initialState,
  reducers: {
    setArchivedStocking: (state: ArchivedStockingState, action: PayloadAction<ArchivedStockingPayload>) => {
      state.total = action.payload.total;
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.stockings = action.payload.data;
    },
    setIsLoading: (state: ArchivedStockingState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCampusSelected: (state: ArchivedStockingState, action: PayloadAction<Campus | undefined>) => {
      state.campusSelected = action.payload;
    },
    setModuleSelected: (state: ArchivedStockingState, action: PayloadAction<string | undefined>) => {
      state.moduleIdSelected = action.payload;
    },
    setTankSelected: (state: ArchivedStockingState, action: PayloadAction<string | undefined>) => {
      state.tankIdSelected = action.payload;
    },
    setCampuses: (state: ArchivedStockingState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },
    setModules: (state: ArchivedStockingState, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setTanks: (state: ArchivedStockingState, action: PayloadAction<Container[]>) => {
      state.tanks = action.payload;
    },
    setCurrentPage: (state: ArchivedStockingState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    changeSorts: (state: ArchivedStockingState, action: PayloadAction<{ sortBy: STOCKINGS_SORT; sortType?: SORT_TYPE }>) => {
      const { sortBy, sortType } = action.payload;
      switch (sortBy) {
        case STOCKINGS_SORT.CAMPUS_NAME:
          state.sorts.byCampusName = sortType ?? getSortType(state.sorts.byCampusName);
          break;
        case STOCKINGS_SORT.MODULE_NAME:
          state.sorts.byModuleName = sortType ?? getSortType(state.sorts.byModuleName);
          break;
        case STOCKINGS_SORT.TANK_NAME:
          state.sorts.byTankName = sortType ?? getSortType(state.sorts.byTankName);
          break;
        case STOCKINGS_SORT.STOCKING_DATE:
          state.sorts.byStockingDate = sortType ?? getSortType(state.sorts.byStockingDate);
          break;
      }
    },
    resetSorts: (state: ArchivedStockingState) => {
      state.sorts = {
        byCampusName: SORT_TYPE.DEFAULT,
        byModuleName: SORT_TYPE.DEFAULT,
        byTankName: SORT_TYPE.ASC,
        byStockingDate: SORT_TYPE.DEFAULT,
      };
    },
  },
});

export const {
  setArchivedStocking,
  setIsLoading,
  setCampusSelected,
  setModuleSelected,
  setTankSelected,
  setCampuses,
  setModules,
  setTanks,
  setCurrentPage,
  changeSorts,
  resetSorts,
} = archivedStockingSlice.actions;

const getParamsFetchStockings = (props: FetchStockingParams) => {
  const {
    page, companyId, campusId, moduleId, tankId, phaseType, stockingsToShow,
    sortByCampusName = SORT_TYPE.DEFAULT,
    sortByModuleName = SORT_TYPE.DEFAULT,
    sortByTankName = SORT_TYPE.ASC,
    sortByStockingDate = SORT_TYPE.DEFAULT,
  } = props;

  const userSession = getUserSession();

  let skip = 0;
  if (page !== 0) {
    skip = paginationStockings * (page - 1);
  }

  const $select = [
    'name', 'startDate', 'status', 'endDate', 'analysisCount', 'companyId', 'code', 'comment', 'harvestQuantity',
    'larvaePerGram', 'endDate', 'isPublic', 'averageHarvestedWeight', 'bindingStockings', 'campusName', 'moduleName', 'tankName', 'isArchived',
    'juvenilesNumber', 'hectares', 'cubicMeters', 'startDateJuvenile', 'litersNumber', 'naupliusNumber', 'startDateGrowOut'
  ];

  const params = {
    $limit: paginationStockings,
    $skip: skip,
    active: true,
    phaseType,
    $populate: ['campusId', 'moduleId', 'tankId', 'maturationId'],
    sortByCampusName,
    sortByModuleName,
    sortByTankName,
    isArchived: true,
    $select,
    tankId: tankId || undefined,
    moduleId: moduleId || undefined,
    campusId: campusId || undefined,
    companyId: hasAdminRole() ? companyId || userSession.companyId : userSession.companyId,
    sortByStartDate: phaseType === stockingPhaseTypes.LARVAE ? sortByStockingDate : undefined,
    sortByStartDateJuvenile: phaseType === stockingPhaseTypes.JUVENILE ? sortByStockingDate : undefined,
    sortByStartDateGrowOut: phaseType === stockingPhaseTypes.ADULT ? sortByStockingDate : undefined,
    'status[$in][0]': stockingsToShow === stockingsShow.ACTIVE ? stockingStatuses.ACTIVE : undefined,
    'status[$in][1]': stockingsToShow === stockingsShow.ACTIVE ? transferTypes.PARTIAL_TRANSFER : undefined,
  };
  return params;
};

export const fetchArchivedStocking = (props: FetchStockingParams) => async (dispatch: Function) => {
  dispatch(setIsLoading(true));
  const url = `${STOCKINGS_URL}-sorts`;
  const params = getParamsFetchStockings(props);

  try {
    const response = await axios.get<ArchivedStockingPayload>(url, { params });
    dispatch(setArchivedStocking(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const unarchiveStocking = (props: { stockingId: string; fetchStockingParams: FetchStockingParams }) => async (dispatch: Function) => {
  const { stockingId, fetchStockingParams } = props;
  const url = `${STOCKINGS_URL}/${stockingId}/unarchive`;

  try {
    await axios.patch(url);
    openSuccessNotification(i18next.t('stockings.updated'));
    dispatch(fetchArchivedStocking(fetchStockingParams));
  } catch (e) {
    console.log(e?.response);

    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
    }
  }
};

export const fetchCampuses = (props: { phaseType: string; companyId?: string }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  const userSession: UserSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    phaseType,
    'status[$in][0]': unitStatuses.ACTIVE,
    'status[$in][1]': unitStatuses.INACTIVE,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params });
    dispatch(setModules(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchTanks = (props: { campusId?: string; moduleId?: string }) => async (dispatch: Function) => {
  const { campusId, moduleId } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId,
    moduleId,
    '$sort[name]': 1,
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params });
    dispatch(setTanks(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const resetArchivedStockingFilters = (props: { company: Company; phaseType: string }) => (dispatch: Function) => {
  const { company, phaseType } = props;

  dispatch(setCampusSelected(undefined));
  dispatch(setModuleSelected(undefined));
  dispatch(setTankSelected(undefined));
  dispatch(resetSorts());

  const params = {
    page: 1,
    companyId: company._id,
    phaseType,
  };

  const unitPhaseType = getUnitPhaseTypeFromStocking(phaseType);
  dispatch(fetchArchivedStocking(params));
  dispatch(fetchCampuses({ companyId: company._id, phaseType: unitPhaseType }));
};

export default archivedStockingSlice.reducer;
