import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BAD_REQUEST, CONFLICT } from 'http-status-codes';

import { getUserSession } from '../../utils/userSession';
import { axiosClient as axios } from '../../utils/axios_instance';
import { analysisStatuses, paginationAnalysis, unitStatuses } from '../../config/commons';
import { openErrorNotification, openSuccessNotification, openWarningNotification } from '../../common/notification/Notification';
import { ANALYSES_URL, CAMPUS_URL, MODULES_URL, REMOVE_CONSOLIDATED_URL, STOCKINGS_URL, TANKS_URL } from '../../config/config.api';

import { Analysis, AnalysisWithStockingPayload, AnalysisWithStockingState, Container, Module, Stocking, Unit } from './interfaces';

const initialState: AnalysisWithStockingState = {
  total: 0,
  skip: 0,
  limit: 0,
  currentPage: 1,
  analysis: [],
  isLoading: false,
  assignStocking: {
    isLoading: false,
    showModal: false,
    analysisId: '',
    currentPage: 0,
    campuses: [],
    modules: [],
    containers: [],
    stockings: [],
  },
};

export const analysisWithoutStockingSlice = createSlice({
  name: 'analysisWithoutStocking',
  initialState,
  reducers: {
    setAnalysis: (state: AnalysisWithStockingState, action: PayloadAction<AnalysisWithStockingPayload>) => {
      state.total = action.payload.total;
      state.skip = action.payload.skip;
      state.limit = action.payload.limit;
      state.analysis = action.payload.data;
    },
    setAnalysisSelected: (state: AnalysisWithStockingState, action: PayloadAction<Analysis>) => {
      state.analysisSelected = action.payload;
    },
    setIsLoading: (state: AnalysisWithStockingState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCurrentPage: (state: AnalysisWithStockingState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCampuses: (state: AnalysisWithStockingState, action: PayloadAction<Unit[]>) => {
      state.assignStocking.campuses = action.payload;
    },
    setModules: (state: AnalysisWithStockingState, action: PayloadAction<Module[]>) => {
      state.assignStocking.modules = action.payload;
    },
    setContainers: (state: AnalysisWithStockingState, action: PayloadAction<Container[]>) => {
      state.assignStocking.containers = action.payload;
    },
    setStockings: (state: AnalysisWithStockingState, action: PayloadAction<Stocking[]>) => {
      state.assignStocking.stockings = action.payload;
    },
    setShowModal: (state: AnalysisWithStockingState, action: PayloadAction<boolean>) => {
      state.assignStocking.showModal = action.payload;
    },
    setIsLoadingAssign: (state: AnalysisWithStockingState, action: PayloadAction<boolean>) => {
      state.assignStocking.isLoading = action.payload;
    },
  },
});

export const {
  setAnalysis,
  setAnalysisSelected,
  setIsLoading,
  setCurrentPage,
  setCampuses,
  setModules,
  setContainers,
  setStockings,
  setShowModal,
  setIsLoadingAssign,
} = analysisWithoutStockingSlice.actions;

export const fetchAnalysis = (props: { page: number; companyId: string; stockingPhaseType?: string }) => async (dispatch: Function) => {
  const { companyId, page, stockingPhaseType } = props;

  let skip = 0;
  dispatch(setIsLoading(true));

  if (page !== 0) {
    skip = paginationAnalysis * (page - 1);
  }

  const params = {
    $limit: paginationAnalysis.toString(),
    $skip: skip.toString(),
    companyId,
    'sowingId[$exists]': false,
    '$sort[createdAt]': -1,
    $select: ['code', 'inputData', 'resultData.uniformity', 'resultData.larvaeNumber', 'resultData.larvaePerGram', 'resultData.averageWeight', 'analysisIds', 'createdAt', 'status', 'type', 'phaseType'],
    status: analysisStatuses.COMPLETED,
    phaseType: stockingPhaseType,
  };

  try {
    const response = await axios.get<AnalysisWithStockingPayload>(ANALYSES_URL, { params: params });
    dispatch(setAnalysis(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampuses = (props: { companyId: string; phaseType: string }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;

  const userSession = getUserSession();
  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
    phaseType: phaseType || undefined,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params: params });
    dispatch(setCampuses(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchModules = (campusId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Module[]>(MODULES_URL, { params: params });
    dispatch(setModules(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchContainers = (campusId?: string, moduleId?: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    moduleId: moduleId || undefined,
    '$sort[name]': 1,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params: params });
    dispatch(setContainers(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const fetchStockings = (campusId?: string, moduleId?: string, containerId?: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    active: true,
    campusId: campusId || undefined,
    moduleId: moduleId || undefined,
    tankId: containerId || undefined,
    $select: ['name'],
  };

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_URL, { params: params });
    dispatch(setStockings(response.data));
  } catch (e) {
    console.log('ERROR', e);
  }
};

export const assignAnalysis = (
  props: {
    analysisId: string;
    body: { stockingId: string };
    paramsFetchAnalysis: { companyId: string; page: number; stockingPhaseType: string };
  }
) => async (dispatch: Function) => {
  const { analysisId, body, paramsFetchAnalysis } = props;
  dispatch(setIsLoadingAssign(true));
  const url = `${ANALYSES_URL}/${analysisId}/assign-stocking`;

  try {
    await axios.patch(url, body);
    openSuccessNotification(i18next.t('analysisWithoutStocking.success'));
  } catch (e) {
    dispatch(setIsLoadingAssign(false));
    console.log('error', e.response);

    const errorCode = e?.response?.data?.code;
    switch (errorCode) {
      case CONFLICT:
        openWarningNotification(i18next.t('analysisWithoutStocking.conflictError'));
        return;
      case BAD_REQUEST:
        openErrorNotification(i18next.t('analysisWithoutStocking.badRequestError'));
        return;
    }
    openErrorNotification(i18next.t('analysisWithoutStocking.error'));
    return;
  }

  dispatch(setIsLoadingAssign(false));
  dispatch(setShowModal(false));
  dispatch(fetchAnalysis(paramsFetchAnalysis));
};

export const desconsolidateAnalysis = (props: { analysisId: string, currentPage: number, companyId: string, stockingPhaseType: string }) => async (dispatch: Function) => {
  const { analysisId, currentPage, companyId, stockingPhaseType } = props;
  try {
    await axios.delete(`${REMOVE_CONSOLIDATED_URL}/${analysisId}`);
    openSuccessNotification(i18next.t('analysis.deconsolidated'));
  } catch (e) {
    console.log(e?.response);
    openErrorNotification(i18next.t('analysisWithoutStocking.error'));
    return;
  }
  dispatch(fetchAnalysis({ page: currentPage, companyId, stockingPhaseType }));
};

export default analysisWithoutStockingSlice.reducer;
