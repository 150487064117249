import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { platform } from '../../../config/commons';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { ANALYSIS_DETAIL_CUSTOMIZABLE_URL } from '../../../config/config.api';
import { openSuccessNotification } from '../../../common/notification/Notification';

import { AnalysisCardMobile, AnalysisCardMobileState } from './interfaces';

const initialState: AnalysisCardMobileState = {
  analysisCardCustomizable: {
    _id: '',
    companyId: '',
    fields: [],
    phaseType: '',
  },
  isLoading: false,
};

export const analysisCardCustomizableSlice = createSlice({
  name: 'analysisCardCustomizable',
  initialState,
  reducers: {
    setAnalysisCardCustomizable: (state: AnalysisCardMobileState, action: PayloadAction<AnalysisCardMobile>) => {
      state.analysisCardCustomizable = action.payload;
    },
    resetAnalysisDetailCustomizable: (state: AnalysisCardMobileState) => {
      state.analysisCardCustomizable._id = '';
      state.analysisCardCustomizable.companyId = '';
      state.analysisCardCustomizable.fields = [];
      state.analysisCardCustomizable.phaseType = '';
    },
    setIsLoading: (state: AnalysisCardMobileState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  setAnalysisCardCustomizable,
  resetAnalysisDetailCustomizable,
  setIsLoading,
} = analysisCardCustomizableSlice.actions;

export const fetchAnalysisCardCustomizable = (props: { companyId: string; phaseType: string; }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  dispatch(setIsLoading(true));
  dispatch(resetAnalysisDetailCustomizable());

  const params = {
    $limit: -1,
    companyId,
    phaseType,
    platform: platform.MOBILE,
  };

  try {
    const response = await axios.get<AnalysisCardMobile[]>(ANALYSIS_DETAIL_CUSTOMIZABLE_URL, { params });
    if (response.data.length && response.data.length > 0) {
      dispatch(setAnalysisCardCustomizable(response.data[0]));
    }
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsLoading(false));
};

export const saveAnalysisCardCustomizable = (props: { companyId: string; phaseType: string; fields: string[]; }) => async (dispatch: Function) => {
  const { companyId, phaseType, fields } = props;

  const body = {
    companyId,
    phaseType,
    fields,
    platform: platform.MOBILE,
  };

  try {
    const response = await axios.post(ANALYSIS_DETAIL_CUSTOMIZABLE_URL, body);
    dispatch(setAnalysisCardCustomizable(response.data));
    openSuccessNotification(i18next.t('analysisCardCustomMobile.created'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateAnalysisCardCustomizable = (props: { _id: string; fields: string[] }) => async (dispatch: Function) => {
  const { _id, fields } = props;

  const url = `${ANALYSIS_DETAIL_CUSTOMIZABLE_URL}/${_id}`;
  const body = { _id, fields };

  try {
    const response = await axios.patch(url, body);
    dispatch(setAnalysisCardCustomizable(response.data));
    openSuccessNotification(i18next.t('analysisCardCustomMobile.updated'));
  } catch (e) {
    console.log(e?.response);
  }
};

export default analysisCardCustomizableSlice.reducer;
