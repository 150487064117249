import React from 'react';

interface Props {
  fill?: string;
  width: number;
  height: number;
}

export const AdultIcon = (props: Props) => {
  const { fill = 'white', width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio='xMinYMin slice'
      viewBox='0 0 36 36'
    >
      <defs>
        <clipPath id='_clipPath_hKnChWvbUZszbyKnRY8rIqvRxMkRxis2'>
          <rect width={width} height={height} />
        </clipPath>
      </defs>
      <g id='url(#_clipPath_hKnChWvbUZszbyKnRY8rIqvRxMkRxis2)'>
        <path
          d=' M 26.442 4.53 Q 29.309 3.47 30.434 4.53 C 32.776 6.733 28.247 8.772 26.442 8.772 Q 20.413 8.772 15.277 8.772 Q 10.142 8.846 6.569 12.94 C 2.996 17.034 5.388 27.322 11.827 30.655 C 15.648 32.633 18.917 32.254 23.614 30.655 C 28.31 29.055 30.062 27.826 25.373 26.859 Q 26.354 23.698 24.778 23.658 C 22.795 23.607 22.52 27.231 20.461 27.603 C 18.085 28.032 14.517 27.338 13.241 24.607 C 12.017 21.987 12.218 18.158 15.277 17.332 C 20.237 15.992 22.541 17.239 25.373 15.917 C 27.606 14.875 30.36 10.856 26.442 8.772'
          fill='none'
          stroke={fill}
          vectorEffect='non-scaling-stroke'
          strokeWidth={1.5}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />

        <path
          d=' M 11.752 4.53 C 15.89 6.987 19.199 5.992 23.512 4.53 C 27.904 3.041 28.459 8.026 27.037 8.772'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <circle
          cx={22.980964107450482}
          cy={11.488763263112457}
          r={0.9303774697573814}
          fill={fill}
          vectorEffect='non-scaling-stroke'
        />

        <path
          d=' M 20.907 27.528 Q 22.321 29.284 21.726 30.952'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 14.283 9.144 Q 13.439 13.646 15.623 17.034'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 12.273 20.83 Q 8.725 21.822 5.277 19.974'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 14.581 26.338 Q 13.588 29.513 10.636 29.91'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 17.93 16.885 Q 20.833 17.654 20.386 20.458'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 14.283 17.778 Q 16.714 18.299 16.814 20.532'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />
      </g>
    </svg>
  );
};
