import { Form } from 'antd';
import Papa from 'papaparse';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';

import { Store } from '../../state/store.interfaces';
import { useDragger } from '../../hooks/useDragger';
import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import ActionButton from '../../common/components/buttons/ActionButton';
import { LrvDragger } from '../../common/components/LrvDragger/LrvDragger';

import { PostpaidCsv } from './interfaces';
import styles from './NewPostpaidPayment.module.scss';
import { createPostpaidCsvPayment, setShowPostpaidCsvModal } from './paymentSlice';

interface Props {
  theme?: 'dark' | 'light';
}

export default function NewPostpaidPaymentCsv (props: Props) {
  const { theme = 'dark' } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [formPostpaidPayment] = Form.useForm();

  const [csvData, setCsvData] = useState<PostpaidCsv[]>([]);

  const {
    showPostpaidCsvModal,
    isLoadingPostpaidCsvPayment,
  } = useSelector((state: Store) => state.payments);

  const {
    canvasRef,
    handleCustomRequest,
    handleOnChange,
    imageUrl,
    loadingImage,
    voucherHeight,
    voucherWidth,
  } = useDragger({});

  const handleFileUpload = (file: File) => {
    Papa.parse<PostpaidCsv>(file, {
      complete: (result) => setCsvData(result.data),
      error: (error) => console.error(error.message),
      header: true,
    });
  };

  const columns: ColumnsType<PostpaidCsv> = [
    {
      title: t('payments.code'),
      dataIndex: 'code',
      key: 1,
      width: '14%',
    },
    {
      title: t('payments.amount'),
      dataIndex: 'amount',
      key: 2,
      width: '14%',
    },
    {
      title: t('payments.createdAt'),
      dataIndex: 'paymentDate',
      key: 3,
      width: '20%',
    },
    {
      title: t('payments.transfer'),
      dataIndex: 'isTransfer',
      key: 4,
      width: '22%',
      render: (_, record: PostpaidCsv) => {
        switch (record.isTransfer) {
          case 'true':
            return t('confirm.yes');

          case 'false':
            return t('confirm.no');

          default:
            return '';
        }
      },
    },
    {
      title: t('payments.description'),
      dataIndex: 'comment',
      key: 5,
      width: '30%',
    },
  ];

  const renderDraggerFile = () => {
    return (
      <div>
        <p>
          <InboxOutlined />
        </p>
        <p >{t('payments.drag')}</p>
      </div>
    );
  };

  const renderBodyModal = () => {
    if (csvData.length === 0) {
      return (
        <Form.Item>
          <div>
            <LrvDragger
              theme={theme}
              name='file'
              accept='text/csv'
              maxCount={1}
              showUploadList={false}
              beforeUpload={(file) => {
                handleFileUpload(file);
                return false;
              }}
              onChange={handleOnChange}
              customRequest={handleCustomRequest}
            >
              {imageUrl && !loadingImage ? <img src={imageUrl} alt='imageVoucher' style={{ width: '100%', height: 'inherit' }} /> : renderDraggerFile()}
            </LrvDragger>

            <canvas
              className={styles.canvas}
              ref={canvasRef}
              width={voucherWidth}
              height={voucherHeight}
            />
          </div>
        </Form.Item>
      );
    }

    return (
      <>
        <Form.Item>
          <ActionButton
            id='btn_clean_data'
            className={styles.button}
            icon={<Icon name='eraser' />}
            onClick={() => setCsvData([])}
            theme='light'
            type='ghost'
          >
            {t('production.sidePanel.clean')}
          </ActionButton>
        </Form.Item>

        <Form.Item>
          <LrvTable
            className={styles.table}
            dataSource={csvData}
            columns={columns}
            theme={theme}
            pagination={false}
            scroll={{ y: 240 }}
          />
        </Form.Item>
      </>
    );
  };

  return (
    <LrvModal
      theme={theme}
      className={styles.postpaidModal}
      title={t('payments.uploadPayments')}
      open={showPostpaidCsvModal}
      destroyOnClose={true}
      okButtonProps={{ id: 'new_postpaid_payment_csv', htmlType: 'submit', form: 'formPostpaidPayment', loading: isLoadingPostpaidCsvPayment }}
      cancelButtonProps={{ id: 'cancel_postpaid_payment_csv' }}
      okText={t('payments.register')}
      onOk={() => dispatch(createPostpaidCsvPayment({ payments: csvData }))}
      onCancel={() => dispatch(setShowPostpaidCsvModal(false))}
      closeIcon={<Icon name='close' />}
      width={700}
    >
      <LrvForm
        theme={theme}
        form={formPostpaidPayment}
        name='formPostpaidPayment'
        id='formPostpaidPayment'
        layout='vertical'
      >
        {renderBodyModal()}
      </LrvForm>
    </LrvModal>
  );
}