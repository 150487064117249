import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { downloadXlsxFile } from '../../utils/download';
import { GenericParam } from '../../common/interfaces/commons';
import { axiosClient as axios } from '../../utils/axios_instance';
import { companiesTypes, stockingPhaseTypes } from '../../config/commons';
import { openErrorNotification } from '../../common/notification/Notification';
import { formatLocaleHour, formatYearMonthDay, getHoursOffset, timezone } from '../../utils/date';
import { APP_USAGE_MONTHLY_RANGE_URL, APP_USAGE_BY_DAY_URL, COMPLETED_ANALYSIS_COUNT_URL, APP_USAGE_BY_DATE_RANGE_URL, XLSX_ANALYSIS_ACTIVITY_REPORT_URL } from '../../config/config.api';

import { getFromDate, getToDate, initialAdminDashboardFiltersState, LAST_DAYS } from './helpers';
import { AdminDashboardState, AppActivity, ActivityType, AppMonthlyActivity, AppDataActivity } from './interfaces';

const hoursOffset = getHoursOffset();
const initialState: AdminDashboardState = {
  appActivity: {
    days: [],
    data: {
      [stockingPhaseTypes.LARVAE]: [],
      [stockingPhaseTypes.JUVENILE]: [],
      [stockingPhaseTypes.ADULT]: [],
      ['ALL']: [],
    },
  },
  appMonthlyActivity: {
    months: [],
    data: {
      [stockingPhaseTypes.LARVAE]: [],
      [stockingPhaseTypes.JUVENILE]: [],
      [stockingPhaseTypes.ADULT]: [],
      ['ALL']: [],
    },
  },
  appFullActivity: {
    days: [],
    data: {
      [stockingPhaseTypes.LARVAE]: [],
      [stockingPhaseTypes.JUVENILE]: [],
      [stockingPhaseTypes.ADULT]: [],
    },
  },
  appFullActivityByDay: [],
  isAppActivityLoading: false,
  isAppActivityByMonthLoading: false,
  isAppActivityByDayLoading: false,
  filters: initialAdminDashboardFiltersState,
  completedAnalysisCount: 0,
  isDownloadingAnalysisActivity: false,
};

export const adminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {
    setAppActivity: (state: AdminDashboardState, action: PayloadAction<AppActivity>) => {
      state.appActivity = action.payload;
    },
    setAppMonthlyActivity: (state: AdminDashboardState, action: PayloadAction<AppMonthlyActivity>) => {
      state.appMonthlyActivity = action.payload;
    },
    setAppFullActivity: (state: AdminDashboardState, action: PayloadAction<AppActivity>) => {
      state.appFullActivity = action.payload;
    },
    setCompletedAnalysisCount: (state: AdminDashboardState, action: PayloadAction<number>) => {
      state.completedAnalysisCount = action.payload;
    },
    setAppFullActivityByDay: (state: AdminDashboardState, action: PayloadAction<[]>) => {
      state.appFullActivityByDay = action.payload;
    },
    setIsAppActivityLoading: (state: AdminDashboardState, action: PayloadAction<boolean>) => {
      state.isAppActivityLoading = action.payload;
    },
    setIsAppActivityByMonthLoading: (state: AdminDashboardState, action: PayloadAction<boolean>) => {
      state.isAppActivityByMonthLoading = action.payload;
    },
    setIsAppActivityByDayLoading: (state: AdminDashboardState, action: PayloadAction<boolean>) => {
      state.isAppActivityByDayLoading = action.payload;
    },

    setDailyFromDate: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.daily.fromDate = action.payload;
    },
    setDailyToDate: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.daily.toDate = action.payload;
    },

    setMonthlyFromDate: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.monthly.fromDate = action.payload;
    },
    setMonthlyToDate: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.monthly.toDate = action.payload;
    },

    setCompanyType: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.companyType = action.payload;
    },
    setActivityType: (state: AdminDashboardState, action: PayloadAction<ActivityType>) => {
      state.filters.activityType = action.payload;
    },
    setShowActivityByPhaseType: (state: AdminDashboardState, action: PayloadAction<boolean>) => {
      state.filters.showActivityByPhaseType = action.payload;
    },
    setDateSelected: (state: AdminDashboardState, action: PayloadAction<string>) => {
      state.filters.dateSelected = action.payload;
    },
    resetAdminDashboardFilters: (state: AdminDashboardState) => {
      state.filters = initialAdminDashboardFiltersState;
    },

    setIsDownloadingAnalysisActivity: (state: AdminDashboardState, action: PayloadAction<boolean>) => {
      state.isDownloadingAnalysisActivity = action.payload;
    },
  },
});

export const {
  setAppActivity,
  setAppMonthlyActivity,
  setAppFullActivity,
  setCompletedAnalysisCount,
  setAppFullActivityByDay,
  setIsAppActivityLoading,
  setIsAppActivityByMonthLoading,
  setIsAppActivityByDayLoading,

  setDailyFromDate,
  setDailyToDate,
  setMonthlyFromDate,
  setMonthlyToDate,
  setCompanyType,
  setActivityType,
  setShowActivityByPhaseType,
  setDateSelected,
  resetAdminDashboardFilters,
  setIsDownloadingAnalysisActivity,
} = adminDashboardSlice.actions;

export const fetchAppActivityDefault = () => (dispatch: Function) => {
  const params = {
    fromDate: formatYearMonthDay(getFromDate(LAST_DAYS)),
    toDate: formatYearMonthDay(getToDate()),
    hoursOffset,
    timezone,
    companyType: companiesTypes.ALL,
  };

  dispatch(fetchAppActivity(params));
};

const getAppActivity = (data: AppDataActivity) => {
  const larvaeActivity = data[stockingPhaseTypes.LARVAE];
  const juvenileActivity = data[stockingPhaseTypes.JUVENILE];
  const adultActivity = data[stockingPhaseTypes.ADULT];
  const allActivities = [];

  const total = larvaeActivity?.length || juvenileActivity?.length || adultActivity?.length || 0;

  for (let index = 0; index < total; index++) {
    const value = (larvaeActivity ? larvaeActivity[index] : 0) + (juvenileActivity ? juvenileActivity[index] : 0) + (adultActivity ? adultActivity[index] : 0);
    allActivities.push(value);
  }

  data['ALL'] = allActivities;
  return data;
};

export const fetchCompletedAnalysisCount = () => async (dispatch: Function) => {
  try {
    const response = await axios.get<{ analysisCount: number }>(COMPLETED_ANALYSIS_COUNT_URL);
    dispatch(setCompletedAnalysisCount(response.data.analysisCount));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchAppActivity = (filterData: { fromDate: string; toDate: string; companyType: string }) => async (dispatch: Function) => {
  dispatch(setIsAppActivityLoading(true));

  const params = {
    fromDate: formatYearMonthDay(filterData.fromDate),
    toDate: formatYearMonthDay(filterData.toDate),
    hoursOffset,
    timezone,
    companyType: filterData.companyType,
  };

  try {
    const response = await axios.get<AppActivity>(APP_USAGE_BY_DATE_RANGE_URL, { params });
    response.data.data = getAppActivity(response.data.data);
    dispatch(setAppActivity(response.data));
    dispatch(setIsAppActivityLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchAppMonthlyActivity = (filterData: { fromDate: string; toDate: string; companyType: string }) => async (dispatch: Function) => {
  dispatch(setIsAppActivityByMonthLoading(true));

  const params = {
    fromDate: formatYearMonthDay(filterData.fromDate),
    toDate: formatYearMonthDay(filterData.toDate),
    hoursOffset,
    timezone,
    companyType: filterData.companyType,
  };

  try {
    const response = await axios.get(APP_USAGE_MONTHLY_RANGE_URL, { params });
    response.data.data = getAppActivity(response.data.data);
    dispatch(setAppMonthlyActivity(response.data));
    dispatch(setIsAppActivityByMonthLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchAppActivityByHour = (props: { date: string; companyType: string }) => async (dispatch: Function) => {
  const { date, companyType } = props;
  dispatch(setIsAppActivityByDayLoading(true));

  const params = {
    date: formatYearMonthDay(date),
    hoursOffset,
    companyType
  };

  try {
    const response = await axios.get(APP_USAGE_BY_DAY_URL, { params });

    const appFullActivity = response.data.map((activity: GenericParam) => {
      const label = formatLocaleHour(activity.hour);
      return {
        label,
        count: activity.count,
      };
    });

    dispatch(setAppFullActivityByDay(appFullActivity));
    dispatch(setIsAppActivityByDayLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

interface AnalysisActivityProps {
  companyType: string;
  fromDate: string;
  toDate?: string;
  hoursOffset: number;
  activityType: ActivityType;
  timezone: string;
}

const getAnalysisActivityFileName = (props: { activityType: ActivityType; fromDate: string; toDate?: string; }) => {
  const { activityType, fromDate, toDate } = props;

  switch (activityType) {
    case 'DAILY':
    case 'MONTHLY':
      return `${activityType}_${fromDate}_${toDate}`;

    case 'PER_HOUR':
      return `${activityType}_${fromDate}`;

    default:
      return activityType;
  }
};

export const downloadAnalysisActivity = (props: AnalysisActivityProps) => async (dispatch: Function) => {
  const { companyType, fromDate, toDate, hoursOffset, activityType, timezone } = props;

  dispatch(setIsDownloadingAnalysisActivity(true));

  const config = {
    params: {
      companyType,
      fromDate,
      toDate,
      hoursOffset,
      activityType,
      timezone,
    }
  };

  try {
    const response = await axios.get(XLSX_ANALYSIS_ACTIVITY_REPORT_URL, config);
    const xlsxData = response.data;
    const fileName = getAnalysisActivityFileName({ activityType, fromDate, toDate });

    downloadXlsxFile({ xlsxData, fileName });
  } catch (error) {
    console.log(error);
    openErrorNotification(i18next.t('download.error'));
  }

  dispatch(setIsDownloadingAnalysisActivity(false));
};

export default adminDashboardSlice.reducer;
