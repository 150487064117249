import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Stocking } from '../interfaces';
import { sortByName } from '../../../utils/sort';
import { getLanguage } from '../../../utils/language';
import { unitStatuses } from '../../../config/commons';
import { downloadFile } from '../../../utils/download';
import { getUserSession } from '../../../utils/userSession';
import { axiosClient as axios } from '../../../utils/axios_instance';
import { openSuccessNotification } from '../../../common/notification/Notification';
import { CompanyStockingParameter } from '../../Company/StockingParameters/interfaces';
import { TANKS_URL, MODULES_URL, CAMPUS_URL, COMPANY_STOCKING_PARAMETER_URL, PARAMETER_CHART_CUSTOMIZABLE_URL, STOCKINGS_URL, STOCKING_PARAMETER_PDF_URL } from '../../../config/config.api';

import { dateRangeOptions, defaultStockingDataChart, defaultStockingParameterChart, parameterCardWidth } from './helpers';
import { Container, Module, Unit, ParameterChartStatus, StockingParameterChart, ParameterChartCustomizable, BodyParameterChartCustomizable, DataCustomizable, DefaultStockingParameterChart, StockingParameterData } from './interfaces';

export const lastDaysXlsxReport = 60;

const initialState: ParameterChartStatus = {
  units: [],
  containers: [],
  modules: [],
  stockings: [],
  containerId: '',
  moduleId: '',
  unitId: '',
  stockingId: '',
  companyStockingParameters: [],
  showDataCustomizableModal: false,
  showParameterCustomizableModal: false,
  parameterChart: {
    defaultData: {},
    data: {
      data: {},
      extraData: {},
      growthRates: {
        unit: '',
        values: [],
      },
      parameters: {
        withFrequencies: {},
        withoutFrequencies: {},
      },
      totalFeed: {
        unit: '',
        values: [],
      },
      dates: {
        maxDate: '',
        minDate: '',
      },
    },
    dataCustomizable: {
      data: [
        { x: 0, y: 0, w: parameterCardWidth.DEFAULT, h: 5, i: defaultStockingDataChart.DAYS },
        { x: 4, y: 0, w: parameterCardWidth.DEFAULT, h: 5, i: defaultStockingDataChart.BIOMASS },
        { x: 8, y: 0, w: parameterCardWidth.DEFAULT, h: 5, i: defaultStockingDataChart.AVERAGE_WEIGHT },
        { x: 12, y: 0, w: parameterCardWidth.DEFAULT, h: 5, i: defaultStockingDataChart.SURVIVAL_RATE },
        { x: 16, y: 0, w: parameterCardWidth.DEFAULT, h: 5, i: defaultStockingDataChart.UNIFORMITY_WEIGHT },
        { x: 20, y: 0, w: 4, h: 5, i: defaultStockingDataChart.FCR },
        { x: 0, y: 1, w: 24, h: 32, i: defaultStockingDataChart.CHART, minW: 4, minH: 10, maxW: 6, maxH: 40, parameters: [defaultStockingParameterChart.OXYGEN, defaultStockingParameterChart.TURBIDITY, defaultStockingParameterChart.TEMPERATURE], },
      ],
    },
  },
  enableEditing: false,
  parameterChartCustomizable: {
    _id: '',
    companyId: '',
    config: {
      data: [],
    },
  },
  parameterWithFrequencies: {},
  parameterWithoutFrequencies: {
    unit: '',
    values: []
  },
  selectedDateOption: dateRangeOptions.WHOLE_CROP.value,
  selectedParameterName: '',
  isRequestInProgress: false,
  selectedDataIndex: -1,
  shouldAddNewData: false,
  isDownloadingFile: false,
};

export const parameterChartSlice = createSlice({
  name: 'parameterChart',
  initialState,
  reducers: {
    setUnits: (state: ParameterChartStatus, action: PayloadAction<Unit[]>) => {
      state.units = action.payload;
    },
    setModules: (state: ParameterChartStatus, action: PayloadAction<Module[]>) => {
      state.modules = action.payload;
    },
    setContainers: (state: ParameterChartStatus, action: PayloadAction<Container[]>) => {
      state.containers = action.payload;
    },
    setStockings: (state: ParameterChartStatus, action: PayloadAction<Stocking[]>) => {
      state.stockings = action.payload;
    },

    setCompanyStockingParameters: (state: ParameterChartStatus, action: PayloadAction<CompanyStockingParameter[]>) => {
      state.companyStockingParameters = action.payload;
    },

    setUnitId: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.unitId = action.payload;
    },
    setModuleId: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.moduleId = action.payload;
    },
    setContainerId: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.containerId = action.payload;
    },
    setStockingId: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.stockingId = action.payload;
    },

    setSelectedStocking: (state: ParameterChartStatus, action: PayloadAction<Stocking | undefined>) => {
      state.selectedStocking = action.payload;
    },

    setShowDataCustomizableModal: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.showDataCustomizableModal = action.payload;
    },

    setShowParameterCustomizableModal: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.showParameterCustomizableModal = action.payload;
    },

    setDefaultStockingParameterChart: (state: ParameterChartStatus, action: PayloadAction<DefaultStockingParameterChart>) => {
      state.parameterChart.defaultData = action.payload;
    },

    setStockingParameterChart: (state: ParameterChartStatus, action: PayloadAction<StockingParameterChart>) => {
      state.parameterChart.data = action.payload;
    },

    resetStockingParameterChart: (state: ParameterChartStatus) => {
      state.parameterChart.data = {
        data: {},
        extraData: {},
        growthRates: {
          unit: '',
          values: [],
        },
        parameters: {
          withFrequencies: {},
          withoutFrequencies: {},
        },
        totalFeed: {
          unit: '',
          values: [],
        },
        dates: {
          maxDate: '',
          minDate: '',
        },
      };
    },

    setDataCustomizable: (state: ParameterChartStatus, action: PayloadAction<DataCustomizable>) => {
      state.parameterChart.dataCustomizable = action.payload;
    },

    setEnableEditing: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.enableEditing = action.payload;
    },

    setParameterChartCustomizable: (state: ParameterChartStatus, action: PayloadAction<ParameterChartCustomizable>) => {
      state.parameterChartCustomizable = action.payload;
      state.parameterChart.dataCustomizable = action.payload.config;
    },

    setHoveredParameter: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.hoveredParameter = action.payload;
    },
    setHoveredFrequency: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.hoveredFrequency = action.payload;
    },

    setSelectedParameterName: (state: ParameterChartStatus, action: PayloadAction<string | undefined>) => {
      state.selectedParameterName = action.payload;
    },
    setSelectedDateOption: (state: ParameterChartStatus, action: PayloadAction<string>) => {
      state.selectedDateOption = action.payload;
    },
    setParameterWithoutFrequencies: (state: ParameterChartStatus, action: PayloadAction<StockingParameterData>) => {
      state.parameterWithoutFrequencies = action.payload;
    },
    setParameterWithFrequencies: (state: ParameterChartStatus, action: PayloadAction<{ [key: string]: StockingParameterData }>) => {
      state.parameterWithFrequencies = action.payload;
    },
    setIsRequestInProgress: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.isRequestInProgress = action.payload;
    },

    setSelectedDataIndex: (state: ParameterChartStatus, action: PayloadAction<number>) => {
      state.selectedDataIndex = action.payload;
    },
    setShouldAddNewData: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.shouldAddNewData = action.payload;
    },

    setIsDownloadingFile: (state: ParameterChartStatus, action: PayloadAction<boolean>) => {
      state.isDownloadingFile = action.payload;
    },
  },
});

export const {
  setUnits,
  setModules,
  setContainers,
  setStockings,
  setCompanyStockingParameters,
  setUnitId,
  setModuleId,
  setContainerId,
  setStockingId,
  setSelectedStocking,
  setShowDataCustomizableModal,
  setShowParameterCustomizableModal,
  setDefaultStockingParameterChart,
  setStockingParameterChart,
  resetStockingParameterChart,
  setDataCustomizable,
  setEnableEditing,
  setParameterChartCustomizable,
  setHoveredParameter,
  setHoveredFrequency,

  setParameterWithFrequencies,
  setParameterWithoutFrequencies,
  setSelectedDateOption,
  setSelectedParameterName,
  setIsRequestInProgress,

  setSelectedDataIndex,
  setShouldAddNewData,
  setIsDownloadingFile,
} = parameterChartSlice.actions;

export const fetchCompanyStockingParameter = (props: { companyId: string; accessToken?: string }) => async (dispatch: Function) => {
  const { companyId, accessToken } = props;

  const params = {
    companyId,
    $limit: -1,
  };

  let response;

  try {
    if (accessToken) {
      response = await axios.get(COMPANY_STOCKING_PARAMETER_URL, {
        headers: { 'Authorization': accessToken },
        params: params,
      });
    } else {
      response = await axios.get<CompanyStockingParameter[]>(COMPANY_STOCKING_PARAMETER_URL, { params });
    }

    dispatch(setCompanyStockingParameters(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchDefaultStockingParameterChart = () => async (dispatch: Function) => {
  const url = `${PARAMETER_CHART_CUSTOMIZABLE_URL}/stocking/data`;

  try {
    const response = await axios.get<DefaultStockingParameterChart>(url);
    dispatch(setDefaultStockingParameterChart(response.data));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockingParameterChart = (props: { companyId: string; stockingId: string; accessToken?: string }) => async (dispatch: Function) => {
  const { companyId, stockingId, accessToken } = props;

  dispatch(resetStockingParameterChart());
  dispatch(setIsRequestInProgress(true));

  const params = {
    companyId,
  };

  let response;
  const url = `${PARAMETER_CHART_CUSTOMIZABLE_URL}/stocking/${stockingId}/data`;

  try {
    if (accessToken) {
      response = await axios.get(url, {
        headers: { 'Authorization': accessToken },
        params: params,
      });
    } else {
      response = await axios.get<StockingParameterChart>(url, { params });
    }

    dispatch(setStockingParameterChart(response.data));
  } catch (e) {
    console.log(e?.response);
  }

  dispatch(setIsRequestInProgress(false));
};

export const fetchUnits = (props: { companyId?: string; phaseType: string; }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    phaseType,
    'status[$in]': [unitStatuses.ACTIVE, unitStatuses.INACTIVE],
  };

  try {
    const response = await axios.get<Unit[]>(CAMPUS_URL, { params });
    const units = response.data;
    units.sort(sortByName);

    dispatch(setUnits(units));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchModules = (props: { unitId: string }) => async (dispatch: Function) => {
  const { unitId } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId: unitId,
  };

  try {

    const response = await axios.get<Module[]>(MODULES_URL, { params });

    const module = response.data;
    module.sort(sortByName);

    dispatch(setModules(module));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchContainers = (props: { unitId: string, moduleId: string }) => async (dispatch: Function) => {
  const { unitId, moduleId } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId: unitId,
    moduleId,
  };

  try {
    const response = await axios.get<Container[]>(TANKS_URL, { params });

    const containers = response.data;
    containers.sort(sortByName);

    dispatch(setContainers(containers));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStockings = (props: { companyId: string; unitId: string; moduleId: string; containerId: string; }) => async (dispatch: Function) => {
  const { companyId, unitId, moduleId, containerId } = props;

  const params = {
    $limit: -1,
    active: true,
    companyId,
    campusId: unitId,
    moduleId,
    tankId: containerId,
    isArchived: false,
    $select: ['name', 'code', 'phaseType'],
  };

  try {
    const response = await axios.get<Stocking[]>(STOCKINGS_URL, { params });

    const stockings = response.data;
    stockings.sort(sortByName);

    dispatch(setStockings(stockings));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchParameterChart = (props: { companyId: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { companyId, accessToken } = props;

  const params = {
    companyId,
    $limit: -1,
  };

  let response;

  try {
    if (accessToken) {
      response = await axios.get(PARAMETER_CHART_CUSTOMIZABLE_URL, {
        headers: { 'Authorization': accessToken },
        params: params,
      });
    } else {
      response = await axios.get<ParameterChartCustomizable[]>(PARAMETER_CHART_CUSTOMIZABLE_URL, { params });
    }

    if (!response.data[0]) {
      return;
    }

    dispatch(setParameterChartCustomizable(response.data[0]));
  } catch (e) {
    console.log(e?.response);
  }
};

export const createParameterChart = (props: BodyParameterChartCustomizable) => async (dispatch: Function) => {
  const { companyId, config } = props;

  const body = {
    companyId,
    config,
  };

  try {
    const response = await axios.post<ParameterChartCustomizable>(PARAMETER_CHART_CUSTOMIZABLE_URL, body);
    dispatch(setParameterChartCustomizable(response.data));
    openSuccessNotification(i18next.t('production.parameter.created'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const updateParameterChart = (props: BodyParameterChartCustomizable) => async (dispatch: Function) => {
  const { _id, companyId, config } = props;

  const body = {
    companyId,
    config,
  };

  const url = `${PARAMETER_CHART_CUSTOMIZABLE_URL}/${_id}`;

  try {
    const response = await axios.patch<ParameterChartCustomizable>(url, body);

    dispatch(setParameterChartCustomizable(response.data));
    openSuccessNotification(i18next.t('production.parameter.updated'));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchStocking = (props: { id: string; accessToken?: string; }) => async (dispatch: Function) => {
  const { id, accessToken, } = props;

  const params = {
    $select: [
      'name',
      'type',
      'code',
      'phaseType',
      'companyId',
      'companyId',
      'campusId',
      'maturationId',
      'naupliusNumber',
      'juvenilesNumber',
      'growOutNumber',
      'litersNumber',
      'cubicMeters',
      'hectares',
      'harvestQuantity',
    ],
    $populate: [
      'campusId',
      'maturationId',
    ]
  };

  const url = `${STOCKINGS_URL}/${id}`;

  try {
    const response = await axios.get<Stocking>(url, {
      headers: { 'Authorization': accessToken },
      params: params,
    });

    dispatch(setSelectedStocking(response.data));
  } catch (e) {
    dispatch(setSelectedStocking(undefined));
    console.log(e?.response);
  }
};

export const resetFilters = () => (dispatch: Function) => {
  dispatch(setUnitId(undefined));
  dispatch(setModuleId(undefined));
  dispatch(setContainerId(undefined));
  dispatch(setStockingId(undefined));
  dispatch(setUnits([]));
  dispatch(setModules([]));
  dispatch(setContainers([]));
  dispatch(setStockings([]));
  dispatch(resetStockingParameterChart());
};

interface ParamsStockingParameterPdf {
  stockingId: string;
  stockingName: string;
  parameter: string;
  dateOption: string;
}

export const fetchUrlStockingParameterPdf = (params: ParamsStockingParameterPdf) => async (dispatch: Function) => {
  const { stockingId, stockingName, parameter, dateOption } = params;
  dispatch(setIsDownloadingFile(true));

  try {
    const response = await axios.get(STOCKING_PARAMETER_PDF_URL);
    const language = getLanguage();
    const accessToken = localStorage.getItem('accessToken');
    const url = `${response.data.url}?stockingId=${stockingId}&dateOption=${dateOption}&parameter=${parameter}&accessToken=${accessToken}&language=${language}`;
    await downloadFile(url, stockingName, 'pdf');
  } catch (e) {
    console.log(e.response);
  }

  dispatch(setIsDownloadingFile(false));
};

export default parameterChartSlice.reducer;
