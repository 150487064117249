import { useRef, useState } from 'react';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

import { GenericParam } from '../common/interfaces/commons';

interface Props {
  applyBlackWhite?: boolean | undefined;
}

export const useDragger = (props: Props) => {
  const { applyBlackWhite } = props;

  const [imageName, setImageName] = useState('');
  const [voucherWidth, setVoucherWidth] = useState(0);
  const [voucherHeight, setVoucherHeight] = useState(0);
  const [reducedImage, setReducedImage] = useState('');

  const [imageUrl, setImageUrl] = useState('');
  const [loadingImage, setLoadingImage] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const reduceSizeImage = (file: File) => {
    const fileName = file.name;
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      let reducedValue = 0.9;

      if (width > 1600 || height >= 1600) {
        reducedValue = 0.3;
      } else if (width >= 1000 || height >= 1000) {
        reducedValue = 0.5;
      } else if (width >= 600 || height >= 600) {
        reducedValue = 0.7;
      }

      setVoucherWidth(width * reducedValue);
      setVoucherHeight(height * reducedValue);
      ctx.imageSmoothingQuality = 'low';

      ctx.drawImage(img, 0, 0, width * reducedValue, height * reducedValue);
      const imgData = ctx.getImageData(0, 0, width, height);

      if (applyBlackWhite) {
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const i = ((y * 4) * width) + (x * 4);
            const avg = (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3;
            imgData.data[i] = avg;
            imgData.data[i + 1] = avg;
            imgData.data[i + 2] = avg;
          }
        }
      }

      ctx.putImageData(imgData, 0, 0, 0, 0, imgData.width, imgData.height);

      const image = canvas.toDataURL('image/*', 0.1);
      setReducedImage(image);
      setImageName(fileName);
    };

    img.src = URL.createObjectURL(file);
  };

  function getBase64 (img: RcFile, callback: Function) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const handleOnChange = (info: UploadChangeParam<UploadFile<GenericParam>>) => {
    if (info.file.status === 'uploading') {
      setLoadingImage(true);
      return;
    }
    if (info.file.status === 'done' && info.file.originFileObj) {
      reduceSizeImage(info.file.originFileObj);
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setImageUrl(imageUrl);
        setLoadingImage(false);
      });
    }
  };

  const handleCustomRequest = (options: GenericParam) => {
    setTimeout(() => {
      options.onSuccess('ok');
    }, 0);
  };

  const cleanImage = () => {
    setReducedImage('');
    setImageUrl('');
  };

  return {
    imageName,
    voucherWidth,
    voucherHeight,
    reducedImage,
    imageUrl,
    loadingImage,
    canvasRef,
    handleOnChange,
    handleCustomRequest,
    cleanImage,
  };
};
