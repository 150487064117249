import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { sortByName } from '../../utils/sort';
import { unitStatuses } from '../../config/commons';
import { Store } from '../../state/store.interfaces';
import Icon from '../../common/components/Icon/Icon';
import { LrvForm } from '../../common/components/LrvForm/LrvForm';
import TinyLabel from '../../common/components/TinyLabel/TinyLabel';
import { LrvModal } from '../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../common/components/LrvInput/LrvInput';
import { LrvSelect } from '../../common/components/LrvSelect/LrvSelect';
import { Stocking, Container, Module, Campus } from '../Sowings/interfaces';

import styles from './MoveAnalysis.module.scss';
import * as stockingSlice from './sowingSlice';
import './Sowing.scss';

interface Props {
  theme?: 'dark' | 'light';
}

function MoveAnalysis (props: Props) {
  const { theme = 'dark' } = props;

  const { Option } = Select;

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const [newStocking, setNewStocking] = useState('');
  const [newTank, setNewTank] = useState('');
  const [newModule, setNewModule] = useState('');
  const [newCampus, setNewCampus] = useState('');
  const [disabledButtonForm, setDisabledButtonForm] = useState(true);

  const selectedStocking = useSelector((state: Store) => state.stockings.selectedStocking);
  const isLoading = useSelector((state: Store) => state.analysis.moveAnalysis.isLoading);
  const analysisId = useSelector((state: Store) => state.analysis.moveAnalysis.analysisId);
  const currentPage = useSelector((state: Store) => state.analysis.currentPage);
  const currentCampus = useSelector((state: Store) => state.analysis.moveAnalysis.selectedCampus);
  const currentModule = useSelector((state: Store) => state.analysis.moveAnalysis.selectedModule);
  const currentTank = useSelector((state: Store) => state.analysis.moveAnalysis.selectedContainer);
  const currentStocking = useSelector((state: Store) => state.analysis.moveAnalysis.selectedStocking);

  const showModal = useSelector((state: Store) => state.analysis.moveAnalysis.showModal);
  const campuses = useSelector((state: Store) => state.analysis.moveAnalysis.campuses);
  const modules = useSelector((state: Store) => state.analysis.moveAnalysis.modules);
  const containers = useSelector((state: Store) => state.analysis.moveAnalysis.containers);
  const stockings = useSelector((state: Store) => state.analysis.moveAnalysis.stockings);

  const isCampusInactive = selectedStocking.campusId.status === unitStatuses.INACTIVE;

  useEffect(() => {
    if (currentCampus._id) {
      setNewCampus(currentCampus._id);
    }

    if (currentModule._id) {
      setNewModule(currentModule._id);
    }

    if (currentTank._id) {
      setNewTank(currentTank._id);
    }
  }, [dispatch, currentCampus._id, currentModule._id, currentTank._id]);

  useEffect(() => {
    if (isCampusInactive) {
      setDisabledButtonForm(true);
    }
  }, [isCampusInactive]);

  const campusData = campuses ? campuses.map((campus: Campus, index) => {
    return {
      key: index,
      _id: campus._id,
      name: campus.name,
    };
  }) : [];

  const modulesData = modules ? modules.map((module: Module, index) => {
    return {
      key: index,
      _id: module._id,
      name: module.name,
      phaseType: module.phaseType,
    };
  }) : [];

  const containersData = containers ? containers.map((tank: Container, index) => {
    return {
      key: index,
      _id: tank._id,
      name: tank.name,
    };
  }) : [];

  const stockingsData = stockings ? stockings.map((stocking: Stocking, index) => {
    return {
      key: index,
      _id: stocking._id,
      name: stocking.name,
    };
  }) : [];

  const childrenCampus: JSX.Element[] = [];
  campusData.sort(sortByName);
  for (let i = 0; i < campusData.length; i++) {
    childrenCampus.push(<Option key={campusData[i]._id} value={campusData[i]._id}>{campusData[i].name}</Option>);
  }

  const childrenModulesMove: JSX.Element[] = [];
  modulesData.sort(sortByName);
  for (let i = 0; i < modulesData.length; i++) {
    if (selectedStocking.phaseType === modulesData[i].phaseType) {
      childrenModulesMove.push(<Option key={modulesData[i]._id} value={modulesData[i]._id}>{modulesData[i].name}</Option>);
    }
  }

  const childrenTanksMove: JSX.Element[] = [];
  containersData.sort(sortByName);
  for (let i = 0; i < containersData.length; i++) {
    childrenTanksMove.push(<Option key={containersData[i]._id} value={containersData[i]._id}>{containersData[i].name}</Option>);
  }

  const childrenStockingsMove: JSX.Element[] = [];
  stockingsData.sort(sortByName);
  for (let i = 0; i < stockingsData.length; i++) {
    childrenStockingsMove.push(<Option key={stockingsData[i]._id} value={stockingsData[i]._id}>{stockingsData[i].name}</Option>);
  }

  function validateAnalysisMovement () {
    return newCampus && newModule && newTank && newStocking;
  }

  function resetForm () {
    dispatch(stockingSlice.setSelectedCampus({ _id: '', name: '' }));
    dispatch(stockingSlice.setSelectedModule({ _id: '', name: '' }));
    dispatch(stockingSlice.setSelectedContainer({ _id: '', name: '' }));
    dispatch(stockingSlice.setSelectedStocking({ _id: '', name: '' }));
  }

  function renderLabelCampusInactive () {
    if (isCampusInactive) {
      return (
        <Form.Item>
          <TinyLabel className={styles.campusInactive} text={t('analysis.inactiveCampus')} />
        </Form.Item>
      );
    }

    return null;
  }

  return <LrvModal
    theme={theme}
    title={t('analysis.move.title')}
    open={showModal}
    destroyOnClose={true}
    okButtonProps={{ id: 'submit_move_analysis', htmlType: 'submit', form: 'formMoveAnalysis', loading: isLoading, disabled: disabledButtonForm }}
    cancelButtonProps={{ id: 'cancel_move_analysis' }}
    onOk={() => {
      const paramsFetchAnalysis = { stockingId: currentStocking._id, page: currentPage };

      if (validateAnalysisMovement()) {
        const body = { campusId: newCampus, moduleId: newModule, tankId: newTank, sowingId: newStocking };
        dispatch(stockingSlice.moveAnalysis({ companyId: selectedStocking.companyId, analysisId, body, paramsFetchAnalysis }));
      }
    }}
    okText={t('analysis.move.accept')}
    cancelText={t('analysis.move.cancel').toUpperCase()}
    onCancel={() => {
      setNewCampus('');
      setNewModule('');
      setNewTank('');
      setNewStocking('');

      resetForm();
      dispatch(stockingSlice.setShowModalMove(false));
    }}
    width={760}
  >
    <LrvForm
      theme={theme}
      layout='vertical'
    >
      <div className={styles.parent}>

        <Form.Item className={styles.item}>
          <div className={styles.row}>
            <LrvInput
              theme={theme}
              value={currentCampus.name}
              readOnly
            />

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <LrvInput
              theme={theme}
              value={currentModule.name}
              readOnly
            />

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <LrvInput
              theme={theme}
              value={currentTank.name}
              readOnly
            />

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <LrvInput
              theme={theme}
              value={currentStocking.name}
              readOnly
            />
          </div>
        </Form.Item>

        <Form.Item className={styles.item}>
          <div className={styles.row}>
            <div id='dropdown_campus_move' className={styles.select}>
              <LrvSelect
                theme={theme}
                showSearch
                value={newCampus}
                disabled={isCampusInactive}
                onChange={(value) => {
                  setNewCampus(value);
                  setNewModule('');
                  setNewTank('');
                  setNewStocking('');

                  dispatch(stockingSlice.fetchModules(value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) => {
                  const children = `${option?.children || ''}`;
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {childrenCampus}
              </LrvSelect>
            </div>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <div id='dropdown_modules_move' className={styles.select}>
              <LrvSelect
                theme={theme}
                showSearch
                value={newModule}
                onChange={(value) => {
                  setNewModule(value);
                  setNewTank('');
                  setNewStocking('');

                  dispatch(stockingSlice.fetchContainers(newCampus, value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newCampus || isCampusInactive}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) => {
                  const children = `${option?.children || ''}`;
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {childrenModulesMove}
              </LrvSelect>
            </div>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <div id='dropdown_tanks_move' className={styles.select}>
              <LrvSelect
                theme={theme}
                showSearch
                value={newTank}
                onChange={(value) => {
                  setNewStocking('');
                  setNewTank(value);

                  dispatch(stockingSlice.fetchStockings(newCampus, newModule, value));
                  setDisabledButtonForm(true);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newModule || isCampusInactive}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) => {
                  const children = `${option?.children || ''}`;
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {childrenTanksMove}
              </LrvSelect>
            </div>

            <div className={styles.icon} >
              <Icon name='arrow-right-s' theme='light' />
            </div>

            <div id='dropdown_stockings_move' className={styles.select}>
              <LrvSelect
                theme={theme}
                showSearch
                value={newStocking}
                onChange={(value) => {
                  setNewStocking(value);
                  const disabled = !(newCampus && newModule && newTank && value) || isCampusInactive;
                  setDisabledButtonForm(disabled);
                }}
                suffixIcon={<Icon name='arrow-down-s' />}
                disabled={!newTank || isCampusInactive}
                dropdownMatchSelectWidth={false}
                filterOption={(input, option) => {
                  const children = `${option?.children || ''}`;
                  return children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {childrenStockingsMove}
              </LrvSelect>
            </div>
          </div>
        </Form.Item>

        {renderLabelCampusInactive()}

        <div className={styles.child}>
          <ul className={styles.tree}>
            <li> {t('stockings.origin')} </li>
            <li className={styles.last}> {t('stockings.destination')} </li>
          </ul>
        </div>
      </div>
    </LrvForm>
  </LrvModal>;
}

export default MoveAnalysis;
