import Rollbar from 'rollbar';

import { GenericParam } from '../common/interfaces/commons';

import { ENVIRONMENT } from './config.api';
import { environments, getPayloadToken } from './commons';

const errorsToIgnore = [
  'ResizeObserver loop completed with undelivered notifications.'
];

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: ENVIRONMENT,
  checkIgnore (isUncaught, args, item) {
    // To check errors to ignore
    const body: GenericParam = item?.body as GenericParam;
    const errorDescription = body?.trace?.exception?.description;
    const isAnErrorToIgnore = errorsToIgnore.some(error => error.includes(errorDescription));

    // To only send notifications when ENVIRONMENT value is 'prod'
    const isProdEnvironment = ENVIRONMENT === environments.PROD;

    // Ignore errors if the condition is true
    return !isProdEnvironment || (isProdEnvironment && isAnErrorToIgnore);
  },
  payload: {
    payloadAccessToken: getPayloadToken(),
  },
};