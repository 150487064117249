import cx from 'classnames';
import { Popconfirm } from 'antd';

import { LrvPopconfirmProps } from '../../interfaces/lrvPopconfirm';

import styles from './LrvPopconfirm.module.scss';

export const LrvPopconfirm = (props: LrvPopconfirmProps) => {
  const { className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkPopconfirm : styles.lightPopconfirm, className),
  };

  return (
    <Popconfirm {...lrvProps}>
    </Popconfirm>
  );
};
