import moment from 'moment';
import i18next from 'i18next';
import { AxiosResponse } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { uploadFileToS3 } from '../../utils/upload_s3';
import { PAYPHONE_AUTHORIZATION_TOKEN } from '../../config/payments';
import { axiosClient as axios, ApiClient } from '../../utils/axios_instance';
import { openSuccessNotification, openErrorNotification } from '../../common/notification/Notification';
import { paginationPayments, plansTypes, IVA, convertDataURLtoFile, bucketName, methodPresignedUrl, typeErrorPayment } from '../../config/commons';
import { PAYMENTS_URL, HISTORICAL_COMPANY_BALANCES_URL, PLANS_URL, PAYPHONE_BUTTON_PREPARE_URL, PAYPHONE_BUTTON_CONFIRM_URL, PAYMENTS_SECTION_URL, GENERATE_PRESIGNED_URL, COMPANY_BALANCES_URL, s3BucketURL, ENVIRONMENT, POSTPAID_TRANSFER_URL, POSTPAID_CSV_PAYMENT_URL } from '../../config/config.api';
import { getUserSession } from '../../utils/userSession';

import { PaymentState, PaymentPayload, CompanyPlan, Payphone, PresignedPostURL, CompanyBalance, PostpaidTransfer, PostpaidCsv, DetailDistributorCompany } from './interfaces';

const STATUS_CODE = {
  CANCELED: 2,
  APPROVED: 3,
};

const initialState: PaymentState = {
  isLoading: false,
  currentPage: 1,
  list: [],
  total: 0,
  skip: 0,
  limit: 0,
  paymentSelected: {
    _id: '',
    amount: 0,
    orderId: '',
    transactionId: '',
    companyPayerId: '',
    companyName: '',
    userPayerId: '',
    userPayer: {
      email: '',
      firstName: '',
      lastName: '',
    },
    type: '',
    paymentDate: '',
    comment: '',
    createdAt: '',
    updatedAt: '',
  },
  plans: [],
  showPostpaidCsvModal: false,
  showPostpaidTransferModal: false,
  showPrepaidPaymentModal: false,
  companyPlans: [],
  isLoadingCompanyPlans: false,
  checkingBalanceAvailable: false,
  typeErrorBalance: '',
  payphone: {
    payWithCard: '',
    payWithPayPhone: '',
    paymentId: '',
  },
  isLoadingCreatePayment: false,
  existsPaymentInTheSelectedMonth: true,
  isLoadingPostpaidPayment: false,
  isLoadingPostpaidCsvPayment: false,
  detailDistributorCompany: [],
  discountPercentage: 0,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments: (state: PaymentState, action: PayloadAction<PaymentPayload>) => {
      state.list = action.payload.data;
      state.limit = action.payload.limit;
      state.skip = action.payload.skip;
      state.total = action.payload.total;
    },
    setIsLoading: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShowPostpaidCsvModal: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.showPostpaidCsvModal = action.payload;
    },
    setShowPostpaidTransferModal: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.showPostpaidTransferModal = action.payload;
    },
    setShowPrepaidPaymentModal: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.showPrepaidPaymentModal = action.payload;
    },
    setCurrentPage: (state: PaymentState, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setCompanyPlans: (state: PaymentState, action: PayloadAction<CompanyPlan[]>) => {
      state.companyPlans = action.payload;
    },
    setIsLoadingCompanyPlans: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingCompanyPlans = action.payload;
    },
    setPlans: (state: PaymentState, action: PayloadAction<[]>) => {
      state.plans = action.payload;
    },
    setCheckingBalanceAvailable: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.checkingBalanceAvailable = action.payload;
    },
    setTypeErrorBalance: (state: PaymentState, action: PayloadAction<string>) => {
      state.typeErrorBalance = action.payload;
    },
    setPayphone: (state: PaymentState, action: PayloadAction<Payphone>) => {
      state.payphone.payWithCard = action.payload.payWithCard;
      state.payphone.payWithPayPhone = action.payload.payWithPayPhone;
      state.payphone.paymentId = action.payload.paymentId;
    },
    setIsLoadingCreatePayment: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingCreatePayment = action.payload;
    },
    setExistsPaymentInTheSelectedMonth: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.existsPaymentInTheSelectedMonth = action.payload;
    },
    setIsLoadingPostpaidTransfer: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingPostpaidPayment = action.payload;
    },
    setIsLoadingPostpaidCsvTransfer: (state: PaymentState, action: PayloadAction<boolean>) => {
      state.isLoadingPostpaidCsvPayment = action.payload;
    },
    setDetailDistributorCompany: (state: PaymentState, action: PayloadAction<DetailDistributorCompany[]>) => {
      state.detailDistributorCompany = action.payload;
    },
    setDiscountPercentage: (state: PaymentState, action: PayloadAction<number>) => {
      state.discountPercentage = action.payload;
    },
  },
});

export const {
  setPayments,
  setIsLoading,
  setShowPostpaidCsvModal,
  setShowPostpaidTransferModal,
  setShowPrepaidPaymentModal,
  setCurrentPage,
  setCompanyPlans,
  setIsLoadingCompanyPlans,
  setPlans,
  setCheckingBalanceAvailable,
  setTypeErrorBalance,
  setPayphone,
  setIsLoadingCreatePayment,
  setExistsPaymentInTheSelectedMonth,
  setIsLoadingPostpaidTransfer,
  setIsLoadingPostpaidCsvTransfer,
  setDetailDistributorCompany,
  setDiscountPercentage,
} = paymentsSlice.actions;

const showNotification = (existsPayment: boolean) => {
  if (existsPayment) {
    openErrorNotification(i18next.t('payments.errorPaymentMade'));
    return;
  }
};

export const checkPayments = (props: { companyId: string; months: string[] }) => async (dispatch: Function) => {
  const { companyId, months } = props;

  for (let index = 0; index < months.length; index++) {
    const paymentDate = months[index];

    const startDate = moment(paymentDate).utc().startOf('month').toISOString();
    const endDate = moment(paymentDate).utc().endOf('month').toISOString();

    const params = {
      companyPayerId: companyId,
      startDate,
      endDate,
      type: plansTypes.POSTPAID,
      $select: ['_id'],
    };

    try {
      const response = await axios.get(PAYMENTS_URL, { params: params });
      const total = response.data?.total || 0;
      const existsPayment = total > 0;
      dispatch(setExistsPaymentInTheSelectedMonth(existsPayment));
      showNotification(existsPayment);
    } catch (error) {
      console.log(error?.response);
    }
  }
};

export const fetchPayments = (props: { page: number; companyId: string }) => async (dispatch: Function) => {
  const { page, companyId } = props;

  let skip = 0;
  dispatch(setIsLoading(true));

  if (page !== 0) {
    skip = paginationPayments * (page - 1);
  }

  const params = {
    '$limit': paginationPayments.toString(),
    '$skip': skip.toString(),
    'companyPayerId': companyId,
    '$sort[paymentDate]': '-1',
  };

  try {
    const response = await axios.get(PAYMENTS_URL, { params: params });
    dispatch(setPayments(response.data));
  } catch (error) {
    console.log(error?.response);
  }

  dispatch(setIsLoading(false));
};

const getBodyCreatePayment = (
  props: {
    price: number; quota: number; paymentDate: string; type: string;
    orderId?: string; clientTransactionId?: string; comment?: string;
    isTransfer?: boolean; url?: string; key?: string;
    userPayerId: string; companyPayerId: string;
  }
) => {
  const {
    orderId, clientTransactionId, price,
    quota = 0, paymentDate, type, comment,
    isTransfer, url, key,
    userPayerId, companyPayerId,
  } = props;

  const body = {
    orderId,
    clientTransactionId,
    type,
    amount: price,
    quota,
    userPayerId,
    companyPayerId,
    paymentDate,
    comment,
    isTransfer,
    image: {
      url: url,
      key: key,
    }
  };

  return body;
};

const createPayment = (
  props: {
    prices: number[]; quota: number; months: string[]; type: string;
    orderId?: string; clientTransactionId?: string; comment?: string;
    isTransfer?: boolean; url?: string; key?: string;
  }
) => async (dispatch: Function) => {
  const {
    orderId, clientTransactionId, prices,
    quota = 0, months, type, comment,
    isTransfer, url, key,
  } = props;

  const userSession = getUserSession();
  const companyPayerId = userSession.companyId;

  if (!months.length) {
    return;
  }

  try {
    for (let index = 0; index < months.length; index++) {
      const paymentDate = months[index];
      const price = prices[index];

      const body = getBodyCreatePayment({ orderId, clientTransactionId, price, quota, paymentDate, type, comment, isTransfer, url, key, userPayerId: userSession._id, companyPayerId });
      await axios.post(PAYMENTS_URL, body);
    }

    dispatch(fetchPayments({ page: 0, companyId: companyPayerId }));

    if (type === plansTypes.POSTPAID) {
      dispatch(fetchCompanyPlans({ companyId: companyPayerId, months }));
    } else if (type === plansTypes.PREPAID && !isTransfer) {
      dispatch(setShowPrepaidPaymentModal(false));
    }

    if (isTransfer) {
      dispatch(setIsLoadingCreatePayment(false));
      openSuccessNotification(i18next.t('payments.activatedBalance'));
    }

  } catch (error) {
    console.log(error?.response);
  }
};

const fetchCompanyBalance = async (companyId: string): Promise<CompanyPlan> => {
  const params = {
    $limit: -1,
    companyId,
    $select: ['quota', 'bonusQuota', 'planPrice', 'planPriceOverride'],
  };

  const response = await axios.get<CompanyBalance[]>(COMPANY_BALANCES_URL, { params });
  const companyBalance = response.data[0];

  const companyPlan: CompanyPlan = {
    quota: companyBalance?.quota || 0,
    bonusQuota: companyBalance?.bonusQuota || 0,
    planPrice: companyBalance?.planPrice || 0,
    planPriceOverride: companyBalance?.planPriceOverride || 0,
  };
  return companyPlan;
};

const fetchHistoricalCompanyBalance = async (props: { companyId: string; paymentDate: string }): Promise<CompanyPlan> => {
  const { companyId, paymentDate } = props;

  const params = {
    companyId,
    startDate: moment(paymentDate).utc().startOf('month').toISOString(),
    endDate: moment(paymentDate).utc().endOf('month').toISOString(),
    $select: ['quota', 'bonusQuota', 'planPrice', 'planPriceOverride'],
  };

  const response = await axios.get(HISTORICAL_COMPANY_BALANCES_URL, { params });
  const historicalCompanyBalance: CompanyBalance = response.data?.data[0];

  if (!historicalCompanyBalance) {
    openErrorNotification(i18next.t('payments.errorSelectedMonth'));
  }

  const companyPlan: CompanyPlan = {
    quota: historicalCompanyBalance?.quota || 0,
    bonusQuota: historicalCompanyBalance?.bonusQuota || 0,
    planPrice: historicalCompanyBalance?.planPrice || 0,
    planPriceOverride: historicalCompanyBalance?.planPriceOverride || 0,
  };

  return companyPlan;
};

export const fetchCompanyPlan = (props: { companyId: string; paymentDate: string }) => async (dispatch: Function) => {
  const { companyId, paymentDate } = props;
  const companyPlans: CompanyPlan[] = [];

  const currentMonth = moment().month();
  const paymentMonth = moment(paymentDate).month();
  const currentYear = moment().year();
  const paymentYear = moment(paymentDate).year();

  dispatch(setIsLoadingCompanyPlans(true));

  try {
    if ((paymentMonth === currentMonth || paymentMonth === currentMonth + 1) && currentYear === paymentYear) {
      const companyPlan = await fetchCompanyBalance(companyId);
      companyPlans.push(companyPlan);
      dispatch(setCompanyPlans(companyPlans));
      dispatch(setIsLoadingCompanyPlans(false));
      return;
    }

    const companyPlan = await fetchHistoricalCompanyBalance({ companyId, paymentDate });
    companyPlans.push(companyPlan);
    dispatch(setCompanyPlans(companyPlans));
    dispatch(setIsLoadingCompanyPlans(false));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCompanyPlans = (props: { companyId: string; months: string[] }) => async (dispatch: Function) => {
  const { companyId, months } = props;
  const companyPlans: CompanyPlan[] = [];

  dispatch(setIsLoadingCompanyPlans(true));

  for (let index = 0; index < months.length; index++) {
    const paymentDate = months[index];

    const currentMonth = moment().month();
    const paymentMonth = moment(paymentDate).month();
    const currentYear = moment().year();
    const paymentYear = moment(paymentDate).year();

    try {
      // eslint-disable-next-line
      if ((paymentMonth === currentMonth || paymentMonth === currentMonth + 1) && currentYear === paymentYear) {
        const companyPlan = await fetchCompanyBalance(companyId);
        companyPlans.push(companyPlan);
        continue;
      }

      const companyPlan = await fetchHistoricalCompanyBalance({ companyId, paymentDate });
      companyPlans.push(companyPlan);
    } catch (error) {
      console.log(error?.response);
    }
  }

  dispatch(setCompanyPlans(companyPlans));
  dispatch(setIsLoadingCompanyPlans(false));
};

export const fetchPlans = () => async (dispatch: Function) => {
  const params = {
    '$limit': -1,
    '$sort[value]': 1,
    'type': plansTypes.PREPAID,
  };

  try {
    const response = await axios.get(PLANS_URL, { params });
    dispatch(setPlans(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchCheckBalance = () => async (dispatch: Function) => {
  dispatch(setCheckingBalanceAvailable(true));
  dispatch(setTypeErrorBalance(''));

  try {
    await axios.get(`${PAYMENTS_URL}/check/balance-available`);
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.data?.data) {
      dispatch(setTypeErrorBalance(error?.response?.data?.data?.error));
    }
  }
  dispatch(setCheckingBalanceAvailable(false));
};

export const fetchButtonPrepare = (params: { amount: number }) => async (dispatch: Function) => {
  const { amount } = params;
  const apiClient = new ApiClient(false);
  const date = new Date();

  const amountWithTax = Math.round(amount / IVA);
  const tax = amount - amountWithTax;

  dispatch(setPayphone(initialState.payphone));

  const body = {
    'responseUrl': PAYMENTS_SECTION_URL,
    'cancellationUrl': PAYMENTS_SECTION_URL,
    'amount': amount,
    'amountWithTax': amountWithTax,
    'amountWithoutTax': 0,
    'tax': tax,
    'service': 0,
    'tip': 0,
    'clientTransactionId': date.getTime(),
    'currency': 'USD',
  };

  try {
    const response = await apiClient.axios.post(PAYPHONE_BUTTON_PREPARE_URL, body, {
      headers: {
        Authorization: PAYPHONE_AUTHORIZATION_TOKEN,
      }
    });

    dispatch(setPayphone(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchConfirm = (
  params: { id: string; clientTransactionId: string; paymentType: string; months: string[]; prices: number[]; quota: number }
) => async (dispatch: Function) => {
  const { id, clientTransactionId, paymentType, months, prices, quota } = params;
  const apiClient = new ApiClient(false);

  const body = {
    'id': id,
    'clientTxId': clientTransactionId,
  };

  try {
    const response = await apiClient.axios.post(PAYPHONE_BUTTON_CONFIRM_URL, body, {
      headers: {
        Authorization: PAYPHONE_AUTHORIZATION_TOKEN,
      }
    });

    if (response.data && response.data.statusCode === STATUS_CODE.APPROVED) {
      openSuccessNotification(i18next.t('payments.approved'));
      const comment = response.data.reference;

      const params = { orderId: id, clientTransactionId, prices, quota, months, comment, type: paymentType };
      dispatch(createPayment(params));
    } else {
      openErrorNotification(i18next.t('payments.declined'));
    }
  } catch (error) {
    console.log(error?.response);
  }
};

export const createPrepaidTransfer = (
  props: {
    prices: number[]; quota: number; months: string[]; type: string;
    orderId?: string; clientTransactionId?: string; comment?: string;
    dataUrl: string; key: string; url?: string; isTransfer?: boolean;
  }
) => async (dispatch: Function) => {
  const { dataUrl, key } = props;
  dispatch(setIsLoadingCreatePayment(true));
  const file = convertDataURLtoFile(dataUrl, key);

  const body = {
    'objectKey': key,
    'bucketName': bucketName.PREPAID_VOUCHERS,
    'methodPresignedUrl': methodPresignedUrl.POST,
  };

  let presignedURL: AxiosResponse<PresignedPostURL>;

  try {
    presignedURL = await axios.post<PresignedPostURL>(GENERATE_PRESIGNED_URL, body);
  } catch (error) {
    console.log(error?.response);
    return;
  }

  try {
    await uploadFileToS3(presignedURL.data, file);

    const url = s3BucketURL[ENVIRONMENT].VOUCHER_PREPAID + key;
    props.isTransfer = true;
    props.url = url;
  } catch (error) {
    console.log(error?.response);
    console.error('Error when trying to upload the invoice image');
  }

  dispatch(createPayment(props));
};

export const savePostpaidTransfer = (props: { key: string; dataUrl: string; amounts: number[]; months: string[]; companyId: string; userId: string; }) => async (dispatch: Function) => {
  const { amounts, dataUrl, key, months, companyId, userId } = props;
  dispatch(setIsLoadingPostpaidTransfer(true));
  const url = await uploadPostpaidVoucher({ key, dataUrl });

  if (!url) {
    return;
  }

  const transfers: PostpaidTransfer[] = [];
  for (let index = 0; index < months.length; index++) {
    const month = months[index];
    const amount = amounts[index];

    const transfer: PostpaidTransfer = {
      amount,
      companyId,
      userId,
      paymentDate: month,
      image: {
        key,
        url,
      },
    };

    transfers.push(transfer);
  }

  dispatch(createPostpaidTransfer({ transfers, companyId }));
};

const uploadPostpaidVoucher = async (props: { key: string; dataUrl: string; }) => {
  const { dataUrl, key } = props;

  const body = {
    'objectKey': key,
    'bucketName': bucketName.POSTPAID_VOUCHERS,
    'methodPresignedUrl': methodPresignedUrl.POST,
  };

  let presignedURL: AxiosResponse<PresignedPostURL>;

  try {
    presignedURL = await axios.post<PresignedPostURL>(GENERATE_PRESIGNED_URL, body);
  } catch (error) {
    console.log(error?.response);
    return;
  }

  const file = convertDataURLtoFile(dataUrl, key);

  try {
    await uploadFileToS3(presignedURL.data, file);
    return s3BucketURL[ENVIRONMENT].VOUCHER_POSTPAID + key;
  } catch (error) {
    console.log(error?.response);
    console.error('Error when trying to upload the invoice image');
  }
};

const createPostpaidTransfer = (props: { transfers: PostpaidTransfer[]; companyId: string; }) => async (dispatch: Function) => {
  const { transfers, companyId } = props;
  const body = { transfers };

  try {
    await axios.post(POSTPAID_TRANSFER_URL, body);
    dispatch(fetchPayments({ page: 0, companyId }));

    openSuccessNotification(i18next.t('payments.postpaidTransferSuccessfully'));
    dispatch(setShowPostpaidTransferModal(false));
    dispatch(setIsLoadingPostpaidTransfer(false));
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.data?.data) {
      dispatch(setIsLoadingPostpaidTransfer(false));
      showErrorPayment(error?.response?.data?.data?.error);
    }
  }
};

export const createPostpaidCsvPayment = (props: { payments: PostpaidCsv[] }) => async (dispatch: Function) => {
  const { payments } = props;
  const body = { payments };
  dispatch(setIsLoadingPostpaidCsvTransfer(true));

  try {
    await axios.post(POSTPAID_CSV_PAYMENT_URL, body);

    openSuccessNotification(i18next.t('payments.postpaidTransferSuccessfully'));
    dispatch(setShowPostpaidCsvModal(false));
    dispatch(setIsLoadingPostpaidCsvTransfer(false));
  } catch (error) {
    console.log(error?.response);
    if (error?.response?.data?.data) {
      dispatch(setIsLoadingPostpaidCsvTransfer(false));
      showErrorPayment(error?.response?.data?.data?.error);
    }
  }
};

export const fetchDiscountPorcentageCompanyBalance = (companyId: string) => async (dispatch: Function) => {
  const params = {
    $limit: -1,
    companyId,
    $select: ['discountPercentage'],
  };

  try {
    const response = await axios.get<CompanyBalance[]>(COMPANY_BALANCES_URL, { params });
    const discountPercentage = response.data[0].discountPercentage || 0;
    dispatch(setDiscountPercentage(discountPercentage));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchDetailDistributorCompany = (props: { companyId: string; months: string[] }) => async (dispatch: Function) => {
  const { companyId, months } = props;
  const detailDistributorCompany: DetailDistributorCompany[] = [];

  for (let index = 0; index < months.length; index++) {
    const paymentDate = months[index];

    const currentMonth = moment().month();
    const paymentMonth = moment(paymentDate).month();
    const currentYear = moment().year();
    const paymentYear = moment(paymentDate).year();
    const params = { paymentDate };

    try {
      // eslint-disable-next-line
      if ((paymentMonth === currentMonth || paymentMonth === currentMonth + 1) && currentYear === paymentYear) {
        const url = `${COMPANY_BALANCES_URL}/${companyId}/detail-distributor`;
        const response = await axios.get<DetailDistributorCompany[]>(url, { params });
        detailDistributorCompany.push(...response.data);
        continue;
      }

      const url = `${HISTORICAL_COMPANY_BALANCES_URL}/${companyId}/detail-distributor`;
      const response = await axios.get<DetailDistributorCompany[]>(url, { params });
      detailDistributorCompany.push(...response.data);
    } catch (error) {
      console.log(error?.response);
    }
  }

  dispatch(setDetailDistributorCompany(detailDistributorCompany));
};

const showErrorPayment = (error: string) => {
  switch (error) {
    case typeErrorPayment.TRANSFER_ALREADY_REGISTERED:
      openErrorNotification(i18next.t('payments.transferAlreadyRegistered'));
      break;

    case typeErrorPayment.WRONG_COMPANY_CODES:
      openErrorNotification(i18next.t('payments.wrongCompanyCodes'));
      break;
  }
};

export default paymentsSlice.reducer;
