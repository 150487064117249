import * as d3 from 'd3';
import cx from 'classnames';
import i18next from 'i18next';
import { Dispatch } from 'react';

import { formatLongDateWithOffset } from '../../../../utils/date';
import resetIconSvg from '../../../../assets/reset.svg';
import { GenericParam } from '../../../interfaces/commons';
import { roundTwoDecimals, THEME } from '../../../../config/commons';
import * as parameterChartSlice from '../../../../pages/Sowings/Parameters/parameterChartSlice';
import { allFrequencies, defaultLegendChart } from '../../../../pages/Sowings/Parameters/helpers';
import { StockingParameterValue, TooltipData, StockingParameterData } from '../../../../pages/Sowings/Parameters/interfaces';

import styles from './ParameterChartD3.module.scss';
import { getDateWithoutTime, getMaxY, getMinY, renderTickBottomFormat, generateDateArray, getColorParameterWithFrequencies, getAllData, getStockingChartLeftPosition, getLabelTooltip, renderCircle, renderRect, renderPlusIcon, getUnitTooltip, fillArea } from './helpers';
import { Area } from './interfaces';

let zoomDone = false;
let idleTimeout: NodeJS.Timeout | null;
let currentDateActive: string;

interface Props {
  container: HTMLDivElement | null;
  height: number;
  width: number;
  theme: string;
  minDate: Date;
  maxDate: Date;
  totalFeed: StockingParameterData;
  growthRates: StockingParameterData;
  parameterWithoutFrequencies: StockingParameterData;
  parameterWithFrequencies: { [key: string]: StockingParameterData };
  dispatch: Dispatch<GenericParam>;
  selectedParameter?: string;
}

let isLightTheme = true;

const TIME_TRANSITION = 300;
const DEFAULT_CIRCLE = 3;
const DEFAULT_CIRCLE_ACTIVE = DEFAULT_CIRCLE + 1;

class ParameterChartD3 {
  container: HTMLDivElement | null;
  svg: d3.Selection<SVGSVGElement, StockingParameterData, null, undefined>;
  groupMain: d3.Selection<SVGGElement, StockingParameterData, null, undefined>;

  scaleTimeX: d3.ScaleTime<number, number, never> = d3.scaleTime<number, number, never>();
  scaleLinearFeed: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  scaleLinearGrowthRate: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  scaleLinearParameterWithoutFrequencies: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();
  scaleLinearParameterWithFrequencies: d3.ScaleLinear<number, number, never> | d3.ScaleSymLog<number, number, never> = d3.scaleLinear();

  margin = { top: 24, right: 150, bottom: 24, left: 60 };
  tooltip: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));
  tooltipOption: d3.Selection<HTMLDivElement, unknown, null, undefined> = d3.select<HTMLDivElement, unknown>(document.createElement('div'));

  width: number;
  height: number;
  xAxis: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxisFeed: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxisGrowthDelta: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxisParameterWithoutFrequencies: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));
  yAxisParameterWithFrequencies: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  theme: string;
  minDate: Date;
  maxDate: Date;

  clip: d3.Selection<d3.BaseType, StockingParameterData, null, undefined> = d3.select<d3.BaseType, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'defs'));;
  brush: d3.BrushBehavior<StockingParameterData> = d3.brush();
  gClipPath: d3.Selection<SVGGElement, StockingParameterData, null, undefined> = d3.select<SVGGElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'g'));

  parameterWithoutFrequencies: StockingParameterData;
  parameterWithFrequencies: { [key: string]: StockingParameterData };
  totalFeed: StockingParameterData;
  growthRates: StockingParameterData;

  selectedParameter: string | undefined;
  hoveredParameter: string | undefined;
  hoveredFrequency: string | undefined;

  dispatch: Dispatch<GenericParam>;

  areaData: d3.Area<Area> = d3.area();
  pathArea: d3.Selection<SVGPathElement, StockingParameterData, null, undefined> = d3.select<SVGPathElement, StockingParameterData>(document.createElementNS('http://www.w3.org/2000/svg', 'path'));

  // eslint-disable-next-line
  constructor(props: Props) {
    const { container, height, width, minDate, maxDate, parameterWithoutFrequencies, parameterWithFrequencies, totalFeed, growthRates, dispatch, selectedParameter, theme } = props;

    this.container = container;
    this.minDate = minDate;
    this.maxDate = maxDate;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    this.totalFeed = totalFeed;
    this.growthRates = growthRates;
    this.parameterWithoutFrequencies = parameterWithoutFrequencies;
    this.parameterWithFrequencies = parameterWithFrequencies;
    this.dispatch = dispatch;
    this.selectedParameter = selectedParameter;
    this.theme = theme;

    d3.select(container).select('#tooltipParameterChart').remove();
    d3.select(container).select('svg').remove();

    isLightTheme = theme === THEME.LIGHT;

    this.svg = d3.select<HTMLDivElement | null, StockingParameterData>(container)
      .append('svg')
      .attr('id', 'svg')
      .attr('width', this.width + this.margin.left + this.margin.right)
      .attr('height', this.height + this.margin.top + this.margin.bottom);

    this.groupMain = this.svg
      .append('g')
      .attr('id', 'content')
      .attr('position', 'relative')
      .attr('transform', `translate( ${this.margin.left}, ${this.margin.top} )`);

    this.createBrushElement();

    this.createTooltip();
    this.renderTooltipOption();
    this.buildXAxis();
    this.buildFeedYAxix();
    this.buildGrowthRateYAxix();
    this.buildParameterWithoutFrequenciesYAxix();
    this.buildParameterWithFrequenciesYAxix();

    this.drawXAxis();
    this.drawFeedYAxis();
    this.drawGrowthRateYAxis();
    this.drawParameterWithoutFrequenciesYAxis();
    this.drawParameterWithFrequenciesYAxis();

    this.createGClipPathElement();
    this.renderTotalFeedArea();
    this.renderGrowthRatesLines();
    this.renderParameterWithoutFrequenciesLines();
    this.renderParameterWithFrequenciesLines();

    this.renderParameterWithoutFrequenciesPoints();
    this.renderParameterWithFrequenciesPoints();
    this.renderGrowthRatesPlus();
    this.renderGrowthRateValues();

    this.renderLineSelected();
    this.renderTriangle();
    this.renderTooltip();
  }

  createTooltip = () => {
    this.tooltip = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipParameterChart')
      .attr('class', styles.tooltip)
      .style('display', 'none')
      .on('mouseover', () => {
        this.tooltip.style('display', 'block');
        d3.select('#selectedTick').style('display', 'block');
        d3.selectAll(currentDateActive).attr('r', DEFAULT_CIRCLE_ACTIVE);
      });
  };

  renderTooltipOption = () => {
    d3.select(this.container).select('#tooltipOption').remove();

    this.tooltipOption = d3.select(this.container)
      .append('div')
      .attr('id', 'tooltipOption')
      .attr('class', styles.tooltipOption);
  };

  createBrushElement = () => {
    const { height, width } = this;

    this.brush = d3.brush<StockingParameterData>()
      .extent([[0, 0], [width, height]])
      .on('end', (event) => this.onBrush(event));
  };

  createGClipPathElement = () => {
    const { groupMain, height, width } = this;

    this.clip = groupMain.append('defs')
      .attr('id', 'defs')
      .append('svg:clipPath')
      .attr('id', 'clip')
      .append('svg:rect')
      .attr('width', width)
      .attr('height', height)
      .attr('x', 0)
      .attr('y', 0);

    this.gClipPath = groupMain.append('g')
      .attr('id', 'gClipPath')
      .attr('clip-path', 'url(#clip)');

    this.renderResetBrush();
  };

  onBrush = (event: GenericParam) => {
    if (!event.sourceEvent || !event.selection) {
      const { minDate, maxDate } = this;

      if (!idleTimeout) {
        return idleTimeout = setTimeout(() => {
          idleTimeout = null;
        }, 350);
      }

      const feedY = this.getFeedYValues();
      const growthRateY = this.getGrowthRateYValues();
      const parameterWithoutFrequenciesY = this.getParameterWithoutFrequenciesYValues();
      const parameterWithFrequenciesY = this.getParameterWithFrequenciesYValues();

      this.resetBrush({ x0: minDate, x1: maxDate, feedY0: feedY.minYValue, feedY1: feedY.maxYValue, growthRateY0: growthRateY.minYValue, growthRateY1: growthRateY.maxYValue, parameterWithoutFrequenciesY0: parameterWithoutFrequenciesY.minYValue, parameterWithoutFrequenciesY1: parameterWithoutFrequenciesY.maxYValue, parameterWithFrequenciesY0: parameterWithFrequenciesY.minYValue, parameterWithFrequenciesY1: parameterWithFrequenciesY.maxYValue });
      return;
    }

    const [[x0, y0], [x1, y1]] = event.selection;
    this.applyBrush({ x0, y0, x1, y1 });
  }

  applyBrush = (props: { x0: Date; x1: Date; y0: number; y1: number; }) => {
    const { x0, x1, y0, y1 } = props;

    zoomDone = true;
    this.scaleTimeX.domain([x0, x1].map(this.scaleTimeX.invert));
    this.scaleLinearFeed.domain([y1, y0].map(this.scaleLinearFeed.invert));
    this.scaleLinearGrowthRate.domain([y1, y0].map(this.scaleLinearGrowthRate.invert));
    this.scaleLinearParameterWithoutFrequencies.domain([y1, y0].map(this.scaleLinearParameterWithoutFrequencies.invert));
    this.scaleLinearParameterWithFrequencies.domain([y1, y0].map(this.scaleLinearParameterWithFrequencies.invert));

    // eslint-disable-next-line
    this.gClipPath.select('.brush').call(this.brush.move as any, null);
    this.refreshBrush();
  }

  resetBrush = (props: {
    x0: Date;
    x1: Date;
    feedY0: number;
    feedY1: number;
    growthRateY0: number;
    growthRateY1: number;
    parameterWithoutFrequenciesY0: number;
    parameterWithoutFrequenciesY1: number;
    parameterWithFrequenciesY0: number;
    parameterWithFrequenciesY1: number;
  }) => {
    const {
      x0,
      x1,
      feedY0,
      feedY1,
      growthRateY0,
      growthRateY1,
      parameterWithoutFrequenciesY0,
      parameterWithoutFrequenciesY1,
      parameterWithFrequenciesY0,
      parameterWithFrequenciesY1,
    } = props;

    zoomDone = false;
    this.scaleTimeX.domain([getDateWithoutTime(new Date(x0)), getDateWithoutTime(new Date(x1))]);

    this.scaleLinearFeed.domain([feedY0, feedY1]);
    this.scaleLinearGrowthRate.domain([growthRateY0, growthRateY1]);
    this.scaleLinearParameterWithoutFrequencies.domain([parameterWithoutFrequenciesY0, parameterWithoutFrequenciesY1]);
    this.scaleLinearParameterWithFrequencies.domain([parameterWithFrequenciesY0, parameterWithFrequenciesY1]);

    this.refreshBrush();
    this.hideTooltipOption();
  }

  createScaleBand = (props: { dates: Date[]; }) => {
    const { dates } = props;
    const { width } = this;

    const scaleBandX = d3.scaleBand()
      .range([0, width])
      .domain(dates.map((d) => getDateWithoutTime(new Date(d)).toString()))
      .padding(0.6);

    return scaleBandX;
  };

  refreshBrush = () => {
    const { height } = this;

    const axisBottom = d3.axisBottom(this.scaleTimeX)
      .tickFormat(renderTickBottomFormat)
      .tickSize(-height)
      .tickValues(this.getTickValuesXAxis())
      .tickPadding(10);

    const axisLeft = d3.axisLeft(this.scaleLinearFeed)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    const axisGrowthRate = d3.axisRight(this.scaleLinearGrowthRate)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    const axisParameterWithoutFrequencies = d3.axisRight(this.scaleLinearParameterWithoutFrequencies)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    const axisParameterWithFrequencies = d3.axisRight(this.scaleLinearParameterWithFrequencies)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.xAxis
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisBottom);

    this.yAxisFeed
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisLeft);

    this.yAxisGrowthDelta
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisGrowthRate);

    this.yAxisParameterWithoutFrequencies
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisParameterWithoutFrequencies);

    this.yAxisParameterWithFrequencies
      .transition()
      .duration(TIME_TRANSITION)
      .call(axisParameterWithFrequencies);

    const area = this.fillAreaChart();
    this.createArea();

    const areaData = this.areaData(area);
    this.pathArea.transition()
      .duration(TIME_TRANSITION)
      .attr('class', cx('pathArea', isLightTheme ? styles.rectLight : styles.rectDark))
      .attr('d', areaData);

    const { line: growthRatesLines } = this.generateGrowthRatesLines();
    const { line: parameterWithoutFrequenciesLines } = this.generateParameterWithoutFrequenciesLines();
    const { line: parameterWithFrequenciesLines } = this.generateParameterWithFrequenciesLines();

    this.gClipPath
      .selectAll('.growthRateLines')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', growthRatesLines as any);

    this.gClipPath
      .selectAll('.growthRatesPoints')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('transform', (point: any) => `translate(${this.scaleTimeX(getDateWithoutTime(new Date(point.date)))}, ${this.scaleLinearGrowthRate(point.value)})`);

    this.gClipPath
      .selectAll('.parameterWithoutFrequenciesLines')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', parameterWithoutFrequenciesLines as any);

    this.gClipPath
      .selectAll('.parameterWithoutFrequenciesCircle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.scaleTimeX(getDateWithoutTime(new Date(point.date))))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.scaleLinearParameterWithoutFrequencies(point.value));

    this.gClipPath
      .selectAll('.parameterWithFrequenciesLines')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('d', parameterWithFrequenciesLines as any);

    this.gClipPath
      .selectAll('.parameterWithFrequenciesCircle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('cx', (point: any) => this.scaleTimeX(getDateWithoutTime(new Date(point.date))))
      // eslint-disable-next-line
      .attr('cy', (point: any) => this.scaleLinearParameterWithFrequencies(point.value));

    this.renderGrowthRateValues();
    this.renderResetBrush();
  }

  hideTooltipOption = () => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 0);
  };

  showTooltipOption = (marginRight: number) => {
    this.tooltipOption.transition()
      .duration(TIME_TRANSITION)
      .style('opacity', 1);

    this.tooltipOption.html(i18next.t('shadedplot.reset'))
      .style('right', (marginRight + 28) + 'px')
      .style('top', '-8px');
  };

  renderResetBrush = () => {
    const { margin, width, svg } = this;
    const marginLeft = width + margin.left - 8;

    svg.select('#resetBrushButton').remove();

    if (!zoomDone) {
      return;
    }

    svg
      .append('image')
      .attr('id', 'resetBrushButton')
      .attr('x', marginLeft)
      .attr('y', -1)
      .attr('xlink:href', resetIconSvg)
      .attr('width', 16)
      .attr('height', 16)
      .attr('cursor', 'pointer')
      .attr('filter', 'url(#background-color-filter)')
      .on('mouseover', () => this.showTooltipOption(margin.right))
      .on('mouseout', this.hideTooltipOption)
      .on('click', () => {
        const { minDate, maxDate } = this;
        const feedY = this.getFeedYValues();
        const growthRateY = this.getGrowthRateYValues();
        const parameterWithoutFrequenciesY = this.getParameterWithoutFrequenciesYValues();
        const parameterWithFrequenciesY = this.getParameterWithFrequenciesYValues();

        this.resetBrush({ x0: minDate, x1: maxDate, feedY0: feedY.minYValue, feedY1: feedY.maxYValue, growthRateY0: growthRateY.minYValue, growthRateY1: growthRateY.maxYValue, parameterWithoutFrequenciesY0: parameterWithoutFrequenciesY.minYValue, parameterWithoutFrequenciesY1: parameterWithoutFrequenciesY.maxYValue, parameterWithFrequenciesY0: parameterWithFrequenciesY.minYValue, parameterWithFrequenciesY1: parameterWithFrequenciesY.maxYValue });
      });

    svg
      .append('defs')
      .attr('id', 'defsBackgroundIcon')
      .append('filter')
      .attr('id', 'background-color-filter')
      .append('feFlood')
      .attr('flood-color', '#959595')
      .attr('result', 'backgroundColor');

    svg.select('#background-color-filter').append('feComposite')
      .attr('id', 'feCompositeBackgroundIcon')
      .attr('in', 'backgroundColor')
      .attr('in2', 'SourceGraphic')
      .attr('operator', 'atop');
  };

  getDefaultDomain = () => {
    const { minDate, maxDate } = this;
    return [getDateWithoutTime(new Date(minDate)), getDateWithoutTime(new Date(maxDate))];
  };

  buildXAxis () {
    const { width } = this;
    const defaultDomain = this.getDefaultDomain();

    this.scaleTimeX = d3.scaleTime()
      .domain(defaultDomain)
      .range([0, width]);
  }

  getFeedYValues = () => {
    const { minDate, maxDate, totalFeed } = this;
    const margin = 0.15;

    let minYValue = getMinY({ parameterData: totalFeed.values, minDate, maxDate });
    let maxYValue = getMaxY({ parameterData: totalFeed.values, minDate, maxDate });

    minYValue = minYValue - (minYValue * margin) < 0 ? 0 : minYValue - (minYValue * margin);
    maxYValue = maxYValue + (maxYValue * margin);

    return {
      maxYValue,
      minYValue,
    };
  };

  getGrowthRateYValues = () => {
    const { minDate, maxDate, growthRates } = this;
    const margin = 0.15;

    let minYValue = getMinY({ parameterData: growthRates.values, minDate, maxDate });
    let maxYValue = getMaxY({ parameterData: growthRates.values, minDate, maxDate });

    minYValue = minYValue - (minYValue * margin) < 0 ? 0 : minYValue - (minYValue * margin);
    maxYValue = maxYValue + (maxYValue * margin);

    return {
      maxYValue,
      minYValue,
    };
  };

  getParameterWithoutFrequenciesYValues = () => {
    const { minDate, maxDate, parameterWithoutFrequencies } = this;
    const margin = 0.15;

    let minYValue = getMinY({ parameterData: parameterWithoutFrequencies.values, minDate, maxDate });
    let maxYValue = getMaxY({ parameterData: parameterWithoutFrequencies.values, minDate, maxDate });

    minYValue = minYValue - (minYValue * margin) < 0 ? 0 : minYValue - (minYValue * margin);
    maxYValue = maxYValue + (maxYValue * margin);

    return {
      maxYValue,
      minYValue,
    };
  };

  getParameterWithFrequenciesYValues = () => {
    const { minDate, maxDate, parameterWithFrequencies } = this;
    const margin = 0.15;

    const parameterData: StockingParameterValue[] = [];

    Object.keys(parameterWithFrequencies).forEach((key) => {
      const item = parameterWithFrequencies[key];
      parameterData.push(...item.values);
    });

    let minYValue = getMinY({ parameterData: parameterData, minDate, maxDate });
    let maxYValue = getMaxY({ parameterData: parameterData, minDate, maxDate });

    minYValue = minYValue - (minYValue * margin) < 0 ? 0 : minYValue - (minYValue * margin);
    maxYValue = maxYValue + (maxYValue * margin);

    return {
      maxYValue,
      minYValue,
    };
  };

  buildFeedYAxix () {
    const { height } = this;
    const { maxYValue, minYValue } = this.getFeedYValues();

    this.scaleLinearFeed = d3.scaleLinear()
      .domain([minYValue, maxYValue])
      .range([height, 0]);
  }

  buildGrowthRateYAxix () {
    const { height } = this;
    const { maxYValue, minYValue } = this.getGrowthRateYValues();

    this.scaleLinearGrowthRate = d3.scaleLinear()
      .domain([minYValue, maxYValue])
      .range([height, 0]);
  }

  buildParameterWithoutFrequenciesYAxix () {
    const { height } = this;
    const { maxYValue, minYValue } = this.getParameterWithoutFrequenciesYValues();

    this.scaleLinearParameterWithoutFrequencies = d3.scaleLinear()
      .domain([minYValue, maxYValue])
      .range([height, 0]);
  }

  buildParameterWithFrequenciesYAxix () {
    const { height } = this;
    const { maxYValue, minYValue } = this.getParameterWithFrequenciesYValues();

    this.scaleLinearParameterWithFrequencies = d3.scaleLinear()
      .domain([minYValue, maxYValue])
      .range([height, 0]);
  }

  getTickValuesXAxis = () => {
    const defaultDomain = this.getDefaultDomain();
    const values = d3.timeDays(defaultDomain[0], defaultDomain[1]).filter(date => date.getDate() === 1 || date.getDate() === 15);
    return values;
  };

  drawXAxis () {
    const { height, groupMain } = this;

    const axisBottom = d3.axisBottom(this.scaleTimeX)
      .tickFormat(renderTickBottomFormat)
      .tickSize(-height)
      .tickValues(this.getTickValuesXAxis())
      .tickPadding(10);

    this.xAxis = groupMain.append('g')
      .attr('id', 'axisX')
      .attr('class', cx(styles.axisX, isLightTheme ? styles.axisLight : styles.axisDark))
      .attr('transform', `translate(0, ${this.height})`)
      .call(axisBottom);
  }

  renderYAxisBackgroundRect = (props: { yAxisBBox: DOMRect; hoveredParameter: string; x?: number; parameterHasFrequencies?: boolean }) => {
    const { yAxisBBox, hoveredParameter, x = 0, parameterHasFrequencies = false } = props;
    const { groupMain, dispatch } = this;


    groupMain.append('rect')
      .attr('x', yAxisBBox.x + x)
      .attr('y', yAxisBBox.y)
      .attr('width', yAxisBBox.width)
      .attr('height', yAxisBBox.height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', () => {
        dispatch(parameterChartSlice.setHoveredParameter(hoveredParameter));

        const hoveredFrequency = parameterHasFrequencies ? allFrequencies : undefined;
        dispatch(parameterChartSlice.setHoveredFrequency(hoveredFrequency));
      })
      .on('mouseout', () => {
        dispatch(parameterChartSlice.setHoveredParameter(undefined));
        dispatch(parameterChartSlice.setHoveredFrequency(undefined));
      });
  };

  drawFeedYAxis () {
    const { groupMain } = this;

    const axisLeft = d3.axisLeft(this.scaleLinearFeed)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.yAxisFeed = groupMain.append('g')
      .attr('id', 'axisYFeed')
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axisLeft);

    const yAxisBBox = this.yAxisFeed.node()?.getBBox();
    if (!yAxisBBox) {
      return;
    }

    this.renderYAxisBackgroundRect({ hoveredParameter: defaultLegendChart.TOTAL_FEED, yAxisBBox });
  }

  drawGrowthRateYAxis () {
    const { groupMain, width } = this;

    const axis = d3.axisRight(this.scaleLinearGrowthRate)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    this.yAxisGrowthDelta = groupMain.append('g')
      .attr('id', 'axisYGrowthDelta')
      .attr('transform', `translate(${width}, 0)`)
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);

    const yAxisBBox = this.yAxisGrowthDelta.node()?.getBBox();
    if (!yAxisBBox) {
      return;
    }

    this.renderYAxisBackgroundRect({ hoveredParameter: defaultLegendChart.GROWTH_RATE, yAxisBBox, x: width });
  }

  drawParameterWithoutFrequenciesYAxis () {
    const { groupMain, width, margin, selectedParameter } = this;

    const axis = d3.axisRight(this.scaleLinearParameterWithoutFrequencies)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    const x = width + (margin.right / 2.5);

    this.yAxisParameterWithoutFrequencies = groupMain.append('g')
      .attr('id', 'axisYParameterWithoutFrequencies')
      .attr('transform', `translate(${x}, 0)`)
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);

    const yAxisBBox = this.yAxisParameterWithoutFrequencies.node()?.getBBox();
    if (!yAxisBBox || !selectedParameter) {
      return;
    }

    this.renderYAxisBackgroundRect({ hoveredParameter: selectedParameter, yAxisBBox, x });
  }

  drawParameterWithFrequenciesYAxis () {
    const { groupMain, width, margin, selectedParameter, parameterWithFrequencies } = this;

    const axis = d3.axisRight(this.scaleLinearParameterWithFrequencies)
      .tickFormat((d) => d.toString())
      .ticks(10)
      .tickSize(0)
      .tickPadding(15);

    const x = width + (margin.right / 2.5);

    this.yAxisParameterWithFrequencies = groupMain.append('g')
      .attr('id', 'axisYParameterWithFrequencies')
      .attr('transform', `translate(${x}, 0)`)
      .attr('class', cx(styles.axisY, isLightTheme ? styles.axisLight : styles.axisDark))
      .call(axis);

    const yAxisBBox = this.yAxisParameterWithFrequencies.node()?.getBBox();
    if (!yAxisBBox || !selectedParameter) {
      return;
    }

    this.renderYAxisBackgroundRect({ hoveredParameter: selectedParameter, yAxisBBox, x, parameterHasFrequencies: Object.keys(parameterWithFrequencies).length > 0 });
  }

  renderTriangle = () => {
    const { container, margin, height, width, parameterWithFrequencies, parameterWithoutFrequencies } = this;

    const triangleMargins = {
      left: margin.left - 5,
      top: -(height + margin.top + margin.bottom + 11),
    };

    d3.select(container).selectAll('.triangles').remove();

    const triangles = d3.select(container)
      .append('div')
      .attr('class', cx('triangles', styles.triangles));

    triangles
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${triangleMargins.left}px, ${triangleMargins.top}px)`);

    triangles
      .append('div')
      .attr('class', cx('triangle', styles.triangle))
      .style('transform', `translate(${width + margin.right - (margin.left / 0.85)}px, ${triangleMargins.top}px)`);

    if (parameterWithoutFrequencies.values.length > 0 || Object.keys(parameterWithFrequencies).length > 0) {
      triangles
        .append('div')
        .attr('class', cx('triangle', styles.triangle))
        .style('transform', `translate(${width + margin.right - (margin.left / 1.50)}px, ${triangleMargins.top}px)`);
    }
  };

  renderTotalFeedArea = () => {
    const { gClipPath, brush } = this;
    const area = this.fillAreaChart();
    this.createArea();

    gClipPath
      .append('g')
      .attr('class', 'brush')
      .call(brush);

    const areaData = this.areaData(area);

    this.pathArea = this.gClipPath
      .append('path')
      .attr('class', cx('pathArea', isLightTheme ? styles.rectLight : styles.rectDark))
      .attr('d', areaData);
  };

  generateGrowthRatesLines = () => {
    const line = d3.line<StockingParameterValue>()
      .x(d => this.scaleTimeX(getDateWithoutTime(new Date(d.date))))
      .y(d => this.scaleLinearGrowthRate(d.value))
      .curve(d3.curveCatmullRom);

    return { line };
  };

  renderGrowthRatesLines = () => {
    const { growthRates, gClipPath } = this;

    const { line } = this.generateGrowthRatesLines();

    gClipPath
      .append('path')
      .datum(growthRates.values)
      .attr('class', cx('growthRateLines', styles.growthRateLines, isLightTheme ? styles.growthRateLinesLight : styles.growthRateLinesDark))
      .attr('d', line);
  };

  generateParameterWithoutFrequenciesLines = () => {
    const line = d3.line<StockingParameterValue>()
      .x(d => this.scaleTimeX(getDateWithoutTime(new Date(d.date))))
      .y(d => this.scaleLinearParameterWithoutFrequencies(d.value))
      .curve(d3.curveCatmullRom);

    return { line };
  };

  renderParameterWithoutFrequenciesLines = () => {
    const { parameterWithoutFrequencies, gClipPath } = this;

    const { line } = this.generateParameterWithoutFrequenciesLines();

    gClipPath
      .append('path')
      .datum(parameterWithoutFrequencies.values)
      .attr('class', cx('parameterWithoutFrequenciesLines', styles.parameterWithoutFrequenciesLines))
      .attr('d', line);
  };

  generateParameterWithFrequenciesLines = () => {
    const line = d3.line<StockingParameterValue>()
      .x(d => this.scaleTimeX(getDateWithoutTime(new Date(d.date))))
      .y(d => this.scaleLinearParameterWithFrequencies(d.value))
      .curve(d3.curveCatmullRom);

    return { line };
  };

  renderParameterWithFrequenciesLines = () => {
    const { parameterWithFrequencies, gClipPath } = this;

    const { line } = this.generateParameterWithFrequenciesLines();

    let index = 0;
    Object.keys(parameterWithFrequencies).forEach((key) => {
      const item = parameterWithFrequencies[key];

      gClipPath
        .append('path')
        .datum(item.values)
        .attr('class', cx('parameterWithFrequenciesLines', styles.parameterWithFrequenciesLines))
        .attr('d', line)
        .style('stroke', getColorParameterWithFrequencies(index));

      index++;
    });
  };

  renderGrowthRatesPlus () {
    const { gClipPath, growthRates } = this;

    const symbolPlus = d3.symbol().type(d3.symbolPlus).size(48);

    gClipPath.append('g')
      .attr('class', 'plus')
      .attr('fill', 'transparent')
      .selectAll('.growthRatesPoints')
      .data(growthRates.values)
      .enter()
      .append('path')
      .attr('class', cx('growthRatesPoints', isLightTheme ? styles.growthRatePlusLight : styles.growthRatePlusDark))
      .attr('d', symbolPlus)
      .attr('transform', (point: StockingParameterValue) => `translate(${this.scaleTimeX(getDateWithoutTime(new Date(point.date)))}, ${this.scaleLinearGrowthRate(point.value)})`)
      .attr('stroke-width', 2);
  }

  renderGrowthRateValues () {
    const { gClipPath, growthRates } = this;

    this.gClipPath.selectAll('.value-containers').remove();

    const valueContainers = gClipPath.append('g')
      .attr('class', 'value-containers');

    const valuePadding = 4;
    const defaultBBox = {
      x: -12,
      y: -15,
      width: 22,
      height: 14,
    };

    valueContainers.selectAll('.value-container')
      .data(growthRates.values)
      .enter()
      .append('g')
      .attr('class', 'value-container')
      .attr('transform', d => `translate(${this.scaleTimeX(getDateWithoutTime(new Date(d.date)))}, ${this.scaleLinearGrowthRate(d.value) - (3 * valuePadding)})`);

    valueContainers.selectAll('.value-container')
      // eslint-disable-next-line
      .each((d: any, i, nodes) => {
        const container = d3.select(nodes[i]);

        const text = container.append('text')
          .attr('class', styles.growthRateText)
          .attr('text-anchor', 'middle')
          .attr('dy', -valuePadding)
          .text(roundTwoDecimals(d.value));

        const bbox = text.node()?.getBBox();
        if (!bbox) {
          return;
        }

        container.insert('rect', 'text')
          .attr('class', styles.growthRateContainer)
          .attr('x', (bbox.x || defaultBBox.x) - valuePadding)
          .attr('y', (bbox.y || defaultBBox.y) - valuePadding)
          .attr('width', (bbox.width || defaultBBox.width) + (2 * valuePadding))
          .attr('height', (bbox.height || defaultBBox.height) + (2 * valuePadding))
          .attr('rx', 5)
          .attr('ry', 5);
      });
  }

  getScaleTimeXRound = (props: { date: Date }) => {
    const { date } = props;
    const { scaleTimeX } = this;

    return `date-${Math.round(scaleTimeX(getDateWithoutTime(new Date(date))))}`;
  };

  renderParameterWithoutFrequenciesPoints () {
    const { gClipPath, parameterWithoutFrequencies } = this;

    gClipPath.append('g')
      .attr('class', 'points')
      .attr('fill', 'transparent')
      .selectAll('circle')
      .data(parameterWithoutFrequencies.values)
      .enter()
      .append('circle')
      .attr('class', (point: StockingParameterValue) => cx('parameterWithoutFrequenciesCircle', this.getScaleTimeXRound({ date: point.date }), styles.parameterWithoutFrequenciesCircle, isLightTheme ? styles.parameterWithoutFrequenciesCircleLight : styles.parameterWithoutFrequenciesCircleDark))
      .attr('r', DEFAULT_CIRCLE)
      .attr('cx', (point: StockingParameterValue) => this.scaleTimeX(getDateWithoutTime(new Date(point.date))))
      .attr('cy', (point: StockingParameterValue) => this.scaleLinearParameterWithoutFrequencies(point.value))
      .attr('stroke-width', 1);
  }

  renderParameterWithFrequenciesPoints () {
    const { gClipPath, parameterWithFrequencies } = this;

    let index = 0;
    Object.keys(parameterWithFrequencies).forEach((key) => {
      const item = parameterWithFrequencies[key];

      gClipPath.append('g')
        .attr('class', 'points')
        .attr('fill', 'transparent')
        .selectAll('circle')
        .data(item.values)
        .enter()
        .append('circle')
        .attr('class', (point: StockingParameterValue) => cx('parameterWithFrequenciesCircle', key, this.getScaleTimeXRound({ date: point.date })))
        .attr('r', DEFAULT_CIRCLE)
        .attr('cx', (point: StockingParameterValue) => this.scaleTimeX(getDateWithoutTime(new Date(point.date))))
        .attr('cy', (point: StockingParameterValue) => this.scaleLinearParameterWithFrequencies(point.value))
        .attr('fill', getColorParameterWithFrequencies(index))
        .attr('stroke', isLightTheme ? '#e3f8ff' : 'white')
        .attr('stroke-width', 1);

      index++;
    });
  }

  refreshChart = (props: { minDate: Date; maxDate: Date; totalFeed: StockingParameterData; growthRates: StockingParameterData; parameterWithoutFrequencies: StockingParameterData; parameterWithFrequencies: { [key: string]: StockingParameterData }; selectedParameter?: string; theme: string; }) => {
    const { minDate, maxDate, totalFeed, growthRates, parameterWithoutFrequencies, parameterWithFrequencies, selectedParameter, theme } = props;
    const { container } = this;

    isLightTheme = theme === THEME.LIGHT;

    this.theme = theme;
    this.minDate = minDate;
    this.maxDate = maxDate;
    this.totalFeed = totalFeed;
    this.growthRates = growthRates;
    this.selectedParameter = selectedParameter;
    this.parameterWithoutFrequencies = parameterWithoutFrequencies;
    this.parameterWithFrequencies = parameterWithFrequencies;

    d3.select(container).select('#tooltipContent').remove();

    d3.select(container).selectAll('.rects').remove();
    d3.select(container).selectAll('.pathArea').remove();
    d3.select(container).selectAll('.brush').remove();
    d3.select(container).selectAll('.lines').remove();
    d3.select(container).selectAll('.plus').remove();
    d3.select(container).selectAll('.points').remove();

    d3.select(container).selectAll('.growthRateLines').remove();
    d3.select(container).selectAll('.parameterWithoutFrequenciesLines').remove();
    d3.select(container).selectAll('.parameterWithFrequenciesLines').remove();

    d3.select(container).select('#defs').remove();
    d3.select(container).select('#gClipPath').remove();

    d3.select(container).select('#axisX').remove();
    d3.select(container).select('#axisYFeed').remove();
    d3.select(container).select('#axisYGrowthDelta').remove();
    d3.select(container).select('#axisYParameterWithoutFrequencies').remove();
    d3.select(container).select('#axisYParameterWithFrequencies').remove();

    this.createBrushElement();

    this.buildXAxis();
    this.buildFeedYAxix();
    this.buildGrowthRateYAxix();
    this.buildParameterWithoutFrequenciesYAxix();
    this.buildParameterWithFrequenciesYAxix();

    this.drawXAxis();
    this.drawFeedYAxis();
    this.drawGrowthRateYAxis();
    this.drawParameterWithoutFrequenciesYAxis();
    this.drawParameterWithFrequenciesYAxis();

    this.createGClipPathElement();
    this.renderTotalFeedArea();
    this.renderGrowthRatesLines();
    this.renderParameterWithoutFrequenciesLines();
    this.renderParameterWithFrequenciesLines();

    this.renderParameterWithoutFrequenciesPoints();
    this.renderParameterWithFrequenciesPoints();
    this.renderGrowthRatesPlus();
    this.renderGrowthRateValues();

    this.renderLineSelected();
    this.renderTriangle();
    this.renderTooltip();
  }

  refreshBorders = (props: { hoveredParameter?: string; hoveredFrequency?: string; theme: string; }) => {
    const { hoveredParameter, hoveredFrequency, theme } = props;
    const { selectedParameter, parameterWithFrequencies } = this;

    this.theme = theme;
    this.hoveredParameter = hoveredParameter;
    this.hoveredFrequency = hoveredFrequency;

    isLightTheme = theme === THEME.LIGHT;

    this.gClipPath
      .selectAll('.pathArea')
      .transition()
      .duration(TIME_TRANSITION)
      .attr('class', cx('pathArea', styles.rect, isLightTheme ? styles.rectLight : styles.rectDark, this.hoveredParameter === defaultLegendChart.TOTAL_FEED ? isLightTheme ? styles.hoverReactLight : styles.hoverReactDark : null));

    const symbolPlus = d3.symbol().type(d3.symbolPlus);
    this.gClipPath
      .selectAll('.growthRatesPoints')
      .transition()
      .duration(TIME_TRANSITION)
      .attr('class', cx('growthRatesPoints', isLightTheme ? styles.growthRatePlusLight : styles.growthRatePlusDark))
      .attr('d', this.hoveredParameter === defaultLegendChart.GROWTH_RATE ? symbolPlus.size(72) : symbolPlus.size(48))
      .attr('stroke-width', this.hoveredParameter === defaultLegendChart.GROWTH_RATE ? 3 : 2);

    this.gClipPath
      .selectAll('.parameterWithoutFrequenciesCircle')
      .transition()
      .duration(TIME_TRANSITION)
      // eslint-disable-next-line
      .attr('class', (point: any) => cx('parameterWithoutFrequenciesCircle', this.getScaleTimeXRound({ date: point.date }), styles.parameterWithoutFrequenciesCircle, isLightTheme ? styles.parameterWithoutFrequenciesCircleLight : styles.parameterWithoutFrequenciesCircleDark))
      .attr('r', this.hoveredParameter === selectedParameter && this.hoveredFrequency === undefined ? DEFAULT_CIRCLE_ACTIVE : DEFAULT_CIRCLE);

    if (this.hoveredFrequency === allFrequencies) {
      Object.keys(parameterWithFrequencies).forEach((key) => {
        this.gClipPath
          .selectAll(`.parameterWithFrequenciesCircle.${key}`)
          .transition()
          .duration(TIME_TRANSITION)
          // eslint-disable-next-line
          .attr('class', (point: any) => cx('parameterWithFrequenciesCircle', key, this.getScaleTimeXRound({ date: point.date })))
          .attr('r', DEFAULT_CIRCLE_ACTIVE);
      });

      return;
    }

    if (this.hoveredFrequency) {
      this.gClipPath
        .selectAll(`.parameterWithFrequenciesCircle.${this.hoveredFrequency}`)
        .transition()
        .duration(TIME_TRANSITION)
        // eslint-disable-next-line
        .attr('class', (point: any) => cx('parameterWithFrequenciesCircle', `${this.hoveredFrequency}`, this.getScaleTimeXRound({ date: point.date })))
        .attr('r', this.hoveredParameter === selectedParameter ? DEFAULT_CIRCLE_ACTIVE : DEFAULT_CIRCLE);
    } else {
      Object.keys(parameterWithFrequencies).forEach((key) => {

        this.gClipPath
          .selectAll(`.parameterWithFrequenciesCircle.${key}`)
          .transition()
          .duration(TIME_TRANSITION)
          // eslint-disable-next-line
          .attr('class', (point: any) => cx('parameterWithFrequenciesCircle', key, this.getScaleTimeXRound({ date: point.date })))
          .attr('r', this.hoveredParameter === selectedParameter ? DEFAULT_CIRCLE_ACTIVE : DEFAULT_CIRCLE);
      });
    }
  }

  resize = (props: { width: number, height: number }) => {
    const { width, height } = props;
    const { container } = this;

    d3.select(container).select('#content').selectAll('*').remove();
    d3.select(container).select('#tooltipParameterChart').remove();

    this.updateSize(width, height);
    this.createTooltip();
    this.createBrushElement();

    this.buildXAxis();
    this.buildFeedYAxix();
    this.buildGrowthRateYAxix();
    this.buildParameterWithoutFrequenciesYAxix();
    this.buildParameterWithFrequenciesYAxix();

    this.drawXAxis();
    this.drawFeedYAxis();
    this.drawGrowthRateYAxis();
    this.drawParameterWithoutFrequenciesYAxis();
    this.drawParameterWithFrequenciesYAxis();

    this.createGClipPathElement();
    this.renderTotalFeedArea();
    this.renderGrowthRatesLines();
    this.renderParameterWithoutFrequenciesLines();
    this.renderParameterWithFrequenciesLines();

    this.renderParameterWithoutFrequenciesPoints();
    this.renderParameterWithFrequenciesPoints();
    this.renderGrowthRatesPlus();
    this.renderGrowthRateValues();

    this.renderLineSelected();
    this.renderTriangle();
    this.renderTooltip();
  };

  updateSize = (width: number, height: number) => {
    const { container } = this;

    this.width = width - this.margin.left - this.margin.right;
    this.height = height - this.margin.top - this.margin.bottom;

    const _width = this.width + this.margin.left + this.margin.right;
    const _height = this.height + this.margin.top + this.margin.bottom;

    d3.select(container).select('svg')
      .attr('width', _width)
      .attr('height', _height);
  };

  renderLineSelected () {
    const { container, groupMain, height } = this;
    d3.select(container).select('#selectedTick').remove();

    groupMain.append('line')
      .attr('id', 'selectedTick')
      .attr('class', isLightTheme ? styles.selectedLineLight : styles.selectedLineDark)
      .attr('y1', 0)
      .attr('y2', height)
      .style('display', 'none');
  }

  fillAreaChart = () => {
    const { minDate, maxDate, totalFeed } = this;

    const defaultDomain = this.getDefaultDomain();

    const dates = d3.timeDays(defaultDomain[0], defaultDomain[1]);

    const totalFeedValues = totalFeed.values.filter(item => new Date(item.date) >= minDate && new Date(item.date) <= maxDate);
    const dataY = totalFeedValues.map((value) => value.value);

    return fillArea({ dataY, dates });
  };

  createArea () {
    const { scaleTimeX, scaleLinearFeed, minDate, maxDate } = this;

    const dates = generateDateArray({ minDate, maxDate });
    const scaleBandX = this.createScaleBand({ dates });
    const barWidth = scaleBandX.bandwidth();

    const { minYValue } = this.getFeedYValues();

    this.areaData = d3.area<Area>()
      .curve(d3.curveCatmullRom)
      .x(d => scaleTimeX(getDateWithoutTime(new Date(d.date))) - (barWidth / 2))
      .y0(scaleLinearFeed(minYValue))
      .y1((d) => scaleLinearFeed(d.high));
  }

  renderTooltip () {
    const {
      tooltip,
      parameterWithFrequencies,
      parameterWithoutFrequencies,
      totalFeed,
      width,
      height,
      groupMain,
      scaleTimeX,
      minDate,
      maxDate,
      renderTooltipContent,
      selectedParameter,
      growthRates,
    } = this;

    const bisect = d3.bisector((point: StockingParameterValue) => scaleTimeX(getDateWithoutTime(new Date(point.date)))).left;
    let previousPointX = -1;

    const tooltipContent = tooltip.append('div')
      .attr('id', 'tooltipContent')
      .attr('class', styles.content);

    groupMain
      .on('mouseout', function () {
        tooltip.style('display', 'none');
        d3.select('#selectedTick').style('display', 'none');
        d3.selectAll(currentDateActive).attr('r', DEFAULT_CIRCLE);
      })
      .on('mousemove', function (event) {
        const allData: TooltipData[] = getAllData({ parameterWithFrequencies, parameterWithoutFrequencies, totalFeed, growthRates, selectedParameter });
        const date = scaleTimeX.invert((d3).pointer(event)[0]);
        const x0 = scaleTimeX(getDateWithoutTime(date));

        const isXVisible = (x0 >= scaleTimeX(getDateWithoutTime(scaleTimeX.domain()[0]))) && (x0 <= scaleTimeX(getDateWithoutTime(scaleTimeX.domain()[1])));
        if (!isXVisible) {
          return;
        }

        const index = bisect(allData, x0, 1);
        if (index === allData.length) {
          return;
        }

        const previousPoint = scaleTimeX(getDateWithoutTime(new Date(allData[index - 1].date)));
        const currentPoint = scaleTimeX(getDateWithoutTime(new Date(allData[index].date)));
        let selectedPoint: number;

        if (currentPoint) {
          selectedPoint = x0 - previousPoint > currentPoint - x0 ? currentPoint : previousPoint;
        } else {
          selectedPoint = previousPoint;
        }

        if (!selectedPoint || selectedPoint < 0) {
          return;
        }

        if (!(selectedPoint < scaleTimeX(getDateWithoutTime(minDate)) || selectedPoint > scaleTimeX(getDateWithoutTime(maxDate)))) {
          tooltip.style('display', 'block');
          d3.select('#selectedTick').style('display', 'block');
        }

        if (previousPointX === selectedPoint) {
          if (currentDateActive) {
            d3.selectAll(currentDateActive).attr('r', () => DEFAULT_CIRCLE_ACTIVE);
          }

          return;
        }

        previousPointX = selectedPoint;
        const tooltipData: TooltipData[] = allData.filter((item) => scaleTimeX(getDateWithoutTime(new Date(item.date))) === selectedPoint);
        d3.selectAll('.points circle').attr('r', DEFAULT_CIRCLE);

        if (tooltipData.length === 0) {
          tooltip.style('display', 'none');
          d3.select('#selectedTick').style('display', 'none');
          return;
        }

        const higherValue: TooltipData = tooltipData.reduce((prev: TooltipData, current: TooltipData) => (prev.value > current.value) ? prev : current);

        const marginLeft = scaleTimeX(getDateWithoutTime(new Date(higherValue.date)));
        currentDateActive = `.date-${Math.round(marginLeft)}`;

        d3.selectAll(currentDateActive).attr('r', DEFAULT_CIRCLE_ACTIVE);

        const tooltipDialogWidth = 200;
        const bubbleWidth = 17; // this needs to be the same as defined in the css
        const tooltipTotalWidth = tooltipDialogWidth + bubbleWidth;

        if (marginLeft + tooltipTotalWidth < width) {
          tooltip.classed(styles.rightAlignedTooltip, false);
          tooltip.classed(styles.leftAlignedTooltip, true);
        } else {
          tooltip.classed(styles.rightAlignedTooltip, true);
          tooltip.classed(styles.leftAlignedTooltip, false);
        }
        const leftPositionProps = { marginLeft, tooltipDialogWidth, bubbleWidth, width };

        tooltip
          .style('left', getStockingChartLeftPosition(leftPositionProps))
          .style('bottom', `${height - (height / 2.5)}px`);

        d3.select('#selectedTick')
          .attr('x1', marginLeft)
          .attr('x2', marginLeft);

        tooltipContent.selectAll('*').remove();
        renderTooltipContent({ tooltipData, tooltipContent, selectedParameter, parameterWithoutFrequencies: parameterWithoutFrequencies.values, parameterWithFrequencies });
      });
  }

  renderTooltipContent (props: { tooltipData: TooltipData[]; tooltipContent: d3.Selection<HTMLDivElement, unknown, null, undefined>; selectedParameter?: string; parameterWithoutFrequencies: StockingParameterValue[]; parameterWithFrequencies: { [key: string]: StockingParameterData }; }) {
    const { tooltipData, tooltipContent, selectedParameter, parameterWithFrequencies, parameterWithoutFrequencies } = props;

    for (let index = 0; index < tooltipData.length; index++) {
      const data = tooltipData[index];

      const entry = tooltipContent
        .append('div')
        .attr('class', styles.entry);

      if (index === 0) {
        const entryTitle = entry.append('div')
          .attr('class', styles.entryHeader);

        entryTitle.append('div')
          .attr('class', styles.entryTitle)
          .style('color', 'royalblue')
          .html(formatLongDateWithOffset(data.date.toString()));
      }

      const entryContent = entry.append('div')
        .attr('class', styles.entryContent);

      entryContent.append('div')
        .attr('class', styles.stat)
        .html(`
          <svg width="16" height="16">
            ${data.type === selectedParameter ? renderCircle({ parameterWithFrequencies, parameterWithoutFrequencies, key: data.key }) : ''}
            ${data.type === defaultLegendChart.TOTAL_FEED ? renderRect() : ''}
            ${data.type === defaultLegendChart.GROWTH_RATE ? renderPlusIcon() : ''}
          </svg>
          <div>${getLabelTooltip({ type: data.type, key: data.key })}:</div>
          <div><strong>&nbsp;${roundTwoDecimals(data.value)} ${getUnitTooltip({ type: data.type, unit: data.unit })}</strong></div>
        `);

      if (index !== tooltipData.length - 1) {
        tooltipContent.append('hr');
      }
    }
  }
}

export default ParameterChartD3;
