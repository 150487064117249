import React from 'react';

interface Props {
  fill?: string;
  size?: number;
}

export const JuvenileIcon = (props: Props) => {
  const { fill = 'currentColor', size = 36 } = props;

  return (
    <svg width={size} height={size} fill={fill} viewBox='0 0 36 36'>
      <g clipPath='url(#_clipPath_zlyYr03qAIhJcpDeHqAUiqGqfVYKC3w0)'>
        <path
          d=' M 31.485 4.541 Q 31.467 5.885 31.188 6.551 C 30.324 8.609 27.708 8.783 25.903 8.783 Q 19.874 8.783 14.739 8.783 Q 9.603 8.858 6.03 12.952 C 2.458 17.045 5.435 26.08 12.506 29.103 C 13.968 29.728 15.109 29.819 16.351 30.676 C 19.105 32.576 22.023 32.186 19.651 29.103 Q 24.935 25.456 18.758 26.572 C 12.58 27.689 9.603 17.12 14.739 16.747 C 19.874 16.375 20.243 16.58 23.075 15.259 C 25.308 14.217 30.146 9.677 25.903 8.783'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
        <path
          d=' M 27.913 4.169 Q 29.476 7.22 26.499 8.783'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
        <circle vectorEffect='non-scaling-stroke' cx='22.442079177144887' cy='11.500115480432193' r='0.9303774697573814' fill={fill} />
        <path
          d=' M 9.975 9.9 Q 9.938 12.84 11.612 14.068'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
        <path
          d=' M 4.988 20.358 Q 7.254 21.965 9.007 21.585'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
        <path d=' M 15.408 29.847 Q 16.45 28.768 16.632 27.019'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
        <path
          d=' M 18 16.597 Q 20.431 17.118 20.531 19.351'
          fill='none'
          vectorEffect='non-scaling-stroke'
          strokeWidth='1.5'
          stroke={fill}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit='3'
        />
      </g>
    </svg>
  );
};
