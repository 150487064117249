import cx from 'classnames';
import React, { useState, useRef } from 'react';

import { LrvTooltip } from '../LrvTooltip/LrvTooltip';
import { getCurrentTheme } from '../../../helpers/theme';

import styles from './AutoEllipsisTooltip.module.scss';

interface Props {
  text: string;
  classNameText?: string;
  containerClassName?: string;
  theme?: 'light' | 'dark';
}

export const AutoEllipsisTooltip = (props: Props) => {
  const { classNameText, containerClassName, text, theme = getCurrentTheme() } = props;

  const [showTooltip, setShowTooltip] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    if (!textRef.current || !containerRef.current) {
      return;
    }

    const isOverflowing = textRef.current.scrollWidth > containerRef.current.offsetWidth;
    setShowTooltip(isOverflowing);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      className={cx(styles.container, containerClassName)}
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <LrvTooltip
        title={text}
        open={showTooltip}
        themeStyle={theme}
      >
        <div
          ref={textRef}
          className={cx(styles.ellipsis, classNameText)}
          style={{
            width: `${containerRef.current?.scrollWidth || 0}px`,
          }}
        >
          {text}
        </div>
      </LrvTooltip>
    </div>
  );
};

