import React from 'react';
import * as jwt from 'jsonwebtoken';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';

import { PayloadToken } from '../interfaces/auth';
import { getHigherRole, roles } from '../../config/commons';

interface PrivateRouteProps {
  component?: React.ComponentType<any> | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>> | undefined;
  path?: string;
  exact?: boolean;
  requiredRoles: string[];
  render?: (props: RouteComponentProps<{
    [x: string]: string | undefined;
  }>) => React.ReactNode;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, path, exact, requiredRoles, render } = props;
  const accessToken = localStorage.getItem('accessToken');
  const isAuthenticated = !!accessToken;
  const payloadToken = jwt.decode(accessToken || '') as PayloadToken;
  const userRoles: string[] = payloadToken ? payloadToken.roles : [];
  const higherRole = getHigherRole(userRoles);
  const isSuperAdmin = userRoles.includes(roles.SUPER_ADMIN);
  const hasRequiredRole = requiredRoles.includes(higherRole);

  const hasPermission = isSuperAdmin || (isAuthenticated && hasRequiredRole);

  return (
    <Route
      exact={exact}
      path={path}
      component={hasPermission ? Component : undefined}
      render={props => (
        (hasPermission) ?
          ((render && !Component) ? render(props) : undefined)
          : <Redirect to={{
            pathname: isAuthenticated ? '/unauthorized' : '/login',
            state: { from: props.location },
          }} />
      )} />
  );
};
