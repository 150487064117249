import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { Badge, Col, Row, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../ActivityDashboard.scss';
import { Store } from '../../../state/store.interfaces';
import { getTextColor } from '../../../helpers/chartjs.helpers';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import IconButton from '../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../common/components/LrvTable/LrvTable';
import FullScreen from '../../../common/components/FullScreen/FullScreen';
import { getUnitPhaseTypeFromAnalysis } from '../../../helpers/units.helpers';
import { isAdminUser, roles, getMainRole, THEME } from '../../../config/commons';
import { fetchCampusActivity, fetchUnassignedStockings } from '../activityDashboardSlice';
import { Campus, CampusActivity as ICampusActivity, UnassignedStocking } from '../interfaces';

import styles from './CampusActivity.module.scss';

const { Text } = Typography;

interface Stocking {
  _id: string;
  name: string;
  user: string;
}

interface Props {
  theme?: 'dark' | 'light';
}

function CampusActivity (props: Props) {
  const { theme } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const isLightTheme = theme === THEME.LIGHT;
  const ASSIGNED_CAMPUS_COLOR = isLightTheme ? '#1b62e6' : '#0000ff';

  const fromDate = useSelector((state: Store) => state.activityDashboard.filters.fromDate);
  const toDate = useSelector((state: Store) => state.activityDashboard.filters.toDate);
  const selectedCampus = useSelector((state: Store) => state.activityDashboard.filters.campus as Campus);
  const campusActivity = useSelector((state: Store) => state.activityDashboard.campusActivity as ICampusActivity[]);
  const unassignedStocking = useSelector((state: Store) => state.activityDashboard.unassignedStockings as UnassignedStocking[]);
  const isUnassignedStockingsLoading = useSelector((state: Store) => state.activityDashboard.isUnassignedStockingsLoading);
  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);
  const isRunningOnboarding = useSelector((state: Store) => state.onboarding.run);

  const lastDays = moment(toDate).diff(moment(fromDate), 'days');
  const [showFullScreenModal, setShowFullScreenModal] = useState(false);
  const [hasUnassignedAnalysis, setHasUnassignedAnalysis] = useState(false);
  const UNASSIGNED_CAMPUS = 'UNASSIGNED';

  useEffect(() => {
    if (!selectedCompany._id || selectedCompany.maxStage === 1) {
      return;
    }

    dispatch(fetchCampusActivity({ fromDate, toDate, unitPhaseType: getUnitPhaseTypeFromAnalysis(phaseType), phaseType, companyId: selectedCompany._id, campusId: selectedCampus._id }));
  }, [dispatch, fromDate, phaseType, selectedCampus, selectedCompany, toDate]);

  useEffect(() => {
    const unassignedCampus = campusActivity.find(value => value.name === UNASSIGNED_CAMPUS);
    setHasUnassignedAnalysis(!!unassignedCampus);
  }, [campusActivity]);

  const data = sortBy(campusActivity, 'count').slice(campusActivity.length - 5, campusActivity.length);
  const barsColors = data.map(entry => entry.name === UNASSIGNED_CAMPUS ? '#e67e22' : ASSIGNED_CAMPUS_COLOR);
  const campusActivityData = {
    labels: data.map(activity => activity.name === UNASSIGNED_CAMPUS ? t('clientDashboard.unassigned') : activity.name),
    datasets: [
      {
        backgroundColor: barsColors,
        borderColor: 'transparent',
        borderWidth: 0,
        data: data.map(activity => activity.count)
      }
    ]
  };

  const fullData = sortBy(campusActivity, 'count');
  const fullBarsColors = fullData.map(entry => entry.name === UNASSIGNED_CAMPUS ? '#e67e22' : ASSIGNED_CAMPUS_COLOR);
  const campusActivityFullData = {
    labels: fullData.map(activity => activity.name === UNASSIGNED_CAMPUS ? t('clientDashboard.unassigned') : activity.name),
    datasets: [
      {
        backgroundColor: fullBarsColors,
        borderColor: 'transparent',
        borderWidth: 0,
        data: fullData.map(activity => activity.count)
      }
    ]
  };

  const stockingsColumns: ColumnProps<Stocking>[] = [{
    key: 1,
    width: '50%',
    title: t('clientDashboard.stocking'),
    dataIndex: 'name',
    render: (name, record) => {
      const route = `/production/stockings/${record._id}`;
      const mainRole = getMainRole();
      return mainRole !== roles.SALES && mainRole !== roles.SALES_MANAGER ?
        <Link to={route} target='_blank' className='link'>{name}</Link> :
        <Text>{name}</Text>;
    }
  },
  {
    key: 2,
    width: '50%',
    title: t('clientDashboard.user'),
    dataIndex: 'user',
  }];

  const unassignedStockingsData = unassignedStocking.map((stocking: UnassignedStocking, index) => {
    return {
      key: index,
      _id: stocking._id,
      name: stocking.name,
      user: `${stocking.user.firstName} ${stocking.user.lastName}`
    };
  });

  const parentRef = useRef<HTMLDivElement>(null);
  const [tableHeight, setTableHeight] = useState(0);

  let doResize: string | number | NodeJS.Timeout | undefined;
  function handleResize () {
    clearTimeout(doResize);
    doResize = setTimeout(resizeTable, 100);
  }

  function resizeTable () {
    let parentHeight = 0;
    if (parentRef.current) {
      parentHeight = parentRef.current.offsetHeight;
      if (parentHeight !== tableHeight) {
        setTableHeight(parentHeight);
      }
    }
  }

  window.addEventListener('resize', handleResize);
  handleResize();

  function renderWidget () {
    return <div className={styles.container}>
      <div className={styles.actions}>
        {hasUnassignedAnalysis ?
          <Badge dot >
            <IconButton
              onClick={() => {
                if (isRunningOnboarding) {
                  return;
                }
                setShowFullScreenModal(true);
                dispatch(fetchUnassignedStockings(selectedCompany._id));
              }}
              iconName='fullscreen'
              size='small'
            />
          </Badge> :
          <IconButton
            id='open_campus_activity_button'
            onClick={() => {
              if (isRunningOnboarding) {
                return;
              }
              setShowFullScreenModal(true);
            }}
            iconName='fullscreen'
            size='small'
          />}
      </div>
      {renderGraph(campusActivityData)}
    </div>;
  }

  function renderFullWidget () {
    return <div className={styles.containerFull} ref={parentRef}>
      <Row gutter={16} className='row'>
        {hasUnassignedAnalysis ?
          <>
            <Col xs={24} sm={24} md={16} lg={16} xl={16} className='col'>
              {renderGraph(campusActivityFullData)}
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              {renderUnassignedAnalysis()}
            </Col>
          </> :
          <>
            <Col xs={24} sm={24} md={16} lg={24} xl={24} className='col'>
              {renderGraph(campusActivityFullData)}
            </Col>
          </>}
      </Row>
    </div>;
  }

  function renderGraph (data: ChartData<'bar', (number | [number, number] | null)[], unknown>) {
    return <Bar
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 0
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const tooltipItem = context.dataset.data[context.dataIndex];
                return ` ${tooltipItem}`;
              },
            },
          },
        },
        scales: {
          x: {
            ticks: {
              minRotation: 0,
              maxRotation: 0,
              color: getTextColor(theme),
              maxTicksLimit: 5,
              font: {
                size: 11,
              }
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: getTextColor(theme),
            }
          }
        },
      }}
    />;
  }

  function renderModalTitle () {
    const campus = selectedCampus._id === '' ? t('clientDashboard.allCampus') : selectedCampus.name;
    const amountAnalysis = t('clientDashboard.amountAnalysis', { analysis: getAmountAnalysis() });

    if (isAdminUser()) {
      return `${t('clientDashboard.campusActivityFull')} - ${selectedCompany.name} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
    }

    return `${t('clientDashboard.campusActivityFull')} - ${campus} - ${t('clientDashboard.subtitle', { days: lastDays })} - ${amountAnalysis}`;
  }

  function getAmountAnalysis () {
    let value = 0;
    for (let index = 0; index < campusActivity.length; index++) {
      value += campusActivity[index].count;
    }

    return value;
  }

  function renderUnassignedAnalysis () {
    return (
      <LrvTable
        title={() => <LrvText text={t('clientDashboard.unassignedStockings')} theme={theme} />}
        pagination={{ position: ['bottomCenter'], hideOnSinglePage: true, showSizeChanger: false }}
        columns={stockingsColumns}
        dataSource={unassignedStockingsData}
        loading={isUnassignedStockingsLoading}
        scroll={{ y: tableHeight - 170 }}
        theme={theme}
      />
    );
  }

  return <div>
    {renderWidget()}
    <FullScreen
      title={renderModalTitle()}
      show={showFullScreenModal}
      onClose={() => setShowFullScreenModal(false)}
      theme={theme}
    >
      {renderFullWidget()}
    </FullScreen>
  </div>;
}

export default CampusActivity;
