import React from 'react';

interface Props {
  fill?: string;
  width: number;
  height: number;
}

export const LarvaeIcon = (props: Props) => {
  const { fill = 'white', width, height } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 36'
    >
      <defs>
        <clipPath id='_clipPath_yjnVhTglf0uICnBP1FX3eOQ6gx1xo1bq'>
          <rect width={28} height={36} />
        </clipPath>
      </defs>
      <g id='url(#_clipPath_yjnVhTglf0uICnBP1FX3eOQ6gx1xo1bq)'>
        <mask id='_mask_8CaNy4k6FK0zUdQYZPyAHkeVGKo6efA1'>
          <path
            d=' M 9.54 9.971 C 9.54 7.359 11.512 5.197 13.941 5.197 C 16.37 5.197 18.343 7.104 18.343 9.715 C 18.343 12.326 17.495 15.271 13.941 15.086 C 10.371 15.278 9.54 12.582 9.54 9.971 Z '
            fill='none'
            stroke='none'
          />
        </mask>

        <path
          d=' M 9.54 9.971 C 9.54 7.359 11.512 5.197 13.941 5.197 C 16.37 5.197 18.343 7.104 18.343 9.715 C 18.343 12.326 17.495 15.271 13.941 15.086 C 10.371 15.278 9.54 12.582 9.54 9.971 Z '
          fill='none'
          stroke={fill}
          strokeWidth={1}
        />

        <path
          d=' M 9.54 9.971 C 9.54 7.359 11.512 5.197 13.941 5.197 C 16.37 5.197 18.343 7.104 18.343 9.715 C 18.343 12.326 17.495 15.271 13.941 15.086 C 10.371 15.278 9.54 12.582 9.54 9.971 Z '
          fill='none'
          stroke={fill}
          mask='url(#_mask_8CaNy4k6FK0zUdQYZPyAHkeVGKo6efA1)'
          vectorEffect='non-scaling-stroke'
          strokeWidth={3}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />

        <path
          d=' M 12.338 14.538 Q 11.325 15.165 12.717 24.256'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 15.671 14.538 Q 16.684 14.891 15.291 24.256'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 13.941 27.312 Q 20.714 33.582 13.941 31.683'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 13.941 27.312 Q 7.168 33.582 13.941 31.683'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <path
          d=' M 12.717 24.217 C 12.827 24.709 13.284 25.519 14.041 25.499 C 14.772 25.48 15.133 24.75 15.291 24.217'
          fill='none'
          vectorEffect='non-scaling-stroke'
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
        />

        <line
          x1={14.046}
          y1={25.588}
          x2={14.046}
          y2={27.312}
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
          vectorEffect='non-scaling-stroke'
        />

        <mask id='_mask_5hFxU0y3FR4vBCzmwLduZVf7N59dC1HE'>
          <path
            d=' M 7.446 7.632 C 7.446 6.312 8.669 5.197 10.117 5.197 C 11.565 5.197 12.454 6.312 12.454 7.632 C 12.454 8.952 10.754 9.98 9.306 9.98 C 7.858 9.98 7.446 8.952 7.446 7.632 Z '
            fill='white'
            stroke='none'
          />
        </mask>

        <path
          d=' M 7.446 7.632 C 7.446 6.312 8.669 5.197 10.117 5.197 C 11.565 5.197 12.454 6.312 12.454 7.632 C 12.454 8.952 10.754 9.98 9.306 9.98 C 7.858 9.98 7.446 8.952 7.446 7.632 Z '
          fill={fill}
        />

        <path
          d=' M 7.446 7.632 C 7.446 6.312 8.669 5.197 10.117 5.197 C 11.565 5.197 12.454 6.312 12.454 7.632 C 12.454 8.952 10.754 9.98 9.306 9.98 C 7.858 9.98 7.446 8.952 7.446 7.632 Z '
          fill={fill}
          stroke={fill}
          mask='url(#_mask_5hFxU0y3FR4vBCzmwLduZVf7N59dC1HE)'
          vectorEffect='non-scaling-stroke'
          strokeWidth={3}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />

        <mask id='_mask_2KF47RYPCTQ0VuJiJnBAdPme9wQ1qSoK'>
          <path
            d=' M 20.554 7.632 C 20.554 6.312 19.331 5.197 17.883 5.197 C 16.435 5.197 15.546 6.312 15.546 7.632 C 15.546 8.952 17.246 9.98 18.694 9.98 C 20.142 9.98 20.554 8.952 20.554 7.632 Z '
            fill='white'
            stroke='none'
          />
        </mask>

        <path
          d=' M 20.554 7.632 C 20.554 6.312 19.331 5.197 17.883 5.197 C 16.435 5.197 15.546 6.312 15.546 7.632 C 15.546 8.952 17.246 9.98 18.694 9.98 C 20.142 9.98 20.554 8.952 20.554 7.632 Z '
          fill={fill}
        />

        <path
          d=' M 20.554 7.632 C 20.554 6.312 19.331 5.197 17.883 5.197 C 16.435 5.197 15.546 6.312 15.546 7.632 C 15.546 8.952 17.246 9.98 18.694 9.98 C 20.142 9.98 20.554 8.952 20.554 7.632 Z '
          fill={fill}
          stroke={fill}
          mask='url(#_mask_2KF47RYPCTQ0VuJiJnBAdPme9wQ1qSoK)'
          vectorEffect='non-scaling-stroke'
          strokeWidth={3}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />

        <line
          x1={14.004}
          y1={5.695}
          x2={14.046}
          y2={3.876}
          stroke={fill}
          strokeWidth={1.5}
          strokeMiterlimit={3}
          strokeLinejoin='round'
          strokeLinecap='round'
          vectorEffect='non-scaling-stroke'
        />

        <path
          d=' M 16.067 16.008 Q 18.38 17.322 20.033 15.538'
          fill='none'
          stroke={fill}
          vectorEffect='non-scaling-stroke'
          strokeWidth={1.5}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />

        <path
          d=' M 11.413 16.008 Q 9.1 17.322 7.446 15.538'
          fill='none'
          stroke={fill}
          vectorEffect='non-scaling-stroke'
          strokeWidth={1.5}
          strokeLinejoin='round'
          strokeLinecap='round'
          strokeMiterlimit={3}
        />
      </g>
    </svg>
  );
};
