import cx from 'classnames';
import { Collapse, Space } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { signOut } from '../auth/authSlice';
import { RootState } from '../../state/store';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { canRenderItem } from '../../utils/checkRoutes';
import DnaIcon from '../../common/components/Icon/DnaIcon';
import { isSuperAdmin, THEME } from '../../config/commons';
import { JuvenileIcon } from '../../common/components/Icon/JuvenileIcon';
import { LrvDivider } from '../../common/components/LrvDivider/LrvDivider';
import FullScreenFrame from '../../common/components/FullScreen/FullScreen';
import { backAccount, setShowModalImpersonate } from '../Sidebar/impersonateSlice';
import { MenuItem, menuItemsAbove, popoverMenuItems, SubMenuItem } from '../Sidebar/sidebar.config';

import { SwitchTheme } from './SwitchTheme';
import styles from './FullScreenMenu.module.scss';
import { setShowFullScreenMenu } from './headerSlice';

const FullScreenMenu = () => {

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { pathname: currentPath } = useLocation();
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  const { showFullScreenMenu } = useSelector((state: RootState) => state.header);

  const impersonate = localStorage.getItem('impersonate');

  const onClose = () => dispatch(setShowFullScreenMenu(false));

  const renderItem = (item: SubMenuItem | MenuItem, isSelected?: boolean, showArrow = false) => {
    if (item.icon === 'production') {
      return (
        <div className={cx(styles.productionItem, isSelected ? styles.itemSelected : '')}>
          <JuvenileIcon />
          <div className={styles.itemTitle}>{t(item.title)}</div>
          {showArrow && <div className={styles.subMenuArrow}><Icon name='arrow-down-s' /></div>}
        </div>
      );
    }

    if (item.icon === 'dna') {
      return (
        <div className={cx(styles.geneticsItem, isSelected ? styles.itemSelected : '')}>
          <DnaIcon />
          <div className={styles.itemTitle}>{t(item.title)}</div>
          {showArrow && <div className={styles.subMenuArrow}><Icon name='arrow-down-s' /></div>}
        </div>
      );
    }

    return (
      <div className={cx(styles.item, isSelected ? styles.itemSelected : '')}>
        {item.icon && <Icon name={item.icon} type={item.path === '/units' ? 'none' : 'line'} />}
        <div className={styles.itemTitle}>{t(item.title)}</div>
        {showArrow && <div className={styles.subMenuArrow}><Icon name='arrow-down-s' /></div>}
      </div>
    );
  };

  const getSubMenu = (menuItem: MenuItem) => {
    const subMenuItems = menuItem.subMenu ?? [];
    const items: JSX.Element[] = [];
    let isSubMenuSelected = false;

    subMenuItems.forEach((subMenuItem, index) => {
      const itemPathname = menuItem.path + subMenuItem.path;
      const hasAccess = canRenderItem(subMenuItem.rolesRequired);
      const isSubItemSelected = currentPath.includes(itemPathname) || subMenuItem?.subPaths?.some(subpath => currentPath.includes(subpath));

      if (isSubItemSelected) { // if there is any subItem selected, select subMenuItem too
        isSubMenuSelected = true;
      }

      if (hasAccess) {
        items.push(
          <Link
            key={`link-sub-menu-${subMenuItem.id}-${index}`}
            to={itemPathname} onClick={onClose}
            className={styles.subMenuItem}
          >
            {renderItem(subMenuItem, isSubItemSelected)}
          </Link>
        );
      }
    });

    return (
      <Collapse
        key={`collapse-${menuItem.id || menuItem.icon}`}
        onChange={(keys) => {
          if (typeof keys === 'string') {
            setActiveKeys([keys]);
          }

          if (Array.isArray(keys)) {
            setActiveKeys(keys);
          }
        }}
        activeKey={activeKeys}
      >
        <Collapse.Panel
          key={`collapse-panel-${menuItem.id || menuItem.icon}`}
          header={renderItem(menuItem, isSubMenuSelected, true)}
          showArrow={false}
        >
          {items}
        </Collapse.Panel>
      </Collapse>
    );
  };

  const renderMenu = (menuItems: MenuItem[]) => {
    const items: JSX.Element[] = [];

    menuItems.forEach((menuItem) => {
      if (menuItem.subMenu) {
        items.push(getSubMenu(menuItem));
      }
      const hasAccess = menuItem.rolesRequired && canRenderItem(menuItem.rolesRequired);
      const isSelected = currentPath.includes(menuItem.path) || menuItem?.subPaths?.some(subpath => currentPath.includes(subpath));
      if (hasAccess && !menuItem.subMenu) {
        items.push(
          <Link
            key={`link-${menuItem.id || menuItem.icon}`}
            to={menuItem.path}
            onClick={onClose}
          >
            <div className={styles.menuItem}>
              {renderItem(menuItem, isSelected)}
            </div>
          </Link>
        );
      }
    });

    return items;
  };

  return (
    <FullScreenFrame
      show={showFullScreenMenu}
      title='Larvia'
      onClose={onClose}
      theme={theme}
    >
      <div className={cx(styles.container, isLightTheme ? styles.containerLight : styles.containerDark)}>
        <div className={styles.options}>
          {renderMenu(menuItemsAbove)}

          <LrvDivider />

          {renderMenu(popoverMenuItems)}

          <div className={styles.menuItem}>
            <Link to='/settings' onClick={onClose}>
              <div className={cx(styles.item, currentPath.includes('/settings') ? styles.itemSelected : '')}>
                <Icon name='settings-2' type='line' />
                <div className={styles.itemTitle}>{t('users.profile.account')}</div>
              </div>
            </Link>
          </div>

          {isSuperAdmin() || impersonate ?
            impersonate === 'true' ?
              <div onClick={() => dispatch(backAccount())} className={styles.hand}>
                <div className={styles.item}>
                  <Icon name='spy' type='line' />
                  <div className={styles.itemTitle}>{t('sidebar.backAccount')}</div>
                </div>
              </div>

              :
              <div onClick={() => {
                onClose();
                dispatch(setShowModalImpersonate(true));
              }} className={styles.hand}>
                <div className={styles.item}>
                  <Icon name='spy' type='line' />
                  <div className={styles.itemTitle}>{t('sidebar.impersonate')}</div>
                </div>
              </div>
            : null
          }

          <div className={styles.hand}>
            <div className={styles.item}>
              <Icon name='palette' type='line' />
              <Space size={15} align='center'>
                <div className={styles.itemTitle}>{t('users.theme')}</div>
                <SwitchTheme size='default' showBoxShadow />
              </Space>
            </div>
          </div>

          <div onClick={() => dispatch(signOut())} className={styles.hand}>
            <div className={styles.item}>
              <Icon name='shut-down' type='line' />
              <div className={styles.itemTitle}>{t('signIn.signOut')}</div>
            </div>
          </div>

        </div>
      </div>
    </FullScreenFrame>
  );
};

export default withRouter(FullScreenMenu);
