import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { THEME } from '../../../config/commons';
import { Store } from '../../../state/store.interfaces';
import { handleResizeEvent } from '../../../utils/dimensions';
import { SurvivalRate } from '../../../pages/Sowings/interfaces';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvResult } from '../../../common/components/LrvResult/LrvResult';
import { selectedTickStoke } from '../../../common/components/charts/ShadedPlot/helpers';

import './SurvivalRate.scss';
import SurvivalD3 from './SurvivalD3';
import styles from './SurvivalRateChart.module.scss';
import { getHeightChart, getWidthChart } from './helpers';

let chart: SurvivalD3 | null;

interface Props {
  theme?: 'dark' | 'light';
}

export default function SurvivalRateChart (props: Props) {
  const { theme } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChart = useRef<HTMLDivElement>(null);
  const isLightTheme = theme === THEME.LIGHT;

  const [height, setHeight] = useState(getHeightChart());
  const [width, setWidth] = useState(getWidthChart());
  const [isEmpty, setIsEmpty] = useState(false);

  const {
    survivalRate,
    company,
    isLoading,
    global,
  } = useSelector((state: Store) => state.survivalRate);

  const companyAvg = company?.survivalAvg;
  const { survivalAvg: globalAvg } = global;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  const dataset = useCallback(() => {
    const items: SurvivalRate[] = [];

    for (let index = 0; index < survivalRate.length; index++) {
      const item: SurvivalRate = survivalRate[index];
      const data: SurvivalRate = { week: item.week, year: item.year, avgSurvivalRate: item.avgSurvivalRate, virtualWeek: item.week, showYearLine: false };
      items.push(data);
    }

    return items;
  }, [survivalRate]);

  useEffect(() => {
    return () => {
      chart = null;
    };
  }, [dispatch]);

  useEffect(() => {
    if (!companyAvg) {
      return;
    }

    const data = dataset();

    if (data.length) {
      setIsEmpty(false);
      if (chart) {
        chart.refreshChart(data, width, height, globalAvg, companyAvg, tickStroke);
      } else {
        chart = new SurvivalD3(refChart.current, data, width, height, globalAvg, companyAvg, tickStroke);
      }
    } else {
      setIsEmpty(true);
    }
  }, [dataset, width, height, globalAvg, companyAvg, theme, tickStroke]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(getWidthChart());
      setHeight(getHeightChart());
    });
  }, []);

  useEffect(updateScreenOnResize, [width, height]);

  function updateScreenOnResize () {
    chart && chart.resize(width, height);
  }

  function renderSpinner () {
    if (!isLoading) {
      return null;
    }

    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  function renderLegends () {
    if (isLoading) {
      return null;
    }

    return (
      <div className={styles.legend}>
        <div className={styles.item}>
          <div className={cx(styles.colorBox, isLightTheme ? styles.survivalRateLight : styles.survivalRateDark, styles.survivalRate)}>
            <div className={styles.smallSquare}></div>
          </div>
          <span>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('survivalRate.legends.survivalRate')} />
          </span>
        </div>

        <div className={styles.item}>
          <div className={cx(styles.colorBox, styles.globalAvg)} />
          <span>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('survivalRate.legends.globalAvg')} />
          </span>
        </div>

        <div className={styles.item}>
          <div className={cx(styles.colorBox, styles.companyAvg)} />
          <span>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('survivalRate.legends.companyAvg')} />
          </span>
        </div>
      </div>
    );
  }

  const renderNotAvailableContainer = () => {
    if (!companyAvg && !isLoading) {
      return (
        <div className={styles.containerResult}>
          <LrvResult
            status='info'
            title={t('survivalRate.notAvailable')}
            className={styles.result}
            theme={theme}
          />
        </div>
      );
    }


    if (isEmpty && !isLoading) {
      return (
        <div className={styles.center} style={{ width: width, height: height }}>
          <LrvEmpty description={t('survivalRate.empty')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.survivalRateChart}>
      {renderSpinner()}
      {renderNotAvailableContainer()}

      <div className={isEmpty || (!companyAvg && !isLoading) ? styles.hide : ''}>
        <div className={cx(styles.labelAxisY, isLightTheme ? styles.axisLight : styles.axisDark)}>
          <LrvText className='' text={t('survivalRate.title')} />
        </div>

        <div id='survival_rate_chart' className={styles.margin}>
          <div ref={refChart} />
        </div>

        <div className={cx(styles.labelAxisX, isLightTheme ? styles.axisLight : styles.axisDark)}>
          <LrvText text={t('survivalRate.weeksOfYear')} theme={theme} />
        </div>

        {renderLegends()}
      </div>
    </div>
  );
}