import React from 'react';
import { useTranslation } from 'react-i18next';

import { LrvText } from '../LrvText/LrvText';
import { getLanguage } from '../../../utils/language';

import { NewsFunctionalities } from './interfaces';
import styles from './NewFunctionalities.module.scss';

interface Props {
  theme?: 'dark' | 'light';
}

function NewFunctionalities (props: Props) {
  const { theme } = props;

  const [t] = useTranslation();
  const messages = localStorage.getItem('messages') ?? '';
  const messagesList = messages ? JSON.parse(messages) : {};
  const language = getLanguage().toLowerCase();

  return (
    <div className={styles.functionalities}>
      <div>
        <LrvText theme={theme} text={t('functionalities.at')} /> <span className={styles.larvia}> <LrvText theme={theme} text={t('functionalities.larvia')} /> </span> <LrvText theme={theme} text={t('functionalities.introduction')} />
      </div>

      <div className={styles.options}>
        {messagesList[language] ?
          messagesList[language].map((message: NewsFunctionalities, index: number) => {
            return <div key={index}>
              <div className={styles.title}>{message.title}</div>
              <div className={styles.description}>
                <LrvText theme={theme} text={message.description} />
              </div>
            </div>;
          })
          : null
        }
      </div>
    </div>
  );
}
export default NewFunctionalities;
