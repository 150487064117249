import cx from 'classnames';
import { Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { RootState } from '../../state/store';
import * as homeSlice from '../home/homeSlice';
import { Store } from '../../state/store.interfaces';
import { formatLongDateWithZone } from '../../utils/date';
import { getCurrentTheme } from '../../helpers/theme';
import { changeHeader } from '../AppHeader/headerSlice';
import { LrvTag } from '../../common/components/LrvTag/LrvTag';
import { LrvTable } from '../../common/components/LrvTable/LrvTable';
import { getStartDateStocking } from '../../helpers/stocking.helpers';
import TinyCounter from '../../common/components/TinyCounter/TinyCounter';
import { SortTitleCell } from '../../common/components/SortTitleCell/SortTitleCell';
import { ItemRenderPagination } from '../../common/components/LrvPagination/ItemRenderPagination';
import * as onboardingSlice from '../../common/components/Onboarding/OnboardingSlice';
import { stockingStatuses, stockingPhaseTypes, onboardingTypes, stockingsShow, THEME, transferTypes } from '../../config/commons';

import './Sowings.scss';
import styles from './Sowings.module.scss';
import { getSortType } from './sowings.helpers';
import * as stockingSlice from './sowingsSlice';
import XlsxReport from './XlsxReport/XlsxReport';
import EditStockingModal from './EditStockingModal';
import MoveStockingModal from './MoveStockingModal';
import StockingSubHeader from './StockingSubHeader';
import { Stocking as IStocking } from './interfaces';
import FinishStockingModal from './FinishStockingModal';
import EnableStockingModal from './EnableStockingModal';
import { NewStockingLarvae } from './NewStockingLarvae';
import StockingSearchMobile from './StockingSearchMobile';
import { NewStockingGrowOut } from './NewStockingGrowOut';
import { NewStockingJuvenile } from './NewStockingJuvenile';
import { FetchStockingParams, STOCKINGS_SORT } from './interfaces';
import { DropdownStockingOptions } from './DropdownStockingOptions';
import { FinishStockingInfoModal } from './FinishStockingInfoModal';
import { TransferStockingInfoModal } from './TransferStockingInfoModal';
import * as stockingToBeFinishedSlice from './stockingToBeFinishedSlice';
import stylesDropdownOptions from './DropdownStockingOptions.module.scss';
import { HarvestsStockingInfoModal } from './HarvestsStockingInfoModal';

function Stocking () {

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const history = useHistory();

  const stockingsList: IStocking[] = useSelector((state: Store) => state.stockings.list);
  const isLoading = useSelector((state: Store) => state.stockings.isLoading);
  const total = useSelector((state: Store) => state.stockings.total);
  const limit = useSelector((state: Store) => state.stockings.limit);
  const currentPage = useSelector((state: Store) => state.stockings.currentPage);

  const selectedCampus = useSelector((state: Store) => state.stockings.selectedCampus);
  const moduleId = useSelector((state: Store) => state.stockings.selectedModuleId);
  const tankId = useSelector((state: Store) => state.stockings.selectedTankId);
  const maturationId = useSelector((state: Store) => state.stockings.selectedMaturationId);
  const maturationCodeId = useSelector((state: Store) => state.stockings.selectedMaturationCodeId);
  const { sorts, searchValue, searchType, stockingsToShow, campuses, maturations } = useSelector((state: RootState) => state.stockings.filters);

  const {
    existsUserData,
    user: onboardingUserData,
    run: isRunningOnboarding,
  } = useSelector((state: Store) => state.onboarding);

  const { company, phaseType: phaseTypeSelected } = useSelector((state: Store) => state.header);

  const actionHistory = history.action;

  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;

  //#region React hooks
  const resetFilters = useCallback(
    async () => {
      const defaultCompany = await homeSlice.getCompany();
      dispatch(stockingSlice.setSearchValue(''));
      dispatch(stockingSlice.setShowSearchInput(false));
      dispatch(stockingSlice.setPage(1));
      dispatch(stockingSlice.setCampusSelected({ _id: '', name: '', code: '', status: '', phaseType: '' }));
      dispatch(stockingSlice.setModuleSelected(undefined));
      dispatch(stockingSlice.setTankSelected(undefined));
      dispatch(stockingSlice.setStockingsToShow(stockingsShow.ACTIVE));
      dispatch(stockingSlice.resetSorts());
      dispatch(stockingSlice.fetchStockings({ page: 1, companyId: defaultCompany?._id, phaseType: stockingPhaseTypes.LARVAE, stockingsToShow: stockingsShow.ACTIVE }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!company._id || !phaseTypeSelected) {
      return;
    }

    const props = { page: 1, phaseType: phaseTypeSelected, companyId: company._id, stockings: [] };
    dispatch(stockingToBeFinishedSlice.fetchStockingsToBeFinished(props));
  }, [dispatch, phaseTypeSelected, company._id]);

  useEffect(() => {
    dispatch(stockingToBeFinishedSlice.setShowStockingNotification(true));

    return (() => {
      dispatch(stockingToBeFinishedSlice.resetStockingsToBeFinished());
      dispatch(stockingToBeFinishedSlice.setShowStockingNotification(false));
    });
  }, [dispatch, phaseTypeSelected, company._id]);

  useEffect(() => {
    if (!existsUserData || onboardingUserData.onboarding?.initConfig) {
      return;
    }

    dispatch(onboardingSlice.setOnboardingType(onboardingTypes.INIT_CONFIG));
    dispatch(onboardingSlice.setShouldRun(true));
  }, [dispatch, existsUserData]);

  useEffect(() => {
    if (!existsUserData || !onboardingUserData.onboarding?.initConfig) {
      return;
    }

    if (!onboardingUserData.onboarding?.stockingSection && campuses.length > 0 && maturations.length > 0) {
      dispatch(onboardingSlice.setStepIndex(0));
      dispatch(onboardingSlice.setOnboardingType(onboardingTypes.STOCKING_SECTION));
      dispatch(onboardingSlice.setShouldRun(true));
      dispatch(onboardingSlice.setRun(true));
    }
  }, [dispatch, existsUserData, campuses.length, maturations.length]);

  useEffect(() => {
    if (actionHistory === 'REPLACE') {
      resetFilters();
    }
  }, [dispatch, actionHistory, resetFilters]);

  useEffect(() => {
    dispatch(changeHeader({ title: 'stockings.title' }));
  }, [dispatch]);

  useEffect(() => {
    const params: FetchStockingParams = {
      page: currentPage,
      companyId: company?._id,
      campusId: selectedCampus?._id,
      moduleId,
      tankId,
      maturationId,
      maturationCodeId,
      stockingsToShow,
      phaseType: phaseTypeSelected,
      sortByCampusName: sorts.byCampusName,
      sortByModuleName: sorts.byModuleName,
      sortByTankName: sorts.byTankName,
      sortByStockingDate: sorts.byStockingDate,
    };

    dispatch(stockingSlice.fetchInitialData(params));
  }, [dispatch, company._id]);
  //#endregion

  //#region Commons functions
  function onChangePage (page: number) {
    const params: FetchStockingParams = {
      page,
      companyId: company._id,
      campusId: selectedCampus?._id,
      moduleId,
      tankId,
      maturationId,
      maturationCodeId,
      stockingsToShow,
      phaseType: phaseTypeSelected,
      sortByCampusName: sorts.byCampusName,
      sortByModuleName: sorts.byModuleName,
      sortByTankName: sorts.byTankName,
      sortByStockingDate: sorts.byStockingDate,
    };
    dispatch(stockingSlice.fetchStockings(params));
    dispatch(stockingSlice.setPage(page));
  }

  const searchStockingsByName = (value: string) => {
    const params: FetchStockingParams = {
      page: 1,
      searchValue: value,
      searchType,
      phaseType: phaseTypeSelected,
      companyId: company._id,
      stockingsToShow,
      sortByCampusName: sorts.byCampusName,
      sortByModuleName: sorts.byModuleName,
      sortByTankName: sorts.byTankName,
      sortByStockingDate: sorts.byStockingDate,
      campusId: selectedCampus?._id,
      moduleId,
      tankId,
      maturationId,
      maturationCodeId,
    };

    dispatch(stockingSlice.searchStockingsByName(params));
  };

  const columnsType: ColumnsType<IStocking> = [
    {
      key: 1,
      width: '4%',
      render: (record: IStocking) => {
        return (
          <TinyCounter theme={theme} title={t('stockings.analysisCount')} text={record.analysisCount?.toString()} className={isLightTheme ? styles.analysisCountLight : styles.analysisCountDark} />
        );
      },
    },
    {
      key: 2,
      width: '25%',
      title: t('stockings.name'),
      dataIndex: 'name',
      ellipsis: { showTitle: false },
    },
    {
      key: 3,
      width: '16%',
      dataIndex: ['campusId', 'name'],
      ellipsis: { showTitle: false },
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byCampusName} title={t('stockings.campus')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(stockingSlice.changeSorts({
              sortBy: STOCKINGS_SORT.CAMPUS_NAME,
            }));
            const params: FetchStockingParams = {
              page: 1,
              companyId: company._id,
              searchValue,
              searchType,
              stockingsToShow,
              campusId: selectedCampus?._id,
              moduleId: moduleId,
              phaseType: phaseTypeSelected,
              tankId,
              maturationId,
              maturationCodeId,
              sortByCampusName: getSortType(sorts.byCampusName),
              sortByModuleName: sorts.byModuleName,
              sortByTankName: sorts.byTankName,
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(stockingSlice.fetchStockings(params));
          }
        };
      },
    },
    {
      key: 4,
      width: '12%',
      dataIndex: ['moduleId', 'name'],
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byModuleName} title={t('stockings.module')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(stockingSlice.changeSorts({
              sortBy: STOCKINGS_SORT.MODULE_NAME,
            }));
            const params: FetchStockingParams = {
              page: 1,
              companyId: company._id,
              searchValue,
              searchType,
              stockingsToShow,
              campusId: selectedCampus?._id,
              moduleId: moduleId,
              phaseType: phaseTypeSelected,
              tankId,
              maturationId,
              maturationCodeId,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: getSortType(sorts.byModuleName),
              sortByTankName: sorts.byTankName,
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(stockingSlice.fetchStockings(params));
          }
        };
      },
    },
    {
      key: 5,
      width: '14%',
      dataIndex: ['tankId', 'name'],
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['lg'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byTankName} title={t('stockings.container')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(stockingSlice.changeSorts({
              sortBy: STOCKINGS_SORT.TANK_NAME,
            }));
            const params: FetchStockingParams = {
              page: 1,
              companyId: company._id,
              searchValue,
              searchType,
              stockingsToShow,
              campusId: selectedCampus?._id,
              moduleId: moduleId,
              phaseType: phaseTypeSelected,
              tankId,
              maturationId,
              maturationCodeId,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: sorts.byModuleName,
              sortByTankName: getSortType(sorts.byTankName),
              sortByStockingDate: sorts.byStockingDate,
            };
            dispatch(stockingSlice.fetchStockings(params));
          }
        };
      },
    },
    {
      key: 6,
      width: '16%',
      dataIndex: 'date',
      className: isLightTheme ? styles.sortColumnCellLight : styles.sortColumnCellDark,
      ellipsis: { showTitle: false },
      responsive: ['md'] as Breakpoint[],
      title: () => {
        return (
          <SortTitleCell sortType={sorts.byStockingDate} title={t('stockings.startDate')} theme={theme} />
        );
      },
      onHeaderCell: () => {
        return {
          onClick: () => {
            dispatch(stockingSlice.changeSorts({
              sortBy: STOCKINGS_SORT.STOCKING_DATE,
            }));
            const params: FetchStockingParams = {
              page: 1,
              companyId: company._id,
              searchValue,
              searchType,
              stockingsToShow,
              campusId: selectedCampus?._id,
              moduleId: moduleId,
              phaseType: phaseTypeSelected,
              tankId,
              maturationId,
              maturationCodeId,
              sortByCampusName: sorts.byCampusName,
              sortByModuleName: sorts.byModuleName,
              sortByTankName: sorts.byTankName,
              sortByStockingDate: getSortType(sorts.byStockingDate),
            };
            dispatch(stockingSlice.fetchStockings(params));
          }
        };
      },
    },
    {
      key: 7,
      width: '8%',
      dataIndex: 'stage',
      ellipsis: { showTitle: false },
      title: phaseTypeSelected === stockingPhaseTypes.LARVAE ? t('stockings.stage') : t('stockings.days'),
      responsive: ['sm'] as Breakpoint[],
    },
    {
      key: 8,
      width: '15%',
      render: (record: IStocking) => {
        return (
          <Space align='center' className={styles.statusCell} size={5}>
            {renderStatusTag(record)}
            {record.isPublic && <LrvTag type='info'>trade</LrvTag>}
            <DropdownStockingOptions stocking={record} theme={theme} />
          </Space>
        );
      }
    },
  ];

  const dataSource = stockingsList.map((stocking: IStocking) => {
    const startDate = getStartDateStocking({ stocking, phaseType: phaseTypeSelected });

    return {
      key: stocking._id,
      ...stocking,
      date: formatLongDateWithZone(startDate),
    };
  });

  function renderStatusTag (stocking: IStocking) {
    const active = stocking.status === stockingStatuses.ACTIVE || stocking.status === transferTypes.PARTIAL_TRANSFER;

    if (active) {
      return <LrvTag type='active' theme={theme}>{t('stockings.status.active').toLowerCase()}</LrvTag>;
    }

    const fullTransfer = stocking.status === transferTypes.FULL_TRANSFER;

    if (fullTransfer) {
      return <LrvTag type='transfer' theme={theme}>{t('stockings.status.fullTransfer').toLowerCase()}</LrvTag>;
    }

    return <LrvTag theme={theme}>{t('stockings.status.finished').toLowerCase()}</LrvTag>;
  }

  const renderStockingsList = () => {
    return (
      <div>
        <LrvTable
          id='stockings-table'
          className={cx(styles.table, 'stockingsTable')}
          rowClassName={stylesDropdownOptions.stockingRow}
          columns={columnsType}
          loading={isLoading}
          dataSource={dataSource}
          scroll={dataSource.length === 0 ? undefined : { y: 'calc(100vh - 230px)' }}
          size='small'
          theme={theme}
          pagination={{
            size: 'default',
            showSizeChanger: false,
            defaultPageSize: limit,
            pageSize: limit,
            onChange: onChangePage,
            total: total,
            current: currentPage,
            itemRender: ItemRenderPagination,
          }}
          onRow={(record: IStocking) => {
            return {
              onClick: (e) => {
                e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
                if (isRunningOnboarding) {
                  return;
                }
                history.push(`/production/stockings/${record._id}`);
              }
            };
          }}
        />
      </div>
    );
  };

  return <div className={styles.stockings}>
    <StockingSubHeader searchStockingsByName={searchStockingsByName} />
    <StockingSearchMobile searchStockingsByName={searchStockingsByName} />
    {renderStockingsList()}

    <FinishStockingInfoModal theme='light' />
    <TransferStockingInfoModal theme='light' />
    <HarvestsStockingInfoModal theme='light' />
    <NewStockingLarvae theme='light' />
    <NewStockingJuvenile theme='light' />
    <NewStockingGrowOut theme='light' />

    <EditStockingModal theme='light' />
    <MoveStockingModal theme='light' />
    <FinishStockingModal theme='light' />
    <EnableStockingModal theme='light' />

    <XlsxReport theme='light' />
  </div>;
}

export default Stocking;