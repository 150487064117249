import React from 'react';
import Particles from 'react-tsparticles';
import { useTranslation } from 'react-i18next';

import { options } from '../../config/ts-particles';
import { GenericParam } from '../../common/interfaces/commons';
import { ReactComponent as Logo } from '../../assets/larvia-dark.svg';

import styles from './index.module.scss';
import RegisterForm from './RegisterForm';

export default function Register () {
  const [t] = useTranslation();
  return <>
    <Particles id='tsparticles' options={options as GenericParam} />
    <div className={styles.registerWrapper}>
      <div className={styles.registerFormWrapper}>
        <div className={styles.registerForm}>
          <div className={styles.registerLogo}><Logo /></div>
          <div className={styles.registerFormTitle}>
            {t('signIn.register')}
          </div>
          <RegisterForm />
        </div>
      </div>
    </div>
  </>;
}
