import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import React, { useEffect, useRef, useState, } from 'react';

import Data from '../Data';
import Footer from '../Footer';
import Header from '../Header';
import { Store } from '../../../state/store.interfaces';
import * as headerSlice from '../../AppHeader/headerSlice';
import { stockingPhaseTypes } from '../../../config/commons';
import { charactersToSeparate } from '../../../utils/strings';
import { getParameter } from '../../../helpers/stocking.helpers';
import D3Multiphase from '../../Sowings/Multiphase/D3Multiphase';
import { AnalysisMultiphase } from '../../Sowings/Multiphase/interfaces';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { OriginsMultiphase } from '../../Sowings/Multiphase/OriginsMultiphase';
import { lineColor } from '../../../common/components/charts/ShadedPlot/helpers';
import { getAnalysisMultiphaseFilter } from '../../Sowings/Multiphase/multiphase-helpers';
import { fetchMultiphase, resetMultiphases, fetchStocking } from '../../Sowings/Multiphase/multiphaseSlice';

import './MultiphasePdf.scss';
import styles from './MultiphasePdf.module.scss';

let chart: D3Multiphase | null;

type TParams = {
  stockingId: string;
  companyId: string;
  accessToken: string;
  language: string;
  parameter: string;
  scale: string;
  stockingPhaseType: string;
  phaseTypeSelected: string;
  larvaeStockingId?: string;
  juvenileStockingId?: string;
};

export const MultiphasePdf = ({ match }: RouteComponentProps<TParams>) => {
  const { companyId, stockingId, accessToken, stockingPhaseType, language, parameter, scale, phaseTypeSelected, larvaeStockingId, juvenileStockingId } = match.params;

  const phaseTypesSelected = phaseTypeSelected ? phaseTypeSelected.split(charactersToSeparate) : [];

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const refChartMain = useRef<HTMLDivElement>(null);
  const [analysisMultiphases, setAnalysisMultiphases] = useState<AnalysisMultiphase[]>([]);

  const {
    isFetchMultiphase,
    multiphase,
    selectedStocking,
  } = useSelector((state: Store) => state.stockingMultiphase);

  const { company } = useSelector((state: Store) => state.header);
  const metrics = useSelector((state: Store) => state.metrics);
  const { isFetchMetrics } = metrics;

  const { analysis, stockings, maturationName } = multiphase;

  const width = 1584;
  const height = 746;
  const backgroundColor = 'white';

  const showReferenceArea = selectedStocking?.bindingStockings?.length !== undefined && phaseTypesSelected.length === 1;

  useEffect(() => {
    return () => {
      chart = null;
      dispatch(resetMultiphases());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(headerSlice.fetchCompany(companyId, accessToken));
  }, [dispatch, companyId, accessToken]);

  useEffect(() => {
    dispatch(fetchStocking(stockingId, accessToken));
    dispatch(fetchMultiphase({ stockingId, phaseType: stockingPhaseType, token: accessToken, larvaeStockingId, juvenileStockingId }));
  }, [dispatch, companyId, accessToken, stockingId, stockingPhaseType]);

  useEffect(() => {
    if (!analysis.length) {
      return;
    }

    const multiphaseList: AnalysisMultiphase[] = getAnalysisMultiphaseFilter(analysis, phaseTypesSelected, parameter);
    setAnalysisMultiphases(multiphaseList);
  }, [analysis, parameter, phaseTypeSelected]);


  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n, i18n.language]);

  useEffect(() => {
    if (isFetchMultiphase || isFetchMetrics || analysisMultiphases.length === 0 || chart) {
      return;
    }

    const props = {
      colorFillRect: backgroundColor,
      colorLine: lineColor.light,
      container: refChartMain.current,
      analysisMultiphaseData: analysisMultiphases,
      height,
      parameter,
      scale,
      showLabels: true,
      width,
      phaseType: stockingPhaseType,
      phaseTypeSelected: showReferenceArea ? phaseTypesSelected[0] : '',
      showReferenceArea,
      dataMetric: [],
    };

    chart = new D3Multiphase(props);
  }, [analysisMultiphases, isFetchMetrics, metrics, phaseTypesSelected, showReferenceArea, isFetchMultiphase, scale, parameter, stockingPhaseType, width, height]);

  if (isFetchMultiphase) {
    return (
      <div className={styles.spinner}>
        <DotSpinner />
      </div>
    );
  }

  const getTitle = () => {
    const title = company?.name;

    if (selectedStocking.stockingBindingCode) {
      return `${title} - ${t('stockings.pdf.stockingBindingCode')}`;
    }

    return title;
  };

  const getSubTitle = () => {
    switch (stockingPhaseType) {
      case stockingPhaseTypes.LARVAE:
        if (stockings.larvaes?.length) {
          return t('stockings.pdf.stocking') + ' ' + stockings.larvaes[stockings.larvaes.length - 1].name;
        }

        return t('stockings.pdf.stocking') + ' ' + stockings.larvae?.name;

      case stockingPhaseTypes.JUVENILE:
        if (stockings.juveniles?.length) {
          return t('stockings.pdf.stocking') + ' ' + stockings.juveniles[stockings.juveniles.length - 1].name;
        }

        return t('stockings.pdf.stocking') + ' ' + stockings.juvenile?.name;

      case stockingPhaseTypes.ADULT:
        return t('stockings.pdf.stocking') + ' ' + stockings.growOut?.name;

      default:
        return t('stockings.pdf.stocking');
    }
  };

  const renderOriginsMultiphase = () => {
    if (!analysis.length) {
      return null;
    }

    return (
      <div className={styles.originsMultiphase}>
        <OriginsMultiphase rowClassName={styles.rowClassName} />
      </div>
    );
  };

  const renderLegends = () => {
    let phaseType = stockingPhaseType;
    if (stockingPhaseType !== stockingPhaseTypes.LARVAE && phaseTypesSelected.length === 1 && phaseTypesSelected[0] === stockingPhaseTypes.LARVAE) {
      phaseType = stockingPhaseTypes.LARVAE;
    }

    return (
      <div className={styles.legends}>
        <div className={styles.labelAxisY}>{getParameter({ parameter, stockingPhaseType: phaseType })}</div>
        <div className={styles.labelAxisY}>{maturationName}</div>
        <div className={cx(styles.labelAxisY, styles.transparent)}>{getParameter({ parameter, stockingPhaseType: phaseType })}</div>
      </div>
    );
  };

  return (
    <div className={cx(styles.containerMain, 'multiphasePdf')}>
      <Header
        title={getTitle()}
        subtitle={getSubTitle()}
        stockingBindingCode={selectedStocking.stockingBindingCode}
      />

      <div className={styles.colData}>
        <Data
          stockingPhaseType={stockingPhaseType}
          parameter={parameter}
          scale={scale}
          language={language}
        />
      </div>

      <div className={styles.colData}>
        <div className={styles.stockings}>
          {renderOriginsMultiphase()}
        </div>

        {
          (analysis.length) && renderLegends()
        }

        <div ref={refChartMain} className={styles.chart} />

        {
          (analysis.length && analysisMultiphases.length) &&
          <div className={styles.labelAxisX}>{t('shadedplot.dayPostHatching')}</div>
        }
      </div>

      <Footer />
    </div>
  );
};