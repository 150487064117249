import { typeHistogram, roundWeight, roundLength, getWeightUnit, getLengthUnit } from '../../../config/commons';

import { Analysis, Dataset, Frequency } from './interfaces';

export const typeStyles = {
  DEFAULT: 'DEFAULT',
  PDF: 'PDF',
};

export const limitsPigmentation = [0.0, 0.2, 0.4, 0.6, 0.8, 1.0];
export const limitsConditionFactor = [0.4, 0.6, 0.8, 1.0, 1.2, 1.4, 1.6];
export const colosPigmentation = ['#E2C066', '#CDA830', '#A27F1D', '#6A5122', '#3B320E'];
export const IDEAL_CONDITION_FACTOR = 1;

export function getDataset (frequency: Frequency, animals?: number): Dataset[] {
  const dataset: Dataset[] = [];
  for (let index = 0; index < frequency.frequencies.length; index++) {
    const value: number = frequency.frequencies[index];

    const data: Dataset = { frequency: value, index };

    if (animals) {
      const percent = value * 100 / animals;
      data.percent = percent;
    }

    dataset.push(data);
  }
  return dataset;
}

export function getFrequency (histogram: string, analysisData: Analysis, numberBins: number) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
    default:
      return getFrequencyWeight(analysisData, numberBins);

    case typeHistogram.LENGTH:
      return getFrequencyLength(analysisData, numberBins);

    case typeHistogram.PIGMENTATION: {
      const frequencies: number[] = analysisData.resultData.histogramPigmentation;
      const pigmentation: Frequency = { frequencies: frequencies, limits: limitsPigmentation };
      return pigmentation;
    }

    case typeHistogram.CONDITION_FACTOR: {
      const frequencies: number[] = analysisData.resultData.histogramConditionFactor;
      const frequency: Frequency = { frequencies: frequencies, limits: limitsConditionFactor };
      return frequency;
    }
  }
}

export function getAverageOriginal (histogram: string, analysisData: Analysis) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
      return analysisData.resultData.averageWeight;

    case typeHistogram.LENGTH:
      return analysisData.resultData.averageLength;

    case typeHistogram.PIGMENTATION:
      return 0.6;

    default:
      return analysisData.resultData.averageWeight;
  }
}

export function getAnimalsAboveAverage (histogram: string, analysisData: Analysis) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
    default:
      return analysisData.resultData.animalsAboveAverageWeight;

    case typeHistogram.LENGTH:
      return analysisData.resultData.animalsAboveAverageLength;

    case typeHistogram.CONDITION_FACTOR:
      return analysisData.resultData.animalsAboveConditionFactor;
  }
}

export function getAnimalsBelowAverage (histogram: string, analysisData: Analysis) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
    default:
      return analysisData.resultData.animalsBelowAverageWeight;

    case typeHistogram.LENGTH:
      return analysisData.resultData.animalsBelowAverageLength;

    case typeHistogram.CONDITION_FACTOR:
      return analysisData.resultData.animalsBelowConditionFactor;
  }
}

export function getPercentageAnimalsAboveAverage (props: { total: number; animalsAboveAverage: number }) {
  const { total, animalsAboveAverage } = props;
  return animalsAboveAverage * 100 / total;
}

export function getAverage (histogram: string, analysisData: Analysis) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
    default:
      return roundWeight({ value: analysisData.resultData.averageWeight, showUnit: false });

    case typeHistogram.LENGTH:
      return roundLength({ value: analysisData.resultData.averageLength, showUnit: false });

    case typeHistogram.PIGMENTATION:
      return 0.6;

    case typeHistogram.CONDITION_FACTOR:
      return IDEAL_CONDITION_FACTOR;
  }
}

export function getUnit (histogram: string, analysisData: Analysis) {
  switch (histogram) {
    case typeHistogram.WEIGHT:
    default:
      return getWeightUnit(analysisData.resultData.averageWeight);

    case typeHistogram.LENGTH:
      return getLengthUnit(analysisData.resultData.averageLength);

    case typeHistogram.CONDITION_FACTOR:
      return '';
  }
}

export function getLimits (histogram: string, frequency: Frequency, averageOriginal: number) {
  let limits: number[] = [];

  switch (histogram) {
    case typeHistogram.WEIGHT:
      limits = roundWeight({ value: frequency.limits, showUnit: false, average: averageOriginal }) as number[];
      break;

    case typeHistogram.LENGTH:
      limits = roundLength({ value: frequency.limits, showUnit: false, average: averageOriginal }) as number[];
      break;

    default:
      limits = frequency.limits;
      break;
  }

  return limits;
}

export function getFrequencyWeight (analysisData: Analysis, numberBins: number) {
  if (analysisData.resultData.histogramBins && analysisData.resultData.histogramBins[6]) {
    let frequencySelected = analysisData.resultData.histogramBins[6];
    switch (numberBins) {
      case 6:
        frequencySelected = analysisData.resultData.histogramBins[6];
        break;
      case 7:
        frequencySelected = analysisData.resultData.histogramBins[7];
        break;
      case 8:
        frequencySelected = analysisData.resultData.histogramBins[8];
        break;
      case 9:
        frequencySelected = analysisData.resultData.histogramBins[9];
        break;
      case 10:
        frequencySelected = analysisData.resultData.histogramBins[10];
        break;
    }

    return frequencySelected;
  }
  return { frequencies: [], limits: [] };
}

export function getFrequencyLength (analysisData: Analysis, numberBins: number) {
  if (analysisData.resultData.histogramLengthBins && analysisData.resultData.histogramLengthBins[6]) {
    let frequencySelected = analysisData.resultData.histogramLengthBins[6];
    switch (numberBins) {
      case 6:
        frequencySelected = analysisData.resultData.histogramLengthBins[6];
        break;
      case 7:
        frequencySelected = analysisData.resultData.histogramLengthBins[7];
        break;
      case 8:
        frequencySelected = analysisData.resultData.histogramLengthBins[8];
        break;
      case 9:
        frequencySelected = analysisData.resultData.histogramLengthBins[9];
        break;
      case 10:
        frequencySelected = analysisData.resultData.histogramLengthBins[10];
        break;
    }

    return frequencySelected;
  }
  return { frequencies: [], limits: [] };
}