import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMinStage } from '../../Analysis/helpers';
import { Store } from '../../../state/store.interfaces';
import Icon from '../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../helpers/theme';
import { DataSource, Point } from '../../home/interfaces';
import { changeHeader } from '../../AppHeader/headerSlice';
import { ItemReferenceCurves } from '../../Units/interfaces';
import { handleResizeEvent } from '../../../utils/dimensions';
import { stockingPhaseTypes, THEME } from '../../../config/commons';
import { LrvText } from '../../../common/components/LrvText/LrvText';
import { LrvEmpty } from '../../../common/components/LrvEmpty/LrvEmpty';
import DotSpinner from '../../../common/components/DotSpinner/DotSpinner';
import { LrvTooltip } from '../../../common/components/LrvTooltip/LrvTooltip';
import D3ShadedPlot from '../../../common/components/charts/ShadedPlot/D3ShadedPlot';
import { calcStages, rectColor, colorsPoints, getDefaultDataByStage, metricsStatuses, typeScale, typesChart, lineColor, selectedTickStoke, getNewColor } from '../../../common/components/charts/ShadedPlot/helpers';

import styles from './LaboratoryChart.module.scss';
import { LaboratoryStageMetrics } from './interfaces';
import * as laboratoryChartSlice from './laboratoryChartSlice';
import { LaboratoryChartFilters } from './LaboratoryChartFilters';
import { getHeightOfTheOtherElements, getWidthOfTheOtherElements } from './helpers';

let chart: D3ShadedPlot | null;
const maxSelectedLaboratories = 10;

export const LaboratoryChart = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const refChart = useRef<HTMLDivElement>(null);
  const refFilters = useRef<HTMLDivElement>(null);
  const refLaboratoriesPanel = useRef<HTMLDivElement>(null);
  const refSlider = useRef<HTMLDivElement>(null);

  const {
    isLoading,
    filters,
    firstStage,
    lastStage,
    dataSource,
    laboratoryMetrics,
    laboratoryMetricsByUnits,
  } = useSelector((state: Store) => state.laboratoryChart);

  const { company: selectedCompany, phaseType } = useSelector((state: Store) => state.header);

  const {
    parameter,
    scale,
    minimumDate,
    maximumDate,
    unitId,
  } = filters;

  const [width, setWidth] = useState(window.innerWidth - getWidthOfTheOtherElements({ laboratoryPanel: refLaboratoriesPanel }));
  const [height, setHeight] = useState(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
  const [amountLaboratories, setAmountLaboratories] = useState<number>(0);

  const minStage = getMinStage(phaseType);
  const theme = getCurrentTheme();
  const isLightTheme = theme === THEME.LIGHT;
  const colorFillRect = isLightTheme ? rectColor.light : rectColor.dark;
  const colorLine = isLightTheme ? lineColor.light : lineColor.dark;
  const tickStroke = isLightTheme ? selectedTickStoke.light : selectedTickStoke.dark;

  useEffect(() => {
    dispatch(changeHeader({ title: 'laboratory.title' }));

    return (() => {
      chart = null;
    });
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCompany._id) {
      return;
    }

    const params = {
      companyId: selectedCompany._id,
      parameter,
      minimumDate,
      maximumDate,
      phaseType,
      unitId,
    };

    dispatch(laboratoryChartSlice.fetchLaboratoryMetrics(params));
  }, [dispatch, phaseType, selectedCompany._id, minimumDate, maximumDate, parameter, unitId]);

  useEffect(() => {
    const laboratoryDataSource: DataSource[] = [];

    let index = 0;
    for (const [laboratoryName, laboratoryStageMetrics] of Object.entries(laboratoryMetrics)) {
      const laboratoryByStageList: Point[] = [];

      const dataSourceSelected = dataSource.find((data) => data.id === laboratoryName);
      const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;

      const color = dataSourceSelected?.color || colorsPoints[index];
      const enabled = dataSourceSelected?.enabled === undefined ? dataSourceLength < maxSelectedLaboratories : dataSourceSelected.enabled;
      const show = dataSourceSelected?.show || true;

      for (const [stage, laboratoryStage] of Object.entries(laboratoryStageMetrics)) {
        const x: number = parseInt(stage);
        const y: number = laboratoryStage.mean;
        const analysesNumber = laboratoryStage.number;
        const laboratoryByStage: Point = getDefaultDataByStage({ x, y, analysesNumber, stageRange: laboratoryStage.stageRange });
        laboratoryByStageList.push(laboratoryByStage);
      }

      const laboratoryData: DataSource = { id: laboratoryName, labName: laboratoryName, name: laboratoryName, color, enabled, show, points: [], avgPoint: laboratoryByStageList };
      laboratoryDataSource.push(laboratoryData);
      index++;
    }

    for (const [laboratoryName, laboratoryMetricByUnit] of Object.entries(laboratoryMetricsByUnits)) {
      for (const [unitName, laboratoryStage] of Object.entries(laboratoryMetricByUnit)) {
        const key = `${laboratoryName}_${unitName}`;
        const laboratoryByStageList: Point[] = [];
        const dataSourceSelected = dataSource.find((data) => data.id === key);
        const color = dataSourceSelected?.color;
        const enabled = dataSourceSelected?.enabled || false;
        const show = dataSourceSelected?.show || true;

        // eslint-disable-next-line
        for (const [stage, value] of Object.entries(laboratoryStage)) {
          const x: number = parseInt(stage);
          const y: number = value.mean;
          const analysesNumber = value.number;
          const laboratoryByStage: Point = getDefaultDataByStage({ x, y, analysesNumber, stageRange: value.stageRange });
          laboratoryByStageList.push(laboratoryByStage);
        }
        const laboratoryData: DataSource = { id: key, labName: laboratoryName, name: `${laboratoryName}: ${unitName}`, color, enabled, show, points: [], avgPoint: laboratoryByStageList };
        laboratoryDataSource.push(laboratoryData);
      }
    }

    dispatch(laboratoryChartSlice.setDataSource(laboratoryDataSource));
  }, [laboratoryMetrics]);

  useEffect(() => {
    handleResizeEvent(() => {
      setWidth(window.innerWidth - getWidthOfTheOtherElements({ laboratoryPanel: refLaboratoriesPanel }));
      setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
    });
  }, []);

  useEffect(() => {
    if (!selectedCompany._id || !dataSource.length) {
      return;
    }

    setWidth(window.innerWidth - getWidthOfTheOtherElements({ laboratoryPanel: refLaboratoriesPanel }));
    setHeight(window.innerHeight - getHeightOfTheOtherElements({ filters: refFilters, slider: refSlider }));
  }, [selectedCompany._id, dataSource]);

  useEffect(() => {
    chart && chart.resize(width, height);
  }, [width, height]);

  useEffect(() => {
    if ((firstStage === undefined || !lastStage) || !selectedCompany._id) {
      return;
    }

    const renderD3Chart = () => {
      const data: ItemReferenceCurves[] = [];
      const dataSourceFiltered = dataSource.filter((data) => data.enabled && data.show && data.color);
      const colorsPoints = dataSourceFiltered.map((dataSource) => `${dataSource.color}`);
      const colorsStroke = colorsPoints;

      if (dataSourceFiltered.length === 0 && !!chart) {
        chart.refreshChart({ showMovingAverage: false, dataMetric: data, dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, typeMetric: metricsStatuses.COMPANY, scale, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      if (chart) {
        chart.refreshChart({ showMovingAverage: false, dataMetric: data, dataSource: cloneDeep(dataSourceFiltered), firstStage, lastStage, width, height, phaseType, colorsPoints, colorsStroke, parameter, typeMetric: metricsStatuses.COMPANY, scale, colorFillRect, colorLine, selectedTickStoke: tickStroke });
        return;
      }

      const props = {
        colorFillRect,
        colorLine,
        dispatch,
        companyData: selectedCompany,
        container: refChart.current,
        dataMetric: data,
        dataSource: cloneDeep(dataSourceFiltered),
        firstStage,
        height,
        lastStage,
        parameter,
        phaseType,
        scale: typeScale.LINEAR,
        showLabels: false,
        typeChart: typesChart.LABORATORY,
        typeMetric: metricsStatuses.COMPANY,
        isMaturationCurves: true,
        width,
        colorsPoints,
        colorsStroke,
        selectedTickStoke: tickStroke,
        showMovingAverage: false,
      };
      chart = new D3ShadedPlot(props);
    };

    renderD3Chart();
  }, [dispatch, dataSource, scale, parameter, firstStage, lastStage, width, height, selectedCompany, phaseType, colorFillRect, colorLine, tickStroke]);

  useEffect(() => {
    if (!dataSource.length) {
      return;
    }

    const updateRange = () => {
      const stages = calcStages(dataSource, minStage);
      const firstStage = stages[0];
      const lastStage = stages[1];

      dispatch(laboratoryChartSlice.setFirstStage(firstStage));
      dispatch(laboratoryChartSlice.setLastStage(lastStage));

      dispatch(laboratoryChartSlice.setFirstStageZoom(firstStage));
      dispatch(laboratoryChartSlice.setLastStageZoom(lastStage));
    };

    if (dataSource.length !== amountLaboratories) {
      setAmountLaboratories(dataSource.length);
    }

    updateRange();
  }, [dataSource, amountLaboratories, minStage]);

  const disabledCheckbox = () => {
    const dataSourceLength = dataSource.filter((data) => data.enabled && data.show).length;
    return dataSourceLength >= maxSelectedLaboratories;
  };

  const disabledShowButton = (laboratoryName: string) => {
    const enabledDataLength = dataSource.filter((data) => data.enabled && data.show).length;
    const disabledDataLength = dataSource.filter((data) => data?.labName === laboratoryName && data.enabled && !data.show).length;
    return (enabledDataLength + disabledDataLength) > maxSelectedLaboratories;
  };

  const enableItem = (checked: boolean, key: string) => {
    if (disabledCheckbox() && checked) {
      return;
    }

    const dataSourceCopy: DataSource[] = cloneDeep(dataSource);

    dataSourceCopy.forEach((data) => {
      if (data.id === key) {
        const colors: string[] = [];
        dataSource.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        data.enabled = checked;
        data.color = checked ? newColor : undefined;
      }
    });

    dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
    dispatch(laboratoryChartSlice.setDataSource(dataSourceCopy));
  };

  const hideLaboratory = (props: {
    show: boolean;
    laboratoryName: string;
    laboratoryKey: string;
    laboratoryMetricByUnit?: {
      [key: string]: LaboratoryStageMetrics;
    }
  }
  ) => {
    const { show, laboratoryName, laboratoryKey, laboratoryMetricByUnit } = props;
    const dataSourceCopy: DataSource[] = cloneDeep(dataSource);

    dataSourceCopy.forEach((data) => {
      if (data.id === laboratoryKey) {
        const colors: string[] = [];
        dataSource.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        data.show = show;
        data.color = show ? newColor : undefined;
      }
    });

    if (!laboratoryMetricByUnit) {
      dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
      dataSourceCopy.sort((a, b) => Number(b.show) - Number(a.show));
      dispatch(laboratoryChartSlice.setDataSource(dataSourceCopy));
      return;
    }

    for (const [unitName,] of Object.entries(laboratoryMetricByUnit)) {
      const unitNameKey = `${laboratoryName}_${unitName}`;
      const dataSourceFind = dataSourceCopy.find((data) => data.id === unitNameKey);

      if (!dataSourceFind?.enabled) {
        continue;
      }

      if (dataSourceFind.id === unitNameKey) {
        const colors: string[] = [];
        dataSourceCopy.forEach((data) => {
          if (data.color && !colors.includes(data.color)) {
            colors.push(data.color);
          }
        });

        const newColor = getNewColor(colors);
        dataSourceFind.show = show;
        dataSourceFind.color = show ? newColor : undefined;
      }
    }

    dataSourceCopy.sort((a, b) => Number(b.enabled) - Number(a.enabled));
    dataSourceCopy.sort((a, b) => Number(b.show) - Number(a.show));
    dispatch(laboratoryChartSlice.setDataSource(dataSourceCopy));
  };

  const renderLaboratoryOptions = () => {
    const items: JSX.Element[] = [];

    for (const [laboratoryName,] of Object.entries(laboratoryMetrics)) {
      if (!(laboratoryName in laboratoryMetricsByUnits)) {
        const laboratoryData = dataSource.find((data) => data.id === laboratoryName);
        const disabledButton = disabledShowButton(laboratoryName);

        const itemLaboratory = (
          <div className={styles.header}>
            <Checkbox
              onChange={event => enableItem(event.target.checked, laboratoryName)}
              checked={(laboratoryData?.enabled && laboratoryData?.show)}
              className={(laboratoryData?.enabled && laboratoryData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
              disabled={!laboratoryData?.show || (!laboratoryData?.enabled && disabledCheckbox())}
            >
              <div
                className={styles.label}
                style={{ color: (laboratoryData?.enabled && laboratoryData?.show) ? `${laboratoryData?.color}` : '' }}
              >
                {laboratoryName}
              </div>
            </Checkbox>

            <LrvTooltip
              trigger={disabledButton ? undefined : 'contextMenu'}
              title={t('laboratory.limitTooltip')}
            >
              <Button
                id='hide_button'
                type='text'
                icon={<Icon name={laboratoryData?.show ? 'eye' : 'eye-off'} className={styles.icon} />}
                onClick={() => hideLaboratory({ show: !(laboratoryData?.show), laboratoryName, laboratoryKey: laboratoryName })}
                disabled={disabledButton}
              />
            </LrvTooltip>
          </div>
        );

        const item = (
          <div
            key={laboratoryName}
            className={cx(styles.laboratory, isLightTheme ? styles.laboratoryLight : styles.laboratoryDark)}
          >
            {itemLaboratory}
          </div>
        );

        items.push(item);
      }

    }

    for (const [laboratoryName, laboratoryMetricByUnit] of Object.entries(laboratoryMetricsByUnits)) {
      const laboratoryData = dataSource.find((data) => data.id === laboratoryName);
      const disabledButton = disabledShowButton(laboratoryName);

      const itemLaboratory = (
        <div className={styles.header}>
          <Checkbox
            onChange={event => enableItem(event.target.checked, laboratoryName)}
            checked={(laboratoryData?.enabled && laboratoryData?.show)}
            className={(laboratoryData?.enabled && laboratoryData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
            disabled={!laboratoryData?.show || (!laboratoryData?.enabled && disabledCheckbox())}
          >
            <div
              className={styles.label}
              style={{ color: (laboratoryData?.enabled && laboratoryData?.show) ? `${laboratoryData?.color}` : '' }}
            >
              {laboratoryName}
            </div>
          </Checkbox>

          <LrvTooltip
            trigger={disabledButton ? undefined : 'contextMenu'}
            title={t('laboratory.limitTooltip')}
          >
            <Button
              id='hide_button'
              type='text'
              icon={<Icon name={laboratoryData?.show ? 'eye' : 'eye-off'} className={styles.icon} />}
              onClick={() => hideLaboratory({ show: !(laboratoryData?.show), laboratoryName, laboratoryKey: laboratoryName, laboratoryMetricByUnit })}
              disabled={disabledShowButton(laboratoryName)}
            />
          </LrvTooltip>
        </div>
      );

      const itemsUnits = [];
      for (const [unitName,] of Object.entries(laboratoryMetricByUnit)) {
        const key = `${laboratoryName}_${unitName}`;
        const unitsData = dataSource.find((data) => data.id === key);

        const itemUnit = (
          <Checkbox
            key={key}
            onChange={event => enableItem(event.target.checked, key)}
            checked={(unitsData?.enabled && laboratoryData?.show)}
            className={(unitsData?.enabled && laboratoryData?.show) ? cx(styles.checkbox, styles.checkboxEnabled) : styles.checkbox}
            disabled={!laboratoryData?.show || (!unitsData?.enabled && disabledCheckbox())}
          >
            <div
              className={styles.label}
              style={{ color: (unitsData?.enabled && laboratoryData?.show) ? `${unitsData?.color}` : '' }}
            >
              {unitName}
            </div>
          </Checkbox>
        );

        itemsUnits.push(itemUnit);
      }

      const item = (
        <div
          key={laboratoryName}
          className={cx(styles.laboratory, isLightTheme ? styles.laboratoryLight : styles.laboratoryDark)}
        >
          {itemLaboratory}
          <div className={styles.unit}>
            {itemsUnits}
          </div>
        </div>
      );

      items.push(item);
    }

    return (
      <div ref={refLaboratoriesPanel} className={cx(styles.laboratoryOptions, isLightTheme ? styles.laboratoryOptionsLight : styles.laboratoryOptionsDark)}>
        {items}
      </div>
    );
  };

  const showChart = () => {
    return dataSource.length > 0 && !isLoading && phaseType !== stockingPhaseTypes.LARVAE;
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinnerContainer}>
          <div className={styles.spinner}>
            <DotSpinner />
          </div>
        </div>
      );
    }

    if (phaseType === stockingPhaseTypes.LARVAE) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('laboratory.larvae')} theme={theme} />
        </div>
      );
    }

    if (!dataSource.length) {
      return (
        <div className={styles.center} >
          <LrvEmpty description={t('laboratory.empty')} theme={theme} />
        </div>
      );
    }

    return null;
  };

  const renderChart = () => {
    return (
      <div className={styles.chart}>

        <div style={{ display: showChart() ? '' : 'none' }}>
          <div ref={refChart} className={styles.chartSvg} />
        </div>

        {
          showChart() &&
          <div className={styles.labelAxisX}>
            <LrvText className={isLightTheme ? styles.lightText : styles.darkText} text={t('shadedplot.days')} />
          </div>
        }
      </div>
    );
  };

  return (
    <div className={styles.container} >
      <LaboratoryChartFilters refFilters={refFilters} theme={theme} />
      {renderContent()}

      <div className={styles.main}>
        {showChart() && renderLaboratoryOptions()}
        {renderChart()}
      </div>
    </div>
  );
};
