import React from 'react';
import cx from 'classnames';
import { Upload } from 'antd';

import { LrvUploadProps } from '../../interfaces/lrvUpload';

import styles from './LrvDragger.module.scss';

const { Dragger } = Upload;

export const LrvDragger = (props: LrvUploadProps) => {
  const { className, containerClassName, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkDragger : styles.lightDragger, className),
  };

  return (
    <div className={cx(styles.container, containerClassName)}>
      <Dragger {...lrvProps}>
      </Dragger>
    </div>
  );
};
