import i18next from 'i18next';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getUserSession } from '../../utils/userSession';
import { axiosClient as axios } from '../../utils/axios_instance';
import { showErrorStocking } from '../../helpers/stocking.helpers';
import { openSuccessNotification } from '../../common/notification/Notification';
import { stockingStatuses, transferTypes, unitStatuses } from '../../config/commons';
import { STOCKINGS_URL, CAMPUS_URL, MODULES_URL, TANKS_URL } from '../../config/config.api';

import * as sowingsSlice from './sowingsSlice';
import * as finishStockingSlice from './finishStockingSlice';
import { TransferStockingProps, UndoTransferStockingProps, TransferStockingsState, Transfer, Campus, Module, Container, EnableTransferStockingProps, DestinationStocking } from './interfaces';

const initialState: TransferStockingsState = {
  isLoadingTransfer: false,
  isLoadingUndoTransfer: false,
  isLoadingEnableTransfer: false,
  showTransferStockingInfo: false,
  transfers: [],
  isLoading: false,
  campuses: [],
};

export const transferStockingsSlice = createSlice({
  name: 'transferStocking',
  initialState,
  reducers: {
    setIsLoadingTransfer: (state: TransferStockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingTransfer = action.payload;
    },
    setIsLoadingUndoTransfer: (state: TransferStockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingUndoTransfer = action.payload;
    },
    setIsLoadingEnableTransfer: (state: TransferStockingsState, action: PayloadAction<boolean>) => {
      state.isLoadingEnableTransfer = action.payload;
    },
    setShowTransferStockingInfo: (state: TransferStockingsState, action: PayloadAction<boolean>) => {
      state.showTransferStockingInfo = action.payload;
    },
    setTransfers: (state: TransferStockingsState, action: PayloadAction<Transfer[]>) => {
      state.transfers = action.payload;
    },
    setIsLoading: (state: TransferStockingsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCampuses: (state: TransferStockingsState, action: PayloadAction<Campus[]>) => {
      state.campuses = action.payload;
    },
  },
});

export const {
  setIsLoadingTransfer,
  setIsLoadingUndoTransfer,
  setIsLoadingEnableTransfer,
  setShowTransferStockingInfo,
  setTransfers,
  setIsLoading,
  setCampuses
} = transferStockingsSlice.actions;

export const transferStocking = (props: TransferStockingProps) => async (dispatch: Function) => {
  const { stockingId, stockingData, makeFetchStockings, paramsToFetchStocking } = props;
  dispatch(setIsLoadingTransfer(true));

  const url = `${STOCKINGS_URL}/${stockingId}/stocking-transfer`;

  try {
    await axios.post(url, stockingData);
    openSuccessNotification(i18next.t('stockings.updated'));
  } catch (e) {
    console.log(e?.response);

    dispatch(setIsLoadingTransfer(false));
    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
    }
    return;
  }

  dispatch(setIsLoadingTransfer(false));
  dispatch(finishStockingSlice.setShowModalFinish(false));
  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: stockingId }));
  }
};

export const undoTransferStocking = (props: UndoTransferStockingProps) => async (dispatch: Function) => {
  const { originStockingId, destinationStockingId, makeFetchStockings, paramsToFetchStocking } = props;
  dispatch(setIsLoadingUndoTransfer(true));

  const body = { destinationStockingId };
  const url = `${STOCKINGS_URL}/${originStockingId}/stocking-undo-transfer`;

  try {
    await axios.patch(url, body);
    openSuccessNotification(i18next.t('stockings.updated'));

    dispatch(fetchTransfers({ stockingId: originStockingId }));
    dispatch(setIsLoadingUndoTransfer(false));
  } catch (e) {
    console.log(e?.response);

    dispatch(setIsLoadingUndoTransfer(false));
    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
      return;
    }
  }

  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: originStockingId }));
  }
};

export const enableTransfers = (props: EnableTransferStockingProps) => async (dispatch: Function) => {
  const { makeFetchStockings, paramsToFetchStocking, stockingId } = props;
  dispatch(setIsLoadingEnableTransfer(true));

  const url = `${STOCKINGS_URL}/${stockingId}/undo-transfer-full`;

  try {
    await axios.patch(url);
    openSuccessNotification(i18next.t('stockings.updated'));
    dispatch(setIsLoadingEnableTransfer(false));
  } catch (e) {
    console.log(e?.response);

    dispatch(setIsLoadingEnableTransfer(false));
    if (e?.response?.data?.data?.error) {
      const error = e.response.data.data.error;
      showErrorStocking({ error });
      return;
    }
  }

  dispatch(finishStockingSlice.setShowModalEnable(false));
  if (makeFetchStockings) {
    dispatch(sowingsSlice.fetchStockings(paramsToFetchStocking));
  } else {
    dispatch(sowingsSlice.fetchStocking({ id: stockingId }));
  }
};

export const fetchTransfers = (props: { stockingId: string }) => async (dispatch: Function) => {
  const { stockingId } = props;
  dispatch(setIsLoading(true));

  const url = `${STOCKINGS_URL}/${stockingId}/transfers`;

  try {
    const response = await axios.get<Transfer[]>(url);
    dispatch(setTransfers(response.data));
    dispatch(setIsLoading(false));
  } catch (e) {
    console.log(e?.response);
  }
};

export const fetchCampuses = (props: { companyId: string; phaseType: string; }) => async (dispatch: Function) => {
  const { companyId, phaseType } = props;
  const userSession = getUserSession();

  const params = {
    $limit: -1,
    companyId: companyId || userSession.companyId,
    phaseType,
    'status[$in][0]': unitStatuses.ACTIVE,
    'status[$in][1]': unitStatuses.INACTIVE,
  };

  try {
    const response = await axios.get<Campus[]>(CAMPUS_URL, { params });
    dispatch(setCampuses(response.data));
  } catch (error) {
    console.log(error?.response);
  }
};

export const fetchModules = async (props: { campusId: string; phaseType: string; }) => {
  const { campusId, phaseType } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId,
    phaseType,
  };

  const response = await axios.get<Module[]>(MODULES_URL, { params });
  return response.data;
};

export const fetchTanks = async (props: { campusId: string; moduleId: string; }) => {
  const { campusId, moduleId } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId,
    moduleId,
  };

  const response = await axios.get<Container[]>(TANKS_URL, { params });
  return response.data;
};

export const fetchStocking = async (props: { campusId: string; moduleId: string; tankId: string; }) => {
  const { campusId, moduleId, tankId } = props;

  const params = {
    $limit: -1,
    active: true,
    campusId,
    moduleId,
    tankId,
    'status[$in][0]': stockingStatuses.ACTIVE,
    'status[$in][1]': transferTypes.PARTIAL_TRANSFER,
    $select: ['_id', 'name', 'transferRecord', 'naupliusNumber', 'juvenilesNumber', 'growOutNumber'],
    isArchived: false,
  };

  const response = await axios.get<DestinationStocking[]>(STOCKINGS_URL, { params });
  return response.data[0];
};

export default transferStockingsSlice.reducer;
