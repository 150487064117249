import { Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { AnimalsByGroup } from '../Analysis/Detail/interfaces';
import SampleD3, { SampleD3Props } from '../Analysis/Detail/Sample/SampleD3';
import { createListAnimalsByGroup, fillAnimalsByGroup, fillAnimalsByGroupOld } from '../Analysis/Detail/math_percentile';

import { Analysis } from './interfaces';
import styles from './Sample.module.scss';

export default function Sample (props: { analysis: Analysis; imageSizeDefault: number }) {
  const { analysis, imageSizeDefault } = props;
  const { imageData, inferenceData, type: analysisType, pointSizeRatio } = analysis;

  const { url } = imageData.reduced;
  const { weightGroups } = inferenceData;

  const [image, setImage] = useState(new window.Image());
  const [imageSize, setImageSize] = useState(0);
  const refChart = useRef<HTMLDivElement>(null);

  const palette = ['#FF149380', '#FFA52A80', '#2B9A2180', '#00BFFC80', '#1B62E680'];

  let animalsByGroup: AnimalsByGroup[] = createListAnimalsByGroup({ colors: palette, imageSize, pointSizeRatio, weightGroups });

  const scaleFactor = imageSize / imageSizeDefault;
  animalsByGroup = pointSizeRatio === undefined ?
    fillAnimalsByGroupOld({ animalsByGroup, weightGroups, scaleFactor }) :
    fillAnimalsByGroup({ animalsByGroup, weightGroups, scaleFactor });

  useEffect(() => {
    let isMounted = true;

    if (!isMounted || !url) {
      return;
    }

    const img = new window.Image();
    img.src = url;

    img.addEventListener('load', () => {
      setImage(img);

      const height = img.naturalHeight;
      const width = img.naturalWidth;

      if (height && width && width === height) {
        setImageSize(height);
      }
    });

    return () => {
      isMounted = false;
    };

  }, [url]);

  useEffect(() => {
    if (!url || !animalsByGroup.length) {
      return;
    }

    const props: SampleD3Props = {
      container: refChart.current,
      height: imageSizeDefault,
      width: imageSizeDefault,
      urlImage: url,
      animalsByGroup,
      phaseType: analysisType,
    };

    new SampleD3(props);
  }, [url, animalsByGroup]);

  return (
    <Space direction='horizontal' key={analysis._id} className={styles.space}>
      <img
        src={url}
        alt='sample'
        style={{
          width: imageSizeDefault,
          height: imageSizeDefault,
        }}
      />

      {
        imageSize > 0 && image &&
        <div className={styles.containerImage}>
          <div ref={refChart} />
        </div>
      }
    </Space>
  );
}
