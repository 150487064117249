import React from 'react';
import cx from 'classnames';
import { Switch } from 'antd';
import { useDispatch } from 'react-redux';

import { THEME } from '../../config/commons';
import Icon from '../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../helpers/theme';
import { setTheme, updateTheme } from '../Settings/settingsSlice';

import styles from './SwitchTheme.module.scss';

interface Props {
  size?: 'small' | 'default';
  showBoxShadow?: boolean;
  isAnalysisPublic?: boolean;
}

export const SwitchTheme = (props: Props) => {
  const { size = 'small', showBoxShadow = false, isAnalysisPublic = false } = props;
  const dispatch = useDispatch();
  const theme = getCurrentTheme();
  const isDarkTheme = theme === THEME.DARK;

  const changeThemeAnalysisPublic = () => {
    let newTheme: 'dark' | 'light' | undefined;

    switch (theme) {
      case THEME.DARK:
      default:
        newTheme = 'light';
        break;

      case THEME.LIGHT:
        newTheme = 'dark';
        break;
    }

    localStorage.setItem('user', JSON.stringify({ theme: newTheme }));
    dispatch(setTheme(newTheme));
  };

  const changeTheme = () => {
    if (isAnalysisPublic) {
      changeThemeAnalysisPublic();
      return;
    }

    switch (theme) {
      case THEME.DARK:
      default:
        dispatch(updateTheme({ theme: THEME.LIGHT }));
        break;

      case THEME.LIGHT:
        dispatch(updateTheme({ theme: THEME.DARK }));
        break;
    }
  };

  return (
    <div className={cx(styles.container)}>
      <Switch
        className={cx(isAnalysisPublic && isDarkTheme ? styles.switchDarkAnalysisPublic : '', isDarkTheme ? styles.switchDark : styles.switchLight, showBoxShadow ? styles.boxShadow : '')}
        checkedChildren={<Icon className={styles.icon} name='moon' type='fill' />}
        unCheckedChildren={<Icon className={styles.icon} name='sun' type='fill' />}
        defaultChecked={isDarkTheme}
        checked={isDarkTheme}
        onChange={changeTheme}
        size={size}
      />
    </div>
  );
};
