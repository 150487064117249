import React from 'react';
import cx from 'classnames';
import { Descriptions } from 'antd';

import { LrvDescriptionsProps } from '../../interfaces/lrvDescriptions';

import styles from './LrvDescriptions.module.scss';

export const LrvDescriptions = (props: LrvDescriptionsProps) => {
  const { children, className, theme = 'dark' } = props;
  const isDarkTheme = theme === 'dark';

  const lrvDescriptionsProps = {
    ...props,
    className: cx(isDarkTheme ? styles.darkDescriptions : styles.lightDescriptions, className),
  };

  return (
    <Descriptions {...lrvDescriptionsProps}>
      {children}
    </Descriptions>
  );
};