import React from 'react';
import cx from 'classnames';

import { getCurrentTheme } from '../../../helpers/theme';
import { THEME } from '../../../config/commons';

import styles from './DotSpinner.module.scss';


interface Props {
  theme?: 'dark' | 'light';
}

export default function DotSpinner ({ theme = getCurrentTheme() }: Props) {
  const isLightTheme = theme === THEME.LIGHT;

  return <div className={cx(styles.spinner, isLightTheme ? styles.spinnerLight : styles.spinnerDark)}>
    <div className={styles.dot1} />
    <div className={styles.dot2} />
    <div className={styles.dot3} />
  </div>;
}
